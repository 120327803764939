export enum CalculationStatus {
	NotProcessed = 'Not Processed',
	Processed = 'Processed',
	Failed= 'Failed',
	Status='status',
	SurplusCalculationHistory='surplusCalculationHistory',
	Id='id',
	createdOn='createdOn',
	UpdatedOn='updatedOn'
}
