import React, { useContext, useEffect, useRef, useState } from 'react';

import './FAQuickView.scss';
import BreadCrumbs from '../BreadCrumbs/BreadCrumb';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import { Tooltip } from '@appkit4/react-components';
import { useTranslation } from 'react-i18next';
import AffiliateContext from 'src/common/components/SharedDataContext/AffiliateContext';
import { IEngagementNavigationState } from 'src/common/types/interfaces/IEngagementNavigationState';

export const FAQuickView = (props: {showCalcInProgressBanner: boolean}) =>
{
	const affiliateContext = useContext(AffiliateContext);

	const [engagementNavigationState, setEngagementNavigationState] = useState<
    IEngagementNavigationState | undefined
  >(undefined);

	const faNameRef = useRef<HTMLDivElement>(null);

	const [showToolTips, setShowToolTips] = useState<Boolean>(false);

	const { t } = useTranslation(
		'sharedComponents',
		{
			keyPrefix: 'faQuickView'
		}
	);

	const checkEllipsis = () =>
	{
		const div = faNameRef.current;
		if (div)
		{
			const isEllipsisShown = div.scrollWidth > div.clientWidth;
			setShowToolTips(isEllipsisShown);
		}
	};

	useEffect(() =>
	{
		if (affiliateContext)
		{
			const engagementNavigationState: IEngagementNavigationState = {
				clientId: affiliateContext.engagementDetail.clientId,
				clientName: affiliateContext.clients.find((c) => c.clientId===affiliateContext.engagementDetail.clientId)?.clientName,
				engagementId: affiliateContext.engagementDetail.id,
				engagementName: affiliateContext.engagementDetail.name,
				foreignAffiliateId: affiliateContext.affiliateDetail.affiliateId,
				foreignAffiliateName: affiliateContext.affiliateDetail.name
			};
			setEngagementNavigationState(engagementNavigationState);
		}
	},[affiliateContext]);

	useEffect(() =>
	{
		window.addEventListener('resize', checkEllipsis);

		return () =>
		{
			window.removeEventListener('resize', checkEllipsis);
		};
	}, []);

	useEffect(() =>
	{
		setTimeout(() =>
		{
			checkEllipsis();
		}, 1000);
	});

	return (<>
		{props.showCalcInProgressBanner && <div className="eng-calc-in-progress-banner">
			<div className="centered"><span className="Appkit4-icon icon-circle-warning-outline"/>{t('calcInProgressBannerText')}</div>
		</div>}
		<div className="fa-quick-view">
			<div>
				{' '}
				<BreadCrumbs state={engagementNavigationState}></BreadCrumbs>
			</div>
			{affiliateContext&&
			<div className="fa-view">
				<div className="fa-title">
					<div className="tooltip-field">
						{showToolTips && (
							<Tooltip
								target="fa-quick-view-name"
								trigger="hover"
								position="bottom-left"
								className="fa-summary-tooltipDesc"
								appendAfterTarget={false}
								distance={4}
								content={affiliateContext.affiliateDetail.name}
							>
								<div ref={faNameRef} className="name" id="fa-quick-view-name">
									{affiliateContext.affiliateDetail.name}
								</div>
							</Tooltip>
						)}
						{!showToolTips && (
							<div ref={faNameRef} className="name" id="fa-quick-view-name">
								{affiliateContext.affiliateDetail.name}
							</div>
						)}
					</div>
					<div className="status">
						{/* <Badge value={'20% Completed'} type={'warning-outlined'} />{' '} */}
					</div>
				</div>
				<div className="fa-detail">
					<div className="fa-detail-field">
						<div className="left-title">{t('dateCorpBecameFA')}</div>
						<div className="right-title">
							{dateFormatter.format(
								dateFormatter.utcToEst(affiliateContext.affiliateDetail.dateBecameFa),
								'MMM DD, yyyy'
							)}
						</div>
					</div>
					<div className="fa-detail-field">
						<div className="left-title">{t('analysisStartDate')}</div>
						<div className="right-title">
							{dateFormatter.format(
								dateFormatter.utcToEst(affiliateContext.affiliateDetail.analysisStartDate),
								'MMM DD, yyyy'
							)}
						</div>
					</div>
					<div className="fa-detail-field">
						<div className="left-title">{t('analysisEndDate')}</div>
						<div className="right-title">
							{dateFormatter.format(
								affiliateContext.affiliateDetail.analysisEndDate?
									dateFormatter.utcToEst(affiliateContext.affiliateDetail.analysisEndDate):
									affiliateContext.engagementDetail.lastCalculationTaxationYearEnd,
								'MMM DD, yyyy'
							)}
						</div>
					</div>
					<div className="fa-detail-field">
						<div className="left-title">{t('reportingTaxpayer')}</div>
						<div className="right-title">
							{affiliateContext.taxpayers?.find((t) => t.id === affiliateContext.engagementDetail.taxPayerId)?.name}
						</div>
					</div>
				</div>
			</div>
			}
		</div>
	</>
	);
};
