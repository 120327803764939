export enum RelationshipFieldEnum {
    faId='faId',
    faName='faName',
    relationshipId='relationshipId',
    engagementId='engagementId',
    ownerAffiliateId='ownerAffiliateId',
    ownerName='ownerName',
    equityClassId='equityClassId',
    equityClass='equityClass',
    equityPercentage='equityPercentage',
    SEP='SEP',
    asOfDate='asOfDate',
    asOfTime='asOfTime',
    effectiveDate='effectiveDate',
    changeReason='changeReason',
	survivorAffiliateId='survivorAffiliateId',
    survivorAffiliateName='survivorAffiliateName',
    notes='notes',
    primary='primary',
    selected='selected',
    editable='editable',
    inEdit='inEdit',
    action='action',
    expanded='expanded',
    dirty='dirty',
}