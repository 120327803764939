export enum AdjustedCostBaseFields {

	id = 'id',
	engagementId = 'engagementId',
	affiliateId = 'affiliateId',
	effectiveDate = 'effectiveDate',
	date = 'date',
	time = 'time',
	isAddition = 'isAddition',
	transactionTypeId = 'transactionTypeId',
	description = 'description',
	shareholderId = 'shareholderId',
	shareholderName = 'shareholderName',
	equityClassId = 'equityClassId',
	currencyId = 'currencyId',
	electionsAmount = 'electionsAmount',
	shareholderEquityPercentage = 'shareholderEquityPercentage',
	amount = 'amount',
	foreignExchangeRate = 'foreignExchangeRate',
	isPartnershipOwned = 'isPartnershipOwned',
	partnershipName = 'partnershipName',
	partnershipCurrencyId = 'partnershipCurrencyId',
	partnershipsEquityPercentage = 'partnershipsEquityPercentage',
	noOfSharesAcquiredOrDisposed = 'noOfSharesAcquiredOrDisposed',
	notes = 'notes',
	isAutomaticEntry = 'isAutomaticEntry'
}