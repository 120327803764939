import { Switch } from '@appkit4/react-components';
import { ITableTopHeaderProps } from './ITableTopHeaderProps';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './TableTopHeader.scss';

export function TableTopHeader(props: ITableTopHeaderProps): JSX.Element
{
	const [displayNotes, setDisplayNotes] = useState(false);

	const { t } = useTranslation(
		'common',
		{
			keyPrefix: 'columnarTableTopHeader'
		}
	);

	const onNotesToggle = (value: boolean) =>
	{
		setDisplayNotes(!!value);

		if (!!props.onToggleNotes)
		{
			props.onToggleNotes(!!value);
		}
	};

	return <>
		<div className={'table-top-header'}>
			<div className={'left'}>
				<div className={'title'}>
					{
						props.title
					}
				</div>
				{
					(!!props.hasNotesToggle || !!props.hasHeaderNotes) &&
					!!props.hasData &&
					<div className={'toggle'}>
						<Switch
							className={'switch-label'}
							checked={!!displayNotes}
							disabled={!!props.isLoading || !!props.isDisabled}
							onChange={onNotesToggle}
						>
							{
								t('toggleNotesLabel')
							}
						</Switch>
					</div>
				}
			</div>
			<div>
				{
					!!props.onRenderFilters &&
					props.onRenderFilters()
				}
			</div>
		</div>
	</>;
}