import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { TAuthConfig } from './features/authentication/Types';
import { AuthProvider } from './features/authentication/AuthContext';
import store from './store/store';

const container = document.getElementById('root')!;
const root = createRoot(container);

const authConfig: TAuthConfig = {
	clientId: process.env.REACT_APP_CLIENT_ID || '',
	authorizationEndpoint: process.env.REACT_APP_AUTHORITY || '',
	tokenEndpoint: process.env.REACT_APP_TOKEN || '',
	redirectUri: process.env.REACT_APP_REDIRECTURI || '',
	scope: process.env.REACT_APP_SCOPE || '',
	autoLogin: false,
	storage: 'session'
};

root.render(
	// <React.StrictMode>
	<AuthProvider authConfig={authConfig}>
		<Provider store={store}>
			<App />
		</Provider>
	</AuthProvider>
	// </React.StrictMode>
);

reportWebVitals();
