import { useEffect, useState } from 'react';
import { IPropertyIncomeAggregateRowsProps } from './IPropertyIncomeAggregateRowsProps';
import { PropertyIncomeTypes } from 'src/common/types/enums/PropertyIncomeTypes';
import { IPropertyIncomeRow } from 'src/common/types/interfaces/IPropertyIncome';
import { Utils } from 'src/common/utils/utils';

import './PropertyIncomeAggregateRows.scss';
import { useTranslation } from 'react-i18next';

interface ISummationBase {
	fapi: number | undefined;
	deemedAbi: number | undefined;
	abi: number |undefined;
}

const summationBase: ISummationBase = {
	fapi: undefined,
	deemedAbi: undefined,
	abi: undefined
};

export function PropertyIncomeAggregateRows(props: IPropertyIncomeAggregateRowsProps): JSX.Element
{
	const [summationByYear, setSummationByYear] = useState<ISummationBase[]>([]);

	const { t } = useTranslation(
		'common',
		{
			keyPrefix: 'propertyIncomeAggregateRows'
		}
	);

	const sumRecordsByYear = (records: IPropertyIncomeRow[][]): ISummationBase[] =>
	{
		const sumsByYear: ISummationBase[] = [];

		if (!!records && !!records.length)
		{
			// iterate each and sum all records based on array position
			// index in row records represents years ordered
			for (const row of records)
			{
				if (!!row.length)
				{
					for (let i = 0; i < row.length; i++)
					{
						if ((sumsByYear.length - 1) < i)
						{
							sumsByYear.push({
								...summationBase
							});
						}

						const amount: number = !!Utils.isValidNumber(`${row[i].amount}`) ?
						row[i].amount as number :
							0;

						if (row[i].typeId === PropertyIncomeTypes.FAPI)
						{
							sumsByYear[i].fapi = Utils.areAllNullOrUndefined(row[i].amount,sumsByYear[i].fapi) ?
								undefined : Utils.add(amount,sumsByYear[i].fapi);
						}
						else if (row[i].typeId === PropertyIncomeTypes.DeemedABI)
						{
							sumsByYear[i].deemedAbi = Utils.areAllNullOrUndefined(row[i].amount,sumsByYear[i].deemedAbi) ?
							 undefined : Utils.add(amount,sumsByYear[i].deemedAbi);
						}
						else if (row[i].typeId === PropertyIncomeTypes.ABI)
						{
							sumsByYear[i].abi = Utils.areAllNullOrUndefined(row[i].amount,sumsByYear[i].abi) ?
								undefined : Utils.add(amount,sumsByYear[i].abi);
						}
					}
				}
			}
		}
		else
		{
			const years = !!props.allYearData.length ?
				props.allYearData.map((d) => d.faYear) :
				[];

			if (!!years && !!years.length)
			{
				// create dummy data for each year to render 0 for all values as there is no data
				years.forEach((y) =>
				{
					sumsByYear.push({
						...summationBase
					});
				});
			}
		}

		return sumsByYear;
	};

	useEffect(
		() =>
		{
			if (
				!!props.income &&
				!!props.expenses &&
				!!props.adjustments
			)
			{
				const sumsByYear: ISummationBase[] = sumRecordsByYear(
					props.income
						.concat(
							props.expenses,
							props.adjustments
						)
				);

				setSummationByYear(sumsByYear);
			}
		},
		[
			props.income,
			props.expenses,
			props.adjustments
		]
	);

	return <div
		ref={props.customRef}
		className={'property-income-aggregate-rows table-scroll-container'}
		style={{
			visibility: props.isLoading ? 'hidden' : 'visible'
		}}
	>
		<table id={props.id}>
			<tbody>
				<tr>
					<td className={'first-column'}>
						{
							t('fapiMessage')
						}
					</td>
					{
						!!summationByYear &&
						summationByYear
							.map((s) =>
							{
								return <>
									<td className={`content-column ${!!props.displayNotes ? 'has-notes' : ''}`}>
										{
											s.fapi === undefined ? '':
												Utils.formatCurrency(`${s.fapi}`, 2)
										}
									</td>
								</>;
							})
					}
				</tr>
				<tr>
					<td className={'first-column'}>
						{
							t('deemedAbi')
						}
					</td>
					{
						!!summationByYear &&
						summationByYear
							.map((s) =>
							{
								return <>
									<td className={`content-column ${!!props.displayNotes ? 'has-notes' : ''}`}>
										{
											s.deemedAbi === undefined ? '':
												Utils.formatCurrency(`${s.deemedAbi}`, 2)
										}
									</td>
								</>;
							})
					}
				</tr>
				<tr>
					<td className={'first-column'}>
						{
							t('abi')
						}
					</td>
					{
						!!summationByYear &&
						summationByYear
							.map((s) =>
							{
								return <>
									<td className={`content-column ${!!props.displayNotes ? 'has-notes' : ''}`}>
										{
											s.abi === undefined ? '':
												Utils.formatCurrency(`${s.abi}`, 2)
										}
									</td>
								</>;
							})
					}
				</tr>
			</tbody>
		</table>
	</div>;
}