import { Tab, Tabs } from '@appkit4/react-components';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate} from 'react-router-dom';
import BreadCrumbs from '../BreadCrumbs/BreadCrumb';
import './EngagementInfo.scss';
import { IEngagementNavigationState } from 'src/common/types/interfaces/IEngagementNavigationState';
import { UnderConstruction } from 'src/common/components/UnderConstruction/UnderConstruction';
import { useTranslation } from 'react-i18next';
import { engagementService } from '../../services/engagementService';
import { ForeginAffiliates } from './ForeignAffiliates/ForeignAffiliates';
import { EngagementOverview } from './EngagementOverview/EngagementOverview';
import EngagementRelationships from './EngagementRelationships/EngagementRelationships';
import { ImportExportFiles } from './ImportExport/ImportExportFiles';
import { EngagementOverviewEntity, IEngagementOverview } from '../../models/IEngagementOverview';
import { IForeignAffiliate } from 'src/features/engagement/models/IForeignAffiliate';
import { TaxPayerReport } from '../Report/TaxPayerReport/TaxPayerReport';
import { CalculationStatus } from 'src/common/types/enums/CalculationStatus';
import { ICalculationHistory } from 'src/features/engagement/models/ICalculationHistory';
import { RootState } from 'src/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { clearSurplusCalculationNotification } from 'src/store/reducers/notificationSlice';
import ManageEngagementMembers from './ManageEngagementMembers/EngagementMembers';
import { EngagementTotalChangeSubject } from 'src/core/services/EventBus';
import { EngagementPersonalizationStatus } from 'src/core/types/enums/EngagementPersonalizationStatus';
import { Downloads } from 'src/core/components/Dashboard/DownloadsList';
import { HttpStatusCode } from 'axios';

export const EngagementInfo = () =>
{
	const { t } = useTranslation(
		'homeDashboard',
		{
			keyPrefix: 'enagementDashboard'
		}
	);

	const [engagementNavigationState, setEngagementNavigationState] = useState<
		IEngagementNavigationState | undefined
	>(undefined);

	const { engagementId } = useParams();
	const [engagementOverview, setEngagementOverview] = useState<IEngagementOverview | undefined>(undefined);
	const [foreignAffiliates, setForeignAffiliates] = useState<IForeignAffiliate[] | undefined>(undefined);
	const [requireFAData, setRequireFAData] = useState<boolean>(false);

	const [selectedTabValue, setSelectedTabValue] = useState<string>('FA');
	const [showCalcInProgressBanner, setShowCalcInProgressBanner] = useState<boolean>(false);
	const [calculationHistory, setCalculationHistory] = useState<ICalculationHistory>();
	const [downloadsCount, setDownloadsCount] = React.useState<number>(0);
	const surplusCalculationNotification = useSelector((state: RootState) => state.notifications.surplusCalculationNotification);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const fetchEngagementContext = async (engagementId: number) =>
	{
		if (engagementId)
		{
			const engagementResponse = await engagementService.getEngagementOverviewById(engagementId)
				.catch((err) =>
				{
					if (!!err && !!err.response && !!err.response.status)
					{
						switch (err.response.status)
						{
						case HttpStatusCode.NotFound:
							navigate('/resource-not-found');
							break;
						case HttpStatusCode.Forbidden:
							navigate('/user-access-denied');
							break;
						}
					}
				});

			const fasResponse = await engagementService.getForeignAffiliatesByEngagementId(engagementId)
				.catch((err) =>
				{
					if (!!err && !!err.response && err.response.status ===  HttpStatusCode.Forbidden)
					{
						navigate('/user-access-denied');
					}
				});

			setForeignAffiliates(!!fasResponse && !!fasResponse.data && !!fasResponse.data.result ? fasResponse.data.result : []);

			if (engagementResponse)
			{
				const engagementOverview = EngagementOverviewEntity.parseFields(engagementResponse.data.result);
				setEngagementOverview(engagementOverview);

				const engagementNavigationState: IEngagementNavigationState = {
					clientId: engagementOverview.clientId,
					clientName: engagementOverview.clientName,
					engagementId: engagementOverview.id,
					engagementName: engagementOverview.name,
					foreignAffiliateId: undefined,
					foreignAffiliateName: undefined
				};
				setEngagementNavigationState(engagementNavigationState);
			}
		}
	};

	useEffect(() =>
	{
		if (engagementId)
		{
			fetchEngagementContext(+engagementId);
		}
	}, [engagementId]);

	useEffect(() =>
	{
		if (!!surplusCalculationNotification)
		{
			if (!!surplusCalculationNotification[CalculationStatus.SurplusCalculationHistory])
			{
				const surplusCalculationHistory = surplusCalculationNotification[CalculationStatus.SurplusCalculationHistory];
				setCalculationHistory(surplusCalculationHistory);

				const status = surplusCalculationHistory[CalculationStatus.Status];

				if (status === CalculationStatus.Processed || status === CalculationStatus.Failed)
				{
					setShowCalcInProgressBanner(false);
				}
				else if (status === CalculationStatus.NotProcessed)
				{
					setShowCalcInProgressBanner(true);
				}

				dispatch(clearSurplusCalculationNotification());
			}
		}
	}, [surplusCalculationNotification]);

	useEffect(() =>
	{
		const subscription = EngagementTotalChangeSubject.subscribe((e: any) =>
		{
			if (e.eventType === EngagementPersonalizationStatus.Downloads)
			{
				setDownloadsCount(e.payload);
			}
		});

		return () =>
		{
			subscription.unsubscribe();
		};
	}, []);

	const [activeIndex, setactiveIndex] = React.useState(0);
	const onTabChange = (i: number, value: string) =>
	{
		setactiveIndex(i);
		setSelectedTabValue(value);
	};

	const reloadEngagement = (engagementId: number) =>
	{
		fetchEngagementContext(engagementId);
	};

	return (<>
		{showCalcInProgressBanner && <div className="eng-calc-in-progress-banner">
			<div className="centered"><span className="Appkit4-icon icon-circle-warning-outline" />{t('calcInProgressBannerText')}</div>
		</div>}
		<div className="eng-info-wrapper">
			<div className="eng-info-breadcrumbs">
				<BreadCrumbs state={engagementNavigationState}></BreadCrumbs>
				<div className='header'>
					<div className="eng-name">
						<span>{engagementNavigationState?.engagementName}</span>
					</div>
				</div>
			</div>
			<Tabs
				type="underline"
				activeIndex={activeIndex}
				onTabChange={onTabChange}
			>
				<Tab label={t('foreignAffiliates')} value="FA">
					{Number(engagementId) &&
						<ForeginAffiliates
							clientId={engagementOverview?.clientId}
							engagementId={Number(engagementId)}
							engagementDetail={engagementOverview}
							foreignAffiliates={foreignAffiliates}
							onRequireFAData={(value: boolean) => setRequireFAData(value)}
						></ForeginAffiliates>
					}
				</Tab>
				<Tab label={t('relationships')} value="RS" disabled={requireFAData}>
					{!!Number(engagementId) && !!engagementOverview && (activeIndex === 1) &&
						<EngagementRelationships engagementId={Number(engagementId)} engagementOverview={engagementOverview} />
					}
				</Tab>
				<Tab
					label={t('taxpayerReport')}
					value={'TR'}
					disabled={requireFAData}
				>
					{
						!!selectedTabValue &&
						selectedTabValue === 'TR' &&
						<TaxPayerReport
							engagementOverview={engagementOverview}
							setShowBanner={setShowCalcInProgressBanner}
							calcHistory={calculationHistory}
							onRefreshReport={reloadEngagement}
						/>
					}
				</Tab>
				<Tab label={t('corporateStructure')} value="CS" disabled={requireFAData}>
					<UnderConstruction>
						<div>{t('corporateStructure')}</div>
					</UnderConstruction>
				</Tab>
				<Tab label={t('engagementOverview')} value="EO">
					{!!engagementNavigationState && !!engagementOverview &&
						<EngagementOverview engagementOverview={engagementOverview} onEnagementChanged={reloadEngagement} />
					}
				</Tab>
				<Tab label={t('members')} value="MS">
					{!!Number(engagementId) && !!engagementOverview && (activeIndex === 5) &&
						<ManageEngagementMembers engagementId={Number(engagementId)} engagementOverview={engagementOverview} />
					}
				</Tab>
				<Tab label={`${t('downloads')} (${downloadsCount})`} value="DF" disabled={requireFAData}>
					{Number(engagementId) && <Downloads
					 	engagementPersonalizationStatus ={EngagementPersonalizationStatus.Downloads}
						 activeIndex={activeIndex}
						 engagementId={Number(engagementId)}
					/>}
				</Tab>
				<Tab label={t('bulk-import')} value="IE" disabled={requireFAData}>
					{!!Number(engagementId) && (activeIndex === 7) &&
						<ImportExportFiles engagementId={Number(engagementId)} foreignAffiliates={foreignAffiliates} />
					}
				</Tab>
			</Tabs>
		</div>
	</>
	);
};
