import React, { useEffect, useState } from 'react';
import {
	Button,
	Input,
	Modal,
	Select,
	TextArea,
	TimePicker
} from '@appkit4/react-components';
import { useTranslation } from 'react-i18next';
import './AcquisitionDisposition.scss';
import { UnsavedChangesModal } from 'src/core/components/Dashboard/unsavedModal/UnsavedChangesModal';
import useDisableBodyScroll from 'src/common/hooks/useDisableBodyScroll';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { IAcquisitionDispositionModalProps } from './IAcquisitionDispositionModalProps';
import moment from 'moment';
import { isEqual, cloneDeep } from 'lodash';
import { SelectValue } from '@appkit4/react-components/esm/select/Select';
import { useAppSelector } from 'src/store/hooks';
import { RegularPatternEnum } from 'src/common/types/enums/RegularPatternEnum';
import { INameId } from 'src/common/types/interfaces/INameId';
import { Utils } from 'src/common/utils/utils';
import { Constants } from 'src/common/types/constants';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import { IRelationship } from 'src/features/engagement/models/IRelationship';
import { ChangeReasons } from 'src/common/types/enums/ChangeReasons';
import { UnexpectedErrorModal } from 'src/common/components/UnexpectedErrorModal/UnexpectedErrorModal';
import { IAffiliateOwner } from 'src/features/engagement/models/IAffiliateOwner';
import { CalendarPickerWrapper } from 'src/common/components/CalendarPickerWrapper/CalendarPickerWrapper';

interface AcquisitionDispositionForm {
	faName: string;
	equityClassId: number | undefined;
	equityPercentage: string | number | undefined;
	sep: string | number | undefined;
	sellerAffiliateId: number | undefined;
	purchaserAffiliateId: number | undefined;
	consideration: string | number | undefined;
	currencyId: number | undefined;
	asOfDate: Date | undefined;
	asOfTime: Date | undefined;
	notes: string | undefined;
}

const startingFormState: AcquisitionDispositionForm = {
	faName: '',
	equityClassId: undefined,
	equityPercentage: '',
	sep: '',
	sellerAffiliateId: undefined,
	purchaserAffiliateId: undefined,
	consideration: 0,
	currencyId: undefined,
	asOfDate: undefined,
	asOfTime: moment().startOf('day')
		.add(1, 'minutes')
		.toDate(),
	notes: undefined
};

const defaultFormError: Record<string, string | null> = {
	faName: null,
	equityClassId: null,
	equityPercentage: null,
	sep: null,
	sellerAffiliateId: null,
	purchaserAffiliateId: null,
	consideration: null,
	currencyId: null,
	asOfDate: null,
	asOfTime: null,
	notes: null
};

const fieldChange: Record<string, boolean> = {
	faName: false,
	equityClassId: false,
	equityPercentage: false,
	sep: false,
	sellerAffiliateId: false,
	purchaserAffiliateId: false,
	consideration: false,
	currencyId: false,
	asOfDate: false,
	asOfTime: false,
	notes: false
};


interface INameIdEffectiveDate {
	id: number;
	name: string;
	effectiveDate: Date|undefined;
}

const otherFA: INameIdEffectiveDate = {
	id: Constants.ID_OTHER,
	name: Constants.NAME_OTHER,
	effectiveDate: new Date(0)
};


export const AcquisitionDispositionModal = (props: IAcquisitionDispositionModalProps) =>
{
	useDisableBodyScroll(!!props.visible);

	const equityClasses = useAppSelector((state) => state.appSettings.equityClasses);
	const currencies = useAppSelector((state) => state.appSettings.currencies);

	const { t } = useTranslation(
		'engagementSetup',
		{
			keyPrefix: 'acquisitionDispositionModal'
		}
	);

	const { t: apiErrors } = useTranslation(
		'engagementSetup',
		{
			keyPrefix: 'acquisitionDispositionModal.remoteErrors'
		}
	);
	const { t: errorMessage } = useTranslation('errors');

	const [form, setForm] = useState<AcquisitionDispositionForm>(startingFormState);
	const [fieldChanges, setFieldChanges] = useState(fieldChange);
	const [initialFormState, setInitialFormState] = useState<AcquisitionDispositionForm>(startingFormState);
	const [showUnsavedModal, setShowUnsavedModal] = useState<boolean>(false);
	const [formError, setFormError] = useState<Record<string, any>>(defaultFormError);

	const [purchaserFAOptions, setPurchaserFAOptions] = useState<IAffiliateOwner[]>([]);
	const [purchaserFAOptionsAsOfDate, setPurchaserFAOptionsAsOfDate] = useState<IAffiliateOwner[]>([]);
	const [sellerFAOptions, setSellerFAOptions] = useState<INameIdEffectiveDate[]>([]);
	const [sellerFAOptionsAsOfDate, setSellerFAOptionsAsOfDate] = useState<INameIdEffectiveDate[]>([]);

	const [isSaving, setIsSaving] = useState(false);

	const [unexpectedErrorMessage, setUnexpectedErrorMessage] = useState('');

	const [allFARelationships, setAllFARelationships] = useState<IRelationship[]>([]);
	const [equityClassesAsOfDate, setEquityClassesAsOfDate] = useState<INameId[]>([]);
	const [maxDate, setMaxDate] = useState<Date|undefined>(undefined);

	useEffect(
		() =>
		{
			setAllFARelationships(cloneDeep(props.allRelationships));
		},
		[props.allRelationships]
	);

	useEffect(() =>
	{
		if (props.lastCalcYear&&props.affiliate.analysisEndDate&&props.affiliate.analysisEndDate<props.lastCalcYear)
		{
			setMaxDate(props.affiliate.analysisEndDate);
		}
		else
		{
			setMaxDate(props.lastCalcYear);
		}
	},[props.lastCalcYear, props.affiliate]);

	useEffect(
		() =>
		{
			if (
				!!props.visible &&
				!!props.selectedRelationship &&
				!!allFARelationships &&
				!!allFARelationships.length
			)
			{
				const currencyId = !!currencies && !!currencies.length && !!props.selectedRelationship.currencyId ?
					currencies.find((c) => c.id === props.selectedRelationship.currencyId)?.id :
					undefined;

				const initialForm: AcquisitionDispositionForm = {
					...startingFormState,
					faName: props.selectedRelationship.faName,
					currencyId: currencyId
				};

				const relationshipsByFA: Record<string, IRelationship[]> = {
				};

				for (const relationship of allFARelationships)
				{
					if (!!relationshipsByFA[relationship.faId])
					{
						relationshipsByFA[relationship.faId].push(relationship);
					}
					else
					{
						relationshipsByFA[relationship.faId] = [{
							...relationship
						}];
					}
				}

				const changedRelationships: IRelationship[] = [];

				// get those options that are relationships that have a change reason attached
				// so they can be excluded from the full list of affiliate owner options for purchasers
				for (const key in relationshipsByFA)
				{
					if (!!relationshipsByFA[key])
					{
						// check if any are merged or liquidated
						const hasChanged = relationshipsByFA[key]
							.some((r) =>
							{
								return r.changeReason === ChangeReasons.Merger ||
									r.changeReason === ChangeReasons.Liquidation;
							});

						if (hasChanged)
						{
							changedRelationships.push(...relationshipsByFA[key]);
						}
					}
				}


				// purchaser options
				// can be any FA excluding the selected FA being purchased/sold
				// and excluding any FA's that have been merged/liquidated
				setPurchaserFAOptions([
					{
						id: Constants.ID_OTHER,
						name: Constants.NAME_OTHER,
						isTaxPayer: false,
						dateBecameFa: undefined
					},
					...props.affiliateOwners
						.filter((item) =>

							item.id !== props.selectedRelationship.faId
						).map((item) =>
						{
							return {
								...item,
								name: `${item.name} ${item.isTaxPayer ? t('taxPayerSuffix') : ''}`
							};
						})
						.sort((a, b) =>
						{
							return a.name.localeCompare(b.name);
						})
				]);

				// seller options
				// can only be FA's that have ownership in the selected FA for sale/purchase
				const sellers = allFARelationships
					.filter((far) =>
					{
						return far.faId === props.selectedRelationship.faId;
					});

				const uniqueSellers = Utils
					.getUniqueRecords({
						key: 'ownerAffiliateId',
						items: sellers
					});

				const prioritizedNames = [
					Constants.NAME_OTHER
				];

				const getSuffix = (affiliateId: number): string =>
				{
					return props.affiliateOwners.some((ao) => ao.id === affiliateId && !!ao.isTaxPayer) ?
						t('taxPayerSuffix') :
						'';
				};

				setSellerFAOptions([
					...[
						otherFA,
						...uniqueSellers
							.map((far): INameIdEffectiveDate =>
							{
								return {
									id: far.ownerAffiliateId,
									name: `${far.ownerName} ${getSuffix(far.ownerAffiliateId)}`,
									effectiveDate: far.effectiveDate
								};
							})
					]
						.sort((a, b) =>
						{
							const nameIndexA = prioritizedNames.indexOf(a.name);
							const nameIndexB = prioritizedNames.indexOf(b.name);

							if (nameIndexA !== -1 && nameIndexB !== -1)
							{
								return nameIndexA - nameIndexB;
							}
							else if (nameIndexA !== -1)
							{
								return -1;
							}
							else if (nameIndexB !== -1)
							{
								return 1;
							}

							return a.name.localeCompare(b.name);
						})
				]);

				setInitialFormState({
					...initialForm
				});

				// set form state if any of these have changed in parent
				setForm({
					...initialForm
				});
			}
		},
		[
			props.selectedRelationship,
			allFARelationships,
			props.visible
		]
	);

	const UpdateDropdowns = (form: AcquisitionDispositionForm) =>
	{
		if (form.asOfDate)
		{
			const asOfDate = !!form.asOfDate && !!form?.asOfTime ?
				new Date(dateFormatter.estDateStringOverrideToUtc(
					dateFormatter.mergeDateTime(
						form?.asOfDate,
						form?.asOfTime
					)
				)!):
				form.asOfDate;


			const filteredSellers = sellerFAOptions.filter((s: INameIdEffectiveDate) =>
				s.effectiveDate===undefined|| dateFormatter.utcToEst(s.effectiveDate)!<new Date(asOfDate.toISOString()));

			setSellerFAOptionsAsOfDate([...filteredSellers]);

			//update purchased based on as of date time
			const filteredPurchasers = purchaserFAOptions.filter((s: IAffiliateOwner) => ((s.id!== form.sellerAffiliateId &&
					form.sellerAffiliateId !== Constants.ID_OTHER)||form.sellerAffiliateId === Constants.ID_OTHER)&&
				(s.dateBecameFa===undefined|| dateFormatter.utcToEst(s.dateBecameFa)!<new Date(asOfDate.toISOString())));

			setPurchaserFAOptionsAsOfDate([...filteredPurchasers]);

			//Update equityClasses

			const filteredEquityClasses = form.sellerAffiliateId === Constants.ID_OTHER ? equityClasses :
				 props.allRelationships.filter((r: IRelationship) => r.faId===props.selectedRelationship.faId&&
			r.ownerAffiliateId===form.sellerAffiliateId&&(r.effectiveDate&&
				dateFormatter.utcToEst(r.effectiveDate)!<new Date(asOfDate.toISOString())))
					.map((r: IRelationship) =>
			   {
				   return {
					   id: r.equityClassId,
					   name: r.equityClass
				   };
					});

				 setEquityClassesAsOfDate( [...Utils.getUniqueRecords<INameId>({
				key: 'id',
				items: filteredEquityClasses
			})]
			);

			//reset the dropdown value if it is not qualifed
			if (!filteredSellers.find((seller: INameIdEffectiveDate) => seller.id===form.sellerAffiliateId)||
				!filteredPurchasers.find((purchaser: IAffiliateOwner) => purchaser.id===form.purchaserAffiliateId)||
				!filteredEquityClasses.find((equityClass: INameId) => equityClass.id===form.equityClassId))
			   {
				   const updatedForm = {
					   ...form,
					   sellerAffiliateId: filteredSellers.find(
						   (seller: INameIdEffectiveDate) => seller.id===form.sellerAffiliateId)? form.sellerAffiliateId:undefined,
					equityClassId: filteredEquityClasses.find((equityClass: INameId) =>
						equityClass.id===form.equityClassId)? form.equityClassId: undefined,
					   purchaserAffiliateId: filteredPurchasers.find((purchaser: IAffiliateOwner) =>
						   purchaser.id===form.purchaserAffiliateId)? form.purchaserAffiliateId:undefined
				};

				   setForm(updatedForm);
			}
		}
	};


	const handleApiErrorResponse = (error: any) =>
	{
		const updatedErrors = Utils.processErrors({
			error,
			errorValidationRecords: {
				...defaultFormError
			},
			unexpectedErrorMessage: t('unexpectedErrorMessage'),
			translationService: apiErrors,
			explicitRulesCallback: (fieldError, validationRecords) =>
			{
				if (Utils.pascalToCamel(fieldError.Field) === 'effectiveDate')
				{
					validationRecords.asOfDate = apiErrors(`${fieldError.ErrorCode}`);
					validationRecords.asOfTime = apiErrors(`${fieldError.ErrorCode}`);
				}

				return validationRecords;
			}
		});

		if (typeof updatedErrors === 'string')
		{
			setUnexpectedErrorMessage(updatedErrors);
			setFormError({
				...defaultFormError
			});
		}
		else
		{
			const errors = error.response.data.Errors;
			if (errors)
			{
				errors.forEach((error: any) =>
				{
					if (error.ErrorCode === 'AFFILIATE_RELATIONSHIP_CIRCULAR_REFERENCE')
					{
						updatedErrors['purchaserAffiliateId'] = errorMessage(
							`code.${error.ErrorCode}`
						);
					}
				});
			}
			setFormError({
				...updatedErrors
			});
		}

		fireAllFieldValidations();
	};

	const closeUnexpectedErrorModal = () =>
	{
		setUnexpectedErrorMessage('');
	};

	const fireAllFieldValidations = () =>
	{
		// update all fields to true to fire validation if it hasn't already
		const fields = {
			...fieldChange
		};

		for (const key in fields)
		{
			if (!!key)
			{
				fields[key] = true;
			}
		}

		setFieldChanges({
			...fields
		});
	};

	const onSave = async () =>
	{
		if (!!checkUpdateFormValidity(form))
		{
			setIsSaving(true);

			try
			{
				const payload = {
					engagementId: props.engagementId!,
					selectedAffiliateId: props.selectedRelationship.faId,
					equityClassId: form.equityClassId,
					equityPercentage: !!form.equityPercentage ?
						Number(form.equityPercentage) :
						undefined,
					sep: !!form.sep ?
						Number(form.sep) :
						undefined,
					sellerAffiliateId: form.sellerAffiliateId !== otherFA.id ?
						form.sellerAffiliateId :
						Constants.ID_OTHER,
					purchaserAffiliateId: form.purchaserAffiliateId !== otherFA.id ?
						form.purchaserAffiliateId :
						Constants.ID_OTHER,
					consideration: !!form.consideration ?
						Number(form.consideration) :
						0,
					currencyId: form.currencyId,
					notes: form.notes,
					effectiveDate: dateFormatter.estDateStringOverrideToUtc(
						dateFormatter.mergeDateTime(
							form.asOfDate,
							form.asOfTime
						)
					)
				};

				const res = await engagementService
					.addAcquisitionDisposition(payload);

				const fas: number[] = !!res.data && !!res.data.newAffiliateRelationships && res.data.newAffiliateRelationships.length ?
					res.data.newAffiliateRelationships :
					[];

				props.onSuccess(fas);
			}
			catch (error)
			{
				handleApiErrorResponse(error);
			}
			finally
			{
				setIsSaving(false);
			}
		}
		else
		{
			fireAllFieldValidations();
		}
	};

	const onCancel = () =>
	{
		if (!!hasChanges())
		{
			setShowUnsavedModal(true);
		}
		else
		{
			setFormError(defaultFormError);
			props.onUpdateVisibility(false);
		}
	};

	const hasChanges = () =>
	{
		// always validate against original state of form
		return !isEqual(form, initialFormState);
	};

	const closeUnsavedModal = (str: string) =>
	{
		if (str === 'stay')
		{
			setShowUnsavedModal(false);
		}
		else if (str === 'leave')
		{
			setShowUnsavedModal(false);
			setFormError(defaultFormError);
			props.onUpdateVisibility(false);
		}
	};

	const onValueChange = (field: string, value: any) =>
	{
		const updatedForm = {
			...form,
			[field]: value
		};

		setFieldChanges({
			...fieldChanges,
			[field]: true
		});

		checkUpdateFormValidity(updatedForm);

		setForm(updatedForm);

		if (field==='asOfDate'||field==='asOfTime'||field==='sellerAffiliateId')
		{
			UpdateDropdowns(updatedForm);
		}
	};

	const isEquityClassIncludedInSellerList = (equityClassId: number): boolean =>
	{
		return allFARelationships
			.some((far) =>
			{
				return far.equityClassId === equityClassId &&
					far.faId === props.selectedRelationship.faId;
			});
	};

	const onSortByEffectiveDate = (a: IRelationship, b: IRelationship): number =>
	{
		const dateA = moment(a.effectiveDate);
		const dateB = moment(b.effectiveDate);

		if (dateA.isBefore(dateB))
		{
			return 1;
		}
		else if (dateA.isAfter(dateB))
		{
			return -1;
		}

		return 0;
	};

	const getSellersByEquityClass = (equityClassId: number): IRelationship[] =>
	{
		return allFARelationships
			.filter((far) =>
			{
				return far.equityClassId === equityClassId &&
					far.faId === props.selectedRelationship.faId;
			})
			// sort by effective date to ensure latest record gets found and pulled first
			.sort(onSortByEffectiveDate);
	};

	const getTotalEquityByClass = (equityClassId: number): number =>
	{
		return Utils
			.getUniqueRecords({
				key: 'ownerAffiliateId',
				items: getSellersByEquityClass(equityClassId)
			})
			.reduce((acc, item) =>
			{
				return acc + (!!item.equityPercentage ? Number(item.equityPercentage) : 0);
			}, 0);
	};

	const checkUpdateFormValidity = (form: AcquisitionDispositionForm): boolean =>
	{
		const errors = {
			...defaultFormError
		};

		const sortedFAs = allFARelationships
			.sort(onSortByEffectiveDate);

		const seller = !!form.sellerAffiliateId ?
			sortedFAs.find((far) =>
			{
				return far.faId === props.selectedRelationship.faId &&
					far.ownerAffiliateId === form.sellerAffiliateId;
			}) :
			undefined;

		const sellerWithEquityClass = !!form.sellerAffiliateId && !!form.equityClassId ?
			sortedFAs.find((far) =>
			{
				return far.faId === props.selectedRelationship.faId &&
					far.ownerAffiliateId === form.sellerAffiliateId &&
					far.equityClassId === form.equityClassId;
			}) :
			undefined;

		const purchaser = !!form.purchaserAffiliateId && !!form.equityClassId ?
			sortedFAs.find((far) =>
			{
				return far.faId === props.selectedRelationship.faId &&
					far.ownerAffiliateId === form.purchaserAffiliateId &&
					far.equityClassId === form.equityClassId;
			}) :
			undefined;

		// only populate if there is not a valid FA seller selected already
		const otherSeller = !!form.sellerAffiliateId && !seller && form.sellerAffiliateId === Constants.ID_OTHER ?
			otherFA :
			undefined;

		// equity class
		if (!form.equityClassId)
		{
			errors.equityClassId = t('equityClassRequiredErrorMessage');
		}
		else
		{
			// additional validations to see if equity class selected is valid based on selected seller
			if (!!seller && !sellerWithEquityClass)
			{
				errors.equityClassId = t('equityClassSellerMismatchErrorMessage');
			}
			// check if seller selection is Other, if so, check if there is any allocation to selected equity class
			// or if not in list of sellers then allow to proceed as separate equity class is allowable if no pre-existing seller
			else if (
				!!otherSeller &&
				!!isEquityClassIncludedInSellerList(form.equityClassId) &&
				getTotalEquityByClass(form.equityClassId) === 100
			)
			{
				errors.equityClassId = t('equityClassNoOtherAllocationErrorMessage');
			}
		}

		// equity %
		if (!form.equityPercentage)
		{
			errors.equityPercentage = t('equityPercentageRequiredErrorMessage');
		}
		else
		{
			// check if equity percentage exceeds 100%
			if (Number(form.equityPercentage) > 100)
			{
				errors.equityPercentage = t('equityPercentageExceeds100ErrorMessage');
			}
			else if (Number(form.equityPercentage) === 0 &&
			(form.sep !== 0 && Number(form.sep!) > 0 ))
			{
				errors.sep = t('nilSEP');;
			}
			// check if equity percentage is valid based on seller equity holdings
			else if (!!sellerWithEquityClass)
			{
				const sellerEquityPercentage = !!sellerWithEquityClass && !!sellerWithEquityClass?.equityPercentage ?
					sellerWithEquityClass.equityPercentage :
					0;

				if (Number(form.equityPercentage) > sellerEquityPercentage)
				{
					errors.equityPercentage = t('equityPercentageExceedsSellerEquityErrorMessage');
				}
			}
			// If seller is Other, any % can be entered.
		}

		// sep
		if (!form.sep)
		{
			errors.sep = t('sepRequiredErrorMessage');
		}
		else
		{
			// check if sep exceeds 100%
			if (Number(form.sep) > 100)
			{
				errors.sep = t('sepExceeds100ErrorMessage');
			}
			// check if sep is valid based on seller equity holdings
			else if (!!sellerWithEquityClass)
			{
				const sellerSEP = !!sellerWithEquityClass && !!sellerWithEquityClass.SEP ?
					sellerWithEquityClass.SEP :
					0;

				if (Number(form.sep) > sellerSEP)
				{
					errors.sep = t('sepExceedsSellerEquityErrorMessage');
				}
			}
			//If seller is Other, any % can be entered (not exceed 100).
		}

		// vendor/seller
		if (!form.sellerAffiliateId)
		{
			errors.sellerAffiliateId = t('vendorSellerRequiredErrorMessage');
		}
		else
		{
			// check for purchaser/seller overlap
			if (!!form.purchaserAffiliateId && form.purchaserAffiliateId === form.sellerAffiliateId)
			{
				errors.sellerAffiliateId = t('sellerPurchaserOverlapErrorMessage');
			}
		}

		// purchaser/acquiror
		if (!form.purchaserAffiliateId)
		{
			errors.purchaserAffiliateId = t('purchaserAcquirorRequiredErrorMessage');
		}
		else
		{
			// check for purchaser/seller overlap
			if (!!form.sellerAffiliateId && form.sellerAffiliateId === form.purchaserAffiliateId)
			{
				errors.purchaserAffiliateId = t('sellerPurchaserOverlapErrorMessage');
			}
		}

		// currency
		if (!form.currencyId)
		{
			errors.currencyId = t('currencyRequiredErrorMessage');
		}

		// as of date
		if (!form.asOfDate)
		{
			errors.asOfDate = t('asOfDateRequiredErrorMessage');
		}

		// as of time
		if (!form.asOfTime)
		{
			errors.asOfTime = t('asOfTimeRequiredErrorMessage');
		}

		// ensure date/time selected exceeds the latest effective date for seller/purchaser,
		// equity class (not needed in case of 'Other' seller)
		if (
			!!form.asOfDate &&
			!!form.asOfTime &&
			(!!sellerWithEquityClass || !!purchaser) &&
			!!form.equityClassId
		)
		{
			const formEffectiveDate = dateFormatter.utcToEst(
				dateFormatter.estDateStringOverrideToUtc(
					dateFormatter.mergeDateTime(
						form.asOfDate,
						form.asOfTime
					)
				)
			);

			// check if effective date selected is greater than latest seller effective date
			if (!!sellerWithEquityClass)
			{
				const sellerLatestEffectiveDate = dateFormatter.utcToEst(sellerWithEquityClass.effectiveDate);

				if (moment(formEffectiveDate).isSameOrBefore(moment(sellerLatestEffectiveDate)))
				{
					errors.asOfDate = t('asOfDateInvalidEffectiveDateSellerErrorMessage');
					errors.asOfTime = t('asOfTimeInvalidEffectiveDateSellerErrorMessage');
				}
			}
			// check if effective date selected is greater than latest purchaser effective date
			else if (!!purchaser)
			{
				const purchaserLatestEffectiveDate = dateFormatter.utcToEst(purchaser.effectiveDate);

				if (moment(formEffectiveDate).isSameOrBefore(moment(purchaserLatestEffectiveDate)))
				{
					errors.asOfDate = t('asOfDateInvalidEffectiveDatePurchaserErrorMessage');
					errors.asOfTime = t('asOfTimeInvalidEffectiveDatePurchaserErrorMessage');
				}
			}
		}

		setFormError({
			...errors
		});

		// check if any fields have error messages and return status
		return Object.values(errors).every((e) => !e);
	};

	return <>
		{
			!!props.visible &&
			!!props.selectedRelationship &&
			!!allFARelationships &&
			!!allFARelationships.length &&
			<>
				<Modal
					className={'acquisition-disposition-modal'}
					visible={props.visible}
					initialFocus={false}
					closeOnPressEscape={false}
					maskCloseable={false}
					title={t('title') || ''}
					onCancel={onCancel}
					footer={
						<div className={'footer-wrapper'}>
							<Button
								kind={'secondary'}
								disabled={isSaving}
								onClick={onCancel}
							>
								{t('cancelButtonText')}
							</Button>
							<Button
								kind={'primary'}
								disabled={isSaving}
								onClick={onSave}
							>
								{t('saveButtonText')}
							</Button>
						</div>
					}
				>
					<div className={'acquisition-disposition-modal-body'}>
						<div className={'acquisition-disposition-form-body'}>
							<div>
								<Input
									readonly
									type={'text'}
									title={t('foreignAffiliateNameLabel')}
									value={form?.faName}
								/>
							</div>
							<div className={'calendar-time'}>
								<CalendarPickerWrapper
									required
									useCustomValidation
									editable={false}
									fieldTitle={
										t('asOfDateTitle') || ''
									}
									format={'MMM DD, YYYY'}
									value={form?.asOfDate}
									datePanelHorizontalAlign={'right'}
									error={
										!!fieldChanges &&
										!!fieldChanges.asOfDate &&
										!!formError &&
										!!formError.asOfDate
									}
									customErrorNode={<></>}
									minDate={props.affiliate.dateBecameFa}
									maxDate={maxDate}
									onChange={(value: Date) =>
									{
										onValueChange('asOfDate', new Date(value));
									}}
								/>
								{
									!!fieldChanges &&
									!!fieldChanges.asOfDate &&
									!!formError &&
									!!formError.asOfDate &&
									<div className="custom-cell-error">
										{
											formError.asOfDate
										}
									</div>
								}
							</div>
							<div className={'calendar-time'}>
								<TimePicker
									required
									hourTime={12}
									fieldTitle={
										t('asOfTimeTitle') || ''
									}
									value={form?.asOfTime}
									onChange={(value: Date) =>
									{
										onValueChange('asOfTime', new Date(value));
									}}
									error={
										!!fieldChanges &&
										!!fieldChanges.asOfTime &&
										!!formError &&
										!!formError.asOfTime
									}
								/>
								{
									!!fieldChanges &&
									!!fieldChanges.asOfTime &&
									!!formError &&
									!!formError.asOfTime &&
									<div className="custom-cell-error">
										{
											formError.asOfTime
										}
									</div>
								}
							</div>
							<div>
								<Select
									required
									searchable
									disabled={form.asOfDate===undefined}
									data={
										!!sellerFAOptionsAsOfDate &&
										!!sellerFAOptionsAsOfDate.length ?
											sellerFAOptionsAsOfDate :
											[]
									}
									labelKey={'name'}
									valueKey={'id'}
									dropdownRenderMode={'portal'}
									value={form?.sellerAffiliateId}
									placeholder={
										t('vendorSellerPlaceholder') || ''
									}
									error={
										!!fieldChanges &&
										!!fieldChanges.sellerAffiliateId &&
										!!formError &&
										!!formError.sellerAffiliateId
									}
									noResultFound={
										<>
											{t('noDataAvailable')}
										</>
									}
									onSelect={(value: SelectValue) =>
										onValueChange('sellerAffiliateId', value)
									}
								/>
								{
									!!fieldChanges &&
									!!fieldChanges.sellerAffiliateId &&
									!!formError &&
									!!formError.sellerAffiliateId &&
									<div className="custom-cell-error">
										{
											formError.sellerAffiliateId
										}
									</div>
								}
							</div>
							<div>
								<Select
									required
									searchable
									disabled={form.asOfDate===undefined}
									data={
										!!equityClassesAsOfDate &&
										!!equityClassesAsOfDate.length ?
											equityClassesAsOfDate :
											[]
									}
									labelKey={'name'}
									valueKey={'id'}
									dropdownRenderMode={'portal'}
									value={form?.equityClassId}
									placeholder={
										t('equityClassPlaceholder') || ''
									}
									error={
										!!fieldChanges &&
										!!fieldChanges.equityClassId &&
										!!formError &&
										!!formError.equityClassId
									}
									noResultFound={
										<>
											{t('noDataAvailable')}
										</>
									}
									onSelect={(value: SelectValue) =>
										onValueChange('equityClassId', value)
									}
								/>
								{
									!!fieldChanges &&
									!!fieldChanges.equityClassId &&
									!!formError &&
									!!formError.equityClassId &&
									<div className="custom-cell-error">
										{
											formError.equityClassId
										}
									</div>
								}
							</div>
							<div>
								<Input
									required
									type={'text'}
									title={
										t('equityPercentageTitle') || ''
									}
									placeholder={
										t('equityPercentagePlaceholder') || ''
									}
									value={
										!!form.equityPercentage ?
											form.equityPercentage :
											''
									}
									inputProps={{
										maxLength: 12
									}}
									error={
										!!fieldChanges &&
										!!fieldChanges.equityPercentage &&
										!!formError &&
										!!formError.equityPercentage
									}
									onChange={(value: string) =>
									{
										const regPattern = new RegExp(RegularPatternEnum.Percentage);
										if (!regPattern.test(value))
										{
											return;
										}

										onValueChange('equityPercentage', value);
									}}
								/>
								{
									!!fieldChanges &&
									!!fieldChanges.equityPercentage &&
									!!formError &&
									!!formError.equityPercentage &&
									<div className="custom-cell-error">
										{
											formError.equityPercentage
										}
									</div>
								}
							</div>
							<div>
								<Input
									required
									type={'text'}
									inputProps={{
										maxLength: 12
									}}
									title={
										t('sepTitle') || ''
									}
									placeholder={
										t('sepPlaceholder') || ''
									}
									value={
										!!form.sep ?
											form.sep :
											''
									}
									error={
										!!fieldChanges &&
										!!fieldChanges.sep &&
										!!formError &&
										!!formError.sep
									}
									onChange={(value: string) =>
									{
										const regPattern = new RegExp(RegularPatternEnum.Percentage);
										if (!regPattern.test(value))
										{
											return;
										}

										onValueChange('sep', value);
									}}
								/>
								{
									!!fieldChanges &&
									!!fieldChanges.sep &&
									!!formError &&
									!!formError.sep &&
									<div className="custom-cell-error">
										{
											formError.sep
										}
									</div>
								}
							</div>
							<div>
								<Select
									required
									searchable
									disabled={form.asOfDate===undefined}
									data={
										!!purchaserFAOptionsAsOfDate &&
										!!purchaserFAOptionsAsOfDate.length ?
											purchaserFAOptionsAsOfDate :
											[]
									}
									labelKey={'name'}
									valueKey={'id'}
									dropdownRenderMode={'portal'}
									value={form?.purchaserAffiliateId}
									placeholder={
										t('purchaserAcquirorPlaceholder') || ''
									}
									error={
										!!fieldChanges &&
										!!fieldChanges.purchaserAffiliateId &&
										!!formError &&
										!!formError.purchaserAffiliateId
									}
									noResultFound={
										<>
											{t('noDataAvailable')}
										</>
									}
									onSelect={(value: SelectValue) =>
										onValueChange('purchaserAffiliateId', value)
									}
								/>
								{
									!!fieldChanges &&
									!!fieldChanges.purchaserAffiliateId &&
									!!formError &&
									!!formError.purchaserAffiliateId &&
									<div className="custom-cell-error">
										{
											formError.purchaserAffiliateId
										}
									</div>
								}
							</div>
							<div>
								<Input
									type={'text'}
									title={
										t('considerationTitle') || ''
									}
									placeholder={
										t('considerationPlaceholder') || ''
									}
									value={form.consideration}
									error={
										!!fieldChanges &&
										!!fieldChanges.consideration &&
										!!formError &&
										!!formError.consideration
									}
									onChange={(value: string) =>
									{
										const regPattern = new RegExp(RegularPatternEnum.WholeNumber);
										if (!regPattern.test(value))
										{
											return;
										}

										onValueChange('consideration', value);
									}}
								/>
								{
									!!fieldChanges &&
									!!fieldChanges.consideration &&
									!!formError &&
									!!formError.consideration &&
									<div className="custom-cell-error">
										{
											formError.consideration
										}
									</div>
								}
							</div>
							<div>
								<Select
									required
									searchable
									disabled={form.asOfDate===undefined}
									data={
										!!currencies ?
											currencies :
											[]
									}
									labelKey={'name'}
									valueKey={'id'}
									dropdownRenderMode={'portal'}
									value={form?.currencyId}
									placeholder={
										t('currencyPlaceholder') || ''
									}
									error={
										!!fieldChanges &&
										!!fieldChanges.currencyId &&
										!!formError &&
										!!formError.currencyId
									}
									noResultFound={
										<>
											{t('noDataAvailable')}
										</>
									}
									onSelect={(value: SelectValue) =>
										onValueChange('currencyId', value)
									}
								/>
								{
									!!fieldChanges &&
									!!fieldChanges.currencyId &&
									!!formError &&
									!!formError.currencyId &&
									<div className="custom-cell-error">
										{
											formError.currencyId
										}
									</div>
								}
							</div>
							<div>
								<TextArea
									autosize
									title={
										t('notesTitle') || ''
									}
									placeholder={
										t('notesPlaceholder') || ''
									}
									value={form?.notes}
									maxLength={200}
									error={
										!!fieldChanges &&
										!!fieldChanges.notes &&
										!!formError &&
										!!formError.notes
									}
									onChange={(value: string) =>
									{
										onValueChange('notes', value);
									}}
								/>
								{
									!!fieldChanges &&
									!!fieldChanges.notes &&
									!!formError &&
									!!formError.notes &&
									<div className="custom-cell-error">
										{
											formError.notes
										}
									</div>
								}
							</div>
						</div>
					</div>
				</Modal>
				<UnsavedChangesModal
					visible={showUnsavedModal}
					onClose={closeUnsavedModal}
					title={t('discardChangesTitle') || ''}
				/>
				<UnexpectedErrorModal
					visible={!!unexpectedErrorMessage}
					onClose={closeUnexpectedErrorModal}
					message={unexpectedErrorMessage}
					error={''}
				/>
			</>
		}
	</>;
};
