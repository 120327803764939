import { useEffect, useState } from 'react';
import { INumericInputControlProps } from './INumericInputControlProps';
import { Input } from '@appkit4/react-components';
import { RegularPatternEnum } from '../../../types/enums/RegularPatternEnum';
import { Utils } from 'src/common/utils/utils';

import './NumericInputControl.scss';

export function NumericInputControl(props: INumericInputControlProps): JSX.Element
{
	const [value, setValue] = useState('');
	const [isDisplayOnly, setIsDisplayOnly] = useState(true);

	const onValueChange = (value: string, onChangeCallback: (value: string) => void) =>
	{
		const regPattern = new RegExp(
			!!props.regex ?
				props.regex :
				RegularPatternEnum.Currency
		);
		if (!regPattern.test(value))
		{
			return;
		}
		setValue(value);
		if (!isNaN(Number(value)))
		{
			onChangeCallback(value);
		}
	};

	const onChange = (value: string) =>
	{
		if (!!props.onValueChange)
		{
			onValueChange(value, props.onValueChange);
		}
	};

	const onFocus = () =>
	{
		setIsDisplayOnly(false);
	};

	const onBlur = (event: React.FocusEvent<HTMLInputElement>) =>
	{
		setIsDisplayOnly(true);

		if (!!props.onBlur)
		{
			onValueChange(
				!!event && !!event.target && !!event.target.value ?
					event.target.value :
					'',
				props.onBlur
			);
		}
	};

	const onRenderPrefix = (): JSX.Element | undefined =>
	{
		if (!!props.onRenderPrefixTemplate)
		{
			return props.onRenderPrefixTemplate();
		}

		return undefined;
	};

	useEffect(
		() =>
		{
			if (!!Utils.isValidNumber(`${props.value}`))
			{
				setValue(`${props.value}`);
			}
			else
			{
				setValue('');
			}
		},
		[props.value]
	);

	useEffect(
		() =>
		{
			if (!Utils.isNullUndefined(props.forceFocusIndex))
			{
				onFocus();
			}
		},
		[props.forceFocusIndex]
	);

	return <>
		<div className={'numeric-input-container'}>
			<div className={'numeric-input'}>
				<div className={'control'}>
					<Input
						id={props.id}
						type={'text'}
						hideTitleOnInput
						required={!!props.isRequired}
						disabled={!!props.isDisabled}
						readonly={!!props.isReadonly}
						value={
							isDisplayOnly ?
								Utils.formatCurrency(value, 2) :
								value
						}
						prefix={onRenderPrefix()}
						error={!!props.isError}
						errorNode={<></>}
						onChange={onChange}
						onBlur={onBlur}
						onFocus={onFocus}
					/>
				</div>
				{
					!!props.isError &&
					(
						!!props.errorMessage ||
						!!props.onRenderErrorMessage
					) &&
					<div className={'error'}>
						{
							!!props.onRenderErrorMessage ?
								props.onRenderErrorMessage() :
								props.errorMessage
						}
					</div>
				}
			</div>
		</div>
	</>;
}