import { useContext, useEffect, useState } from 'react';
import { DividendsFromFA } from './DividendsFromFA/DividendsFromFA';
import { DividendsFromOthers } from './DividendsFromOthers/DividendsFromOthers';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { IFAHistoryBase } from 'src/features/engagement/models/IFAHistory';
import { NotificationService } from 'src/core/services/notificationService';
import { useTranslation } from 'react-i18next';

import './DividendsReceived.scss';
import AffiliateContext from 'src/common/components/SharedDataContext/AffiliateContext';

export function DividendsReceived(props: {handleChange: (change: boolean) => void}): JSX.Element
{
	const { t } = useTranslation(
		'input',
		{
			keyPrefix: 'dividendsReceived'
		}
	);

	const affiliateContext = useContext(AffiliateContext);

	const [faHistory, setFAHistory] = useState<IFAHistoryBase[]>([]);

	const loadFAHistory = async (
		engagementId: number,
		affiliateId: number
	): Promise<void> =>
	{
		try
		{
			const response = await engagementService
				.getFAHistory(
					engagementId,
					affiliateId
				);

			if (
				!!response &&
				!!response.data &&
				!!response.data.result
			)
			{
				setFAHistory([
					...response.data.result
				]);
			}
			else
			{
				setFAHistory([]);
			}
		}
		catch (error)
		{
			triggerErrorNotification(
				t('faHistoryError')
			);
		}
	};

	const triggerErrorNotification = (message: string): void =>
	{
		NotificationService.error({
			message
		});
	};

	useEffect(
		() =>
		{
			if (
				!!affiliateContext&&
				!!affiliateContext?.engagementDetail.id&&
				!!affiliateContext?.affiliateDetail.affiliateId

			)
			{
				loadFAHistory(
					affiliateContext?.engagementDetail.id!,
					affiliateContext?.affiliateDetail.affiliateId!

				);
			}
		},
		[affiliateContext]
	);

	return (
		<div className={'dividends-recieved'}>
			<DividendsFromFA
				faHistory={faHistory}
				handleChange={props.handleChange}
			/>
			<hr></hr>
			<DividendsFromOthers
				faHistory={faHistory}
			/>
		</div>
	);
};