import SurplusBalancesReport from './SurplusBalancesReport/SurplusBalancesReport';
import ShareholderOwnershipReport from './ShareholderOwnershipReport/ShareholderOwnershipReport';
import AffiliateOwnershipReport from './AffiliateOwnershipReport/AffiliateOwnershipReport';
import ExemptEarningsAndTableEarningReport from './ExemptEarningsAndTaxableEnaringsReport/ExemptEarningsAndTaxableEarningReport';
import ForeignTaxesReport from './ForeignTaxesReport/ForeignTaxesReport';
import DividendsReceivedReport from './DividendsReceivedReport/DividendsReceivedReport';
import DividendsPaidReport from './DividendsPaidReport/DividendsPaidReport';
import CaptialGainsReport from './CapitalGainsReport/CaptialGainsReport';
import { ReportEnum } from 'src/common/types/enums/ReportEnum';
import { ExemptSurplusDetailsReport } from './ExemptSurplusReport/ExemptSurplusDetailsReport';
import HybridSurplusDetailsReport from './HybridSurplusReport/HybridSurplusDetailsReport';
import TaxableSurplusDetailsReport from './TaxableSurplusReport/TaxableSurplusDetailsReport';
import EarningsDetailsReport from './EarningsReport/EarningsDetailsReport';
import AdjustedCostBaseDetailsReport from './AdjustedCostBaseReport/AdjustedCostBaseDetailsReport';
import ExemptSurplusSummaryReport from './ExemptSurplusReport/ExemptSurplusSummaryReport';
import HybridSurplusSummaryReport from './HybridSurplusReport/HybridSurplusSummaryReport';
import TaxableSurplusSummaryReport from './TaxableSurplusReport/TaxableSurplusSummaryReport';
import EarningsSummaryReport from './EarningsReport/EarningsSummaryReport';
import AdjustedCostBaseSummaryReport from './AdjustedCostBaseReport/AdjustedCostBaseSummaryReport';
import ForeignAccurualPropertyIncomeReport from './ForeignAccrualPropertyIncomeReport/ForeignAccurualPropertyIncomeReport';
import './Report.scss';

export const Report = (props: {selectedReportId: ReportEnum, refershReport: boolean}) =>
{
	return <div className="report-container">
		{props.selectedReportId === ReportEnum.SurplusBalances && <SurplusBalancesReport refershReport={props.refershReport} />}
		{props.selectedReportId === ReportEnum.ShareholderOwnership && <ShareholderOwnershipReport refershReport={props.refershReport} />}
		{props.selectedReportId === ReportEnum.AffiliateOwnership && <AffiliateOwnershipReport refershReport={props.refershReport} />}
		{props.selectedReportId === ReportEnum.ExemptSurplusSummary && <ExemptSurplusSummaryReport refershReport={props.refershReport} />}
		{props.selectedReportId === ReportEnum.ExemptSurplusDetails &&<ExemptSurplusDetailsReport  refershReport={props.refershReport} />}
		{props.selectedReportId === ReportEnum.HybridSurplusSummary && <HybridSurplusSummaryReport refershReport={props.refershReport} />}
		{props.selectedReportId === ReportEnum.HybridSurplusDetails && <HybridSurplusDetailsReport  refershReport={props.refershReport} />}
		{props.selectedReportId === ReportEnum.TaxableSurplusSummary && <TaxableSurplusSummaryReport refershReport={props.refershReport} />}
		{props.selectedReportId === ReportEnum.TaxableSurplusDetails &&
			<TaxableSurplusDetailsReport  refershReport={props.refershReport} />}
		{props.selectedReportId === ReportEnum.EarningsSummary && <EarningsSummaryReport  refershReport={props.refershReport} />}
		{props.selectedReportId === ReportEnum.EarningsDetails && <EarningsDetailsReport refershReport={props.refershReport} />}
		{props.selectedReportId === ReportEnum.ExemptEarningsAndTaxable &&
			<ExemptEarningsAndTableEarningReport  refershReport={props.refershReport} />}
		{props.selectedReportId === ReportEnum.AdjustedCostBaseSummary &&
			<AdjustedCostBaseSummaryReport refershReport={props.refershReport} />}
		{props.selectedReportId === ReportEnum.AdjustedCostBaseDetails &&
			<AdjustedCostBaseDetailsReport refershReport={props.refershReport} />}
		{props.selectedReportId === ReportEnum.ForeignAccrualPropertyIncome &&
			<ForeignAccurualPropertyIncomeReport refershReport={props.refershReport} />}
		{props.selectedReportId === ReportEnum.ForeignTaxes && <ForeignTaxesReport  refershReport={props.refershReport} />}
		{props.selectedReportId === ReportEnum.DividendsReceived && <DividendsReceivedReport refershReport={props.refershReport} />}
		{props.selectedReportId === ReportEnum.DividendsPaid && <DividendsPaidReport refershReport={props.refershReport} />}
		{props.selectedReportId === ReportEnum.CapitalGains && <CaptialGainsReport  refershReport={props.refershReport} />}
	</div>;
};