import { Button, Modal } from '@appkit4/react-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './RemoveModal.scss';

export const RemoveModal = (props: any) =>
{
	const { t } = useTranslation('engagementSetup');
	const [disableIcon, setDisableIcon] = useState<boolean>(false);

	const preventDoubleClick = () =>
	{
		setDisableIcon(true);

		setTimeout(() =>
		{
			setDisableIcon(false);
		}, 3000);
	};
	return (
		<>
			<Modal
				className="remove-self-modal"
				visible={props.visible}
				initialFocus={false}
				closeOnPressEscape={false}
				maskCloseable={false}
				title={props.title}
				onCancel={() => props.setVisible(false)}
				footer={
					<div className="footer-wrapper">
						<Button
							kind="secondary"
							onClick={() =>
							{
								props.setVisible(false);
							}}
						>
							{t('engagementMember.cancel')}
						</Button>
						<div onClick={preventDoubleClick}>
							<Button kind="primary" onClick={() => props.onDelete()} disabled={disableIcon}>
								{t('engagementMember.removeUser')}
							</Button>
						</div>
					</div>
				}
			>
				<div style={{
					whiteSpace: 'pre-wrap',
					lineHeight: '24px'
				}}>{props.deleteMessage}</div>
			</Modal>
		</>
	);
};
