import { TimePicker } from '@appkit4/react-components';
import { ITimeFormControlProps } from './ITimeFormControlProps';
import { InputError } from '../../ModalControls/InputError/InputError';

import './TimeFormControl.scss';

export function TimeFormControl(props: ITimeFormControlProps): JSX.Element
{
	const onChange = (value: Date): void =>
	{
		if (!!props.onChange)
		{
			props.onChange(props.field, new Date(value));
		}
	};

	return <>
		<div className={'time-form-control'}>
			<TimePicker
				required={!!props.isRequired}
				disabled={!!props.isDisabled}
				readonly={!!props.isReadonly}
				fieldTitle={props.title}
				hourTime={
					!!props.hourTime ?
						props.hourTime :
						12
				}
				value={props.value}
				error={!!props.isError}
				onChange={onChange}
			/>
			<InputError
				isError={!!props.isError}
				message={props.errorMessage}
				onRenderMessage={props.onRenderErrorMessage}
			/>
		</div>
	</>;
}