import axios from 'axios';

export const apiService = {
	getProfile: async () =>
	{
		return axios.get(`${process.env.REACT_APP_WEBAPI}/User`);
	},

	getSystemRoles: async () =>
	{
		return axios.get(`${process.env.REACT_APP_WEBAPI}/User/GetSystemRoles`);
	},

	getAllOffices: async () =>
	{
		return axios.get(`${process.env.REACT_APP_WEBAPI}/User/GetAllOffices`);
	},

	confirmPrivacy: async () =>
	{
		return axios.post(`${process.env.REACT_APP_WEBAPI}/User/ConfirmPrivacy`);
	},

	loadCurrencies: async () =>
	{
		return axios.get(`${process.env.REACT_APP_WEBAPI}/lookup/GetCurrencies`);
	},

	loadCountries: async () =>
	{
		return axios.get(`${process.env.REACT_APP_WEBAPI}/lookup/GetCountries`);
	},

	loadCalculationTypes: async () =>
	{
		return axios.get(`${process.env.REACT_APP_WEBAPI}/Lookup/CalculationTypes`);
	},

	loadOptions: async () =>
	{
		return axios.get(`${process.env.REACT_APP_WEBAPI}/Lookup`);
	},
	loadInputForms: async () =>
	{
		return axios.get('/config/forms.json');
	},
	loadEquityClasses: async () =>
	{
		return axios.get(`${process.env.REACT_APP_WEBAPI}/Lookup/GetEquityClasses`);
	},
	loadEngagementRoles: async () =>
	{
		return axios.get(`${process.env.REACT_APP_WEBAPI}/lookup/engagementRoles`);
	},
	loadSurplusPoolData: async () =>
	{
		return axios.get(`${process.env.REACT_APP_WEBAPI}/lookup/SurplusPoolMasterData`);
	}
};
