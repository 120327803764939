export enum ACBDetailsReportFields {
	effectiveDate= 'effectiveDate',
	transactionType= 'typeId',
	transactionDescription= 'description',
	amountInShareholderCurrency= 'amountInShareholderCurrency',
	balanceInShareholderCurrency= 'balanceInShareholderCurrency',
	foreignExchangeRate= 'foreignExchangeRate',
	amountInTaxPayerCurrency= 'amountInTaxPayerCurrency',
	balanceInTaxPayerCurrency= 'balanceInTaxPayerCurrency',
	shares= 'shares',
	sharesBalance= 'sharesBalance',
	notes= 'notes',
	shareholderCurrency= 'shareholderCurrency',
	taxPayerCurrency= 'taxPayerCurrency'
}

export enum ACBDetailsReportCurrencyTypes {
	BothCurrencies = 1,
	ShareholderCurrency = 2,
	TaxpayerCurrency = 3
}