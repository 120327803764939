import { Input } from '@appkit4/react-components';

interface InputFieldProps {
  title: string;
  readonly: boolean;
  required?: boolean;
  name: string;
  handleInputChange?: Function;
  userData: UserData;
  handleInputBlur?: Function;
  userFormError?: Record<string, any>;
}
interface UserData {
  email: string;
  name: string;
  office: string;
  role: string;
}
const InputField = (props: InputFieldProps) =>
{
	const {
		title,
		readonly,
		required,
		name,
		userData,
		handleInputBlur,
		userFormError,
		handleInputChange
	} = props;
	// @ts-ignore
	const inputVal: string = userData[name];

	const getErrorMessage = () =>
	{
		if (!!userFormError)
		{
			let errorMsg = '';
			if (!!userFormError?.['apiError'])
			{
				errorMsg = props?.userFormError?.['apiError'] || '';
			}
			else if (!!userFormError?.[name])
			{
				errorMsg = props?.userFormError?.[name] || '';
			}

			return (
				<div
					id="errormessage"
					aria-live="polite"
					className={'ap-field-email-validation-error'}
				>
					{errorMsg}
				</div>
			);
		}
		return null;
	};

	return (
		<Input
			name={name}
			type={'text'}
			title={title}
			readonly={readonly}
			onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
				handleInputBlur?.(event, props.name)
			}
			value={inputVal}
			onChange={(value: string, event: React.ChangeEvent<HTMLInputElement>) =>
				handleInputChange?.(event, name, value)
			}
			error={
				(!!userFormError && !!userFormError?.['apiError']) ||
        (!!userFormError && !!userFormError?.[name])
			}
			errorNode={getErrorMessage()}
			required={required}
		></Input>
	);
};

export default InputField;
