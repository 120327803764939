import React from 'react';

import './UnderConstruction.scss';

export const UnderConstruction = (props: any) =>
{
	return (
		<div className='under-construction'>
			<div className='title'>
				<span className="Appkit4-icon icon-service-outline"></span> Under Construction
			</div>
			<div>
				{props.children}
			</div></div>
	);
};
