import { Utils } from 'src/common/utils/utils';
import { DividendPaidFields } from '../enums/DividendsPaidFields';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

export interface IDividendPaid {

	[DividendPaidFields.id]: number;
	[DividendPaidFields.engagementId]: number;
	[DividendPaidFields.affiliateId]: number;
	[DividendPaidFields.effectiveDate]: Date;
	[DividendPaidFields.date]?: Date;
	[DividendPaidFields.time]?: Date;
	[DividendPaidFields.currencyId]?: number;
	[DividendPaidFields.amount]?: number;
	[DividendPaidFields.underlyingForeignTax]?: number;
	[DividendPaidFields.paidOnEquityClassId]: number;
	[DividendPaidFields.isSurplusElection]: boolean;
	[DividendPaidFields.isCapitalElection]: boolean;
	[DividendPaidFields.notes]?: string;
}

export class DividendPaid implements IDividendPaid
{
	constructor(
		engagementId: number,
		affiliateId: number,
		currencyId?: number
	)
	{
		const parsedDate = moment()
			.set({
				hour: 0,
				minute: 1,
				second: 0,
				millisecond: 0
			})
			.toDate();

		this[DividendPaidFields.id] = Utils.generateTempId();
		this[DividendPaidFields.engagementId] = engagementId;
		this[DividendPaidFields.affiliateId] = affiliateId;
		this[DividendPaidFields.effectiveDate] = parsedDate;
		this[DividendPaidFields.date] = undefined;
		this[DividendPaidFields.time] = cloneDeep(parsedDate);
		this[DividendPaidFields.currencyId] = currencyId;
		this[DividendPaidFields.amount] = undefined;
		this[DividendPaidFields.underlyingForeignTax] = undefined;
		this[DividendPaidFields.paidOnEquityClassId] = 0;
		this[DividendPaidFields.isSurplusElection] = false;
		this[DividendPaidFields.isCapitalElection] = false;
		this[DividendPaidFields.notes] = undefined;
	}

	[DividendPaidFields.id]: number;
	[DividendPaidFields.engagementId]: number;
	[DividendPaidFields.affiliateId]: number;
	[DividendPaidFields.effectiveDate]: Date;
	[DividendPaidFields.date]?: Date;
	[DividendPaidFields.time]?: Date;
	[DividendPaidFields.currencyId]?: number;
	[DividendPaidFields.amount]?: number;
	[DividendPaidFields.underlyingForeignTax]?: number;
	[DividendPaidFields.paidOnEquityClassId]: number;
	[DividendPaidFields.isSurplusElection]: boolean;
	[DividendPaidFields.isCapitalElection]: boolean;
	[DividendPaidFields.notes]?: string;

	static populateFields(dividend: IDividendPaid): IDividendPaid
	{
		const parsedDate = dateFormatter
			.utcToEst(dividend[DividendPaidFields.effectiveDate])!;

		return {
			...dividend,
			[DividendPaidFields.effectiveDate]: cloneDeep(parsedDate),
			[DividendPaidFields.date]: cloneDeep(parsedDate),
			[DividendPaidFields.time]: cloneDeep(parsedDate)
		};
	}

	static isSame(dividend?: IDividendPaid, compareDividend?: IDividendPaid): boolean
	{
		const isDateSame = (date1?: Date, date2?: Date): boolean =>
		{
			return !!date1 && !!date2 ?
				moment(date1).isSame(date2) :
				!(
					(!!date1 && !date2) ||
					(!date1 && !!date2)
				);
		};

		return !!dividend && !!compareDividend &&
			dividend[DividendPaidFields.id] === compareDividend[DividendPaidFields.id] &&
			dividend[DividendPaidFields.affiliateId] === compareDividend[DividendPaidFields.affiliateId] &&
			dividend[DividendPaidFields.engagementId] === compareDividend[DividendPaidFields.engagementId] &&
			dividend[DividendPaidFields.amount] === compareDividend[DividendPaidFields.amount] &&
			dividend[DividendPaidFields.underlyingForeignTax] === compareDividend[DividendPaidFields.underlyingForeignTax] &&
			dividend[DividendPaidFields.currencyId] === compareDividend[DividendPaidFields.currencyId] &&
			dividend[DividendPaidFields.paidOnEquityClassId] === compareDividend[DividendPaidFields.paidOnEquityClassId] &&
			isDateSame(dividend[DividendPaidFields.effectiveDate], compareDividend[DividendPaidFields.effectiveDate]) &&
			isDateSame(dividend[DividendPaidFields.date], compareDividend[DividendPaidFields.date]) &&
			isDateSame(dividend[DividendPaidFields.time], compareDividend[DividendPaidFields.time]) &&
			dividend[DividendPaidFields.isCapitalElection] === compareDividend[DividendPaidFields.isCapitalElection] &&
			dividend[DividendPaidFields.isSurplusElection]=== compareDividend[DividendPaidFields.isSurplusElection] &&
			dividend[DividendPaidFields.notes] === compareDividend[DividendPaidFields.notes];
	}
}