export enum LineItemCategories {

	Disposition = 'Disposition',
	IncomeTaxesPaid = 'IncomeTaxesPaidYear',
	Earnings = 'Earnings',
	DividendsPaid = 'DividendsPaid',
	DividendsReceivedFromFA = 'DividendsReceivedFromFA',
	DividendsReceivedFromOthers = 'DividendsReceivedFromOthers',
	AdjustmentsFromAllTaxpayer = 'AdjustmentsFromAllTaxpayer',
	AdjustmentsFromSpecificTaxpayer = 'AdjustmentsFromSpecificTaxpayer',
	AdjustedCostBase = 'AdjustedCostBase'
}