import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment-timezone';

const padNumber = (value: number, maxLength: number = 2) =>
{
	return `${value}`.padStart(maxLength, '0');
};

export const dateFormatter  =  {
	toMMMdd: (date: Date|undefined) =>
	{
		if (!date)
		{
			return '';
		}
		const monthNames = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
		const month = monthNames[new Date(date).getMonth()];
		const day = (new Date(date)).getDate();

		return `${month} ${day}`;
	},

	format: (date: Date|undefined, format: string) =>
	{
		// moment(date).format("MMM DD")
		if (date)
		{
			return moment(date).format(format);
		}
		else
		{
			return '';
		}
	},

	/**
     * Converts javascript date to date string with timezone information.
     * If no input provided then will, return `null`
     * @param date javascript date object
     * @returns date string with timezone information or null
     *
     * e.g. response: `2023-11-22T05:00:00.000Z` or `null`
     */
	toUtc: (date?: Date): string | undefined =>
	{
		if (!!date)
		{
			return date.toISOString();
		}

		return undefined;
	},

	/**
     * Merge date where date portion is required from one property and time from another
     * @param datePart date that will be parsed and only date portion will be used in output
     * @param timePart date that will be parsed and only time portion will be used in output
     * @returns ET `Date` or `null`
     */
	mergeDateTime: (datePart: Date | undefined, timePart: Date | undefined): string | undefined =>
	{
		if (!!datePart && !!timePart)
		{
			const year = padNumber(datePart.getFullYear(), 4);
			const month = padNumber(datePart.getMonth() + 1); // zero based index so need to add one
			const day = padNumber(datePart.getDate());
			const hours = padNumber(timePart.getHours());
			const mins = padNumber(timePart.getMinutes());
			const seconds = padNumber(timePart.getSeconds());

			return `${year}-${month}-${day}T${hours}:${mins}:${seconds}`;
		}

		return undefined;
	},

	/**
     * Convert UTC date string or date to local date object
     * If Date object is passed in then no formatting will be applied as function should only be used
     * directly on date string objects
     * Note: Assumes that the date string does not contain timezone information (except utc info)
     * and will always force convert date string format to utc before conversion to local
     * @param dateValue json date `string` or `Date` object
     *
     * Acceptable formats are:
     * - `2023-11-22T00:00:00.000+00:00`
     * - `2023-11-22T00:00:00.000Z`
     * - `2023-11-22T00:00:00.000`
     * @returns javascript `Date` object or `null` if input is not provided
    */
	utcToLocal: (dateValue?: string | Date): Date | undefined =>
	{
		if (!!dateValue)
		{
			if (typeof dateValue === 'string')
			{
				// handle cases of different utc date strings to ensure proper conversion to local timezone
				if (!dateValue.endsWith('+00:00') && !dateValue.endsWith('-00:00') && !dateValue.endsWith('Z'))
				{
					dateValue = `${dateValue}Z`;
				}

				return new Date(dateValue);
			}
			// if already a date object then return unformatted as it may already have tz info added
			else
			{
				return dateValue;
			}
		}

		return undefined;
	},

	/**
     * Convert UTC date string or date to start of day local date object (timezone agnostic)
     * If Date object is passed in then no formatting will be applied as function should only be used
     * directly on date string objects
     * Note: Assumes that the date string does not contain timezone information (except utc info)
     * @param dateValue json date `string` or `Date` object
     *
     * Acceptable formats are:
     * - `2023-11-22T00:00:00.000+00:00`
     * - `2023-11-22T00:00:00.000Z`
     * - `2023-11-22T00:00:00.000`
     * @returns javascript `Date` object or `null` if input is not provided
     */
	toStartOfDayLocal: (dateValue?: string | Date): Date | undefined =>
	{
		if (!!dateValue)
		{
			if (typeof dateValue === 'string')
			{
				// handle cases of different utc date strings to ensure proper conversion to local timezone
				if (dateValue.endsWith('+00:00') || dateValue.endsWith('-00:00') || dateValue.endsWith('Z'))
				{
					dateValue = dateValue
						.replace('+00:00', '')
						.replace('-00:00', '')
						.replace('Z', '');
				}

				return new Date(dateValue);
			}
			// if already a date object then return date with time set to start of day
			else
			{
				return moment(dateValue)
					.startOf('day')
					.toDate();
			}
		}

		return undefined;
	},

	toStartOfDayLocalDateOnly: (dateValue?: string | Date): Date | undefined =>
	{
		if (!!dateValue)
		{
			if (typeof dateValue === 'string')
			{
				return new Date(`${dateValue}T00:00:00`);
			}
			// if already a date object then return date with time set to start of day
			else
			{
				return moment(dateValue)
					.startOf('day')
					.toDate();
			}
		}

		return undefined;
	},

	/**
     * Convert Date oject to start of day UTC, ignoring timezone information.
     * Used to keep date agnostic of timezone information to always mark start of day
     * @param date
     * @returns
     */
	toStartOfDayUtc: (date?: Date): string | undefined =>
	{
		if (!!date)
		{
			const tzOffset = date.getTimezoneOffset() * 60 * 1000;
			const updatedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
			return new Date(updatedDate.getTime() - tzOffset).toISOString();
		}

		return undefined;
	},

	/**
     * Whatever date is given for this function no matter the timezone, it will automatically assume ET and output an iso date string
     * Usage for this function is for fields that will always operate in EST Time
	 * even though a control or logic may be operating on local timezone information
     * @param date javascript `Date` object
     * @returns
     */
	estDateStringOverrideToUtc: (dateString?: string, includeZ: boolean = true): string | undefined =>
	{
		if (!!dateString)
		{
			// check whether date is DST (force date to be checked in context of EST)
			const estTimezone = moment(dateString).tz('America/Toronto');
			const isDst = estTimezone.isDST();

			const modifiedDate = moment.utc(dateString).add(isDst ? 4 : 5, 'hours');

			return `${modifiedDate.format('YYYY-MM-DDTHH:mm:ss')}${includeZ ? 'Z' : ''}`;
		}

		return undefined;
	},

	/**
     * Whatever date is given for this function no matter the timezone, it will automatically assume ET and output an iso date string
     * Usage for this function is for fields that will always operate in EST Time
	 * even though a control or logic may be operating on local timezone information
     * @param date javascript `Date` object
     * @returns
     */
	estDateToString: (date?: Date): string | undefined =>
	{
		const padNumber = (value: number, maxLength: number = 2) =>
		{
			return `${value}`.padStart(maxLength, '0');
		};

		if (!!date)
		{
			const year = padNumber(date.getFullYear(), 4);
			const month = padNumber(date.getMonth() + 1); // zero based index so need to add one
			const day = padNumber(date.getDate());
			const hours = padNumber(date.getHours());
			const mins = padNumber(date.getMinutes());
			const seconds = padNumber(date.getSeconds());

			return `${year}-${month}-${day}T${hours}:${mins}:${seconds}`;
		}

		return undefined;
	},

	dateToString: (date?: Date): string | undefined =>
	{
		const padNumber = (value: number, maxLength: number = 2) =>
		{
			return `${value}`.padStart(maxLength, '0');
		};

		if (!!date)
		{
			const year = padNumber(date.getFullYear(), 4);
			const month = padNumber(date.getMonth() + 1); // zero based index so need to add one
			const day = padNumber(date.getDate());

			return `${month}/${day}/${year}`;
		}

		return undefined;
	},

	/**
     * Convert utc date string directly to ET timezone (considers both EST/EDT)
     * @param dateValue iso format utc date string
     * @returns js `Date` object or `undefined`
     */
	utcToEst: (dateValue?: string | Date): Date | undefined =>
	{
		if (!dateValue)
		{
			return undefined;
		}

		if (typeof dateValue === 'string')
		{
			// handle cases of different utc date strings to ensure proper conversion to local timezone
			if (!dateValue.endsWith('+00:00') && !dateValue.endsWith('-00:00') && !dateValue.endsWith('Z'))
			{
				dateValue = `${dateValue}Z`;
			}

			// check whether date is DST (force date to be checked in context of EST)
			const estTimezone = moment(dateValue).tz('America/Toronto');
			const isDst = estTimezone.isDST();

			const utcDate = moment.utc(dateValue);
			const modifiedDate = utcDate.subtract(isDst ? 4 : 5, 'hours');

			const newDate = new Date(modifiedDate.format('YYYY-MM-DDTHH:mm:ss'));
			return newDate;
		}

		return dateValue;
	},

	formatEST: (input: any, format: string) =>
	{
		const isDate = input instanceof Date;
		const date = isDate? input : new Date(input.endsWith('Z')?input:input+'Z');
		if (date)
		{
			const estTime = date.toLocaleTimeString('en-US', {
				hour: '2-digit',
				minute: '2-digit',
				timeZone: 'EST'
			});
			return estTime;
		}
		else
		{
			return '';
		}
	},

	parseDateString: (dateString: string, inputFormat: string = 'MM/DD/YYYY'): Date | undefined =>
	{
		if (!!dateString)
		{
			return moment(dateString, inputFormat).toDate();
		}

		return undefined;
	},

	toEndOfDay: (date: Date | undefined): Date | undefined =>
	{
		if (!!date)
		{
			return moment(date)
				.endOf('day')
				.toDate();
		}

		return undefined;
	},

	dateOnly: (dateString: string | undefined): string | undefined =>
	{
		if (!!dateString)
		{
			const dateParts = dateString.split('T');

			if (!!dateParts && !!dateParts.length)
			{
				return dateParts[0];
			}
		}

		return undefined;
	},

	/**
	 * Conditionally add time to date string if there is a date that is within the same day
	 * @param fieldName name of the field for compare
	 * @param item entity containing date field for compare
	 * @param index index of currently active entity (to exclude from comparison against list)
	 * @param allData full list of data for comparison
	 * @returns formatted date string (either `'MMM DD, YYYY'` if no match on same day or 'MMM DD, YYYY (hh:mm A)' if same day)
	 */
	conditionallyFormatDateWithTime: (
		fieldName: string,
		item: { [key: string]: any },
		index: number,
		allData: { [key: string]: any }[]
	): string =>
	{
		const currentDate = dateFormatter.utcToEst(item[fieldName]);

		const allDataCopied = cloneDeep([...allData]);
		allDataCopied.splice(index, 1);

		const isSameDay = allDataCopied.some((o) =>
		{
			const otherDate = moment(
				dateFormatter.utcToEst(o[fieldName])
			);

			return otherDate.isSame(moment(currentDate), 'day');
		});

		return !!item[fieldName] ?
			dateFormatter.format(
				dateFormatter.utcToEst(item[fieldName]),
				!!isSameDay ? 'MMM DD, YYYY (hh:mm A)' : 'MMM DD, YYYY'
			) :
			'';
	},

	/**
	 * Conditionally add time to date string if there is a date that is within the same day
	 * @param fieldName name of the field for compare
	 * @param item entity containing date field for compare
	 * @param index index of currently active entity (to exclude from comparison against list)
	 * @param allData full list of data for comparison
	 * @returns formatted date string with time string if required
	 */
	conditionallyFormatDateSeparateFromTime: (
		fieldName: string,
		item: { [key: string]: any },
		index: number,
		allData: { [key: string]: any }[]
	): { date: string; time: string; } =>
	{
		const currentDate = dateFormatter.utcToEst(item[fieldName]);

		const allDataCopied = cloneDeep([...allData]);
		allDataCopied.splice(index, 1);

		const isSameDay = allDataCopied.some((o) =>
		{
			const otherDate = moment(
				dateFormatter.utcToEst(o[fieldName])
			);

			return otherDate.isSame(moment(currentDate), 'day');
		});

		return {
			date: !!item[fieldName] ?
				dateFormatter.format(
					dateFormatter.utcToEst(item[fieldName]),
					'MMM DD, YYYY'
				) :
				'',
			time: !!isSameDay && !!item[fieldName] ?
				dateFormatter.format(
					dateFormatter.utcToEst(item[fieldName]),
					'(hh:mm A)'
				) :
				''
		};
	}
};
