import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { IReport } from 'src/features/engagement/models/IReport';
import DividendsPaidReportFields  from './../Settings/DividendsPaidReportFields';
import { ReportUtil } from 'src/features/engagement/utils/ReportUtil';
import { IReportField } from 'src/common/types/interfaces/IReportField';
import { ReportFieldEnum } from 'src/common/types/enums/ReportFieldEnum';
import ReportCell from '../ReportCells/ReportCell';
import ReportHeader from '../ReportCells/ReportHeader';
import { ReportI18NKeys } from 'src/common/types/enums/ReportI18NKeys';
import ReportRow from '../ReportCells/ReportRow';
import { Loading, Button } from '@appkit4/react-components';
import { DividendPaidReportFields } from 'src/common/types/enums/DividendPaidReportFields';
import { EngagementUtil } from 'src/features/engagement/utils/EngagementUtil';
import { Utils } from 'src/common/utils/utils';
import './DividendsPaidReport.scss';

interface IDividendsPaidReport extends IReport {
	summaryTotals: IReportField[];
	post90DayDetails: IReportField[];
}

const DividendsPaidReport = (props: {refershReport: boolean}) =>
{
	const {engagementId, affiliateId } = useParams();

	const [reportData, setReportData] = useState<any[]>([]);

	const [sections, setSections] = useState<{index: number, collapsed: boolean}[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [post90DaySections, setPost90DaySections] = useState<any>([]);
	const [totalAmounts, setTotalAmounts] = useState<any>();
	const report: IDividendsPaidReport = DividendsPaidReportFields;

	const { t } = useTranslation(
		'report',
		{
			keyPrefix: ReportI18NKeys.DividendsPaid
		}
	);

	const loadDividendsPaidReportData = () =>
	{
		if (engagementId && affiliateId)
		{
			setIsLoading(true);
			engagementService.loadDividendsPaidReport(Number(engagementId), Number(affiliateId)).then((res: any) =>
			{
				setIsLoading(false);
				const result = res.data.result;

				const updatedReportData = updatePreAcquisitionAndElectionSurpluses(result);
				setReportData(updatedReportData);


				if (!!result && result.length > 0)
				{
					const dividendPaymentsLengths = result.map((c: any) => !!c[DividendPaidReportFields.DividendPayments] ?
						c[DividendPaidReportFields.DividendPayments].length : 0);

					const maxDividendPaymentsCount = Math.max(...dividendPaymentsLengths);

					const initialSections = Array.from({
						length: maxDividendPaymentsCount
					}, (_, index) => ({
						index,
						collapsed: true
					}));
					setSections(initialSections);

					checkPost90DaySectionVisibility(result, maxDividendPaymentsCount);

					const rowTotal = calculateRowTotal(updatedReportData);
					setTotalAmounts(rowTotal);
				}
			})
				.catch((error: any) =>
				{
					setIsLoading(false);
				});
		}
	};

	const checkPost90DaySectionVisibility = (result: any, maxDividendPaymentsCount: number) =>
	{
		const sections: any = Array.from({
			length: maxDividendPaymentsCount
		}, () => []);

		result.forEach((year: any) =>
		{
			!!year[DividendPaidReportFields.DividendPayments] &&
			year[DividendPaidReportFields.DividendPayments].forEach((payment: any , index: number) =>
			{
				if (!sections[index])
				{
					sections[index] = [];
				}
				sections[index].push(payment);
			});
		});

		const post90DayDividendSections = sections.map((section: any) =>
		{
			const isPost90DaySectionVisible = section.some((payment: any) =>
				payment[DividendPaidReportFields.IsPost90DayDividend] &&
				payment[DividendPaidReportFields.DividendAllocation]?.[DividendPaidReportFields.PreAcquisitionSurplus] > 0
			);
			return {
				isPost90DaySectionVisible
			};
		});

		setPost90DaySections(post90DayDividendSections);
	};

	const calculateSummation = (parentKey: any, total: any) =>
	{
		Object.keys(parentKey).forEach((key: string) =>
		{
			const properties = key.split('-');

			if (!!properties && properties.length > 0)
			{
				switch (properties.length)
				{
				case 1:
					total[properties[0]] = Utils.add(...parentKey[key]);
					break;
				case 2:
					total[properties[0]] =  {
						...total[properties[0]],
						[properties[1]]: Utils.add(...parentKey[key])
					};
					break;
				case 3:
					total[properties[0]] =  {
						...total[properties[0]],
						[properties[1]]: {
							...total[properties[0]][properties[1]],
							[properties[2]]: Utils.add(...parentKey[key])
						}
					};
					break;
				}
			}
		});
	};

	const calculateRowTotal = (data: any): any =>
	  {
		const summarySum: any = {
		};
		const summaryTotal: any = {
		};

		data.forEach((item: any) =>
		{
			if (!!item[DividendPaidReportFields.DividendPayments] && item[DividendPaidReportFields.DividendPayments].length > 0)
			{
				const summary = DividendPaidReportFields.Summary;
				Object.keys(item[summary]).forEach((key) =>
				{
					if (key === DividendPaidReportFields.UpdatedPreAcquisitionSurpluses)
					{
						Object.keys(item[summary][key]).forEach((equityClass) =>
						{
							const newKey: string = `${summary}-${key}-${equityClass}`;
							const amount = item[summary][key][equityClass];
							summarySum[newKey] = !!summarySum[newKey] ? [...summarySum[newKey], amount || 0] : [amount || 0];
						});
					}
					else if (key === DividendPaidReportFields.UpdatedElectionDividends)
					{
						Object.keys(item[summary][key]).forEach((equityClass) =>
						{
							const newKey: string = `${summary}-${key}-${equityClass}`;
							const amount = item[summary][key][equityClass];
							summarySum[newKey] = !!summarySum[newKey] ? [...summarySum[newKey], amount || 0] : [amount || 0];
						});
					}
					else
					{
						const newKey: string = `${summary}-${key}`;
						const amount = item[summary][key];
						summarySum[newKey] = !!summarySum[newKey] ? [...summarySum[newKey], amount || 0] : [amount || 0];
					}
				});
				calculateSummation(summarySum, summaryTotal);
			}
		});
		return summaryTotal;
	  };

	const updatePreAcquisitionAndElectionSurpluses = (data: any) =>
	{
		const updatedReportData: any = [];
		data.forEach((item: any) =>
		{
			let updatedDividendSurpluses = {
			};

			if (!!item[DividendPaidReportFields.Summary] &&
				!!item[DividendPaidReportFields.Summary][DividendPaidReportFields.PreAcquisitionSurpluses] &&
				item[DividendPaidReportFields.Summary][DividendPaidReportFields.PreAcquisitionSurpluses].length > 0)
			{
				item[DividendPaidReportFields.Summary][DividendPaidReportFields.PreAcquisitionSurpluses].forEach((equityClass: any) =>
				{
					updatedDividendSurpluses ={
						...updatedDividendSurpluses,
						[equityClass.equityClassId]: equityClass.value
					};
				});
				item.summary[DividendPaidReportFields.UpdatedPreAcquisitionSurpluses] = updatedDividendSurpluses;
				updatedDividendSurpluses= {
				};
			}

			if (!!item[DividendPaidReportFields.Summary] &&
				!!item[DividendPaidReportFields.Summary][DividendPaidReportFields.ElectionDividends] &&
				item[DividendPaidReportFields.Summary][DividendPaidReportFields.ElectionDividends].length > 0)
			{
				item[DividendPaidReportFields.Summary][DividendPaidReportFields.ElectionDividends].forEach((equityClass: any) =>
				{
					updatedDividendSurpluses ={
						...updatedDividendSurpluses,
						[equityClass.equityClassId]: equityClass.value
					};
				});
				item.summary[DividendPaidReportFields.UpdatedElectionDividends] = updatedDividendSurpluses;
				updatedDividendSurpluses= {
				};
			}

			updatedReportData.push(item);
		});
		return updatedReportData;
	};

	useEffect(() =>
	{
		loadDividendsPaidReportData();
	},[engagementId, affiliateId]);

	useEffect(() =>
	{
		if (!!props.refershReport)
		{
			loadDividendsPaidReportData();
		}
	}, [props.refershReport]);

	const handleToggleSection = (index: number) =>
	{
	  const updatedSections =	sections.map((section: {index: number,
			 collapsed: boolean}) =>
		{
			if (section.index===index)
			{
				return {
					...section,
					collapsed: !section.collapsed
				};
			}
			else
			{
				return {
					...section
				};
			}
		});

		setSections(updatedSections);
	};

	const handleToggleAll = (expanded: boolean) =>
	{
	  const updatedSections =	sections.map((section: {index: number,
			 collapsed: boolean}) =>
		{
			if (expanded)
			{
				return {
					...section,
					collapsed: false
				};
			}
			else
			{
				return {
					...section,
					collapsed: true
				};
			}
		});
		setSections(updatedSections);
	};

	const renderReportDetailsSection = (section: {index: number, collapsed: boolean}, field: IReportField ): JSX.Element =>
	{
		return (
			<tr  className={`${ReportUtil.getRowClassName(field)} ${section.collapsed ?
				 'collapsed': 'expanded'}`}>
				<td className={`${!!field?.background ? field?.background : ''}`}>
					{!!field.type && !field.type.includes(ReportFieldEnum.Separator) &&
					<div className={`${ReportUtil.getRowClassName(field)}`}
						onClick={() => field.type===ReportFieldEnum.Section && handleToggleSection(section.index)}>
						{ field.type===ReportFieldEnum.Section &&
					<span
						className={`Appkit4-icon 
							${section.collapsed?'icon-down-chevron-outline':'icon-up-chevron-outline'}`}
					></span> }
						{`${!!field.name ? t(field.name) : ''}`}
						{`${field.type===ReportFieldEnum.Section? ' ' + (section.index+1):''}`}
					</div>}
				</td>
				{!!totalAmounts && <td  className={`${!!field?.background ? field?.background : ''}`}>
					<ReportCell
						data={totalAmounts}
						field={field} />
				</td>}
				{reportData.map((item: any) =>
				{
					return (
						<td  className={`${!!field?.background ? field?.background : ''}`}>
							<ReportCell data={!!item.dividendPayments ? item.dividendPayments[section.index] : []} field={field}/>
						</td>
					);
				})
				 }
			</tr>);
	};

	const renderEquityClasses = (reportData: any, fieldKey: string): JSX.Element =>
	{
		const equityClasses: any[] = [];
		const data = reportData.map((item: any) => !!item[DividendPaidReportFields.Summary] &&
				!!item[DividendPaidReportFields.Summary][fieldKey] ?
			item[DividendPaidReportFields.Summary][fieldKey] : {
			});


		const uniqueEquityClassIds: number[]  = Array.from(new Set<number>(data.flatMap(Object.keys)
			.map((key: any) => Number(key)))).sort((a, b) => a - b);

		uniqueEquityClassIds.forEach((equityClassId: any) =>
		{
			const equityClassName = EngagementUtil.getEquityClassName(Number(equityClassId));
			equityClasses.push({
				'id': Number(equityClassId),
				'name': equityClassName
			});
		});

		return (
			<>
				{equityClasses.map((item: any) =>
				{
					const field = {
						'name': 'summary.' + fieldKey + '.' + item.id,
						'type': 'currency'
					};
					return (<tr className='left-indented'>
						<td >
							{item.name}
						</td>
						{!!totalAmounts && <td>
							<ReportCell
								data={totalAmounts}
								field={field} />
						</td>}
						{reportData.map((data: any) =>
						{
							return (
								<td>
									<ReportCell
										data={data}
										field={field}/>
								</td>
							);
						})}
					</tr>);
				})}
			</>
		);
	};

	return <div className='dividends-paid-report'>

		{DividendsPaidReportFields&&<div className='table-container'>
			{isLoading && (
				<div className="loading-icon">
					<Loading
						loadingType="circular"
						indeterminate={true}
						compact={false}
					/>
				</div>
			)}
			{reportData &&
			<table>
				<thead>
					<tr>
						<th colSpan={reportData.length+2}>
							<div className={'header-area'}>
								<div className={'left'}>
									<div className='header-title'>
										{t('header-title')}
									</div>
								</div>
								{reportData.length > 0 && <div className={'right'}>
									<div className={'button'}>
										<Button kind='text' className={'text'} onClick={() => handleToggleAll(false)}>
											{t('collapse-all')}
										</Button>
									</div>
									<div className={'button'}>
										<Button kind='text' className={'text'} onClick={() => handleToggleAll(true)}>
											{t('expand-all')}
										</Button>
									</div>
								</div>}
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					{report.header.map((field: any) =>
						<ReportHeader reportData={reportData} field={field}
							i18nKey={ReportI18NKeys.DividendsPaid} totalAmounts={totalAmounts}/>
					)}
					{report.summary.map((field: IReportField) =>
						<>
							<ReportRow reportData={reportData} field={field}
								i18nKey={ReportI18NKeys.DividendsPaid} totalAmounts={totalAmounts}/>
							{field.name ===
							(DividendPaidReportFields.Summary + '.' + DividendPaidReportFields.TotalPreAcquisitionSurplusBalance) &&
							reportData.length > 0 &&
								renderEquityClasses(reportData, DividendPaidReportFields.UpdatedPreAcquisitionSurpluses)
							}
							{field.name ===
							(DividendPaidReportFields.Summary + '.' + DividendPaidReportFields.TotalElectionDividendsBalance) &&
							reportData.length > 0 &&
								renderEquityClasses(reportData, DividendPaidReportFields.UpdatedElectionDividends)
							}
						</>
					)}
					{report.summaryTotals.map((field: IReportField) =>
						<ReportRow reportData={reportData} field={field}
							i18nKey={ReportI18NKeys.DividendsPaid} totalAmounts={totalAmounts}/>
					)}
					{sections.map((section: {index: number, collapsed: boolean}) =>
					{
						const isPost90DaySectionVisible = post90DaySections[section.index].isPost90DaySectionVisible;
						return (
							<>
	 							{report.details.map((field: any) =>
								{
									return (renderReportDetailsSection(section, field));
					 			})}
								{isPost90DaySectionVisible && report.post90DayDetails.map((field: IReportField) =>
								{
									return (renderReportDetailsSection(section, field));
								})}
							</>
						);
					})
					}
				</tbody>
			</table>
			}
		</div>}
	</div>;
};


export default DividendsPaidReport;