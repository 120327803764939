import { useEffect, useState } from 'react';
import { Loading, Notification} from '@appkit4/react-components';
import SurplusReportSection from '../ReportCells/SurplusReportSection';
import { ReportI18NKeys } from 'src/common/types/enums/ReportI18NKeys';
import { ISurplusReportField } from 'src/common/types/interfaces/IReportField';

import { useTranslation } from 'react-i18next';
import './HybridSurplusDetailsReport.scss';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { useParams } from 'react-router-dom';
import { NotificationService } from 'src/core/services/notificationService';
import { HybridSurplusDetailsReportFields } from '../Settings/HybridSurplusDetailsReportFields';

const HybridSurplusDetailsReport = (props: {refershReport: boolean}) =>
{
	const {engagementId, affiliateId } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [reportData, setReportData] = useState<any[]>([]);
	const reportFields: ISurplusReportField[] = HybridSurplusDetailsReportFields;

	const columnsCount = 6;
	const { t } = useTranslation(
		'report',
		{
			keyPrefix: ReportI18NKeys.HybridSurplusDetails
		}
	);

	const { t: report } = useTranslation(
		'report',
		{
			keyPrefix: ReportI18NKeys.Report
		}
	);

	const renderRowSeperator = () =>
	{
		return (<tr className='separator fixed'>{
			Array.from({
				length: columnsCount
			}, () =>
			{
				return ( <td></td>);
			}
			)
		}</tr>);
	};

	const triggerErrorNotification = async (message: string): Promise<void> =>
	{
		const ele = (
			<Notification
			  message={message}
			  status={'error'}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};

	const loadHybridSurplusDetailsReportdata = () =>
	{
		if ( engagementId && affiliateId )
		{
			setIsLoading(true);
			engagementService.loadHybridSurplusDetailsReport(Number(engagementId), Number(affiliateId)).then((res: any) =>
			{
				setIsLoading(false);
				setReportData(res.data.result);
			})
				.catch((error: any) =>
				{
					triggerErrorNotification(
						t('retrievalFailed')
					);
					setIsLoading(false);
				});
		}
	};

	useEffect(() =>
	{
		loadHybridSurplusDetailsReportdata();
	},[engagementId, affiliateId]);

	useEffect(() =>
	{
		if (!!props.refershReport)
		{
			loadHybridSurplusDetailsReportdata();
		}
	}, [props.refershReport]);

	return <>
		<div className="hybrid-surplus-details-report">
			<div className='table-container'>
				{isLoading && (
					<div className="loading-icon">
						<Loading
							loadingType="circular"
							indeterminate={true}
							compact={false}
						/>
					</div>
		 )}
				{reportData &&
			<table>
				<thead>
					<tr>
						<div className='header-title'>
							{t('header-title')}
						</div>
						{
							Array.from({
								length: columnsCount-1
							}, () =>
							{
								return ( <th></th>);
							}
							)
						}
					</tr>
				</thead>
				<tbody>
					{reportData.map(( item: any, index: number) =>

						<SurplusReportSection
							index={index}
							reportData={reportData}
							reportFields={reportFields}
							columnsCount={columnsCount}
							rowSeperator={renderRowSeperator}
							data={item}
							i18nKey={ReportI18NKeys.HybridSurplusDetails}/>
					 )}
				</tbody>
			</table>
			 }
			</div>
			{!isLoading && !!reportData && reportData.length === 0 &&
			<div className='report-no-data'>
				<div className='content'>
					<div className='title'>{report('report-not-ready')}</div>
					<div className='message'>{report('generate-report')}</div>
				</div>
			</div>
			}
		</div>
	</>;
};

export default HybridSurplusDetailsReport;