import { IAdjustedCostBaseFormProps } from './IAdjustedCostBaseFormProps';
import { DeleteModal } from 'src/common/components/DeleteModal/DeleteModal';
import { useTranslation } from 'react-i18next';
import { NotificationService } from 'src/core/services/notificationService';
import {
	Button,
	Input,
	Modal,
	Select,
	TextArea,
	TimePicker
} from '@appkit4/react-components';
import { useContext, useEffect, useState } from 'react';
import { UnsavedChangesModal } from 'src/core/components/Dashboard/unsavedModal/UnsavedChangesModal';
import { UnexpectedErrorModal } from 'src/common/components/UnexpectedErrorModal/UnexpectedErrorModal';
import { AdjustedCostBase, IAdjustedCostBase } from 'src/common/types/interfaces/IAdjustedCostBase';
import { AdjustedCostBaseFields } from 'src/common/types/enums/AdjustedCostBaseFields';
import { SelectValue } from '@appkit4/react-components/esm/combobox/Combobox';
import { RegularPatternEnum } from 'src/common/types/enums/RegularPatternEnum';
import { InputError } from 'src/common/components/ModalControls/InputError/InputError';
import { engagementService } from 'src/features/engagement/services/engagementService';
import AffiliateContext from 'src/common/components/SharedDataContext/AffiliateContext';
import { Utils } from 'src/common/utils/utils';
import { dateFormatter } from 'src/common/utils/dateFormatter';

import './AdjustedCostBaseForm.scss';
import { CalendarPickerWrapper } from 'src/common/components/CalendarPickerWrapper/CalendarPickerWrapper';

const defaultFormError: Record<string, string | null> = {
	[AdjustedCostBaseFields.date]: null,
	[AdjustedCostBaseFields.time]: null,
	[AdjustedCostBaseFields.isAddition]: null,
	[AdjustedCostBaseFields.transactionTypeId]: null,
	[AdjustedCostBaseFields.shareholderId]: null,
	[AdjustedCostBaseFields.equityClassId]: null,
	[AdjustedCostBaseFields.currencyId]: null,
	[AdjustedCostBaseFields.amount]: null,
	[AdjustedCostBaseFields.isPartnershipOwned]: null,
	[AdjustedCostBaseFields.partnershipsEquityPercentage]: null,
	[AdjustedCostBaseFields.partnershipName]: null,
	[AdjustedCostBaseFields.partnershipCurrencyId]: null
};

export function AdjustedCostBaseForm(props: IAdjustedCostBaseFormProps): JSX.Element
{
	const { t } = useTranslation(
		'input',
		{
			keyPrefix: 'AdjustedCostBaseForm'
		}
	);

	const { t: fieldNames } = useTranslation(
		'input',
		{
			keyPrefix: 'adjustedCostBaseFieldNames'
		}
	);

	const { t: errorMessages } = useTranslation(
		'input',
		{
			keyPrefix: 'AdjustedCostBaseFormErrorMessages'
		}
	);
	const affiliateContext = useContext(AffiliateContext);
	const [record, setRecord] = useState<IAdjustedCostBase | undefined>(undefined);

	const [hasChange, setHasChange] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [acbShareholders, setAcbShareholders] = useState<any>([]);

	const [formError, setFormError] = useState<Record<string, any>>(defaultFormError);

	const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
	const [displayUnsavedChangesModal, setDisplayUnsavedChangesModal] = useState(false);
	const [errorModalMessage, setErrorModalMessage] = useState('');
	const [isDisplayOnly, setIsDisplayOnly] = useState<boolean>(true);

	const triggerErrorNotification = (message: string): void =>
	{
		NotificationService.error({
			message
		});
	};

	const loadAdjustedCostBaseShareholders = async (
		engagementId: number,
		affiliateId: number,
		transactionDate?: Date
	): Promise<void> =>
	{
		try
		{
			const response = await engagementService
				.getAdjustedCostBaseShareholders(
					engagementId,
					affiliateId,
					transactionDate
				);

			if (!!response && !!response.data && !!response.data.result)
			{
				setAcbShareholders(response.data.result);
			}
		}
		catch (error)
		{
			triggerErrorNotification(
				errorMessages('loadingACBShareholdersError')
			);
		}
	};

	const onCreate = async (): Promise<void> =>
	{
		try
		{
			setIsSubmitted(true);

			if (!!checkUpdateFormValidity(record!))
			{
				setIsSaving(true);

				await engagementService.createAdjustedCostBase(
					record!
				);

				onCloseModal(true);
				setIsSaving(false);

				onSuccessNotification({
					isCreate: true
				});
			}
		}
		catch (error)
		{
			setErrorModalMessage(errorMessages('createFailed') || '');
			setIsSaving(false);
		}
	};

	const onUpdate = async (): Promise<void> =>
	{
		try
		{
			setIsSubmitted(true);

			if (!!checkUpdateFormValidity(record!))
			{
				setIsSaving(true);

				await engagementService.updateAdjustedCostBase({
					...record!,
					engagementId: props.engagementInfo?.id!
				});

				onCloseModal(true);
				setIsSaving(false);

				onSuccessNotification({
					isUpdate: true
				});
			}
		}
		catch (error)
		{
			setErrorModalMessage(errorMessages('updateFailed') || '');
			setIsSaving(false);
		}
	};

	const onDelete = async (): Promise<void> =>
	{
		try
		{
			setIsSubmitted(true);

			setDisplayDeleteModal(false);

			if (!!checkUpdateFormValidity(record!))
			{
				setIsSaving(true);

				await engagementService.deleteAdjustedCostBase(
					props.engagementInfo?.id!,
					record!.id
				);

				onCloseModal(true);
				setIsSaving(false);

				onSuccessNotification({
					isDelete: true
				});
			}
		}
		catch (error)
		{
			setErrorModalMessage(errorMessages('deleteFailed') || '');
			setIsSaving(false);
		}
	};

	const onDisplayDeleteModal = (): void =>
	{
		setDisplayDeleteModal(true);
	};

	const onCloseModal = (isSuccess?: boolean): void =>
	{
		if (!!hasChange && !isSuccess)
		{
			setDisplayUnsavedChangesModal(true);
		}
		else
		{
			onClose(!!isSuccess);
		}
	};

	const onCloseUnsavedChangesModal = (key: 'leave' | 'stay'): void =>
	{
		if (key === 'leave')
		{
			onClose();
		}
		else
		{
			setDisplayUnsavedChangesModal(false);
		}
	};

	const onReset = () =>
	{
		setIsSaving(false);
		setHasChange(false);
		setRecord(undefined);
		setIsSubmitted(false);
		setErrorModalMessage('');
		setDisplayDeleteModal(false);
		setDisplayUnsavedChangesModal(false);
		setFormError({
			...formError
		});
	};

	const onClose = async (isSuccess?: boolean): Promise<void> =>
	{
		setDisplayUnsavedChangesModal(false);
		props.onToggleDisplayModal(false);

		await Utils.timeout(500);

		onReset();
		props.onReset();

		if (!!isSuccess)
		{
			props.onRefresh();
		}
	};

	const onSuccessNotification = (
		{
			isCreate,
			isUpdate,
			isDelete
		}:
		{
			isCreate?: boolean;
			isUpdate?: boolean;
			isDelete?: boolean;
		}
	) =>
	{
		let message = '';

		if (isCreate)
		{
			message = t('createSuccess');
		}
		else if (isUpdate)
		{
			message = t('updateSuccess');
		}
		else if (isDelete)
		{
			message = t('deleteSuccess');
		}

		NotificationService.success({
			message
		});
	};

	const onValueChange = async (field: string, value: any): Promise<void> =>
	{
		let updatedForm: IAdjustedCostBase = {
			...record!,
			[field]: value === '' ? undefined : value
		};

		if (field === AdjustedCostBaseFields.currencyId &&
			updatedForm[AdjustedCostBaseFields.currencyId] !== props.record[AdjustedCostBaseFields.currencyId])
		{
			updatedForm  = {
				...updatedForm!,
				[AdjustedCostBaseFields.foreignExchangeRate]: undefined
			};
		}

		const mergedDateTime = dateFormatter.mergeDateTime(
			updatedForm.date!,
			updatedForm.time!
		);

		if ((field === AdjustedCostBaseFields.date || field === AdjustedCostBaseFields.time) && !!mergedDateTime)
		{
			updatedForm  = {
				...updatedForm!,
				[AdjustedCostBaseFields.shareholderId]: 0,
				[AdjustedCostBaseFields.equityClassId]: 0,
				[AdjustedCostBaseFields.effectiveDate]: new Date(mergedDateTime)
			};

			await loadAdjustedCostBaseShareholders(
				affiliateContext?.engagementDetail.id!,
				affiliateContext?.affiliateDetail.affiliateId!,
				updatedForm[AdjustedCostBaseFields.effectiveDate]
			);
		}

		if (field === AdjustedCostBaseFields.shareholderId)
		{
			updatedForm  = {
				...updatedForm!,
				[AdjustedCostBaseFields.equityClassId]: 0,
				[AdjustedCostBaseFields.isPartnershipOwned]: value < 0
			};
		}

		checkUpdateFormValidity(updatedForm);

		setRecord(updatedForm);
	};

	const checkUpdateFormValidity = (form: IAdjustedCostBase): boolean =>
	{
		const errors = {
			...defaultFormError
		};

		if (!form[AdjustedCostBaseFields.date])
		{
			errors[AdjustedCostBaseFields.date] = errorMessages('dateRequired');
		}

		if (!form[AdjustedCostBaseFields.time])
		{
			errors[AdjustedCostBaseFields.time] = errorMessages('timeRequired');
		}

		if (form[AdjustedCostBaseFields.isAddition] === undefined)
		{
			errors[AdjustedCostBaseFields.isAddition] = errorMessages('typeOfTransactionRequired');
		}

		if (!form[AdjustedCostBaseFields.transactionTypeId] && !!form[AdjustedCostBaseFields.isAddition])
		{
			errors[AdjustedCostBaseFields.transactionTypeId] = errorMessages('typeOfAdditionDeductionRequired');
		}

		if (form[AdjustedCostBaseFields.shareholderId] === 0)
		{
			errors[AdjustedCostBaseFields.shareholderId] = errorMessages('shareholderRequired');
		}

		if (!form[AdjustedCostBaseFields.equityClassId]  && !!form[AdjustedCostBaseFields.shareholderId])
		{
			errors[AdjustedCostBaseFields.equityClassId] = errorMessages('equityClassRequired');
		}

		if (!form[AdjustedCostBaseFields.currencyId])
		{
			errors[AdjustedCostBaseFields.currencyId] = errorMessages('currencyRequired');
		}

		if (!form[AdjustedCostBaseFields.amount])
		{
			errors[AdjustedCostBaseFields.amount] = errorMessages('amountRequired');
		}

		if (form[AdjustedCostBaseFields.isPartnershipOwned] === undefined)
		{
			errors[AdjustedCostBaseFields.isPartnershipOwned] = errorMessages('partnershipOwnedRequired');
		}

		if (!form[AdjustedCostBaseFields.partnershipsEquityPercentage] && !!form[AdjustedCostBaseFields.isPartnershipOwned] &&
			!!props.record && props.record[AdjustedCostBaseFields.isAutomaticEntry])
		{
			errors[AdjustedCostBaseFields.partnershipsEquityPercentage] = errorMessages('partnershipsEquityPercentageRequired');
		}

		if (!form[AdjustedCostBaseFields.partnershipName] && !!form[AdjustedCostBaseFields.isPartnershipOwned] &&
			!!props.record && !props.record[AdjustedCostBaseFields.isAutomaticEntry])
		{
			errors[AdjustedCostBaseFields.partnershipName] = errorMessages('partnershipNameRequired');
		}

		if (!form[AdjustedCostBaseFields.partnershipCurrencyId] && !!form[AdjustedCostBaseFields.isPartnershipOwned]&&
			!!props.record && !props.record[AdjustedCostBaseFields.isAutomaticEntry])
		{
			errors[AdjustedCostBaseFields.partnershipCurrencyId] = errorMessages('currencyRequired');
		}

		setFormError({
			...errors
		});

		// check if any fields have error messages and return status
		return Object.values(errors).every((e) => !e);
	};

	const onFocus = () =>
	{
		setIsDisplayOnly(false);
	};

	const onBlur = () =>
	{
		setIsDisplayOnly(true);
	};

	useEffect(
		() =>
		{
			if (!!props.record)
			{
				setRecord({
					...props.record
				});

				if (!!props.record[AdjustedCostBaseFields.effectiveDate])
				{
					loadAdjustedCostBaseShareholders(
							affiliateContext?.engagementDetail.id!,
							affiliateContext?.affiliateDetail.affiliateId!,
							props.record[AdjustedCostBaseFields.effectiveDate]
					);
				}
			}
			else
			{
				setRecord(undefined);
			}
		},
		[props.record]
	);

	useEffect(
		() =>
		{
			setHasChange(
				!AdjustedCostBase.isSame(props.record, record)
			);
		},
		[record]
	);

	return <>
		<Modal
			className={'adjusted-cost-base-modal'}
			visible={
				props.displayModal &&
				!!props.record &&
				!!record
			}
			closeOnPressEscape={false}
			maskCloseable={false}
			initialFocus={false}
			title={
				!!props.isAdd ?
					t('addModalTitle') || '' :
					t('editModalTitle') || ''
			}
			onCancel={() =>
			{
				onCloseModal();
			}}
			footer={
				<div className={'buttons'}>
					<div className={'left'}>
						{
							!props.isAdd && !!props.record && !props.record[AdjustedCostBaseFields.isAutomaticEntry] &&
							<Button
								kind={'negative'}
								disabled={!!isSaving}
								onClick={() => onDisplayDeleteModal()}
							>
								{
									t('deleteButtonText')
								}
							</Button>
						}
					</div>
					<div className={'right'}>
						{
							!!props.isAdd &&
							<Button
								disabled={
									!hasChange ||
									!!isSaving
								}
								onClick={onCreate}
							>
								{
									t('saveButtonText')
								}
							</Button>
						}
						{
							!props.isAdd &&
							<Button
								disabled={
									!hasChange ||
									!!isSaving
								}
								onClick={onUpdate}
							>
								{
									t('saveButtonText')
								}
							</Button>
						}
						<Button
							kind={'secondary'}
							disabled={!!isSaving}
							onClick={() =>
							{
								onCloseModal();
							}}
						>
							{
								t('cancelButtonText')
							}
						</Button>
					</div>
				</div>
			}
		>
			{
				!!props.record &&
				!!record &&
				!!props.lineConfiguration &&
				!!props.lineConfiguration.length &&
				<div className={'adjusted-cost-base-modal-body'}>
					<div className={'adjusted-cost-base-form-body'}>
						<div className={'calendar-time'}>
							<CalendarPickerWrapper
								required
								disabled={!!props.record && props.record[AdjustedCostBaseFields.isAutomaticEntry]}
								useCustomValidation
								editable={false}
								fieldTitle={
									fieldNames(AdjustedCostBaseFields.date) || ''
								}
								format={'MMM DD, YYYY'}
								value={record[AdjustedCostBaseFields.date]}
								minDate={
									!!props.foreignAffiliate && !!props.foreignAffiliate.dateBecameFa ?
										props.foreignAffiliate.dateBecameFa :
										new Date(1972, 1, 1)
								}
								maxDate={
									!!props.foreignAffiliate && !!props.foreignAffiliate.analysisEndDate ?
										props.foreignAffiliate.analysisEndDate :
										(!!props.engagementInfo && !!props.engagementInfo.lastCalculationTaxationYearEnd ?
											props.engagementInfo.lastCalculationTaxationYearEnd :
											new Date())
								}
								datePanelHorizontalAlign={'right'}
								error={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.date]
								}
								customErrorNode={<></>}
								onChange={(value: Date) =>
								{
									onValueChange(AdjustedCostBaseFields.date, new Date(value));
								}}
							/>
							<InputError
								isError={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.date]
								}
								message={formError[AdjustedCostBaseFields.date]}
							/>
						</div>
						<div className={'calendar-time'}>
							<TimePicker
								required
								disabled={!!props.record && props.record[AdjustedCostBaseFields.isAutomaticEntry]}
								hourTime={12}
								fieldTitle={
									fieldNames(AdjustedCostBaseFields.time) || ''
								}
								value={record[AdjustedCostBaseFields.time]}
								onChange={(value: Date) =>
								{
									onValueChange(AdjustedCostBaseFields.time, new Date(value));
								}}
								error={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.time]
								}
							/>
							<InputError
								isError={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.time]
								}
								message={formError[AdjustedCostBaseFields.time]}
							/>
						</div>
						<div>
							<Select
								required
								disabled={!!props.record && props.record[AdjustedCostBaseFields.isAutomaticEntry]}
								data={
									!!props.lineConfiguration &&
									props.lineConfiguration.some((l) => l.field === AdjustedCostBaseFields.isAddition) ?
										props.lineConfiguration.find((l) => l.field === AdjustedCostBaseFields.isAddition)!.options :
										[]
								}
								labelKey={'label'}
								valueKey={'value'}
								dropdownRenderMode={'portal'}
								value={
									record[AdjustedCostBaseFields.isAddition] === undefined ? undefined :
										`${record[AdjustedCostBaseFields.isAddition]}`
								}
								placeholder={
									fieldNames(AdjustedCostBaseFields.isAddition) || ''
								}
								error={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.isAddition]
								}
								noResultFound={
									<>
										{
											t('noDataAvailable')
										}
									</>
								}
								onSelect={(value: SelectValue) =>
								{
									onValueChange(
										AdjustedCostBaseFields.isAddition,
										JSON.parse(value.toString().toLowerCase())
									);
								}}
							/>
							<InputError
								isError={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.isAddition]
								}
								message={formError[AdjustedCostBaseFields.isAddition]}
							/>
						</div>
						<div>
							<Select
								required
								disabled={record[AdjustedCostBaseFields.isAddition] === undefined ||
									(!!props.record && props.record[AdjustedCostBaseFields.isAutomaticEntry])
								}
								searchable
								data={
									!!props.lineConfiguration &&
									props.lineConfiguration.some((l) => l.field === AdjustedCostBaseFields.transactionTypeId) ?
										props.lineConfiguration.find((l) => l.field === AdjustedCostBaseFields.transactionTypeId)!.options
											.filter((t: any) =>
												t[AdjustedCostBaseFields.isAddition] === record[AdjustedCostBaseFields.isAddition]) : []
								}
								labelKey={'label'}
								valueKey={'value'}
								dropdownRenderMode={'portal'}
								value={record[AdjustedCostBaseFields.transactionTypeId]}
								placeholder={record[AdjustedCostBaseFields.isAddition] === undefined ?
									fieldNames(AdjustedCostBaseFields.transactionTypeId) || '' :
									(!!record[AdjustedCostBaseFields.isAddition] ?
										fieldNames('typeOfAddition') || '': fieldNames('TypeOfDeduction')|| '')
								}
								error={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.transactionTypeId]
								}
								noResultFound={
									<>
										{
											t('noDataAvailable')
										}
									</>
								}
								onSelect={(value: SelectValue) =>
									onValueChange(AdjustedCostBaseFields.transactionTypeId, value)
								}
							/>
							<InputError
								isError={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.transactionTypeId]
								}
								message={formError[AdjustedCostBaseFields.transactionTypeId]}
							/>
						</div>
						<div>
							<TextArea
								autosize
								title={
									fieldNames(AdjustedCostBaseFields.description) || ''
								}
								value={
									!!record[AdjustedCostBaseFields.description] ?
										record[AdjustedCostBaseFields.description] :
										''
								}
								maxLength={255}
								error={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.description]
								}
								onChange={(value: string) =>
								{
									onValueChange(AdjustedCostBaseFields.description, value);
								}}
							/>
							<InputError
								isError={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.description]
								}
								message={formError[AdjustedCostBaseFields.description]}
							/>
						</div>
						<div>
							<Select
								required
								disabled={!!props.record && props.record[AdjustedCostBaseFields.isAutomaticEntry]}
								searchable
								data={!!acbShareholders && acbShareholders.length > 0 ? [...acbShareholders, {
									[AdjustedCostBaseFields.shareholderId]: -1,
									[AdjustedCostBaseFields.shareholderName]: t('other')
								}] : []}
								labelKey={'shareholderName'}
								valueKey={'shareholderId'}
								dropdownRenderMode={'portal'}
								dropdownAlwaysDown={false}
								value={record[AdjustedCostBaseFields.shareholderId] !== null ?
									record[AdjustedCostBaseFields.shareholderId] : -1}
								placeholder={
									fieldNames(AdjustedCostBaseFields.shareholderName) || ''
								}
								error={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.shareholderId]
								}
								noResultFound={
									<>
										{
											t('noDataAvailable')
										}
									</>
								}
								onSelect={(value: SelectValue) =>
									onValueChange(AdjustedCostBaseFields.shareholderId, value)
								}
							/>
							<InputError
								isError={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.shareholderId]
								}
								message={formError[AdjustedCostBaseFields.shareholderId]}
							/>
						</div>
						<div>
							<Select
								required
								disabled={record[AdjustedCostBaseFields.shareholderId] === 0 ||
									(!!props.record && props.record[AdjustedCostBaseFields.isAutomaticEntry])}
								searchable
								data= {(!!record && !!acbShareholders && acbShareholders.length > 0 &&
										!!record[AdjustedCostBaseFields.shareholderId] &&
										record[AdjustedCostBaseFields.shareholderId] > 0) ?
									(!!props.lineConfiguration &&
											props.lineConfiguration.some((l) => l.field === AdjustedCostBaseFields.equityClassId) ?
											props.lineConfiguration.find((l) => l.field === AdjustedCostBaseFields.equityClassId)!.options
												.filter((e: any) =>
													!!acbShareholders.find((sh: any) => sh[AdjustedCostBaseFields.shareholderId] ===
													record[AdjustedCostBaseFields.shareholderId]) &&
													acbShareholders.find((sh: any) => sh[AdjustedCostBaseFields.shareholderId] ===
													record[AdjustedCostBaseFields.shareholderId]).equityClassIds.includes(e.value)) : []) :
									(!!props.lineConfiguration &&
											props.lineConfiguration.some((l) => l.field === AdjustedCostBaseFields.equityClassId) ?
												props.lineConfiguration.find((l) =>
													l.field === AdjustedCostBaseFields.equityClassId)!.options : [])
								}
								labelKey={'label'}
								valueKey={'value'}
								dropdownRenderMode={'portal'}
								dropdownAlwaysDown={false}
								value={record[AdjustedCostBaseFields.equityClassId]}
								placeholder={
									fieldNames(AdjustedCostBaseFields.equityClassId) || ''
								}
								error={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.equityClassId]
								}
								noResultFound={
									<>
										{
											t('noDataAvailable')
										}
									</>
								}
								onSelect={(value: SelectValue) =>
									onValueChange(AdjustedCostBaseFields.equityClassId, value)
								}
							/>
							<InputError
								isError={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.equityClassId]
								}
								message={formError[AdjustedCostBaseFields.equityClassId]}
							/>
						</div>
						<div>
							<Select
								required
								disabled={!!props.record && props.record[AdjustedCostBaseFields.isAutomaticEntry]}
								searchable
								data={
									!!props.lineConfiguration &&
									props.lineConfiguration.some((l) => l.field === AdjustedCostBaseFields.currencyId) ?
										props.lineConfiguration.find((l) => l.field === AdjustedCostBaseFields.currencyId)!.options :
										[]
								}
								labelKey={'label'}
								valueKey={'value'}
								dropdownRenderMode={'portal'}
								dropdownAlwaysDown={false}
								value={record[AdjustedCostBaseFields.currencyId]}
								placeholder={
									fieldNames(AdjustedCostBaseFields.currencyId) || ''
								}
								error={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.currencyId]
								}
								noResultFound={
									<>
										{
											t('noDataAvailable')
										}
									</>
								}
								onSelect={(value: SelectValue) =>
									onValueChange(AdjustedCostBaseFields.currencyId, value)
								}
							/>
							<InputError
								isError={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.currencyId]
								}
								message={formError[AdjustedCostBaseFields.currencyId]}
							/>
						</div>
						<div>
							<Input
								required
								disabled={!!props.record && props.record[AdjustedCostBaseFields.isAutomaticEntry]}
								type={'text'}
								title={
									fieldNames(AdjustedCostBaseFields.amount) || ''
								}
								value={!Utils.isNullUndefined(record[AdjustedCostBaseFields.amount] ) ?
									(!!isDisplayOnly ? Utils.formatCurrency(`${record[AdjustedCostBaseFields.amount]}`, 2) :
										`${record[AdjustedCostBaseFields.amount]}`)	 :	''
								}
								error={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.amount]
								}
								onChange={(value: string) =>
								{
									const regPattern = new RegExp(RegularPatternEnum.CurrencyNonNegative);
									if (!regPattern.test(value))
									{
										return;
									}

									onValueChange(
										AdjustedCostBaseFields.amount,
										value
									);
								}}
								onBlur={onBlur}
								onFocus={onFocus}
							/>
							<InputError
								isError={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.amount]
								}
								message={formError[AdjustedCostBaseFields.amount]}
							/>
						</div>
						<div>
							<Input
								type={'text'}
								title={
									fieldNames(AdjustedCostBaseFields.foreignExchangeRate) || ''
								}
								value={
									record[AdjustedCostBaseFields.foreignExchangeRate] !== undefined &&
									record[AdjustedCostBaseFields.foreignExchangeRate] !== null ?
										`${record[AdjustedCostBaseFields.foreignExchangeRate]}` :
										''
								}
								error={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.foreignExchangeRate]
								}
								onChange={(value: string) =>
								{
									const regPattern = new RegExp(RegularPatternEnum.FxRateNonNegative);
									if (!regPattern.test(value))
									{
										return;
									}

									onValueChange(
										AdjustedCostBaseFields.foreignExchangeRate,
										value
									);
								}}
							/>
							<InputError
								isError={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.foreignExchangeRate]
								}
								message={formError[AdjustedCostBaseFields.foreignExchangeRate]}
							/>
						</div>
						<div>
							<Select
								required
								disabled={!!props.record && !props.record[AdjustedCostBaseFields.isAutomaticEntry] &&
									record[AdjustedCostBaseFields.shareholderId] !== 0
								}
								data={
									!!props.lineConfiguration &&
									props.lineConfiguration.some((l) => l.field === AdjustedCostBaseFields.isPartnershipOwned) ?
										props.lineConfiguration.find((l) => l.field ===
										AdjustedCostBaseFields.isPartnershipOwned)!.options : []
								}
								labelKey={'label'}
								valueKey={'value'}
								dropdownRenderMode={'portal'}
								value={
									record[AdjustedCostBaseFields.isPartnershipOwned] ?
										'true' :
										'false'
								}
								placeholder={
									fieldNames(AdjustedCostBaseFields.isPartnershipOwned) || ''
								}
								error={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.isPartnershipOwned]
								}
								noResultFound={
									<>
										{
											t('noDataAvailable')
										}
									</>
								}
								onSelect={(value: SelectValue) =>
								{
									onValueChange(
										AdjustedCostBaseFields.isPartnershipOwned,
										JSON.parse(value.toString().toLowerCase())
									);
								}}
							/>
							<InputError
								isError={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.isPartnershipOwned]
								}
								message={formError[AdjustedCostBaseFields.isPartnershipOwned]}
							/>
						</div>
						{!!props.record && props.record[AdjustedCostBaseFields.isAutomaticEntry] &&
						!!record[AdjustedCostBaseFields.isPartnershipOwned] &&
							<div>
								<Input
									required
									type={'text'}
									title={
										fieldNames(AdjustedCostBaseFields.partnershipsEquityPercentage) || ''
									}
									value={
										Utils.isValidNumber(`${record[AdjustedCostBaseFields.partnershipsEquityPercentage]}`) ?
											record[AdjustedCostBaseFields.partnershipsEquityPercentage] :
											''
									}
									error={
										!!isSubmitted &&
										!!formError &&
										!!formError[AdjustedCostBaseFields.partnershipsEquityPercentage]
									}
									onChange={(value: string) =>
									{
										const regPattern = new RegExp(RegularPatternEnum.Percentage);
										if (!regPattern.test(value))
										{
											return;
										}
										if (Number(value)>100||Number(value)<0)
										{
											return;
										}

										onValueChange(
											AdjustedCostBaseFields.partnershipsEquityPercentage,
											Utils.isValidNumber(value) ?
												value:
												''
										);
									}}
								/>
								<InputError
									isError={
										!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.partnershipsEquityPercentage]
									}
									message={formError[AdjustedCostBaseFields.partnershipsEquityPercentage]}
								/>
							</div>}
						{!!props.record && !props.record[AdjustedCostBaseFields.isAutomaticEntry] &&
							!!record[AdjustedCostBaseFields.isPartnershipOwned] &&
							<div>
								<TextArea
									autosize
									title={
										fieldNames(AdjustedCostBaseFields.partnershipName) || ''
									}
									value={
										!!record[AdjustedCostBaseFields.partnershipName] ?
											record[AdjustedCostBaseFields.partnershipName] :
											''
									}
									maxLength={255}
									error={
										!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.partnershipName]
									}
									onChange={(value: string) =>
									{
										onValueChange(AdjustedCostBaseFields.partnershipName, value);
									}}
								/>
								<InputError
									isError={
										!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.partnershipName]
									}
									message={formError[AdjustedCostBaseFields.partnershipName]}
								/>
							</div>}
						{!!props.record && !props.record[AdjustedCostBaseFields.isAutomaticEntry] &&
							!!record[AdjustedCostBaseFields.isPartnershipOwned] &&
						<div>
							<Select
								required
								searchable
								data={
									!!props.lineConfiguration &&
									props.lineConfiguration.some((l) => l.field === AdjustedCostBaseFields.partnershipCurrencyId) ?
										props.lineConfiguration.find((l) => l.field ===
										AdjustedCostBaseFields.partnershipCurrencyId)!.options : []
								}
								labelKey={'label'}
								valueKey={'value'}
								dropdownRenderMode={'portal'}
								value={record[AdjustedCostBaseFields.partnershipCurrencyId]}
								placeholder={
									fieldNames(AdjustedCostBaseFields.partnershipCurrencyId) || ''
								}
								error={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.partnershipCurrencyId]
								}
								noResultFound={
									<>
										{
											t('noDataAvailable')
										}
									</>
								}
								onSelect={(value: SelectValue) =>
									onValueChange(AdjustedCostBaseFields.partnershipCurrencyId, value)
								}
							/>
							<InputError
								isError={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.partnershipCurrencyId]
								}
								message={formError[AdjustedCostBaseFields.partnershipCurrencyId]}
							/>
						</div>}
						<div>
							<Input
								//required
								type={'text'}
								title={
									fieldNames(AdjustedCostBaseFields.noOfSharesAcquiredOrDisposed) || ''
								}
								value={
									record[AdjustedCostBaseFields.noOfSharesAcquiredOrDisposed] !== undefined &&
									record[AdjustedCostBaseFields.noOfSharesAcquiredOrDisposed] !== null ?
										`${record[AdjustedCostBaseFields.noOfSharesAcquiredOrDisposed]}` :
										''
								}
								error={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.noOfSharesAcquiredOrDisposed]
								}
								onChange={(value: string) =>
								{
									const regPattern = new RegExp(RegularPatternEnum.Integer);
									if (!regPattern.test(value))
									{
										return;
									}

									onValueChange(
										AdjustedCostBaseFields.noOfSharesAcquiredOrDisposed,
										value
									);
								}}
							/>
							<InputError
								isError={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.noOfSharesAcquiredOrDisposed]
								}
								message={formError[AdjustedCostBaseFields.noOfSharesAcquiredOrDisposed]}
							/>
						</div>

						<div>
							<TextArea
								autosize
								disabled={!!props.record && props.record[AdjustedCostBaseFields.isAutomaticEntry]}
								title={
									fieldNames(AdjustedCostBaseFields.notes) || ''
								}
								value={
									!!record[AdjustedCostBaseFields.notes] ?
										record[AdjustedCostBaseFields.notes] :
										''
								}
								maxLength={255}
								error={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.notes]
								}
								onChange={(value: string) =>
								{
									onValueChange(AdjustedCostBaseFields.notes, value);
								}}
							/>
							<InputError
								isError={
									!!isSubmitted &&
									!!formError &&
									!!formError[AdjustedCostBaseFields.notes]
								}
								message={formError[AdjustedCostBaseFields.notes]}
							/>
						</div>
					</div>
				</div>
			}
		</Modal>
		<UnsavedChangesModal
			visible={displayUnsavedChangesModal}
			onClose={onCloseUnsavedChangesModal}
			title={t('discardChangesTitle') || ''}
		/>
		<UnexpectedErrorModal
			visible={!!errorModalMessage}
			error={''}
			message={errorModalMessage}
			onClose={() =>
			{
				setErrorModalMessage('');
			}}
		/>
		<DeleteModal
			visible={displayDeleteModal}
			title={t('deleteModalTitle')}
			deleteMessage={t('deleteMessage')}
			setVisible={setDisplayDeleteModal}
			onDelete={onDelete}
		/>
	</>;
}