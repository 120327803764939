import { GridCellProps } from '@progress/kendo-react-grid';
import * as React from 'react';
import { Select } from '@appkit4/react-components';
import { SelectValue } from '@appkit4/react-components/esm/combobox/Combobox';
import { useEffect, useState } from 'react';
import './DropdownEditorCell.scss';

interface Props {
  fieldErrors: Record<string, any>;
  dropdownValues?: Array<Record<string, any>>;
  valueKey: string;
  labelKey: string;
  handleInputChange: Function;
  handleRowClick: Function;
  handleInputClick: Function;
  placeholder?: string;
}

export const DropdownEditorCell = (props: GridCellProps & Props) =>
{
	const {
		dataItem,
		field,
		valueKey,
		labelKey,
		handleInputChange,
		handleRowClick,
		handleInputClick,
		dropdownValues,
		fieldErrors,
		placeholder
	} = props;

	const [SelectValue, setSelectValue] = useState('');
	const [changed, setChanged] = useState<boolean>(false);
	const [dataValue, setDataValue] = useState<string>('');
	const [existingError, setExistingError] = useState<boolean>(false);

	useEffect(() =>
	{
		setExistingError(!!fieldErrors);
	}, [fieldErrors]);

	const onClick = () =>
	{
		handleRowClick(field, dataItem);
	};

	useEffect(() =>
	{
		if (dataItem?.inEditableMode === false)
		{
			if (field === 'userRole')
			{
				const selectObj = dropdownValues?.find(
					(d) => d.id === dataItem[field]
				);
				if (selectObj)
				{
					setSelectValue(selectObj[labelKey]);
				}
			}
			else if (field)
			{
				const selectObj = dropdownValues?.find(
					(d) => d.id === dataItem[field]
				);
				if (selectObj)
				{
					setSelectValue(selectObj[labelKey]);
				}
			}
		}
	}, []);

	useEffect(() =>
	{
		if (dataItem)
		{
			const d =
        dropdownValues?.filter(
        	(v) => v[valueKey] === dataItem[field ? field : '']
        )?.[0]?.[valueKey] || '';
			setDataValue(d);
		}
	}, [dataItem]);

	const onSelectValue = (value: any) =>
	{
		setChanged(true);
		setDataValue(value);
		handleInputChange?.({
		}, field, value);
	};

	const getNoDataTemplate = (): React.ReactNode =>
	{
		return <>No Data Available</>;
	};

	return (
		<td>
			{dataItem?.inEditableMode ? (
				<div className="custom-cell">
					<div
						className={`custom-input ${field}  ${
							!changed &&
              //  fieldErrors &&
              fieldErrors?.clientSideErr[field ? field : ''] ?
								'custom-input-error' :
								''
						}`}
						onClick={() => handleInputClick(field)}
					>
						<Select
							className="client-name-style"
							data={dropdownValues}
							hideTitleOnInput={true}
							valueKey={valueKey}
							labelKey={labelKey}
							searchable={true}
							value={dataValue}
							placeholder={placeholder || ''}
							noResultFound={getNoDataTemplate()}
							dropdownRenderMode="portal"
							dropdownAlwaysDown={false}
							onSelect={(value: SelectValue) => onSelectValue(value)}
						/>
					</div>
					{/* {existingError ? "yes" : "no"} */}

					{/* <div className="custom-cell-error">
            {changed
              ? ""
              : !existingError
              ? ""
              : fieldErrors["clientSideErr"][field ? field : ""]}
          </div> */}

					{(changed ? '' : fieldErrors?.clientSideErr[field ? field : '']) ? (
						<div className="custom-cell-error">
							{fieldErrors?.clientSideErr[field ? field : '']}
						</div>
					) : (
						<>
							{existingError && <div className="custom-cell-error">&nbsp;</div>}
						</>
					)}
				</div>
			) : (
				<div className="custom-cell-value" onClick={onClick}>
					{SelectValue}
				</div>
			)}
		</td>
	);
};
