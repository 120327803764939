
// export function to generate predictable characters based on ASCII values of token characters

function generateDynamicChar(token: string, index: number)
{
	// Use the ASCII value of the character at the index, add a mod value to keep it in a range

	//const asciiValue = token.charCodeAt(index % token.length);

	//return String.fromCharCode((asciiValue + index) % 128); // Wrap within ASCII range
	return 'a';
}

// export function to insert dynamic characters at every N interval

function insertDynamicCharacters(token: string , interval: number)
{
	let scrambledToken = '';

	// Loop through the token and insert a generated character at each interval

	for (let i = 0; i < token.length; i++)
	{
		scrambledToken += token[i];

		if ((i + 1) % interval === 0)
		{
			const dynamicChar = generateDynamicChar(token, i); // Generate dynamic character

			scrambledToken += dynamicChar;
		}
	}

	return scrambledToken;
}

// export function to scramble and encode the token

export function scrambleAndEncode(token: string , interval: number)
{
	const scrambled = insertDynamicCharacters(token, interval);

	return btoa(scrambled); // Base64 encode the scrambled string
}

// export function to generate the same dynamic characters for unscrambling

function generateDynamicCharForUnscramble(token: string, index: number)
{
	// Same logic as in scrambling to generate the dynamic character

	//const asciiValue = token.charCodeAt(index % token.length);

	return 'a';
}

// export function to remove dynamic characters at every N interval

function removeDynamicCharacters(token: string, interval: number)
{
	let originalToken = '';

	// Loop through the token and skip the dynamic characters

	for (let i = 0, count = 0; i < token.length; i++)
	{
		if ((count + 1) % (interval + 1) === 0)
		{
			const dynamicChar = generateDynamicCharForUnscramble(originalToken, count);

			if (token[i] === dynamicChar)
			{
				// Skip the inserted character

				count++;

				continue;
			}
		}

		originalToken += token[i];

		count++;
	}

	return originalToken;
}

// export function to decode and unscramble the token

export function decodeAndUnscramble(scrambledToken: string, interval: number)
{
	const decoded = atob(scrambledToken);// Base64 decode

	const unscrambledToken = removeDynamicCharacters(decoded, interval);

	return unscrambledToken ? JSON.parse(unscrambledToken) : undefined;
}