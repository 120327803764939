import * as React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { TimePicker } from '@appkit4/react-components';
import { useEffect } from 'react';
import './TimePickerEditor.scss';
import { dateFormatter } from 'src/common/utils/dateFormatter';

interface Props {
	fieldErrors: Record<string, any>;
	handleInputChange: any;
	handleRowClick: Function;
	handleInputClick: Function;
  }

export const TimePickerEditor = (props: GridCellProps & Props) =>
{
	  const {
		  dataItem,
		  fieldErrors,
		  handleInputChange,
		  handleRowClick,
		  handleInputClick
	  } = props;
	  const field = props.field || '';
	const dataValue = dataItem[field] === null ? undefined : dataItem[field];
	const [changed, setChanged] = React.useState<boolean>(false);
	const [existingError, setExistingError] = React.useState<boolean>(false);

	useEffect(() =>
	{
		setExistingError(!!fieldErrors);
	}, [fieldErrors]);

	const handleChange = (params: any) =>
	{
		if (params)
		{
			setChanged(true);
			handleInputChange(params, field, params);
		}
	};

	const onClick = () =>
	{
		handleRowClick(field, dataItem);
	};

	return (
		<>
			<td>
				{dataItem.inEditableMode ? (
					<div className="custom-cell">
						<div
							className={`custom-input ${field}  ${
								!changed && fieldErrors?.clientSideErr[field ? field : ''] ?
									'custom-input-error' :
									''
							}`}
							onClick={() => handleInputClick(field)}
						>
							<TimePicker
								fieldTitle={''}
								hourTime={12}
								hideTitleOnInput={true}
								value={new Date(dataValue)}
								onChange={handleChange}
								error={
									!changed && fieldErrors?.clientSideErr[field ? field : '']
								}
								customErrorNode={<></>}
							/>
						</div>
						{(changed ? '' : fieldErrors?.clientSideErr[field ? field : '']) ? (
							<div className="custom-cell-error">
								{fieldErrors?.clientSideErr[field ? field : '']}
							</div>
						) : (
							<>
								{existingError && (
									<div className="custom-cell-error">&nbsp;</div>
								)}
							</>
						)}
					</div>
				) : (
					<div className="custom-cell-value" onClick={onClick}>
						{dateFormatter.format(props.dataItem[field], 'hh:mm A')}
					</div>
				)}
			</td>
		</>
	);
};