import {
	Grid,
	GridCellProps,
	GridColumn,
	GridDetailRowProps
} from '@progress/kendo-react-grid';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Button, Loading } from '@appkit4/react-components';
import { EngagementPersonalizationStatus } from 'src/core/types/enums/EngagementPersonalizationStatus';
import { Popup } from '@progress/kendo-react-popup';
import { EngagementCommand } from 'src/core/types/enums/EngagementCommand';

import './EngagementList.scss';
import { useAppSelector } from 'src/store/store';
//@ts-ignore
import Highlighter from 'react-highlight-words';
import { useNavigate } from 'react-router-dom';
import { RoleEnum } from 'src/common/types/enums/RoleEnum';

interface GridDetailRowCustomProps {
	engagementPersonalizationStatus: EngagementPersonalizationStatus;
	searchText: string;
	onCommand: (
		command: EngagementCommand,
		engagementPersonalizationStatus: EngagementPersonalizationStatus,
		dataItem: any,
		clientId: string,
		reload: boolean
	) => void;
}

export const EngagementList = (
	props: GridDetailRowProps & GridDetailRowCustomProps
) =>
{
	const [data, setData] = useState(props.dataItem.engagements);
	const { t } = useTranslation('homeDashboard');
	const navigate = useNavigate();
	const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>) =>
	{
		const white = {
			backgroundColor: 'white'
		};
		const trProps: any = {
			style: white
		};
		return React.cloneElement(trElement, {
			...trProps
		});
	};

	useEffect(() =>
	{
		setData(props.dataItem.engagements);
	}, [props.dataItem.engagements]);

	// const [loading, setLoading] = useState<false>

	const handleCommand = (
		event: any,
		gridCellProps: GridCellProps,
		engagementPersonalizationStatus: EngagementPersonalizationStatus,
		command: EngagementCommand
	) =>
	{
		event.currentTarget.disabled = true;
		props.onCommand(
			command,
			engagementPersonalizationStatus,
			gridCellProps.dataItem,
			props.dataItem.id,
			data.length > 1
		);
	};

	const CommandCell = (gridCellProps: GridCellProps) =>
	{
		const tripleDotRef = useRef<HTMLSpanElement>(null);
		const userProfile = useAppSelector((state) => state.userProfile.profile);

		const [show, setShow] = useState(false);
		const [anchor, setAnchor] = useState(null);
		const handleClick = (event: any) =>
		{
			if (tripleDotRef.current?.contains(event.target as Node))
			{
				setShow(true);
			}
			else
			{
				setShow(false);
			}
		};

		useEffect(() =>
		{
			document.addEventListener('click', handleClick);

			return () =>
			{
				document.removeEventListener('click', handleClick);
			};
		}, [show]);

		const onClose = () =>
		{
			setShow(false);
		};

		const showContext = (e: any) =>
		{
			setAnchor(e.currentTarget);
		};

		const contextMenu = (
			<div className="engagement-context-menu">
				<Button
					kind="text"
					className="engagement-context-menu-item"
					onClick={(event: any) =>
						handleCommand(
							event,
							gridCellProps,
							EngagementPersonalizationStatus.My,
							EngagementCommand.Hide
						)
					}
				>
					{t('engagemenList.hideEngagement')}
				</Button>
				{userProfile?.role === RoleEnum.BusinessOwner && (
					<Button
						className="engagement-context-menu-item"
						kind="text"
						onClick={(event: any) =>
							handleCommand(
								event,
								gridCellProps,
								EngagementPersonalizationStatus.My,
								EngagementCommand.Delete
							)
						}
					>
						{t('engagemenList.deleteEngagement')}
					</Button>
				)}
			</div>
		);

		let cellValue = <></>;
		switch (props.engagementPersonalizationStatus)
		{
		case EngagementPersonalizationStatus.My:
			cellValue = (
				<div>
					<span
						ref={tripleDotRef}
						style={{
							float: 'right'
						}}
						className={`engagement-stacked-dots ${show ?
							'engagement-stacked-dots-active' :
							'engagement-stacked-dots-inactive'
						} Appkit4-icon icon-vertical-more-outline`}
						onClick={showContext}
					/>
					{show && (
						<Popup
							anchor={anchor}
							show={show}
							popupAlign={{
								horizontal: 'right',
								vertical: 'top'
							}}
							onClose={onClose}
						>
							{contextMenu}
						</Popup>
					)}
				</div>
			);
			break;

		case EngagementPersonalizationStatus.Pending:
			cellValue = (
				<Button
					style={{
						fontSize: '15px',
						width: 'auto',
						float: 'right',
						padding: '8px 16px 8px 16px'
					}}
					kind="tertiary"
					onClick={(event: any) =>
					{
						handleCommand(
							event,
							gridCellProps,
							EngagementPersonalizationStatus.Pending,
							EngagementCommand.CancelRequest
						);
					}}
				>
					{t('engagemenList.cancelRequest')}
				</Button>
			);
			break;

		case EngagementPersonalizationStatus.Hidden:
			cellValue = (
				<Button
					style={{
						fontSize: '15px',
						float: 'right',
						width: '70%'
					}}
					kind="tertiary"
					onClick={(event: any) =>
					{
						handleCommand(
							event,
							gridCellProps,
							EngagementPersonalizationStatus.Hidden,
							EngagementCommand.Unhide
						);
					}}
				>
					{t('engagemenList.unhide')}
				</Button>
			);
			break;

		case EngagementPersonalizationStatus.Draft:
			cellValue = (
				<Button
					style={{
						fontSize: '15px',
						width: 'auto',
						float: 'right',
						padding: '8px 16px 8px 16px'
					}}
					kind="tertiary"
					onClick={(event: any) =>
					{
						navigate(`/engagement/setup/${gridCellProps.dataItem.id}`, {
							state: {
								clientId: props.dataItem.id,
								clientName: props.dataItem.clientName,
								engagementId: gridCellProps.dataItem.id
							}
						});
						// handleCommand(
						//   event,
						//   gridCellProps,
						//   EngagementStatus.Draft,
						//   EngagementCommand.ContinueDraft
						// );
					}}
				>
					{'Continue Draft'}
				</Button>
			);
			break;

		default:
			cellValue = <></>;
		}
		return (
			<td
				className={`k-command-cell ${props.engagementPersonalizationStatus ===
					EngagementPersonalizationStatus.Pending
				} ? enable-padding : '' `}
				style={{
					borderLeft: 'transparent'
				}}
			>
				{' '}
				{cellValue}
			</td>
		);
	};

	const SetWidth = () =>
	{
		let width = 0;
		switch (props.engagementPersonalizationStatus)
		{
		case EngagementPersonalizationStatus.My:
			width = 100;
			break;
		case EngagementPersonalizationStatus.Pending:
		case EngagementPersonalizationStatus.Hidden:
		case EngagementPersonalizationStatus.Draft:
			width = 140;
			break;
		default:
			width = 0;
		}
		return width;
	};

	const formatDate = (date: any) =>
	{
		let formattedDate: string;
		if (!date)
		{
			formattedDate = '';
		}
		else
		{
			formattedDate = moment(date).format('MMM DD[,] YYYY');
		}

		return (
			<td
				className="k-command-cell"
				style={{
					borderLeft: 'transparent',
					borderBottom: '1px solid red !important'
				}}
			>
				{formattedDate ? formattedDate : '-'}
			</td>
		);
	};

	const handleEngagementClick = (event: any) =>
	{
		if (
			props.engagementPersonalizationStatus ===
			EngagementPersonalizationStatus.My
		)
		{
			navigate(`/engagement/${event.dataItem.id}`, {
				state: {
					clientIdd: props.dataItem.id,
					clientName: props.dataItem.clientName,
					engagementId: event.dataItem.id,
					engagementName: event.dataItem.name
				}
			});
		}
	};

	if (props.dataItem.loading)
	{
		return (
			<div className="loading">
				<Loading
					loadingType="circular"
					indeterminate={true}
					compact={false}
					stopPercent={99}
				></Loading>
			</div>
		);
	}
	else
	{
		if (data)
		{
			return (
				<>
					<Grid
						data={data}
						rowRender={rowRender}
						className={`engagement-grid ${props.engagementPersonalizationStatus === 1 ?
							'my-engagements' :
							''
						}`}
						onRowClick={handleEngagementClick}
					>
						<GridColumn
							field="taxpayerName"
							title={t('engagement-grid-column.taxpayer') || undefined}
							width={'150px'}
							cell={(cellProps: GridCellProps) => (
								<td
									className="k-table-td long-text"
									role="gridcell"
									aria-selected="false"
									data-grid-col-index="1"
									onClick={() => handleEngagementClick(cellProps)}
								>
									<span className="wrap-text">
										<Highlighter
											highlightClassName="highlightClass"
											searchWords={[props?.searchText?.trim()]}
											autoEscape={true}
											textToHighlight={
												cellProps.dataItem.taxpayerName ?
													cellProps.dataItem.taxpayerName :
													'-'
											}
										/>
									</span>
								</td>
							)}
						/>
						<GridColumn
							field="lastCalculationTaxationYearEnd"
							title={t('engagement-grid-column.lastCalculationTYE') || undefined}
							cell={(props: GridCellProps) =>
								formatDate(props.dataItem.lastCalculationTaxationYearEnd)
							}
							width={'220px'}
						/>
						<GridColumn
							field="name"
							title={t('engagement-grid-column.engagementName') || undefined}
							width={'250px'}
							cell={(cellProps: GridCellProps) => (
								<td
									className="k-table-td long-text"
									role="gridcell"
									aria-selected="false"
									data-grid-col-index="1"
									onClick={() => handleEngagementClick(cellProps)}
								>
									<span className="wrap-text">
										<Highlighter
											highlightClassName="highlightClass"
											searchWords={[props.searchText?.trim()]}
											autoEscape={true}
											textToHighlight={
												cellProps.dataItem.name ? cellProps.dataItem.name : '-'
											}
										/>
									</span>
								</td>
							)}
						/>
						<GridColumn
							field="deliveryDate"
							title={t('engagement-grid-column.deliveryDate') || undefined}
							cell={(props: GridCellProps) =>
								formatDate(props.dataItem.deliveryDate)
							}
							width={'150px'}
						/>
						<GridColumn
							field=""
							title=""
							cell={CommandCell}
							width={SetWidth()}
						/>
					</Grid>
				</>
			);
		}
	}

	return <></>;
};
