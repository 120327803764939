import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { ColumnarGrid } from 'src/common/components/ColumnarGrid/ColumnarGrid';
import { ILineItem } from 'src/common/types/interfaces/ILineItem';
import { cloneDeep, isEqual, differenceWith } from 'lodash';
import { LineItemCategories } from 'src/common/types/enums/LineItemCategories';
import { Utils } from 'src/common/utils/utils';
import { inputRecordHelper } from 'src/common/utils/inputRecordHelper';
import { DividendsReceivedHelper } from '../DividendsRecivedHelper';
import { TaxYearEndFilter } from 'src/common/components/TaxYearEndFilter/TaxYearEndFilter';
import { DividendReceivedFA, IDividendReceivedFA } from 'src/features/engagement/models/IDividendReceivedFA';
import { DividendsReceivedFromFAFields } from 'src/common/types/enums/DividendsReceivedFromFAFields';
import { UnexpectedErrorModal } from 'src/common/components/UnexpectedErrorModal/UnexpectedErrorModal';
import { SaveButtonLocations } from 'src/common/components/ColumnarGrid/IColumnarGridProps';
import { IDividendsFromFAProps } from './IDividendsFromFAProps';

import './DividendsFromFA.scss';
import AffiliateContext from 'src/common/components/SharedDataContext/AffiliateContext';

export function DividendsFromFA(props: IDividendsFromFAProps): JSX.Element
{
	const [showUnexpectedErrorModal, setShowUnexpectedErrorModal] =
    useState<boolean>(false);
	const [unexpectedError, setUnExpectedError] = useState<string>('');
	const [errorCode, setErrorCode] = useState<number|undefined>(undefined);

	const { t } = useTranslation(
		'input',
		{
			keyPrefix: 'dividendsReceivedFormFA'
		}
	);

	const { t: fieldsTranslationService } = useTranslation(
		'input',
		{
			keyPrefix: 'dividendsReceivedFromFAFieldNames'
		}
	);

	const affiliateContext = useContext(AffiliateContext);
	const [lineConfiguration, setLineConfiguration] = useState<ILineItem[]>([]);
	const [toDate, setToDate] = useState<Date | undefined>(undefined);
	const [fromDate, setFromDate] = useState<Date | undefined>(undefined);
	const [rowInformation, setRowInformation] = useState(DividendsReceivedHelper.getRowInformation(fieldsTranslationService));
	const [dividends, setDividends] = useState<IDividendReceivedFA[]>([]);
	const [rowDividends, setRowDividends] = useState<IDividendReceivedFA[]>([]);
	const [dividendRecords, setDividendRecords] = useState(
		inputRecordHelper
			.getStartRowKeysAsDictionary<IDividendReceivedFA>(
				DividendsReceivedHelper.getRowInformation(fieldsTranslationService)
			)
	);

	const [dataChanged, setDataChanged] = useState<boolean>(false);

	const [isLoading, setIsLoading] = useState(true);

	const loadDividendData = async (
		{
			engagementId,
			affiliateId,
			fromDate,
			toDate
		}:
		{
			engagementId: number;
			affiliateId: number;
			fromDate?: Date;
			toDate?: Date;
		}
	): Promise<void> =>
	{
		try
		{
			const response = await engagementService
				.getDividendsReceivedFromFA(
					{
						engagementId,
						affiliateId,
						fromDate,
						toDate
					}
				);

			if (
				!!response &&
				!!response.data &&
				!!response.data.result
			)
			{
				setDividends(response.data.result);
				setRowDividends(cloneDeep(response.data.result));
				const allRecords: IDividendReceivedFA[] = response.data.result
					.map(DividendReceivedFA.populateFields);

				const gridRecords = {
					...cloneDeep(
						inputRecordHelper
							.getRowData(
								allRecords,
								rowInformation
							)
					)
				};

				setDividendRecords(gridRecords);
			}
		}
		catch (error)
		{
			handleApiErrorResponse(error);
		}
		finally
		{
			await Utils.timeout(1000);
			setIsLoading(false);
		}
	};

	const handleSave = () =>
	{
		const datarRequired = dividends.some((d: IDividendReceivedFA) =>

			!d.actualAmount
		);

		if (datarRequired)
		{
			const dataRequiredError = t('dataRequired');
			setUnExpectedError(dataRequiredError);
			setErrorCode(400);
			setShowUnexpectedErrorModal(true);
			return;
		}

		const changedDividends = differenceWith(dividends, rowDividends, isEqual);
		if (changedDividends.length>0)
		{
			const updatedDividends = dividends.map((d: IDividendReceivedFA) =>
			{
				return {
					'id': d.id,
					'actualAmount': d.actualAmount,
					'withholdingTax': d.withholdingTax,
					'notes': d.notes
				};
			});

			const payload = {
				engagementId: affiliateContext?.engagementDetail.id!,
				affiliateId: affiliateContext?.affiliateDetail.affiliateId!,
				dividends: updatedDividends
			};

			if (!isLoading)
			{
				setIsLoading(true);
				engagementService.updateDividendsReceivedFromFA(payload).then((res: any) =>
				{
					setIsLoading(false);
					!!props.handleChange && props.handleChange(false);
					loadDividendData({
						engagementId: affiliateContext?.engagementDetail.id!,
						affiliateId: affiliateContext?.affiliateDetail.affiliateId!
					});
				})
					.then((error: any) =>
					{
						setIsLoading(false);
					})
					.catch((err: any) =>
					{
						setIsLoading(false);
						handleApiErrorResponse(err);
					});
			}
		}
	};

	const loadRowInformation = async (
		engagementId: number,
		affiliateId: number
	): Promise<void> =>
	{
		try
		{
			const lines = await inputRecordHelper
				.initializeLineItems(
					LineItemCategories.DividendsReceivedFromFA,
					engagementId,
					affiliateId
				);

			const rowInfo = DividendsReceivedHelper.getRowInformation(
				fieldsTranslationService,
				lines
			);

		   const updatedRowInfo =	rowInfo.map((info: any) =>
			{
				if (info.key===DividendsReceivedFromFAFields.percentageOwned)
				{
					return {
						...info,
						'name': info.name+' '+ affiliateContext?.affiliateDetail.name
					};
				}
				else if (info.key===DividendsReceivedFromFAFields.receiverCurrencyId)
				{
					return {
						...info,
						'name': affiliateContext?.affiliateDetail.name+
						`${affiliateContext?.affiliateDetail.name?.endsWith('s')?'\'':'\'s'} `+info.name
					};
				}
				else
				{
					return info;
				}
			});

			setRowInformation(
				updatedRowInfo
			);

			setLineConfiguration(lines);
		}
		catch (error)
		{
			handleApiErrorResponse(error);
		}
	};

	const onFilter = (
		{
			fromDate,
			toDate
		}:
		{
			fromDate?: Date;
			toDate?: Date;
		}
	): void =>
	{
		setIsLoading(true);

		setFromDate(fromDate);
		setToDate(toDate);

		loadDividendData({
			engagementId: affiliateContext?.engagementDetail.id!,
			affiliateId: affiliateContext?.affiliateDetail.affiliateId!,
			fromDate,
			toDate
		});
	};

	const onRenderFilters = (): JSX.Element =>
	{
		return <>
			<TaxYearEndFilter
				onlyRelevantResults
				isDisabled={
					isLoading ||
					(
						!Object.values(dividendRecords).some((d) => !!d.length) &&
						fromDate === undefined &&
						toDate === undefined
					)
				}
				faHistory={props.faHistory}
				onFilter={onFilter}
			/>
		</>;
	};


	useEffect(
		() =>
		{
			if (
				!!affiliateContext&&
				!!affiliateContext?.engagementDetail.id&&
				!!affiliateContext?.affiliateDetail.affiliateId

			)
			{
				loadRowInformation(
					affiliateContext?.engagementDetail.id!,
					affiliateContext?.affiliateDetail.affiliateId!

				);
			}
		},
		[affiliateContext]
	);

	useEffect(
		() =>
		{
			setIsLoading(true);

			if (
				!!lineConfiguration &&
				!!lineConfiguration.length &&
				affiliateContext&&
				affiliateContext?.engagementDetail.id&&
				affiliateContext?.affiliateDetail.affiliateId

			)
			{
				loadDividendData({
					engagementId: affiliateContext?.engagementDetail.id!,
					affiliateId: affiliateContext?.affiliateDetail.affiliateId!

				});
			}
		},
		[
			affiliateContext,
			lineConfiguration
		]
	);

	const handleInputChange = (id: string, field: string, value: any) =>
	{
		if (value==='')
		{
			value=null;
		}
		const updatedDividends = dividends.map((d: IDividendReceivedFA) =>
		{
			if (d.id.toString()===id.toString())
			{
				return {
					...d,
					[field]: (typeof(d[field as keyof IDividendReceivedFA]) === 'number') ? Number(value) : value
				};
			}
			else
			{
				return d;
			}
		});
		setDividends(updatedDividends);

		const hasChange = !isEqual(updatedDividends, rowDividends);
		setDataChanged(hasChange);
		!!props.handleChange && props.handleChange(hasChange);
	};

	const closeUnexpectedErrorModal = () =>
	{
		setUnExpectedError('');
		setShowUnexpectedErrorModal(false);
	};

	const handleApiErrorResponse = (err: any) =>
	{
		if (err.response)
		{
			setErrorCode(err.response.status);
			if (err.response.status >= 400)
			{
				if (err.response.data.Errors&&err.response.data.Errors.length>0)
				{
					setUnExpectedError(err.response.data.Errors[0].Message);
					setShowUnexpectedErrorModal(true);
				}
				else
				{
					const dataRequiredError = t('dataRequired');
					setUnExpectedError(dataRequiredError);
					setShowUnexpectedErrorModal(true);
				}
			}
		}
	};

	return <div className='dividends-received-from-fa'>
		<ColumnarGrid
			title={t('gridTitle')}
			columnIndexKeyName={t('columnIndexKeyName')}
			isLoading={isLoading}
			isDisabled={!dataChanged}
			rowInformation={rowInformation}
			data={
				!!dividendRecords ?
					dividendRecords :
					{
					}
			}
			addNewButtonText={t('addNewButtonText')}
			addNewMessage={t('addNewMessage')}
			saveButtonLocation={SaveButtonLocations.Top}
			onRenderFilters={onRenderFilters}
			onGridSave={handleSave}
			onInputChanged={handleInputChange}
		/>
		<UnexpectedErrorModal
			visible={showUnexpectedErrorModal}
			onClose={closeUnexpectedErrorModal}
			message={unexpectedError}
			error={''}
			statusCode={errorCode}
		/>
	</div>;
}