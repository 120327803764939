import { Button, Loading } from '@appkit4/react-components';
import { ITablePlaceholderProps } from './ITablePlaceholderProps';
import { useTranslation } from 'react-i18next';

import './TablePlaceholder.scss';

export function TablePlaceholder(props: ITablePlaceholderProps): JSX.Element
{
	const { t } = useTranslation(
		'common',
		{
			keyPrefix: 'columnarTablePlaceholder'
		}
	);

	return <>
		<td
			className={'data-column table-placeholder'}
			rowSpan={
				!!props.rowInformation ?
					props.rowInformation.length :
					1
			}
		>
			<div className={'content'}>
				{
					!!props.isLoading ?
						<div className={'loading-data'}>
							<Loading
								indeterminate
								loadingType={'circular'}
							/>
							{
								`${t('loadingMessageP1')} ${props.title} ${t('loadingMessageP2')}`
							}
						</div> :
						<div>
							<div>
								{
									props.addNewMessage
								}
							</div>
							<div>
								<Button
									kind={'text'}
									onClick={props.onOpenNew}
								>
									{
										props.addNewButtonText
									}
								</Button>
							</div>
						</div>
				}
			</div>
		</td>
	</>;
}