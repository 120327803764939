import { Badge, Button, Loading, Pagination, Notification } from '@appkit4/react-components';
import { useEffect, useState } from 'react';
import { EngagementPersonalizationStatus } from 'src/core/types/enums/EngagementPersonalizationStatus';
import './DownloadsList.scss';
import { useTranslation } from 'react-i18next';
import { DownloadRequest, IDownloadRequest } from 'src/common/types/interfaces/IDownloadRequest';
import { ReportStatusEnum } from 'src/common/types/enums/ReportStatusEnum';
import { reportService } from 'src/core/services/reportSevice';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import { EngagementTotalChangeSubject } from 'src/core/services/EventBus';
import { NotificationService } from 'src/core/services/notificationService';
import { HttpStatusCode } from 'axios';
import { useNavigate} from 'react-router-dom';
import { Utils } from 'src/common/utils/utils';

interface IDownloadsListProps {
	engagementPersonalizationStatus: EngagementPersonalizationStatus;
	activeIndex?: number;
	engagementId: number,
}

export const Downloads = (props: IDownloadsListProps) =>
{
	const { t } = useTranslation('homeDashboard');
	const [isLoading, setIsLoading] = useState(true);

	const [filteredDownloadRequestRecords, setFilteredDownloadRequestRecords] = useState<IDownloadRequest[]>([]);

	const defaultRecordsPerPage = 10;

	const pagingOptions = [10, 25, 50];
	const [recordsPerPage, setRecordsPerPage] = useState(defaultRecordsPerPage); // todo: add setRecordsPerPage for paging dropdown use
	const [currentPage, setCurrentPage] = useState(1);
	const [isPagePickerExpanded, setIsPagePickerExpanded] = useState<boolean>(false);

	const navigate = useNavigate();

	const handleRecordsPerPage = (option: any) =>
	{
		setRecordsPerPage(option);
		setIsPagePickerExpanded(false);
	};

	const statusCell = (dataItem: IDownloadRequest) =>
	{
		const data = [
			{
				value: ReportStatusEnum.Requested,
				label: t('myDownloads.requestStatus.requested'),
				background: 'rgba(71, 71, 71, 0.12)',
				opacity: '0.12',
				color: '#474747'
			},
			{
				value: ReportStatusEnum.InProgress,
				label: t('myDownloads.requestStatus.inProgress'),
				background: 'rgba(255, 191, 31, 0.12)',
				opacity: '0.12',
				color: '#252525',
				borderColor: '#FFBF1F'
			},
			{
				value: ReportStatusEnum.Available,
				label: t('myDownloads.requestStatus.completed'),
				background: 'rgba(33, 129, 45, 0.12)',
				color: '#21812D'
			},
			{
				value: ReportStatusEnum.Error,
				label: t('myDownloads.requestStatus.error'),
				background: 'rgba(197, 42, 26, 0.12)',
				color: '#C52A1A'
			}
		];

		const statusCellData = data.find((item: any) => item.value === dataItem.requestStatus);

		return (
			<>
				{!!statusCellData ?
					<Badge size="lg"
						className="status-cell-badge"
						style={{
							border: `1px solid ${!!statusCellData?.borderColor ? statusCellData?.borderColor : statusCellData?.color}`,
							color: `${statusCellData?.color}`,
							backgroundColor: `${statusCellData?.background}`
						}}
						value={statusCellData?.label}>
					</Badge> : <div></div>
				}
			</>
		);
	};

	const actionCell = (dataItem: IDownloadRequest) =>
	{
		return (<> {!!dataItem.requestStatus && dataItem.requestStatus === ReportStatusEnum.Available ?
			<div className="actions-cell-icon" onClick={() => handleActionClick(dataItem)}>
				<span className={'Appkit4-icon icon-download-outline'} />
			</div> :
			<div></div>}
		</>
		);
	};

	const triggerNotification = async (message: string, status: string): Promise<void> =>
	{
		const ele = (
			<Notification
				message={message}
				status={status}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};

	const downloadReport = (requestId: number, fileName: string) =>
	{
		if (!!requestId)
		{
			reportService.downloadReport(requestId)
				.then((res: any) =>
				{
					if (!!res && !!res.data)
					{
						const blob = new Blob([res.data], {
							type: 'application/pdf'
						});

						const url = URL.createObjectURL(blob);
						const link = document.createElement('a');
						link.href = url;
						link.download = Utils.escapeHtml(fileName) || 'report.pdf';
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
						URL.revokeObjectURL(url);
						NotificationService.clearExisting();
					}
				})
				.catch((err: any) =>
				{

				});
		}
	};

	const handleActionClick = (dataItem: any) =>
	{
		if (!!dataItem && !!dataItem.requestStatus)
		{
			triggerNotification(t('myDownloads.downloadNotification'), 'warning');
			if (dataItem.requestStatus === ReportStatusEnum.Available)
			{
				downloadReport(dataItem.id, dataItem.fileName);
			}
		}
	};

	const loadData = async () =>
	{
		setIsLoading(true);

		const response = await reportService.getMyDownloadRequests(props.engagementId)
			.catch((err) =>
			{
				if (!!err && !!err.response && err.response.status ===  HttpStatusCode.Unauthorized)
				{
					navigate('/user-access-denied');
				}
			});

		if (
			!!response &&
			!!response.data &&
			!!response.data.result &&
			!!response.data.result.length
		)
		{
			const transformedDownloadRequestRecords = response.data.result
				.map(DownloadRequest.toEntity);
			EngagementTotalChangeSubject.next({
				eventType: props.engagementPersonalizationStatus,
				payload: transformedDownloadRequestRecords.length
			});
			setFilteredDownloadRequestRecords([...transformedDownloadRequestRecords]);
			setCurrentPage(1);
		}
		else
		{
			setFilteredDownloadRequestRecords([]);
			setCurrentPage(1);
		}

		setIsLoading(false);
	};

	useEffect(() =>
	{
		loadData();
	},
	[props.engagementId]);

	useEffect(() =>
	{
		if (!!props.activeIndex && props.activeIndex === 6)
		{
			loadData();
		}
	},
	[props.activeIndex]);

	return (
		<>
			<div className="my-downloads">
				<div className={'my-downloads-header'}>
					<div className="request-disclaimer">
						<span className={'Appkit4-icon icon-information-outline'} />
						{t('myDownloads.disclaimer')}
					</div>
					<div>
						<Button
							kind="secondary"
							icon="Appkit4-icon icon-refresh-outline"
							onClick={() => loadData()}>
							{t('myDownloads.refresh')}
						</Button>
					</div>
				</div>
				{isLoading && (
					<div className="loading-icon">
						<Loading
							loadingType="circular"
							indeterminate={true}
							compact={false}
						/>
					</div>
				)}
				{!isLoading && (
					<div className={'grid'}>
						<table>
							<thead>
								<tr>
									<td className={'grid-text'}>
										{
											t('myDownloads.columnHeaders.dateRequested')
										}
									</td>
									<td className={'grid-text'}>
										{
											t('myDownloads.columnHeaders.requestedBy')
										}
									</td>
									<td className={'grid-long-text'}>
										{
											t('myDownloads.columnHeaders.fileName')
										}
									</td>
									<td className={'grid-status'}>
										{
											t('myDownloads.columnHeaders.status')
										}
									</td>
									<td className={'grid-action'}>
										{
											t('myDownloads.columnHeaders.action')
										}
									</td>
								</tr>
							</thead>
							<tbody>
								{
									!isLoading &&
									!!filteredDownloadRequestRecords &&
									!!filteredDownloadRequestRecords.length &&
									filteredDownloadRequestRecords
										.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage)
										.map((t) =>
										{
											return <tr key={t.id}>
												<td className={'grid-text'}>
													{
														dateFormatter.format(
															t.requestedDate, 'MMM DD, YYYY hh:mm A'
														)
													}
												</td>
												<td className={'grid-text'}>
													{
														t.requestedBy
													}
												</td>
												<td className={'grid-text-long'}>
													{
														t.fileName
													}
												</td>
												<td className={'grid-status'}>
													{
														statusCell(t)
													}
												</td>
												<td className={'grid-action'}>
													{
														actionCell(t)
													}
												</td>
											</tr>;
										})
								}
							</tbody>
						</table>
						{
							filteredDownloadRequestRecords?.length === 0 && <div className='no-report-data'>{t('noDataAvailable')}</div>
						}
					</div>
				)
				}
				<div className={'paging'}>
					<div className={'page-picker'}>
						<div className={'page-picker-text'}>
							{t('show')}
						</div>
						<div className={`page-selector ${filteredDownloadRequestRecords.length === 0 ? 'disabled' : ''}`}>
							<div className={'dropdown-container'}>
								<div className={'page-picker-text highlight'} onClick={() =>
									setIsPagePickerExpanded(!isPagePickerExpanded)}>
									{recordsPerPage}
								</div>
								{isPagePickerExpanded &&
									<div className="dropdown-menu">
										{pagingOptions.map((option: any) =>
											<div className="dropdown-item" onClick={() => handleRecordsPerPage(option)}>
												{option}
											</div>)}
									</div>}
							</div>
							<div className={'icons'}>
								{isPagePickerExpanded ? (
									<span
										className="Appkit4-icon icon-up-chevron-outline"
										onClick={() => setIsPagePickerExpanded(false)}
									></span>
								) : (
									<span
										className="Appkit4-icon icon-down-chevron-outline"
										onClick={() => setIsPagePickerExpanded(true)}
									></span>
								)}
							</div>
						</div>
						<div className={'page-picker-text'}>
							{t('itemPerPage')}
						</div>
					</div>

					<Pagination
						disabled={filteredDownloadRequestRecords.length === 0}
						defaultCurrent={currentPage}
						total={filteredDownloadRequestRecords.length === 0 ? 1 :
							Math.ceil(filteredDownloadRequestRecords.length / recordsPerPage)}
						onPageChange={(page) =>
						{
							// ensure the page is within the bounds of min (1) and max pages
							const maxPage = Math.ceil(filteredDownloadRequestRecords.length / recordsPerPage);
							setCurrentPage(Math.max(1, Math.min(page, maxPage)));
						}}
					/>
				</div>
			</div>
		</>
	);
};