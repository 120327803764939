import { dateFormatter } from 'src/common/utils/dateFormatter';

export interface IEngagementDetail {
    id: number|undefined,
    clientId: number|undefined,
    name: string,
    taxPayerId: number,
    status: string,
    lastCalculationTaxationYearEnd: Date|undefined,
    deliveryDate: Date | undefined,
}

export class EngagementDetail implements IEngagementDetail
{
	constructor ()
	{
		this.name = '';
		this.taxPayerId = 0;
		this.status = '';
	}

	id: number | undefined;
	clientId: number | undefined;
	name: string;
	taxPayerId: number;
	status: string;
	lastCalculationTaxationYearEnd: Date | undefined;
	deliveryDate: Date | undefined;

	static parseFields(engagement: IEngagementDetail): IEngagementDetail
	{
		return {
			...engagement,
			lastCalculationTaxationYearEnd: dateFormatter.utcToEst(engagement.lastCalculationTaxationYearEnd),
			deliveryDate: dateFormatter.utcToEst(engagement.deliveryDate)
		};
	}
}