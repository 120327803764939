import { IABIGridProps } from './IABIGridProps';
import { ABIRowColumns } from './components/ABIRowColumns/ABIRowColumns';
import { Button } from '@appkit4/react-components';
import { useTranslation } from 'react-i18next';

import './ABIGrid.scss';
import { Tooltip } from '../Tooltip/Tooltip';

export function ABIGrid(props: IABIGridProps): JSX.Element
{
	const { t } = useTranslation(
		'common',
		{
			keyPrefix: 'abiGrid'
		}
	);

	return <>
		{
			!!props.abiData &&
			<div
				className={'abi-grid'}
				style={{
					visibility: props.isLoading ? 'hidden' : 'visible'
				}}
			>
				{
					!!props.title &&
					<div className={'title'}>
						<span>
							{
								props.title
							}
						</span>
						<Tooltip
							text={props.titleTooltip}
						/>
					</div>
				}
				<div
					ref={props.customRef}
					className={'table-container table-scroll-container'}
				>
					<table
						id={props.id}
						className={props.displayNotes ? 'notes' : ''}
					>
						<tbody>
							{
								!!props.rowData &&
								!!props.rowData.length &&
								props.rowData
									.map((rd, rowIndex) =>
									{
										return <ABIRowColumns
											isLoading={props.isLoading}
											isDisabled={props.isDisabled}
											validate={props.validate}
											displayNotes={props.displayNotes}
											rowData={rd}
											descriptionOptions={props.descriptionOptions}
											onDescriptionUpdate={(value) =>
											{
												props.onDescriptionUpdate(rowIndex, value);
											}}
											onValueUpdate={(fieldName, index, value) =>
											{
												props.onValueUpdate(fieldName, rowIndex, index, value);
											}}
											onDeleteRow={props.onDeleteRow}
										/>;
									})
							}
						</tbody>
					</table>
				</div>
				{
					(
						!props.rowData ||
						!props.rowData.length
					) &&
					<div className={'no-data'}>
						<div>
							{ t('noDataPart1') }
						</div>
						<div>
							{t('noDataPart2')}<strong>+ {props.addRowButtonText}</strong> {t('noDataPart3')}
						</div>
					</div>
				}
				<div className={'actions'}>
					<Button
						kind={'text'}
						icon={'icon-plus-outline'}
						onClick={props.onRowAdd}
					>
						{
							props.addRowButtonText
						}
					</Button>
				</div>
			</div>
		}
	</>;
}