import { dateFormatter } from 'src/common/utils/dateFormatter';
import { IEngagementDetail } from './IEngagementDetail';

export interface IEngagementOverview extends IEngagementDetail{
	clientName: string;
	clientIPowerCode: string;
	taxPayerName: string;
	taxPayerCurrencyId: number|undefined;
}

export class EngagementOverviewEntity implements IEngagementOverview
{
	constructor ()
	{
		this.name = '';
		this.taxPayerId = 0;
		this.status = '';
		this.clientName = '';
		this.clientIPowerCode = '';
		this.taxPayerName= '';
		this.taxPayerCurrencyId = undefined;
	}

	id: number | undefined;
	clientId: number | undefined;
	name: string;
	taxPayerId: number;
	status: string;
	lastCalculationTaxationYearEnd: Date | undefined;
	deliveryDate: Date | undefined;
	clientName: string;
	clientIPowerCode: string;
	taxPayerName: string;
	taxPayerCurrencyId: number|undefined;

	static parseFields(engagement: IEngagementOverview): IEngagementOverview
	{
		return {
			...engagement,
			lastCalculationTaxationYearEnd: dateFormatter.utcToEst(engagement.lastCalculationTaxationYearEnd),
			deliveryDate: dateFormatter.utcToEst(engagement.deliveryDate)
		};
	}
}