import { Button, Checkbox, Loading, Switch, Tooltip } from '@appkit4/react-components';
import React, { useContext, useEffect, useRef, useState } from 'react';
import './SurplusEntitlementPercentageAdjustments.scss';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Grid, GridColumn, GridPageChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { engagementService } from 'src/features/engagement/services/engagementService';
import AffiliateContext from 'src/common/components/SharedDataContext/AffiliateContext';
import { NotificationService } from 'src/core/services/notificationService';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import { NotesEditor } from 'src/common/components/NotesEditor/NotesEditor';
import { UnexpectedErrorModal } from 'src/common/components/UnexpectedErrorModal/UnexpectedErrorModal';
import { useTranslation } from 'react-i18next';
import { cloneDeep, differenceWith, isEqual } from 'lodash';
import { ISepChange, ISurplusEP, ISurplusEPSubmissions } from 'src/features/engagement/models/ISurplusEPAdjustment';
import { PagerTargetEvent } from '@progress/kendo-react-data-tools';

interface PageState {
	skip: number;
	take: number;
	pageNumber: number;
  }

const initialDataState: PageState = {
	  skip: 0,
	  take: 10,
	  pageNumber: 1
};

const SurplusEntitlementPercentageAdjustments = (props: {handleChange?: (change: boolean) => void}) =>
{
	const [isLoading, setIsLoading] = useState(false);
	const [displayNotes, setDisplayNotes] = useState(false);
	const [surplusEPData, setSurplusEPData]=useState<ISurplusEP[]>([]);
	const [rawSurplusEPData, setRawSurplusEPdata]=useState<ISurplusEP[]>([]);

	const rowDataRef = useRef(surplusEPData);
	const [page, setPage] = React.useState<PageState>(initialDataState);
	const [pageSizeValue, setPageSizeValue] = React.useState<number | string | undefined>();
	const affiliateContext = useContext(AffiliateContext);
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const [errorModalMessage, setErrorModalMessage] = useState('');
	const [total, setTotal] = useState<number>(0);

	const { t } = useTranslation(
		'input',
		{
			keyPrefix: 'surplusEntitlementPercentageAdjustments'
		}
	);

	useEffect(
		() =>
		{
			setIsLoading(true);

			if (
				!!affiliateContext&&
				!!affiliateContext?.engagementDetail.id&&
				!!affiliateContext?.affiliateDetail.affiliateId
			)
			{
				loadSurplusEntPercentageData({
					engagementId: affiliateContext?.engagementDetail.id!,
					affiliateId: affiliateContext?.affiliateDetail.affiliateId!,
					pageSize: page.take,
					pageNumber: page.pageNumber
				});
			}
		},
		[
			affiliateContext,
			page.take,
			page.pageNumber
		]
	);

	useEffect(() =>
	{
		rowDataRef.current = surplusEPData;
	},[surplusEPData]);

	const onNotesToggle = (value: boolean) =>
	{
		setDisplayNotes(!!value);
	};

	const loadSurplusEntPercentageData = ({
		engagementId,
		affiliateId,
		pageSize,
		pageNumber
	}: {
		engagementId: number,
		affiliateId: number,
		pageSize?: number,
		pageNumber?: number
	}) =>
	{
		setDataChanged(false);
		!!props.handleChange && props.handleChange(false);
		setIsLoading(true);
		engagementService
			.getSurplusEntitlementPercentageAdjustmenst({
				engagementId,
				affiliateId,
				pageSize,
				pageNumber
			}).then((response) =>
			{
				if (response.data.result)
				{
					const result = response.data.result;
					setTotal(result.totalRecordCount);
					const surplusEPA = result.sepChanges.map((sc: ISurplusEP) =>
					{
						const f = {
							id: sc.id,
							changeDate: dateFormatter.utcToEst(sc.changeDate),
							isReg5905NotApplicable: sc.isReg5905NotApplicable,
							sep: sc.sep,
							sepChangeReason: sc.sepChangeReason,
							notes: sc.notes,
							isDisabled: sc.isDisabled
						};
						return f;
					});
					setSurplusEPData(surplusEPA);
					setRawSurplusEPdata(cloneDeep(surplusEPA));
				}

				setIsLoading(false);
			})
			.catch((error) =>
			{
				triggerErrorNotification(
					t('adjustmentInformationError')
				);
				setIsLoading(false);
			});
	};

	const triggerErrorNotification = (message: string): void =>
	{
		NotificationService.error({
			message
		});
	};

	const handleSelection = async (param: any, selected: boolean) =>
	{
		const updatedRowData = rowDataRef.current.map((r: ISurplusEP) =>
		{
			if (r.id === param.dataItem.id)
			{
				return {
					...r,
					isReg5905NotApplicable: selected
				};
			}
			else
			{
				return r;
			}
		});
		setSurplusEPData(updatedRowData);

		const hasChange = !isEqual(rawSurplusEPData, updatedRowData);
		setDataChanged(hasChange);
		!!props.handleChange && props.handleChange(hasChange);
	};

	const onRenderNotesEditor = (data: ISurplusEP): JSX.Element | undefined =>
	{
		if (!!displayNotes)
		{
			return <NotesEditor
				notes={data.notes}
				onSave={(updatedNotes: string) => onNoteSave(updatedNotes, data)}
			/>;
		}
		return undefined;
	};

	const onNoteSave =(updatedNote: string, data?: any) =>
	{
		if ((!!data?.notes && !!updatedNote) &&
		 (data?.notes === updatedNote))
		{
			return;
		}

		const updatedSurplusEPData = surplusEPData.reduce((formatted: ISurplusEP[], item: ISurplusEP) =>
		{
			if (item.id === data.id && !isEqual(updatedNote, item.notes))
			{
				item = {
					...item,
					notes: updatedNote
				};
			}

			formatted.push(item);
			return formatted;
		}, []);

		setSurplusEPData(updatedSurplusEPData);

		const hasChange = !isEqual(rawSurplusEPData, updatedSurplusEPData);
		setDataChanged(hasChange);
		!!props.handleChange && props.handleChange(hasChange);
	};

	const onUpdate = (payload: ISurplusEPSubmissions) =>
	{
		engagementService.updateSurplusEntPerAdjustment(
			payload
		).then(() =>
		{
			loadSurplusEntPercentageData({
				engagementId: affiliateContext?.engagementDetail.id!,
				affiliateId: affiliateContext?.affiliateDetail.affiliateId!,
				pageSize: page.take,
				pageNumber: page.pageNumber
			});

			onSuccessNotification({
				isUpdate: true
			});
			setIsLoading(false);
		})
			.catch((error) =>
			{
				setIsLoading(false);

				setErrorModalMessage(t('updateFailed') || '');
			});
	};

	const onSuccessNotification = (
		{isUpdate}:
		{
			isUpdate?: boolean;
		}
	) =>
	{
		let message = '';

		if (isUpdate)
		{
			message = t('updateSuccess');
		}

		NotificationService.success({
			message
		});
	};

	const handleSave = () =>
	{
		const changedSurplusEPData = differenceWith(surplusEPData, rawSurplusEPData, isEqual);
		if (changedSurplusEPData.length>0)
		{
			const filteredSEP = changedSurplusEPData.reduce((formatted: ISepChange[],item: ISepChange) =>
			{
				const payloadObj = {
					'id': item.id,
					'isReg5905NotApplicable': item.isReg5905NotApplicable,
					'changeDate': item.changeDate,
					'notes': item.notes ? item.notes : ''
				};
				formatted.push(payloadObj);
				return formatted;
			},[]);
			if (!isLoading)
			{
				const payload = {
					'engagementId': affiliateContext?.engagementDetail.id,
					'affiliateId': affiliateContext?.affiliateDetail.affiliateId,
					sepChanges: filteredSEP
				};
				setIsLoading(true);
				onUpdate(payload);
			}
		};
	};

	const pageChange = (event: GridPageChangeEvent) =>
	{
		const targetEvent = event.targetEvent as PagerTargetEvent;
		const take = event.page.take;
		const pageNumber = Math.floor(event.page.skip / event.page.take) + 1;

		if (targetEvent.value)
		{
			setPageSizeValue(targetEvent.value);
		}
		setPage({
			...event.page,
			take,
			pageNumber
		});
	};

	return (
		<>
			<div className='surplus-entitlement-percentage'>
				<div className="tool-bar">
					<Button kind="primary"
						disabled={isLoading||!dataChanged} onClick={() => handleSave()}>{t('save')}
					</Button>
				</div>
				<div className='table-container'>
					{isLoading && (
						<div className="loading-icon">
							<Loading
								loadingType="circular"
								indeterminate={true}
								compact={false}
							/>
						</div>
					)}
					<LocalizationProvider language="en-user">
						<IntlProvider locale="en">
							<Grid
								className={`surplusEP-grid ${
									surplusEPData.length === 0 ? 'grid-pageSize-disable no-records' : ''
								}`}
								data={surplusEPData}
								skip={page.skip}
								take={page.take}
								total={total === 0 ? 1 : total}
								pageable={{
									buttonCount: 2,
									info: false,
									type: 'input',
									pageSizes: [10, 25],
									pageSizeValue: pageSizeValue
								}}
								onPageChange={pageChange}
							>
								<GridToolbar>
									<div className="caption">
										<div className="switch-title">
											{t('sepChanges')}
										</div>{' '}
										<div className={'toggle'}>
											<Switch
												className={'switch-label'}
												checked={displayNotes}
												disabled={isLoading||surplusEPData.length===0}
												onChange={onNotesToggle}
											>
												{t('showNotes')}
											</Switch>
										</div>
									</div>
								</GridToolbar>
								<GridColumn
									field="changeDate"
									width={200}
									title={t('changeDate')||''}
									editable={false}
									sortable={{
										allowUnsort: false
									}}
									cell={(props) => (
										<td>
											<div className="custom-cell">
												<div className="custom-input">
													{dateFormatter.format(props.dataItem.changeDate, 'MMM DD, YYYY hh:mm A')}
												</div>
											</div>
										</td>
									)}
								/>
								<GridColumn
									field="sep"
									width={180}
									title={t('taxpayerSEP')||''}
									editable={false}
									sortable={{
										allowUnsort: false
									}}
									cell={(props) =>
										(
											<td>
												<div className="custom-cell">
													<div className="custom-input">
														{`${parseFloat(props.dataItem.sep).toFixed(2)}%` }
													</div>
												</div>
											</td>
										)
									}
								/>
								<GridColumn
									field="sepChangeReason"
									width={250}
									title={t('changeReasonSEP')||''}
									editable={false}
									sortable={{
										allowUnsort: false
									}}
									cell={(props) =>
									{
										const {sepChangeReason } = props.dataItem;
										const formattedSepChangeValues = !!sepChangeReason && (JSON.parse(sepChangeReason).join(', '));
										return (
											<td>
												<div className="custom-cell">
													<div className="custom-input">
														{formattedSepChangeValues}
													</div>
												</div>
											</td>
										);
									}}
								/>
								<GridColumn
									field="isReg5905NotApplicable"
									width={180}
									sortable={{
										allowUnsort: false
									}}
									headerCell={(props) => (
										<span className="k-cell-inner">
											<span
												className="k-link !k-cursor-default"
												style={{
													display: 'flex',
													justifyContent: 'flex-start'
												}}
											>
												<span className="k-column-title"> {t('regSEP')}</span>
												<Tooltip
													trigger="hover"
													distance={4}
													position={'top-right'}
													appendAfterTarget={true}
													content={t('regSEPTooltip')||''}
												>
													<span className={'Appkit4-icon icon-help-question-outline'} />
												</Tooltip>
											</span>
										</span>
									)}
									cell={(props) => (
										<td>
											<div className="custom-cell">
												<div className="custom-input">
													<Checkbox
														disabled={props.dataItem.isDisabled}
														checked={props.dataItem.isReg5905NotApplicable}
														key={props.field}
														value={props.dataItem.isReg5905NotApplicable}
														onChange={(val) => handleSelection(props, val)}
													>{t('notApplicable')}</Checkbox>
													{!!displayNotes && onRenderNotesEditor(props.dataItem)}
												</div>
											</div>
										</td>
									)}
								/>
							</Grid>
						</IntlProvider>
					</LocalizationProvider>
				</div>
				<div className="tool-bar">
					<Button disabled={isLoading||!dataChanged} kind="primary" onClick={() => handleSave()} >{t('save')}</Button>
				</div>
			</div>
			<UnexpectedErrorModal
				visible={!!errorModalMessage}
				error={''}
				message={errorModalMessage}
				onClose={() =>
				{
					setErrorModalMessage('');
				}}
			/>
		</>
	);
};

export default SurplusEntitlementPercentageAdjustments;
