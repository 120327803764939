
import { ForeignTaxesFields } from 'src/common/types/enums/ForeignTaxesFields';

const ForeignTaxesReportFields = {
	'header': [
		{
			'name': 'status',
			'type': 'status',
			'background': 'section-top-border'
		},
		{
			'name': 'taxYearEnd',
			'type': 'taxYearEnd'
		},
		{
			'name': 'countryId',
			'type': 'countryId'
		},
		{
			'name': 'calculatingCurrencyId',
			'type': 'currencyId'
		}
	],
	'taxes': [
		{
			'name': 'incomeTaxesPaid-title',
			'type': 'title'
		},
		{
			'name': `${ForeignTaxesFields.corporateIncomeTax}`,
			'type': 'amountWithNotes'
		},
		{
			'name': `${ForeignTaxesFields.withholdingTaxCredit}`,
			'type': 'amountWithNotes'
		},
		{
			'name': `${ForeignTaxesFields.groupTaxAdjustment}`,
			'type': 'amountWithNotes'
		},
		{
			'name': `${ForeignTaxesFields.otherCorporateTax}`,
			'type': 'amountWithNotes'
		},
		{
			'name': `${ForeignTaxesFields.totalIncomeTaxesPaid}`,
			'type': 'amountWithNotes',
			'background': 'highlight'
		},
		{
			'name': '',
			'type': 'separator fixed'
		},
		{
			'name': 'taxableIncomes-title',
			'type': 'title'
		},
		{
			'name': `${ForeignTaxesFields.incomeFapi}`,
			'type': 'amountWithNotes'
		},
		{
			'name': `${ForeignTaxesFields.incomeCapitalGains}`,
			'type': 'amountWithNotes'
		},
		{
			'name': `${ForeignTaxesFields.incomeABINonDTC}`,
			'type': 'amountWithNotes'
		},
		{
			'name': `${ForeignTaxesFields.incomeDeemedABINonDTC}`,
			'type': 'amountWithNotes'
		},
		{
			'name': `${ForeignTaxesFields.incomeDeemedABIDTC}`,
			'type': 'amountWithNotes'
		},
		{
			'name': `${ForeignTaxesFields.incomeAbidtc}`,
			'type': 'amountWithNotes'
		},
		{
			'name': `${ForeignTaxesFields.totalTaxableIncome}`,
			'type': 'amountWithNotes',
			'background': 'highlight'
		},
		{
			'name': '',
			'type': 'separator fixed'
		},
		{
			'name': 'taxAllocations-title',
			'type': 'title'
		},
		{
			'name': `${ForeignTaxesFields.allocationFapi}`,
			'type': 'amountWithNotes'
		},
		{
			'name': `${ForeignTaxesFields.allocationCapitalGainsInHybridSurplus}`,
			'type': 'amountWithNotes'
		},
		{
			'name': `${ForeignTaxesFields.allocationABINonDTC}`,
			'type': 'amountWithNotes'
		},
		{
			'name': `${ForeignTaxesFields.allocationDeemedABINonDTC}`,
			'type': 'amountWithNotes'
		},
		{
			'name': `${ForeignTaxesFields.allocationDeemedABIDTC}`,
			'type': 'amountWithNotes'
		},
		{
			'name': `${ForeignTaxesFields.allocationAbidtc}`,
			'type': 'amountWithNotes'
		},
		{
			'name': `${ForeignTaxesFields.totalTaxAllocation}`,
			'type': 'amountWithNotes',
			'background': 'highlight'
		},
		{
			'name': `${ForeignTaxesFields.taxPaidButDeniedFTXCredit}`,
			'type': 'amountWithNotes'
		}
	]
};

export default ForeignTaxesReportFields;