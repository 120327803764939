import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface NotificationState {
	surplusCalculationNotification: any;
	relationshipValidationNotification: any;
	isHubConnectionOpen: boolean;
}

const initialState: NotificationState = {
	surplusCalculationNotification: null,
	relationshipValidationNotification: null,
	isHubConnectionOpen: false
};

const notificationSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		addSurplusCalculationNotification: (state, action: PayloadAction<any>) =>
		{
			state.surplusCalculationNotification = action.payload;
		},

		addRelationshipValidationNotification: (state, action: PayloadAction<any>) =>
		{
			state.relationshipValidationNotification = action.payload;
		},
		clearSurplusCalculationNotification: (state) =>
		{
			state.surplusCalculationNotification = null;
		},
		clearRelationshipValidationNotification: (state) =>
		{
			state.relationshipValidationNotification = null;
		},
		setSignalRHubConnectionOpen: (state) =>
		{
			state.isHubConnectionOpen = true;
		}
	}
});

export const {
	addSurplusCalculationNotification,
	addRelationshipValidationNotification,
	clearSurplusCalculationNotification,
	clearRelationshipValidationNotification,
	setSignalRHubConnectionOpen
} = notificationSlice.actions;
export default notificationSlice.reducer;
