export enum EarningField {
	id = 'id',
	affiliateTaxYearEndId = 'affiliateTaxYearEndId',
	taxYearEnd = 'taxYearEnd',
	countryId = 'countryId',
	calculatingCurrencyId = 'calculatingCurrencyId',

	foreignTaxableIncome = 'foreignTaxableIncome',
	lessIncomeFromPropertyFAPI='lessIncomeFromPropertyFAPI',
	lessIncomeFromPropertyABI='lessIncomeFromPropertyABI',
	lessCapitalGain='lessCapitalGain',
	reg5907Adjustments='reg5907Adjustments',
	additionalReg5907Adjustments='additionalReg5907Adjustments',
	fapi='fapi',
	deemedABI='deemedABI',
	abi='abi',
	otherABIAdjustments='otherABIAdjustments',
	otherTaxableEarnings='otherTaxableEarnings',
	totalUnderForeignRules='totalUnderForeignRules',
	totalUnderPartIofTheAct='totalUnderPartIofTheAct',
}

