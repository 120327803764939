import { ICapitalGainsDispositionFormProps } from './ICapitalGainsDispositionFormProps';
import { NotificationService } from 'src/core/services/notificationService';
import { useEffect, useState } from 'react';
import { Utils } from 'src/common/utils/utils';
import { CapitalGainsDisposition, ICapitalGainsDisposition } from 'src/common/types/interfaces/ICapitalGainsDisposition';
import { CapitalGainsDispositionFields } from 'src/common/types/enums/CapitalGainsDispositionFields';
import { useTranslation } from 'react-i18next';
import { UnsavedChangesModal } from 'src/core/components/Dashboard/unsavedModal/UnsavedChangesModal';
import { UnexpectedErrorModal } from 'src/common/components/UnexpectedErrorModal/UnexpectedErrorModal';
import { DeleteModal } from 'src/common/components/DeleteModal/DeleteModal';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { Button, Modal } from '@appkit4/react-components';
import {
	CalendarFormControl,
	DropdownFormControl,
	NumericFormControl,
	TextAreaFormControl,
	TimeFormControl
} from 'src/common/components/FormControls';

import './CapitalGainsDispositionForm.scss';
import { RegularPatternEnum } from 'src/common/types/enums/RegularPatternEnum';
import { dateFormatter } from 'src/common/utils/dateFormatter';

const fields = CapitalGainsDispositionFields;
const August_20_2011 = new Date('2011-08-20 0:0:0:0');

const enum DispositionTypes {
	ExcludedProperty = 'ExcludedProperty',
	SharesPartnership = 'SharesPartnership',
	NonExcludedProperty = 'NonExcludedProperty',
}

const defaultFormError: Record<string, string | null> = {
	[fields.notes]: null,
	[fields.description]: null,
	[fields.isDepreciableProperty]: null,
	[fields.dateOfDisposition]: null,
	[fields.timeOfDisposition]: null,
	[fields.dateOfAcquisition]: null,
	[fields.transactionCurrencyId]: null,
	[fields.proceedsOfDisposition]: null,
	[fields.cost]: null,
	[fields.taxPaidOnDisposition]: null
};

export function CapitalGainsDispositionForm(props: ICapitalGainsDispositionFormProps): JSX.Element
{
	const { t } = useTranslation(
		'input',
		{
			keyPrefix: 'capitalGainsDispositionForm'
		}
	);

	const { t: fieldNames } = useTranslation(
		'input',
		{
			keyPrefix: 'capitalGainsDispositionFieldNames'
		}
	);

	const { t: errorMessages } = useTranslation(
		'input',
		{
			keyPrefix: 'capitalGainsDispositionFormErrorMessages'
		}
	);

	const [record, setRecord] = useState<ICapitalGainsDisposition | undefined>(undefined);

	const [hasChange, setHasChange] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [isStepOneSubmitted, setIsStepOneSubmitted] = useState(false);
	const [isStepTwoSubmitted, setIsStepTwoSubmitted] = useState(false);

	const [formError, setFormError] = useState<Record<string, any>>(defaultFormError);

	const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
	const [displayUnsavedChangesModal, setDisplayUnsavedChangesModal] = useState(false);
	const [errorModalMessage, setErrorModalMessage] = useState('');

	const [currentStep, setCurrentStep] = useState<1 | 2>(1);

	const onChangeStep = (step: 1 | 2): void =>
	{
		// validate the first step is valid before allowing progression to step 2
		if (step === 2)
		{
			setIsStepOneSubmitted(true);

	        const updatedRecord  = {
				...record!
			};
			if (record)
			{
				switch (record.type)
				{
				case DispositionTypes.ExcludedProperty:
					updatedRecord.electionAmount = 0;
					updatedRecord.taxPaidOnDisposition = 0;
					break;
				case DispositionTypes.NonExcludedProperty:
					updatedRecord.taxPaidOnDisposition = 0;
					break;
				case DispositionTypes.SharesPartnership:
					if (!!checkTaxPaidOnDispositionReadonly())
					{
						updatedRecord.taxPaidOnDisposition = 0 ;
					}
					break;
				}
			}

			if (!!checkUpdateFormValidity(updatedRecord, currentStep))
			{
				setRecord(updatedRecord);
				setCurrentStep(step);
			}
		}
		else
		{
			setCurrentStep(1);
		}
	};

	const getErrorMessage = (error: any): string | undefined =>
	{
		if (
			!!error &&
			!!error.response &&
			!!error.response.data &&
			!!error.response.data.Errors &&
			!!error.response.data.Errors.length
		)
		{
			const apiError = {
				...formError
			};

			error.response.data.Errors.forEach((fieldError: any) =>
			{
				const fieldName = Utils.pascalToCamel(fieldError.Field);
				const message = errorMessages(fieldError.ErrorCode) || '';
				apiError[fieldName]= message;
			});

			setFormError(apiError);
			return error.response.data.Errors.length;
		}

		return undefined;
	};

	const onCreate = async (): Promise<void> =>
	{
		try
		{
			setIsStepTwoSubmitted(true);

			if (!!checkUpdateFormValidity(record!, currentStep))
			{
				setIsSaving(true);

				await engagementService.createCapitalGainsDisposition(
					record!
				);

				onCloseModal(true);
				setIsSaving(false);

				onSuccessNotification({
					isCreate: true
				});
			}
		}
		catch (error)
		{
			if (!getErrorMessage(error))
			{
				setErrorModalMessage(errorMessages('createFailed') || '');
			}

			setIsSaving(false);
		}
	};

	const onUpdate = async (): Promise<void> =>
	{
		try
		{
			setIsStepTwoSubmitted(true);

			if (!!checkUpdateFormValidity(record!, currentStep))
			{
				setIsSaving(true);

				await engagementService.updateCapitalGainsDisposition({
					...record!,
					engagementId: props.engagementInfo?.id!
				});

				onCloseModal(true);
				setIsSaving(false);

				onSuccessNotification({
					isUpdate: true
				});
			}
		}
		catch (error)
		{
			if (!getErrorMessage(error))
			{
				setErrorModalMessage(errorMessages('updateFailed') || '');
			}

			setIsSaving(false);
		}
	};

	const onDelete = async (): Promise<void> =>
	{
		try
		{
			setIsStepTwoSubmitted(true);

			setDisplayDeleteModal(false);

			if (!!checkUpdateFormValidity(record!, currentStep))
			{
				setIsSaving(true);

				await engagementService.deleteCapitalGainsDisposition(
					props.engagementInfo?.id!,
					record!.id!
				);

				onCloseModal(true);
				setIsSaving(false);

				onSuccessNotification({
					isDelete: true
				});
			}
		}
		catch (error)
		{
			setErrorModalMessage(errorMessages('deleteFailed') || '');
			setIsSaving(false);
		}
	};

	const onDisplayDeleteModal = (): void =>
	{
		setDisplayDeleteModal(true);
	};

	const onCloseModal = (isSuccess?: boolean): void =>
	{
		if (!!hasChange && !isSuccess)
		{
			setDisplayUnsavedChangesModal(true);
		}
		else
		{
			onClose(!!isSuccess);
		}
	};

	const onCloseUnsavedChangesModal = (key: 'leave' | 'stay'): void =>
	{
		if (key === 'leave')
		{
			onClose();
		}
		else
		{
			setDisplayUnsavedChangesModal(false);
		}
	};

	const onReset = () =>
	{
		setIsSaving(false);
		setHasChange(false);
		setRecord(undefined);
		setCurrentStep(1);
		setIsStepOneSubmitted(false);
		setIsStepTwoSubmitted(false);
		setErrorModalMessage('');
		setDisplayDeleteModal(false);
		setDisplayUnsavedChangesModal(false);
		setFormError({
			...formError
		});
	};

	const onClose = async (isSuccess?: boolean): Promise<void> =>
	{
		setDisplayUnsavedChangesModal(false);
		props.onToggleDisplayModal(false);

		await Utils.timeout(500);

		onReset();
		props.onReset();

		if (!!isSuccess)
		{
			props.onRefresh();
		}
	};

	const onSuccessNotification = (
		{
			isCreate,
			isUpdate,
			isDelete
		}:
		{
			isCreate?: boolean;
			isUpdate?: boolean;
			isDelete?: boolean;
		}
	) =>
	{
		let message = '';

		if (isCreate)
		{
			message = t('createSuccess');
		}
		else if (isUpdate)
		{
			message = t('updateSuccess');
		}
		else if (isDelete)
		{
			message = t('deleteSuccess');
		}

		NotificationService.success({
			message
		});
	};

	const onValueChange = (field: string, value: any): void =>
	{
		const updatedForm: ICapitalGainsDisposition = {
			...record!,
			[field]: value === '' ? undefined : value
		};

		checkUpdateFormValidity(updatedForm, currentStep);

		setRecord(updatedForm);
	};

	const checkUpdateFormValidity = (
		form: ICapitalGainsDisposition,
		currentStep: number
	): boolean =>
	{
		const errors = {
			...defaultFormError
		};

		if (!form[fields.type])
		{
			errors[fields.type] = errorMessages(`${fields.type}Required`);
		}

		if (!form[fields.description])
		{
			errors[fields.description] = errorMessages(`${fields.description}Required`);
		}

		if (!!Utils.isNullUndefined(form[fields.isDepreciableProperty]))
		{
			errors[fields.isDepreciableProperty] = errorMessages(`${fields.isDepreciableProperty}Required`);
		}

		if (!form[fields.dateOfDisposition])
		{
			errors[fields.dateOfDisposition] = errorMessages(`${fields.dateOfDisposition}Required`);
		}

		if (form[fields.type]===DispositionTypes.NonExcludedProperty&&!form[fields.dateOfAcquisition])
		{
			errors[fields.dateOfAcquisition] = errorMessages(`${fields.dateOfAcquisition}Required`);
		}

		if (!form[fields.transactionCurrencyId])
		{
			errors[fields.transactionCurrencyId] = errorMessages(`${fields.transactionCurrencyId}Required`);
		}

		if (currentStep === 2)
		{
			if (!form[fields.proceedsOfDisposition])
			{
				errors[fields.proceedsOfDisposition] = errorMessages(`${fields.proceedsOfDisposition}Required`);
			}

			if (!form[fields.cost])
			{
				errors[fields.cost] = errorMessages(`${fields.cost}Required`);
			}

			if (Utils.isNullUndefined(form[fields.taxPaidOnDisposition]))
			{
				errors[fields.taxPaidOnDisposition] = errorMessages(`${fields.taxPaidOnDisposition}Required`);
			}
		}

		setFormError({
			...errors
		});

		// check if any fields have error messages and return status
		return Object.values(errors).every((e) => !e);
	};

	useEffect(
		() =>
		{
			if (!!props.record)
			{
				setRecord({
					...props.record
				});
			}
			else
			{
				setRecord(undefined);
			}
		},
		[props.record]
	);

	useEffect(
		() =>
		{
			setHasChange(
				!CapitalGainsDisposition.isSame(props.record, record)
			);
		},
		[record]
	);

	//check if income/profit tax paid on disposition is readonly
	const checkTaxPaidOnDispositionReadonly = () =>
	{
		if (record!)
		{
			const dateOfDisposition = dateFormatter.estDateStringOverrideToUtc(
				dateFormatter.mergeDateTime(
					record[fields.dateOfDisposition],
					record[fields.timeOfDisposition]
				)
			);

			const august20_2011_Utc = dateFormatter.estDateStringOverrideToUtc(
				dateFormatter.estDateToString(August_20_2011)
			);


			return (((record[fields.type] === DispositionTypes.SharesPartnership) &&
			(dateOfDisposition!<august20_2011_Utc!)) || (record[fields.type] === DispositionTypes.ExcludedProperty) ||
				(record[fields.type] === DispositionTypes.NonExcludedProperty));
		}
	};

	return <>
		<Modal
			className={'capital-gains-disposition-modal'}
			visible={
				props.displayModal &&
				!!props.record &&
				!!record
			}
			closeOnPressEscape={false}
			maskCloseable={false}
			initialFocus={false}
			title={
				!!props.isAdd ?
					t('addModalTitle') || '' :
					t('editModalTitle') || ''
			}
			onCancel={() =>
			{
				onCloseModal();
			}}
			footer={
				<div className={'buttons'}>
					<div className={'left'}>
						{
							!props.isAdd &&
							currentStep === 1 &&
							<Button
								kind={'negative'}
								disabled={!!isSaving}
								onClick={() => onDisplayDeleteModal()}
							>
								{
									t('deleteButtonText')
								}
							</Button>
						}
						{
							currentStep === 2 &&
							<Button
								kind={'secondary'}
								disabled={!!isSaving}
								onClick={() =>
								{
									onChangeStep(1);
								}}
							>
								{
									t('backButtonText')
								}
							</Button>
						}
					</div>
					<div className={'right'}>
						{
							!!props.isAdd &&
							currentStep === 2 &&
							<Button
								disabled={
									!hasChange ||
									!!isSaving
								}
								onClick={onCreate}
							>
								{
									t('saveButtonText')
								}
							</Button>
						}
						{
							!props.isAdd &&
							currentStep === 2 &&
							<Button
								disabled={
									!hasChange ||
									!!isSaving
								}
								onClick={onUpdate}
							>
								{
									t('saveButtonText')
								}
							</Button>
						}
						{
							currentStep === 1 &&
							<Button
								disabled={
									!!props.isAdd &&
									!hasChange
								}
								onClick={() =>
								{
									onChangeStep(2);
								}}
							>
								{
									t('nextButtonText')
								}
							</Button>
						}
						<Button
							kind={'secondary'}
							disabled={!!isSaving}
							onClick={() =>
							{
								onCloseModal();
							}}
						>
							{
								t('cancelButtonText')
							}
						</Button>
					</div>
				</div>
			}
		>
			{
				!!props.record &&
				!!record &&
				!!props.lineConfiguration &&
				!!props.lineConfiguration.length &&
				<div className={'capital-gains-disposition-modal-body'}>
					<div className={'capital-gains-disposition-form-body'}>
						<div className="title">{`Step ${currentStep}/2`}</div>
						{
							currentStep === 1 &&
							<>
								<DropdownFormControl
									isRequired
									isSearchable
									field={fields.type}
									placeholder={fieldNames(fields.type) || ''}
									value={record[fields.type]}
									options={
										!!props.lineConfiguration &&
										!!props.lineConfiguration.some((l) => l.field === fields.type) ?
											props.lineConfiguration.find((l) => l.field === fields.type)!.options :
											[]
									}
									isError={
										!!isStepOneSubmitted &&
										!!formError &&
										!!formError[fields.type]
									}
									errorMessage={formError[fields.type]}
									onChange={onValueChange}
								/>
								<TextAreaFormControl
									isAutosize
									isRequired
									field={fields.description}
									title={fieldNames(fields.description) || ''}
									value={record[fields.description]}
									isError={
										!!isStepOneSubmitted &&
										!!formError &&
										!!formError[fields.description]
									}
									errorMessage={formError[fields.description]}
									onChange={onValueChange}
								/>
								<DropdownFormControl
									isRequired
									field={fields.isDepreciableProperty}
									placeholder={fieldNames(fields.isDepreciableProperty) || ''}
									value={
										record[fields.isDepreciableProperty] !== undefined ?
											record[fields.isDepreciableProperty] ?
												'true' :
												'false' :
											undefined
									}
									options={
										!!props.lineConfiguration &&
										!!props.lineConfiguration.some((l) => l.field === fields.isDepreciableProperty) ?
											props.lineConfiguration.find((l) => l.field === fields.isDepreciableProperty)!.options :
											[]
									}
									isError={
										!!isStepOneSubmitted &&
										!!formError &&
										!!formError[fields.isDepreciableProperty]
									}
									errorMessage={formError[fields.isDepreciableProperty]}
									onChange={(field, value) =>
									{
										onValueChange(
											field,
											!!value ?
												JSON.parse(value.toString().toLowerCase()) :
												undefined
										);
									}}
								/>
								<CalendarFormControl
								   isRequired = {record[fields.type]===DispositionTypes.NonExcludedProperty}
									field={fields.dateOfAcquisition}
									title={fieldNames(fields.dateOfAcquisition) || ''}
									value={record[fields.dateOfAcquisition]}
									showClearIcon={true}
									maxDate={props.engagementInfo?.lastCalculationTaxationYearEnd}
									isError={
										!!isStepOneSubmitted &&
										!!formError &&
										!!formError[fields.dateOfAcquisition]
									}
									errorMessage={formError[fields.dateOfAcquisition]}
									onChange={onValueChange}
								/>
								<CalendarFormControl
									isRequired
									field={fields.dateOfDisposition}
									title={fieldNames(fields.dateOfDisposition) || ''}
									value={record[fields.dateOfDisposition]}
									minDate={props.foreignAffiliate?.analysisStartDate}
									maxDate={props.engagementInfo?.lastCalculationTaxationYearEnd}
									isError={
										!!isStepOneSubmitted &&
										!!formError &&
										!!formError[fields.dateOfDisposition]
									}
									errorMessage={formError[fields.dateOfDisposition]}
									onChange={onValueChange}
								/>
								<TimeFormControl
									isRequired
									field={fields.timeOfDisposition}
									title={fieldNames(fields.timeOfDisposition) || ''}
									value={record[fields.timeOfDisposition]===undefined?
										new Date(new Date().setHours(0,1,0,0)): record[fields.timeOfDisposition]}
									isError={
										!!isStepOneSubmitted &&
										!!formError &&
										!!formError[fields.timeOfDisposition]
									}
									errorMessage={formError[fields.timeOfDisposition]}
									onChange={onValueChange}
								/>
								<DropdownFormControl
									isRequired
									isSearchable
									field={fields.transactionCurrencyId}
									placeholder={fieldNames(fields.transactionCurrencyId) || ''}
									value={record[fields.transactionCurrencyId]}
									options={
										!!props.lineConfiguration &&
										!!props.lineConfiguration.some((l) => l.field === fields.transactionCurrencyId) ?
											props.lineConfiguration.find((l) => l.field === fields.transactionCurrencyId)!.options :
											[]
									}
									isError={
										!!isStepOneSubmitted &&
										!!formError &&
										!!formError[fields.transactionCurrencyId]
									}
									errorMessage={formError[fields.transactionCurrencyId]}
									onChange={(field, value) =>
									{
										onValueChange(
											field,
											!!Utils.isValidNumber(`${value}`) ?
												+value! :
												value
										);
									}}
								/>
							</>
						}
						{
							currentStep === 2 &&
							<>
								<NumericFormControl
									isRequired
									field={fields.proceedsOfDisposition}
									title={fieldNames(fields.proceedsOfDisposition) || ''}
									value={record[fields.proceedsOfDisposition]}
									format={RegularPatternEnum.WholeNumber}
									isError={
										!!isStepTwoSubmitted &&
										!!formError &&
										!!formError[fields.proceedsOfDisposition]
									}
									errorMessage={formError[fields.proceedsOfDisposition]}
									onChange={onValueChange}
								/>
								<NumericFormControl
									field={fields.electionAmount}
									isReadonly = {record[fields.type]===DispositionTypes.ExcludedProperty}
									title={fieldNames(fields.electionAmount) || ''}
									value={record[fields.electionAmount]}
									isError={
										!!isStepTwoSubmitted &&
										!!formError &&
										!!formError[fields.electionAmount]
									}
									errorMessage={formError[fields.electionAmount]}
									onChange={onValueChange}
								/>
								<NumericFormControl
									isRequired
									field={fields.cost}
									title={fieldNames(fields.cost) || ''}
									value={record[fields.cost]}
									isError={
										!!isStepTwoSubmitted &&
										!!formError &&
										!!formError[fields.cost]
									}
									errorMessage={formError[fields.cost]}
									onChange={onValueChange}
								/>
								<NumericFormControl
									field={fields.expenses}
									title={fieldNames(fields.expenses) || ''}
									value={record[fields.expenses]}
									isError={
										!!isStepTwoSubmitted &&
										!!formError &&
										!!formError[fields.expenses]
									}
									errorMessage={formError[fields.expenses]}
									onChange={onValueChange}
								/>
								<NumericFormControl
									field={fields.otherAdjustments}
									title={fieldNames(fields.otherAdjustments) || ''}
									value={record[fields.otherAdjustments]}
									isError={
										!!isStepTwoSubmitted &&
										!!formError &&
										!!formError[fields.otherAdjustments]
									}
									errorMessage={formError[fields.otherAdjustments]}
									onChange={onValueChange}
								/>
								<NumericFormControl
									field={fields.preAcquisitionAmount}
									title={fieldNames(fields.preAcquisitionAmount) || ''}
									value={record[fields.preAcquisitionAmount]}
									isError={
										!!isStepTwoSubmitted &&
										!!formError &&
										!!formError[fields.preAcquisitionAmount]
									}
									errorMessage={formError[fields.preAcquisitionAmount]}
									onChange={onValueChange}
								/>
								<NumericFormControl
									isReadonly
									field={fields.calculatedCapitalGainOrLoss}
									title={fieldNames(fields.calculatedCapitalGainOrLoss) || ''}
									value={
										Utils.add(
											record[fields.proceedsOfDisposition],
											record[fields.electionAmount],
											record[fields.cost],
											record[fields.expenses],
											record[fields.otherAdjustments],
											record[fields.preAcquisitionAmount]
										)
									}
									isError={
										!!isStepTwoSubmitted &&
										!!formError &&
										!!formError[fields.calculatedCapitalGainOrLoss]
									}
									errorMessage={formError[fields.calculatedCapitalGainOrLoss]}
									onChange={onValueChange}
								/>
								<NumericFormControl
									isRequired
									field={fields.taxPaidOnDisposition}
									title={fieldNames(fields.taxPaidOnDisposition) || ''}
									isReadonly = {checkTaxPaidOnDispositionReadonly()}
									value={record[fields.taxPaidOnDisposition]}
									isError={
										!!isStepTwoSubmitted &&
										!!formError &&
										!!formError[fields.taxPaidOnDisposition]
									}
									errorMessage={formError[fields.taxPaidOnDisposition]}
									onChange={onValueChange}
								/>
								<TextAreaFormControl
									isAutosize
									field={fields.notes}
									title={fieldNames(fields.notes) || ''}
									value={record[fields.notes]}
									isError={
										!!isStepOneSubmitted &&
										!!formError &&
										!!formError[fields.notes]
									}
									errorMessage={formError[fields.notes]}
									onChange={onValueChange}
								/>
							</>
						}
					</div>
				</div>
			}
		</Modal>
		<UnsavedChangesModal
			visible={displayUnsavedChangesModal}
			onClose={onCloseUnsavedChangesModal}
			title={t('discardChangesTitle') || ''}
		/>
		<UnexpectedErrorModal
			visible={!!errorModalMessage}
			error={''}
			message={errorModalMessage}
			onClose={() =>
			{
				setErrorModalMessage('');
			}}
		/>
		<DeleteModal
			visible={displayDeleteModal}
			title={t('deleteModalTitle')}
			deleteMessage={t('deleteMessage')}
			setVisible={setDisplayDeleteModal}
			onDelete={onDelete}
		/>
	</>;
}