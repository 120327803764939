import { inputRecordHelper } from 'src/common/utils/inputRecordHelper';
import { IRowDataProps } from './IRowDataProps';

import './RowData.scss';
import { GridCellEditor } from '../GridCellEditor/GridCellEditor';

export function RowData<T>(props: IRowDataProps<T>): JSX.Element
{
	return <>
		{
			!!props.data &&
			props.data
				.map((record, index) =>
				{
					const recordData = record as any;
					const isHighlighted = !!props.highlightedOnField &&
						!!recordData && !!recordData.data && recordData.data?.[props.highlightedOnField];
					return <>
						<td className={`row-data ${index === 0 ? 'first' : ''}
						 ${props.rowInformation.lineItemConfiguration?.editable &&
							props.rowInformation.lineItemConfiguration?.inline?	'inline-editable':'readonly-cell'} 
						 ${props.rowIndex === 0 ? 'first-row' : ''}
						 ${isHighlighted ? 'highlight' : ''}
						 `}>
							{
								props.rowInformation.lineItemConfiguration?.editable&&props.rowInformation.lineItemConfiguration.inline ?
									<>
										<GridCellEditor
										  rowInformation={props.rowInformation}
										  data={record}
										  onChange={props.onChange}
										/>
									</> :
									<>
										{inputRecordHelper.formatField(
											props.rowInformation,
											record
										)}
									</>
							}
						</td>
					</>;
				})
		}
	</>;
}