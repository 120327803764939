import { Button, Modal } from '@appkit4/react-components';
import { useTranslation } from 'react-i18next';
import './CreateEngagementModal.scss';
import { useState } from 'react';

export const CreateEngagementModal = (props: any) =>
{
	const { t } = useTranslation('engagementSetup');
	const [disableIcon, setDisableIcon] = useState<boolean>(false);

	const preventDoubleClick = () =>
	{
		setDisableIcon(true);

		setTimeout(() =>
		{
			setDisableIcon(false);
		}, 3000);
	};

	if (props?.visible === false)
	{
		return null;
	}

	return (
		<>
			<Modal
				className="create-engagement-modal"
				visible={props.visible}
				initialFocus={false}
				closeOnPressEscape={false}
				maskCloseable={false}
				title={props.title}
				onCancel={() => props.setVisible(false)}
				footer={
					<div className="footer-wrapper">
						<Button
							kind="secondary"
							onClick={() =>
							{
								props.setVisible(false);
							}}
						>
							{t('createEngagement.cancel')}
						</Button>
						<div onClick={preventDoubleClick}>
							<Button kind="primary" onClick={() => props.onCreate()} disabled={disableIcon}>
								{t('createEngagement.create')}
							</Button>
						</div>
					</div>
				}
			>
				<div className="create-message">{`${t(
					'createEngagement.createMessage'
				)} "${props.engagementName}"?`}</div>
			</Modal>
		</>
	);
};
