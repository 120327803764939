import React, { useEffect, useState, useRef } from 'react';
import './RelationshipList.scss';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import {
	Grid,
	GridColumn,
	GridItemChangeEvent,
	GridNoRecords,
	GridRowProps,
	GridToolbar
} from '@progress/kendo-react-grid';
import { IRelationship } from 'src/features/engagement/models/IRelationship';
import { engagementService } from 'src/features/engagement/services/engagementService';
import {
	Checkbox,
	Search,
	Button,
	Notification,
	Tooltip
} from '@appkit4/react-components';
import { Constants } from 'src/common/types/constants';
import { RootState, useAppSelector } from 'src/store/store';
import { INameId } from 'src/common/types/interfaces/INameId';
import { MergerModal } from '../Merger/MergerModal';
import { ActionEnum } from 'src/common/types/enums/ActionEnum';
import { EngagementStepEnum } from '../../../../models/EngagementStepEnum';
import { RelationshipCellEditor } from '../RelationshipCellEditor/RelationshipCellEditor';
import { RelationshipFieldEnum } from '../RelationshipCellEditor/RelationshipFieldEnum';
import { CheckStatusEnum } from 'src/common/types/enums/CheckStatusEnum';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import { IRelationshipError } from 'src/features/engagement/models/IRelationshipError';
import { Utils } from 'src/common/utils/utils';
import { UnsavedChangesModal } from 'src/core/components/Dashboard/unsavedModal/UnsavedChangesModal';
import { useTranslation } from 'react-i18next';
import { UnexpectedErrorModal } from 'src/common/components/UnexpectedErrorModal/UnexpectedErrorModal';
import { NotificationService } from 'src/core/services/notificationService';
import { AcquisitionDispositionModal } from '../AcquisitionDisposition/AcquisitionDispositionModal';
import { LiquidationModal } from '../Liquidation/LiquidationModal';
import { IRelationshipListProps } from './IRelationshipListProps';
import { RegularPatternEnum } from 'src/common/types/enums/RegularPatternEnum';
import { IAffiliateOwner } from 'src/features/engagement/models/IAffiliateOwner';
import { IForeignAffiliate } from 'src/features/engagement/models/IForeignAffiliate';
import { DeleteModal } from 'src/common/components/DeleteModal/DeleteModal';
import { RelationshipValidationFields } from 'src/common/types/enums/RelationshipValidationFields';
import { ValidationInfoElement, ValidationErrorsCell } from './ValidationErrorsElements';
import { useDispatch, useSelector } from 'react-redux';
import { clearRelationshipValidationNotification } from 'src/store/reducers/notificationSlice';

const enum NotificationType {
	Merger = 'Merger',
	Liquidation = 'Liquidation',
	Acquisition = 'Acquisition'
};

export const RelationshipList = (props: IRelationshipListProps) =>
{
	const [relationships, setRelationships] = useState<IRelationship[]>([]);
	const [filterRelationships, setFilterRelationships] = useState<IRelationship[]>([]);
	const [editingRelationship, setEditingRelationship] = useState<IRelationship | undefined>(undefined);
	const [rawAffiliateOwners, setRawAffiliateOwners] = useState<IAffiliateOwner[]>([]);
	const [affiliateOwners, setAffiliateOwners] = useState<IAffiliateOwner[]>([]);
	const [showUnsavedModal, setShowUnsavedModal] = useState<boolean>(false);
	const [showUnexpectedErrorModal, setShowUnexpectedErrorModal] = useState<boolean>(false);
	const [unexpectedError, setUnExpectedError] = useState<string>('');
	const [editingError, setEditingError] = useState<IRelationshipError | undefined>(undefined);
	const [searchText, setSearchText] = useState<string>('');
	const [selectedRelationships, setSelectedRelationships] = useState<IRelationship[]>([]);
	const [singleSelected, setSingleSelected] = useState<Boolean>(false);
	const [showMergerModal, setShowMergereModal] = useState<boolean>(false);
	const [showLiquidataionModal, setShowLiquidationModal] = useState<boolean>(false);
	const [checkStatus, setCheckStatus] = useState<CheckStatusEnum>(CheckStatusEnum.unchecked);
	const [isAllExpanded, setIsAllExpanded] = useState<boolean>(false);
	const [newAffiliateRelationshipIds, setNewAffiliateRelationshipIds] =
		useState<{ notificationType: NotificationType, items: number[] }>({
			notificationType: NotificationType.Merger,
			items: []
		});
	const { t } = useTranslation('engagementSetup');
	const { t: errorMessage } = useTranslation('errors');

	const equityClasses = useAppSelector((state) => state.appSettings.equityClasses);
	const [showAcquisitionDispositionModal, setShowAcquisitionDispositionModal] = useState(false);
	const [defaultDate, setDefaultDate] = useState<Date | undefined>(undefined);
	const [lastCalcYear, setLastCalcYear] = useState<Date | undefined>(undefined);
	const [enableMerger, setEnableMerger] = useState<Boolean>(false);
	const [allRelationshipDefined, setAllRelationshipDefined] = useState<boolean>(false);
	const [engagementAffiliates, setEngagementAffiliates] = useState<IForeignAffiliate[]>([]);
	const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
	const [deletingRelationship, setDeletingRelationship] = useState<any>(undefined);
	const intervalId = useRef<number | undefined>(undefined);
	const validationStatusErrorCount = useRef<number>(0);

	const validationInitialTooltip = {
		'validationStatus': `${t('relationships.validation')} ${t('relationships.neverRunStatus')}`,
		'lastValidationOn': `${t('relationships.lastValidationOn')}: ${t('relationships.notApplicable')}`
	};

	const [validationStatus, setValidationStatus] = useState<string | undefined>();
	const [valiadtionTooltip, setValiadtionTooltip] = useState<any>(validationInitialTooltip);
	const [isValidateDisabled, setIsValidateDisabled] = useState<boolean>(false);
	const [validationStatusHistory, setValidationStatusHistory] = useState<any>();
	const [validationErrors, setValidationErrors] = useState<any>([]);

	const relationshipValidationNotification = useSelector((state: RootState) => state.notifications.relationshipValidationNotification);
	const dispatch = useDispatch();

	const loadRelationships = () =>
	{
		if (props.clientId && props.engagementId)
		{
			const regPattern = new RegExp(RegularPatternEnum.Exponential);

			engagementService
				.getForeignAffiliatesByEngagementId(props.engagementId)
				.then((res: any) =>
				{
					if (res.data.result)
					{
						const fas = res.data.result.map((fa: any) =>
						{
							const f: IForeignAffiliate = {
								affiliateId: fa.affiliateId,
								name: fa.name,
								currencyId: fa.currencyId,
								countryId: fa.countryId,
								taxationYearEnd: dateFormatter.toStartOfDayLocal(fa.taxationYearEnd),
								dateBecameFa: (new Date(fa.dateBecameFa).getFullYear() <= Constants.MinYear) ?
									undefined : dateFormatter.toStartOfDayLocal(fa.dateBecameFa),
								analysisStartDate: (new Date(fa.analysisStartDate).getFullYear() <= Constants.MinYear) ?
									undefined : dateFormatter.toStartOfDayLocal(fa.analysisStartDate),
								analysisEndDate: dateFormatter.utcToEst(fa.analysisEndDate),
								inEditableMode: false,
								isNewItem: false,
								selected: false,
								showedWarning: false,
								isSystemAdded: fa.isSystemAdded
							};
							return f;
						});
						setEngagementAffiliates(fas);
					}
				});

			engagementService.getRelationshipOwners(props.clientId).then((res: any) =>
			{
				setAffiliateOwners([
					...res.data.result.map((item: IAffiliateOwner) =>
					{
						return {
							id: item.id,
							name: `${item.name} ${item.isTaxPayer ? t('relationships.taxPayerSuffix') : ''}`,
							isTaxPayer: item.isTaxPayer
						};
					})
				]);
			});
			engagementService
				.getRelationships(props.engagementId)
				.then(async (res: any) =>
				{
					const engagementFAs = res.data.result.engagementAffiliates.map((fa: any) =>
					{
						const affiliateOwner: IAffiliateOwner = {
							id: fa.affiliateId,
							name: fa.affiliateName,
							isTaxPayer: false,
							dateBecameFa: fa.dateBecameFa
						};
						return affiliateOwner;
					});


					const taxPayersResponse = await engagementService.getTaxPayers(props.clientId!);
					const taxPayers = taxPayersResponse.data.result.map((tp: any) =>
					{
						const taxPayerOwner: IAffiliateOwner = {
							id: tp.id,
							name: tp.name,
							isTaxPayer: true,
							dateBecameFa: undefined
						};
						return taxPayerOwner;
					});
					const owners = [...taxPayers, ...engagementFAs];
					//The purchaser dropdown options are other FAs in the engagement, taxpayers under that client and "Other"
					//at the As of Date and Time
					setRawAffiliateOwners(owners);


					const rows = res.data.result.engagementAffiliates.map((fa: any) =>
					{
						if (fa.affiliateOwners.length < 2)
						{
							const subRows = fa.affiliateOwners.map((item: any) =>
							{
								const r: IRelationship = {
									relationshipId: item.relationshipId,
									engagementId: props.engagementId ?? 0,
									faId: fa.affiliateId,
									faName: fa.affiliateName,
									dateBecameFa: dateFormatter.utcToEst(fa.dateBecameFa),
									currencyId: fa.affiliateCurrencyId,
									ownerAffiliateId: item.ownerAffiliateId ?? Constants.ID_EMPTY,
									ownerName: item.ownerAffiliateName,
									equityClassId: item.equityClassId,
									equityClass: equityClasses?.find((c: INameId) => c.id === item.equityClassId)?.name ?? '',
									equityPercentage: regPattern.test(item.equityPercentage) ?
										item.equityPercentage.toFixed(8) : parseFloat(item.equityPercentage),
									SEP: regPattern.test(item.sep) ?
										item.sep.toFixed(8) : parseFloat(item.sep),
									asOfDate: dateFormatter.utcToEst(item.effectiveDate),
									asOfTime: dateFormatter.utcToEst(item.effectiveDate),
									effectiveDate: item.effectiveDate,
									changeReason: item.relationshipReason,
									changeReasonId: item.relationshipReasonId,
									survivorAffiliateId: item.survivorAffiliateId,
									survivorAffiliateName: item.survivorAffiliateName,
									notes: item.notes,
									primary: true,
									selected: false,
									editable: true,
									inEdit: false,
									expanded: false,
									isNew: false,
									dirty: false,
									operatable: fa.availableForOperations
								};
								return r;
							});

							if (subRows.length === 0)
							{
								return [
									{
										relationshipId: fa.affiliateId,
										engagementId: props.engagementId ?? 0,
										faId: fa.affiliateId,
										faName: fa.affiliateName,
										dateBecameFa: fa.dateBecameFa,
										currencyId: fa.affiliateCurrencyId,
										ownerAffiliateId: 0,
										ownerAffiliateName: '',
										equityClassId: 0,
										equityClass: '',
										equityPercentage: undefined,
										SEP: undefined,
										asOfDate: fa.dateBecameFa,
										asOfTime: undefined,
										effectiveDate: fa.dateBecameFa,
										changeReason: '',
										changeReasonId: 0,
										survivorAffiliateId: 0,
										survivorAffiliateName: '',
										notes: '',
										primary: true,
										selected: false,
										editable: true,
										inEdit: false,
										expanded: false,
										isNew: true,
										dirty: false,
										operatable: false
									}
								];
							}

							return subRows;
						}
						else
						{
							let isGroupExpanded = false;
							const subRows = fa.affiliateOwners.map((item: any) =>
							{
								isGroupExpanded = isGroupExpanded ||
									(!!relationships.find((r: IRelationship) => r.primary === true && r.expanded === true &&
										r.engagementId === props.engagementId && r.faId === fa.affiliateId));;
								const r: IRelationship = {
									relationshipId: item.relationshipId,
									engagementId: props.engagementId ?? Constants.ID_EMPTY,
									faId: fa.affiliateId,
									faName: fa.affiliateName,
									dateBecameFa: dateFormatter.utcToEst(fa.dateBecameFa),
									currencyId: fa.affiliateCurrencyId,
									ownerAffiliateId: item.ownerAffiliateId ?? Constants.ID_EMPTY,
									ownerName: item.ownerAffiliateName,
									equityClassId: item.equityClassId,
									equityClass: equityClasses?.find((c: INameId) => c.id === item.equityClassId)?.name ?? '',
									equityPercentage: regPattern.test(item.equityPercentage) ?
										item.equityPercentage.toFixed(8) : parseFloat(item.equityPercentage),
									SEP: regPattern.test(item.sep) ?
										item.sep.toFixed(8) : parseFloat(item.sep),
									asOfDate: dateFormatter.utcToEst(item.effectiveDate),
									asOfTime: dateFormatter.utcToEst(item.effectiveDate),
									effectiveDate: item.effectiveDate,
									changeReason: item.relationshipReason,
									changeReasonId: item.relationshipReasonId,
									survivorAffiliateId: item.survivorAffiliateId,
									survivorAffiliateName: item.survivorAffiliateName,
									notes: item.notes,
									primary: false,
									selected: false,
									editable: true,
									inEdit: false,
									expanded: false,
									isNew: false,
									dirty: false,
									operatable: true
								};
								return r;
							});

							const primaryRow = {
								...subRows[0],
								relationshipId: Utils.generateTempId(),
								primary: true,
								notes: '',
								editable: false,
								operatable: fa.availableForOperations,
								expanded: isGroupExpanded
							};

							return [primaryRow, ...subRows];
						}
					});
					setRelationships(rows.flat());
					setAllRelationshipDefined(!res.data.result.engagementAffiliates.some((fa: any) => fa.affiliateOwners.length === 0));
				});
		}
	};

	const loadRelationshipsValidationDetails = async (
		engagementId: number
	): Promise<void> =>
	{
		try
		{
			const response = await engagementService.getRelationshipsValidationDetails(engagementId);
			if (!!response && !!response.data && !!response.data.result)
			{
				const result = response.data.result;
				setValidationErrors(!!result.errors ? result.errors : []);
			}
		}
		catch (error)
		{
			triggerErrorNotification(
				t('relationships.validationResultError')
			);
		}
	};

	const checkValidationStatus = (result: any) =>
	{
		setValidationStatusHistory(result);
		if (!!result[RelationshipValidationFields.Status])
		{
			const status = result[RelationshipValidationFields.Status];
			const updatedOn = result[RelationshipValidationFields.UpdatedOn];

			setValidationStatus(status);
			setValiadtionTooltip(createTooltip(status, updatedOn));
		}
		else if (result[RelationshipValidationFields.Status] === null)
		{
			setValiadtionTooltip(validationInitialTooltip);
			setValidationStatus('');
		}
	};
	const loadRelationshipsValidationStatus = async (
		engagementId: number
	): Promise<void> =>
	{
		try
		{
			const response = await engagementService.getRelationshipsValidationStatus(engagementId);
			if (!!response && !!response.data && !!response.data.result)
			{
				checkValidationStatus(response.data.result);
			}
			else
			{
				setValidationStatus(undefined);
			}
			validationStatusErrorCount.current = 0;
		}
		catch (error)
		{
			if (!!validationStatusErrorCount && validationStatusErrorCount.current >= 3)
			{
				triggerErrorNotification(
					t('relationships.validationStatusError')
				);

				if (intervalId.current !== undefined)
				{
					clearInterval(intervalId.current);
				}
				validationStatusErrorCount.current = 0;
				setIsValidateDisabled(false);
				if (!!validationStatusHistory && !!validationStatusHistory?.updatedOn)
				{
					setValiadtionTooltip(createTooltip(RelationshipValidationFields.Failed, validationStatusHistory?.updatedOn!));
				}
				else
				{
					setValiadtionTooltip(validationInitialTooltip);
				}
			}
		}
	};

	const updateFAsWithErrors = (fas: any, errors: any) =>
	{
		const updatedFAsId: number[] = [];
		const updatedFAs = fas.map((fa: any) =>
		{
			const faWithErrors = errors.find((f: any) => f.affiliateId === fa.faId && !updatedFAsId.includes(fa.faId));
			if (!!faWithErrors)
			{
				updatedFAsId.push(fa.faId);
				return {
					...fa,
					errorTypes: faWithErrors.errorTypes
				};
			}
			else
			{
				return fa;
			}
		});
		return updatedFAs;
	};

	useEffect(() =>
	{
		if (!!relationshipValidationNotification)
		{
			checkValidationStatus(relationshipValidationNotification);
			dispatch(clearRelationshipValidationNotification());
		}
	}, [relationshipValidationNotification]);

	useEffect(() =>
	{
		loadRelationships();
		if (!!props.engagementId && !!props.fromEngagementDashboard)
		{
			loadRelationshipsValidationStatus(props.engagementId);
		}
	}, [props.engagementId]);

	useEffect(() =>
	{
		if (!!validationStatus && !!props.engagementId && !!props.fromEngagementDashboard &&
			(validationStatus === RelationshipValidationFields.NotProcessed ||
				validationStatus === RelationshipValidationFields.InProgress))
		{
			setIsValidateDisabled(true);
			intervalId.current = window.setInterval(() =>
			{
				loadRelationshipsValidationStatus(props.engagementId!);
			}, 60000);
		}

		if (!!validationStatus && !!props.engagementId && !!props.fromEngagementDashboard &&
			(validationStatus === RelationshipValidationFields.Completed ||
				validationStatus === RelationshipValidationFields.Failed))
		{
			setIsValidateDisabled(false);
			loadRelationshipsValidationDetails(props.engagementId!);

			if (intervalId.current !== undefined)
			{
				clearInterval(intervalId.current);
				intervalId.current = undefined;
			}
		}
		return () =>
		{
			if (intervalId.current !== undefined)
			{
				clearInterval(intervalId.current);
			}
		};
	}, [validationStatus]);

	useEffect(() =>
	{
		if (props.engagementDetail)
		{
			setLastCalcYear(props.engagementDetail.lastCalculationTaxationYearEnd);
		}
	}, [props.engagementDetail]);


	useEffect(() =>
	{
		!!props.onCreateEngagementReady && props.onCreateEngagementReady(allRelationshipDefined);
	}, [allRelationshipDefined]);

	useEffect(() =>
	{
		if (newAffiliateRelationshipIds.items?.length > 0)
		{
			NotificationService.notify({
				component: notificationElement,
				onClose: () => handleNotificationClose()
			});
			loadRelationships();
		}
	}, [newAffiliateRelationshipIds]);

	useEffect(() =>
	{
		const list = relationships.filter(
			(r: IRelationship) => r.selected === true
		);
		setSingleSelected(list.length === 1);
		if (list.length === 1)
		{
			const selectedRelationship = list[0];
			const childRelationship = relationships.filter((r: IRelationship) => r.faId === selectedRelationship.faId);
			const equityClassData: Record<string, { effectiveDate: Date; equityPercentage: Number }> =
				childRelationship.reduce((acc: any, item) =>
				{
					const { equityClassId, effectiveDate, equityPercentage } = item;
					if ((!acc[equityClassId] || new Date(acc[equityClassId].effectiveDate) <= new Date(effectiveDate || '')))
					{
						acc[equityClassId] = {
							effectiveDate,
							equityPercentage
						};
					}

					return acc;
				}, {
				});

			if (equityClassData)
			{
				const equityClassArray: { equityClassId: string; effectiveDate: Date; equityPercentage: Number }[] =
					Object.entries(equityClassData).map(([equityClassId, { effectiveDate, equityPercentage }]) => ({
						equityClassId,
						effectiveDate,
						equityPercentage
					}));
				const allZeroEquityClassData = equityClassArray.every((item: any) => item.equityPercentage === 0);
				setEnableMerger(!allZeroEquityClassData);
			}
		}
		setSelectedRelationships(list);

		if (relationships)
		{
			const updatedRelationships = !!props.fromEngagementDashboard ?
				updateFAsWithErrors(relationships, validationErrors) : relationships;

			if (searchText === '')
			{
				setFilterRelationships(updatedRelationships);
			}
			else
			{
				const updatedFilterRelationships = updatedRelationships.filter(
					(r: IRelationship) =>
						r.faName.toLowerCase().includes(searchText.trim().toLowerCase())
				);
				setFilterRelationships(updatedFilterRelationships);
			}

			//check Checked Status
			const checkedItems = relationships.filter(
				(r: IRelationship) => r.selected && r.primary
			);
			if (checkedItems.length === 0)
			{
				setCheckStatus(CheckStatusEnum.unchecked);
			}
			else
			{
				const primaryItems = relationships.filter(
					(r: IRelationship) => r.primary
				);
				if (primaryItems.length === checkedItems.length)
				{
					setCheckStatus(CheckStatusEnum.checked);
				}
				else
				{
					setCheckStatus(CheckStatusEnum.indeterminate);
				}
			}
		}
	}, [relationships, searchText, validationErrors]);

	useEffect(() =>
	{
		if (props.currentStep === EngagementStepEnum.Relationships)
		{
			switch (props.action)
			{
			case ActionEnum.Next:
				!!props.onAction && props.onAction(props.action, true);
				break;
			case ActionEnum.Back:
			case ActionEnum.Draft:
			case ActionEnum.Create:
				if (editingRelationship)
				{
					const editingRow = relationships.find(
						(r: IRelationship) =>
							r.inEdit &&
								r.relationshipId === editingRelationship.relationshipId
					);
					handleAction(ActionEnum.Cancel, editingRow);
					!!props.onAction && props.onAction(props.action, false);
				}
				else
				{
					!!props.onAction && props.onAction(props.action, true);
				}
				break;
			}
		}
	}, [props.action]);

	const onSearchTextChange = async (val: string) =>
	{
		setSearchText(val);
	};

	const itemChange = (event: GridItemChangeEvent) =>
	{
		if (editingRelationship === undefined)
		{
			const field = event.field || '';
			const newData = relationships.map((item: any) =>
			{
				if (item.relationshipId === event.dataItem.relationshipId)
				{
					item[field] = event.value;
				}
				return item;
			});
			setRelationships(newData);
			//if all primary relationships are expanded show collapse all
			setIsAllExpanded(relationships?.filter((r: IRelationship) => r.primary)?.every((r: IRelationship) => r.expanded));
		}
		else
		{
			if (event.field)
			{
				event.dataItem[event.field] = event.value;
			}
		}
	};

	const checkAll = (e: any) =>
	{
		const updatedData = relationships.map((r: IRelationship) =>
		{
			if (r.primary)
			{
				return {
					...r,
					selected: e
				};
			}
			else
			{
				return {
					...r
				};
			}
		});
		setRelationships(updatedData);
	};

	const handleExpandAll = (isExpanded: boolean) =>
	{
		const updatedData = relationships.map((r: IRelationship) =>
		{
			if (r.primary)
			{
				return {
					...r,
					expanded: isExpanded
				};
			}
			else
			{
				return {
					...r
				};
			}
		});
		setRelationships(updatedData);
		setIsAllExpanded(isExpanded);
	};

	const validateRelationship = (dataItem: IRelationship) =>
	{
		const error: IRelationshipError = {
			ownerAffiliateId: '',
			equityClassId: '',
			equityPercentage: '',
			SEP: '',
			asOfDate: '',
			asOfTime: '',
			effectiveDate: '',
			changeReason: '',
			survivorAffiliateName: '',
			notes: ''
		};

		if (!dataItem.ownerAffiliateId)
		{
			error.ownerAffiliateId = t('relationships.errors.ownedBy');
		}
		if (!dataItem.equityClassId)
		{
			error.equityClassId = t('relationships.errors.equityClass');
		}

		if (dataItem.SEP === undefined || String(dataItem.SEP) === '')
		{
			error.SEP = t('relationships.errors.SEP');
		}
		if (
			dataItem.equityPercentage === undefined ||
			String(dataItem.equityPercentage) === ''
		)
		{
			error.equityPercentage = t('relationships.errors.equity');
		}
		if (Number(dataItem.equityPercentage) === 0 &&
			(dataItem.SEP !== 0 && dataItem.SEP! > 0))
		{
			error.SEP = t('relationships.errors.nilSEP');;
		}
		if (dataItem.SEP && dataItem.SEP > 100)
		{
			error.SEP = t('relationships.errors.SEP100');
		}
		if (dataItem.equityPercentage && dataItem.equityPercentage > 100)
		{
			error.equityPercentage = t('relationships.errors.equity100');
		}
		if (dataItem.asOfDate === undefined)
		{
			error.asOfDate = t('relationships.errors.asOfDate');
		}
		// if (dataItem.asOfTime === undefined)
		// {
		// 	error.asOfTime = t('relationships.errors.asOfTime');
		// }
		if (hasError(error))
		{
			setEditingError(error);
			return false;
		}
		else
		{
			setEditingError(undefined);
			return true;
		}
	};

	const handleApiErrorResponse = (err: any) =>
	{
		if (err.response)
		{
			if (err.response.status >= 500)
			{
				setUnExpectedError(err.response.data.Errors[0].Message);
				setShowUnexpectedErrorModal(true);
			}
			else if (err.response.status >= 400)
			{
				const errors = err.response.data.Errors;
				const relationshipError: IRelationshipError = {
					ownerAffiliateId: '',
					equityClassId: '',
					equityPercentage: '',
					SEP: '',
					asOfDate: '',
					asOfTime: '',
					effectiveDate: '',
					changeReason: '',
					survivorAffiliateName: '',
					notes: ''
				};
				if (errors)
				{
					errors.forEach((error: any) =>
					{
						if (error.Field === 'EffectiveDate')
						{
							relationshipError.asOfDate = errorMessage(
								`code.${error.ErrorCode}`
							);
						}
						if (error.Field === 'Owner')
						{
							relationshipError.ownerAffiliateId = errorMessage(
								`code.${error.ErrorCode}`
							);
						}
						if (error.Field === 'SEP')
						{
							relationshipError.SEP = errorMessage(
								`code.${error.ErrorCode}`
							);
						}
						if (error.Field === 'EquityPercentage')
						{
							relationshipError.equityPercentage = errorMessage(
								`code.${error.ErrorCode}`
							);
						}
					});
					setEditingError(relationshipError);
				}
			}
		}
	};

	const handleAction = (action: ActionEnum, dataItem: any) =>
	{
		switch (action)
		{
		case ActionEnum.Save:
			const validationResult = validateRelationship(dataItem);
			if (validationResult)
			{
				if (dataItem.isNew)
				{
					engagementService
						.addNewRelationship(dataItem)
						.then((res: any) =>
						{
							setEditingRelationship(undefined);
							setEditingError(undefined);
							loadRelationships();
						})
						.catch((err: any) =>
						{
							handleApiErrorResponse(err);
						});
				}
				else
				{
					engagementService
						.updateRelationship(dataItem)
						.then((res: any) =>
						{
							const updatedRelationships = relationships
								.map((relationship: IRelationship) =>
								{
									if (relationship.relationshipId === dataItem.relationshipId)
									{
										const updatedOwnerInformation = relationships.find((r) => r.faId === dataItem.ownerAffiliateId);

										const ownerInfo: Record<string, string | number> = {
										};

										const updatedEntity = {
											...dataItem,
											inEdit: false,
											dirty: false
										};

										if (!!updatedOwnerInformation)
										{
											ownerInfo.ownerAffiliateId = updatedOwnerInformation.faId;
											ownerInfo.ownerAffiliateName = updatedOwnerInformation.faName;

											// update the already selected relationships with updated data
											if (!!selectedRelationships && !!selectedRelationships.length)
											{
												const existingSelectedRelationships = [
													...selectedRelationships
												];

												const selectedIndex = selectedRelationships
													.findIndex((sr) => sr.faId === relationship.faId);

												if (selectedIndex > -1)
												{
													existingSelectedRelationships[selectedIndex] = {
														...updatedEntity,
														...ownerInfo
													};

													setSelectedRelationships([...existingSelectedRelationships]);
												}
											}
										}

										return {
											...updatedEntity,
											...ownerInfo
										};
									}
									else
									{
										return {
											...relationship
										};
									}
								});
							setRelationships(updatedRelationships);
							setEditingRelationship(undefined);
							setEditingError(undefined);
							loadRelationships();
						})
						.catch((err: any) =>
						{
							handleApiErrorResponse(err);
						});
				}
			}
			else
			{
				// eslint-disable-next-line no-console
				console.log('validation result =', validationResult);
			}
			break;
		case ActionEnum.Cancel:
			if (dataItem.dirty)
			{
				validateRelationship(dataItem); //update the error message
				setShowUnsavedModal(true);
			}
			else
			{
				handleCancel();
			}

			break;
		case ActionEnum.Edit:
			setEditingError(undefined);
			const editingRelationships = relationships.map(
				(relationship: IRelationship) =>
				{
					if (relationship.relationshipId === dataItem.relationshipId)
					{
						return {
							...relationship,
							inEdit: true,
							dirty: false
						};
					}
					else
					{
						return {
							...relationship
						};
					}
				}
			);
			setRelationships(editingRelationships);
			setEditingRelationship(dataItem);
			break;

		case ActionEnum.Delete:

			if (!!dataItem && !!dataItem.relationshipId)
			{
				let primaryMessage = '';
				if (!!dataItem.changeReasonId)
				{
					switch (dataItem.changeReasonId)
					{
					case 1:
						primaryMessage = t('relationships.deleteModal.MergerDeleteMessage');
						break;
					case 2:
						primaryMessage = t('relationships.deleteModal.LiquidationDeleteMessage');
						break;
					case 3:
						primaryMessage = t('relationships.deleteModal.AcquisitionDeleteMessage');
						break;
					}
				}
				setDeletingRelationship({
					primaryMessage,
					...dataItem
				});
				setDisplayDeleteModal(true);
			}
			else
			{
				setDeletingRelationship(undefined);
			}
			break;
		}
	};

	const hasError = (error: any): boolean =>
	{
		for (const key of Object.keys(error))
		{
			if (typeof error[key] === 'string' && error[key] !== '')
			{
				return true;
			}
		}
		return false;
	};

	const rowRender = (
		row: React.ReactElement<HTMLTableRowElement>,
		props: GridRowProps
	) =>
	{
		if (!props.dataItem[RelationshipFieldEnum.primary])
		{
			const currentRelationshipId = props.dataItem[RelationshipFieldEnum.relationshipId];

			const isAvailable = newAffiliateRelationshipIds.items
				.find((x: number) => x === currentRelationshipId);

			const currentFaId = props.dataItem[RelationshipFieldEnum.faId];

			const expandStatus = relationships.find(
				(r: IRelationship) =>
					r.primary && r.expanded === false && r.faId === currentFaId
			);
			const {
				props: { className = '' } = {
				}
			} = row;
			if (expandStatus)
			{
				return <></>;
			}
			const nonPrimaryRowClass = `${className} nonPrimary`;
			if (!!isAvailable)
			{
				return React.cloneElement(row, {
					className: `${nonPrimaryRowClass} highlightRow`
				});
			}
			return React.cloneElement(row, {
				className: `${nonPrimaryRowClass}`
			});
		}
		return row;
	};

	const notificationElement = (
		<Notification message={t(`relationships.notificationMessage.${newAffiliateRelationshipIds.notificationType}`)} />
	);

	const handleNotificationClose = () =>
	{
		setNewAffiliateRelationshipIds({
			notificationType: NotificationType.Merger,
			items: []
		});
	};

	const addRow = () =>
	{
		const faId = relationships.find((r: IRelationship) => r.selected)?.faId;
		const dateBecameFa = relationships.find((r: IRelationship) => r.selected)?.dateBecameFa;
		relationships.reduce((formatted: any, item: any) =>
		{
			//updating expand status for selected and primary row
			if (item.selected && item.primary)
			{
				item.expanded = true;
			}
			formatted.push(item);
			return formatted;
		}, []);

		const faOwners = relationships.filter(
			(r: IRelationship) => r.faId === faId
		);

		const newRow: IRelationship = {
			relationshipId: Constants.ID_EMPTY,
			engagementId: props.engagementId ?? Constants.ID_EMPTY,
			faId: faId ?? Constants.ID_EMPTY,
			faName: '',
			dateBecameFa: dateFormatter.utcToEst(dateBecameFa),
			ownerAffiliateId: 0,
			ownerName: '',
			equityClassId: 0,
			equityClass: '',
			equityPercentage: undefined,
			currencyId: undefined,
			SEP: undefined,
			asOfDate: undefined,
			asOfTime: undefined,
			effectiveDate: undefined,
			changeReason: '',
			changeReasonId: 0,
			survivorAffiliateId: 0,
			survivorAffiliateName: '',
			notes: '',
			primary: false,
			selected: false,
			editable: true,
			inEdit: true,
			expanded: false,
			isNew: true,
			dirty: false,
			operatable: true
		};
		const index = Utils.findLastIndex(
			relationships,
			(r: IRelationship) => r.faId === faId
		);

		const newRows = faOwners?.length === 1 ?  //handle add new row as the first child. Replace the orginal one row with three rows
			[
				...relationships.slice(0, index),
				[{
					...faOwners[0],
					editable: false,
					notes: '',
					relationshipId: Utils.generateTempId()
				}, {
					...faOwners[0],
					primary: false
				}, newRow],
				...relationships.slice(index + 1)
			] :
			[
				...relationships.slice(0, index + 1),
				[newRow],
				...relationships.slice(index + 1)
			];
		setRelationships(newRows.flat());
		setEditingRelationship(newRow);
		setEditingError(undefined);
	};

	const closeUnsavedModal = (str: string) =>
	{
		if (str === 'stay')
		{
			setShowUnsavedModal(false);
		}
		else if (str === 'leave')
		{
			setShowUnsavedModal(false);
			handleCancel();
		}
	};

	const triggerDeleteSuccessNotification = async (): Promise<void> =>
	{
		const element = (
			<Notification
				message={t('relationships.deleteModal.deleteSuccessMessage')}
				status={'success'}
			/>
		);
		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: element
			});
	};

	const triggerDeleteErrorNotification = async (): Promise<void> =>
	{
		const element = (
			<Notification
				message={t('relationships.deleteModal.deleteError')}
				status={'error'}
			/>
		);
		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: element
			});
	};

	const onDelete = async (): Promise<void> =>
	{
		if (!!deletingRelationship)
		{
			try
			{
				await engagementService
					.deleteRelationship(
						deletingRelationship?.engagementId, deletingRelationship?.relationshipId
					);

				triggerDeleteSuccessNotification();
				loadRelationships();
			}
			catch (error)
			{
				triggerDeleteErrorNotification();
			}
			setDisplayDeleteModal(false);
		}
	};

	const handleCancel = () =>
	{
		if (editingRelationship)
		{
			if (editingRelationship.isNew && editingRelationship.primary === false)
			{
				const faOwners = relationships.filter(
					(relationship: IRelationship) =>
						relationship.faId === editingRelationship.faId
				);

				const updatedRelationships =
					faOwners.length === 3 ? //Handle UI for new row as the first child
						relationships.filter(
							(relationship: IRelationship) =>
								!(
									relationship.faId === editingRelationship.faId &&
									(relationship.isNew || relationship.primary)
								)
						) :
						relationships.filter(
							(relationship: IRelationship) => relationship.isNew === false
						);
				if (faOwners.length === 3)
				{
					//Update UI when the new row is the first child
					const r = updatedRelationships.find(
						(r: IRelationship) => r.faId === editingRelationship.faId
					);
					if (r)
					{
						r.primary = true;
						r.editable = true;
					}
				}
				setRelationships(updatedRelationships);
				setEditingRelationship(undefined);
				setEditingError(undefined);
			}
			else
			{
				const updatedRelationships = relationships.map(
					(relationship: IRelationship) =>
					{
						if (
							relationship.relationshipId ===
							editingRelationship.relationshipId
						)
						{
							return {
								...editingRelationship
							};
						}
						else
						{
							return {
								...relationship
							};
						}
					}
				);
				setRelationships(updatedRelationships);
				setEditingRelationship(undefined);
				setEditingError(undefined);
			}
		}
	};

	const closeUnexpectedErrorModal = () =>
	{
		setUnExpectedError('');
		setShowUnexpectedErrorModal(false);
	};

	const getLatestDate = () =>
	{
		if (selectedRelationships && selectedRelationships.length === 1)
		{
			const faId = selectedRelationships[0].faId;
			const ownerAffiliateId = selectedRelationships[0].ownerAffiliateId;
			let effectiveDate = undefined;

			const relationshipDates = filterRelationships.reduce((formatted: any, item: IRelationship) =>
			{
				//filtering all the effective dates where selectedFaId is either owner or owned by
				if ((item.faId === faId) || (item.ownerAffiliateId === faId && item.relationshipId > 0) ||
					(item.faId === ownerAffiliateId && item.relationshipId > 0))
				{
					effectiveDate = item?.effectiveDate;
					formatted.push(effectiveDate);
				}
				return formatted;
			}, []);

			const latestDate = new Date(relationshipDates.reduce((a: any, b: any) => (a > b ? a : b)));
			latestDate.setDate(latestDate.getDate() + 1);
			setDefaultDate(latestDate);
		}
	};


	const triggerErrorNotification = (message: string): void =>
	{
		NotificationService.error({
			message
		});
	};

	const formatDate = (date: any) =>
	{
		return `${!!date ?
			dateFormatter.format(dateFormatter.utcToEst(date), 'MMM DD, YYYY hh:mm A') : ''}`;
	};

	const createTooltip = (status: string, updatedOn: string) =>
	{
		let statusValue = '';
		switch (status)
		{
		case RelationshipValidationFields.NotProcessed:
			statusValue = t('relationships.notProcessedStatus');
			break;
		case RelationshipValidationFields.InProgress:
			statusValue = t('relationships.inProgressStatus');
			break;
		case RelationshipValidationFields.Completed:
			statusValue = t('relationships.completedStatus');
			break;
		case RelationshipValidationFields.Failed:
			statusValue = t('relationships.failedStatus');
			break;
		}
		const validationStatus = {
			'validationStatus': `${t('relationships.validation')} ${statusValue}`
		};
		const lastValidationOn = !!updatedOn ? {
			'lastValidationOn': `${t('relationships.lastValidationOn')} ${formatDate(updatedOn)}`
		} : {
		};
		return ({
			...validationStatus,
			...lastValidationOn
		});
	};

	const renderValidationTooltip = () =>
	{
		return (<>
			{!!valiadtionTooltip && Object.keys(valiadtionTooltip).map((item: any, index: number) =>
			{
				return <div key={index}> {valiadtionTooltip[item]}</div>;
			})}
		</>
		);
	};

	const validateRelationships = async () =>
	{
		if (!!props.engagementId)
		{
			setIsValidateDisabled(true);
			await engagementService
				.validateRelationshipsByEngagementId(props.engagementId)
				.then((res: any) =>
				{
					if (!!res && !!res.data && !!res.data.result)
					{
						checkValidationStatus(res.data.result);
					}
				})
				.catch((err: any) =>
				{
					triggerErrorNotification(
						t('relationships.validateRelationshipsError')
					);
				});
		}
	};

	return (
		<div className={`relationships ${!!props.fromEngagementDashboard ? 'dashboard' : 'draft-step'}`}>
			<div className={`view ${!!props.fromEngagementDashboard ? '' : 'draft-step'}`}>
				{!props.fromEngagementDashboard &&
					<div className="title">{t('relationships.relationships')}</div>
				}
				<div className={`grid-container ${!!props.fromEngagementDashboard ? '' : 'draft-step'}`}>
					{affiliateOwners.length > 0 && (
						<LocalizationProvider language="en-user">
							<IntlProvider locale="en">
								<Grid
									data={filterRelationships}
									editField={'inEdit'}
									selectable={{
										enabled: true,
										drag: false,
										cell: false,
										mode: 'single'
									}}
									rowRender={rowRender}
									onItemChange={itemChange}
									sortable={{
										allowUnsort: false
									}}
								>
									<GridToolbar>
										<div className={`header-tool-bar ${!!props.fromEngagementDashboard ? '' : 'draft-step'}`}>
											<div className="left">
												<div className="search">
													<Search
														searchType="secondary"
														placeholder={
															t('relationships.searchPlaceholder') || undefined
														}
														searchValue={searchText}
														onChange={(value) =>
														{
															onSearchTextChange(value);
														}}
														disabled={relationships.length === 0}
													/>
												</div>
											</div>

											<div className="right">
												<Tooltip
													trigger='hover'
													position='top-right'
													distance={0}
													id="tooltipDesc"
													content={t('relationships.instructionTooltip') || ''}
													style={{
														maxWidth: '300px'
													}}
												>
													<div className="flex-button margin-right  custom-command">
														<Button
															kind={!!props.fromEngagementDashboard ? 'secondary' : 'primary'}
															disabled={
																!allRelationshipDefined ||
																!enableMerger ||
																!singleSelected ||
																editingRelationship !== undefined
															}
															onClick={() =>
															{
																getLatestDate();
																setShowMergereModal(true);
															}}
														>
															{t('relationships.merger')}
														</Button>
													</div>
													<div className="flex-button margin-right  custom-command">
														<Button
															kind={!!props.fromEngagementDashboard ? 'secondary' : 'primary'}
															disabled={
																!allRelationshipDefined ||
																!enableMerger ||
																!singleSelected ||
																editingRelationship !== undefined
															}
															onClick={() =>
															{
																getLatestDate();
																setShowLiquidationModal(true);
															}}
														>
															{t('relationships.liquidation')}
														</Button>
													</div>
													<div className="flex-button margin-right">
														<Button
															kind={!!props.fromEngagementDashboard ? 'secondary' : 'primary'}
															disabled={
																!allRelationshipDefined ||
																!singleSelected ||
																editingRelationship ||
																editingRelationship !== undefined
															}
															onClick={() =>
															{
																setShowAcquisitionDispositionModal(true);
															}}
														>
															{t('relationships.acquisitionDisposition')}
														</Button>
													</div>
													<div className="flex-button">
														<Button
															icon="icon-plus-outline"
															kind="secondary"
															disabled={
																!allRelationshipDefined ||
																!singleSelected ||
																editingRelationship !== undefined
															}
															onClick={addRow}
														>
															{t('relationships.addRow')}
														</Button>
													</div>
												</Tooltip>
												{!!props.fromEngagementDashboard &&
													<Tooltip
														trigger='hover'
														position='top-right'
														distance={0}
														id="tooltipDesc"
														content={renderValidationTooltip}
														style={{
															maxWidth: '300px'
														}}
													>
														<div className="flex-button">
															<Button
																icon="icon-shield-check-outline"
																kind="secondary"
																disabled={isValidateDisabled}
																onClick={validateRelationships}
															>
																{t('relationships.validate')}
															</Button>
														</div>
													</Tooltip>}
											</div>
										</div>
										{!!props.fromEngagementDashboard && validationErrors.length > 0 &&
											<ValidationInfoElement />}
									</GridToolbar>
									<GridNoRecords></GridNoRecords>
									{!!props.fromEngagementDashboard && validationErrors.length > 0 && <GridColumn
										field={RelationshipFieldEnum.validationErrors}
										title={' '}
										width={'74px'}
										sortable={true}
										locked={true}
										cell={ValidationErrorsCell}
									/>}
									<GridColumn
										field="selected"
										width={'40px'}
										locked={true}
										headerCell={(props) => (
											<Checkbox
												disabled={editingRelationship !== undefined}
												checked={checkStatus === CheckStatusEnum.checked}
												indeterminate={
													checkStatus === CheckStatusEnum.indeterminate
												}
												onChange={checkAll}
											></Checkbox>
										)}
										cell={(params) =>
											RelationshipCellEditor({
												...params,
												affiliateOwners: affiliateOwners,
												error: editingError,
												editing: editingRelationship !== undefined,
												onAction: handleAction
											})
										}
									/>
									<GridColumn
										field={RelationshipFieldEnum.expanded}
										title={' '}
										width={'40px'}
										sortable={true}
										locked={true}
										headerCell={() => (
											filterRelationships?.length > 0 &&
											filterRelationships?.some((r: IRelationship) => r.relationshipId < 0) ?
												<Tooltip
													trigger='hover'
													position='top'
													distance={10}
													id="tooltipDesc"
													content={isAllExpanded ? (t('relationships.collapseAll') || '') :
														(t('relationships.expandAll') || '')}
													style={{
														maxWidth: '88px'
													}}
												>
													<div className='expand-icon'>
														{isAllExpanded ? (
															<span
																className="Appkit4-icon icon-up-chevron-outline"
																onClick={() => handleExpandAll(false)}
															></span>
														) : (
															<span
																className="Appkit4-icon icon-down-chevron-outline"
																onClick={() => handleExpandAll(true)}
															></span>
														)}
													</div>
												</Tooltip> : <></>
										)}
										cell={(params) =>
											RelationshipCellEditor({
												...params,
												affiliateOwners: affiliateOwners,
												error: editingError,
												editing: editingRelationship !== undefined,
												onAction: handleAction
											})
										}
									/>
									<GridColumn
										field={RelationshipFieldEnum.faName}
										title={t('relationships.faName') || ''}
										width={212}
										sortable={true}
										locked={true}
										cell={(params) =>
											RelationshipCellEditor({
												...params,
												affiliateOwners: affiliateOwners,
												error: editingError,
												editing: editingRelationship !== undefined,
												onAction: handleAction
											})
										}
									/>
									<GridColumn
										field={RelationshipFieldEnum.ownerAffiliateId}
										title={t('relationships.ownedBy') || ''}
										width={212}
										editable={false}
										sortable={true}
										cell={(params) =>
											RelationshipCellEditor({
												...params,
												affiliateOwners: affiliateOwners,
												error: editingError,
												editing: editingRelationship !== undefined,
												onAction: handleAction
											})
										}
									/>
									<GridColumn
										field={RelationshipFieldEnum.equityClassId}
										title={t('relationships.equityClass') || ''}
										width={200}
										editable={false}
										sortable={true}
										cell={(params) =>
											RelationshipCellEditor({
												...params,
												affiliateOwners: affiliateOwners,
												error: editingError,
												editing: editingRelationship !== undefined,
												onAction: handleAction
											})
										}
									/>
									<GridColumn
										field={RelationshipFieldEnum.equityPercentage}
										title={t('relationships.equityPercentage') || ''}
										width={100}
										editable={false}
										sortable={true}
										cell={(params) =>
											RelationshipCellEditor({
												...params,
												affiliateOwners: affiliateOwners,
												error: editingError,
												editing: editingRelationship !== undefined,
												onAction: handleAction
											})
										}
									/>
									<GridColumn
										field={RelationshipFieldEnum.SEP}
										title={t('relationships.sep') || ''}
										width={100}
										editable={false}
										sortable={true}
										headerClassName={'FAgrid-header-cell1'}
										headerCell={(props) => (
											<span className="k-cell-inner">
												<span
													className="k-link !k-cursor-default"
													style={{
														display: 'flex',
														justifyContent: 'flex-start'
													}}
												>
													<span className="k-column-title"> {t('relationships.sep') || ''}</span>
													<Tooltip
														trigger="hover"
														distance={5}
														position={'top'}
														appendAfterTarget
														content={t('relationships.sepUserAid') || ''}
													>
														<span className={'Appkit4-icon icon-help-question-outline'} />
													</Tooltip>
												</span>
											</span>
										)}
										cell={(params) =>
											RelationshipCellEditor({
												...params,
												affiliateOwners: affiliateOwners,
												error: editingError,
												editing: editingRelationship !== undefined,
												onAction: handleAction
											})
										}
									/>
									<GridColumn
										field={RelationshipFieldEnum.asOfDate}
										title={t('relationships.asOfDate') || ''}
										width={150}
										editable={false}
										sortable={true}
										filter="date"
										format="{0: MMM DD, YYYY}"
										cell={(params) =>
											RelationshipCellEditor({
												...params,
												affiliateOwners: affiliateOwners,
												error: editingError,
												editing: editingRelationship !== undefined,
												onAction: handleAction,
												maxDate: lastCalcYear
											})
										}
									/>
									<GridColumn
										field={RelationshipFieldEnum.asOfTime}
										title={t('relationships.asOfTime') || ''}
										width={150}
										editable={false}
										sortable={true}
										cell={(params) =>
											RelationshipCellEditor({
												...params,
												affiliateOwners: affiliateOwners,
												error: editingError,
												editing: editingRelationship !== undefined,
												onAction: handleAction
											})
										}
									/>
									<GridColumn
										field={RelationshipFieldEnum.changeReason}
										title={t('relationships.reasonForChange') || ''}
										width={180}
										editable={false}
										sortable={true}
										cell={(params) =>
											RelationshipCellEditor({
												...params,
												affiliateOwners: affiliateOwners,
												error: editingError,
												editing: editingRelationship !== undefined,
												onAction: handleAction
											})
										}
									/>
									<GridColumn
										field={RelationshipFieldEnum.survivorAffiliateName}
										title={t('relationships.survivorOfMerger') || ''}
										width={212}
										editable={false}
										sortable={true}
										cell={(params) =>
											RelationshipCellEditor({
												...params,
												affiliateOwners: affiliateOwners,
												error: editingError,
												editing: editingRelationship !== undefined,
												onAction: handleAction
											})
										}
									/>
									<GridColumn
										field={RelationshipFieldEnum.notes}
										title={t('relationships.notes') || ''}
										width={212}
										editable={false}
										sortable={true}
										cell={(params) =>
											RelationshipCellEditor({
												...params,
												affiliateOwners: affiliateOwners,
												error: editingError,
												editing: editingRelationship !== undefined,
												onAction: handleAction
											})
										}
									/>
								</Grid>
							</IntlProvider>
						</LocalizationProvider>
					)}
				</div>
			</div>
			<UnsavedChangesModal
				visible={showUnsavedModal}
				onClose={closeUnsavedModal}
				title={t('unsaved-popup.discardChangesTitle') || ''}
			/>
			<UnexpectedErrorModal
				visible={showUnexpectedErrorModal}
				onClose={closeUnexpectedErrorModal}
				message={t('unexpected-error-popup.disposition')}
				error={unexpectedError}
			/>
			<MergerModal
				visible={showMergerModal}
				engagementId={props.engagementId}
				onClose={(
					val: boolean,
					newAffiliateRelationshipIds?: number[]
				) =>
				{
					setShowMergereModal(val);
					if (!!newAffiliateRelationshipIds)
					{
						setNewAffiliateRelationshipIds({
							notificationType: NotificationType.Merger,
							items: newAffiliateRelationshipIds
						});
					}
				}}
				mergingEntity={selectedRelationships[0]}
				defaultDate={defaultDate}
				maxDate={lastCalcYear}
			/>
			{
				!!showAcquisitionDispositionModal &&
				!!affiliateOwners &&
				!!affiliateOwners.length &&
				!!selectedRelationships &&
				!!selectedRelationships.length &&
				selectedRelationships.length === 1 &&
				<AcquisitionDispositionModal
					visible={showAcquisitionDispositionModal}
					engagementId={props.engagementId}
					selectedRelationship={selectedRelationships[0]}
					allRelationships={relationships}
					affiliateOwners={rawAffiliateOwners}
					affiliate={engagementAffiliates.find((f: IForeignAffiliate) => f.affiliateId === selectedRelationships[0].faId)!}
					lastCalcYear={lastCalcYear}
					onUpdateVisibility={(visible: boolean) =>
					{
						setShowAcquisitionDispositionModal(visible);
					}}
					onSuccess={(updatedFARelationshipIds) =>
					{
						if (!!updatedFARelationshipIds)
						{
							setNewAffiliateRelationshipIds({
								notificationType: NotificationType.Acquisition,
								items: updatedFARelationshipIds
							});
						}

						setShowAcquisitionDispositionModal(false);
					}}
				/>
			}
			<LiquidationModal
				visible={showLiquidataionModal}
				engagementId={props.engagementId!}
				onClose={(
					newAffiliateRelationshipIds?: number[]
				) =>
				{
					setShowLiquidationModal(false);
					if (!!newAffiliateRelationshipIds)
					{
						setNewAffiliateRelationshipIds({
							notificationType: NotificationType.Liquidation,
							items: newAffiliateRelationshipIds
						});
					}
				}}
				selectedItem={selectedRelationships[0]}
				defaultDate={defaultDate}
				maxDate={lastCalcYear}
			/>
			<DeleteModal
				visible={displayDeleteModal}
				title={t('relationships.deleteModal.deleteModalTitle')}
				primaryMessage={deletingRelationship?.primaryMessage}
				deleteMessage={t('relationships.deleteModal.deleteMessage')}
				showWarningIcon={true}
				setVisible={setDisplayDeleteModal}
				onDelete={onDelete}
			/>
		</div>
	);
};
