export enum ForeignAccurualProertyIncomeFields {
	revenues = 'revenues',
	incomes = 'incomes',
	expenses = 'expenses',
	adjustments = 'adjustments',
	totalRevenue = 'totalRevenue',
	totalExpense = 'totalExpense',
	totalAdjustment = 'totalAdjustment',
	computedNetFAPIOrFAPL = 'computedNetFAPIOrFAPL',
	nonExcludedPropertyCapitalGain = 'nonExcludedPropertyCapitalGain',
	taxesAllocatedToFAPI = 'taxesAllocatedToFAPI',
	netFAPIOrFAPLAfterTaxes = 'netFAPIOrFAPLAfterTaxes',
	netFACL = 'netFACL'
}