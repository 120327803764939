import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { DividendsPaidHelper } from './DividendsPaidHelper';
import { ColumnarGrid } from 'src/common/components/ColumnarGrid/ColumnarGrid';
import { ILineItem } from 'src/common/types/interfaces/ILineItem';
import { DividendsPaidForm } from './components/DividendsPaidForm/DividendsPaidForm';
import { DividendPaid, IDividendPaid } from 'src/common/types/interfaces/IDividendsPaid';
import { LineItemCategories } from 'src/common/types/enums/LineItemCategories';
import { TaxYearEndFilter } from '../../../../../common/components/TaxYearEndFilter/TaxYearEndFilter';
import { inputRecordHelper } from 'src/common/utils/inputRecordHelper';
import { ForeignAffiliate, IForeignAffiliate } from 'src/features/engagement/models/IForeignAffiliate';
import { EngagementDetail, IEngagementDetail } from 'src/features/engagement/models/IEngagementDetail';
import { NotificationService } from 'src/core/services/notificationService';
import { IFAHistoryBase } from 'src/features/engagement/models/IFAHistory';
import { Utils } from 'src/common/utils/utils';
import cloneDeep from 'lodash/cloneDeep';

import './DividendsPaid.scss';
import AffiliateContext from 'src/common/components/SharedDataContext/AffiliateContext';

export function DividendsPaid(): JSX.Element
{
	const { t } = useTranslation(
		'input',
		{
			keyPrefix: 'dividendsPaid'
		}
	);

	const { t: fieldsTranslationService } = useTranslation(
		'input',
		{
			keyPrefix: 'dividendsPaidFieldNames'
		}
	);

	const affiliateContext = useContext(AffiliateContext);
	const [lineConfiguration, setLineConfiguration] = useState<ILineItem[]>([]);

	const [rowInformation, setRowInformation] = useState(DividendsPaidHelper.getRowInformation(fieldsTranslationService));

	const [engagementInfo, setEngagementInfo] = useState<IEngagementDetail | undefined>(undefined);
	const [foreignAffiliate, setForeignAffiliate] = useState<IForeignAffiliate | undefined>(undefined);
	const [faHistory, setFAHistory] = useState<IFAHistoryBase[]>([]);

	const [dividendRecords, setDividendRecords] = useState(
		inputRecordHelper
			.getStartRowKeysAsDictionary<IDividendPaid>(
				DividendsPaidHelper.getRowInformation(fieldsTranslationService)
			)
	);

	const [isLoading, setIsLoading] = useState(true);

	const [toDate, setToDate] = useState<Date | undefined>(undefined);
	const [fromDate, setFromDate] = useState<Date | undefined>(undefined);

	const [selectedRecord, setSelectedRecord] = useState<IDividendPaid | undefined>(undefined);
	const [displayModal, setDisplayModal] = useState(false);
	const [isAddRecord, setIsAddRecord] = useState(false);

	const onOpenPanel = (isAdd: boolean, entity: IDividendPaid): void =>
	{
		setIsAddRecord(!!isAdd);
		setSelectedRecord(entity);
		setDisplayModal(true);
	};

	const loadDividendData = async (
		{
			engagementId,
			affiliateId,
			fromDate,
			toDate
		}:
		{
			engagementId: number;
			affiliateId: number;
			fromDate?: Date;
			toDate?: Date;
		}
	): Promise<void> =>
	{
		try
		{
			const response = await engagementService
				.getDividendsPaidInput({
					engagementId,
					affiliateId,
					fromDate,
					toDate
				});

			if (
				!!response &&
				!!response.data &&
				!!response.data.result
			)
			{
				const allRecords: IDividendPaid[] = response.data.result
					.map(DividendPaid.populateFields);

				setDividendRecords({
					...cloneDeep(
						inputRecordHelper
							.getRowData(
								allRecords,
								rowInformation
							)
					)
				});
			}
		}
		catch (error)
		{
			triggerErrorNotification(
				t('dividendInformationError')
			);
		}
		finally
		{
			await Utils.timeout(1000);
			setIsLoading(false);
		}
	};

	const loadForeignAffiliateInformation = async (
		engagementId: number,
		affiliateId: number
	): Promise<void> =>
	{
		try
		{
			const result = await engagementService
				.getForeignAffiliate(
					engagementId,
					affiliateId
				);

			if (
				!!result &&
				!!result.data &&
				!!result.data.result
			)
			{
				setForeignAffiliate(
					ForeignAffiliate.parseFields(result.data.result)
				);
			}
		}
		catch (error)
		{
			triggerErrorNotification(
				t('foreignAffiliateInformationError')
			);
		}
	};

	const loadEngagementInformation = async (
		engagementId: number
	): Promise<void> =>
	{
		try
		{
			const result = await engagementService
				.getEngagementDetailById(
					engagementId
				);

			if (
				!!result &&
				!!result.data &&
				!!result.data.result
			)
			{
				setEngagementInfo(
					EngagementDetail.parseFields(result.data.result)
				);
			}
		}
		catch (error)
		{
			triggerErrorNotification(
				t('engagementInformationError')
			);
		}
	};

	const loadFAHistory = async (
		engagementId: number,
		affiliateId: number
	): Promise<void> =>
	{
		try
		{
			const response = await engagementService
				.getFAHistory(
					engagementId,
					affiliateId
				);

			if (
				!!response &&
				!!response.data &&
				!!response.data.result
			)
			{
				setFAHistory([
					...response.data.result
				]);
			}
			else
			{
				setFAHistory([]);
			}
		}
		catch (error)
		{
			triggerErrorNotification(
				t('faHistoryError')
			);
		}
	};

	const loadRowInformation = async (
		engagementId: number,
		affiliateId: number
	): Promise<void> =>
	{
		try
		{
			const lines = await inputRecordHelper
				.initializeLineItems(
					LineItemCategories.DividendsPaid,
					engagementId,
					affiliateId
				);

			setRowInformation(
				DividendsPaidHelper.getRowInformation(
					fieldsTranslationService,
					lines
				)
			);

			setLineConfiguration(lines);
		}
		catch (error)
		{
			triggerErrorNotification(
				t('rowInformationError')
			);
		}
	};

	const onFilter = (
		{
			fromDate,
			toDate
		}:
		{
			fromDate?: Date;
			toDate?: Date;
		}
	): void =>
	{
		setIsLoading(true);

		setFromDate(fromDate);
		setToDate(toDate);

		loadDividendData({
			engagementId: affiliateContext?.engagementDetail.id!,
			affiliateId: affiliateContext?.affiliateDetail.affiliateId!,
			fromDate,
			toDate
		});
	};

	const onRefresh = async (): Promise<void> =>
	{
		setIsLoading(true);

		loadDividendData({
			engagementId: affiliateContext?.engagementDetail.id!,
			affiliateId: affiliateContext?.affiliateDetail.affiliateId!,
			fromDate,
			toDate
		});
	};

	const onRenderFilters = (): JSX.Element =>
	{
		return <>
			<TaxYearEndFilter
				onlyRelevantResults
				isDisabled={
					isLoading ||
					(
						!Object.values(dividendRecords).some((d) => !!d.length) &&
						fromDate === undefined &&
						toDate === undefined
					)
				}
				faHistory={faHistory}
				onFilter={onFilter}
			/>
		</>;
	};

	const triggerErrorNotification = (message: string): void =>
	{
		NotificationService.error({
			message
		});
	};

	useEffect(
		() =>
		{
			if (
				!!affiliateContext &&
				!!affiliateContext?.engagementDetail.id&&
				!!affiliateContext?.affiliateDetail.affiliateId
			)
			{
				loadFAHistory(
					affiliateContext?.engagementDetail.id!,
					affiliateContext?.affiliateDetail.affiliateId!
				);

				loadEngagementInformation(
					affiliateContext?.engagementDetail.id!
				);

				loadForeignAffiliateInformation(
					affiliateContext?.engagementDetail.id!,
					affiliateContext?.affiliateDetail.affiliateId!
				);

				loadRowInformation(
					affiliateContext?.engagementDetail.id!,
					affiliateContext?.affiliateDetail.affiliateId!
				);
			}
		},
		[affiliateContext]
	);

	useEffect(
		() =>
		{
			setIsLoading(true);

			if (
				!!lineConfiguration &&
				!!lineConfiguration.length &&
				!!affiliateContext &&
				!!affiliateContext?.engagementDetail.id&&
				!!affiliateContext?.affiliateDetail.affiliateId
			)
			{
				loadDividendData({
					engagementId: affiliateContext?.engagementDetail.id!,
					affiliateId: affiliateContext?.affiliateDetail.affiliateId!
				});
			}
		},
		[
			affiliateContext,
			lineConfiguration
		]
	);

	return <>
		<div
			className={'dividends-paid-root'}
		>
			<ColumnarGrid
				title={t('gridTitle')}
				columnIndexKeyName={t('columnIndexKeyName')}
				isLoading={isLoading}
				isDisabled={false}
				rowInformation={rowInformation}
				data={
					!!dividendRecords ?
						dividendRecords :
						{
						}
				}
				addNewButtonText={t('addNewButtonText')}
				addNewMessage={t('addNewMessage')}
				onRenderFilters={onRenderFilters}
				onOpenNew={() =>
				{
					onOpenPanel(
						true,
						new DividendPaid(
							affiliateContext?.engagementDetail.id!,
							affiliateContext?.affiliateDetail.affiliateId!,
							!!foreignAffiliate ?
								foreignAffiliate.currencyId :
								undefined
						)
					);
				}}
				onEdit={(entity) =>
				{
					onOpenPanel(
						false,
						entity
					);
				}}
			/>
		</div>
		<DividendsPaidForm
			isAdd={isAddRecord}
			displayModal={displayModal}
			record={selectedRecord}
			engagementInfo={engagementInfo}
			foreignAffiliate={foreignAffiliate}
			lineConfiguration={lineConfiguration}
			onRefresh={onRefresh}
			onReset={() =>
			{
				setSelectedRecord(undefined);
			}}
			onToggleDisplayModal={(display) =>
			{
				setDisplayModal(!!display);
			}}
		/>
	</>;
}