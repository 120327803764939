import { dateFormatter } from 'src/common/utils/dateFormatter';
import { Utils } from 'src/common/utils/utils';

export interface ITaxPayerReportRecord {

	id: number;
	affiliateTaxYearEndCountryId: number;
	affiliateName: string;
	affiliateTaxYearEndCurrencyId: number;
	affiliateTaxYearEnd: Date;
	exemptSurplusBalance?: number;
	hybridSurplusBalance?: number;
	hybridUnderlyingTaxBalance?: number;
	taxableSurplusBalance?: number;
	underlyingForeignTaxBalance?: number;
	foreignExchangeRate?: number;
	taxpayerSEP?: number;
	taxpayerTaxYearEnd?: Date;
}

export class TaxPayerReportRecord implements ITaxPayerReportRecord
{
	constructor (taxYearEnd: Date)
	{
		this.id = Utils.generateTempId();
		this.affiliateTaxYearEndCountryId = 0;
		this.affiliateName = '';
		this.affiliateTaxYearEndCurrencyId = 0;
		this.affiliateTaxYearEnd = taxYearEnd;
	}

	id: number;
	affiliateTaxYearEndCountryId: number;
	affiliateName: string;
	affiliateTaxYearEndCurrencyId: number;
	affiliateTaxYearEnd: Date;
	exemptSurplusBalance?: number;
	hybridSurplusBalance?: number;
	hybridUnderlyingTaxBalance?: number;
	taxableSurplusBalance?: number;
	underlyingForeignTaxBalance?: number;
	foreignExchangeRate?: number;
	taxpayerSEP?: number;
	taxpayerTaxYearEnd?: Date;

	static toEntity = (record: any): ITaxPayerReportRecord =>
	{
		return {
			...record,
			affiliateTaxYearEnd: dateFormatter.utcToEst(record.affiliateTaxYearEnd)!,
			taxpayerTaxYearEnd: dateFormatter.utcToEst(record.taxpayerTaxYearEnd)!
		};
	};
}