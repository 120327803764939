export enum DividendPaidFields {

	id = 'id',
	engagementId = 'engagementId',
	affiliateId = 'affiliateId',
	effectiveDate = 'effectiveDate',
	date = 'date',
	time = 'time',
	currencyId = 'currencyId',
	amount = 'amount',
	paidOnEquityClassId = 'paidOnEquityClassId',
	underlyingForeignTax = 'underlyingForeignTax',
	isSurplusElection = 'isSurplusElection',
	isCapitalElection = 'isCapitalElection',
	notes = 'notes',
}