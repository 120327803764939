import { ReportUtil } from 'src/features/engagement/utils/ReportUtil';
import { useTranslation } from 'react-i18next';
import { EngagementUtil } from 'src/features/engagement/utils/EngagementUtil';
import { ReportFieldEnum } from 'src/common/types/enums/ReportFieldEnum';
import  ReportCell  from 'src/features/engagement/components/Report/ReportCells/ReportCell';

const SurplusReportSection = (props: {index: number, reportFields: any, reportData: any, rowSeperator: Function,
	data: any, i18nKey: string, columnsCount: number, noTransactionSection?: Function}) =>
{
	const { t } = useTranslation(
		'report',
		{
			keyPrefix: props.i18nKey
		}
	);
	const sortByField = (a: any, b: any, field: string) =>
	{
		if (a[field] < b[field])
		{
			return -1;
		}
		if (a[field] > b[field])
		{
			return 1;
		}
		 return 0;
	};

	const renderTransactions =(transactions: any, field: any) =>
	{
		return (
			<>
				{transactions.map((t: any) =>
				{
					return (
						<tr className={`${ReportUtil.getRowClassName(field)}`}>
							{field.transactions.map((column: any) =>
							{
								return (<td className={`${ReportUtil.getRowClassName(column)}`}>
									<ReportCell data={t} field={column}/></td>);
							})}
						</tr>
					);
				})}
			</>);
	};

	const SurplusReportSectionByTaxYear = (data: any, index: number, reportData: any, report: any,
		renderRowSeperator: Function, columnsCount: number, renderNoTransactionSection?: Function ) =>
	{
		return (
			<>
				{report.map((field: any) =>
				{
					switch (field.type)
					{
					case ReportFieldEnum.ColumnHeader:
						return (
							<tr className={`${ReportUtil.getRowClassName(field)}`}>
								{field.transactions.map((column: any, index: number) =>
								{
									if (index >= 2)
									{
										const currency = EngagementUtil.getCurrencyCode(data[ReportFieldEnum.CalculatingCurrencyId]);
										return (<td className={`${ReportUtil.getRowClassName(column)}`}>
											{t(column.name) + ' (' + currency + ')'}
										</td>);
									}
									else
									{
										return <td className={`${ReportUtil.getRowClassName(column)}`}>
											{`${!!column.name ? t(column.name)  : ''}`}
										</td>;
									}
								}
								)}
							</tr>
						);

					case ReportFieldEnum.Transactions:

						const hasTransactions: boolean = !!data.transactions && data.transactions.length > 0 ;
						const hasYearEndTransactions: boolean = !!data.yearEndTransactions && data.yearEndTransactions.length > 0 ;

						const transactions = hasTransactions ? data.transactions.filter((t: any) => !!t[ReportFieldEnum.Date])
							.sort((a: any,b: any) => sortByField(a, b, ReportFieldEnum.Date)) : [];

						const noDatesTransactions = hasTransactions ?
							data.transactions.filter((t: any) => !t[ReportFieldEnum.Date]) : [];

						const yearEndTransactions = hasYearEndTransactions ? data.yearEndTransactions : [];

						return (<>
							{( hasTransactions || hasYearEndTransactions) ?
								<>
									{(!!transactions || !!noDatesTransactions) &&
									renderTransactions([...transactions, ...noDatesTransactions], field)}
									{renderRowSeperator()}
									{!!yearEndTransactions && renderTransactions(yearEndTransactions, field)}
								</> :
								<>{!!renderNoTransactionSection && renderNoTransactionSection(data)}</>
							}
						</>);

					case ReportFieldEnum.Title:
						return (<tr className={`${ReportUtil.getRowClassName(field)}`}>
							<td>
								{ReportUtil.hasYearEndDuplicatedDate(data, reportData)?
									<ReportCell data={data} field={{
										...field,
										type: ReportFieldEnum.DateWithTime
									}} />:
									<ReportCell data={data} field={{
										...field,
										type: ReportFieldEnum.Date
									}} />}
							</td>
							{
								Array.from({
									length: columnsCount-1
								}, () =>
								{
									return ( <td></td>);
								}
								)
							}
						</tr>);

					case ReportFieldEnum.Subtitle:
						return (
							<tr className={`${ReportUtil.getRowClassName(field)}`}>
								{field.transactions.map((column: any, index: number) =>
								{
									if (index === 0)
									{
										return <td className={`${ReportUtil.getRowClassName(column)}`}>
											{`${!!column.name ? t(column.name)  : ''}`}
										</td>;
									}
									else
									{
										return (<td className={`${ReportUtil.getRowClassName(column)}`}>
											<ReportCell data={data} field={column}/></td>);
									}
								}
								)}
							</tr>
						);

					default:
						return (
							<td></td>
						);
					}
				})}
				{!!(index+1) && !!reportData && index !== reportData.length-1 && <>{renderRowSeperator()}</>}
			</>
		);
	};

	return (
		<>
			{SurplusReportSectionByTaxYear(
				props.data,
				props.index,
				props.reportData,
				props.reportFields,
				props.rowSeperator,
				props.columnsCount,
				props.noTransactionSection)}
		</>
	);
};

export default SurplusReportSection;