import { useState } from 'react';
import { NotesEditor } from '../../NotesEditor/NotesEditor';
import { NumericInputControl } from '../NumericInputControl';
import { INumericInputWithNotesAndActionControlProps } from './INumericInputWithNotesAndActionControlProps';
import { Utils } from 'src/common/utils/utils';

export function NumericInputWithNotesAndActionControl(props: INumericInputWithNotesAndActionControlProps): JSX.Element
{
	// used in order to force update child component into retaining focus after modal has taken focus away
	const [forceFocusIndex, setForceFocusIndex] = useState<number | undefined>(undefined);

	const onNotesClose = (): void =>
	{
		setForceFocusIndex(Utils.generateTempId());
	};

	const onRenderNotesEditor = (): JSX.Element | undefined =>
	{
		return <>
			{!!props.displayNotes && <NotesEditor
				isDisabled={!!props.isDisabled}
				notes={props.notes}
				onSave={props.onNotesSave}
				onClose={onNotesClose}
			/>}
			{
				!!props.value && !!props.actionComponent && {
					...props.actionComponent,
					onNumericInputActionClose: {
						onNotesClose
					}
				}
			}
		</>;
	};

	return <>
		<div>
			<NumericInputControl
				id={props.id}
				isRequired={!!props.isRequired}
				isDisabled={!!props.isDisabled}
				isReadonly={!!props.isReadonly}
				regex={props.regex}
				maxLength={props.maxLength}
				placeholder={props.placeholder}
				value={props.value}
				forceFocusIndex={forceFocusIndex}
				onRenderPrefixTemplate={onRenderNotesEditor}
				onBlur={props.onBlur}
				onValueChange={props.onValueChange}
				isError={props.isError}
				errorMessage={props.errorMessage}
				onRenderErrorMessage={props.onRenderErrorMessage}
			/>
		</div>
	</>;
}