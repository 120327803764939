import { Layout } from '../Layout/Layout';
import { Button } from '@appkit4/react-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './ResourceNotFound.scss';

export const ResourceNotFound = () =>
{
	const { t } = useTranslation(
		'common',
		{
			keyPrefix: 'resourceNotFound'
		}
	);

	const navigate = useNavigate();
	return (
		<Layout>
			<div className='resource-not-found'>
				<div className='container'>
					<div className='title'>{t('title') || ''}</div>
					<div className='message'>{t('messagePart1') || ''}</div>
					<div className='message navigate'>{t('messagePart2') || ''}</div>
					<div className='go-to-home'>
						<Button kind="primary" onClick={() => navigate('/')}>
							{t('goToHome') || ''}
						</Button>
					</div>
				</div>
			</div>
		</Layout>
	);
};
