const EarningsDetailsyReportFields = {
	'header': [
		{
			'name': 'status',
			'type': 'status',
			'background': 'section-top-border'
		},
		{
			'name': 'taxYearEnd',
			'type': 'taxYearEnd'
		},
		{
			'name': 'countryId',
			'type': 'countryId'
		},
		{
			'name': 'calculatingCurrencyId',
			'type': 'currencyId'
		}
	],
	'retainedEarnings': [
		{
			'name': 'retainedEarnings.sectionTitle',
			'type': 'title'
		},
		{
			'name': 'retainedEarnings.openingRetainedEarnings',
			'type': 'amountWithNotes',
			'background': 'highlight'
		},
		{
			'name': 'retainedEarnings.netIncomeAfterTax',
			'type': 'amountWithNotes',
			'background': 'left-indented'
		},
		{
			'name': 'retainedEarningsRecords',
			'type': 'descriptionList'
		},
		{
			'name': 'retainedEarnings.closingRetainedEarnings',
			'type': 'amountWithNotes',
			'background': 'highlight'
		},
		{
			'name': 'retainedEarnings.retainedEarningsUnreconciledDifference',
			'type': 'amountWithNotes'
		},
		{
			'name': 'retainedEarnings.closingRetainedEarningsPerFinancialStatements',
			'type': 'amountWithNotes'
		},
		{
			'name': '',
			'type': 'separator fixed'
		},
		{
			'name': 'retainedEarnings.netIncomeAfterTax',
			'type': 'amountWithNotes'
		},
		{
			'name': 'netIncomesRecords',
			'type': 'descriptionList'
		},
		{
			'name': 'retainedEarnings.netIncomeBeforeTax',
			'type': 'amountWithNotes',
			'background': 'highlight'
		},
		{
			'name': 'retainedEarnings.netIncomeUnreconciledDifference',
			'type': 'amountWithNotes'
		},
		{
			'name': 'retainedEarnings.netIncomeFromTaxReturn',
			'type': 'amountWithNotes'
		},
		{
			'name': '',
			'type': 'separator fixed'
		}
	],
	'foreignTaxableIncome': [
		{
			'name': 'foreignTaxableIncome.sectionTitle',
			'type': 'title'
		},
		{
			'name': 'foreignTaxableIncome.bookNetIncome',
			'type': 'amountWithNotes'
		},
		{
			'name': '',
			'type': 'separator fixed'
		},
		{
			'name': 'foreignTaxableIncome.permanentDifferencesSubTitle',
			'type': 'subtitle'
		},
		{
			'name': 'permanentDifferences',
			'type': 'descriptionList'
		},
		{
			'name': '',
			'type': 'separator fixed'
		},
		{
			'name': 'foreignTaxableIncome.temporaryDifferencesSubTitle',
			'type': 'subtitle'
		},
		{
			'name': 'temporaryDifferences',
			'type': 'descriptionList'
		},
		{
			'name': '',
			'type': 'separator fixed'
		},
		{
			'name': 'foreignTaxableIncome.unallocatedAdjustments',
			'type': 'amountWithNotes'
		},
		{
			'name': 'foreignTaxableIncome.totalBookToTaxAdjustments',
			'type': 'amountWithNotes'
		},
		{
			'name': 'foreignTaxableIncome.taxableIncomePerReturn',
			'type': 'amountWithNotes',
			'background': 'highlight'
		},
		{
			'name': '',
			'type': 'separator fixed'
		},
		{
			'name': 'foreignTaxableIncome.otherAdjustmentsSubTitle',
			'type': 'subtitle'
		},
		{
			'name': 'otherAdjustments',
			'type': 'descriptionList'
		},
		{
			'name': '',
			'type': 'separator fixed'
		},
		{
			'name': 'foreignTaxableIncome.taxableIncomeAfterAdjustments',
			'type': 'amountWithNotes',
			'background': 'highlight'
		},
		{
			'name': '',
			'type': 'separator fixed'
		}
	],
	'reg5907Adjustments': [
		{
			'name': 'reg5907Adjustments.sectionTitle',
			'type': 'title'
		},
		{
			'name': 'reg5907Adjustments.allTaxpayersSubTitle',
			'type': 'subtitle'
		},
		{
			'name': 'allTaxpayers',
			'type': 'descriptionList'
		},
		{
			'name': 'reg5907Adjustments.allTaxpayersSubTotal',
			'type': 'amountWithNotes',
			'background': 'left-indented'
		},
		{
			'name': '',
			'type': 'separator fixed'
		},
		{
			'name': 'reg5907Adjustments.reportingTaxpayerSubTitle',
			'type': 'subtitle'
		},
		{
			'name': 'reportingTaxpayer',
			'type': 'descriptionList'
		},
		{
			'name': 'reg5907Adjustments.reportingTaxpayerSubTotal',
			'type': 'amountWithNotes',
			'background': 'left-indented'
		},
		{
			'name': '',
			'type': 'separator fixed'
		},
		{
			'name': 'reg5907Adjustments.totalReg5907Adjustments',
			'type': 'amountWithNotes',
			'background': 'highlight'
		},
		{
			'name': '',
			'type': 'separator fixed'
		}
	],
	'activeBusinessIncome': [
		{
			'name': 'activeBusinessIncome.sectionTitle',
			'type': 'title'
		},
		{
			'name': 'activeBusinessIncome.bookNetIncome',
			'type': 'amountWithNotes'
		},
		{
			'name': '',
			'type': 'separator fixed'
		},
		{
			'name': 'activeBusinessIncome.permanentDifferencesSubTitle',
			'type': 'subtitle'
		},
		{
			'name': 'permanentDifferences',
			'type': 'descriptionList'
		},
		{
			'name': '',
			'type': 'separator fixed'
		},
		{
			'name': 'activeBusinessIncome.temporaryDifferencesSubTitle',
			'type': 'subtitle'
		},
		{
			'name': 'temporaryDifferences',
			'type': 'descriptionList'
		},
		{
			'name': '',
			'type': 'separator fixed'
		},
		{
			'name': 'activeBusinessIncome.totalAdjustments',
			'type': 'amountWithNotes'
		},
		{
			'name': 'activeBusinessIncome.computedABIUnderCanadianRules',
			'type': 'amountWithNotes',
			'background': 'highlight'
		},
		{
			'name': '',
			'type': 'separator fixed'
		}
	],
	'deemedABIPropertyIncome': [
		{
			'name': 'deemedABIPropertyIncome.sectionTitle',
			'type': 'title'
		},
		{
			'name': 'deemedABIPropertyIncome.incomesSubTitle',
			'type': 'subtitle'
		},
		{
			'name': 'incomes',
			'type': 'descriptionList'
		},
		{
			'name': '',
			'type': 'separator fixed'
		},
		{
			'name': 'deemedABIPropertyIncome.expensesSubTitle',
			'type': 'subtitle'
		},
		{
			'name': 'expenses',
			'type': 'descriptionList'
		},
		{
			'name': '',
			'type': 'separator fixed'
		},
		{
			'name': 'deemedABIPropertyIncome.adjustmentsSubTitle',
			'type': 'subtitle'
		},
		{
			'name': 'adjustments',
			'type': 'descriptionList'
		},
		{
			'name': '',
			'type': 'separator fixed'
		},
		{
			'name': 'deemedABIPropertyIncome.totalDeemedABIIncome',
			'type': 'amountWithNotes',
			'background': 'highlight'
		},
		{
			'name': '',
			'type': 'separator fixed'
		}
	],
	'abiPropertyIncome': [
		{
			'name': 'abiPropertyIncome.sectionTitle',
			'type': 'title',
			'tooltip': 'abiPropertyIncome.abiTooltip'
		},
		{
			'name': 'abiPropertyIncome.incomesSubTitle',
			'type': 'subtitle'
		},
		{
			'name': 'incomes',
			'type': 'descriptionList'
		},
		{
			'name': '',
			'type': 'separator fixed'
		},
		{
			'name': 'abiPropertyIncome.expensesSubTitle',
			'type': 'subtitle'
		},
		{
			'name': 'expenses',
			'type': 'descriptionList'
		},
		{
			'name': '',
			'type': 'separator fixed'
		},
		{
			'name': 'abiPropertyIncome.adjustmentsSubTitle',
			'type': 'subtitle'
		},
		{
			'name': 'adjustments',
			'type': 'descriptionList'
		},
		{
			'name': '',
			'type': 'separator fixed'
		},
		{
			'name': 'abiPropertyIncome.totalABIIncome',
			'type': 'amountWithNotes',
			'background': 'highlight'
		}
	]
};

export default EarningsDetailsyReportFields;