export enum ExportFilesListFields {
	DateExported = 'dateExported',
	FileName = 'fileName',
	ExportedBy = 'exportedBy',
	ExportStatus = 'exportStatus',
	Actions = 'actions',
	Name = 'name',
	ExportFileId = 'exportFileId'
}

export enum ImportFilesListFields {
	DateImported = 'dateImported',
	FileName = 'fileName',
	ImportedBy = 'importedBy',
	ImportStatus = 'importStatus',
	Actions = 'actions',
	ImportFileId = 'importFileId'
}

export enum ImportExportStatus {
	Completed = 'Completed',
	Error = 'Error',
	InProgress = 'In Progress',
	InQueue = 'In Queue',
	New = 'New',
	CompletedWithError = 'CompletedWithError'
}