import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { IReport } from 'src/features/engagement/models/IReport';
import CapitalGainsReportFields  from './../Settings/CapitalGainsReportFields';
import { ReportUtil } from 'src/features/engagement/utils/ReportUtil';
import { IReportField } from 'src/common/types/interfaces/IReportField';
import { ReportFieldEnum } from 'src/common/types/enums/ReportFieldEnum';
import ReportCell from '../ReportCells/ReportCell';
import ReportHeader from '../ReportCells/ReportHeader';
import { ReportI18NKeys } from 'src/common/types/enums/ReportI18NKeys';
import ReportRow from '../ReportCells/ReportRow';
import { Loading } from '@appkit4/react-components';


const CaptialGainsReport = (props: {refershReport: boolean}) =>
{
	const {engagementId, affiliateId } = useParams();

	const [reportData, setReportData] = useState<any[]>([]);

	const [sections, setSections] = useState<{index: number, collapsed: boolean}[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const report: IReport = CapitalGainsReportFields;

	const { t } = useTranslation(
		'report',
		{
			keyPrefix: ReportI18NKeys.CapitalGains
		}
	);

	const loadCaptialGainsReportData = () =>
	{
		if (engagementId && affiliateId)
		{
			setIsLoading(true);
			engagementService.loadCaptialGainsReport(Number(engagementId), Number(affiliateId)).then((res: any) =>
			{
				setIsLoading(false);
				setReportData(res.data.result);

				const maxCapitalGainCount = Math.max(...res.data.result.map((c: any) => c.capitalGains.length));

				const initialSections = Array.from({
					length: maxCapitalGainCount
				}, (_, index) => ({
					index,
					collapsed: false
				}));
				setSections(initialSections);
			})
				.catch((error: any) =>
				{
					setIsLoading(false);
				});
		}
	};

	useEffect(() =>
	{
		loadCaptialGainsReportData();
	},[engagementId, affiliateId]);

	useEffect(() =>
	{
		if (!!props.refershReport)
		{
			loadCaptialGainsReportData();
		}
	}, [props.refershReport]);

	const handleToggleSection = (index: number) =>
	{
	  const updatedSections =	sections.map((section: {index: number,
			 collapsed: boolean}) =>
		{
			if (section.index===index)
			{
				return {
					...section,
					collapsed: !section.collapsed
				};
			}
			else
			{
				return {
					...section
				};
			}
		});

		setSections(updatedSections);
	};

	return <div className='capital-gains-report'>

		{CapitalGainsReportFields&&<div className='table-container'>
			{isLoading && (
				<div className="loading-icon">
					<Loading
						loadingType="circular"
						indeterminate={true}
						compact={false}
					/>
				</div>
			)}
			{reportData &&
			<table>
				<thead>
					<tr>
						<th>
							{t('header-title')}
						</th>
						{
							Array.from({
								length: reportData.length
							}, (_, index) =>
							{
								return (
									<th></th>
								);
							}
							)
						}
					</tr>
				</thead>
				<tbody>
					{report.header.map((field: any) =>
						<ReportHeader reportData={reportData} field={field} i18nKey={ReportI18NKeys.CapitalGains} ></ReportHeader>
					  )}
					{report.summary.map((field: IReportField) =>
						<ReportRow reportData={reportData} field={field} i18nKey={ReportI18NKeys.CapitalGains} ></ReportRow>
					  )}
					{
						sections.map((section: {index: number, collapsed: boolean}) =>
						{
							return (
								<>
	 						{report.details.map((field: any) =>
									{
										return (
											<tr  className={`${ReportUtil.getRowClassName(field)} ${section.collapsed?
											 'collapsed': 'expanded'}`}>
												<td className={`${!!field?.background ? field?.background : ''}`}>
													{ field.type===ReportFieldEnum.Section && <span
														className={`Appkit4-icon 
														${section.collapsed?'icon-down-chevron-outline':'icon-up-chevron-outline'}`}
														onClick={() => handleToggleSection(section.index)}
													></span> } {`${t(field.name)}`}
													{`${field.type===ReportFieldEnum.Section?' - '+ (section.index+1):''}`}
												</td>
												{reportData.map((item: any) =>
												{
													return (

														<td>
															<ReportCell data={item.capitalGains[section.index]} field={field} />
														</td>
													);
												})
					 }
											</tr>);
					  })}
								</>
							);
						})
					}
				</tbody>
			</table>
			}
		</div>}
	</div>;
};


export default CaptialGainsReport;