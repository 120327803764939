import { Utils } from 'src/common/utils/utils';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import { EngagementUtil } from './EngagementUtil';
import { IReportField } from 'src/common/types/interfaces/IReportField';
import { ReportFieldEnum } from 'src/common/types/enums/ReportFieldEnum';

export const ReportUtil = {
	getRowClassName: (field: IReportField) =>
	{
		const background = field.background?' '+field.background:'';
		return field.type + background;
	},
	renderField: (entity: any, field: IReportField): any =>
	{
		if (Utils.isNullUndefined(entity))
		{
			return '';
		}
		const propertyValue = Utils.getPropertyValue(entity, field.name);
		if (Utils.isNullUndefined(propertyValue) && field.type !== ReportFieldEnum.Currency)
		{
			return '';
		}
		switch (field.type)
		{
		case ReportFieldEnum.Currency:
			return !!propertyValue ? Utils.formatCurrency(propertyValue, 2) : (propertyValue === null || propertyValue === 0 ? 0 : '');
		case ReportFieldEnum.Date:
			return 	dateFormatter.format(dateFormatter.utcToEst(propertyValue), 'MMM DD, YYYY');
		case ReportFieldEnum.DateTime:
			return 	dateFormatter.format(dateFormatter.utcToEst(propertyValue), 'MMM DD, YYYY hh:mm A');
		case ReportFieldEnum.Time:
			return 	dateFormatter.format(dateFormatter.utcToEst(propertyValue), 'hh:mm A');
		case ReportFieldEnum.Text:
			return propertyValue;
		case ReportFieldEnum.YesNo:
			return propertyValue?'Yes':'No';
		case ReportFieldEnum.CountryId:
			return EngagementUtil.getCountryName(propertyValue);
		case ReportFieldEnum.EquityClassId:
			return EngagementUtil.getEquityClassName(propertyValue);
		case ReportFieldEnum.DispositionType:
			return EngagementUtil.getDispositionTypeName(propertyValue);
		case ReportFieldEnum.CurrencyId:
			return EngagementUtil.getCurrencyCode(propertyValue);
		case ReportFieldEnum.Notes:
			return !!propertyValue?.notes ? propertyValue?.notes : '';
		case ReportFieldEnum.AmountWithNotes:
			return !!propertyValue ?  (!!propertyValue.amount ? Utils.formatCurrency(propertyValue.amount, 2) :
				(propertyValue?.amount === null || propertyValue?.amount === 0 ? 0 : '')) : '';
		case ReportFieldEnum.Description:
			return propertyValue;
		case ReportFieldEnum.Amount:
			const currentTaxYearEndCurrencyId = entity[ReportFieldEnum.CalculatingCurrencyId];
			const previousTaxYearEndCurrencyId = entity[ReportFieldEnum.OpeningBalance]?.[ReportFieldEnum.CurrencyId];
			if (!!currentTaxYearEndCurrencyId && !!previousTaxYearEndCurrencyId &&
				currentTaxYearEndCurrencyId !== previousTaxYearEndCurrencyId)
			{
				const previousTaxYearEndCurrency = EngagementUtil.getCurrencyCode(previousTaxYearEndCurrencyId);
				return Utils.formatCurrency(propertyValue, 2) + ` ${previousTaxYearEndCurrency}`;
			}
			else
			{
				return Utils.formatCurrency(propertyValue, 2);
			}

		default:
			return '';
		}
	},
	hasYearEndDuplicatedDate: (item: any, data: any[]) =>
	{
		const a = data.filter((d: any) =>
			dateFormatter.format(dateFormatter.utcToEst(d.taxYearEnd), 'MMM DD, YYYY') ===
			dateFormatter.format(dateFormatter.utcToEst(item.taxYearEnd), 'MMM DD, YYYY')
		);
		if (a &&a.length>1)
		{
			return true;
		}
		else
		{
			return false;
		}
	}
};