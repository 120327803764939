import { LineItemTypeEnum } from 'src/common/types/enums/LineItemTypeEnum';
import { ICurrency } from 'src/common/types/interfaces/ICurrency';
import { IInputField } from 'src/common/types/interfaces/IInputField';
import { ILineItem } from 'src/common/types/interfaces/ILineItem';
import { IOption } from 'src/common/types/interfaces/IOption';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import { Utils } from 'src/common/utils/utils';
import store from 'src/store/store';
import { IInput } from '../models/IInput';
import { INameId } from 'src/common/types/interfaces/INameId';

export const EngagementUtil = {
	hasValidationError: (validationError: any) =>
	{
		if (!validationError)
		{
			return true;
		}
		if (
			validationError.clientSideErr.faName ||
			validationError.clientSideErr.calculatingCurrencyId ||
			validationError.clientSideErr.countryId ||
			validationError.clientSideErr.taxationYear ||
			validationError.clientSideErr.analysisStartDate ||
			validationError.clientSideErr.dateBecameFa
		)
		{
			return true;
		}
		else
		{
			return false;
		}
	},

	getOptions: (lookupField: string) =>
	{
		if (!lookupField)
		{
			return [];
		}
		const options = store.getState().appSettings.options;
		const currencies = store.getState().appSettings.currencies
			.map((c: any) =>
			{
				return {
					id: c.id,
					field: 'currency',
					value: `${c.id}`,
					label: c.code
				};
			});

		if (lookupField==='currencies')
		{
			return currencies;
		}
		else
		{
			return options.filter((c: IOption) => c.field.toLocaleLowerCase()===lookupField.toLocaleLowerCase());
		}
	},

	getCurrencyCode: (currencyId: number|undefined) =>
	{
		if (currencyId===undefined)
		{
			return '';
		}

		const currency = store.getState().appSettings.currencies.find((c: ICurrency) => c.id===Number(currencyId))?.code;
		return currency;
	},

	getDispositionTypeName: (dispositionType: string) =>
	{
		if (dispositionType===undefined)
		{
			return '';
		}

		const currency = store.getState().appSettings.options.find((c: IOption) =>
			c.field==='DispositionType'&&c.value===dispositionType)?.label;
		return currency;
	},

	getCountryName: (countryId: number|undefined) =>
	{
		if (countryId===undefined)
		{
			return '';
		}
		const countryName = store.getState().appSettings.countries.find((c: INameId ) => c.id===Number(countryId))?.name;
		return countryName;
	},

	getEquityClassName: (equityClassId: number|undefined) =>
	{
		if (equityClassId===undefined)
		{
			return '';
		}
		const equityClassName = store.getState().appSettings.allEquityClasses.find((c: INameId ) => c.id===Number(equityClassId))?.name;
		return equityClassName;
	},

	getLineItemFormattedValue: (lineItem: ILineItem) =>
	{
		switch (lineItem.type)
		{
		case LineItemTypeEnum.datepicker:
			return lineItem.value? dateFormatter.format(new Date(lineItem.value),'MMM DD, YYYY'):'';
		case LineItemTypeEnum.currency:
			return Utils.formatCurrency(lineItem.value, 2);
		case LineItemTypeEnum.dropdown:
			return lineItem.options.find((option: IOption) => option.value===lineItem.value)?.label;
		default:
			return lineItem.value;
		}
	},

	initLineItems: (category: string): ILineItem[] =>
	{
		const inputForm = store.getState().appSettings.inputForms.find((c: any) => c.category===category);
		if (inputForm)
		{
			const lines: ILineItem[] = inputForm.fields.map((item: IInputField) =>
			{
				const lineItem: ILineItem = {
					field: item.field,
					title: item.title,
					line: item.line,
					dataType: item.dataType,
					type: item.type as LineItemTypeEnum,
					step: item.step,
					editable: item.editable,
					inline: item.inline,
					value: item.value,
					note: item.note,
					error: '',
					defaultErrorMessage: item.error,
					required: item.required,
					hasNote: item.hasNote,
					expanded: item.expanded,
					options: EngagementUtil.getOptions(item.options),
					searchable: item.searchable
				};
				return lineItem;
			});

			return lines;
		}
		const emptyArray: ILineItem[] = [];
		return emptyArray;
	},

	getInputArray: ( records: IInput[], showNotes: boolean) =>
	{
		const ids = records.map((record: IInput) => record.recordGuid);
		const recordIds = showNotes? ids.flatMap((item) => [item,'']):[...ids];
		recordIds.unshift('');

		const result =  records[0].lineItems.map((lineItem: ILineItem, line: number) =>
		{
			const r =  records.map((record: IInput) =>
			{
				const current = record.lineItems.find((item: ILineItem) => item.field===lineItem.field);
				if (current)
				{
					const v = EngagementUtil.getLineItemFormattedValue(current) ;
					const note = current.note;
					if (showNotes)
					{
						return [v, note];
					}
					else
					{
						return v;
					}
				}
				else
				{
					return '';
				}
			} );
			return [lineItem.title, ...r.flat()];
		});
		return [recordIds,...result];
	}
};