import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { ILineItem } from 'src/common/types/interfaces/ILineItem';
import { cloneDeep, isEqual, differenceWith } from 'lodash';
import { LineItemCategories } from 'src/common/types/enums/LineItemCategories';
import { inputRecordHelper } from 'src/common/utils/inputRecordHelper';
import { UnexpectedErrorModal } from 'src/common/components/UnexpectedErrorModal/UnexpectedErrorModal';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import { Button, Loading, Switch, Tooltip, Notification } from '@appkit4/react-components';
import { LineItemTypeEnum } from 'src/common/types/enums/LineItemTypeEnum';
import { EngagementUtil } from 'src/features/engagement/utils/EngagementUtil';
import { WarningModal } from 'src/common/components/WarningModal/WarningModal';

import AffiliateContext from 'src/common/components/SharedDataContext/AffiliateContext';
import { IEarningAmount, IEarnings } from 'src/features/engagement/models/IEarnings';
import { EarningField } from 'src/common/types/enums/EarningField';
import EarningOptionDropdown from 'src/common/components/GridControls/EarningOptionDropdown/EarningOptionDropdown';
import { NumericInputWithNotesControl } from 'src/common/components/GridControls/NumericInputWithNotesControl';
import { EarningOptionEnum } from 'src/common/types/enums/EarningOptionEnum';

import './Earnings.scss';
import { useAppSelector } from 'src/store/store';
import { Utils } from 'src/common/utils/utils';
import { CalculationTypesEnum } from 'src/common/types/enums/CalculationTypes';
import { SaveProgressBar } from 'src/common/components/SaveProgressBar/SaveProgressBar';
import { NotificationService } from 'src/core/services/notificationService';

const Earnings = (props: any) =>
{
	const [displayNotes, setDisplayNotes] = useState(false);
	const [showUnexpectedErrorModal, setShowUnexpectedErrorModal] =
    useState<boolean>(false);
	const [unexpectedError, setUnExpectedError] = useState<string>('');
	const [errorCode, setErrorCode] = useState<number|undefined>(undefined);

	const { t } = useTranslation(
		'input',
		{
			keyPrefix: 'earnings'
		}
	);

	const [earnings, setEarnings] = useState<IEarnings[]>([]);
	const [rawEarnings, setRawEarnings] = useState<IEarnings[]>([]);

	const affiliateContext = useContext(AffiliateContext);
	const [lineConfiguration, setLineConfiguration] = useState<ILineItem[]>([]);
	const [showWarningModal, setShowWarningModal] =
    useState<boolean>(false);
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const [hasWarning, setHasWarning] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	const [isEnhancedSurplus, setIsEnhancedSurplus] = useState<boolean>(false);

	const enhancedSurplusId = useAppSelector((state) => state.appSettings.calculationTypes)
		.find((c) => c.code===CalculationTypesEnum.EnhancedSurplus)?.id;

	useEffect(() =>
	{
		if (affiliateContext&&enhancedSurplusId)
		{
			const enhancedSurplus = affiliateContext.affiliateDetail.calculationTypeIds.includes(+enhancedSurplusId);
			setIsEnhancedSurplus(enhancedSurplus);
	   }
	},[affiliateContext, enhancedSurplusId]);

	const getEarningAmount = (earning: IEarningAmount|undefined): number =>
	{
		const retVal = Number(!!earning?.optionId? (earning?.detailedAmount??0): (earning?.amount??0));
		return isNaN(retVal)?0:retVal;
	};

	const areAllNullOrUndefined=  (...earnings: any[]): boolean =>
	{
		return earnings.every((earning) => (!!earning?.optionId?earning?.detailedAmount:earning?.amount)===undefined||
		(!!earning?.optionId?earning?.detailedAmount:earning?.amount)===null||
		(!!earning?.optionId?earning?.detailedAmount:earning?.amount)==='' );
	};

	const calculateTotal = (earnings: IEarnings) =>
	{
		if (areAllNullOrUndefined(earnings.foreignTaxableIncome,
			earnings.lessIncomeFromPropertyFAPI,
			earnings.lessIncomeFromPropertyABI,
			earnings.lessCapitalGain,
			earnings.reg5907Adjustments,
			earnings.additionalReg5907Adjustments)
		)
		{
			earnings.totalUnderForeignRules = undefined;
		}
		else
		{
			earnings.totalUnderForeignRules = Utils.add(getEarningAmount(earnings.foreignTaxableIncome),
				getEarningAmount(earnings.lessIncomeFromPropertyFAPI),
				getEarningAmount(earnings.lessIncomeFromPropertyABI),
				getEarningAmount(earnings.lessCapitalGain),
				getEarningAmount(earnings.reg5907Adjustments),
				getEarningAmount(earnings.additionalReg5907Adjustments));
		}


		if (areAllNullOrUndefined(earnings.fapi,
			earnings.deemedABI,
			earnings.abi,
			earnings.otherABIAdjustments,
			earnings.otherTaxableEarnings))
	   {
			earnings.totalUnderPartIofTheAct = undefined;
	   }
		else
		{
			earnings.totalUnderPartIofTheAct = Utils.add(getEarningAmount(earnings.fapi),
				getEarningAmount(earnings.deemedABI),
				getEarningAmount(earnings.abi),
				getEarningAmount(earnings.otherABIAdjustments),
	   		getEarningAmount(earnings.otherTaxableEarnings));
		}
	};

	const handleApiErrorResponse = (err: any) =>
	{
		if (err.response)
		{
			setErrorCode(err.response.status);
			if (err.response.status >= 400)
			{
				if (err.response.data.Errors&&err.response.data.Errors.length>0)
				{
					setUnExpectedError(err.response.data.Errors[0].Message);
					setShowUnexpectedErrorModal(true);
				}
				else
				{
					const dataRequiredError = t('dataRequired');
					setUnExpectedError(dataRequiredError);
					setShowUnexpectedErrorModal(true);
				}
			}
		}
	};


	const loadEarnings = (
		{
			engagementId,
			affiliateId,
			fromDate,
			toDate
		}:
		{
			engagementId: number;
			affiliateId: number;
			fromDate?: Date;
			toDate?: Date;
		}
	) =>
	{
		setIsLoading(true);

		engagementService
			.getEarnings(
				engagementId,
				affiliateId,
				fromDate,
				toDate
			).then((res: any) =>
			{
				setIsLoading(false);
				setHasWarning(false);
				setDataChanged(false);
				!!props.handleChange && props.handleChange(false);
				if (res.data && res.data.result)
				{
					const earningsData = res.data.result
						.map((item: any, index: number, allData: any[]) =>
						{
							const earnings: IEarnings = {
								id: item.id?item.id: item.affiliateTaxYearEndId,
								affiliateTaxYearEndId: item.affiliateTaxYearEndId,
								taxYearEnd: dateFormatter.conditionallyFormatDateWithTime('taxYearEnd', item, index, allData),
								countryId: item.countryId,
								calculatingCurrencyId: item.calculatingCurrencyId,

								foreignTaxableIncome: item.foreignTaxableIncome,
								lessIncomeFromPropertyFAPI: item.lessIncomeFromPropertyFAPI,
								lessIncomeFromPropertyABI: item.lessIncomeFromPropertyABI,
								lessCapitalGain: item.lessCapitalGain,
								reg5907Adjustments: item.reg5907Adjustments,
								additionalReg5907Adjustments: item.additionalReg5907Adjustments,
								fapi: item.fapi,
								deemedABI: item.deemedABI,
								abi: item.abi,
								otherABIAdjustments: item.otherABIAdjustments,
								otherTaxableEarnings: item.otherTaxableEarnings

							};
							calculateTotal(earnings);
							return earnings;
						}
						);
					setEarnings(earningsData);
					setRawEarnings(cloneDeep(earningsData));
				}
			})
			.catch((error: any) =>
			{
				handleApiErrorResponse(error);
				setIsLoading(false);
			});
	};

	const stopSaveProgressBar = async (): Promise<void> =>
	{
		await Utils.timeout(500);
		setIsSaving(false);
	};

	const triggerSuccessNotification = async (): Promise<void> =>
	{
		const ele = (
			<Notification
				  message={t('successMessage')}
				  status={'success'}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};

	const triggerErrorNotification = async (message: string): Promise<void> =>
	{
		const ele = (
			<Notification
				  message={message}
				  status={'error'}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};


	const handleSave = async () =>
	{
		const changedEarnings = differenceWith(earnings, rawEarnings, isEqual);

		if (changedEarnings.length>0)
		{
			const payload = {
				engagementId: affiliateContext?.engagementDetail.id!,
				affiliateId: affiliateContext?.affiliateDetail.affiliateId!,
				earnings: changedEarnings
			};

			if (!isLoading)
			{
				setIsLoading(true);
				setIsSaving(true);
				engagementService.saveEarnings(payload).then((res: any) =>
				{
					setIsLoading(false);
					stopSaveProgressBar();
					loadEarnings({
						engagementId: affiliateContext?.engagementDetail.id!,
						affiliateId: affiliateContext?.affiliateDetail.affiliateId!
					});
				})
					.then((error: any) =>
					{
						setIsLoading(false);
						stopSaveProgressBar();
						triggerSuccessNotification();
					})
					.catch((err: any) =>
					{
						setIsLoading(false);
						stopSaveProgressBar();
						triggerErrorNotification(
							t('saveError')
						);
						handleApiErrorResponse(err);
					});
			}
		};
	};

	const loadRowInformation = async (
		engagementId: number,
		affiliateId: number
	): Promise<void> =>
	{
		try
		{
			const lines = await inputRecordHelper
				.initializeLineItems(
					LineItemCategories.Earnings,
					engagementId,
					affiliateId
				);

			setLineConfiguration(lines);
		}
		catch (error)
		{
			handleApiErrorResponse(error);
		}
	};


	useEffect(
		() =>
		{
			if (
				!!affiliateContext&&
				!!affiliateContext?.engagementDetail.id&&
				!!affiliateContext?.affiliateDetail.affiliateId
			)
			{
				loadRowInformation(
					affiliateContext?.engagementDetail.id!,
					affiliateContext?.affiliateDetail.affiliateId!
				);
			}
		},
		[affiliateContext]
	);

	useEffect(
		() =>
		{
			if (
				!!lineConfiguration &&
				!!lineConfiguration.length &&
				!!affiliateContext&&
				!!affiliateContext?.engagementDetail.id&&
				!!affiliateContext?.affiliateDetail.affiliateId
			)
			{
				loadEarnings({
					engagementId: affiliateContext?.engagementDetail.id!,
					affiliateId: affiliateContext?.affiliateDetail.affiliateId!
				});
			}
		},
		[
			affiliateContext,
			lineConfiguration
		]
	);

	const handleInputChange = (id: string, field: string, value: any) =>
	{
		if (value==='')
		{
			value=null;
		}
		const updatedEarnings = earnings.map((d: any) =>
		{
			if (d.id&&d.id.toString()===id.toString())
			{
				const updatedEarnings = d[field]? {
					...d,
					[field]: {
						...d[field],
						amount: value
					}
				}:{
					...d,
					[field]: {
						optionId: isEnhancedSurplus? EarningOptionEnum.details: EarningOptionEnum.basic,
						amount: value
					}
				};

				calculateTotal(updatedEarnings);
				return updatedEarnings;
			}
			else
			{
				return d;
			}
		});


		setEarnings(updatedEarnings);
		const hasChange = !isEqual(updatedEarnings, rawEarnings);
		setDataChanged(hasChange);
		!!props.handleChange && props.handleChange(hasChange);
	};

	const handleOptionChange = (id: string, field: string, value: any) =>
	{
		if (value==='')
		{
			value=null;
		}
		const updatedEarnings = earnings.map((d: any) =>
		{
			if (d.id&&d.id.toString()===id.toString())
			{
				const updatedEarnings = d[field]? {
					...d,
					[field]: {
						...d[field],
						optionId: +value
					}
				}:{
					...d,
					[field]: {
						optionId: +value
					}
				};
				calculateTotal(updatedEarnings);
				return updatedEarnings;
			}
			else
			{
				return d;
			}
		});


		setEarnings(updatedEarnings);
		const hasChange = !isEqual(updatedEarnings, rawEarnings);
		setDataChanged(hasChange);
		!!props.handleChange && props.handleChange(hasChange);
	};

	const handleNotesChange = (id: string, field: string, value: any) =>
	{
		if (value==='')
		{
			value=null;
		}
		const updatedEarnings = earnings.map((d: any) =>
		{
			if (d.id&&d.id.toString()===id.toString())
			{
				const updatedEarnings = d[field]? {
					...d,
					[field]: {
						...d[field],
						notes: value
					}
				}:{
					...d,
					[field]: {
						notes: value
					}
				};

				return updatedEarnings;
			}
			else
			{
				return d;
			}
		});

		setEarnings(updatedEarnings);
		const hasChange = !isEqual(updatedEarnings, rawEarnings);
		setDataChanged(hasChange);
		!!props.handleChange && props.handleChange(hasChange);
	};


	const closeUnexpectedErrorModal = () =>
	{
		setUnExpectedError('');
		setShowUnexpectedErrorModal(false);
	};


	const closeWarning = () =>
	{
		setShowWarningModal(false);
	};

	const isEnhancedSurplusField = (line: ILineItem): boolean =>
	{
	 return	line.field===EarningField.foreignTaxableIncome||
										line.field===EarningField.reg5907Adjustments||
									line.field===EarningField.fapi||
									line.field===EarningField.deemedABI||
									line.field===EarningField.abi;
	};

	const onNotesToggle = (value: boolean) =>
	{
		setDisplayNotes(!!value);
	};

	return <div className='earnings'>
		<div className="tool-bar">
			<Button kind="primary" disabled={isLoading||!dataChanged||hasWarning}
				onClick={handleSave}
			>{t('Save')}
			</Button>
		</div>
		<div className='table-container'>
			{isLoading && (
				<div className="loading-icon">
					<Loading
						loadingType="circular"
						indeterminate={true}
						compact={false}
					/>
				</div>
			)}
			<div>
				<SaveProgressBar
					display={isSaving}
					message={t('saveInProgressMessage') || ''}
				/>
			</div>
			{ earnings.length>0 && (
				<table>
					<thead>
						<tr>
							<th>
								<div className="caption">
									<div className="switch-title">
										{t('earnings')}
									</div>{' '}
									<div className={'toggle'}>
										<Switch
											className={'switch-label'}
											checked={displayNotes}
											disabled={isLoading||earnings.length===0}
											onChange={onNotesToggle}
										>
											{
												t('showNotes')
											}
										</Switch>
									</div>
								</div>
							</th>
						</tr>
					</thead>
					<tbody>
						{
							lineConfiguration.map((line: ILineItem) => (
								<>
									{/* show section header */}
									{line.field===EarningField.foreignTaxableIncome&&
								<tr className="sub-title">
									<td>
										{t('earningsComputedUnderForeignRules')}
										<Tooltip
											trigger="hover"
											position="top"
											distance={5}
											appendAfterTarget={true}
											content={`${t('earningsComputedUnderForeignRulesTooltip')}`}
										>
											<span className="Appkit4-icon icon-help-question-outline"></span>
										</Tooltip>
									</td>
								</tr>}
									{line.field===EarningField.lessIncomeFromPropertyFAPI&&
								<tr className="sub-title">
									<td>
										{t('lessIncomeFromProperty')}
									</td>
								</tr>}
									{line.field===EarningField.fapi&&
									<>
										<tr className="seperate-row">&nbsp;</tr>
										<tr className="sub-title">
											<td>
												{t('earningsComputedUnderPartIOftheAct')}
											</td>
										</tr>
									</>}
									{/* show subtitle */}
									{isEnhancedSurplusField(line)&&(
										<>
											<tr className="enhanced-surplus-field">
												<td>
													{t(line.field)}
													{isEnhancedSurplus&&<>
														<span className="Appkit4-icon icon-information-outline"></span>
														<span className='user-aid'>{t('userAid')}</span>
													</>}
												</td>
											</tr>

										</>
									)}
								    {/* add option cell */}
									{isEnhancedSurplus&&isEnhancedSurplusField(line) &&
									<tr className="option-row">
										<td >
											<div className='option-amount'>
												{t('option')}
											</div>
										</td>
										{earnings.map((item: any) => (
											<td >
											  <EarningOptionDropdown field={line.field} value={
													item[line.field]?item[line.field].optionId.toString():
														(isEnhancedSurplus? EarningOptionEnum.details.toString():
															EarningOptionEnum.basic.toString())}
											  onChange={(value: any) => handleOptionChange(
													item.id,line.field, value)} />
											</td>
										)
										)
										}
									</tr>}
									{ line.field !== EarningField.calculatingCurrencyId &&
									<tr className={line.field}>
										{
											line.field === EarningField.countryId ?
												<td>
													<div>
														<div>{t(`${EarningField.countryId}`)}</div>
														<div>{t(`${EarningField.calculatingCurrencyId}`)}</div>
													</div>
												</td>:
												<td className={`${line.type===LineItemTypeEnum.total?'total-header':''}`}>
													{isEnhancedSurplusField(line)?
														<div className='option-amount'>{t('amount')}</div>:
														<div>{t(`${line.field}`)}
															{line.field===EarningField.additionalReg5907Adjustments&&
															<Tooltip
																trigger="hover"
																position="top"
																distance={5}
																appendAfterTarget={true}
																content={`${t('additionalReg5907AdjustmentsTooltip')}`}
															>
																<span className="Appkit4-icon icon-help-question-outline"></span>
															</Tooltip>}
														</div>

													}


												</td>
										}

										{
											earnings.map((item: any) => (
												<>
													{(() =>
													{
														switch (line.type)
														{
														case LineItemTypeEnum.dropdown:
															return <td>
																<div className="country-currency">
																	<div>
																		{EngagementUtil.getCountryName(
																			item[EarningField.countryId])}
																	</div>
																	<div>{EngagementUtil.getCurrencyCode(
																		item[EarningField.calculatingCurrencyId])}</div>
																</div>

															</td>;
														case LineItemTypeEnum.earningAmount:
															return <>
																<td>
																	<NumericInputWithNotesControl
																		isDisabled={
																			 props.isLoading ||props.isDisabled||
																			 (isEnhancedSurplusField(line)&&!!(item[line.field]?.optionId))
																		}
																		displayNotes={!!displayNotes}
																		notes={item[line.field]?.notes}
																		value={item[line.field]?.optionId?
																			item[line.field]?.detailedAmount:item[line.field]?.amount}
																		onValueChange={(value: any) => handleInputChange(
																			item.id,line.field, +value)}
																		onNotesSave={(value) =>
																		{
																			handleNotesChange(item.id,line.field, value);
																		}}
																	/>
																</td>
															</>;
														case LineItemTypeEnum.total:
															return <td className='total'>
																<div>{Utils.formatCurrency(item[line.field], 2)}</div>

															</td>;
														default:
															return <td>
																<div>{item[line.field]}</div>
															</td>;
														}
													})()
													}

												</>
											))
										}
									</tr>
									}

								</>
							)
							)

						}
					</tbody>
				</table>
			)
			}
		</div>
		<div className="tool-bar">
			<Button kind="primary" disabled={isLoading||!dataChanged||hasWarning} onClick={handleSave}>{t('Save')}</Button>
		</div>
		 <UnexpectedErrorModal
			visible={showUnexpectedErrorModal}
			onClose={closeUnexpectedErrorModal}
			message={unexpectedError}
			error={''}
			statusCode={errorCode}
		 />
		<WarningModal
			visible={showWarningModal}
			title={t('warning-title')}
			content={t('warning-content')}
			onClose={closeWarning}
		/>
	</div>;
};

export default Earnings;
