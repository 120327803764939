import { Utils } from 'src/common/utils/utils';
import { CapitalGainsDispositionFields } from '../enums/CapitalGainsDispositionFields';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import moment from 'moment';

export interface ICapitalGainsDisposition {

	[CapitalGainsDispositionFields.id]?: number;
	[CapitalGainsDispositionFields.engagementId]: number;
	[CapitalGainsDispositionFields.affiliateId]: number;

	[CapitalGainsDispositionFields.type]?: string;
	[CapitalGainsDispositionFields.description]: string;
	[CapitalGainsDispositionFields.isDepreciableProperty]?: boolean;
	[CapitalGainsDispositionFields.dateOfAcquisition]?: Date;
	[CapitalGainsDispositionFields.dateOfDisposition]?: Date;
	[CapitalGainsDispositionFields.timeOfDisposition]?: Date;
	[CapitalGainsDispositionFields.transactionCurrencyId]?: number;
	[CapitalGainsDispositionFields.proceedsOfDisposition]?: number;
	[CapitalGainsDispositionFields.electionAmount]?: number;
	[CapitalGainsDispositionFields.cost]?: number;
	[CapitalGainsDispositionFields.expenses]?: number;
	[CapitalGainsDispositionFields.otherAdjustments]?: number;
	[CapitalGainsDispositionFields.preAcquisitionAmount]?: number;
	[CapitalGainsDispositionFields.calculatedCapitalGainOrLoss]?: number;
	[CapitalGainsDispositionFields.taxPaidOnDisposition]?: number;
	[CapitalGainsDispositionFields.notes]?: string;
}

export class CapitalGainsDisposition implements ICapitalGainsDisposition
{
	constructor (
		engagementId: number,
		affiliateId: number,
		currencyId: number
	)
	{
		this[CapitalGainsDispositionFields.id] = Utils.generateTempId();
		this[CapitalGainsDispositionFields.engagementId] = engagementId;
		this[CapitalGainsDispositionFields.affiliateId] = affiliateId;
		this[CapitalGainsDispositionFields.transactionCurrencyId] = currencyId;
		this[CapitalGainsDispositionFields.description] = '';
		this[CapitalGainsDispositionFields.isDepreciableProperty] = undefined;
	}

	[CapitalGainsDispositionFields.id]?: number;
	[CapitalGainsDispositionFields.engagementId]: number;
	[CapitalGainsDispositionFields.affiliateId]: number;

	[CapitalGainsDispositionFields.type]?: string;
	[CapitalGainsDispositionFields.description]: string;
	[CapitalGainsDispositionFields.isDepreciableProperty]?: boolean;
	[CapitalGainsDispositionFields.dateOfAcquisition]?: Date;
	[CapitalGainsDispositionFields.dateOfDisposition]?: Date;
	[CapitalGainsDispositionFields.timeOfDisposition]?: Date;
	[CapitalGainsDispositionFields.transactionCurrencyId]?: number;
	[CapitalGainsDispositionFields.proceedsOfDisposition]?: number;
	[CapitalGainsDispositionFields.electionAmount]?: number;
	[CapitalGainsDispositionFields.cost]?: number;
	[CapitalGainsDispositionFields.expenses]?: number;
	[CapitalGainsDispositionFields.otherAdjustments]?: number;
	[CapitalGainsDispositionFields.preAcquisitionAmount]?: number;
	[CapitalGainsDispositionFields.calculatedCapitalGainOrLoss]?: number;
	[CapitalGainsDispositionFields.taxPaidOnDisposition]?: number;
	[CapitalGainsDispositionFields.notes]?: string;

	static populateFields(record: ICapitalGainsDisposition): ICapitalGainsDisposition
	{
		return {
			...record,
			[CapitalGainsDispositionFields.dateOfAcquisition]: dateFormatter.utcToEst(
				record[CapitalGainsDispositionFields.dateOfAcquisition]
			),
			[CapitalGainsDispositionFields.dateOfDisposition]: dateFormatter.utcToEst(
				record[CapitalGainsDispositionFields.dateOfDisposition]
			),
			[CapitalGainsDispositionFields.timeOfDisposition]: dateFormatter.utcToEst(
				record[CapitalGainsDispositionFields.dateOfDisposition]
			)
		};
	}

	static isSame(record?: ICapitalGainsDisposition, compareRecord?: ICapitalGainsDisposition): boolean
	{
		const isDateSame = (date1?: Date, date2?: Date): boolean =>
		{
			return !!date1 && !!date2 ?
				moment(date1).isSame(date2) :
				!(
					(!!date1 && !date2) ||
					(!date1 && !!date2)
				);
		};

		const numericCompare = (value1: number | string | undefined, value2: number | string | undefined): boolean =>
		{
			return Utils.getNumberValue(value1) === Utils.getNumberValue(value2);
		};

		const fields = CapitalGainsDispositionFields;

		return !!record && !!compareRecord &&
			record[fields.id] === compareRecord[fields.id] &&
			record[fields.affiliateId] === compareRecord[fields.affiliateId] &&
			record[fields.engagementId] === compareRecord[fields.engagementId] &&
			record[fields.type] === compareRecord[fields.type] &&
			record[fields.description] === compareRecord[fields.description] &&
			record[fields.isDepreciableProperty] === compareRecord[fields.isDepreciableProperty] &&
			isDateSame(record[fields.dateOfAcquisition], compareRecord[fields.dateOfAcquisition]) &&
			isDateSame(record[fields.dateOfDisposition], compareRecord[fields.dateOfDisposition]) &&
			isDateSame(record[fields.timeOfDisposition], compareRecord[fields.timeOfDisposition]) &&
			record[fields.transactionCurrencyId] === compareRecord[fields.transactionCurrencyId] &&
			numericCompare(record[fields.proceedsOfDisposition], compareRecord[fields.proceedsOfDisposition]) &&
			numericCompare(record[fields.electionAmount], compareRecord[fields.electionAmount]) &&
			numericCompare(record[fields.cost], compareRecord[fields.cost]) &&
			numericCompare(record[fields.expenses], compareRecord[fields.expenses]) &&
			numericCompare(record[fields.otherAdjustments], compareRecord[fields.otherAdjustments]) &&
			numericCompare(record[fields.preAcquisitionAmount], compareRecord[fields.preAcquisitionAmount]) &&
			numericCompare(record[fields.calculatedCapitalGainOrLoss], compareRecord[fields.calculatedCapitalGainOrLoss]) &&
			numericCompare(record[fields.taxPaidOnDisposition], compareRecord[fields.taxPaidOnDisposition]) &&
			record[fields.notes] === compareRecord[fields.notes];
	}

	static toRequestBody(
		capitalGainDisposition: ICapitalGainsDisposition,
		isUpdate?: boolean
	): { [key: string]: any }
	{
		const fields = CapitalGainsDispositionFields;

		return {
			engagementId: capitalGainDisposition[fields.engagementId],
			capitalGain: {
				...capitalGainDisposition,
				[fields.id]: !!isUpdate ? capitalGainDisposition.id : undefined,
				[fields.dateOfAcquisition]: dateFormatter.estDateStringOverrideToUtc(
					dateFormatter.estDateToString(capitalGainDisposition[fields.dateOfAcquisition])
				),
				[fields.dateOfDisposition]: dateFormatter.estDateStringOverrideToUtc(
					capitalGainDisposition[fields.timeOfDisposition]===undefined?
						dateFormatter.mergeDateTime(
							capitalGainDisposition[fields.dateOfDisposition],
							new Date(new Date().setHours(0,1,0,0))
						):
						dateFormatter.mergeDateTime(
							capitalGainDisposition[fields.dateOfDisposition],
							capitalGainDisposition[fields.timeOfDisposition]
						)
				),
				[fields.cost]: Utils.getNumberValue(capitalGainDisposition[fields.cost]),
				[fields.electionAmount]: Utils.getNumberValue(capitalGainDisposition[fields.electionAmount]),
				[fields.expenses]: Utils.getNumberValue(capitalGainDisposition[fields.expenses]),
				[fields.otherAdjustments]: Utils.getNumberValue(capitalGainDisposition[fields.otherAdjustments]),
				[fields.preAcquisitionAmount]: Utils.getNumberValue(capitalGainDisposition[fields.preAcquisitionAmount]),
				[fields.proceedsOfDisposition]: Utils.getNumberValue(capitalGainDisposition[fields.proceedsOfDisposition]),
				[fields.taxPaidOnDisposition]: Utils.getNumberValue(capitalGainDisposition[fields.taxPaidOnDisposition]),
				[fields.calculatedCapitalGainOrLoss]: Utils.getNumberValue(capitalGainDisposition[fields.calculatedCapitalGainOrLoss])
			}
		};
	}
}