export enum ReportI18NKeys {
	Report = 'report',
	CapitalGains = 'capital-gains',
	EarningsSummary = 'earnings-summary',
	EarningsDetails = 'earnings-details',
	ForeignTaxes = 'foreign-taxes',
	ExemptAndTaxableEarnings = 'exempt-and-taxable-earnings',
	ExemptSurplusDetails = 'exempt-surplus-details',
	TaxableSurplusDetails = 'taxable-surplus-details',
	HybridSurplusDetails = 'hybrid-surplus-details',
	DividendsPaid = 'dividends-paid',
	DividendsReceived = 'dividends-received',
	AdjustedCostBaseDetails = 'adjusted-cost-base-details',
	TaxPayerReport = 'tax-payer-report',
	ForeignAccurualPropertyIncome = 'foreign-accurual-property-income'
}