import { TFunction } from 'i18next';
import { IFieldError } from '../types/interfaces/IFieldError';
import { Decimal } from 'decimal.js';

export const Utils = {

	getUniqueRecords: <T extends Record<string, any>>(
		{
			key,
			items
		}:
		{
			key: string;
			items: T[];
		}
	): T[] =>
	{
		const uniqueValues = new Set();

		return items.filter((item) =>
		{
			const value = item[key];

			if (!uniqueValues.has(value))
			{
				uniqueValues.add(value);
				return true;
			}
			return false;
		});
	},

	isValidNumber: (input: string) =>
	{
		const number = parseFloat(input);
		return !isNaN(number);
	},
	isSameDay: (date1: Date, date2: Date) =>
	{
		return (
			date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()
		);
	},
	isSameDate: (date1: Date,date2: Date) =>
	{
		return (
			date1.getTime() === date2.getTime()
		);
	},

	formatCurrency: (input: string, fixedDecimalPlaces?: number) =>
	{
		if (input===undefined)
		{
			return '';
		}
		if (!input)
		{
			return input;
		}
		if (isNaN(Number(input)))
		{
			return input;
		}
		const number = parseFloat(input);

		let formattedNumber = '';

		if (!!Utils.isValidNumber(`${fixedDecimalPlaces}`))
		{
			if (number % 1 !== 0)
			{
				const num = Number(Math.abs(number).toFixed(fixedDecimalPlaces));
				formattedNumber = num
					.toString()
					.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
			}
			else
			{
				formattedNumber = Math.abs(number)
					.toString()
					.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
			}
		}
		else
		{
			const numberParts = Math
				.abs(number)
				.toString()
				.split('.');

			formattedNumber = numberParts[0]
				.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

			if (numberParts.length > 1)
			{
				formattedNumber = formattedNumber + '.' + numberParts[1];
			}
		}

		if (number < 0)
		{
			return `(${formattedNumber})`;
		}
		else
		{
			return formattedNumber;
		}
	},

	findLastIndex: (array: any[], callback: any) =>
	{
		for (let i=array.length-1;i>=0;i--)
		{
			if (callback(array[i], i, array))
			{
				return i;
			}
		}
		return -1;
	},

	hasError: (error: any): boolean =>
	{
		for (const key of Object.keys(error))
		{
			if (typeof error[key] === 'string' && error[key] !== '')
			{
				return true;
			}
		}
		return false;
	},

	pascalToCamel: (pascal: string): string =>
	{
		if (!pascal)
		{
			return '';
		}

		return pascal.charAt(0).toLowerCase() +
			pascal.slice(1);
	},

	processErrors: (
		{
			error,
			errorValidationRecords,
			unexpectedErrorMessage,
			translationService,
			explicitRulesCallback
		}:
		{
			error: any;
			errorValidationRecords: Record<string, string | null>;
			unexpectedErrorMessage: string;
			translationService: TFunction
			explicitRulesCallback?: (
				fieldError: IFieldError,
				errorValidationRecords: Record<string, string | null>
			) => Record<string, string | null>;
		}
	): Record<string, string | null> | string =>
	{
		let errorRecords: Record<string, string | null> = {
			...errorValidationRecords
		};

		if (!error || !error.response)
		{
			return unexpectedErrorMessage;
		}
		else
		{
			const statusCode = error.response.status;

			if (statusCode >= 500 || statusCode === 404)
			{
				return unexpectedErrorMessage;
			}
			else if (statusCode >= 400)
			{
				const errors: IFieldError[] = [];

				if (
					!!error &&
					!!error.response &&
					!!error.response.data &&
					!!error.response.data.Errors &&
					!!error.response.data.Errors.length
				)
				{
					errors.push(...error.response.data.Errors);

					for (const e of errors)
					{
						const fieldName = Utils.pascalToCamel(e.Field);

						let message = translationService(`${e.ErrorCode}`);

						// if no mapped error then fallback and see if message is available in response
						if (!message)
						{
						// if api message then use that, otherwise fallback to unexpected error message
							if (!!e.Message)
							{
								message = e.Message;
							}
							else
							{
								message = unexpectedErrorMessage;
							}
						}

						// only update if fieldname exists in error entity so we map to actual field
						if (fieldName in errorRecords)
						{
							errorRecords[fieldName] = message;
						}

						// handle explicit rules through callback
						// if a rule was processed prior it will be overwritten if included
						// in callback function rule processing
						if (!!explicitRulesCallback)
						{
							errorRecords = {
								...explicitRulesCallback(e, errorRecords)
							};
						}
					}
				}
				else
				{
					return unexpectedErrorMessage;
				}
			}
		}
		return errorRecords;
	},

	isNullUndefined: (value: string | number | boolean | undefined | null): boolean =>
	{
		return value === null || value === undefined;
	},
	generateTempId: (): number =>
	{
		const randomValue = Math.floor(Math.random() * 1000000);

		return -randomValue;
	},

	areAllPropertiesPopulated: (obj: any): boolean =>
	{
		return Object.keys(obj).every((key) => obj[key]);
	},

	getLengthOfLongestArray: (arrayOfArrays: any[][]): number =>
	{
		let maxLength = 0;

		for (const array of arrayOfArrays)
		{
			if (array.length > maxLength)
			{
				maxLength = array.length;
			}
		}

		return maxLength;
	},

	getKeyAndLengthOfLongestArrayInDictionary: (dictionary: { [key: string]: any[]; }): { key: string; length: number } =>
	{
		const longest = {
			key: '',
			length: 0
		};

		if (!!dictionary)
		{
			const keys = Object.keys(dictionary);

			for (const key of keys)
			{
				if (dictionary[key].length > longest.length)
				{
					longest.key = key;
					longest.length = dictionary[key].length;
				}
			}
		}

		return longest;
	},

	timeout: async (milliseconds: number = 1000): Promise<void> =>
	{
		return new Promise((resolve) => setTimeout(resolve, milliseconds));
	},

	isFalsyValue: (value: any): boolean =>
	{
		return value === '' ||
			value === false ||
			value === 0;
	},

	add: (...numbers: (string | number | undefined | null)[]) =>
	{
		let sum = new Decimal(0);

		for (const num of numbers)
		{
			var cleansedValue = Utils.isValidNumber(`${num}`) ? num : 0;
			const decimalNum = new Decimal(cleansedValue || 0);
			sum = sum.plus(decimalNum);
		}

		return Number(sum);
	},

	areAllNullOrUndefined: (...numbers: (string|number|undefined|null)[]): boolean =>
	{
		return numbers.every((number) => number===undefined||number===null||number==='' );
	},

	getNumberValue: (value: string | number | undefined): number | undefined =>
	{
		return value !== undefined &&
				Utils.isValidNumber(`${value}`) ?
			+`${value}` :
			undefined;
	},

	getPropertyValue: (entity: any, property: string): any =>
	{
		const properties = property.split('.');

		return properties.reduce((acc, prop) =>
		{
			return acc ? acc[prop] : undefined;
		}, entity);
	},

	replaceTokens: (tokenizedString: string | undefined | null, ...params: string[]): string =>
	{
		if (!tokenizedString)
		{
			return '';
		}

		if (!params.length)
		{
			return tokenizedString;
		}

		return tokenizedString
			.replace(/{(\d+)}/g, (match, index) =>
			{
		  		return typeof params[index] !== 'undefined' ?
					params[index] :
					match;
			});
	},

	escapeHtml: (unsafe: string) =>
	{
		return unsafe
			.replace(/&/g, '&amp;')
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;')
			.replace(/"/g, '&quot;')
			.replace(/'/g, '&#039;');
	}
};