export enum ForeignTaxableIncomeTypes {

	TotalBookTaxAdjustments = 1,
	ForeignTaxableIncomeCalculated = 2,
	UnallocatedAdjustments = 3,
	TaxableIncomePerReturn = 4
}

export enum ForeignTaxableIncomeFieldNames {

	Amount = 'amount',
	Notes = 'notes',
	Reg5907Adjustments = 'reg5907Adjustments'
}