import { createBrowserRouter } from 'react-router-dom';
import HomePage from '../pages/Home/HomePage';
import UserPage from 'src/features/user/pages/UserPage';
import LoginPage from '../pages/Login/LoginPage';
import { ResourceNotFound } from 'src/common/components/ResourceNotFound/ResourceNotFound';
import { AccessDeniedPage } from '../pages/AccessDenied/AccessDeniedPage';
import EngagementPage from 'src/features/engagement/pages/EngagementPage';
import EngagementSetup from 'src/features/engagement/pages/EngagementSetup';
import { AffiliatePage } from 'src/features/engagement/pages/AffiliatePage/AffiliatePage';
import { UserAccessDenied } from 'src/features/user/pages/UserAccessDenied';
import {AppAuthorization} from './AppAuthorization';

export const router = createBrowserRouter([
	{
		path: '/',
		element: <AppAuthorization><HomePage/></AppAuthorization>
	},
	{
		path: '/user',
		element: <AppAuthorization><UserPage/></AppAuthorization>
	},
	{
		path: '/engagement/setup/:engagementId?',
		element: <AppAuthorization><EngagementSetup/></AppAuthorization>
	},
	{
		path: '/engagement/:engagementId',
		element: <AppAuthorization><EngagementPage/></AppAuthorization>
	},
	{
		path: '/engagement/:engagementId/affiliate/:affiliateId/:pageType/:pageId?',
		element: <AppAuthorization><AffiliatePage/></AppAuthorization>
	},
	{
		path: '/resource-not-found',
		element: <AppAuthorization><ResourceNotFound/></AppAuthorization>
	},
	{
		path: '/user-access-denied', //user has access to appliaction but not to specific pages
		element: <AppAuthorization><UserAccessDenied/></AppAuthorization>
	},
	{
		path: '/login',
		element: <LoginPage/>
	},
	{
		path: '/access-denied', //user hasn't access to FAE appliaction
		element: <AccessDeniedPage/>
	}
]);