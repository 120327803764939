import moment from 'moment';
import {
	IReg5907AdjustmentsYear
} from 'src/common/types/interfaces/IReg5907Adjustments';

export const Reg5907AdjustmentsHelpers = {

	/**
	 * Sort entities by date in descending order. Dates are in format of 'Sep 13, 2023'
	 * @param date1 entity for comparison
	 * @param date2 entity for comparison
	 */
	sortFAYear: (
		entity1: IReg5907AdjustmentsYear,
		entity2: IReg5907AdjustmentsYear
	): number =>
	{
		const date1 = moment(entity1.taxYearEnd);
		const date2 = moment(entity2.taxYearEnd);

		return date2.isBefore(date1) ? -1 : 1;
	}
};