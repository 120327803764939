import './Layout.scss';
import Header from './Header';

export const Layout = ({ children }: any) =>
{
	return (
		<div className="layout-container">
			<Header></Header>
			<div className="layout-body">
				<main className="fullWidth">{children}</main>
			</div>
		</div>
	);
};
