import { Utils } from 'src/common/utils/utils';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import { DividendReceivedFromOthersFields} from 'src/common/types/enums/DividendsReceivedFromOtherFields';
import { cloneDeep } from 'lodash';

export interface IDividendReceivedOthers {

	[DividendReceivedFromOthersFields.id]: number;
	[DividendReceivedFromOthersFields.engagementId]: number;
	[DividendReceivedFromOthersFields.affiliateId]: number;
	[DividendReceivedFromOthersFields.effectiveDate]?: Date;
	[DividendReceivedFromOthersFields.payerName]?: string;
	[DividendReceivedFromOthersFields.isPayerForeignAffiliate]?: boolean;
	[DividendReceivedFromOthersFields.payerCurrencyId]?: number;
	[DividendReceivedFromOthersFields.amount]?: number;
	[DividendReceivedFromOthersFields.paidOnEquityClassId]?: number;
	[DividendReceivedFromOthersFields.percentageOwned]?: number;
	[DividendReceivedFromOthersFields.dividendReceivedFrom]?: number;
	[DividendReceivedFromOthersFields.exemptSurplus]?: number;
	[DividendReceivedFromOthersFields.hybridSurplus]?: number;
	[DividendReceivedFromOthersFields.hybridUnderlyingTax]?: number;
	[DividendReceivedFromOthersFields.taxableSurplus]?: number;
	[DividendReceivedFromOthersFields.underlyingForeignTax]?: number;
	[DividendReceivedFromOthersFields.preAcquisitionSurplus]?: number;
	[DividendReceivedFromOthersFields.notes]?: string;
}

export class DividendReceivedOther implements IDividendReceivedOthers
{
	constructor(
		engagementId: number,
		affiliateId: number,
		currencyId?: number
	)
	{
		this[DividendReceivedFromOthersFields.id] = Utils.generateTempId();
		this[DividendReceivedFromOthersFields.engagementId] = engagementId;
		this[DividendReceivedFromOthersFields.affiliateId] = affiliateId;
		this[DividendReceivedFromOthersFields.effectiveDate] = undefined;
		this[DividendReceivedFromOthersFields.payerName]= '';
		this[DividendReceivedFromOthersFields.isPayerForeignAffiliate]= undefined;
		this[DividendReceivedFromOthersFields.payerCurrencyId]= currencyId;
		this[DividendReceivedFromOthersFields.amount]= undefined;
		this[DividendReceivedFromOthersFields.paidOnEquityClassId]= 0;
		this[DividendReceivedFromOthersFields.percentageOwned]= 100;
		this[DividendReceivedFromOthersFields.dividendReceivedFrom]= undefined;
		this[DividendReceivedFromOthersFields.exemptSurplus]= undefined;
		this[DividendReceivedFromOthersFields.hybridSurplus]= undefined;
		this[DividendReceivedFromOthersFields.hybridUnderlyingTax]= undefined;
		this[DividendReceivedFromOthersFields.taxableSurplus]= undefined;
		this[DividendReceivedFromOthersFields.underlyingForeignTax]= undefined;
		this[DividendReceivedFromOthersFields.preAcquisitionSurplus]= undefined;
		this[DividendReceivedFromOthersFields.notes]= undefined;
	}
	[DividendReceivedFromOthersFields.id]: number;
	[DividendReceivedFromOthersFields.engagementId]: number;
	[DividendReceivedFromOthersFields.affiliateId]: number;
	[DividendReceivedFromOthersFields.effectiveDate]?: Date;
	[DividendReceivedFromOthersFields.payerName]?: string;
	[DividendReceivedFromOthersFields.isPayerForeignAffiliate]?: boolean;
	[DividendReceivedFromOthersFields.payerCurrencyId]?: number | undefined;
	[DividendReceivedFromOthersFields.amount]?: number | undefined;
	[DividendReceivedFromOthersFields.paidOnEquityClassId]?: number | undefined;
	[DividendReceivedFromOthersFields.percentageOwned]?: number | undefined;
	[DividendReceivedFromOthersFields.dividendReceivedFrom]?: number | undefined;
	[DividendReceivedFromOthersFields.exemptSurplus]?: number | undefined;
	[DividendReceivedFromOthersFields.hybridSurplus]?: number | undefined;
	[DividendReceivedFromOthersFields.hybridUnderlyingTax]?: number | undefined;
	[DividendReceivedFromOthersFields.taxableSurplus]?: number | undefined;
	[DividendReceivedFromOthersFields.underlyingForeignTax]?: number | undefined;
	[DividendReceivedFromOthersFields.preAcquisitionSurplus]?: number | undefined;
	[DividendReceivedFromOthersFields.notes]?: string | undefined;

	static populateFields(dividend: IDividendReceivedOthers): IDividendReceivedOthers
	{
		const parsedDate = dateFormatter
			.utcToEst(dividend[DividendReceivedFromOthersFields.effectiveDate])!;

		return {
			...dividend,
			[DividendReceivedFromOthersFields.effectiveDate]: cloneDeep(parsedDate)
		};
	}
}