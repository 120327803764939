export enum DividendsReceivedFromFAFields {
	id = 'id',
	engagementId = 'engagementId',
	affiliateId = 'affiliateId',
	engagementAffiliateId = 'engagementAffiliateId',
	effectiveDate = 'effectiveDate',
	date = 'date',
	time = 'time',
	payerCurrencyId = 'payerCurrencyId',
	receiverCurrencyId ='receiverCurrencyId',
	payerName= 'payerName',
	paidOnEquityClass= 'equityClass',
	percentageOwned= 'percentageOwned',
	amountInCurrencyPaid= 'amountInCurrencyPaid',
	foreignExchangeRate= 'exchangeRate',
	amountInCurrencyReceived= 'amountInCurrencyReceived',
	actualAmount= 'actualAmount',
	withholdingTax= 'withholdingTax',
	notes = 'notes',
}

