import React, { useEffect, useState } from 'react';
import { DropdownControl } from '../DropdownControl';
import { SelectValue } from '@appkit4/react-components/esm/combobox/Combobox';
import { EarningOptionEnum } from 'src/common/types/enums/EarningOptionEnum';
import { useTranslation } from 'react-i18next';

const EarningOptionDropdown = (props: {value: string, field: string, onChange: (value: string) => void;}) =>
{
	const { t } = useTranslation(
		'input',
		{
			keyPrefix: 'earnings'
		}
	);

	const data = [
		{
			id: 0,
			name: t('basic')
		},
		{
			id: 1,
			name: t(`details-${props.field}`)
		}
	];

	const [value, setValue] = useState('');


	const onSelect = (value: SelectValue) =>
	{
		if (!!value && !!value.toString())
		{
			setValue(value.toString());
			props.onChange(value.toString());
		}
		else
		{
			setValue('');
			props.onChange('');
		}
	};

	useEffect(
		() =>
		{
			if (!!props.value)
			{
				setValue(props.value);
			}
			else
			{
				setValue(EarningOptionEnum.details.toString());
			}
		},
		[
			props.value
		]
	);

	return (
		<DropdownControl
			isSearchable={false}
			includeResetOption={false}
   		    options={data}
			placeholder={t('input')||''}
			// showTitleOnInput={true}
			 value={value}
			dropdownMatchWidth={false}
			onChange={onSelect}		/>
	);
};

export default EarningOptionDropdown;