import { Utils } from 'src/common/utils/utils';
import { AdjustedCostBaseFields } from '../enums/AdjustedCostBaseFields';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

export interface IAdjustedCostBase {

	[AdjustedCostBaseFields.id]: number;
	[AdjustedCostBaseFields.engagementId]: number;
	[AdjustedCostBaseFields.affiliateId]: number;
	[AdjustedCostBaseFields.effectiveDate]: Date;
	[AdjustedCostBaseFields.date]?: Date;
	[AdjustedCostBaseFields.time]?: Date;
	[AdjustedCostBaseFields.isAddition]?: boolean;
	[AdjustedCostBaseFields.isAutomaticEntry]?: boolean;
	[AdjustedCostBaseFields.transactionTypeId]?: number;
	[AdjustedCostBaseFields.description]?: string;
	[AdjustedCostBaseFields.shareholderId]?: number;
	[AdjustedCostBaseFields.equityClassId]?: number;
	[AdjustedCostBaseFields.currencyId]?: number;
	[AdjustedCostBaseFields.electionsAmount]?: number;
	[AdjustedCostBaseFields.shareholderEquityPercentage]?: number;
	[AdjustedCostBaseFields.amount]?: number;
	[AdjustedCostBaseFields.foreignExchangeRate]?: number;
	[AdjustedCostBaseFields.isPartnershipOwned]: boolean;
	[AdjustedCostBaseFields.partnershipName]?: string;
	[AdjustedCostBaseFields.partnershipCurrencyId]?: number;
	[AdjustedCostBaseFields.partnershipsEquityPercentage]?: number;
	[AdjustedCostBaseFields.noOfSharesAcquiredOrDisposed]?: number;
	[AdjustedCostBaseFields.notes]?: string;
}

export class AdjustedCostBase implements IAdjustedCostBase
{
	constructor(
		engagementId: number,
		affiliateId: number,
		currencyId?: number
	)
	{
		const parsedDate = moment()
			.set({
				hour: 0,
				minute: 1,
				second: 0,
				millisecond: 0
			})
			.toDate();

		this[AdjustedCostBaseFields.id] = Utils.generateTempId();
		this[AdjustedCostBaseFields.engagementId] = engagementId;
		this[AdjustedCostBaseFields.affiliateId] = affiliateId;
		this[AdjustedCostBaseFields.effectiveDate] = parsedDate;
		this[AdjustedCostBaseFields.date] = undefined;
		this[AdjustedCostBaseFields.time] = cloneDeep(parsedDate);
		this[AdjustedCostBaseFields.isAddition] = undefined;
		this[AdjustedCostBaseFields.isAutomaticEntry] = undefined;
		this[AdjustedCostBaseFields.transactionTypeId] = 0;
		this[AdjustedCostBaseFields.description] = undefined;
		this[AdjustedCostBaseFields.shareholderId] = 0;
		this[AdjustedCostBaseFields.equityClassId] = 0;
		this[AdjustedCostBaseFields.currencyId] = currencyId;
		this[AdjustedCostBaseFields.electionsAmount] = undefined;
		this[AdjustedCostBaseFields.shareholderEquityPercentage] = undefined;
		this[AdjustedCostBaseFields.amount] = undefined;
		this[AdjustedCostBaseFields.foreignExchangeRate] = undefined;
		this[AdjustedCostBaseFields.isPartnershipOwned] = false;
		this[AdjustedCostBaseFields.partnershipName] = undefined;
		this[AdjustedCostBaseFields.partnershipCurrencyId] = 0;
		this[AdjustedCostBaseFields.partnershipsEquityPercentage] = undefined;
		this[AdjustedCostBaseFields.noOfSharesAcquiredOrDisposed] = undefined;
		this[AdjustedCostBaseFields.notes] = undefined;
	}

	[AdjustedCostBaseFields.id]: number;
	[AdjustedCostBaseFields.engagementId]: number;
	[AdjustedCostBaseFields.affiliateId]: number;
	[AdjustedCostBaseFields.effectiveDate]: Date;
	[AdjustedCostBaseFields.date]?: Date;
	[AdjustedCostBaseFields.time]?: Date;
	[AdjustedCostBaseFields.isAddition]?: boolean;
	[AdjustedCostBaseFields.isAutomaticEntry]?: boolean;
	[AdjustedCostBaseFields.transactionTypeId]?: number;
	[AdjustedCostBaseFields.description]?: string;
	[AdjustedCostBaseFields.shareholderId]?: number;
	[AdjustedCostBaseFields.equityClassId]?: number;
	[AdjustedCostBaseFields.currencyId]?: number;
	[AdjustedCostBaseFields.electionsAmount]?: number;
	[AdjustedCostBaseFields.shareholderEquityPercentage]?: number;
	[AdjustedCostBaseFields.amount]?: number;
	[AdjustedCostBaseFields.foreignExchangeRate]?: number;
	[AdjustedCostBaseFields.isPartnershipOwned]: boolean;
	[AdjustedCostBaseFields.partnershipName]?: string;
	[AdjustedCostBaseFields.partnershipCurrencyId]?: number;
	[AdjustedCostBaseFields.partnershipsEquityPercentage]?: number;
	[AdjustedCostBaseFields.noOfSharesAcquiredOrDisposed]?: number;
	[AdjustedCostBaseFields.notes]?: string;

	static populateFields(adjustedCostBase: IAdjustedCostBase): IAdjustedCostBase
	{
		const parsedDate = dateFormatter
			.utcToEst(adjustedCostBase[AdjustedCostBaseFields.effectiveDate])!;

		return {
			...adjustedCostBase,
			[AdjustedCostBaseFields.effectiveDate]: cloneDeep(parsedDate),
			[AdjustedCostBaseFields.date]: cloneDeep(parsedDate),
			[AdjustedCostBaseFields.time]: cloneDeep(parsedDate)
		};
	}

	static isSame(acb?: IAdjustedCostBase, compareACB?: IAdjustedCostBase): boolean
	{
		const isDateSame = (date1?: Date, date2?: Date): boolean =>
		{
			return !!date1 && !!date2 ?
				moment(date1).isSame(date2) :
				!(
					(!!date1 && !date2) ||
					(!date1 && !!date2)
				);
		};

		return !!acb && !!compareACB &&
			acb[AdjustedCostBaseFields.id] === compareACB[AdjustedCostBaseFields.id] &&
			acb[AdjustedCostBaseFields.engagementId] === compareACB[AdjustedCostBaseFields.engagementId] &&
			acb[AdjustedCostBaseFields.affiliateId] === compareACB[AdjustedCostBaseFields.affiliateId] &&
			isDateSame(acb[AdjustedCostBaseFields.effectiveDate], compareACB[AdjustedCostBaseFields.effectiveDate]) &&
			isDateSame(acb[AdjustedCostBaseFields.date], compareACB[AdjustedCostBaseFields.date]) &&
			isDateSame(acb[AdjustedCostBaseFields.time], compareACB[AdjustedCostBaseFields.time]) &&
			acb[AdjustedCostBaseFields.isAddition] === compareACB[AdjustedCostBaseFields.isAddition] &&
			acb[AdjustedCostBaseFields.transactionTypeId] === compareACB[AdjustedCostBaseFields.transactionTypeId] &&
			acb[AdjustedCostBaseFields.description] === compareACB[AdjustedCostBaseFields.description] &&
			acb[AdjustedCostBaseFields.shareholderId] === compareACB[AdjustedCostBaseFields.shareholderId] &&
			acb[AdjustedCostBaseFields.equityClassId] === compareACB[AdjustedCostBaseFields.equityClassId] &&
			acb[AdjustedCostBaseFields.currencyId] === compareACB[AdjustedCostBaseFields.currencyId] &&
			acb[AdjustedCostBaseFields.electionsAmount] === compareACB[AdjustedCostBaseFields.electionsAmount] &&
			acb[AdjustedCostBaseFields.shareholderEquityPercentage] === compareACB[AdjustedCostBaseFields.shareholderEquityPercentage] &&
			acb[AdjustedCostBaseFields.amount] === compareACB[AdjustedCostBaseFields.amount] &&
			acb[AdjustedCostBaseFields.foreignExchangeRate] === compareACB[AdjustedCostBaseFields.foreignExchangeRate] &&
			acb[AdjustedCostBaseFields.isPartnershipOwned] === compareACB[AdjustedCostBaseFields.isPartnershipOwned] &&
			acb[AdjustedCostBaseFields.partnershipName] === compareACB[AdjustedCostBaseFields.partnershipName] &&
			acb[AdjustedCostBaseFields.partnershipCurrencyId] === compareACB[AdjustedCostBaseFields.partnershipCurrencyId] &&
			acb[AdjustedCostBaseFields.partnershipsEquityPercentage] === compareACB[AdjustedCostBaseFields.partnershipsEquityPercentage] &&
			acb[AdjustedCostBaseFields.noOfSharesAcquiredOrDisposed] === compareACB[AdjustedCostBaseFields.noOfSharesAcquiredOrDisposed] &&
			acb[AdjustedCostBaseFields.notes] === compareACB[AdjustedCostBaseFields.notes];
	}
}