import { useEffect } from 'react';
import { Layout } from '../../../common/components/Layout/Settings/Layout';
import { UserManagement } from '../components/UserManagement';
import { useAppSelector } from 'src/store/store';
import { RoleEnum } from 'src/common/types/enums/RoleEnum';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './UserPage.scss';

const UserPage = () =>
{
	const { t } = useTranslation('user');
	const userProfile = useAppSelector((state) => state.userProfile.profile);
	const navigate = useNavigate();

	useEffect(() =>
	{
		if (!!userProfile && userProfile?.role !== RoleEnum.BusinessOwner)
		{
			navigate('/user-access-denied');
		}
	},[userProfile]);

	return (
		<Layout>
			{userProfile?.role === RoleEnum.BusinessOwner &&
			<div className="user-page">
				<div className="title"> {t('title.user-management')}</div>
				<UserManagement></UserManagement>
			</div>}
		</Layout>
	);
};

export default UserPage;
