import { useContext, useEffect, useRef, useState } from 'react';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { GridSeperator } from 'src/common/components/GridSeperator/GridSeperator';
import { INameId } from 'src/common/types/interfaces/INameId';
import { Utils } from 'src/common/utils/utils';
import { Button, Notification } from '@appkit4/react-components';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { SaveProgressBar } from 'src/common/components/SaveProgressBar/SaveProgressBar';
import { NotificationService } from 'src/core/services/notificationService';
import { useTranslation } from 'react-i18next';
import { DeleteModal } from 'src/common/components/DeleteModal/DeleteModal';

import {
	RecordsByGridType,
	ValuesByAffiliateId as ValuesByAffiliateTaxYearEndId,
	ValuesByDescriptionYear,
	ValuesByYear,
	gridIds,
	initialRecordData,
	initialRowData
} from './BookToForeignTaxableIncomeConstants';

import {
	ForeignTaxableIncomeLookupTypes,
	ForeignTaxableIncomeRow,
	ForeignTaxableIncomeSubmissionToken,
	IForeignTaxableIncomeLookupRecord,
	IForeignTaxableIncomeRow,
	IForeignTaxableIncomeSubmission,
	IForeignTaxableIncomeYear,
	IForeignTaxableIncomeYearSubmission
} from 'src/common/types/interfaces/IForeignTaxableIncome';

import './BookToForeignTaxableIncome.scss';
import { BookToForeignTaxableIncomeHelpers } from './BookToForeignTaxableIncomeHelpers';
import { ForeignTaxableIncomeHeaderTable } from 'src/common/components/ForeignTaxableIncomeGrid/components/ForeignTaxableIncomeHeaderTable';
import { ForeignTaxableIncomeGrid } from 'src/common/components/ForeignTaxableIncomeGrid/ForeignTaxableIncomeGrid';
import { ForeignTaxableIncomeFieldNames } from 'src/common/types/enums/ForeignTaxableIncomeTypes';
import { ForeignTaxableIncomeAggregateRows }
	from 'src/common/components/ForeignTaxableIncomeGrid/components/ForeignTaxableIncomeAggregateRows';
import { ForeignTaxableIncomePropertyRow }
	from 'src/common/components/ForeignTaxableIncomeGrid/components/ForeignTaxableIncomePropertyRow/ForeignTaxableIncomePropertyRow';
import AffiliateContext from 'src/common/components/SharedDataContext/AffiliateContext';

const BookToForeignTaxableIncome = (props: {handleChange?: (change: boolean) => void}) =>
{
	const affiliateContext = useContext(AffiliateContext);

	const [isLoading, setIsLoading] = useState(true);
	const [isSaving, setIsSaving] = useState(false);
	const [isValidating, setIsValidating] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isNoFAHistory, setIsNoFAHistory] = useState(false);

	const [displayNotes, setDisplayNotes] = useState(false);

	const [hasChange, setHasChange] = useState(false);


	const [gridData, setGridData] = useState<IForeignTaxableIncomeYear[]>([]);

	const [initialData, setInitialData] = useState<{ [gridId: string]: IForeignTaxableIncomeRow[][] }>(initialRowData);
	const [permanentData, setPermanentData] = useState<IForeignTaxableIncomeRow[][]>([]);
	const [temporaryData, setTemporaryData] = useState<IForeignTaxableIncomeRow[][]>([]);
	const [otherAdjustmentsData, setOtherAdjustmentsData] = useState<IForeignTaxableIncomeRow[][]>([]);

	const [initialNetIncomeData, setInitialNetIncomeData] = useState<IForeignTaxableIncomeRow[]>([]);
	const [initialTaxableIncomeFiledData, setInitialTaxableIncomeFiledData] = useState<IForeignTaxableIncomeRow[]>([]);
	const [netIncomeRow, setNetIncomeRow] = useState<IForeignTaxableIncomeRow[]>([]);
	const [taxableIncomeFiledRow, setTaxableIncomeFiledRow] = useState<IForeignTaxableIncomeRow[]>([]);
	const [IncomeLossAfterAdjustmentsRow, setIncomeLossAfterAdjustmentsRow] = useState<IForeignTaxableIncomeRow[]>([]);

	const [permanentDescriptionOptions, setPermanentDescriptionOptions] = useState<INameId[]>([]);
	const [temporaryDescriptionOptions, setTemporaryDescriptionOptions] = useState<INameId[]>([]);
	const [otherAdjustmentsDescriptionOptions, setOtherAdjustmentsDescriptionOptions] = useState<INameId[]>([]);
	const [netIncomeDescriptionOption, setNetIncomeDescriptionOption] = useState<INameId | undefined>(undefined);
	const [taxableIncomeFiledDescriptionOption, setTaxableIncomeFiledDescriptionOption] = useState<INameId | undefined>(undefined);
	const [incomeLossAfterAdjustmentsDescriptionOption, setIncomeLossAfterAdjustmentsDescriptionOption] =
	 useState<INameId | undefined>(undefined);


	const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
	const [selectedRowDeletion, setSelectedRowDeletion] = useState<IForeignTaxableIncomeRow[]>([]);

	const { t } = useTranslation(
		'input',
		{
			keyPrefix: 'foreignTaxableIncome'
		}
	);

	const tableRefs = Array.from(
		{
			length: Object.keys(gridIds).length
		},
		// eslint-disable-next-line react-hooks/rules-of-hooks
		() => useRef<HTMLDivElement>(null)
	);

	const createKey = (...keys: (string | number)[]): string =>
	{
		return keys
			.map((k) => `${k}`)
			.join('::');
	};

	const splitKey = (key: string): string[] =>
	{
		return key.split('::');
	};

	const getKey = (key: string, indexToRetrieve: number): string =>
	{
		if (!!key)
		{
			const keys = splitKey(key);

			if (!!keys && !!keys.length && indexToRetrieve in keys)
			{
				return keys[indexToRetrieve];
			}
		}

		return '';
	};

	const getRecordsByRows = (
		allRecords: IForeignTaxableIncomeYear[],
		valuesByDescriptionYear: ValuesByDescriptionYear
	): IForeignTaxableIncomeRow[][] =>
	{
		const allRows: IForeignTaxableIncomeRow[][] = [];

		let rowContinuationIndex = 0;

		if (!!valuesByDescriptionYear)
		{
			const descriptionKeys = Object.keys(valuesByDescriptionYear);

			// loop for every year to determine which array for a given year is longest and fill values for each
			for (const descriptionKey of descriptionKeys)
			{
				const longestArray = Utils.getKeyAndLengthOfLongestArrayInDictionary(
					valuesByDescriptionYear[descriptionKey]
				);

				// get array of longest and seperate from remainder of records.
				// this will be the basis on which the remainder of records are built
				// the longest array already has the maximum number of records, so need to fill remainder of records
				const longestYearRecords = [
					...valuesByDescriptionYear[descriptionKey][longestArray.key]
				];

				const yearRecordsExcludingLongest: ValuesByYear = {
				};

				const yearKeys = Object.keys(valuesByDescriptionYear[descriptionKey]);

				for (const yearKey of yearKeys)
				{
					if (yearKey !== longestArray.key)
					{
						yearRecordsExcludingLongest[yearKey] = valuesByDescriptionYear[descriptionKey][yearKey];
					}
				}

				// fill remainder of records for each year that does not have the full amount of records
				for (const yearKey of Object.keys(yearRecordsExcludingLongest))
				{
					// get affiliate year field for entity by year (assume it will always be found)
					const yearEntity = allRecords
						.find((r) => createKey(r.taxYearEnd, r.affiliateTaxYearEndId) === yearKey)!;

					if (yearRecordsExcludingLongest[yearKey].length < longestYearRecords.length)
					{
						const remainingCount = longestYearRecords.length - yearRecordsExcludingLongest[yearKey].length;

						for (let i = 0; i < remainingCount; i++)
						{
							yearRecordsExcludingLongest[yearKey]
								.push(
									new ForeignTaxableIncomeRow({
										descriptionId: +getKey(descriptionKey, 0),
										taxYearEnd: yearEntity.taxYearEnd,
										affiliateTaxYearEndId: yearEntity.affiliateTaxYearEndId,
										calculatingCurrencyId: yearEntity.calculatingCurrencyId,
										rowIndex: +getKey(descriptionKey, 1)
									})
								);
						}
					}
				}

				// concat the longest and remaining back together for processing into row records
				const allRecordsByYear = {
					[longestArray.key]: longestYearRecords,
					...yearRecordsExcludingLongest
				};

				// add the filler records (while tracking position of rows for each new iteration of description)
				for (let i = 0; i < longestArray.length; i++)
				{
					for (const year of yearKeys)
					{
						if ((allRows.length - 1) < (i + rowContinuationIndex))
						{
							allRows.push([]);
						}

						allRows[(i + rowContinuationIndex)].push(allRecordsByYear[year][i]);
					}
				}

				rowContinuationIndex += longestArray.length;
			}
		}

		return allRows;
	};

	const getPropertyRecords = (
		records: any[],
		description: INameId,
		token: string
	): IForeignTaxableIncomeRow[] =>
	{
		const propertyRows: IForeignTaxableIncomeRow[] = [];

		if (!!records && !!records.length)
		{
			// get values by year (if exist). Assumed records are sorted by date desc already
			for (const record of records)
			{
				if (
					!!record &&
					!!record[token]
				)
				{
					propertyRows.push({
						...record[token],
						affiliateTaxYearEndId: record.affiliateTaxYearEndId,
						calculatingCurrencyId: record.calculatingCurrencyId,
						taxYearEnd: record.taxYearEnd
					});
				}
				else
				{
					propertyRows.push(
						new ForeignTaxableIncomeRow({
							affiliateTaxYearEndId: record.affiliateTaxYearEndId,
							calculatingCurrencyId: record.calculatingCurrencyId,
							rowIndex: 1,
							taxYearEnd: record.taxYearEnd,
							descriptionId: !!description ?
								description.id :
								undefined
						})
					);
				}
			}
		}

		return propertyRows;
	};


	const loadForeignTaxableIncome = async (
		engagementId: number,
		affiliateId: number,
		netIncomeDescription: INameId,
		taxableIncomeFiledDescription: INameId,
		incomeLossAfterAdjustmentsDescription: INameId
	): Promise<void> =>
	{
		try
		{
			const response = await engagementService
				.getForeignTaxableIncome(engagementId, affiliateId);

			const records: IForeignTaxableIncomeYear[] = !!response.data && !!response.data.result ?
				response.data.result.sort(BookToForeignTaxableIncomeHelpers.sortFAYear) :
				[];

			if (!!records && !!records.length)
			{
				const permanentDescriptions: number[] = [];
				const temporaryDescriptions: number[] = [];
				const otherAdjustmentsDescriptions: number[] = [];

				// get all the unique descriptions across years for each type of amount
				const filterDescriptions = (
					records: IForeignTaxableIncomeRow[],
					uniqueDescriptions: number[]
				): number[] =>
				{
					if (
						!!records &&
						!!records.length
					)
					{
						return records
							.filter((d) => !uniqueDescriptions.some((ud) => ud === d.descriptionId))
							.map((d) => d.descriptionId);
					}

					return [];
				};

				for (const r of records)
				{
					permanentDescriptions.push(
						...filterDescriptions(
							r[ForeignTaxableIncomeSubmissionToken.PermanentDifferences],
							permanentDescriptions
						)
					);
					temporaryDescriptions.push(
						...filterDescriptions(
							r[ForeignTaxableIncomeSubmissionToken.TemporaryDifferences],
							temporaryDescriptions
						)
					);
					otherAdjustmentsDescriptions.push(
						...filterDescriptions(
							r[ForeignTaxableIncomeSubmissionToken.OtherAdjustments],
							otherAdjustmentsDescriptions
						)
					);
				}

				const getValuesByDescriptionRowIndexYear = (
					valuesByDescriptionYearStart: ValuesByDescriptionYear,
					rows: IForeignTaxableIncomeRow[],
					year: string,
					affiliateTaxYearEndId: number,
					calculatingCurrencyId: number
				): ValuesByDescriptionYear =>
				{
					const valuesByDescriptionYear: ValuesByDescriptionYear = {
						...valuesByDescriptionYearStart
					};

					if (!!rows && !!rows.length)
					{
						for (const row of rows)
						{
							const allYearsBase: { [year: string]: [] } = {
							};

							records.forEach((r) =>
							{
								allYearsBase[createKey(r.taxYearEnd, r.affiliateTaxYearEndId)] = [];
							});

							const indexKey = createKey(row.descriptionId, row.rowIndex!);

							if (!valuesByDescriptionYear[indexKey])
							{
								valuesByDescriptionYear[indexKey] = {
								};
							}

							const yearKey = createKey(year, affiliateTaxYearEndId);
							if (!valuesByDescriptionYear[indexKey][yearKey])
							{
								valuesByDescriptionYear[indexKey] = {
									...allYearsBase
								};
							}

							valuesByDescriptionYear[indexKey][yearKey]
								.push({
									...row,
									notes: !!row.notes ? row.notes : undefined,
									taxYearEnd: year,
									affiliateTaxYearEndId: affiliateTaxYearEndId,
									calculatingCurrencyId: calculatingCurrencyId
								});
						}
					}

					return valuesByDescriptionYear;
				};

				// build income/expense/adjustments records
				let permanentValuesByDescriptionYear: ValuesByDescriptionYear = {
				};
				let temporaryValuesByDescriptionYear: ValuesByDescriptionYear = {
				};
				let otherAdjustmentsValuesByDescriptionYear: ValuesByDescriptionYear = {
				};


				// for every description there will be an entity (if none exists then empty)
				for (const record of records)
				{
					permanentValuesByDescriptionYear = {
						...getValuesByDescriptionRowIndexYear(
							permanentValuesByDescriptionYear,
							record[ForeignTaxableIncomeSubmissionToken.PermanentDifferences],
							record.taxYearEnd,
							record.affiliateTaxYearEndId,
							record.calculatingCurrencyId
						)
					};
					temporaryValuesByDescriptionYear = {
						...getValuesByDescriptionRowIndexYear(
							temporaryValuesByDescriptionYear,
							record[ForeignTaxableIncomeSubmissionToken.TemporaryDifferences],
							record.taxYearEnd,
							record.affiliateTaxYearEndId,
							record.calculatingCurrencyId
						)
					};
					otherAdjustmentsValuesByDescriptionYear = {
						...getValuesByDescriptionRowIndexYear(
							otherAdjustmentsValuesByDescriptionYear,
							record[ForeignTaxableIncomeSubmissionToken.OtherAdjustments],
							record.taxYearEnd,
							record.affiliateTaxYearEndId,
							record.calculatingCurrencyId
						)
					};
				}

				const permanent = getRecordsByRows(records, permanentValuesByDescriptionYear);
				const temporary = getRecordsByRows(records, temporaryValuesByDescriptionYear);
				const otherAdjustments = getRecordsByRows(records, otherAdjustmentsValuesByDescriptionYear);
				const netIncome = getPropertyRecords(records, netIncomeDescription, ForeignTaxableIncomeSubmissionToken.NetIncome);
				// const taxableIncomeFiled = getPropertyRecords
				// (records, netIncomeDescription, ForeignTaxableIncomeSubmissionToken.NetIncome);
				const taxableIncomeFiled =
				getPropertyRecords(records, taxableIncomeFiledDescription, ForeignTaxableIncomeSubmissionToken.TaxableIncomeFiled);
				const incomeLossAfterAdjustments = getPropertyRecords(records,
					incomeLossAfterAdjustmentsDescription,
					ForeignTaxableIncomeSubmissionToken.IncomeLossAfterAdjustments);

				// set all data in state, including starting values
				setInitialData({
					[gridIds.permanentDifferences]: cloneDeep(permanent),
					[gridIds.temporaryDifferences]: cloneDeep(temporary),
					[gridIds.otherAdjustments]: cloneDeep(otherAdjustments)
				});
				setInitialNetIncomeData(cloneDeep(netIncome));
				setInitialTaxableIncomeFiledData(cloneDeep(taxableIncomeFiled));

				setPermanentData([...permanent]);
				setTemporaryData([...temporary]);
				setOtherAdjustmentsData([...otherAdjustments]);
				setNetIncomeRow([...netIncome]);
				setTaxableIncomeFiledRow([...taxableIncomeFiled]);
				setIncomeLossAfterAdjustmentsRow([...incomeLossAfterAdjustments]);

				setGridData([...records]);
			}
			else
			{
				setIsNoFAHistory(true);
			}
		}
		catch (error)
		{
			triggerErrorNotification(
				t('loadingIncomeError')
			);
		}
		finally
		{
			setIsLoading(false);
		}
	};

	const resetAndLoadData = async (): Promise<void> =>
	{
		setIsLoading(true);
		setIsSaving(false);
		setIsSubmitted(false);
		setIsValidating(false);
		setHasChange(false);
		!!props.handleChange && props.handleChange(false);

		setInitialData({
			...initialRowData
		});
		setInitialNetIncomeData([]);
		setInitialNetIncomeData([]);

		setGridData([]);
		setPermanentData([]);
		setTemporaryData([]);
		setNetIncomeRow([]);
		setTaxableIncomeFiledRow([]);
		setIncomeLossAfterAdjustmentsRow([]);

		if (!!affiliateContext&&
			!!affiliateContext?.engagementDetail.id&&
			!!affiliateContext?.affiliateDetail.affiliateId)
		{
			await loadForeignTaxableIncome(
				affiliateContext?.engagementDetail.id,
				affiliateContext?.affiliateDetail.affiliateId,
			netIncomeDescriptionOption!,
			taxableIncomeFiledDescriptionOption!,
			incomeLossAfterAdjustmentsDescriptionOption!
			);
		}
	};

	const filterLookups = (
		allLookups: IForeignTaxableIncomeLookupRecord[], // todo: need to update once entity is known
		category: ForeignTaxableIncomeLookupTypes
	): INameId[] =>
	{
		return allLookups
			.filter((l) => l.differenceType === category)
			.map((l) => ({
				id: l.id,
				name: l.description
			}))
			.sort();
	};

	const loadLookups = async (
	): Promise<{
		permanentOptions: INameId[],
		temporaryOptions: INameId[];
		otherAdjustmentsOptions: INameId[];
		netIncomeOption: INameId | undefined;
		taxableIncomeFiledOption: INameId |undefined;
		incomeLossAfterAdjustmentsOption: INameId | undefined;
	}> =>
	{
		try
		{
			const response = await engagementService
				.getForeignTaxableIncomeLookups();

			const allLookups: IForeignTaxableIncomeLookupRecord[] = !!response.data &&
			!!response.data.result &&
			!!response.data.result.length ?
				response.data.result :
				[];

			const netIncomeLookups = filterLookups(allLookups, ForeignTaxableIncomeLookupTypes.NetIncome);
			const netIncomeOption = netIncomeLookups[0];
			setNetIncomeDescriptionOption(netIncomeOption);

			const taxableIncomeFiledLookups = filterLookups(allLookups, ForeignTaxableIncomeLookupTypes.TaxableIncomeFiled);
			const taxableIncomeFiledOption = taxableIncomeFiledLookups[0];
			setTaxableIncomeFiledDescriptionOption(taxableIncomeFiledOption);

			const incomeLossAfterAdjustmentsLookups = filterLookups(allLookups, ForeignTaxableIncomeLookupTypes.IncomeLossAfterAdjustments);
			const incomeLossAfterAdjustmentsOption = incomeLossAfterAdjustmentsLookups[0];
			setIncomeLossAfterAdjustmentsDescriptionOption(incomeLossAfterAdjustmentsOption);

			const permanentOptions = [
				...filterLookups(allLookups, ForeignTaxableIncomeLookupTypes.Permanent)
			];
			setPermanentDescriptionOptions(permanentOptions);

			const temporaryOptions = [
				...filterLookups(allLookups, ForeignTaxableIncomeLookupTypes.Temporary)
			];
			setTemporaryDescriptionOptions([
				...filterLookups(allLookups, ForeignTaxableIncomeLookupTypes.Temporary)
			]);

			const otherAdjustmentsOptions = [
				...filterLookups(allLookups, ForeignTaxableIncomeLookupTypes.OtherAdjustments)
			];
			setOtherAdjustmentsDescriptionOptions([
				...filterLookups(allLookups, ForeignTaxableIncomeLookupTypes.OtherAdjustments)
			]);

			return {
				permanentOptions,
				temporaryOptions,
				otherAdjustmentsOptions,
				netIncomeOption,
				taxableIncomeFiledOption,
				incomeLossAfterAdjustmentsOption
			};
		}
		catch (error)
		{
			triggerErrorNotification(
				t('loadingLookupsError')
			);

			return {
				permanentOptions: [],
				temporaryOptions: [],
				otherAdjustmentsOptions: [],
				netIncomeOption: undefined,
				taxableIncomeFiledOption: undefined,
				incomeLossAfterAdjustmentsOption: undefined
			};
		}
	};

	const updateDescriptionFields = (
		startingData: IForeignTaxableIncomeRow[][],
		rowIndex: number,
		value: number
	): IForeignTaxableIncomeRow[][]  =>
	{
		const updatedRows = cloneDeep(startingData);
		const rowData = cloneDeep(updatedRows[rowIndex]);

		rowData.forEach((d) =>
		{
			d.descriptionId = value;
		});

		updatedRows[rowIndex] = [...rowData];

		return updatedRows;
	};

	const updateDescription = (
		id: string,
		rowIndex: number,
		value: number
	): void =>
	{
		if (id === gridIds.permanentDifferences)
		{
			setPermanentData([...updateDescriptionFields(permanentData, rowIndex, value)]);
		}
		else if (id === gridIds.temporaryDifferences)
		{
			setTemporaryData([...updateDescriptionFields(temporaryData, rowIndex, value)]);
		}
		else if (id === gridIds.otherAdjustments)
		{
			setOtherAdjustmentsData([...updateDescriptionFields(otherAdjustmentsData, rowIndex, value)]);
		}
	};

	const updateValueFields = (
		startingData: IForeignTaxableIncomeRow[][],
		fieldName: ForeignTaxableIncomeFieldNames,
		rowIndex: number,
		index: number,
		value: any
	): IForeignTaxableIncomeRow[][] =>
	{
		const updatedRows = cloneDeep(startingData);
		const yearContent = cloneDeep(updatedRows[rowIndex][index]);

		if (fieldName === ForeignTaxableIncomeFieldNames.Amount)
		{
			yearContent[fieldName] = Utils.isValidNumber(value) ?
				+value :
				undefined;
		}
		else if (fieldName === ForeignTaxableIncomeFieldNames.Notes)
		{
			yearContent[fieldName] = !!value ? value : undefined;
		}
		else if (fieldName === ForeignTaxableIncomeFieldNames.Reg5907Adjustments)
		{
			yearContent[fieldName] = !!value ? value : undefined;
		}

		updatedRows[rowIndex][index] = yearContent;

		return updatedRows;
	};

	const updateIncome = (
		startingData: IForeignTaxableIncomeRow[],
		fieldName: ForeignTaxableIncomeFieldNames,
		index: number,
		value: string
	): IForeignTaxableIncomeRow[] =>
	{
		const updatedRows = cloneDeep(startingData);
		const yearContent = cloneDeep(updatedRows[index]);

		if (fieldName === ForeignTaxableIncomeFieldNames.Amount)
		{
			yearContent[fieldName] = Utils.isValidNumber(value) ?
				+value :
				undefined;
		}
		else if (fieldName === ForeignTaxableIncomeFieldNames.Notes)
		{
			yearContent[fieldName] = !!value ? value : undefined;
		}

		updatedRows[index] = yearContent;

		return updatedRows;
	};

	const calculateIncomeLossAfterAdjustments = (records: IForeignTaxableIncomeRow[][], index: number, taxableIncomeFiled: number) =>
	{
		let sum = 0;
		for (const record of records)
		{
			const val = record[index].amount??0;
			sum = sum + val;
		}

		sum = sum + taxableIncomeFiled;

		setIncomeLossAfterAdjustmentsRow([
			...updateIncome(
				IncomeLossAfterAdjustmentsRow,
				ForeignTaxableIncomeFieldNames.Amount,
				index,
				sum.toString()
			)
		]);
	};

	const updateValue = (
		id: string,
		fieldName: ForeignTaxableIncomeFieldNames,
		rowIndex: number,
		index: number,
		value: string
	): void =>
	{
		if (id === gridIds.permanentDifferences)
		{
			setPermanentData([
				...updateValueFields(
					permanentData,
					fieldName,
					rowIndex,
					index,
					value
				)
			]);
		}
		else if (id === gridIds.temporaryDifferences)
		{
			setTemporaryData([
				...updateValueFields(
					temporaryData,
					fieldName,
					rowIndex,
					index,
					value
				)
			]);
		}
		else if (id === gridIds.otherAdjustments)
		{
			const updatedOtherAdjustments = [
				...updateValueFields(
					otherAdjustmentsData,
					fieldName,
					rowIndex,
					index,
					value
				)
			];
			setOtherAdjustmentsData(updatedOtherAdjustments);
			calculateIncomeLossAfterAdjustments(updatedOtherAdjustments, index, taxableIncomeFiledRow[index].amount??0);
		}
		else if (id === gridIds.netIncomeHeader)
		{
			setNetIncomeRow([
				...updateIncome(
					netIncomeRow,
					fieldName,
					index,
					value
				)
			]);
		}
		else if (id === gridIds.taxableIncomeFiled)
		{
			setTaxableIncomeFiledRow([
				...updateIncome(
					taxableIncomeFiledRow,
					fieldName,
					index,
					value
				)
			]);
			calculateIncomeLossAfterAdjustments(otherAdjustmentsData, index, value?Number(value):0);
		}
	};

	const debouncedUpdateValue = debounce(updateValue, 400);

	const onAddRow = (gridId: string): void =>
	{
		const addNewRow = (
			startingData: IForeignTaxableIncomeRow[][]
		): IForeignTaxableIncomeRow[][] =>
		{
			const updatedRows = cloneDeep(startingData);

			const newRow: IForeignTaxableIncomeRow[] = [];
			const rowIndex = Utils.generateTempId();
			for (const data of gridData)
			{
				newRow.push(
					new ForeignTaxableIncomeRow({
						taxYearEnd: data.taxYearEnd,
						affiliateTaxYearEndId: data.affiliateTaxYearEndId,
						calculatingCurrencyId: data.calculatingCurrencyId,
						rowIndex: rowIndex
					})
				);
			}

			updatedRows.push(newRow);
			return updatedRows;
		};

		if (gridId === gridIds.permanentDifferences)
		{
			setPermanentData([...addNewRow(permanentData)]);
		}
		else if (gridId === gridIds.temporaryDifferences)
		{
			setTemporaryData([...addNewRow(temporaryData)]);
		}
		else if (gridId === gridIds.otherAdjustments)
		{
			setOtherAdjustmentsData([...addNewRow(otherAdjustmentsData)]);
		}
	};

	const checkChange = (
		initialData: IForeignTaxableIncomeRow[][],
		updatedData: IForeignTaxableIncomeRow[][]
	): boolean =>
	{
		const newRows: IForeignTaxableIncomeRow[][] = [];
		const existingRows: IForeignTaxableIncomeRow[][] = [];

		for (const row of updatedData)
		{
			if (row.every((record) => record.id! < 0))
			{
				newRows.push([...row]);
			}
			else
			{
				existingRows.push([...row]);
			}
		}

		const newRecordChanges = (rows: IForeignTaxableIncomeRow[][]) =>
		{
			return rows
				.some((row) =>
				{
					return row.some((record) =>
					{
						return !!Utils.isValidNumber(`${record.amount}`) ||
							!!record.notes;
					});
				});
		};

		// check if different number of rows and if new rows have any valid changes for submission
		if (
			initialData.length !== updatedData.length &&
			!!newRecordChanges(newRows)
		)
		{
			return true;
		}

		// check if entities are same
		if (!isEqual(initialData, existingRows))
		{
			return true;
		}

		return false;
	};

	const checkSingleRowChange = (
		initialData: IForeignTaxableIncomeRow[],
		updatedData: IForeignTaxableIncomeRow[]
	) =>
	{
		return !isEqual(initialData, updatedData);
	};

	const hasChanges = (): boolean =>
	{
		const changed = checkChange(initialData[gridIds.permanentDifferences], permanentData) ||
			checkChange(initialData[gridIds.temporaryDifferences], temporaryData) ||
			checkChange(initialData[gridIds.otherAdjustments], otherAdjustmentsData) ||
			checkSingleRowChange(initialNetIncomeData, netIncomeRow)||
			checkSingleRowChange(initialTaxableIncomeFiledData, taxableIncomeFiledRow);

		setHasChange(!!changed);
		!!props.handleChange && props.handleChange(!!changed);
		return changed;
	};

	const validateRow = (
		initialRowsById: { [id: number]: IForeignTaxableIncomeRow },
		updatedRow: IForeignTaxableIncomeRow[],
		startingRecordsByYear?: ValuesByAffiliateTaxYearEndId
	): [boolean, ValuesByYear] =>
	{
		let isValid = true;

		let recordsByYear: ValuesByAffiliateTaxYearEndId = {
		};

		if (!!startingRecordsByYear)
		{
			recordsByYear = cloneDeep(startingRecordsByYear);
		}
		else
		{
			gridData
				.forEach((d) =>
				{
					recordsByYear[d.affiliateTaxYearEndId] = [];
				});
		}

		for (let j = 0; j < updatedRow.length; j++)
		{
			const record = updatedRow[j];

			if (
				!record.descriptionId &&
				(
					!!Utils.isValidNumber(`${record.amount}`) ||
					!!record.notes
				)
			)
			{
				isValid = false;
				break;
			}

			// check if record exists in initial list (if not then consider record to be new)
			// check that the record in question is different than original state and add to list for update
			// deleted records do not need to be considered as delete will update the initial comparison list
			if (
				(
					record.id! <= 0 &&
					(
						!!Utils.isValidNumber(`${record.amount}`) ||
						!!record.notes
					)
				) ||
				(
					record.id! > 0 &&
					!isEqual(record, initialRowsById[record.id!])
				)
			)
			{
				recordsByYear[record.affiliateTaxYearEndId].push(record);
			}
		}

		return [isValid, recordsByYear];
	};

	const isValidRows = (
		initialDataRows: IForeignTaxableIncomeRow[][],
		updatedData: IForeignTaxableIncomeRow[][]
	): [boolean, ValuesByYear] =>
	{
		// flatten initial data rows for quick comparison
		const flattenedInitialRowsById: { [id: number]: IForeignTaxableIncomeRow } = {
		};

		initialDataRows
			.flat()
			.concat()
			.forEach((record) =>
			{
				flattenedInitialRowsById[record.id!] = record;
			});


		let recordsByYear: ValuesByAffiliateTaxYearEndId = {
		};

		gridData
			.forEach((d) =>
			{
				recordsByYear[d.affiliateTaxYearEndId] = [];
			});


		// iterate each row and each record to check validity
		// positionally every entity is the same in the rows/records,
		// so can use indexing for matching of records,
		// and if index cannot be found then can be considered a new row (will use id to determine)
		let isValid = true;

		for (let i = 0; i < updatedData.length && isValid; i++)
		{
			const updatedRow = updatedData[i];

			[isValid, recordsByYear] = validateRow(
				flattenedInitialRowsById,
				updatedRow,
				recordsByYear
			);
		}

		return [isValid, recordsByYear];
	};

	const isValid = (): [boolean, RecordsByGridType] =>
	{
		const dataToUpdate = {
			...initialRecordData
		};

		const [isPermanentValid, permanentRecordsToUpdate] = isValidRows(initialData[gridIds.permanentDifferences], permanentData);
		dataToUpdate[gridIds.permanentDifferences] = permanentRecordsToUpdate;

		if (!isPermanentValid)
		{
			return [isPermanentValid, dataToUpdate];
		}

		const [isTemporaryValid, temporaryRecordsToUpdate] = isValidRows(initialData[gridIds.temporaryDifferences], temporaryData);
		dataToUpdate[gridIds.temporaryDifferences] = temporaryRecordsToUpdate;

		if (!isTemporaryValid)
		{
			return [isTemporaryValid, dataToUpdate];
		}

		const [isOtherAdjustmentValid, otherAdjustmentRecordsToUpdate] =
		isValidRows(initialData[gridIds.otherAdjustments], otherAdjustmentsData);
		dataToUpdate[gridIds.otherAdjustments] = otherAdjustmentRecordsToUpdate;

		if (!isOtherAdjustmentValid)
		{
			return [isOtherAdjustmentValid, dataToUpdate];
		}

		const flattenedInitialRowsById: { [id: number]: IForeignTaxableIncomeRow } = {
		};

		initialNetIncomeData
			.forEach((record) =>
			{
				flattenedInitialRowsById[record.id!] = record;
			});

		const [isNetIncomeValid, netIncomeRecordsToUpdate] = validateRow(flattenedInitialRowsById, netIncomeRow);
		dataToUpdate[gridIds.netIncomeHeader] = netIncomeRecordsToUpdate;

		if (!isNetIncomeValid)
		{
			return [isNetIncomeValid, dataToUpdate];
		}

		initialTaxableIncomeFiledData
			.forEach((record) =>
			{
				flattenedInitialRowsById[record.id!] = record;
			});

		const [isTaxableIncomeFiledValid, taxableIncomeFiledRecordsToUpdate] =
		validateRow(flattenedInitialRowsById, taxableIncomeFiledRow);
		dataToUpdate[gridIds.taxableIncomeFiled] = taxableIncomeFiledRecordsToUpdate;

		if (!isTaxableIncomeFiledValid)
		{
			return [isTaxableIncomeFiledValid, dataToUpdate];
		}

		return [true, dataToUpdate];
	};

	const transformSubmissionEntities = (
		records: IForeignTaxableIncomeYearSubmission[],
		recordsByAffiliate: ValuesByAffiliateTaxYearEndId,
		submissionToken: ForeignTaxableIncomeSubmissionToken
	): IForeignTaxableIncomeYearSubmission[] =>
	{
		for (const affiliateIdKey of Object.keys(recordsByAffiliate))
		{
			if (
				!!recordsByAffiliate[+affiliateIdKey] &&
				!!recordsByAffiliate[+affiliateIdKey].length
			)
			{
				let index = records.findIndex((r) => r.affiliateTaxYearEndId === +affiliateIdKey);
				if (index < 0)
				{
					const initial: IForeignTaxableIncomeYearSubmission = {
						affiliateTaxYearEndId: +affiliateIdKey,
						[ForeignTaxableIncomeSubmissionToken.PermanentDifferences]: [],
						[ForeignTaxableIncomeSubmissionToken.TemporaryDifferences]: [],
						[ForeignTaxableIncomeSubmissionToken.OtherAdjustments]: [],
						[ForeignTaxableIncomeSubmissionToken.NetIncome]: undefined,
						[ForeignTaxableIncomeSubmissionToken.TaxableIncomeFiled]: undefined
					};

					// set the index based on the length of the newly updated array
					index = records.push({
						...initial
					}) - 1;
				}

				const submissionRecords = recordsByAffiliate[+affiliateIdKey]
					.map((r) =>
					{
						return {
							descriptionId: r.descriptionId,
							id: !!r.id && r.id >= 0 ?
								r.id :
								undefined,
							amount: r.amount,
							notes: r.notes,
							reg5907Adjustments: r.reg5907Adjustments,
							rowIndex: r.rowIndex
						};
					});

				// add to list based on existing index
				records[index] = {
					...records[index],
					[submissionToken]: !(submissionToken === ForeignTaxableIncomeSubmissionToken.NetIncome ||
					submissionToken === ForeignTaxableIncomeSubmissionToken.TaxableIncomeFiled)?
						submissionRecords :
						!!submissionRecords && !!submissionRecords.length ?
							submissionRecords[0] :
							undefined
				};
			}
		}

		return records;
	};

	const stopSaveProgressBar = async (): Promise<void> =>
	{
		await Utils.timeout(500);
		setIsSaving(false);
	};

	const onDeleteRowWarning = (selectedRow: IForeignTaxableIncomeRow[]): void =>
	{
		setSelectedRowDeletion(selectedRow);
		setDisplayDeleteModal(true);
	};

	const onDelete = async (): Promise<void> =>
	{
		if (!!affiliateContext&&
			!!affiliateContext?.engagementDetail.id&&
			!!affiliateContext?.affiliateDetail.affiliateId)
		{
			try
			{
				const firstDeletionEntity = selectedRowDeletion[0];

				if (!!firstDeletionEntity.descriptionId)
				{
					await engagementService
						.deleteForeignTaxableIncomeRow(
						affiliateContext?.engagementDetail.id!,
						affiliateContext?.affiliateDetail.affiliateId!,
						firstDeletionEntity.descriptionId,
						firstDeletionEntity.rowIndex!
						);
				}


				setDisplayDeleteModal(false);
				setSelectedRowDeletion([]);

				triggerDeleteSuccessNotification();
				resetAndLoadData();
			}
			catch (error)
			{
				triggerErrorNotification(
					t('deleteError')
				);
			}
		}
	};

	const onSave = async (): Promise<void> =>
	{
		if (!!affiliateContext&&
			!!affiliateContext?.engagementDetail.id&&
			!!affiliateContext?.affiliateDetail.affiliateId)
		{
			try
			{
				setIsValidating(true);
				setIsSubmitted(true);

				if (!!hasChanges())
				{
					const [isValidRecords, recordsByType] = isValid();

					if (!!isValidRecords)
					{
						setIsSaving(true);

						let updatedRecords: IForeignTaxableIncomeYearSubmission[] = [];

						updatedRecords = transformSubmissionEntities(
							updatedRecords,
							recordsByType[gridIds.netIncomeHeader],
							ForeignTaxableIncomeSubmissionToken.NetIncome
						);

						updatedRecords = transformSubmissionEntities(
							updatedRecords,
							recordsByType[gridIds.taxableIncomeFiled],
							ForeignTaxableIncomeSubmissionToken.TaxableIncomeFiled
						);

						updatedRecords = transformSubmissionEntities(
							updatedRecords,
							recordsByType[gridIds.permanentDifferences],
							ForeignTaxableIncomeSubmissionToken.PermanentDifferences
						);

						updatedRecords = transformSubmissionEntities(
							updatedRecords,
							recordsByType[gridIds.temporaryDifferences],
							ForeignTaxableIncomeSubmissionToken.TemporaryDifferences
						);

						updatedRecords = transformSubmissionEntities(
							updatedRecords,
							recordsByType[gridIds.otherAdjustments],
							ForeignTaxableIncomeSubmissionToken.OtherAdjustments
						);

						const submissionData: IForeignTaxableIncomeSubmission = {
							engagementId: affiliateContext?.engagementDetail.id!,
							affiliateId: affiliateContext?.affiliateDetail.affiliateId!,
							foreignTaxableIncomes: [...updatedRecords]
						};

						await engagementService.updateForeignTaxableIncome(submissionData);

						triggerSuccessNotification();

						await stopSaveProgressBar();

						resetAndLoadData();
					}
					else
					{
						triggerWarningNotification();
					}
				}
			}
			catch (error)
			{
				triggerErrorNotification(
					t('saveError')
				);

				await stopSaveProgressBar();
			}
			finally
			{
				setIsValidating(false);
			}
		}
	};

	const onRenderSaveButton = (): JSX.Element =>
	{
		return <Button
			kind={'primary'}
			disabled={
				isLoading ||
				isSaving ||
				isValidating ||
				!hasChange
			}
			onClick={onSave}
		>
			{
				t('saveButtonText')
			}
		</Button>;
	};

	const triggerWarningNotification = async (): Promise<void> =>
	{
		const ele = (
			<Notification
			  message={t('validationWarning')}
			  status={'warning'}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};

	const triggerErrorNotification = async (message: string): Promise<void> =>
	{
		const ele = (
			<Notification
			  message={message}
			  status={'error'}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};

	const triggerSuccessNotification = async (): Promise<void> =>
	{
		const ele = (
			<Notification
			  message={t('successMessage')}
			  status={'success'}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};

	const triggerDeleteSuccessNotification = async (): Promise<void> =>
	{
		const ele = (
			<Notification
			  message={t('deleteSuccessMessage')}
			  status={'success'}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};

	const triggerValueUpdate = (
		gridId: string,
		fieldName: ForeignTaxableIncomeFieldNames,
		rowIndex: number,
		index: number,
		value: string
	) =>
	{
		debouncedUpdateValue(gridId, fieldName, rowIndex, index, value);
	};

	const loadData = async (
		engagementId: number,
		affiliateId: number
	): Promise<void> =>
	{
		const options = await loadLookups();

		await loadForeignTaxableIncome(
			engagementId,
			affiliateId,
			options.netIncomeOption!,
			options.taxableIncomeFiledOption!,
			options.incomeLossAfterAdjustmentsOption!

		);
	};

	useEffect(
		() =>
		{
			setIsLoading(true);

			if (
				!!affiliateContext&&
			!!affiliateContext?.engagementDetail.id&&
			!!affiliateContext?.affiliateDetail.affiliateId
			)
			{
				loadData(affiliateContext?.engagementDetail.id,
					affiliateContext?.affiliateDetail.affiliateId);
			}
		},
		[affiliateContext]
	);

	useEffect(
		() =>
		{
			const handleScroll = (index: number) => (event: any) =>
			{
				tableRefs
					.forEach((ref, i) =>
					{
						if (i !== index && ref.current)
						{
							ref.current.scrollLeft = event.target.scrollLeft;
						}
					});
			};

			tableRefs
				.forEach((ref, index) =>
				{
					if (!!ref.current)
					{
						ref.current.addEventListener('scroll', handleScroll(index));
					}
				});


			return () =>
			{
				tableRefs
					.forEach((ref, index) =>
					{
						if (!!ref.current)
						{
							ref.current.removeEventListener('scroll', handleScroll(index));
						}
					});
			};
		},
		[]
	);

	useEffect(
		() =>
		{
			hasChanges();
		},
		[
			permanentData,
			temporaryData,
			otherAdjustmentsData,
			netIncomeRow,
			taxableIncomeFiledRow
		]
	);

	return <>
		{
			!isNoFAHistory &&
			<div
				className={'forein-taxable-income'}
				style={{
					width: isLoading ? '100%' : 'inherit'
				}}
			>
				<div className={'action-buttons'}>
					{
						onRenderSaveButton()
					}
				</div>
				<div>
					<SaveProgressBar
						display={isSaving}
						width={
							!!permanentData &&
							!!permanentData.length ?
								`${permanentData[0].length * (300 + (!!displayNotes ? 50 : 0)) + 345}px` :
								undefined
						}
						message={t('saveInProgressMessage') || ''}
					/>
				</div>
				<div className={'grid-container'}>
					<div>
						<ForeignTaxableIncomeHeaderTable
							id={gridIds.netIncomeHeader}
							customRef={tableRefs[0]}
							data={gridData}
							netIncomeRow={netIncomeRow}
							isLoading={isLoading}
							isDisabled={isValidating || isSaving}
							validate={isSubmitted}
							onToggleNotes={(isNotesDisplayed) =>
							{
								setDisplayNotes(isNotesDisplayed);
							}}
							onValueUpdate={(fieldName, index, value) =>
							{
								triggerValueUpdate(gridIds.netIncomeHeader, fieldName, 0, index, value);
							}}
						/>
					</div>
					<div>
						<ForeignTaxableIncomePropertyRow
							id={gridIds.netIncomeHeader}
							customRef={tableRefs[1]}
							title={t('netIncome')}
							data={gridData}
							propertyRow={netIncomeRow}
							isLoading={isLoading}
							isDisabled={isValidating || isSaving}
							isReadOnly={false}
							validate={isSubmitted}
							displayNotes={displayNotes}
							onValueUpdate={(fieldName, index, value) =>
							{
								triggerValueUpdate(gridIds.netIncomeHeader, fieldName, 0, index, value);
							}}
						/>
					</div>
					{
						!isLoading &&
						<GridSeperator />
					}
					<div>
						<ForeignTaxableIncomeGrid
							id={gridIds.permanentDifferences}
							isLoading={isLoading}
							isDisabled={isValidating || isSaving}
							validate={isSubmitted}
							displayNotes={displayNotes}
							customRef={tableRefs[2]}
							rowData={permanentData}
							gridData={gridData}
							descriptionOptions={permanentDescriptionOptions}
							title={t('permanentGridTitle')}
							titleTooltip={t('permanentGridTitleTooltip')||''}
							addRowButtonText={t('permanentGridAddButtonText')}
							onRowAdd={() =>
							{
								onAddRow(gridIds.permanentDifferences);
							}}
							onDescriptionUpdate={(rowIndex, value) =>
							{
								updateDescription(gridIds.permanentDifferences, rowIndex, value);
							}}
							onValueUpdate={(fieldName, rowIndex, index, value) =>
							{
								triggerValueUpdate(gridIds.permanentDifferences, fieldName, rowIndex, index, value);
							}}
							onDeleteRow={onDeleteRowWarning}
						/>
					</div>
					{
						!isLoading &&
						<GridSeperator />
					}
					<div>
						<ForeignTaxableIncomeGrid
							id={gridIds.temporaryDifferences}
							isLoading={isLoading}
							isDisabled={isValidating || isSaving}
							validate={isSubmitted}
							displayNotes={displayNotes}
							customRef={tableRefs[3]}
							rowData={temporaryData}
							gridData={gridData}
							descriptionOptions={temporaryDescriptionOptions}
							title={t('temporaryGridTitle')}
							titleTooltip={t('temporaryGridTitleTooltip')||''}
							addRowButtonText={t('temporaryGridAddButtonText')}
							onRowAdd={() =>
							{
								onAddRow(gridIds.temporaryDifferences);
							}}
							onDescriptionUpdate={(rowIndex, value) =>
							{
								updateDescription(gridIds.temporaryDifferences, rowIndex, value);
							}}
							onValueUpdate={(fieldName, rowIndex, index, value) =>
							{
								triggerValueUpdate(gridIds.temporaryDifferences, fieldName, rowIndex, index, value);
							}}
							onDeleteRow={onDeleteRowWarning}
						/>
					</div>
					{
						!isLoading &&
						<GridSeperator />
					}
				 <div>
						<ForeignTaxableIncomeAggregateRows
							id={gridIds.aggregateTable}
							customRef={tableRefs[4]}
							isLoading={isLoading}
							displayNotes={displayNotes}
							permanent={permanentData}
							temporary={temporaryData}
							netIncome={netIncomeRow}
							taxableIncomeFiled={taxableIncomeFiledRow}
							allYearData={gridData}
							onValueUpdate={(fieldName, index, value) =>
							{
								triggerValueUpdate(gridIds.taxableIncomeFiled, fieldName, 0, index, value);
							}}
						/>
					</div>
					{
						!isLoading &&
						<GridSeperator />
					}
					<div>
						<ForeignTaxableIncomeGrid
							id={gridIds.otherAdjustments}
							isLoading={isLoading}
							isDisabled={isValidating || isSaving}
							validate={isSubmitted}
							displayNotes={displayNotes}
							customRef={tableRefs[5]}
							rowData={otherAdjustmentsData}
							gridData={gridData}
							descriptionOptions={otherAdjustmentsDescriptionOptions}
							title={t('otherAdjustmentsGridTitle')}
							addRowButtonText={t('otherAdjustmentsGridAddButtonText')}
							onRowAdd={() =>
							{
								onAddRow(gridIds.otherAdjustments);
							}}
							onDescriptionUpdate={(rowIndex, value) =>
							{
								updateDescription(gridIds.otherAdjustments, rowIndex, value);
							}}
							onValueUpdate={(fieldName, rowIndex, index, value) =>
							{
								triggerValueUpdate(gridIds.otherAdjustments, fieldName, rowIndex, index, value);
							}}
							onDeleteRow={onDeleteRowWarning}
						/>
					</div>

					{
						!isLoading &&
						<GridSeperator />
					}
					<div className="income-loss-after-adjustments">
						<ForeignTaxableIncomePropertyRow
							displayScrollbar
							id={gridIds.incomeLossAfterAdjustments}
							customRef={tableRefs[6]}
							title={t('foreignTaxableIncomeAfterAdjustments')}
							data={gridData}
							isLoading={isLoading}
							propertyRow={IncomeLossAfterAdjustmentsRow}
							isReadOnly={true}
							isDisabled={isValidating || isSaving}
							validate={isSubmitted}
							displayNotes={displayNotes}
							onValueUpdate={(fieldName, index, value) =>
							{}}
						/>
					</div>

				</div>
				<div className={'action-buttons'}>
					{
						onRenderSaveButton()
					}
				</div>
			</div>
		}
		{
			!!isNoFAHistory &&
			<div>
				{
					t('noFAHistory')
				}
			</div>
		}
		<DeleteModal
			visible={displayDeleteModal}
			title={t('deleteModalTitle')}
			deleteMessage={t('deleteMessage')}
			setVisible={setDisplayDeleteModal}
			onDelete={onDelete}
		/>
	</>;
};

export default BookToForeignTaxableIncome;