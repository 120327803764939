import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import userProfileReducer from '../store/reducers/userProfileSlice';
import AppSettingsReducer from '../store/reducers/AppSettingsSlice';
import notificationReducer from '../store/reducers/notificationSlice';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

const reducer = combineReducers({
	userProfile: userProfileReducer,
	appSettings: AppSettingsReducer,
	notifications: notificationReducer
});

const store = configureStore({
	reducer
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
