import { IDescriptionColumnProps } from './IDescriptionColumnProps';
import { Tooltip } from '@appkit4/react-components';

import './DescriptionColumn.scss';

export function DescriptionColumn(props: IDescriptionColumnProps): JSX.Element
{
	return <>
		<td className={`description-column ${props.index === 0 ? 'first' : ''}`}>
			<span className={`${!!props.rowInformation.isBold ? 'bold' : ''}`}>
				{
				 `${props.rowInformation.name} `
				}{props.rowInformation.lineItemConfiguration?.editable&&
					props.rowInformation.lineItemConfiguration?.inline&&
					props.rowInformation.lineItemConfiguration?.required&&
					<span className="required-field">*</span>}
			</span>
			{
				!!props.rowInformation.tooltip &&
				<Tooltip
					distance={4}
					position={'top'}
					appendAfterTarget
					content={props.rowInformation.tooltip}
				>
					<span className={'Appkit4-icon icon-help-question-outline'} />
				</Tooltip>
			}
		</td>
	</>;
}