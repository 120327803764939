import { Modal } from '@appkit4/react-components';
import TermsOfService from './TermsOfService';

const TermsofServiceModal = (props: {
  visible: boolean;
  termsOfService: boolean;
  privacyPolicy: boolean;
  onClose: any;
}) =>
{
	const close = () =>
	{
		props.onClose();
	};

	return (
		<Modal
			visible={props.visible}
			title={''}
			ariaLabel={'modal demo'}
			onCancel={() => props.onClose()}
			maskCloseable={false}
			closable={false}
			closeOnPressEscape={false}
			modalStyle={{
				width: '40%',
				height: '560px'
			}}
			header={''}
			bodyStyle={{
				height: '100%'
			}}
		>
			<TermsOfService
				onContinue={close}
				termsOfService={props.termsOfService}
				privacyPolicy={props.privacyPolicy}
			></TermsOfService>
		</Modal>
	);
};

export default TermsofServiceModal;
