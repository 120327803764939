import { CalendarPicker } from '@appkit4/react-components';
import React, { useEffect, useRef } from 'react';

interface CalendarPickerWrapperProps {
    id?: string;
    style?: React.CSSProperties;
    className?: string;
    onChange?: any;
    onSelect?: any;
    onValueChange?: any;
    placeholder?: string;
    editable?: boolean;
    disabled?: boolean;
    showOtherMonths?: boolean;
    selectRange?: boolean;
    locale?: string;
    format?: string;
    rangeConnecterFormat?: string;
    defaultValue?: any;
    value?: any;
    maxDate?: Date;
    minDate?: Date;
    fieldTitle?: string;
    rangeMenuItems?: any;
    mode?: any;
    disabledDates?: Array<Date>;
    required?: boolean;
    readonly?: boolean;
    disabledRanges?: Array<[Date, Date]>;
    disabledDays?: Array<number>;
    firstDayOfWeek?: number;
    autoClose?: boolean;
    onClickCalendarIcon?: Function;
    dateSeparator?: string;
    fieldWidth?: number | string;
    hideTitleOnInput?: boolean;
    datepickerAlwaysDown?: boolean;
    datePanelHorizontalAlign?: string;
    name?: string;
    useCustomValidation?: boolean;
    error?: boolean;
    customErrorNode?: string | React.ReactNode;
    dataMode?: string;
	showClearIcon?: boolean;
    suffixTemplate?: (value: any) => React.ReactNode;
}

export const CalendarPickerWrapper = (props: CalendarPickerWrapperProps ) =>
{
	const calendarPickerWrapperRef = useRef<HTMLDivElement|null>(null);
	const calendarPickerRef = useRef();

	const clearDate = () =>
	{
		if (!!props.onChange)
		{
			props.onChange(undefined);
		}
	};

	useEffect(() =>
	{
		function handleClickoutside(event: MouseEvent)
		{
			const calendarIcon = (event.target as HTMLElement).closest(
				'.icon-calendar-outline, .icon-circle-delete-outline'
			);
			if (!calendarIcon)
			{
				(calendarPickerRef.current as any).triggerOpen(true);
			}
		}
		if (calendarPickerWrapperRef.current)
		{
			calendarPickerWrapperRef.current.addEventListener('click', handleClickoutside);
		}

		return () =>
		{
			if (calendarPickerWrapperRef.current)
			{
				calendarPickerWrapperRef.current.removeEventListener('click', handleClickoutside);
			}
		};
	}, []);


	return (
		<div ref={calendarPickerWrapperRef}  className='calendar-wrapper'>
			<CalendarPicker
				ref={calendarPickerRef}
				{...props}
			/>
			<div className="appending-icon">
				{!!props.showClearIcon && !!props.value ? (
					<>
						<i
							className="Appkit4-icon icon-circle-delete-outline "
							 onClick={clearDate}
						></i>
						<div className="separte-line" />
					</>
				) : (
					<div className="whitespace">{''}</div>
				)}
			</div>
		</div>
	);
};
