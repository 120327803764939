import { useEffect, useState } from 'react';
import { Utils } from 'src/common/utils/utils';
import { useTranslation } from 'react-i18next';

import './ForeignTaxableIncomeAggregateRows.scss';
import { ForeignTaxableIncomeFieldNames, ForeignTaxableIncomeTypes } from 'src/common/types/enums/ForeignTaxableIncomeTypes';
import { IForeignTaxableIncomeRow } from 'src/common/types/interfaces/IForeignTaxableIncome';
import { IForeignTaxableIncomeAggregateRowsProps } from './IForeignTaxableIncomeAggregateRowsProps';
import { NumericInputControl } from 'src/common/components/GridControls';
import { WarningModal } from 'src/common/components/WarningModal/WarningModal';

interface ISummationBase {
	[ForeignTaxableIncomeTypes.TotalBookTaxAdjustments]: number|undefined;
	[ForeignTaxableIncomeTypes.ForeignTaxableIncomeCalculated]: number|undefined;
	[ForeignTaxableIncomeTypes.UnallocatedAdjustments]: number|undefined;
	[ForeignTaxableIncomeTypes.TaxableIncomePerReturn]: number|undefined;
}

const summationBase: ISummationBase = {
	[ForeignTaxableIncomeTypes.TotalBookTaxAdjustments]: undefined,
	[ForeignTaxableIncomeTypes.ForeignTaxableIncomeCalculated]: undefined,
	[ForeignTaxableIncomeTypes.UnallocatedAdjustments]: undefined,
	[ForeignTaxableIncomeTypes.TaxableIncomePerReturn]: undefined
};

export function ForeignTaxableIncomeAggregateRows(props: IForeignTaxableIncomeAggregateRowsProps): JSX.Element
{
	const [summationByYear, setSummationByYear] = useState<ISummationBase[]>([]);

	const { t } = useTranslation(
		'common',
		{
			keyPrefix: 'foreignTaxableIncomeAggregateRows'
		}
	);

	const [showWarningModal, setShowWarningModal] =
    useState<boolean>(false);


	const sumRecordsByYear = (
		records: IForeignTaxableIncomeRow[][],
		netIncomes: IForeignTaxableIncomeRow[],
		taxableIncomeFileds: IForeignTaxableIncomeRow[]
	): ISummationBase[] =>
	{
		const sumsByYear: ISummationBase[] = [];

		if ((!!records && !!records.length)||!!netIncomes.length)
		{
			// iterate each and sum all records based on array position
			// index in row records represents years ordered
			if (!!records.length)
			{
				for (const row of records)
				{
					if (!!row.length)
					{
						for (let i = 0; i < row.length; i++)
						{
							if ((sumsByYear.length - 1) < i)
							{
								sumsByYear.push({
									...summationBase
								});
							}

							const amount: number|undefined = row[i].amount;

							const currentValue = sumsByYear[i][ForeignTaxableIncomeTypes.TotalBookTaxAdjustments];

							sumsByYear[i][ForeignTaxableIncomeTypes.TotalBookTaxAdjustments] =
							Utils.areAllNullOrUndefined(row[i].amount,currentValue) ? undefined : Utils.add(currentValue, amount);
						}
					}
				}
			}

			// sum for net income after adjustments has been calculated
			for (let i = 0; i < netIncomes.length; i++)
			{
				if ((sumsByYear.length - 1) < i)
				{
					sumsByYear.push({
						...summationBase
					});
				}

				const netIncomeAmount: number|undefined = !!Utils.isValidNumber(`${netIncomes[i].amount}`) ?
					netIncomes[i].amount : undefined;
				const taxableIncomeFiledAmount: number|undefined = !!Utils.isValidNumber(`${taxableIncomeFileds[i].amount}`) ?
					taxableIncomeFileds[i].amount : undefined;

				const adjustment: number|undefined = sumsByYear[i][ForeignTaxableIncomeTypes.TotalBookTaxAdjustments];

				sumsByYear[i][ForeignTaxableIncomeTypes.TotalBookTaxAdjustments] =
				Utils.areAllNullOrUndefined(netIncomes[i].amount,adjustment) ? undefined :
					(adjustment??0);

				sumsByYear[i][ForeignTaxableIncomeTypes.ForeignTaxableIncomeCalculated] =
				Utils.areAllNullOrUndefined(netIncomes[i].amount,adjustment) ? undefined :
				 Utils.add(netIncomes[i].amount,adjustment);

				sumsByYear[i][ForeignTaxableIncomeTypes.TaxableIncomePerReturn] = taxableIncomeFileds[i].amount;

				sumsByYear[i][ForeignTaxableIncomeTypes.UnallocatedAdjustments] =
				Utils.areAllNullOrUndefined(netIncomes[i].amount,taxableIncomeFiledAmount,adjustment) ? undefined :
					Utils.add(taxableIncomeFiledAmount , -1* (netIncomeAmount??0), -1*(adjustment??0));
			}
		}
		else
		{
			const years = !!props.allYearData.length ?
				props.allYearData.map((d) => d.taxYearEnd) :
				[];

			if (!!years && !!years.length)
			{
				for (let i = 0; i < years.length; i++)
				{
					sumsByYear.push({
						...summationBase,
						[ForeignTaxableIncomeTypes.TaxableIncomePerReturn]: taxableIncomeFileds[i].amount
					});
				}
			}
		}

		return sumsByYear;
	};

	useEffect(
		() =>
		{
			if (
				!!props.permanent &&
				!!props.temporary
			)
			{
				const sumsByYear: ISummationBase[] = sumRecordsByYear(
					props.permanent.concat(props.temporary),
					props.netIncome,
					props.taxableIncomeFiled
				);

				setSummationByYear(sumsByYear);
			}
		},
		[
			props.permanent,
			props.temporary,
			props.netIncome,
			props.taxableIncomeFiled
		]
	);

	const closeWarning = () =>
	{
		setShowWarningModal(false);
	};

	return <div className={'foreign-taxable-income-aggregate-rows'}>
		<div
			ref={props.customRef}
			className={'table-scroll-container'}
			style={{
				visibility: props.isLoading ? 'hidden' : 'visible'
			}}
		>
			<table id={props.id}>
				<tbody>
					<tr>
						<td className={'action-column'} />
						<td className={'first-column'}>
							{
								t('totalBookTaxAdjustments')
							}
						</td>
						{
							!!summationByYear &&
						summationByYear
							.map((s) =>
							{
								return <>
									<td className={`content-column  amount-cell ${!!props.displayNotes ? 'has-notes' : ''}`}>
										{
										  !Utils.isNullUndefined(s[ForeignTaxableIncomeTypes.TotalBookTaxAdjustments])?
												Utils.formatCurrency(`${s[ForeignTaxableIncomeTypes.TotalBookTaxAdjustments]}`, 2):''
										}
									</td>
									<td className={`content-column ${!!props.displayNotes ? 'has-notes' : ''}`}>
									</td>
								</>;
							})
						}
					</tr>
					<tr>
						<td className={'action-column'} />
						<td className={'first-column'}>
							{
								t('foreignTaxableIncomeCalculated')
							}
						</td>
						{
							!!summationByYear &&
						summationByYear
							.map((s) =>
							{
								return <>
									<td className={` content-column  amount-cell ${!!props.displayNotes ? 'has-notes' : ''}`}>
										<div className='warning-cell'>{
											s[ForeignTaxableIncomeTypes.ForeignTaxableIncomeCalculated]!==undefined?
												Utils.formatCurrency(`${s[ForeignTaxableIncomeTypes.ForeignTaxableIncomeCalculated]}`, 2):''
										}
										{(s[ForeignTaxableIncomeTypes.TaxableIncomePerReturn]||
										s[ForeignTaxableIncomeTypes.ForeignTaxableIncomeCalculated])&&
											s[ForeignTaxableIncomeTypes.TaxableIncomePerReturn]!==
										s[ForeignTaxableIncomeTypes.ForeignTaxableIncomeCalculated]?
											<div className='warning-icon'
												onClick={() => setShowWarningModal(true)}
											>
												<span className="Appkit4-icon icon-alert-fill"></span>
											</div>:<></>
										}
										</div>
									</td>
									<td className={`content-column ${!!props.displayNotes ? 'has-notes' : ''}`}>
									</td>
								</>;
							})
						}
					</tr>
					<tr>
						<td className={'action-column'} />
						<td className={'first-column'}>
							{
								t('unallocatedAdjustments')
							}
						</td>
						{
							!!summationByYear &&
							summationByYear
								.map((s) =>
								{
									return <>
										<td className={`content-column  amount-cell ${!!props.displayNotes ? 'has-notes' : ''}`}>
											{
												s[ForeignTaxableIncomeTypes.UnallocatedAdjustments]!==undefined?
													Utils.formatCurrency(`${s[ForeignTaxableIncomeTypes.UnallocatedAdjustments]}`, 2):''
											}
										</td>
										<td className={`content-column ${!!props.displayNotes ? 'has-notes' : ''}`}>
										</td>
									</>;
								})
						}
					</tr>
					<tr>
						<td className={'action-column'} />
						<td className={'first-column'}>
							{
								t('taxableIncomePerReturn')
							}
						</td>
						{
							!!summationByYear &&
						summationByYear
							.map((s, index) =>
							{
								return <>
									<td className="boundary amount-cell amount-type ">
										<div className="warning-cell">
											<NumericInputControl
												value={s[ForeignTaxableIncomeTypes.TaxableIncomePerReturn]}
												onValueChange={(value) =>
												{
													props.onValueUpdate(ForeignTaxableIncomeFieldNames.Amount, index, value);
												}}
											/>
											{(s[ForeignTaxableIncomeTypes.TaxableIncomePerReturn]||
											s[ForeignTaxableIncomeTypes.ForeignTaxableIncomeCalculated])&&
											s[ForeignTaxableIncomeTypes.TaxableIncomePerReturn]!==
											s[ForeignTaxableIncomeTypes.ForeignTaxableIncomeCalculated]?
												<div className='warning-icon'
													onClick={() => setShowWarningModal(true)}
												>
													<span className="Appkit4-icon icon-alert-fill"></span>
												</div>:<></>}
										</div>
									</td>
									<td className={`content-column ${!!props.displayNotes ? 'has-notes' : ''}`}>
									</td>
								</>;
							})
						}
					</tr>
				</tbody>
			</table>
			<WarningModal
				visible={showWarningModal}
				title={t('warning-title')}
				showWarningIcon={true}
				content={t('warning-content')}
				onClose={closeWarning}
			/>
		</div>
	</div>;
}