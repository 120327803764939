import { Input } from '@appkit4/react-components';
import { InputError } from '../../ModalControls/InputError/InputError';
import { INumericFormControlProps } from './INumericFormControlProps';
import { Utils } from 'src/common/utils/utils';
import { RegularPatternEnum } from 'src/common/types/enums/RegularPatternEnum';
import { useState } from 'react';

export function NumericFormControl(props: INumericFormControlProps): JSX.Element
{
	const [isDisplayOnly, setIsDisplayOnly] = useState(true);

	const onChange = (value: string): void =>
	{
		const regPattern = new RegExp(props.format??RegularPatternEnum.Integer);
		if (!regPattern.test(value))
		{
			return;
		}

		if (!!props.onChange)
		{
			props.onChange(
				props.field,
				value
			);
		}
	};

	const onFocus = () =>
	{
		setIsDisplayOnly(false);
	};

	const onBlur = () =>
	{
		setIsDisplayOnly(true);
	};

	return <>
		<div>
			<Input
				type={'text'}
				required={!!props.isRequired}
				disabled={!!props.isDisabled}
				readonly={!!props.isReadonly}
				title={props.title}
				value={
					!Utils.isNullUndefined(props.value) ?
						!!isDisplayOnly && !props.isNumericFormatted ?
							Utils.formatCurrency(`${props.value}`, 2) :
							`${props.value}` :
						''
				}
				error={!!props.isError}
				onChange={onChange}
				onBlur={onBlur}
				onFocus={onFocus}
			/>
			<InputError
				isError={!!props.isError}
				message={props.errorMessage}
				onRenderMessage={props.onRenderErrorMessage}
			/>
		</div>
	</>;
}