import { Button, Modal } from '@appkit4/react-components';
import { useTranslation } from 'react-i18next';
import './DeleteModal.scss';

export const DeleteModal = (props: {
	visible: boolean,
	title: string,
	deleteMessage: string,
	primaryMessage?: string,
	showWarningIcon?: boolean,
	setVisible: (visible: boolean) => void,
	onDelete: () => void}) =>
{
	const { t } = useTranslation('common');
	return (
		<>
			<Modal
				className="delete-generic-modal"
				visible={props.visible}
				initialFocus={false}
				closeOnPressEscape={false}
				maskCloseable={false}
				title={props.title}
				header={!!props.showWarningIcon && <span className="Appkit4-icon icon-alert-fill"
					style={{
						marginRight: '5px'
					}}></span>}
				onCancel={() => props.setVisible(false)}
				footer={
					<div className="footer-wrapper">
						<Button
							kind="secondary"
							onClick={() =>
							{
								props.setVisible(false);
							}}
						>
							{t('modal.cancel')}
						</Button>
						<Button kind="negative" onClick={() => props.onDelete()}>
							{t('modal.delete')}
						</Button>
					</div>
				}
			>
				<>
					{!!props.primaryMessage &&
					<div>
						<div className="content-text">{props.primaryMessage}</div>
						<br/>
					</div>
					}
					<div className="content-text">{props.deleteMessage}</div>
				</>
			</Modal>
		</>
	);
};
