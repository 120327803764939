import { useState } from 'react';
import { Button, Modal, Upload, Notification } from '@appkit4/react-components';
import { useTranslation } from 'react-i18next';
import { HttpStatusCode } from 'axios';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { NotificationService } from 'src/core/services/notificationService';
import './UploadFileModal.scss';

export const UploadFileModal = (props: {
	engagementId: number | undefined,
	visible: boolean,
	setVisible: (visible: boolean) => void,
	setIsUploaded: (isUploaded: boolean) => void,
}) =>
{
	const { t } = useTranslation(
		'homeDashboard',
		{
			keyPrefix: 'importExportFiles'
		}
	);

	const [file, setFile] = useState<any>(undefined);
	const [displayError, setDisplayError] = useState<string>('');
	const excelFileTypes = [
		'application/vnd.ms-excel',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
	];

	const importFileSizeLimitInMB: number = Number(process.env.REACT_APP_IMPORT_FILE_SIZE_LIMIT_MB);

	const triggerErrorNotification = async (message: string): Promise<void> =>
	{
		const ele = (
			<Notification
				message={message}
				status={'error'}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};

	const triggerSuccessNotification = async (message: string): Promise<void> =>
	{
		const ele = (
			<Notification
				message={message}
				status={'success'}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};

	const handleUpload = async () =>
	{
		const formData = new FormData();

		formData.append('uploadedFile', file?.originFile);

		await engagementService.uploadTemplateForImport(props.engagementId!, formData)
			.then((res: any) =>
			{
				if (!!res && res.status === HttpStatusCode.NoContent)
				{
					setFile(undefined);
					props.setIsUploaded(true);
					props.setVisible(false);
					triggerSuccessNotification(
						t('uploadFileModal.uploadingSelectedFileSuccess')
					);
				}
			})
			.catch((error: any) =>
			{
				handleCancelClick();
				triggerErrorNotification(
					t('uploadFileModal.uploadingSelectedFileError')
				);
			});
		setFile(undefined);
	};

	const handleOnChange = (file: any): void =>
	{
		if (!!file && !!file.status && file.status === 'deleted')
		{
			setFile(undefined);
			setDisplayError('');
		}
		else if (!!file && !!file.type && !excelFileTypes.includes(file.type))
		{
			setDisplayError(t('uploadFileModal.selectedFileTypeError') || '');
			setFile(undefined);
		}
		else if (!!file && !!file.size && file.size > importFileSizeLimitInMB * 1024 * 1024)
		{
			setDisplayError(t('uploadFileModal.selectedFileSizeError') || '');
			setFile(undefined);
		}
		else
		{
			setDisplayError('');
			setFile(file);
		}
	};

	const handleCancelClick = () =>
	{
		props.setVisible(false);
		setDisplayError('');
		setFile(undefined);
	};

	return (
		<>
			<Modal
				className="upload-file-modal"
				visible={props.visible}
				title={t('uploadFileModal.title') || ''}
				initialFocus={false}
				closeOnPressEscape={false}
				maskCloseable={false}
				header={''}
				onCancel={handleCancelClick}
				modalStyle={{
					width: '540px'
				}}
				icons={''}
				footer={
					<>
						<Button
							className="upload-file-modal-upload"
							onClick={handleUpload}
							disabled={!file}
							kind="primary"
						>
							{t('uploadFileModal.upload')}
						</Button>
					</>
				}
			>
				<div className={'upload-file-modal-body'}>
					<div className="file-subtitle">
						{t('uploadFileModal.subtitle')}
					</div>
					{props.visible && <Upload
						multiple={false}
						autoUpload={false}
						acceptFileType=".xls,.xlsx"
						onChange={handleOnChange}
						config={{
							trigger: true,
							type: 'modal',
							size: true
						}}
					>
					</Upload>}
					{!!displayError && <div className="file-error">
						{displayError}
					</div>}
				</div>
			</Modal>
		</>
	);
};