import { GridCellProps } from '@progress/kendo-react-grid';
import { useEffect, useState } from 'react';

import './CheckboxEditorCell.scss';
import { Checkbox } from '@appkit4/react-components';

interface Props {
  fieldErrors: Record<string, any>;
  validationField?: string;
  updateFaErrorState: Function;
  handleInputChange: Function;
  handleRowClick: Function;
  handleInputClick: Function;
}

export const CheckboxEditorCell = (props: GridCellProps & Props) =>
{
	const {
		dataItem,
		field = '',
		fieldErrors,
		handleInputChange,
		handleRowClick,
		handleInputClick
	} = props;
	const dataValue = dataItem[field] || '';
	const [value, setValue] = useState<boolean>(!!dataValue);
	const [changed, setChanged] = useState<boolean>(false);
	const [existingError, setExistingError] = useState<boolean>(false);

	useEffect(() =>
	{
		setExistingError(!!fieldErrors);
	}, [fieldErrors]);

	const onClick = () =>
	{
		handleRowClick(field, dataItem);
	};
	return (
		<td className={`${field} 
		${(!!props.validationField)&&fieldErrors?.clientSideErr[props.validationField]?'calculation-type-required-error':''}`}>
			{dataItem.inEditableMode ? (
				<div className="custom-cell">
					<div
						className={`custom-input ${field}  ${
							!changed && fieldErrors?.clientSideErr[field] ?
								'custom-input-error' :
								''
						}`}
						onClick={() => handleInputClick(field)}
					>
						<Checkbox checked={value}
							onChange={(
								value: boolean
							) =>
							{
								setValue(value);
								setChanged(true);
								handleInputChange(undefined, field, value);
							}}
						/>
					</div>
					{(changed ? '' : fieldErrors?.clientSideErr[field ? field : '']) ? (
						<div className="custom-cell-error">
							{fieldErrors?.clientSideErr[field ? field : '']}
						</div>
					) : (
						<>
							{existingError && <div className="custom-cell-error">&nbsp;</div>}
						</>
					)}
				</div>
			) : (
				<div className={`custom-cell-value ${field}`} onClick={onClick}
					title={
						!!dataValue && dataValue.length > 0 ?  dataValue : ''
					}>
					{dataValue&&<span className="Appkit4-icon icon-check-mark-outline"></span>}
				</div>
			)}
		</td>
	);
};
