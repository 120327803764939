import {
	IReg5907AdjustmentsRow
} from 'src/common/types/interfaces/IReg5907Adjustments';

export const gridIds = {
	header: 'headerTable',
	faTaxationDetails: 'faTaxationDetailsTable',
	allTaxPayers: 'AllTaxPayersTable',
	taxPayerOnly: 'TaxPayerOnlyTable',
	aggregateTable: 'AggregateTable'
};

export type ValuesByYear = {
	[yearEnd: string]: IReg5907AdjustmentsRow[]
}

export type ValuesByAffiliateId = {
	[affiliateId: number]: IReg5907AdjustmentsRow[]
}

export type ValuesByDescriptionYear = {
	[descriptionId: string]: ValuesByYear
}

export type RowsByGridType = { [gridId: string]: IReg5907AdjustmentsRow[][] }
export type RecordsByGridType = { [gridId: string]: ValuesByAffiliateId }

export const initialRowData: RowsByGridType = {

	[gridIds.faTaxationDetails]: [],
	[gridIds.allTaxPayers]: [],
	[gridIds.taxPayerOnly]: []
};

export const initialRecordData: RecordsByGridType = {

	[gridIds.faTaxationDetails]: {
	},
	[gridIds.allTaxPayers]: {
	},
	[gridIds.taxPayerOnly]: {
	}
};