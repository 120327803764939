import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { IForeignAccurualProertyIncome } from 'src/features/engagement/models/IForeignAccurualProertyIncome';
import ForeignAccurualProertyIncomeReportFields from '../Settings/ForeignAccurualProertyIncomeFields';
import { IReportField } from 'src/common/types/interfaces/IReportField';
import { ForeignAccurualProertyIncomeFields } from 'src/common/types/enums/ForeignAccurualProertyIncomeFields';
import ReportHeader from '../ReportCells/ReportHeader';
import { ReportI18NKeys } from 'src/common/types/enums/ReportI18NKeys';
import ReportRow from '../ReportCells/ReportRow';
import { Loading, Switch, Notification } from '@appkit4/react-components';
import './ForeignAccurualPropertyIncomeReport.scss';
import { Utils } from 'src/common/utils/utils';
import { INameId } from 'src/common/types/interfaces/INameId';
import { ILookupRecord } from 'src/common/types/interfaces/ILookupRecord';
import { LookupCategories } from 'src/common/types/enums/LookupCategories';
import { NotificationService } from 'src/core/services/notificationService';
import { NotesEditor } from 'src/common/components/NotesEditor/NotesEditor';

const ForeignAccurualPropertyIncomeReport = (props: { refershReport: boolean }) =>
{
	const { engagementId, affiliateId } = useParams();
	const [reportData, setReportData] = useState<any>();
	const [totalAmounts, setTotalAmounts] = useState<any>();
	const [isLoading, setIsLoading] = useState(false);
	const [displayNotes, setDisplayNotes] = useState(false);
	const [allDescriptionOptions, setAllDescriptionOptions] = useState<ILookupRecord[]>([]);

	const report: IForeignAccurualProertyIncome = ForeignAccurualProertyIncomeReportFields;

	const { t } = useTranslation(
		'report',
		{
			keyPrefix: ReportI18NKeys.ForeignAccurualPropertyIncome
		}
	);

	const netValuesParentKeys: string[] = [
		ForeignAccurualProertyIncomeFields.totalRevenue,
		ForeignAccurualProertyIncomeFields.totalExpense,
		ForeignAccurualProertyIncomeFields.totalAdjustment,
		ForeignAccurualProertyIncomeFields.computedNetFAPIOrFAPL,
		ForeignAccurualProertyIncomeFields.nonExcludedPropertyCapitalGain,
		ForeignAccurualProertyIncomeFields.taxesAllocatedToFAPI,
		ForeignAccurualProertyIncomeFields.netFAPIOrFAPLAfterTaxes,
		ForeignAccurualProertyIncomeFields.netFACL
	];

	const fapiTransactionsParentKeys: string[] = [
		ForeignAccurualProertyIncomeFields.revenues,
		ForeignAccurualProertyIncomeFields.expenses,
		ForeignAccurualProertyIncomeFields.adjustments
	];

	const triggerErrorNotification = async (message: string): Promise<void> =>
	{
		const ele = (
			<Notification
				message={message}
				status={'error'}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};

	const calculateRowTotal = (data: any): any =>
	{
		const netValuesSum: any = {
		};

		const fapiTransactionsSum: any = {
		};

		fapiTransactionsParentKeys.forEach((parentKey: any) =>
		{
			if (!!data.referenceData[parentKey] && !!data.referenceData[parentKey].length &&
				data.referenceData[parentKey].length > 0)
			{
				data.referenceData[parentKey].forEach((item: any) =>
				{
					const newKey: string = `${parentKey}-${item.key}`;
					fapiTransactionsSum[newKey] = [];
				});
			}
		});

		!!data && !!data.referenceData && !!data.fapiData && data.fapiData.forEach((item: any) =>
		{
			netValuesParentKeys.forEach((key) =>
			{
				netValuesSum[key] = !!netValuesSum[key] ? [...netValuesSum[key], item[key] || 0] : [item[key] || 0];
			});

			fapiTransactionsParentKeys.forEach((parentKey: any) =>
			{
				if (!!item[parentKey] && !!item[parentKey].length && item[parentKey].length > 0)
				{
					item[parentKey].forEach((item: any) =>
					{
						const newKey: string = `${parentKey}-${item.propertyIncomeDescriptionId}`;
						fapiTransactionsSum[newKey] = !!fapiTransactionsSum[newKey] ?
							[...fapiTransactionsSum[newKey], !!item.amount ? item.amount : 0] : [!!item.amount ? item.amount : 0];
					});
				}
			});
		});

		const earningsTotal: any = {
		};

		const sums = {
			...netValuesSum,
			...fapiTransactionsSum
		};

		Object.keys(sums).forEach((key: string) =>
		{
			earningsTotal[key] = Utils.add(...sums[key]);
		});

		return !!data && !!data.referenceData && !!data.fapiData ? earningsTotal : [];
	};

	const transformFapiRecords = (reference: any, data: any): any =>
	{
		const counts: any = {
		};
		const updatedReference: any = {
		};
		fapiTransactionsParentKeys.forEach((parentKey: any) =>
		{
			counts[parentKey] = {
			};

			updatedReference[parentKey] = [];

			!!reference && !!reference[parentKey] && reference[parentKey].forEach((refKey: any) =>
			{
				counts[parentKey][`${refKey.key}`] = 0;
			});
		});

		const formatDescriptionId = (id: number, count: number) =>
		{
			return `${id}--${count}`;
		};

		const updatedData = !!data && data.length > 0 ? data.map((item: any) =>
		{
			const updatedRecord = {
				...item
			};

			fapiTransactionsParentKeys.forEach((parentKey) =>
			{
				const countMap: any = {
				};
				!!item[parentKey] && item[parentKey].forEach((record: any) =>
				{
					const id = record.propertyIncomeDescriptionId;
					if (!countMap[id])
					{
						countMap[id] = 0;
					}
					countMap[id]++;
				});

				Object.keys(countMap).forEach((descriptionId) =>
				{
					const id = Number(descriptionId);
					if (counts[parentKey][id] === undefined)
					{
						counts[parentKey][id] = countMap[id];
					}
					else
					{
						counts[parentKey][id] = Math.max(counts[parentKey][id], countMap[id]);
					}
				});

				const transactions: any = [];
				!!reference && !!reference[parentKey] && reference[parentKey].forEach(({ key, value }: { key: number, value: string }) =>
				{
					const filteredTransactions = !!updatedRecord[parentKey] &&
						updatedRecord[parentKey].filter((e: any) => e.propertyIncomeDescriptionId === key);
					if (!!filteredTransactions && filteredTransactions.length > 0)
					{
						const updatedTransactions = filteredTransactions.map((t: any, index: number) =>
						{
							return {
								...t,
								propertyIncomeDescriptionId: formatDescriptionId(t.propertyIncomeDescriptionId, index + 1)
							};
						});
						transactions.push(...updatedTransactions);
					}
				});
				updatedRecord[parentKey] = transactions;
			});
			return updatedRecord;
		}) : [];

		const generateKeys = (id: number, count: number) =>
		{
			return Array.from({
				length: count
			}, (_, index) => `${id}--${index + 1}`);
		};

		fapiTransactionsParentKeys.forEach((parentKey) =>
		{
			!!data && data.length &&
			!!reference[parentKey] && reference[parentKey].forEach(({ key, value }: { key: number, value: string }) =>
			{
				const descriptionId = key;
				if (counts[parentKey] && counts[parentKey][descriptionId])
				{
					const maxCount = counts[parentKey][descriptionId];
					const updatedKeys = generateKeys(descriptionId, maxCount);
					updatedKeys.forEach((updatedKey) =>
					{
						updatedReference[parentKey].push({
							key: updatedKey,
							value: value
						});
					});
				}
			});
		});

		return ({
			'referenceData': updatedReference,
			'fapiData': updatedData
		});
	};


	const loadForeignAccurualProertyIncomeReportData = async (): Promise<void> =>
	{
		if (engagementId && affiliateId)
		{
			setIsLoading(true);

			await engagementService.loadForeignAccurualProertyIncomeReport(
				Number(engagementId),
				Number(affiliateId)).then((res: any) =>
			{
				setIsLoading(false);
				const result = !!res && !!res.data && !!res.data.result ? res.data.result : [];

				const updatedData = transformFapiRecords(result.referenceData, result.fapiData);
				setReportData(updatedData);

				const rowTotal = calculateRowTotal(updatedData);
				setTotalAmounts(rowTotal);
			})
				.catch((error: any) =>
				{
					setIsLoading(false);
					triggerErrorNotification(
						t('loadingFapiReportError')
					);
				});
		}
	};

	const filterLookups = (
		allLookups: ILookupRecord[],
		category: string
	): INameId[] =>
	{
		let fieldCategory = category;

		switch (category)
		{
		case ForeignAccurualProertyIncomeFields.revenues:
			fieldCategory = LookupCategories.Income;
			break;
		case ForeignAccurualProertyIncomeFields.expenses:
			fieldCategory = LookupCategories.Expenses;
			break;
		case ForeignAccurualProertyIncomeFields.adjustments:
			fieldCategory = LookupCategories.Adjustments;
			break;
		}

		return allLookups
			.filter((l) => l.category === fieldCategory)
			.map((l) => ({
				id: l.id,
				name: l.fieldName
			}))
			.sort();
	};

	const loadLookups = async (): Promise<void> =>
	{
		try
		{
			const response = await engagementService
				.getPropertyIncomeLookups();

			const allLookups: ILookupRecord[] = !!response.data && !!response.data.result &&
				!!response.data.result.propertyIncomeMasterList && response.data.result.propertyIncomeMasterList.length ?
				response.data.result.propertyIncomeMasterList : [];

			setAllDescriptionOptions(allLookups);
		}
		catch (error)
		{
			triggerErrorNotification(
				t('loadingLookupsError')
			);
		}
	};

	useEffect(() =>
	{
		loadLookups();
		loadForeignAccurualProertyIncomeReportData();
	}, [engagementId, affiliateId]);

	useEffect(() =>
	{
		if (!!props.refershReport)
		{
			loadForeignAccurualProertyIncomeReportData();
		}
	}, [props.refershReport]);

	const onNotesToggle = (value: boolean) =>
	{
		setDisplayNotes(!!value);
	};

	const renderFapiTransactions = (field: IReportField, options?: INameId[], fieldName?: any, totalAmounts?: any) =>
	{
		return (<>
			<ReportRow
				reportData={reportData.fapiData}
				field={field}
				i18nKey={ReportI18NKeys.ForeignAccurualPropertyIncome}
				displayNotes={displayNotes}
				totalAmounts={totalAmounts} />
			{
				!!reportData.referenceData[fieldName] && reportData.referenceData[fieldName].map((field: any) =>
					<tr className={'currency'}>
						<td className={`${!!field?.background ? field?.background : ''}`}>
							{!!field.key && !!options && options.length > 0 ?
								(`${field.key}`.includes('--') ?
									options.find(((o) => `${o.id}` === field.key.split('--')[0]))?.name :
									options.find(((o) => o.id === field.key))?.name) : ''
							}
						</td>
						{!!totalAmounts && <td className={`${!!field?.background ? field?.background : ''}`}>
							<div>
								{!!totalAmounts[fieldName + '-' + field.key] ?
									Utils.formatCurrency(totalAmounts[fieldName + '-' + field.key], 2) : 0}
							</div>
						</td>}
						{
							reportData.fapiData.map((item: any) =>
							{
								const relavantData = !!item[fieldName] &&
									item[fieldName].find((r: any) => r.propertyIncomeDescriptionId === field.key);

								return (
									<td className={`${!!field?.background ? field?.background : ''}`}>

										{!!relavantData ? <div className={`${!!displayNotes ? 'notes-container' : ''}`}>
											{!!displayNotes &&
												<NotesEditor
													displayOnly={true}
													isDisabled={true}
													notes={!!relavantData.notes ? relavantData.notes : ''}
												/>}
											<div>
												{!!relavantData.amount ? Utils.formatCurrency(relavantData.amount) :
													(relavantData.amount === null || relavantData.amount === 0 ? 0 : '')}
											</div>
										</div> : 0}
									</td>
								);
							})
						}

					</tr>
				)
			}
			{!reportData.referenceData[fieldName] && <ReportRow
				reportData={reportData.fapiData}
				field={report.separator[0]}
				i18nKey={ReportI18NKeys.ForeignAccurualPropertyIncome}
				displayNotes={displayNotes}
				totalAmounts={totalAmounts} />}
		</>);
	};

	return <div className='foreign-accurual-property-income-report'>

		{ForeignAccurualProertyIncomeReportFields && <div className='table-container'>
			{isLoading && (
				<div className="loading-icon">
					<Loading
						loadingType="circular"
						indeterminate={true}
						compact={false}
					/>
				</div>
			)}
			<div className={'title-area'}>
				<div className={'header-title'}>
					<span>
						{
							t('header-title')
						}
					</span>
				</div>
				<div className={'toggle'}>
					<Switch
						className={'switch-label'}
						checked={displayNotes}
						disabled={false}
						onChange={onNotesToggle}
					>
						{
							t('header-toggleNotesLabel')
						}
					</Switch>
				</div>
			</div>
			{reportData &&
				<>
					<table>
						<tbody>
							{report.header.map((field: any) =>
								<ReportHeader
									reportData={reportData.fapiData}
									field={field}
									i18nKey={ReportI18NKeys.ForeignAccurualPropertyIncome}
									totalAmounts={totalAmounts} />
							)}
							{report.fapiTransactions.map((field: IReportField, index: number) =>
							{
								return (
									<>
										{renderFapiTransactions(report.fapiTransactions[index],
											[...filterLookups(allDescriptionOptions, field.name)],
											field.name,
											totalAmounts)}
									</>
								);
							}
							)}
							{report.netValues.map((field: IReportField) =>
								<ReportRow
									reportData={reportData.fapiData}
									field={field}
									i18nKey={ReportI18NKeys.ForeignAccurualPropertyIncome}
									displayNotes={displayNotes}
									totalAmounts={totalAmounts} />
							)}
						</tbody>
					</table>
				</>
			}
		</div>}
	</div>;
};

export default ForeignAccurualPropertyIncomeReport;