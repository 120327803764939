import { GridCellProps } from '@progress/kendo-react-grid';
import * as React from 'react';
import { Input } from '@appkit4/react-components/field';
import { useEffect, useState } from 'react';

import './TextEditorCell.scss';

interface Props {
  fieldErrors: Record<string, any>;
  updateFaErrorState: Function;
  handleInputChange: Function;
  handleRowClick: Function;
  handleInputClick: Function;
}

export const TextEditorCell = (props: GridCellProps & Props) =>
{
	const {
		dataItem,
		field = '',
		fieldErrors,
		handleInputChange,
		handleRowClick,
		handleInputClick
	} = props;
	const dataValue = dataItem[field] || '';
	const [value, setValue] = useState<string | null>(dataValue);
	const [changed, setChanged] = useState<boolean>(false);
	const [existingError, setExistingError] = useState<boolean>(false);

	useEffect(() =>
	{
		// const hasErr = EngagementUtil.hasValidationError(fieldErrors);
		setExistingError(!!fieldErrors);
	}, [fieldErrors]);

	const onClick = () =>
	{
		handleRowClick(field, dataItem);
	};
	return (
		<td>
			{dataItem.inEditableMode ? (
				<div className="custom-cell">
					<div
						className={`custom-input ${field}  ${
							!changed && fieldErrors?.clientSideErr[field] ?
								'custom-input-error' :
								''
						}`}
						onClick={() => handleInputClick(field)}
					>
						<Input
							type={'text'}
							className="fn-name-input"
							onChange={(
								value: string,
								event: React.ChangeEvent<HTMLInputElement>
							) =>
							{
								setValue(value);
								setChanged(true);
							}}
							hideTitleOnInput={true}
							value={value}
							inputProps={{
								maxLength: 200
							}}
							onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
							{
								if (value !== dataItem[field])
								{
									handleInputChange(event, field, value?.trim());
								}
							}}
						></Input>
					</div>
					{(changed ? '' : fieldErrors?.clientSideErr[field ? field : '']) ? (
						<div className="custom-cell-error">
							{fieldErrors?.clientSideErr[field ? field : '']}
						</div>
					) : (
						<>
							{existingError && <div className="custom-cell-error">&nbsp;</div>}
						</>
					)}
				</div>
			) : (
				<div className={`custom-cell-value ${field}`} onClick={onClick} >
					<div className='ellipsis-content' title={dataValue}>
						{dataValue}
					</div>

				</div>
			)}
		</td>
	);
};
