export const ExemptSurplusDetailsReportFields = [
	{
		'name': 'taxYearEnd',
		'type': 'title'
	},
	{
		'transactions': [
			{
				'name': 'transaction-date',
				'type': 'date'
			},
			{
				'name': 'transaction',
				'type': 'description'
			},
			{
				'name': 'exempt-surplus',
				'type': 'amount'
			},
			{
				'name': 'exempt-surplus-subtotal',
				'type': 'amount'
			}
		],
		'type': 'columnHeader'
	},
	{
		'transactions': [
			{
				'name': 'openingBalance',
				'type': 'subtitle',
				'background': 'highlight'
			},
			{
				'name': '',
				'type': 'description'
			},
			{
				'name': 'openingBalance.balance',
				'type': 'amount',
				'background': 'highlight'
			},
			{
				'name': 'openingBalance.amount',
				'type': 'amount',
				'background': 'highlight'
			}
		],
		'type': 'subtitle',
		'background': 'highlight'
	},
	{
		'transactions': [
			{
				'name': 'date',
				'type': 'date'
			},
			{
				'name': 'description',
				'type': 'description'
			},
			{
				'name': 'amount',
				'type': 'currency'
			},
			{
				'name': 'balance',
				'type': 'currency'
			}
		],
		'type': 'transactions'
	},
	{
		'transactions': [
			{
				'name': 'endingBalance',
				'type': 'subtitle',
				'background': 'highlight'
			},
			{
				'name': '',
				'type': 'description'
			},
			{
				'name': 'endingBalance.balance',
				'type': 'currency',
				'background': 'highlight'
			},
			{
				'name': 'endingBalance.amount',
				'type': 'currency',
				'background': 'highlight'
			}
		],
		'type': 'subtitle',
		'background': 'highlight'
	}
];

export const ExemptSurplusDetailsReportNoTransactionsFields =
	{
		'transactions': [
			{
				'name': 'date',
				'type': 'date'
			},
			{
				'name': 'description',
				'type': 'description'
			},
			{
				'name': 'amount',
				'type': 'currency'
			},
			{
				'name': 'balance',
				'type': 'currency'
			}
		],
		'type': 'transactions'
	};