const DividendsPaidReportFields = {
	'header': [
		{
			'name': 'status',
			'type': 'status',
			'background': 'section-top-border'
		},
		{
			'name': 'taxYearEnd',
			'type': 'taxYearEnd'
		},
		{
			'name': 'countryId',
			'type': 'countryId'
		},
		{
			'name': 'calculatingCurrencyId',
			'type': 'currencyId'
		}
	],
	'summary': [
		{
			'name': 'summary-title',
			'type': 'title'
		},
		{
			'name': 'summary.exemptSurplus',
			'type': 'currency'
		},
		{
			'name': 'summary.hybridSurplus',
			'type': 'currency'
		},
		{
			'name': 'summary.taxableSurplus',
			'type': 'currency'
		},
		{
			'name': 'summary.preAcquisitionSurplus',
			'type': 'currency'
		},
		{
			'name': 'summary.totalDividendsReceived',
			'type': 'currency',
			'background': 'highlight'
		},
		{
			'name': 'summary.totalHUTReceived',
			'type': 'currency',
			'background': 'highlight'
		},
		{
			'name': 'summary.totalUFTReceived',
			'type': 'currency',
			'background': 'highlight'
		}
	],
	'details': [
		{
			'name': 'amountPaid',
			'type': 'currency',
			'background': 'highlight'
		},
		{
			'name': 'exemptSurplusDividend',
			'type': 'currency'
		},
		{
			'name': 'hybridSurplusDividend',
			'type': 'currency'
		},
		{
			'name': 'taxableSurplusDividend',
			'type': 'currency'
		},
		{
			'name': 'preAcquisitionSurplusDividend',
			'type': 'currency'
		},
		{
			'name': 'paymentDate',
			'type': 'dateWithTime'
		},
		{
			'name': 'equityClassId',
			'type': 'equityClassId'
		},
		{
			'name': 'withholdingTax',
			'type': 'currency'
		},
		{
			'name': 'allocableHUT',
			'type': 'currency'
		},
		{
			'name': 'allocableUFT',
			'type': 'currency'
		},
		{
			'name': 'notes',
			'type': 'text'
		}
	]
};

export default DividendsPaidReportFields;