import axios from 'axios';
import { useEffect, useState } from 'react';
import { decodeAndUnscramble } from 'src/features/authentication/encryptUtil';

const RequestInterceptor = ({ children }: any) =>
{
	const [isSet, setIsSet] = useState(false);

	useEffect(() =>
	{
		const reqInterceptor = (request: any) =>
		{
			const token = sessionStorage.getItem('ROCP_idToken');
			const newToken = token ? decodeAndUnscramble(token, 10) : '';
			if (newToken && request.headers)
			{
				request.headers.Authorization = `Bearer ${newToken}`;
			}
			return request;
		};

		const resInterceptor = (response: any) =>
		{
			return response;
		};

		const errInterceptor = (error: any) =>
		{
			// if (error.response.status === 401) {
			//   navigate("/404");
			// }
			return Promise.reject(error);
		};

		const requestInterceptor = axios.interceptors.request.use(
			reqInterceptor,
			errInterceptor
		);
		const responseInterceptor = axios.interceptors.response.use(
			resInterceptor,
			errInterceptor
		);

		setIsSet(true);

		return () =>
		{
			axios.interceptors.request.eject(requestInterceptor);
			axios.interceptors.response.eject(responseInterceptor);
		};
	}, []);

	return isSet && children;
};

export default RequestInterceptor;
