import { ForeignAccurualProertyIncomeFields } from 'src/common/types/enums/ForeignAccurualProertyIncomeFields';

const ForeignAccurualProertyIncomeReportFields = {
	'header': [
		{
			'name': 'status',
			'type': 'status',
			'background': 'section-top-border'
		},
		{
			'name': 'taxYearEnd',
			'type': 'taxYearEnd'
		},
		{
			'name': 'countryId',
			'type': 'countryId'
		},
		{
			'name': 'calculatingCurrencyId',
			'type': 'currencyId'
		}
	],
	'fapiTransactions': [
		{
			'name': `${ForeignAccurualProertyIncomeFields.revenues}`,
			'type': 'title'
		},
		{
			'name': `${ForeignAccurualProertyIncomeFields.totalRevenue}`,
			'type': 'currency',
			'background': 'highlight'
		},
		{
			'name': `${ForeignAccurualProertyIncomeFields.expenses}`,
			'type': 'title'
		},
		{
			'name': `${ForeignAccurualProertyIncomeFields.totalExpense}`,
			'type': 'currency',
			'background': 'highlight'
		},
		{
			'name': `${ForeignAccurualProertyIncomeFields.adjustments}`,
			'type': 'title'
		},
		{
			'name': `${ForeignAccurualProertyIncomeFields.totalAdjustment}`,
			'type': 'currency',
			'background': 'highlight'
		}
	],
	'netValues': [
		{
			'name': `${ForeignAccurualProertyIncomeFields.computedNetFAPIOrFAPL}`,
			'type': 'currency',
			'background': 'highlight'
		},
		{
			'name': '',
			'type': 'separator fixed'
		},
		{
			'name': 'taxablePortionofFAPICapitalGainLoss-title',
			'type': 'title'
		},
		{
			'name': `${ForeignAccurualProertyIncomeFields.nonExcludedPropertyCapitalGain}`,
			'type': 'currency'
		},
		{
			'name': '',
			'type': 'separator fixed'
		},
		{
			'name': 'lessAllocableTaxesUFT-title',
			'type': 'title'
		},
		{
			'name': `${ForeignAccurualProertyIncomeFields.taxesAllocatedToFAPI}`,
			'type': 'currency'
		},
		{
			'name': '',
			'type': 'separator fixed'
		},
		{
			'name': `${ForeignAccurualProertyIncomeFields.netFAPIOrFAPLAfterTaxes}`,
			'type': 'currency',
			'background': 'highlight'
		},
		{
			'name': `${ForeignAccurualProertyIncomeFields.netFACL}`,
			'type': 'currency',
			'background': 'highlight'
		}
	],
	'separator': [
		{
			'name': '',
			'type': 'separator fixed'
		}
	]
};

export default ForeignAccurualProertyIncomeReportFields;