export enum AdjustmentFromSpecificTaxpayerFields {
	id='id',
	engagementId='engagementId',
	affiliateId='affiliateId',
	surplusPoolId='surplusPoolId',
	descriptionId='descriptionId',
	adjustmentDate='adjustmentDate',
	currencyId='currencyId',
	amount='amount',
	notes='notes',
	taxpayerId='taxpayerId',
	specificTo='specificTo',
	time='time'
}