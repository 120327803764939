import {
	Grid,
	GridCellProps,
	GridColumn,
	GridNoRecords
} from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useContext, useEffect, useState } from 'react';
import { DropdownEditorCell } from 'src/common/components/Editors/DropdownEditor/DropdownEditorCell';
import { useAppSelector } from 'src/store/store';

import './FAChanges.scss';
import { Badge, Button } from '@appkit4/react-components';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { IFAHistory } from 'src/features/engagement/models/IFAHistory';
import { DatePickerEditorCell } from 'src/common/components/Editors/DatePickerEditor/DatePickerEditorCell';
import { UnsavedChangesModal } from 'src/core/components/Dashboard/unsavedModal/UnsavedChangesModal';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { FAWeekWarningAndDeleteModal } from '../FAWeekWarningAndDeleteModal/FAWeekWarningAndDeleteModal';
import { TimePickerEditor } from 'src/common/components/Editors/TimePickerEditor/TimePickerEditor';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import { Utils } from 'src/common/utils/utils';
import { UnexpectedErrorModal } from 'src/common/components/UnexpectedErrorModal/UnexpectedErrorModal';
import { ConfirmationModal } from 'src/common/components/ConfirmationModal/ConfirmationModal';
import AffiliateContext from 'src/common/components/SharedDataContext/AffiliateContext';

export const FAChanges = () =>
{
	const editField: string = 'inEdit';

	const currencies = useAppSelector((state) => state.appSettings.currencies);
	const countries = useAppSelector((state) => state.appSettings.countries);
	const affiliateContext = useContext(AffiliateContext);

	const [faHistory, setFaHistory] = useState<IFAHistory[]>([]);
	const [showUnsavedModal, setShowUnsavedModal] = useState<boolean>(false);
	const [showDeleteModal, setShowDeleteModal] =useState<boolean>(false);
	const [deleteDialogParams, setDeleteDialogParams] = useState<Record<string, any> | null>(null);
	const [showWarningModal, setShowWarningModal]= useState<boolean>(false);
	const [unexpectedError, setUnExpectedError] = useState<string>('');
	const [showUnexpectedErrorModal, setShowUnexpectedErrorModal] = useState<boolean>(false);
	const [currentFaHistory, setCurrentFaHistory] = useState<IFAHistory[]>([]);
	const [disableSave, setDisableSave] = useState<boolean>(true);
	const [disableEdit, setDisableEdit] = useState<boolean>(false);
	const [changed, setChanged] = useState<boolean>(false);
	const { t } = useTranslation('engInput');
	const { t: errorMessage } = useTranslation('errors');

	const initFaHistory: IFAHistory = {
		id: 0,
		taxYearEnd: undefined,
		time: new Date(1971, 1, 1, 23, 59, 0),
		countryId: 0,
		currencyId: 0,
		inEditableMode: true,
		isNew: true
	};

	const initialFaErrors = {
		clientSideErr: {
			taxYearEnd: '',
			countryId: '',
			currencyId: '',
			time: ''
		},
		apiErr: {
			taxYearEnd: '',
			countryId: '',
			currencyId: ''
		}
	};

	const [faHistoryErrors,setFaHistoryErrors] = useState<any>(undefined);

	const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);


	useEffect(() =>
	{
		getFAHistory();
	},[affiliateContext]);

	const getFAHistory = () =>
	{
		if (affiliateContext)
		{
			if (affiliateContext.engagementDetail.id && affiliateContext.affiliateDetail.affiliateId)
			{
				engagementService
					.getFAHistory(
						affiliateContext.engagementDetail.id,
						affiliateContext.affiliateDetail.affiliateId
					)
					.then((res: any) =>
					{
						if (res.data.result)
						{
							const faHData = res.data.result.map((item: any) =>
							{
								const faH: IFAHistory = {
									id: item.id,
									taxYearEnd: dateFormatter.utcToEst(item.taxYearEnd),
									time: dateFormatter.utcToEst(item.taxYearEnd),
									countryId: item.countryId,
									currencyId: item.currencyId,
									isRelevant: item.isRelevant,
									inEditableMode: false,
									isNew: false
								};
								return faH;
							});
							setFaHistory(faHData);
						}
					});
			}
		}
	};

	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		field: string,
		value: any
	) =>
	{
		const currentlyEditedRow = faHistory.filter(
			(r) => r.inEditableMode === true
		)?.[0] as any;
		currentlyEditedRow[`${field}`] = value;

		const {taxYearEnd, countryId, currencyId,time}= currentlyEditedRow;
		if (!taxYearEnd || countryId ===0 || currencyId === 0 ||!time)
		{
			setDisableSave(true);
		}
		else
		{
			setDisableSave(false);
		}

		if (!changed)
		{
			setChanged(true);
		}

		if (field === 'taxYearEnd' || field === 'time')
		{
			setFaHistoryErrors(undefined);
			checkIfDateValid(currentlyEditedRow.taxYearEnd,currentlyEditedRow.time);
		}
		if (field === 'taxYearEnd' && !currentlyEditedRow.isNew)
		{
			faHistory.sort(onSortByDate);
			const totalItems = faHistory?.length || 0;
			const currItemIndex = faHistory.findIndex((item) => item.taxYearEnd === currentlyEditedRow.taxYearEnd);
			const currentItemDate = moment(currentlyEditedRow.taxYearEnd);

			const prevItemDate = currItemIndex > 0 ? moment(faHistory[currItemIndex - 1].taxYearEnd) : undefined;
			const nextItemDate = currItemIndex < totalItems - 1 ? moment(faHistory[currItemIndex + 1].taxYearEnd) : undefined;

			const durationPrev = !!prevItemDate ? moment.duration(currentItemDate.diff(prevItemDate)) : undefined;
			const diffWeeksPrev = Math.abs(!!durationPrev ? Math.floor(durationPrev.asWeeks()) : 0);

			const durationNext = !!nextItemDate ? moment.duration(nextItemDate.diff(currentItemDate)): undefined;
			const diffWeeksNext = Math.abs(!!durationNext ? Math.floor(durationNext.asWeeks()) : 0);

			if (diffWeeksNext >= 53 || diffWeeksPrev >= 53)
			{
				setShowWarningModal(true);
			}
		}
	};

	const onSortByDate = (a: IFAHistory, b: IFAHistory) =>
	{
		const dateA = moment(a.taxYearEnd);
		const dateB = moment(b.taxYearEnd);

		if (dateA.isBefore(dateB))
		{
			return 1;
		}
		else if (dateA.isAfter(dateB))
		{
			return -1;
		}

		return 0;
	};

	const checkIfDateValid = (taxYearEnd: Date |undefined, time: Date | undefined) =>
	{
		const currentTaxYearEnd = dateFormatter.estDateStringOverrideToUtc(
			dateFormatter.mergeDateTime(
				taxYearEnd,
				time
			)
		);

		faHistory.map((item: IFAHistory) =>
		{
			if (!item.inEditableMode)
			{
				const completeTaxYearEnd = dateFormatter.estDateStringOverrideToUtc(
					dateFormatter.mergeDateTime(
						item.taxYearEnd,
						item.time
					)
				);
				const date1 = new Date(`${completeTaxYearEnd}`);
				const date2 = new Date(`${currentTaxYearEnd}`);
				const validationErrors = {
					...initialFaErrors
				};
				if (Utils.isSameDate(date1,date2))
				{
					validationErrors.clientSideErr.taxYearEnd = Utils.isSameDate(date1,date2)?
						t('faChange.sameDateErr'):'';
					validationErrors.clientSideErr.time = Utils.isSameDate(date1,date2)?
						t('faChange.sameDateErr'):'';
					setFaHistoryErrors(validationErrors);
				}
			}
			return false;
		});
	};

	const handleInputClick = (field: string, event: any) =>
	{};

	const handleRowClick = async (field: string, event: any) =>
	{};

	const preventDoubleClick = () =>
	{
		setDisableSave(true);

		setTimeout(() =>
		{
			setDisableSave(false);
		}, 3000);
	};

	const ActionsButton = (params: GridCellProps) =>
	{
		const [inEditableMode, setInEditableMode] = useState<boolean>(false);
		const [, setDisableIcon] = useState<boolean>(false);

		const disabledClass = !params.dataItem.isRelevant ?
			'disabled-button-icon' :
			'';

		useEffect(() =>
		{
			setInEditableMode(params.dataItem?.inEditableMode);
			setDisableIcon(false);
		}, [params.dataItem]);

		return inEditableMode ? (
			<td className="k-command-cell">
				<div className="custom-cell">
					<div className="action-buttons">
						<div onClick={preventDoubleClick}><Button
							kind="primary" onClick={confirmSave} disabled={disableSave || !!faHistoryErrors}
						>
              				Save
						</Button></div>
						<Button kind="tertiary"  onClick={confirmCancel}>
              				Cancel
						</Button>
					</div>
					{/* {error && <div className="custom-cell-error">&nbsp;</div>} */}
				</div>
			</td>
		) : (
			<td className="k-command-cell">
				<div className="custom-cell">
					<div className="action-buttons">
						<Button
							kind="text"
							className={`Appkit4-icon icon-pencil-outline ${disabledClass}`}
							disabled={disableEdit || !params.dataItem.isRelevant}
							onClick={() => handleEdit(params.dataItem)}
						></Button>
						<Button
							kind="text"
							className={`Appkit4-icon icon-delete-outline ${disabledClass}`}
							disabled={!params.dataItem.isRelevant}
							onClick={() => onDeleteValidate(params.dataItem)}></Button>
					</div>
				</div>
			</td>
		);
	};

	const onDeleteValidate = (dataItem: any) =>
	{
		setDeleteDialogParams(dataItem);
		const totalItems = faHistory?.length || 0;
		const currItemIndex = faHistory.findIndex((item) => item.taxYearEnd === dataItem.taxYearEnd);

		if (currItemIndex === 0 || currItemIndex === totalItems - 1)
		{
			setShowDeleteModal(true);
			return;
		}

		const prevItemDate = moment(faHistory[currItemIndex - 1].taxYearEnd);
		const nextItemDate = moment(faHistory[currItemIndex + 1].taxYearEnd);
		const duration = moment.duration(nextItemDate.diff(prevItemDate));
		const diffWeeks = Math.floor(duration.asWeeks());

		if (diffWeeks >= 53)
		{
			setShowWarningModal(true);
		}
		else
		{
			setShowDeleteModal(true);
		}
	};

	const confirmSave = () =>
	{
		const newItem = faHistory.find((item: IFAHistory) => item.isNew === true);
		if (!!newItem)
		{
			handleSave();
		}
		else
		{
			setDisplayConfirmationModal(true);
		}
	};

	const handleSave = () =>
	{
		setDisableEdit(false);
		const newItem = faHistory.find((item: IFAHistory) => item.isNew === true);
		if (newItem&&affiliateContext)
		{
			const requestBody={
				engagementId: affiliateContext.engagementDetail.id,
				affiliateId: affiliateContext.affiliateDetail.affiliateId,
				...newItem,
				taxYearEnd: dateFormatter.estDateStringOverrideToUtc(
					dateFormatter.mergeDateTime(
						newItem.taxYearEnd,
						newItem.time
					)
				)
			};

			engagementService
				.createFaHistory(
					requestBody
				)
				.then((res) =>
				{
					getFAHistory();
				})
				.catch((err: any) =>
				{
					handleApiErrorResponse(err);
				});
		}
		else
		{
			const editingItem = faHistory.find(
				(item: any) => item.inEditableMode === true
			);
			if (editingItem&&affiliateContext)
			{
				const requestBody={
					engagementId: affiliateContext.engagementDetail.id,
					affiliateId: affiliateContext.affiliateDetail.affiliateId,
					...editingItem,
					taxYearEnd: dateFormatter.estDateStringOverrideToUtc(
						dateFormatter.mergeDateTime(
							editingItem.taxYearEnd,
							editingItem.time
						)
					)
				};
				engagementService
					.updateFaHistory(requestBody)
					.then((res) =>
					{
						getFAHistory();
					})
					.catch((err: any) =>
					{
						handleApiErrorResponse(err);
					});
			}
		}
	};

	const handleEdit = (event: any) =>
	{
		setFaHistoryErrors(undefined);
		const editedRowIndex = faHistory.findIndex(
			(r) => r.inEditableMode === true
		);

		if (editedRowIndex === -1)
		{
			setDisableEdit(true);
		}

		const updatedFaHistory = faHistory.map((item: any) =>
		{
			if (item.id === event.id)
			{
				return {
					...item,
					taxYearEnd: new Date(item.taxYearEnd),
					inEditableMode: true,
					isNew: false
				};
			}
			else
			{
				return item;
			}
		});
		setChanged(false);
		setFaHistory(updatedFaHistory);
		setCurrentFaHistory(faHistory);
	};

	const handleDelete = (faHistoryId: number) =>
	{
		if (!!faHistoryId&&affiliateContext)
		{
			const engId = affiliateContext.engagementDetail.id;
			const affId=  affiliateContext.affiliateDetail.affiliateId;
			engagementService.deleteFaHistory(engId, affId, faHistoryId).then((res) =>
			{
				setDeleteDialogParams(null);
				setShowDeleteModal(false);
				getFAHistory();
			});
		}
	};

	const confirmCancel = () =>
	{
		if (!changed)
		{
			handleCancel();
		}
		else
		{
			setShowUnsavedModal(true);
		}
	};

	const handleCancel = () =>
	{
		setFaHistoryErrors(undefined);
		setDisableEdit(false);
		const newItem = faHistory.find((item: any) => item.isNew === true);
		setChanged(false);
		setDisableSave(true);
		if (newItem)
		{
			setFaHistory(faHistory.filter((item: any) => item.isNew !== true));
		}
		else
		{
			if (currentFaHistory)
			{
				setFaHistory(currentFaHistory);
				setCurrentFaHistory([]);
			}
		}
	};

	const closeUnsavedModal = (str: string) =>
	{
		if (str === 'stay')
		{
			setShowUnsavedModal(false);
		}
		else if (str === 'leave')
		{
			setShowUnsavedModal(false);
			handleCancel();
		}
	};

	const addFaHistory = () =>
	{
		if (affiliateContext?.affiliateDetail)
		{
			setDisableSave(false);
			setDisableEdit(true);
			const editedRowIndex = faHistory.findIndex(
				(r) => r.inEditableMode === true
			);
			if (editedRowIndex > -1)
			{
			}
			else
			{
				const month = new Date(affiliateContext.affiliateDetail.taxationYearEnd!).getMonth();
				const day = new Date(affiliateContext.affiliateDetail.taxationYearEnd!).getDate();
				const year = new Date(affiliateContext.engagementDetail.lastCalculationTaxationYearEnd!).getFullYear();
				const date1 = new Date(year,month,day);
				const date2 = new Date(affiliateContext.engagementDetail.lastCalculationTaxationYearEnd!);

				if (date1 > date2)
				{
				//if date1 is greater then the engagement last calculation year
					initFaHistory.taxYearEnd = new Date(year-1,month,day);
				}
				else
				{
					initFaHistory.taxYearEnd = date1;
				}
				initFaHistory.countryId = affiliateContext.affiliateDetail.countryId ? affiliateContext.affiliateDetail.countryId : 0;
				initFaHistory.currencyId = affiliateContext.affiliateDetail.currencyId ? affiliateContext.affiliateDetail.currencyId : 0;

				setFaHistoryErrors(undefined);
				setFaHistory([initFaHistory,...faHistory]);
			}
		}
	};

	const handleApiErrorResponse = (err: any) =>
	{
		if (err.response)
		{
			if (err.response.status >= 500)
			{
				setUnExpectedError(err.response.data.Errors[0].Message);
				setShowUnexpectedErrorModal(true);
			}
			else if (err.response.status >= 400)
			{
				const errors = err.response.data.Errors;
				const faHistoryErrors = {
					clientSideErr: {
						taxYearEnd: '',
						countryId: '',
						currencyId: '',
						time: ''
					}
				};
				if (errors)
				{
					errors.forEach((error: any) =>
					{
						if (error.ErrorCode === 'FAHISTORY_DUPLICATE_ROW_EXISTS')
						{
							faHistoryErrors.clientSideErr.taxYearEnd = errorMessage(
								`code.${error.ErrorCode}`
							);
							faHistoryErrors.clientSideErr.time = errorMessage(
								`code.${error.ErrorCode}`
							);
						}
						if (error.Field === 'Currency')
						{
							faHistoryErrors.clientSideErr.currencyId = errorMessage(
								`code.${error.ErrorCode}`
							);
						}
						if (error.Field === 'Country')
						{
							faHistoryErrors.clientSideErr.countryId = errorMessage(
								`code.${error.ErrorCode}`
							);
						}
						if (error.Field === 'TaxYearEnd')
						{
							faHistoryErrors.clientSideErr.taxYearEnd = errorMessage(
								`code.${error.ErrorCode}`
							);
						}
						if (error.Field === 'DuplicateRowCombination')
						{
							faHistoryErrors.clientSideErr.taxYearEnd = errorMessage(
								`code.${error.ErrorCode}`
							);
							faHistoryErrors.clientSideErr.time = errorMessage(
								`code.${error.ErrorCode}`
							);
						}
					});
					setFaHistoryErrors(faHistoryErrors);
				}
			}
		}
	};

	const closeUnexpectedErrorModal = () =>
	{
		setUnExpectedError('');
		setShowUnexpectedErrorModal(false);
	};

	return (
		<div className="changes-container">
			<div className="link-add">
				<Button kind={'text'} onClick={addFaHistory} >
					<span className="Appkit4-icon icon-plus-outline"></span> Add New Change
				</Button>
			</div>
			<div>
				<LocalizationProvider language="en-user">
					<IntlProvider locale="en">
						<Grid
							className={'fa-input-changes-grid'}
							data={faHistory}
							editField={editField}
							selectable={{
								enabled: true,
								drag: false,
								cell: false,
								mode: 'single'
							}}
							sortable={{
								allowUnsort: false
							}}
						>
							{/* <GridToolbar></GridToolbar> */}
							<GridNoRecords>&nbsp;</GridNoRecords>
							<GridColumn
								field="taxYearEnd"
								title={t('faChange.taxationYE')|| ''}
								editable={false}
								sortable={true}
								headerClassName={'FAgrid-header-cell1'}
								cell={(params) =>
									DatePickerEditorCell({

										...params,
										isShortFormat: false,
										fieldErrors: faHistoryErrors,
										handleInputChange,
										handleRowClick,
										handleInputClick,
										minDate: affiliateContext?.affiliateDetail && affiliateContext.affiliateDetail.dateBecameFa ?
											dateFormatter.toStartOfDayLocal(affiliateContext.affiliateDetail.dateBecameFa) :
											undefined,
										maxDate: new Date(affiliateContext?.engagementDetail.lastCalculationTaxationYearEnd!) || undefined,
										onRenderRight: (dataItem) =>
										{
											return <span
												style={{
													marginLeft: '12px'
												}}
											>
												{
													dataItem.isRelevant &&
													<Badge
														value={'Relevant'}
													/>
												}
											</span>;
										}
									})
								}
							/>
							<GridColumn
								field="time"
								title={t('faChange.time')|| ''}
								editable={false}
								sortable={true}
								headerClassName={'FAgrid-header-cell1'}
								cell={(params) =>
									TimePickerEditor({
										...params,
										fieldErrors: faHistoryErrors,
										handleInputChange,
										handleRowClick,
										handleInputClick
									})
								}
							/>
							<GridColumn
								field="countryId"
								title={t('faChange.country')|| ''}
								editable={false}
								sortable={true}
								headerClassName={'FAgrid-header-cell1'}
								cell={(params) =>
									DropdownEditorCell({
										...params,
										fieldErrors: faHistoryErrors,
										dropdownValues: countries,
										labelKey: 'name',
										valueKey: 'id',
										handleInputChange,
										handleRowClick,
										handleInputClick
									})
								}
							/>
							<GridColumn
								field="currencyId"
								title={t('faChange.calcCurrency')|| ''}
								headerClassName={'FAgrid-header-cell'}
								sortable={true}
								cell={(params) =>
									DropdownEditorCell({
										...params,
										fieldErrors: faHistoryErrors,
										dropdownValues: currencies,
										labelKey: 'code',
										valueKey: 'id',
										handleInputChange,
										handleRowClick,
										handleInputClick
									})
								}
							/>
							<GridColumn
								title={''}
								className={'FAgrid-icons'}
								sortable={false}
								cell={(params) => ActionsButton({
									...params
								})}
							/>
						</Grid>
					</IntlProvider>
				</LocalizationProvider>
			</div>
			<UnsavedChangesModal
				visible={showUnsavedModal}
				onClose={closeUnsavedModal}
				title={t('unsavedModal.discardChangesTitle') || ''}
			/>
			{(showWarningModal || showDeleteModal) &&
			<FAWeekWarningAndDeleteModal
				showWarningModal={showWarningModal}
				showDeleteModal={showDeleteModal}
				title={showWarningModal ? t('warningModal.warningTitle') : (showDeleteModal? t('deleteModal.deleteTitle') : '')}
				setShowWarningModal={setShowWarningModal}
				setShowDeleteModal={(val: boolean) =>
				{
					setShowDeleteModal(val);
					if (!val)
					{
						setDeleteDialogParams(null);
					}
				}}
				warningMessage={t('warningModal.warningMsg')}
				deleteMessage={t('deleteModal.deleteMsg')}
				onDelete={() => handleDelete(deleteDialogParams?.id)}
			></FAWeekWarningAndDeleteModal>}
			<UnexpectedErrorModal
				visible={showUnexpectedErrorModal}
				onClose={closeUnexpectedErrorModal}
				message={t('unexpected-error-popup.message')}
				error={unexpectedError}
			/>
			<ConfirmationModal
				visible={displayConfirmationModal}
				message={t('confirmationModal.message') || ''}
				onClose={() =>
				{
					setDisplayConfirmationModal(false);
				}}
				onContinue={() =>
				{
					handleSave();
					setDisplayConfirmationModal(false);
				}}
			/>
		</div>
	);
};
