import React, { useEffect, useState } from 'react';

import {
	Button,
	Input,
	List,
	ListItem,
	Loading,
	Modal,
	Search
} from '@appkit4/react-components';

import './AddFAModal.scss';
import { engagementService } from '../../../../services/engagementService';
import { UnsavedChangesModal } from 'src/core/components/Dashboard/unsavedModal/UnsavedChangesModal';
import { useTranslation } from 'react-i18next';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import useDisableBodyScroll from 'src/common/hooks/useDisableBodyScroll';
import { EngagementUtil } from 'src/features/engagement/utils/EngagementUtil';
import { IForeignAffiliate } from 'src/features/engagement/models/IForeignAffiliate';
import { CalendarPickerWrapper } from 'src/common/components/CalendarPickerWrapper/CalendarPickerWrapper';
import { IEngagementDetail } from 'src/features/engagement/models/IEngagementDetail';
import { omit } from 'lodash';
import { UnexpectedErrorModal } from 'src/common/components/UnexpectedErrorModal/UnexpectedErrorModal';

interface IAddFAProps {
  clientId: number | undefined;
  engagementId: number | undefined;
  engagementDetail: IEngagementDetail | undefined;
  visible: boolean;
  setVisible: Function;
  addExistingFA: Function;
  faModalErros?: any;
}


const blankForeignAffiliate: IForeignAffiliate = {
	affiliateId: 0,
	name: '',
	countryId: 0,
	currencyId: 0,
	taxationYearEnd: undefined,
	dateBecameFa: undefined,
	analysisStartDate: undefined
};

interface AddFaModalErrors {
	ErrorCode: string,
	Field: string,
	Message: string,
	MessageDetail: string
}

export const AddFAModal = (props: IAddFAProps) =>
{
	const [searchText, setSearchText] = useState<string>('');
	const [searched, setSearched] = useState<boolean>(false);
	const [existingForeignAffiliates, setExistingForeignAffiliates] = useState<IForeignAffiliate[]>([]);
	const [filteredForeignAffiliates, setfilteredForeignAffiliates] = useState<IForeignAffiliate[]>([]);
	const [selectedForeignAffiliate, setSelectedForeignAffiliate] = useState<IForeignAffiliate | undefined>(undefined);
	const {visible, setVisible} = props;
	const [showUnsavedModal, setShowUnsavedModal] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [disableIcon, setDisableIcon] = useState<boolean>(false);
	const [invalidAnalysisEndDate, setInvalidAnalysisEndDate] = useState<boolean>(false);
	const [showUnexpectedErrorModal, setShowUnexpectedErrorModal] =useState<boolean>(false);
	const [apiStatusCode,setApiStatusCode]= useState<number>(0);
	const [unexpectedError, setUnExpectedError] = useState<string>('');
	const [faApiErrors, setFaApiErrors] =useState<Record<string,AddFaModalErrors>|null>(null);

	useDisableBodyScroll(visible);

	const { t } = useTranslation('engagementSetup');
	const { t: errorMessages } = useTranslation('errors');

	const handleAddFAModalApiErrors = (err: any) =>
	{
		if (err.response)
		{
			setApiStatusCode(err.response.status);
			if (err.response.status >= 500)
			{
				setUnExpectedError(err.response.data.Errors[0].Message);
				setShowUnexpectedErrorModal(true);
			}
			else if (err.response.status >=400)
			{
				const {
					response: {data: { Errors}} = {
						data: {
							Errors: []
						}
					}
				} = err;
				setFaApiErrors(Errors.reduce((formatted: Record<string, AddFaModalErrors>, item: AddFaModalErrors) =>
				{
					formatted[`${item.Field}`] = {
						...item
					};
					return formatted;
				}, {
				}));
			}
		}
	};

	useEffect(() =>
	{
		setSearchText('');
		setfilteredForeignAffiliates([]);
		setSelectedForeignAffiliate(blankForeignAffiliate);
		setInvalidAnalysisEndDate(false);
		setFaApiErrors(null);
	}, [props.visible]);

	useEffect(() =>
	{
		if (!!props.faModalErros)
		{
			handleAddFAModalApiErrors(props.faModalErros);
		}
	},[props.faModalErros]);

	useEffect(() =>
	{
		setLoading(true);
		setSelectedForeignAffiliate(blankForeignAffiliate);
		if (props.visible && props.clientId && props.engagementId)
		{
			engagementService
				.searchForeignAffiliates(props.clientId, props.engagementId)
				.then((res: any) =>
				{
					const fas = res.data.result;
					setExistingForeignAffiliates(fas);
					setLoading(false);
					setfilteredForeignAffiliates(fas);
					if (fas.length === 0)
					{
						setSearched(true);
					}
				});
		}
	}, [props.clientId, visible]);

	useEffect(() =>
	{
		const fs = existingForeignAffiliates.filter((f: any) =>
			f.name.toLowerCase().includes(searchText.toLowerCase())
		);
		setfilteredForeignAffiliates(fs);
		setSearched(true);
		setSelectedForeignAffiliate(undefined);
	}, [searchText]);

	const closeUnsavedModal = (str: string) =>
	{
		if (str === 'stay')
		{
			setShowUnsavedModal(false);
		}
		else if (str === 'leave')
		{
			setShowUnsavedModal(false);
			setVisible(false);
		}
	};

	const onSelectFA = (item: any) =>
	{
		setInvalidAnalysisEndDate(false);
		setSelectedForeignAffiliate(item);
	};

	if (props?.visible === false)
	{
		return null;
	}

	const onCancel = () =>
	{
		if (selectedForeignAffiliate?.name)
		{
			setShowUnsavedModal(true);
		}
		else
		{
			setVisible(false);
		}
	};

	const validateAnalysisEndDate = (fa: IForeignAffiliate | undefined) =>
	{
		if (fa&&fa.analysisStartDate&&fa.analysisEndDate&&fa.analysisEndDate<fa.analysisStartDate)
		{
			setInvalidAnalysisEndDate(true);
			return false;
		}
		else
		{
			setInvalidAnalysisEndDate(false);
			return true;
		}
	};

	const onSave = () =>
	{
		if (validateAnalysisEndDate(selectedForeignAffiliate))
		{
			props.addExistingFA(selectedForeignAffiliate);
		}
	};

	const updateDate = (field: string, value: Date, errorIdKey?: string) =>
	{
		if (selectedForeignAffiliate)
		{
			const updatedFA = {
				...selectedForeignAffiliate,
				[field]: value
			};
			setSelectedForeignAffiliate(updatedFA);
			validateAnalysisEndDate(updatedFA);
			const updatedFaErrors = omit(props.faModalErros, errorIdKey || '');
			setFaApiErrors(updatedFaErrors);
		}
	};

	const clearSearch = () =>
	{
		setSearchText('');
		setfilteredForeignAffiliates(existingForeignAffiliates);
		if (existingForeignAffiliates.length > 0)
		{
			setSearched(false);
		}
		setSelectedForeignAffiliate(blankForeignAffiliate);
	};

	const renderItem = (item: any, index: number) =>
	{
		return (
			<ListItem
				key={item.name}
				role="option"
				className={item.name === selectedForeignAffiliate?.name ? 'selected-fa' : ''}
				onClick={() =>
				{
					onSelectFA(item);
				}}
			>
				<span className="item-text">{item.name}</span>
			</ListItem>
		);
	};

	const preventDoubleClick = () =>
	{
		setDisableIcon(true);

		setTimeout(() =>
		{
			setDisableIcon(false);
		}, 3000);
	};

	const closeUnexpectedErrorModal = () =>
	{
		setUnExpectedError('');
		setShowUnexpectedErrorModal(false);
	};


	return (
		<div>
			<Modal
				className="add-fa-modal"
				visible={props.visible}
				closeOnPressEscape={false}
				maskCloseable={false}
				initialFocus={false}
				title={t('addExistingForeignAffiliates.addExistingForeignAffiliates')||''}
				onCancel={onCancel}
				footerStyle={{
					paddingTop: '8px',
					marginTop: '-8px',
					minHeight: '64px'
				}}
				header={''}
				icons={''}
				footer={
					<>
						<Button kind="secondary" onClick={onCancel}>
							{t('addExistingForeignAffiliates.cancel') || ''}
						</Button>
						<div onClick={preventDoubleClick}><Button
							disabled={
								!selectedForeignAffiliate?.name ||
                !selectedForeignAffiliate.dateBecameFa ||
                !selectedForeignAffiliate.analysisStartDate || disableIcon || invalidAnalysisEndDate}
							onClick={onSave}
						>
							{t('addExistingForeignAffiliates.save') || ''}
						</Button>
						</div></>
				}
			>
				<div className="add-fa-body">
					<div className="left">
						<div className="search-box">
							<Search
								placeholder={t('addExistingForeignAffiliates.searchExistingForeignAffiliate')||''}
								searchType={'secondary'}
								onClear={clearSearch}
								onChange={(value) => setSearchText(value)}
							/>
						</div>
						{!!filteredForeignAffiliates && filteredForeignAffiliates?.length > 0 ? (
							<List
								className="result-container"
								itemKey="taxpayerForeignAffiliateName"
								bordered={true}
								data={filteredForeignAffiliates}
								renderItem={renderItem}
							/>
						) : (
							<div className="no-data">
								{`${searched ? t('addExistingForeignAffiliates.noDataAvailable') : ''}`}
							</div>
						)}
					</div>
					<div className="right">
						<div className="field">
							<Input
								type={'text'}
								title={t('addExistingForeignAffiliates.name')||''}
								value={selectedForeignAffiliate?.name || ''}
								disabled={true}
							/>
						</div>
						<div className="field">
							<Input
								type={'text'}
								title={t('addExistingForeignAffiliates.countryId')||''}
								value={EngagementUtil.getCountryName(selectedForeignAffiliate?.countryId)}
								disabled={true}
							/>
						</div>
						<div className="field">
							<Input
								type={'text'}
								title={t('addExistingForeignAffiliates.currencyId')||''}
								value={EngagementUtil.getCurrencyCode(selectedForeignAffiliate?.currencyId)}
								disabled={true}
							/>
						</div>
						<div className="field">
							<Input
								type={'text'}
								title={t('addExistingForeignAffiliates.taxationYearEnd')||''}
								value={dateFormatter.toMMMdd(selectedForeignAffiliate?.taxationYearEnd)}
								disabled={true}
							/>
						</div>

						<div className="field">
							<CalendarPickerWrapper
								format={'MMM DD, YYYY'}
								editable={false}
								disabled={!selectedForeignAffiliate?.name}
								locale="en"
								fieldTitle={t('addExistingForeignAffiliates.dateBecameFa')||''}
								fieldWidth={'100%'}
								required={true}
								minDate={new Date('1972,1,1')}
								value={selectedForeignAffiliate?.dateBecameFa}
								onChange={(val: Date) => updateDate('dateBecameFa', val)}
								datepickerAlwaysDown={false}
								datePanelHorizontalAlign="right"
							/>
						</div>
						<div className="field">
							<CalendarPickerWrapper
								format={'MMM DD, YYYY'}
								editable={false}
								disabled={!selectedForeignAffiliate?.name}
								locale="en"
								fieldTitle={t('addExistingForeignAffiliates.analysisStartDate')||''}
								fieldWidth={'100%'}
								required={true}
								error={(!!faApiErrors && 'AnalysisStartDate' in faApiErrors)}
								useCustomValidation={true}
								customErrorNode={
									<div
										id="errormessage"
										aria-live="polite"
										className={'ap-field-email-validation-error'}
									>
										{`${errorMessages(`code.${faApiErrors?.['AnalysisStartDate']?.ErrorCode}`)}`}
									</div>
								}
								minDate={new Date('1972,1,1')}
								maxDate = {props.engagementDetail?.lastCalculationTaxationYearEnd}
								value={selectedForeignAffiliate?.analysisStartDate}
								onChange={(val: Date) => updateDate('analysisStartDate', val, 'AnalysisStartDate')}
								datepickerAlwaysDown={false}
								datePanelHorizontalAlign="right"
							/>
						</div>
						<div className="field">
							<CalendarPickerWrapper
								format={'MMM DD, YYYY'}
								editable={false}
								disabled={!selectedForeignAffiliate?.name}
								locale="en"
								fieldTitle={t('addExistingForeignAffiliates.analysisEndDate')||''}
								fieldWidth={'100%'}
								required={false}
								minDate={selectedForeignAffiliate?.analysisStartDate}
								maxDate = {props.engagementDetail?.lastCalculationTaxationYearEnd}
								error={invalidAnalysisEndDate}
								useCustomValidation={true}
								customErrorNode={
									<div
										id="errormessage"
										aria-live="polite"
										className={'ap-field-email-validation-error'}
									>
										{`${errorMessages('code.INVALID_ANALYSIS_END_DATE')}`}
									</div>
								}
								value={selectedForeignAffiliate?.analysisEndDate}
								onChange={(val: Date) => updateDate('analysisEndDate', val)}
								datepickerAlwaysDown={false}
								datePanelHorizontalAlign="right"
							/>
						</div>

					</div>
					{loading && (
						<div className="loading-icon">
							<Loading
								loadingType="circular"
								indeterminate={true}
								compact={false}
							/>
						</div>
					)}
				</div>

			</Modal>
			<UnsavedChangesModal
				visible={showUnsavedModal}
				onClose={closeUnsavedModal}
				title={t('unsaved-popup.discardChangesTitle') || ''}
			/>
			<UnexpectedErrorModal
				visible={showUnexpectedErrorModal}
				onClose={closeUnexpectedErrorModal}
				message={unexpectedError}
				error={''}
				statusCode={apiStatusCode}
			/>
		</div>
	);
};
