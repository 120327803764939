import axios from 'axios';
import { IClientFilter } from '../modules/IClientFilter';
import { EngagementPersonalizationStatus } from '../types/enums/EngagementPersonalizationStatus';

export const coreService = {
	getClients: async (clientFilter: IClientFilter) =>
	{
		return axios.post(`${process.env.REACT_APP_WEBAPI}/Client/Search`, clientFilter);
	},

	getAllClients: async () =>
	{
		return axios.get(`${process.env.REACT_APP_WEBAPI}/Client`);
	},

	searchEngagements: async (clientId: string, clientFilter: IClientFilter) =>
	{
		return axios.post(`${process.env.REACT_APP_WEBAPI}/Client/${clientId}/Engagements`, clientFilter);
	},

	cancelPendingEngagement: async (engagementId: number) =>
	{
		return axios.post(`${process.env.REACT_APP_WEBAPI}/Engagement/pending/cancel`, {
			engagementId: engagementId
		});
	},

	changeEngagementStatus: async (
		engagementId: string,
		originalStatus: EngagementPersonalizationStatus,
		newStatus: EngagementPersonalizationStatus
	) =>
	{
		return axios.patch(`${process.env.REACT_APP_WEBAPI}/Engagement`, {
			engagementId: engagementId,
			originalStatus: originalStatus,
			newStatus: newStatus
		});
	},

	deleteEngagement: async (engagementId: string) =>
	{
		return axios.delete(`${process.env.REACT_APP_WEBAPI}/Engagement/${engagementId}`);
	},

	createNewClient: async (clientName: string, iPowerCode: string) =>
	{
		return axios.post(`${process.env.REACT_APP_WEBAPI}/Client`, {
			clientName: clientName,
			iPowerCode: iPowerCode
		});
	},

	updateClientDetail: async (clientName: string, iPowerCode: string, clientId: number) =>
	{
		return axios.patch(`${process.env.REACT_APP_WEBAPI}/Client`, {
			clientName: clientName,
			iPowerCode: iPowerCode,
			clientId: clientId
		});
	},

	getTaxpayers: async (engagementPersonalizationStatus: EngagementPersonalizationStatus) =>
	{
		return axios.get(`${process.env.REACT_APP_WEBAPI}/Engagement/TaxPayers/${engagementPersonalizationStatus}`);
	}

};
