import { useTranslation } from 'react-i18next';
import {
	Button,
	Input,
	Modal,
	Select,
	TextArea
} from '@appkit4/react-components';
import { useEffect, useState } from 'react';
import { IDividendReceivedOthers } from 'src/features/engagement/models/IDividendReceivedOthers';
import { DividendReceivedFromOthersFields } from 'src/common/types/enums/DividendsReceivedFromOtherFields';
import { SelectValue } from '@appkit4/react-components/esm/combobox/Combobox';
import { RegularPatternEnum } from 'src/common/types/enums/RegularPatternEnum';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { Utils } from 'src/common/utils/utils';
import { isEqual } from 'lodash';
import { IForeignAffiliate } from 'src/features/engagement/models/IForeignAffiliate';
import { IEngagementDetail } from 'src/features/engagement/models/IEngagementDetail';
import { ILineItem } from 'src/common/types/interfaces/ILineItem';
import { NotificationService } from 'src/core/services/notificationService';
import { DeleteModal } from 'src/common/components/DeleteModal/DeleteModal';
import './DividendsReceivedForm.scss';
import { UnsavedChangesModal } from 'src/core/components/Dashboard/unsavedModal/UnsavedChangesModal';
import { UnexpectedErrorModal } from 'src/common/components/UnexpectedErrorModal/UnexpectedErrorModal';
import { InputError } from 'src/common/components/ModalControls/InputError/InputError';
import { CalendarPickerWrapper } from 'src/common/components/CalendarPickerWrapper/CalendarPickerWrapper';

const defaultFormError: Record<string, string | null> = {
	[DividendReceivedFromOthersFields.payerName]: null,
	[DividendReceivedFromOthersFields.isPayerForeignAffiliate]: null,
	[DividendReceivedFromOthersFields.effectiveDate]: null,
	[DividendReceivedFromOthersFields.payerCurrencyId]: null,
	[DividendReceivedFromOthersFields.amount]: null,
	[DividendReceivedFromOthersFields.exemptSurplus]: null,
	[DividendReceivedFromOthersFields.hybridSurplus]: null,
	[DividendReceivedFromOthersFields.hybridUnderlyingTax]: null,
	[DividendReceivedFromOthersFields.taxableSurplus]: null,
	[DividendReceivedFromOthersFields.underlyingForeignTax]: null,
	[DividendReceivedFromOthersFields.preAcquisitionSurplus]: null,
	[DividendReceivedFromOthersFields.notes]: null
};

export interface IDividendsReceivedFormProps {

	isAdd: boolean;
	displayModal: boolean;

	record?: IDividendReceivedOthers;
	foreignAffiliate?: IForeignAffiliate;
	engagementInfo?: IEngagementDetail;
	lineConfiguration: ILineItem[];

	onReset: () => void;
	onRefresh: () => void;
	onToggleDisplayModal: (display: boolean) => void;
}


export function DividendsReceivedForm(props: IDividendsReceivedFormProps): JSX.Element
{
	const initialFormData = {
		[DividendReceivedFromOthersFields.id]: 0 ,
		[DividendReceivedFromOthersFields.engagementId]: 0,
		[DividendReceivedFromOthersFields.affiliateId]: 0,
		[DividendReceivedFromOthersFields.effectiveDate]: undefined,
		[DividendReceivedFromOthersFields.payerName]: '',
		[DividendReceivedFromOthersFields.isPayerForeignAffiliate]: undefined,
		[DividendReceivedFromOthersFields.payerCurrencyId]: undefined,
		[DividendReceivedFromOthersFields.amount]: undefined,
		[DividendReceivedFromOthersFields.paidOnEquityClassId]: undefined,
		[DividendReceivedFromOthersFields.percentageOwned]: undefined,
		[DividendReceivedFromOthersFields.dividendReceivedFrom]: undefined,
		[DividendReceivedFromOthersFields.exemptSurplus]: undefined,
		[DividendReceivedFromOthersFields.hybridSurplus]: undefined,
		[DividendReceivedFromOthersFields.hybridUnderlyingTax]: undefined,
		[DividendReceivedFromOthersFields.taxableSurplus]: undefined,
		[DividendReceivedFromOthersFields.underlyingForeignTax]: undefined,
		[DividendReceivedFromOthersFields.preAcquisitionSurplus]: undefined,
		[DividendReceivedFromOthersFields.notes]: ''
	};
	const [record, setRecord] = useState<IDividendReceivedOthers | undefined>(initialFormData);


	const [hasChange, setHasChange] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isForeignAffiliate, setIsForeignAffiliate] = useState(true);
	const [totalMismatch, setTotalMismatch] = useState(false);

	const [formError, setFormError] = useState<Record<string, any>>(defaultFormError);

	const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
	const [displayUnsavedChangesModal, setDisplayUnsavedChangesModal] = useState(false);
	const [errorModalMessage, setErrorModalMessage] = useState('');


	const { t } = useTranslation(
		'input',
		{
			keyPrefix: 'dividendsReceivedForm'
		}
	);

	const { t: fieldNames }= useTranslation(
		'input',
		{
			keyPrefix: 'dividendsReceivedFromOthersFieldNames'
		}
	);

	const { t: errorMessages } = useTranslation(
		'input',
		{
			keyPrefix: 'dividendsReceivedFormErrorMessages'
		}
	);

	const checkUpdateFormValidity = (form: IDividendReceivedOthers): boolean =>
	{
		const errors = {
			...defaultFormError
		};

		if (form)
		{
			setTotalMismatch(false);
			if (!form[DividendReceivedFromOthersFields.effectiveDate])
			{
				errors[DividendReceivedFromOthersFields.effectiveDate] = errorMessages('dateRequired');
			}

			if (!form[DividendReceivedFromOthersFields.payerName])
			{
				errors[DividendReceivedFromOthersFields.payerName] = errorMessages('payerNameRequired');
			}
			if (form[DividendReceivedFromOthersFields.isPayerForeignAffiliate]===undefined)
			{
				errors[DividendReceivedFromOthersFields.isPayerForeignAffiliate] = errorMessages('isPayerForeignAffiliateRequired');
			}

			if (!form[DividendReceivedFromOthersFields.payerCurrencyId])
			{
				errors[DividendReceivedFromOthersFields.payerCurrencyId] = errorMessages('payerCurrencyIdRequired');
			}

			if (!form[DividendReceivedFromOthersFields.paidOnEquityClassId])
			{
				errors[DividendReceivedFromOthersFields.paidOnEquityClassId] = errorMessages('paidOnEquityClassIdRequired');
			}


			if (!form[DividendReceivedFromOthersFields.amount])
			{
				errors[DividendReceivedFromOthersFields.amount] = errorMessages('numberRequired');
			}

			if (form.isPayerForeignAffiliate?.toString()==='true')
			{
				if (form[DividendReceivedFromOthersFields.exemptSurplus]===undefined)
				{
					errors[DividendReceivedFromOthersFields.exemptSurplus] = errorMessages('numberRequired');
				}
				if (form[DividendReceivedFromOthersFields.hybridSurplus]===undefined)
				{
					errors[DividendReceivedFromOthersFields.hybridSurplus] = errorMessages('numberRequired');
				}
				if (form[DividendReceivedFromOthersFields.hybridUnderlyingTax]===undefined)
				{
					errors[DividendReceivedFromOthersFields.hybridUnderlyingTax] = errorMessages('numberRequired');
				}
				if (form[DividendReceivedFromOthersFields.taxableSurplus]===undefined)
				{
					errors[DividendReceivedFromOthersFields.taxableSurplus] = errorMessages('numberRequired');
				}
				if (form[DividendReceivedFromOthersFields.underlyingForeignTax]===undefined)
				{
					errors[DividendReceivedFromOthersFields.underlyingForeignTax] = errorMessages('numberRequired');
				}
				if (form[DividendReceivedFromOthersFields.preAcquisitionSurplus]===undefined)
				{
					errors[DividendReceivedFromOthersFields.preAcquisitionSurplus] = errorMessages('numberRequired');
				}

				if (form[DividendReceivedFromOthersFields.exemptSurplus] &&
					form[DividendReceivedFromOthersFields.hybridSurplus]&&
					form[DividendReceivedFromOthersFields.taxableSurplus]&&
					form[DividendReceivedFromOthersFields.preAcquisitionSurplus])
				{
					const surplus = Number(form.exemptSurplus ?? 0) + Number(form.hybridSurplus ?? 0) +
						Number(form.taxableSurplus ?? 0) + Number(form.preAcquisitionSurplus ?? 0);
					const dividendReceivd = Number(form.amount);
					if (surplus===dividendReceivd)
					{
						setTotalMismatch(false);
					}
					else
					{
						setFormError({
							...errors
						});
						setTotalMismatch(true);
						return true;
					}
				}
				else
				{
					setTotalMismatch(false);
				}
			}

			setFormError({
				...errors
			});
		}
		// check if any fields have error messages and return status
		const validated = Object.values(errors).every((e) => !e);

		return validated;
	};

	useEffect(
		() =>
		{
			setIsForeignAffiliate(props.record?.isPayerForeignAffiliate??false);
			if (!!props.record)
			{
				setRecord({
					...props.record
				});
			}
			else
			{
				setRecord(undefined);
			}
		},
		[props.record]
	);

	const handleApiErrorResponse = (err: any) =>
	{
		if (err.response)
		{
			if (err.response.status >= 500)
			{
				setErrorModalMessage(errorMessages('createFailed') || '');
				// setUnExpectedError(err.response.data.Errors[0].Message);
				// setShowUnexpectedErrorModal(true);
			}
			else if (err.response.status >= 400)
			{
				const errors = err.response.data.Errors;
				const apiErrors = {
					...defaultFormError
				};
				if (errors)
				{
					errors.forEach((error: any) =>
					{
						if (error.Field === 'payerName')
						{
							apiErrors[DividendReceivedFromOthersFields.payerName] = errorMessages(`code.${error.ErrorCode}`);
						}
						if (error.Field === 'effectiveDate')
						{
							apiErrors[DividendReceivedFromOthersFields.effectiveDate] = errorMessages(`code.${error.ErrorCode}`);
						}
						if (error.Field === 'amount')
						{
							//apiErrors[DividendReceivedFromOthersFields.amount] = errorMessages(`code.${error.ErrorCode}`);
							setTotalMismatch(true);
						}
					});
					setFormError({
						...apiErrors
					});
				}
			}
		}
	};

	const onCreate = async (): Promise<void> =>
	{
		if (record&&!isSaving)
		{
			setIsSubmitted(true);

			if (!!checkUpdateFormValidity(record!))
			{
				setIsSaving(true);
				const payload = {
					...record,
					'id': 0,
					'isPayerForeignAffiliate': record.isPayerForeignAffiliate?.toString()==='true',
					'paidOnEquityClassId': record.paidOnEquityClassId?record.paidOnEquityClassId:undefined,
					'affiliateId': props.foreignAffiliate?.affiliateId||0
				};

				await engagementService.addDividendReceivedFromOthers(
					payload
				).then((res: any) =>
				{
					onCloseModal(true);

					onSuccessNotification({
						isCreate: true
					});
				})
					.catch((error: any) =>
					{
						setIsSaving(false);
						handleApiErrorResponse(error);
					});
			}
		}
	};

	const onUpdate = async (): Promise<void> =>
	{
		if (record&&!isSaving)
		{
			setIsSubmitted(true);

			if (!!checkUpdateFormValidity(record!))
			{
				setIsSaving(true);
				const payload = {
					...record,
					'isPayerForeignAffiliate': record.isPayerForeignAffiliate?.toString()==='true',
					'engagementId': props.engagementInfo?.id!,
					'paidOnEquityClassId': record.paidOnEquityClassId?record.paidOnEquityClassId:undefined,
					'affiliateId': props.foreignAffiliate?.affiliateId||0
				};

				engagementService.updateDividendReceivedFromOthers(
					payload!
				).then((res: any) =>
				{
					onCloseModal(true);
					setIsSaving(false);

					onSuccessNotification({
						isUpdate: true
					});
				})
					.catch((error: any) =>
					{
						// setErrorModalMessage(errorMessages('updateFailed') || '');
						setIsSaving(false);
						handleApiErrorResponse(error);
					});
			}
		}
	};

	const onDelete = async (): Promise<void> =>
	{
		if (record&&!isSaving)
		{
			setIsSubmitted(true);

			setDisplayDeleteModal(false);

			engagementService.deleteDividendReceivedFromOthers(
					props.engagementInfo?.id!, record!.id
			).then((res: any) =>
			{
				setIsSaving(true);
				onCloseModal(true);
				onSuccessNotification({
					isDelete: true
				});
			})
				.catch((error: any) =>
				{
					setErrorModalMessage(errorMessages('deleteFailed') || '');
					setIsSaving(false);
				});
		}
	};

	const onDisplayDeleteModal = (): void =>
	{
		setDisplayDeleteModal(true);
	};


	const onCloseModal = (isSuccess?: boolean): void =>
	{
		if (!!hasChange && !isSuccess)
		{
			setDisplayUnsavedChangesModal(true);
		}
		else
		{
			onClose(!!isSuccess);
		}
	};

	const onClose = async (isSuccess?: boolean): Promise<void> =>
	{
		setDisplayUnsavedChangesModal(false);
		props.onToggleDisplayModal(false);

		await Utils.timeout(500);

		onReset();
		props.onReset();

		if (!!isSuccess)
		{
			props.onRefresh();
		}
	};

	const onSuccessNotification = (
		{
			isCreate,
			isUpdate,
			isDelete
		}:
		{
			isCreate?: boolean;
			isUpdate?: boolean;
			isDelete?: boolean;
		}
	) =>
	{
		let message = '';

		if (isCreate)
		{
			message = t('createSuccess');
		}
		else if (isUpdate)
		{
			message = t('updateSuccess');
		}
		else if (isDelete)
		{
			message = t('deleteSuccess');
		}

		NotificationService.success({
			message
		});
	};

	const onCloseUnsavedChangesModal = (key: 'leave' | 'stay'): void =>
	{
		if (key === 'leave')
		{
			onClose();
		}
		else
		{
			setDisplayUnsavedChangesModal(false);
		}
	};


	const onReset = () =>
	{
		setIsSaving(false);
		setHasChange(false);
		setRecord(undefined);
		setIsSubmitted(false);
		setErrorModalMessage('');
		setDisplayDeleteModal(false);
		setDisplayUnsavedChangesModal(false);
		setFormError({
			...formError
		});
	};

	const onValueChange = (field: string, value: any): void =>
	{
		if (field === DividendReceivedFromOthersFields.isPayerForeignAffiliate)
		{
			setIsForeignAffiliate(value==='true');
		}

		const updatedForm: IDividendReceivedOthers = {
			...record!,
			[field]: value === '' ? undefined : value
		};

		 checkUpdateFormValidity(updatedForm);
		setRecord(updatedForm);
	};

	useEffect(
		() =>
		{
			if (!isForeignAffiliate)
			{
				const updatedForm: IDividendReceivedOthers = {
					...record!,
					[DividendReceivedFromOthersFields.exemptSurplus]: undefined,
					[DividendReceivedFromOthersFields.hybridSurplus]: undefined,
					[DividendReceivedFromOthersFields.hybridUnderlyingTax]: undefined,
					[DividendReceivedFromOthersFields.taxableSurplus]: undefined,
					[DividendReceivedFromOthersFields.underlyingForeignTax]: undefined,
					[DividendReceivedFromOthersFields.preAcquisitionSurplus]: undefined
				};

				 checkUpdateFormValidity(updatedForm);
				setRecord(updatedForm);
			}
		},
		[isForeignAffiliate]
	);

	useEffect(
		() =>
		{
			setHasChange(
				!isEqual(props.record, record)
			);
		},
		[record]
	);


	return (
		<>
			<Modal
				className={'dividends-received-modal'}
				visible={
					props.displayModal
				}
				closeOnPressEscape={false}
				maskCloseable={false}
				initialFocus={false}
				title={
					!!props.isAdd ?
						t('addModalTitle') || '' :
						t('editModalTitle') || ''
				}
				onCancel={() =>
				{
					onCloseModal();
				}}
				footer={
					<div className={'buttons'}>
						<div className={'left'}>
							{
								!props.isAdd &&
								<Button
									kind={'negative'}
									disabled={!!isSaving}
									onClick={() => onDisplayDeleteModal()}
								>
									{
										t('deleteButtonText')
									}
								</Button>
							}
						</div>
						<div className={'right'}>
							{
								!!props.isAdd &&
							<Button
								disabled={
									!hasChange||isSaving||totalMismatch
								}
								onClick={onCreate}
							>
								{
									t('saveButtonText')
								}
							</Button>
							}
							{
								!props.isAdd &&
							<Button
								disabled={
								   !hasChange||isSaving||totalMismatch
								}
								onClick={onUpdate}
							>
								{
									t('saveButtonText')
								}
							</Button>
							}
							<Button
								kind={'secondary'}
								onClick={() =>
								{
									onCloseModal();
								}}
							>
								{
									t('cancelButtonText')
								}
							</Button>
						</div>
					</div>
				}
			>
				{!!props.record &&
				!!record &&
				!!props.lineConfiguration &&
				!!props.lineConfiguration.length &&
					<div className={'dividends-received-modal-body'}>
						<div className={'dividends-received-form-body'}>
							<div>
								<Input
								  type={'text'}
									required
									title={
										fieldNames(DividendReceivedFromOthersFields.payerName) || ''
									}
									value={
										!!record[DividendReceivedFromOthersFields.payerName] ?
											record[DividendReceivedFromOthersFields.payerName] :
											''
									}
									maxLength={255}
									error={
										!!isSubmitted &&
									!!formError &&
									!!formError[DividendReceivedFromOthersFields.payerName]
									}
									onChange={(value: string) =>
									{
										onValueChange(DividendReceivedFromOthersFields.payerName, value);
									}}
								/>
								<InputError
									isError={
										!!isSubmitted &&
									!!formError &&
									!!formError[DividendReceivedFromOthersFields.payerName]
									}
									message={formError[DividendReceivedFromOthersFields.payerName]}
								/>
							</div>
							<div>
								<Select
									required
									data={
										!!props.lineConfiguration &&
										props.lineConfiguration.some(
											(l) => l.field === DividendReceivedFromOthersFields.isPayerForeignAffiliate) ?
											props.lineConfiguration.find(
												(l) => l.field === DividendReceivedFromOthersFields.isPayerForeignAffiliate)!
												.options :
											[]
									}
									labelKey={'label'}
									valueKey={'value'}
									dropdownRenderMode={'portal'}
									value={record[DividendReceivedFromOthersFields.isPayerForeignAffiliate]?.toString()}
									placeholder={
										fieldNames(DividendReceivedFromOthersFields.isPayerForeignAffiliate) || ''
									}
									error={
										!!isSubmitted &&
									!!formError &&
									!!formError[DividendReceivedFromOthersFields.isPayerForeignAffiliate]
									}
									noResultFound={
										<>
											{
												t('noDataAvailable')
											}
										</>
									}
									onSelect={(value: SelectValue) =>
										onValueChange(DividendReceivedFromOthersFields.isPayerForeignAffiliate, value)
									}
								/>
								<InputError
									isError={
										!!isSubmitted &&
									!!formError &&
									!!formError[DividendReceivedFromOthersFields.isPayerForeignAffiliate]
									}
									message={formError[DividendReceivedFromOthersFields.isPayerForeignAffiliate]}
								/>
							</div>

							<div className={'calendar-time'}>
								<CalendarPickerWrapper
									required
									useCustomValidation
									editable={false}
									fieldTitle={
										fieldNames(DividendReceivedFromOthersFields.effectiveDate) || ''
									}
									format={'MMM DD, YYYY'}
									value={record[DividendReceivedFromOthersFields.effectiveDate]}
									minDate={
										!!props.foreignAffiliate && !!props.foreignAffiliate.analysisStartDate ?
											props.foreignAffiliate.analysisStartDate :
											new Date(1972, 1, 1)
									}
									maxDate={
										!!props.engagementInfo && !!props.engagementInfo.lastCalculationTaxationYearEnd ?
											props.engagementInfo.lastCalculationTaxationYearEnd :
											new Date()
									}
									datePanelHorizontalAlign={'right'}
									error={
										!!isSubmitted &&
									!!formError &&
									!!formError[DividendReceivedFromOthersFields.effectiveDate]
									}
									customErrorNode={<></>}
									onChange={(value: Date) =>
									{
										onValueChange(DividendReceivedFromOthersFields.effectiveDate, value?new Date(value):undefined);
									}}
								/>
								<InputError
									isError={
										!!isSubmitted &&
									!!formError &&
									!!formError[DividendReceivedFromOthersFields.effectiveDate]
									}
									message={formError[DividendReceivedFromOthersFields.effectiveDate]}
								/>
							</div>
							<div>
								<Select
									required
									searchable
									data={
										!!props.lineConfiguration &&
										props.lineConfiguration.some(
											(l) => l.field === DividendReceivedFromOthersFields.paidOnEquityClassId) ?
											props.lineConfiguration.find(
												(l) => l.field === DividendReceivedFromOthersFields.paidOnEquityClassId)!
												.options :
											[]
									}
									labelKey={'label'}
									valueKey={'value'}
									dropdownRenderMode={'portal'}
									dropdownAlwaysDown={false}
									value={record[DividendReceivedFromOthersFields.paidOnEquityClassId]}
									placeholder={
										fieldNames(DividendReceivedFromOthersFields.paidOnEquityClassId) || ''
									}
									noResultFound={
										<>
											{
												t('noDataAvailable')
											}
										</>
									}
									error={
										!!isSubmitted &&
									!!formError &&
									!!formError[DividendReceivedFromOthersFields.paidOnEquityClassId]
									}
									onSelect={(value: SelectValue) =>
										onValueChange(DividendReceivedFromOthersFields.paidOnEquityClassId, value)
									}
								/>
								<InputError
									isError={
										!!isSubmitted &&
									!!formError &&
									!!formError[DividendReceivedFromOthersFields.paidOnEquityClassId]
									}
									message={formError[DividendReceivedFromOthersFields.paidOnEquityClassId]}
								/>
							</div>
							<div>
								<Input
									type={'text'}
									title={
										fieldNames(DividendReceivedFromOthersFields.percentageOwned) + props.foreignAffiliate?.name || ''
									}
									value={
										Utils.isValidNumber(`${record[DividendReceivedFromOthersFields.percentageOwned]}`) ?
											record[DividendReceivedFromOthersFields.percentageOwned] :
											''
									}
									onChange={(value: string) =>
									{
										const regPattern = new RegExp(RegularPatternEnum.Percentage);
										if (!regPattern.test(value))
										{
											return;
										}
										if (Number(value)>100||Number(value)<0)
										{
											return;
										}

										onValueChange(
											DividendReceivedFromOthersFields.percentageOwned,
											Utils.isValidNumber(value) ?
												value :
												''
										);
									}}
								/>
							</div>
							<div>
								<Select
									required
									searchable
									data={
										!!props.lineConfiguration &&
										props.lineConfiguration.some((l) => l.field === DividendReceivedFromOthersFields.payerCurrencyId) ?
											props.lineConfiguration.find(
												(l) => l.field === DividendReceivedFromOthersFields.payerCurrencyId)!
												.options :
											[]
									}
									labelKey={'label'}
									valueKey={'value'}
									dropdownRenderMode={'portal'}
									dropdownAlwaysDown={false}
									value={record[DividendReceivedFromOthersFields.payerCurrencyId]}
									placeholder={
										fieldNames(DividendReceivedFromOthersFields.payerCurrencyId) || ''
									}
									error={
										!!isSubmitted &&
									!!formError &&
									!!formError[DividendReceivedFromOthersFields.payerCurrencyId]
									}
									noResultFound={
										<>
											{
												t('noDataAvailable')
											}
										</>
									}
									onSelect={(value: SelectValue) =>
										onValueChange(DividendReceivedFromOthersFields.payerCurrencyId, value)
									}
								/>
								<InputError
									isError={
										!!isSubmitted &&
									!!formError &&
									!!formError[DividendReceivedFromOthersFields.payerCurrencyId]
									}
									message={formError[DividendReceivedFromOthersFields.payerCurrencyId]}
								/>
							</div>
							{totalMismatch &&<div className="total-mismatch-error">
								{errorMessages('totalMismatch')}
							</div>}
							<div>
								<Input
									required
									type={'text'}
									title={
										fieldNames(DividendReceivedFromOthersFields.amount) || ''
									}
									value={
										Utils.isValidNumber(`${record[DividendReceivedFromOthersFields.amount]}`) ?
											record[DividendReceivedFromOthersFields.amount] :
											''
									}
									error={
										totalMismatch||(
											!!isSubmitted &&
									!!formError &&
									!!formError[DividendReceivedFromOthersFields.amount])
									}
									onChange={(value: string) =>
									{
										const regPattern = new RegExp(RegularPatternEnum.CurrencyNonNegative);
										if (!regPattern.test(value))
										{
											return;
										}

										onValueChange(
											DividendReceivedFromOthersFields.amount,
											Utils.isValidNumber(value) ?
												value :
												''
										);
									}}
								/>
								<InputError
									isError={
										!!isSubmitted &&
									!!formError &&
									!!formError[DividendReceivedFromOthersFields.amount]
									}
									message={formError[DividendReceivedFromOthersFields.amount]}
								/>
							</div>
							<div className="dividend-recieved-from">
								Dividend received from:
							</div>
							<div>
								<Input
									required={isForeignAffiliate}
									type={'text'}
									title={
										fieldNames(DividendReceivedFromOthersFields.exemptSurplus) || ''
									}
									value={
										Utils.isValidNumber(`${record[DividendReceivedFromOthersFields.exemptSurplus]}`) ?
											record[DividendReceivedFromOthersFields.exemptSurplus] :
											''
									}
									disabled={!isForeignAffiliate}
									error={
										totalMismatch||(
											!!isSubmitted &&
									!!formError &&
									!!formError[DividendReceivedFromOthersFields.exemptSurplus])
									}
									onChange={(value: string) =>
									{
										const regPattern = new RegExp(RegularPatternEnum.CurrencyNonNegative);
										if (!regPattern.test(value))
										{
											return;
										}

										onValueChange(
											DividendReceivedFromOthersFields.exemptSurplus,
											Utils.isValidNumber(value) ?
												value :
												''
										);
									}}
								/>
								<InputError
									isError={
										!!isSubmitted &&
									!!formError &&
									!!formError[DividendReceivedFromOthersFields.exemptSurplus]
									}
									message={formError[DividendReceivedFromOthersFields.exemptSurplus]}
								/>
							</div>
							<div>
								<Input
									required={isForeignAffiliate}
									type={'text'}
									title={
										fieldNames(DividendReceivedFromOthersFields.hybridSurplus) || ''
									}
									value={
										Utils.isValidNumber(`${record[DividendReceivedFromOthersFields.hybridSurplus]}`) ?
											record[DividendReceivedFromOthersFields.hybridSurplus] :
											''
									}
									disabled={!isForeignAffiliate}
									error={
										totalMismatch||(
											!!isSubmitted &&
									!!formError &&
									!!formError[DividendReceivedFromOthersFields.hybridSurplus])
									}
									onChange={(value: string) =>
									{
										const regPattern = new RegExp(RegularPatternEnum.CurrencyNonNegative);
										if (!regPattern.test(value))
										{
											return;
										}

										onValueChange(
											DividendReceivedFromOthersFields.hybridSurplus,
											Utils.isValidNumber(value) ?
												value :
												''
										);
									}}
								/>
								<InputError
									isError={
										!!isSubmitted &&
									!!formError &&
									!!formError[DividendReceivedFromOthersFields.hybridSurplus]
									}
									message={formError[DividendReceivedFromOthersFields.hybridSurplus]}
								/>
							</div>
							<div>
								<Input
									required={isForeignAffiliate}
									type={'text'}
									title={
										fieldNames(DividendReceivedFromOthersFields.hybridUnderlyingTax) || ''
									}
									value={
										Utils.isValidNumber(`${record[DividendReceivedFromOthersFields.hybridUnderlyingTax]}`) ?
											record[DividendReceivedFromOthersFields.hybridUnderlyingTax] :
											''
									}
									disabled={!isForeignAffiliate}
									error={
										!!isSubmitted &&
									!!formError &&
									!!formError[DividendReceivedFromOthersFields.hybridUnderlyingTax]
									}
									onChange={(value: string) =>
									{
										const regPattern = new RegExp(RegularPatternEnum.CurrencyNonNegative);
										if (!regPattern.test(value))
										{
											return;
										}

										onValueChange(
											DividendReceivedFromOthersFields.hybridUnderlyingTax,
											Utils.isValidNumber(value) ?
												value :
												''
										);
									}}
								/>
								<InputError
									isError={
										!!isSubmitted &&
									!!formError &&
									!!formError[DividendReceivedFromOthersFields.hybridUnderlyingTax]
									}
									message={formError[DividendReceivedFromOthersFields.hybridUnderlyingTax]}
								/>
							</div>
							<div>
								<Input
									required={isForeignAffiliate}
									type={'text'}
									title={
										fieldNames(DividendReceivedFromOthersFields.taxableSurplus) || ''
									}
									value={
										Utils.isValidNumber(`${record[DividendReceivedFromOthersFields.taxableSurplus]}`) ?
											record[DividendReceivedFromOthersFields.taxableSurplus] :
											''
									}
									disabled={!isForeignAffiliate}
									error={
										totalMismatch||(
											!!isSubmitted &&
									!!formError &&
									!!formError[DividendReceivedFromOthersFields.taxableSurplus])
									}
									onChange={(value: string) =>
									{
										const regPattern = new RegExp(RegularPatternEnum.CurrencyNonNegative);
										if (!regPattern.test(value))
										{
											return;
										}

										onValueChange(
											DividendReceivedFromOthersFields.taxableSurplus,
											Utils.isValidNumber(value) ?
												value :
												''
										);
									}}
								/>
								<InputError
									isError={
										!!isSubmitted &&
									!!formError &&
									!!formError[DividendReceivedFromOthersFields.taxableSurplus]
									}
									message={formError[DividendReceivedFromOthersFields.taxableSurplus]}
								/>
							</div>
							<div>
								<Input
									required={isForeignAffiliate}
									type={'text'}
									title={
										fieldNames(DividendReceivedFromOthersFields.underlyingForeignTax) || ''
									}
									value={
										Utils.isValidNumber(`${record[DividendReceivedFromOthersFields.underlyingForeignTax]}`) ?
											record[DividendReceivedFromOthersFields.underlyingForeignTax] :
											''
									}
									disabled={!isForeignAffiliate}
									error={
										!!isSubmitted &&
									!!formError &&
									!!formError[DividendReceivedFromOthersFields.underlyingForeignTax]
									}
									onChange={(value: string) =>
									{
										const regPattern = new RegExp(RegularPatternEnum.CurrencyNonNegative);
										if (!regPattern.test(value))
										{
											return;
										}

										onValueChange(
											DividendReceivedFromOthersFields.underlyingForeignTax,
											Utils.isValidNumber(value) ?
												value :
												''
										);
									}}
								/>
								<InputError
									isError={
										!!isSubmitted &&
									!!formError &&
									!!formError[DividendReceivedFromOthersFields.underlyingForeignTax]
									}
									message={formError[DividendReceivedFromOthersFields.underlyingForeignTax]}
								/>
							</div>
							<div>
								<Input
									required={isForeignAffiliate}
									type={'text'}
									title={
										fieldNames(DividendReceivedFromOthersFields.preAcquisitionSurplus) || ''
									}
									value={
										Utils.isValidNumber(`${record[DividendReceivedFromOthersFields.preAcquisitionSurplus]}`) ?
											record[DividendReceivedFromOthersFields.preAcquisitionSurplus] :
											''
									}
									disabled={!isForeignAffiliate}
									error={
										totalMismatch||(
											!!isSubmitted &&
									!!formError &&
									!!formError[DividendReceivedFromOthersFields.preAcquisitionSurplus])
									}
									onChange={(value: string) =>
									{
										const regPattern = new RegExp(RegularPatternEnum.Currency);
										if (!regPattern.test(value))
										{
											return;
										}

										onValueChange(
											DividendReceivedFromOthersFields.preAcquisitionSurplus,
											Utils.isValidNumber(value) ?
												value :
												''
										);
									}}
								/>
								<InputError
									isError={
										!!isSubmitted &&
									!!formError &&
									!!formError[DividendReceivedFromOthersFields.preAcquisitionSurplus]
									}
									message={formError[DividendReceivedFromOthersFields.preAcquisitionSurplus]}
								/>
							</div>
							<div>
								<TextArea
									autosize
									title={
										fieldNames(DividendReceivedFromOthersFields.notes) || ''
									}
									disabled={!isForeignAffiliate}
									value={
										!!record[DividendReceivedFromOthersFields.notes] ?
											record[DividendReceivedFromOthersFields.notes] :
											''
									}
									maxLength={255}
									onChange={(value: string) =>
									{
										onValueChange(DividendReceivedFromOthersFields.notes, value);
									}}
								/>

							</div>
						</div>
					</div>
				}
				<UnsavedChangesModal
					visible={displayUnsavedChangesModal}
					onClose={onCloseUnsavedChangesModal}
					title={t('discardChangesTitle') || ''}
				/>
				<UnexpectedErrorModal
					visible={!!errorModalMessage}
					error={''}
					message={errorModalMessage}
					onClose={() =>
					{
						setErrorModalMessage('');
					}}
				/>
				<DeleteModal
					visible={displayDeleteModal}
					title={t('deleteModalTitle')}
					deleteMessage={t('deleteMessage')}
					setVisible={setDisplayDeleteModal}
					onDelete={onDelete}
				/>
			</Modal>
		</>
	);
}
