import { Badge, ItemDataType, Select } from '@appkit4/react-components';
import React from 'react';
import './StatusCell.scss';

type ValueType = string | number;
type SelectValue = ValueType | ValueType[];

const StatusCell = () =>
{
	const [value, setValue] = React.useState<SelectValue>('');
	const data = [
		{
			value: 'In Progress',
			label: 'In Progress',
			background: '#F3F3F3',
			color: '#252525'
		},
		{
			value: 'In PwC Review',
			label: 'In PwC Review',
			background: '#FFBF1F',
			color: '#252525'
		},
		{
			value: 'PwC Reviewed',
			label: 'PwC Reviewed',
			background: '#D04A02',
			color: '#D04A02'
		}
	];


	return (
		<Select
			data={data}
			value={value}
			hideTitleOnInput={true}
			placeholder={''}
			dropdownAlwaysDown={true}
			itemTemplate={(label: React.ReactNode, item: ItemDataType) =>
			{
				return (item.label && <Badge size="lg"
					className="status-cell-badge"
				 style={{
						border: `1px solid ${item.color}`,
						color: `${item.color}`,
						backgroundColor: `${item.background}31`
					}} value={item.label}></Badge>);
			}}
			valueTemplate={(
				value: SelectValue,
				item: ItemDataType
			) =>
			{
				const selectedValue = (
					<div className="selected-val">
						<Badge size="lg"
							className="status-cell-badge"
						 style={{
								border: `1px solid ${item.color}`,
								color: `${item.color}`,
								backgroundColor: `${item.background}31`
							}} value={value}></Badge>
					</div>
				);
				return selectedValue;
			}}
			onSelect={(vals: SelectValue) => setValue(vals)}>
		</Select>
	);
};

export default StatusCell;