import React from 'react';
import { Navigation, NavigationItem } from '@appkit4/react-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Sidebar = () =>
{
	const { t } = useTranslation('ns1');

	const navList: NavigationItem[] = [
		{
			name: t('sidebar.user-management')
		},
		{
			name: t('sidebar.edit-history')
		},
		{
			name: t('sidebar.recycleBin')
		}
	];

	const navigate = useNavigate();

	// const [collapsed, setCollapsed] = React.useState(false);

	const onClickLogo = (event: React.MouseEvent<HTMLElement>) =>
	{ };

	const redirect = (
		event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
		item: NavigationItem,
		index: number
	) =>
	{
		switch (index)
		{
		case 0:
			navigate('/user');
			break;
		case 1:
			//navigate('/404');
			break;
		case 2:
			//navigate('/404');
			break;
		}
	};

	return (
		<div className="ap-navigation-demo-wrapper" style={{
			flex: '0 0 280px'
		}}>
			<Navigation
				style={{
					height: '100%',
					backgroundColor: '#fafafa'
				}}
				hasLogo={false}
				className="sitedemo"
				showTooltip={true}
				navList={navList}
				onClickLogo={onClickLogo}
				onClickItem={redirect}
				selectedIndex={0}
				hasFooter={false}
				titleTemplate={() =>
					<span
						style={{
							fontFamily: 'Pwc Helvetica Neue',
							fontSize: '24px',
							fontWeight: 600,
							lineHeight: '32px',
							letterSpacing: '-0.4000000059604645px',
							textAlign: 'left'
						}}
					>
						Settings
					</span>

				}
			></Navigation>
		</div>
	);
};

export default Sidebar;
