export enum ReportFieldEnum {
    Decimal='decimal',
	Date='date',
	Time='time',
	DateTime='dateTime',
	Text='text',
	CountryId = 'countryId',
	CurrencyId = 'currencyId',
	CalculatingCurrencyId = 'calculatingCurrencyId',
	EquityClassId = 'equityClassId',
	Currency = 'currency',
	Title='title',
	Subtitle='subtitle',
	Section='section',
	Separator='separator',
	YesNo='yesno',
	Status='status',
	TaxYearEnd='taxYearEnd',
	DispositionType='dispositionType',
	Total='total',
	AmountWithNotes='amountWithNotes',
	Notes='notes',
	Description='description',
	Amount='amount',
	Balance='balance',
	DateWithTime='dateWithTime',
	ColumnHeader='columnHeader',
	Transactions='transactions',
	TypeId='typeId',
	OpeningBalance='openingBalance',
	UFT='uft',
	UFTBalance='uftBalance',
	DescriptionList = 'descriptionList'
}