import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { ReportI18NKeys } from 'src/common/types/enums/ReportI18NKeys';
import { Button, Select, Loading, Notification } from '@appkit4/react-components';
import { NotificationService } from 'src/core/services/notificationService';
import { ReportFieldEnum } from 'src/common/types/enums/ReportFieldEnum';
import EquityClassSectionsByShareholder from './components/EquityClassSectionsByShareholder';
import { ACBDetailsReportFields, ACBDetailsReportCurrencyTypes } from 'src/common/types/enums/AdjustedCostBaseDetailsReportFields';
import './AdjustedCostBaseDetailsReport.scss';

const AdjustedCostBaseDetailsReport = (props: {refershReport: boolean}) =>
{
	const { t } = useTranslation(
		'report',
		{
			keyPrefix: ReportI18NKeys.AdjustedCostBaseDetails
		}
	);

	const currenyList = [
		{
			id: ACBDetailsReportCurrencyTypes.BothCurrencies,
			value: t('currencyListBothCurrencies')
		},
		{
			id: ACBDetailsReportCurrencyTypes.ShareholderCurrency,
			value: t('shareholderCurrency')
		},
		{
			id: ACBDetailsReportCurrencyTypes.TaxpayerCurrency,
			value: t('currencyListTaxpayerCurrency')
		}
	];

	const {engagementId, affiliateId } = useParams();
	const [reportData, setReportData] = useState<any[]>([]);
	const [shareholderList, setShareholderList] = useState<any[]>([]);
	const [adjustedCostBaseMasterData, setAdjustedCostBaseMasterData] = useState<any[]>([]);
	const [selectedCurrencyId, setSelectedCurrencyId] = useState<any>(currenyList[0].id);
	const [selectedCurrency, setSelectedCurrency] = useState<any>();
	const [selectedShareholders, setSelectedShareholders] = useState<any>();
	const [selectedShareholdersData, setSelectedShareholdersData] = useState<any>([]);
	const [isShowDetailDisabled, setIsShowDetailDisabled] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState(false);

	const columnsCount = !!selectedCurrency && selectedCurrency === ACBDetailsReportCurrencyTypes.BothCurrencies ? 11 : 8;

	const triggerErrorNotification = async (message: string): Promise<void> =>
	{
		const ele = (
			<Notification
				  message={message}
				  status={'error'}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};

	const loadAdjustedCostBaseDetailsReportData = async () =>
	{
		if (engagementId && affiliateId)
		{
			setIsLoading(true);
			await engagementService.loadAdjustedCostBaseDetailsReport(Number(engagementId), Number(affiliateId)).then((res: any) =>
			{
				setIsLoading(false);
				const result = res.data.result;

				if (!!result)
				{
					setReportData(!!result.shareholderData ? result.shareholderData : []);
					setShareholderList(!!result.shareholderList ? result.shareholderList : []);

					if (!!props.refershReport && !!result.shareholderData && !!selectedShareholders && selectedShareholders.length > 0)
					{
						setSelectedShareholdersData(result.shareholderData.filter((sh: any) => selectedShareholders.includes(sh.id)));
					}
				}
			})
				.catch((error: any) =>
				{
					triggerErrorNotification(
						t('loadingACBDetailsError')
					);
					setIsLoading(false);
				});
		}
	};

	const loadLookups = async () =>
	{
		await engagementService.getAdjustedCostBaseMasterData()
			.then((res: any) => setAdjustedCostBaseMasterData(!!res && !!res.data && !!res.data.result ? res.data.result : []))
			.catch((error: any) => setAdjustedCostBaseMasterData([]));
	};

	const loadData = async (): Promise<void> =>
	{
		await loadLookups();

		await loadAdjustedCostBaseDetailsReportData();
	};

	const transactions = [
		{
			'name': ACBDetailsReportFields.effectiveDate,
			'type': ReportFieldEnum.Date,
			'isVisible': true

		},
		{
			'name': ACBDetailsReportFields.transactionType,
			'type': ReportFieldEnum.TypeId,
			'isVisible': true
		},
		{
			'name': ACBDetailsReportFields.transactionDescription,
			'type': ReportFieldEnum.Description,
			'isVisible': true
		},
		{
			'name': ACBDetailsReportFields.amountInShareholderCurrency,
			'type': ReportFieldEnum.Currency,
			'isVisible': selectedCurrency  === ACBDetailsReportCurrencyTypes.BothCurrencies ||
				selectedCurrency  === ACBDetailsReportCurrencyTypes.ShareholderCurrency
		},
		{
			'name': ACBDetailsReportFields.balanceInShareholderCurrency,
			'type': ReportFieldEnum.Currency,
			'isVisible': selectedCurrency  === ACBDetailsReportCurrencyTypes.BothCurrencies ||
				selectedCurrency  === ACBDetailsReportCurrencyTypes.ShareholderCurrency
		},
		{
			'name': ACBDetailsReportFields.foreignExchangeRate,
			'type': ReportFieldEnum.Text,
			'isVisible': selectedCurrency  === ACBDetailsReportCurrencyTypes.BothCurrencies
		},
		{
			'name': ACBDetailsReportFields.amountInTaxPayerCurrency,
			'type': ReportFieldEnum.Currency,
			'isVisible': selectedCurrency  === ACBDetailsReportCurrencyTypes.BothCurrencies ||
				selectedCurrency === ACBDetailsReportCurrencyTypes.TaxpayerCurrency
		},
		{
			'name': ACBDetailsReportFields.balanceInTaxPayerCurrency,
			'type': ReportFieldEnum.Currency,
			'isVisible': selectedCurrency  === ACBDetailsReportCurrencyTypes.BothCurrencies ||
				selectedCurrency === ACBDetailsReportCurrencyTypes.TaxpayerCurrency
		},
		{
			'name': ACBDetailsReportFields.shares,
			'type': ReportFieldEnum.Currency,
			'isVisible': true
		},
		{
			'name': ACBDetailsReportFields.sharesBalance,
			'type': ReportFieldEnum.Currency,
			'isVisible': true
		},
		{
			'name': ACBDetailsReportFields.notes,
			'type': ReportFieldEnum.Description,
			'isVisible': true
		}

	];

	const headerFields = [
		{
			'name': ACBDetailsReportFields.effectiveDate,
			'type': ReportFieldEnum.Date,
			'isVisible': true
		},
		{
			'name': ACBDetailsReportFields.transactionType,
			'type': ReportFieldEnum.TypeId,
			'isVisible': true
		},
		{
			'name': ACBDetailsReportFields.transactionDescription,
			'type': ReportFieldEnum.Description,
			'isVisible': true
		},
		{
			'name': ACBDetailsReportFields.shareholderCurrency,
			'type': ReportFieldEnum.Currency,
			'isVisible': selectedCurrency  === ACBDetailsReportCurrencyTypes.BothCurrencies ||
				selectedCurrency  === ACBDetailsReportCurrencyTypes.ShareholderCurrency
		},
		{
			'name': ACBDetailsReportFields.foreignExchangeRate,
			'type': ReportFieldEnum.Text,
			'isVisible': selectedCurrency  === ACBDetailsReportCurrencyTypes.BothCurrencies
		},
		{
			'name': ACBDetailsReportFields.taxPayerCurrency,
			'type': ReportFieldEnum.Currency,
			'isVisible': selectedCurrency  === ACBDetailsReportCurrencyTypes.BothCurrencies ||
				selectedCurrency === ACBDetailsReportCurrencyTypes.TaxpayerCurrency
		},
		{
			'name': ACBDetailsReportFields.shares,
			'type': ReportFieldEnum.Currency,
			'isVisible': true
		},
		{
			'name': ACBDetailsReportFields.sharesBalance,
			'type': ReportFieldEnum.Currency,
			'isVisible': true
		},
		{
			'name': ACBDetailsReportFields.notes,
			'type': ReportFieldEnum.Description,
			'isVisible': true
		}
	];

	const reportFields = [
		{
			'fields': headerFields,
			'type': ReportFieldEnum.ColumnHeader
		},
		{
			'fields': transactions,
			'type': ReportFieldEnum.Transactions
		}
	];

	const showDetail = () =>
	{
		if (!!selectedShareholders && selectedShareholders.length > 0 && !!selectedCurrencyId)
		{
			const fileterdData = reportData.filter((sh: any) => selectedShareholders.includes(sh.id));
			setSelectedShareholdersData(fileterdData);
			setSelectedCurrency(selectedCurrencyId);
		}
		else
		{
			setSelectedShareholdersData([]);
		}
	};

	useEffect(() =>
	{
		loadData();
	},[engagementId, affiliateId]);

	useEffect(() =>
	{
		if (!!props.refershReport)
		{
			loadAdjustedCostBaseDetailsReportData();
		}
	}, [props.refershReport]);

	useEffect(() =>
	{
		setIsShowDetailDisabled(!(!!selectedShareholders && selectedShareholders.length > 0 && !!selectedCurrencyId));
	},[selectedShareholders, selectedCurrency]);

	return (
		<div className='adjusted-cost-base-details-report'>
		 	<div className='table-container'>
		 		{isLoading && (
					<div className="loading-icon">
						<Loading
							loadingType="circular"
							indeterminate={true}
							compact={false}
						/>
					</div>
				)}
				<div className='header-title'>
					{t('header-title')}
				</div>
				<div className='filter-acb-details'>
					<div className='info'>
						{t('filter-selection')}
					</div>
					<div>
						<Select
							data={shareholderList}
							showSelectAll={true}
							placeholder={t('shareholder') || ''}
							multiple
							dropdownRenderMode={'portal'}
							onSelect={(selectedValue) => setSelectedShareholders(selectedValue)}
							valueKey={'key'}
							labelKey={'value'}
						/>
					</div>
					<div>
						<Select
							valueKey={'id'}
							labelKey={'value'}
							data={currenyList}
							value={selectedCurrencyId}
							placeholder={t(ReportFieldEnum.Currency) || ''}
							dropdownRenderMode={'portal'}
							onSelect={(selectedId) => setSelectedCurrencyId(selectedId)}
						/>
					</div>
					<div>
						<Button
							kind={'primary'}
							disabled={isShowDetailDisabled || isLoading}
							onClick={showDetail}
						>
							{t('showDetailButtonText')}
						</Button>
					</div>
				</div>
				{!!selectedShareholdersData && selectedShareholdersData.length > 0 &&
				<>
					<table>
						<tbody>
							{selectedShareholdersData.map(( item: any, index: number) =>
								<EquityClassSectionsByShareholder
									index={index}
									i18nKey={ReportI18NKeys.AdjustedCostBaseDetails}
									columnsCount={columnsCount}
									reportFields={reportFields}
									selectedCurrency={selectedCurrency}
									lookupData={adjustedCostBaseMasterData}
									shareholdersData={selectedShareholdersData}
									data={item}/>)
							}
						</tbody>
					</table>
				</>
				}
			</div>
		</div>);
};

export default AdjustedCostBaseDetailsReport;