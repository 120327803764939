import { Constants } from 'src/common/types/constants';
import { ICurrency } from 'src/common/types/interfaces/ICurrency';
import { Utils } from 'src/common/utils/utils';
import store from 'src/store/store';

export enum TaxPayerFieldNames {

	id = 'id',
	name = 'name',
	currencyId = 'currencyId'
}

export interface ITaxPayer {

	id: number;
	name: string;
	currencyId: number
}

export class TaxPayer implements ITaxPayer
{
	constructor ()
	{
		const defaultCurrencyId: number = store.getState().appSettings.currencies
			.find((c: ICurrency) => c.code===Constants.DEFAULT_CURRENCE)?.id??0;

		this.id = Utils.generateTempId();
		this.name = '';
		this.currencyId = defaultCurrencyId;
	}

	id: number;
	name: string;
	currencyId: number;
}