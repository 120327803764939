import { IPropertyIncomeGridProps } from './IPropertyIncomeGridProps';
import { PropertyIncomeRowColumns } from './components/PropertyIncomeRowColumns/PropertyIncomeRowColumns';
import { Button } from '@appkit4/react-components';

import './PropertyIncomeGrid.scss';
import { useTranslation } from 'react-i18next';

export function PropertyIncomeGrid(props: IPropertyIncomeGridProps): JSX.Element
{
	const { t } = useTranslation(
		'common',
		{
			keyPrefix: 'propertyIncomeGrid'
		}
	);

	return <>
		{
			!!props.propertyIncomeData &&
			<div
				className={'property-income-grid'}
				style={{
					visibility: props.isLoading ? 'hidden' : 'visible'
				}}
			>
				{
					!!props.title &&
					<div className={'title'}>
						{
							props.title
						}
					</div>
				}
				<div
					ref={props.customRef}
					className={'table-container table-scroll-container'}
				>
					<table
						id={props.id}
						className={props.displayNotes ? 'notes' : ''}
					>
						<thead>
							<tr>
								<th className={'first-column'}>
									{
										t('descriptionHeader')
									}
								</th>
								{
									!!props.propertyIncomeData && !!props.propertyIncomeData.length &&
									props.propertyIncomeData
										.map(() =>
										{
											return <>
												<th className={`amount-type ${!!props.displayNotes ? 'notes-included' : ''}`}>
													<div>
														{
															t('amountHeader')
														}
													</div>
												</th>
												<th className={'amount-type boundary'}>
													<div>
														{
															t('typeHeader')
														}
													</div>
												</th>
											</>;
										})
								}
							</tr>
						</thead>
						<tbody>
							{
								!!props.rowData &&
								!!props.rowData.length &&
								props.rowData
									.map((rd, rowIndex) =>
									{
										return <PropertyIncomeRowColumns
											isLoading={props.isLoading}
											isDisabled={props.isDisabled}
											validate={props.validate}
											displayNotes={props.displayNotes}
											rowData={rd}
											typeOptions={props.typeOptions}
											descriptionOptions={props.descriptionOptions}
											onDescriptionUpdate={(value) =>
											{
												props.onDescriptionUpdate(rowIndex, value);
											}}
											onValueUpdate={(fieldName, index, value) =>
											{
												props.onValueUpdate(fieldName, rowIndex, index, value);
											}}
										/>;
									})
							}
						</tbody>
					</table>
				</div>
				{
					(
						!props.rowData ||
						!props.rowData.length
					) &&
					<div className={'no-data'}>
						<div>
							{ t('noDataPart1') }
						</div>
						<div>
							{t('noDataPart2')}<strong>+ {props.addRowButtonText}</strong> {t('noDataPart3')}
						</div>
					</div>
				}
				<div className={'actions'}>
					<Button
						kind={'text'}
						icon={'icon-plus-outline'}
						onClick={props.onRowAdd}
					>
						{
							props.addRowButtonText
						}
					</Button>
				</div>
			</div>
		}
	</>;
}