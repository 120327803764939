import { Button, Modal } from '@appkit4/react-components';

import './DeleteUserModal.scss';

export const DeleteUserModal = (props: {
  row: any;
  visible: boolean;
  onClose: (result: boolean, email: any) => void;
}) =>
{
	return (
		<>
			<Modal
				className="delete-user-modal"
				visible={props.visible}
				title={'Delete User?'}
				maskCloseable={false}
				ariaLabel={'Lorem ipsum dolor sit'}
				onCancel={() => props.onClose(false, props.row.email)}
				header={''}
				icons={''}
				footer={
					<>
						<Button
							className="delete-user-cancel"
							onClick={() =>
							{
								props.onClose(false, props.row.email);
							}}
							kind="secondary"
						>
              Cancel
						</Button>
						<Button
							onClick={() =>
							{
								props.onClose(true, props.row.email);
							}}
							kind="negative"
						>
              Delete
						</Button>
					</>
				}
			>
				<div className="delete-user-message">
          Are you sure you want to delete {props.row?.firstName}{' '}
					{props.row?.lastName}? This action is permanent.
				</div>
			</Modal>
		</>
	);
};
