import {
	Button,
	Input,
	Loading,
	Select,
	Tooltip
} from '@appkit4/react-components';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './EngagementForm.scss';
import { coreService } from 'src/core/services/coreService';
import ClientInfo from '../ClientInfo/ClientInfo';
import { ClientModal } from 'src/core/components/Dashboard/editClientModal/ClientModal';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { useAppSelector } from 'src/store/store';
import { FormPickerControl } from '../../../../../../common/components/FormPickerControl/FormPickerControl';
import { ITaxPayer, TaxPayer } from 'src/features/engagement/models/ITaxPayer';
import { NotificationService } from 'src/core/services/notificationService';
import { Utils } from 'src/common/utils/utils';
import { TaxPayerForm } from '../TaxPayerForm/TaxPayerForm';
import { IViewValue } from 'src/features/engagement/models/IViewValue';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import { CalendarPickerWrapper } from 'src/common/components/CalendarPickerWrapper/CalendarPickerWrapper';

interface ClientData {
  clientName: string;
  iPowerCode: string;
}

const defaultFormValue: ClientData = {
	clientName: '',
	iPowerCode: ''
};

const EngagementForm = (props: {
  engagementDetail: any;
  engagementFormError: Record<string, any>;
  fromEngagementOverview?: boolean; //when navigating from engagemnet overview tab
  isSaveDisabled?: boolean;
  onChange: (name: any, val: any, errorParamName?: string) => void;
  onClientChange?: (clientId: number, clientName: string) => void;
  onCancel?: () => void;
  onSave?: () => void;
}) =>
{
	const [clients, setClients] = React.useState<any[]>([]);
	const [loading] = React.useState<boolean>(false);
	const [disableClientEdit, setDisableClientEdit] = useState<boolean>(false);
	const [client, setClient] = React.useState<any | null>(null);
	const currencies = useAppSelector((state) => state.appSettings.currencies);
	const taxpayerCurrencies = useAppSelector((state) => state.appSettings.taxpayerCurrencies);
	const [showNewClientModal, setShowNewClientModal] = useState<boolean>(false);
	const [clientModalContext, setClientModalContext] =
    useState<ClientData>(defaultFormValue);
	const { engagementFormError, engagementDetail } = props;

	const [selectedClientId, setSelectedClientId] = useState(0);
	const [engagementId, setEngagementId] = useState(0);

	const { t } = useTranslation('homeDashboard');

	const { t: taxPayerTranslations } = useTranslation(
		'homeDashboard',
		{
			keyPrefix: 'engagement-form.taxPayer'
		}
	);

	const [isLoadingTaxpayers, setIsLoadingTaxpayers] = useState(false);
	const [taxpayers, setTaxpayers] = useState<ITaxPayer[]>([]);
	const [selectedTaxpayer, setSelectedTaxpayer] = useState<ITaxPayer | undefined>(undefined);
	const [selectedTaxpayerId, setSelectedTaxpayerId] = useState(0);
	const [isChanged, setIsChanged] = useState<boolean>(false);

	const onOpenTaxPayerForm = (taxPayerId?: number) =>
	{
		if (!!taxPayerId)
		{
			const taxpayer = taxpayers.find((t) => t.id === taxPayerId);

			if (!!taxpayer)
			{
				setSelectedTaxpayer(taxpayer);
			}
			else
			{
				setSelectedTaxpayer(undefined);
			}
		}
		else
		{
			setSelectedTaxpayer(new TaxPayer());
		}
	};

	const loadTaxpayers = async (clientId?: number, forceUpdate?: boolean): Promise<void> =>
	{
		if (
			!!clientId &&
			(
				!!forceUpdate ||
				clientId !== selectedClientId
			)
		)
		{
			setSelectedClientId(clientId);
			setIsLoadingTaxpayers(true);
			setSelectedTaxpayer(undefined);
			setTaxpayers([]);

			try
			{
				const clientTaxpayers = await engagementService
					.getTaxPayers(clientId);

				if (
					!!clientTaxpayers &&
					!!clientTaxpayers.data &&
					!!clientTaxpayers.data.result &&
					!!clientTaxpayers.data.result.length
				)
				{
					setTaxpayers([...clientTaxpayers.data.result]);
				}
				else
				{
					setTaxpayers([]);
				}
			}
			catch (error)
			{
				NotificationService.error({
					message: taxPayerTranslations('loadFailed')
				});
			}
			finally
			{
				await Utils.timeout(300);
				setIsLoadingTaxpayers(false);
			}
		}
	};

	const onRefreshTaxPayers = (taxPayerId: number): void =>
	{
		if (!!taxPayerId)
		{
			props.onChange('taxPayerId', taxPayerId, 'taxPayerId');

			if (taxPayerId !== selectedTaxpayerId)
			{
				setIsChanged(true);
			}
			else
			{
				setIsChanged(false);
			}
		}

		loadTaxpayers(props.engagementDetail.clientId, true);
	};

	const getTaxpayerValues = (taxPayer?: ITaxPayer): IViewValue[] =>
	{
		if (!!taxPayer)
		{
			const currency = currencies.find((c) => c.id === taxPayer.currencyId);

			return [
				{
					key: taxPayer.id,
					name: taxPayerTranslations('fieldName'),
					value: taxPayer.name
				},
				{
					key: taxPayer.id,
					name: taxPayerTranslations('fieldCurrency'),
					value: !!currency ?
						currency.code :
						''
				}
			];
		}

		return [];
	};

	useEffect(() =>
	{
		coreService.getAllClients().then((res: any) =>
		{
			setClients(res.data.result);
		});
	}, []);

	useEffect(() =>
	{
		if (
			!!props.engagementDetail.id &&
			props.engagementDetail.id !== engagementId
		)
		{
			setEngagementId(props.engagementDetail.id);

			// setLoading(true);
			engagementService
				.getForeignAffiliatesByEngagementId(props.engagementDetail.id)
				.then((res: any) =>
				{
					if (res.data.result)
					{
						setDisableClientEdit(res.data.result.length > 0);
					}
					// setLoading(false);
				})
				.catch((error: any) =>
				{
					//  setLoading(false);
				});
		}
		else
		{
			setDisableClientEdit(false);
		}
		loadTaxpayers(
			!!props.engagementDetail && !!props.engagementDetail.clientId ?
				props.engagementDetail.clientId :
				undefined
		);
	}, [props.engagementDetail]);

	useEffect(() =>
	{
		if (!!props.fromEngagementOverview)
		{
			setClient({
				'clientName': props.engagementDetail.clientName,
				'iPowerCode': props.engagementDetail.clientIPowerCode,
				'clientId': props.engagementDetail.clientId
			});
			setSelectedTaxpayerId(props.engagementDetail.taxPayerId);
		}
	}, []);

	const onSelectClient = (vals: any) =>
	{
		props.onChange('clientId', vals);
		const selectedClient = clients.find((c: any) => c.clientId === vals);
		setClient(selectedClient);
		!!props.onClientChange && props.onClientChange(selectedClient.clientId, selectedClient.clientName);
	};

	const unSelectClient = () =>
	{
		props.onChange('clientId', 0);
		!!props.onClientChange && props.onClientChange(0, '');
		setClient(null);
	};

	const setNewClientData = (client: any) =>
	{
		setClients([...clients, client]);
		props.onChange('clientId', client?.clientId);
		setClient(client);
		!!props.onClientChange && props.onClientChange(client?.clientId, client?.clientName);
		setShowNewClientModal(false);
		setClientModalContext(defaultFormValue);
	};

	const setUpdatedClientData = (client: any) =>
	{
		setClient(client);
		const upd_client = clients.map((obj) =>
		{
			if (obj.clientId === client?.clientId)
			{
				obj.clientName = client?.clientName;
				obj.iPowerCode = client?.iPowerCode;
			}
			return obj;
		});

		setClients([]);
		setTimeout(() =>
		{
			setClients([...upd_client]);
		}, 100);

		props.onChange('clientId', client?.clientId);
	};

	const closeClientModal = () =>
	{
		setClientModalContext(defaultFormValue);
		setShowNewClientModal(false);
	};

	const getErrorMessage = (fieldName: string) =>
	{
		if (!!engagementFormError)
		{
			let errorMsg = '';
			//@ts-ignore
			if (
				!!engagementFormError?.[fieldName] &&
        		!engagementDetail?.[fieldName]
			)
			{
				errorMsg = engagementFormError?.[fieldName] || '';

				return <div
					id="errormessage"
					aria-live="polite"
					className={'ap-field-email-validation-error'}
				>
					{errorMsg}
				</div>;
			}
		}
		return null;
	};

	return (
		<>
			<div className="engagement-form-wrapper">
				<div className="engagement-form">
					{loading && (
						<div className="loading-icon">
							<Loading
								loadingType="circular"
								indeterminate={true}
								compact={false}
							/>
						</div>
					)}
					{ !props.fromEngagementOverview &&
						<span className="engDetail-header">
							{t('engagement-form.engagementDetail')}
						</span>
					}
					<div>
						<Tooltip
							trigger="hover"
							position="bottom"
							distance={4}
							disabled={!!props.fromEngagementOverview ? true : !disableClientEdit}
							id="tooltipDesc"
							appendAfterTarget={true}
							content={
								'The client for this engagement cannot be modified, ' +
								'as there are foreign affiliates currently assigned to this engagement. '+
								'In order to modify the client, please delete all assigned foreign affiliates for this engagement.'
							}
						>
							<div className="row client-m-top">
								<div className={!!props.fromEngagementOverview ? 'row' : 'client-row'}>
									<div className="client-dropdown">
										<Select
											className="client-name-style"
											data={clients}
											disabled={!!props.fromEngagementOverview ? true : disableClientEdit}
											valueKey="clientId"
											labelKey="clientName"
											searchable={true}
											value={props.engagementDetail.clientId}
											placeholder={
												t('engagement-form.clientPlaceholder') || undefined
											}
											required={true}
											dropdownAlwaysDown={true}
											dropdownRenderMode='portal'
											onSelect={onSelectClient}
											error={
												!!engagementFormError &&
                        !!engagementFormError?.['clientName'] &&
                        engagementDetail?.clientId === 0
											}
										/>

										<div className="appending-icon">
											{engagementDetail.clientId ? (
												<>
													{!!props.fromEngagementOverview || disableClientEdit ? (
														<span className="Appkit4-icon icon-circle-delete-outline disabled-icon "></span>
													) : (
														<i
															className="Appkit4-icon icon-circle-delete-outline "
															onClick={unSelectClient}
														></i>
													)}
													<span className="separte-line" />
												</>
											) : (
												<div className="whitespace">{''}</div>
											)}
										</div>
									</div>
									{ !props.fromEngagementOverview &&
										<Button
											className="new-client-button"
											kind="secondary"
											disabled={disableClientEdit}
											onClick={() => setShowNewClientModal(true)}
										>
											{t('buttons.newClient')}
										</Button>
									}
								</div>
							</div>
						</Tooltip>
						{!!engagementFormError &&
              !!engagementFormError?.['clientName'] &&
              engagementDetail?.clientId === 0 && (
							<div
								id="errormessage"
								aria-live="polite"
								className="ap-field-email-validation-error"
							>
								{' '}
								{engagementFormError && engagementFormError?.['clientName'] ?
									engagementFormError?.['clientName'] :
									''}
							</div>
						)}
						<div>
							{client && (
								<ClientInfo
									client={client}
									setClient={setUpdatedClientData}
								></ClientInfo>
							)}
						</div>
					</div>
					<div className="row">
						{' '}
						<Input
							type={'text'}
							title={t('engagement-form.engagementName')}
							value={props.engagementDetail.name}
							required={true}
							inputProps={{
								maxLength: 250
							}}
							onChange={(value: any) =>
							{
								props.onChange('name', value, 'engagementName');
							}}
							onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
							{
								props.onChange(
									'name',
									event.target.value.trim(),
									'engagementName'
								);
							}}
							error={
								!!engagementFormError &&
                !!engagementFormError?.['engagementName']
							}
							errorNode={getErrorMessage('engagementName')}
						/>
					</div>
					<div className={'row'}>
						<FormPickerControl
							isDisabled={
								!!isLoadingTaxpayers ||
								!props.engagementDetail ||
								!props.engagementDetail.clientId
							}
							isButtonDisabled={
								!!isLoadingTaxpayers ||
								!props.engagementDetail ||
								!props.engagementDetail.clientId
							}
							selectedKey={
								props.engagementDetail.taxPayerId
							}
							options={taxpayers}
							selectedViewInformation={
								!!props.engagementDetail.taxPayerId ?
									getTaxpayerValues(
										taxpayers.find((t) => t.id === props.engagementDetail.taxPayerId)
									) :
									[]
							}
							editButtonText={taxPayerTranslations('editButtonText')}
							placeholder={taxPayerTranslations('placeholder') || ''}
							createButtonText={taxPayerTranslations('createButtonText')}
							onChange={(selectedValue) =>
							{
								if (!!props.fromEngagementOverview)
								{
									if (selectedValue !== selectedTaxpayerId)
									{
										setIsChanged(true);
									}
									else
									{
										setIsChanged(false);
									}
								}
								props.onChange('taxPayerId', selectedValue, 'taxPayerId');
							}}
							onCreateButtonTrigger={onOpenTaxPayerForm}
							onEditButtonTrigger={onOpenTaxPayerForm}
							isError={
								!!engagementFormError &&
                				!!engagementFormError?.taxPayer &&
                				(
                					!engagementDetail ||
									!engagementDetail.taxPayerId
                				)
							}
							onRenderErrorMessage={() =>
							{
								return <>
									{
										getErrorMessage('taxPayer')
									}
								</>;
							}}
							onRenderNote={() =>
							{
								return <>
									{
										isChanged ?
											<div className={'review-message'}>
												<div className={'review-message-title'}>{taxPayerTranslations('reviewChangesTitle')}</div>
												<div>{taxPayerTranslations('reviewChangesText1')}</div>
												<br/>
												<div>{taxPayerTranslations('reviewChangesText2')}</div>
											</div>: null
									}
								</>;
							}}
						/>
						{
							!!props.engagementDetail &&
							!!props.engagementDetail.clientId &&
							<TaxPayerForm
								isAdd={
									!!selectedTaxpayer &&
									selectedTaxpayer.id < 0
								}
								displayModal={
									!!selectedTaxpayer
								}
								clientId={props.engagementDetail.clientId}
								record={selectedTaxpayer}
								currencies={taxpayerCurrencies}
								onDismiss={() =>
								{
									setSelectedTaxpayer(undefined);
								}}
								onRefresh={onRefreshTaxPayers}
							/>
						}
					</div>
					<div className="row">
						{' '}
						<CalendarPickerWrapper
							name={'lastCalculationTaxationYearEnd'}
							className="calendar-style"
							placeholder="mm/dd/yyyy"
							locale="en"
							editable={false}
							fieldTitle={t('engagement-form.lastCalculationTYE') || ''}
							value={props.engagementDetail.lastCalculationTaxationYearEnd || undefined}
							required={true}
							maxDate={new Date('2050,12,31')}
							minDate={new Date('1972,1,1')}
							onValueChange={(vals: string) =>
							{
								props.onChange(
									'lastCalculationTaxationYearEnd',
									dateFormatter.toEndOfDay(
										dateFormatter.parseDateString(vals)
									),
									'lastCalculationTaxationYearEnd'
								);
							}}
							useCustomValidation={true}
							error={
								!!engagementFormError && !!engagementFormError?.['lastCalculationTaxationYearEnd']
							}
							customErrorNode={getErrorMessage('lastCalculationTaxationYearEnd')}
							datePanelHorizontalAlign="right"
							datepickerAlwaysDown={false}
						/>
					</div>
					<div className="row">
						{' '}
						<CalendarPickerWrapper
							name={'deliveryDate'}
							className="calendar-style"
							placeholder="mm/dd/yyyy"
							locale="en"
							editable={false}
							fieldTitle={t('engagement-form.deliveryDate') || ''}
							value={props.engagementDetail.deliveryDate || undefined}
							required={true}
							minDate={new Date('1972,1,1')}
							onValueChange={(vals: string) =>
							{
								props.onChange(
									'deliveryDate',
									dateFormatter.toEndOfDay(
										dateFormatter.parseDateString(vals)
									),
									'deliveryDate'
								);
							}}
							useCustomValidation={true}
							error={
								!!engagementFormError && !!engagementFormError?.['deliveryDate']
							}
							customErrorNode={getErrorMessage('deliveryDate')}
							datePanelHorizontalAlign="right"
							datepickerAlwaysDown={false}
						/>
					</div>
					{ !!props.fromEngagementOverview &&
					<div className="action-row">
						<Button
							kind={'primary'}
							disabled={props.isSaveDisabled}
							onClick={() =>
							{
								!!props.onSave && props.onSave();
							}}
						>
							Save Changes
						</Button>

						<Button
							className="delete-engagement-cancel"
							onClick={() =>
							{
								!!props.onCancel && props.onCancel();
							}}
							kind="secondary"
						>
             				Cancel
						</Button>
					</div>}

				</div>
			</div>
			<ClientModal
				visible={showNewClientModal}
				onClose={closeClientModal}
				clientModalContext={clientModalContext}
				title={t('client-popup.newClientTitle')}
				setClient={setNewClientData}
			/>
		</>
	);
};

export default EngagementForm;
