import React, { useEffect } from 'react';
import './EngagementPage.scss';
import { EngagementInfo } from '../components/EngagementDashboard/EngagementInfo';
import { Layout } from 'src/common/components/Layout/Layout';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import signalRService from 'src/common/services/signalRService';
import { RootState } from 'src/store/store';

const EngagementPage = () =>
{
	const {engagementId } = useParams();
	const isHubConnectionOpen = useSelector((state: RootState) => state.notifications.isHubConnectionOpen);

	useEffect(() =>
	{
		if (isHubConnectionOpen)
		{
			signalRService.subscribeToEngagementNotifications(Number(engagementId));

			return () =>
			{
				signalRService.unsubscribeFromEngagementNotifications();
			};
		}
	}, [isHubConnectionOpen]);

	return (
		<Layout>
			<div className="engagement-page">
				<EngagementInfo></EngagementInfo>
			</div>
		</Layout>
	);
};

export default EngagementPage;
