import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { ILineItem } from 'src/common/types/interfaces/ILineItem';
import { cloneDeep, isEqual, differenceWith } from 'lodash';
import { LineItemCategories } from 'src/common/types/enums/LineItemCategories';
import { inputRecordHelper } from 'src/common/utils/inputRecordHelper';
import { UnexpectedErrorModal } from 'src/common/components/UnexpectedErrorModal/UnexpectedErrorModal';
import { IAmountNotes, IIncomeTaxesPaid } from 'src/features/engagement/models/IIncomeTaxesPaid';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import { Button, Loading, Switch, Tooltip } from '@appkit4/react-components';
import { CurrencyEditor } from 'src/common/components/Editors/CurrencyEditor/CurrencyEditor';
import { Utils } from 'src/common/utils/utils';
import { LineItemTypeEnum } from 'src/common/types/enums/LineItemTypeEnum';
import { EngagementUtil } from 'src/features/engagement/utils/EngagementUtil';
import { IncomeTaxesPaidField } from 'src/common/types/enums/IncomeTaxesPaidFields';
import { WarningModal } from 'src/common/components/WarningModal/WarningModal';

import './IncomeTaxesPaid.scss';
import AffiliateContext from 'src/common/components/SharedDataContext/AffiliateContext';
import { NumericInputWithNotesControl } from 'src/common/components/GridControls';

export const IncomeTaxesPaid = (props: any) =>
{
	const [displayNotes, setDisplayNotes] = useState(false);
	const [showUnexpectedErrorModal, setShowUnexpectedErrorModal] =
    useState<boolean>(false);
	const [unexpectedError, setUnExpectedError] = useState<string>('');
	const [errorCode, setErrorCode] = useState<number|undefined>(undefined);

	const { t } = useTranslation(
		'input',
		{
			keyPrefix: 'incomeTaxesPaid'
		}
	);

	const affiliateContext = useContext(AffiliateContext);
	const [lineConfiguration, setLineConfiguration] = useState<ILineItem[]>([]);
	const [incomeTaxesPaids, setIncomeTaxesPaids] = useState<IIncomeTaxesPaid[]>([]);
	const [rawIncomeTaxesPaids, setRawIncomeTaxesPaids] = useState<IIncomeTaxesPaid[]>([]);
	const [showWarningModal, setShowWarningModal] =
    useState<boolean>(false);
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const [hasWarning, setHasWarning] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(false);

	const loadIncomeTaxesPaids = (
		{
			engagementId,
			affiliateId,
			fromDate,
			toDate
		}:
		{
			engagementId: number;
			affiliateId: number;
			fromDate?: Date;
			toDate?: Date;
		}
	) =>
	{
		setIsLoading(true);
		engagementService
			.getIncomeTaxPaid(
				engagementId,
				affiliateId,
				fromDate,
				toDate
			).then((res: any) =>
			{
				setIsLoading(false);
				setHasWarning(false);
				setDataChanged(false);
				!!props.handleChange && props.handleChange(false);
				if (res.data && res.data.result)
				{
					const incomeTaxesPaidData = res.data.result
						.map((item: any, index: number, allData: any[]) =>
						{
							const taxPaid: IIncomeTaxesPaid = {
								id: item.id?item.id: item.affiliateTaxYearEndId,
								affiliateTaxYearEndId: item.affiliateTaxYearEndId,
								taxYearEnd: dateFormatter.conditionallyFormatDateWithTime('taxYearEnd', item, index, allData),
								countryId: item.countryId,
								calculatingCurrencyId: item.calculatingCurrencyId,
								corporateIncomeTax: item.incomeTaxesPaid.corporateIncomeTax,
								withholdingTaxCredit: item.incomeTaxesPaid.withholdingTaxCredit,
								otherCorporateTax:	item.incomeTaxesPaid.otherCorporateTax,
								other:	item.incomeTaxesPaid.other,
								totalIncomeTaxesPaid: item.incomeTaxesPaid.totalIncomeTaxesPaid,
								activeBusinessIncome: item.taxAllocations.activeBusinessIncome,
								deemedActiveBusinessIncome: item.taxAllocations.deemedActiveBusinessIncome,
								foreignAccrualPropertyIncome: item.taxAllocations.foreignAccrualPropertyIncome,
								capitalGains: item.taxAllocations.capitalGains,
								totalIncomeTaxesPaidProportion: item.taxAllocations.totalIncomeTaxesPaidProportion,
								taxesPaidDeniedForFTXCredit: item.taxesPaidDeniedForFTXCredit,
								taxPaidOnCapitalGain: item.taxPaidOnCapitalGain
							};
							calculateTotal(taxPaid);  //recalculate the total fields
							return taxPaid;
						}
						);
					setIncomeTaxesPaids(incomeTaxesPaidData);
					setRawIncomeTaxesPaids(cloneDeep(incomeTaxesPaidData));
				}
			})
			.catch((error: any) =>
			{
				handleApiErrorResponse(error);
				setIsLoading(false);
			});
	};

	const handleSave = () =>
	{
		const changedIncomeTaxesPaids = differenceWith(incomeTaxesPaids, rawIncomeTaxesPaids, isEqual);

		if (changedIncomeTaxesPaids.length>0)
		{
			 const updatedIncomeTaxesPaids = changedIncomeTaxesPaids
				.map((item: IIncomeTaxesPaid) =>
				{
					return {
						'id': item.id.toString()===item.affiliateTaxYearEndId.toString()?0:item.id,
						'affiliateTaxYearEndId': item.affiliateTaxYearEndId,
						'incomeTaxesPaid': {
							'corporateIncomeTax': item.corporateIncomeTax,
							'withholdingTaxCredit': item.withholdingTaxCredit,
							'otherCorporateTax': item.otherCorporateTax,
							'other': item.other,
							'totalIncomeTaxesPaid': item.totalIncomeTaxesPaid
						},
						'taxAllocations': {
							'activeBusinessIncome': item.activeBusinessIncome,
							'deemedActiveBusinessIncome': item.deemedActiveBusinessIncome,
							'foreignAccrualPropertyIncome': item.foreignAccrualPropertyIncome,
							'capitalGains': item.capitalGains,
							'totalIncomeTaxesPaidProportion': item.totalIncomeTaxesPaidProportion
						},
						'taxesPaidDeniedForFTXCredit': item.taxesPaidDeniedForFTXCredit
					};
				});

			const payload = {
				engagementId: affiliateContext?.engagementDetail.id!,
				affiliateId: affiliateContext?.affiliateDetail.affiliateId!,
				incomeTaxesPaidList: updatedIncomeTaxesPaids
			};

			if (!isLoading)
			{
				setIsLoading(true);
				engagementService.saveIncomeTaxPaid(payload).then((res: any) =>
				{
					setIsLoading(false);
					loadIncomeTaxesPaids({
						engagementId: affiliateContext?.engagementDetail.id!,
						affiliateId: affiliateContext?.affiliateDetail.affiliateId!

					});
				})
					.then((error: any) =>
					{
						setIsLoading(false);
					})
					.catch((err: any) =>
					{
						setIsLoading(false);
						handleApiErrorResponse(err);
					});
			}
		}
	};

	const loadRowInformation = async (
		engagementId: number,
		affiliateId: number
	): Promise<void> =>
	{
		try
		{
			const lines = await inputRecordHelper
				.initializeLineItems(
					LineItemCategories.IncomeTaxesPaid,
					engagementId,
					affiliateId
				);

			setLineConfiguration(lines);
		}
		catch (error)
		{
			handleApiErrorResponse(error);
		}
	};


	useEffect(
		() =>
		{
			if (
				!!affiliateContext&&
				!!affiliateContext?.engagementDetail.id&&
				!!affiliateContext?.affiliateDetail.affiliateId
			)
			{
				loadRowInformation(
					affiliateContext?.engagementDetail.id!,
					affiliateContext?.affiliateDetail.affiliateId!
				);
			}
		},
		[affiliateContext]
	);

	useEffect(
		() =>
		{
			if (
				!!lineConfiguration &&
				!!lineConfiguration.length &&
				!!affiliateContext&&
				!!affiliateContext?.engagementDetail.id&&
				!!affiliateContext?.affiliateDetail.affiliateId
			)
			{
				loadIncomeTaxesPaids({
					engagementId: affiliateContext?.engagementDetail.id!,
					affiliateId: affiliateContext?.affiliateDetail.affiliateId!
				});
			}
		},
		[
			affiliateContext,
			lineConfiguration
		]
	);

	const areAllNullOrUndefined =  (...fields: (IAmountNotes|undefined)[]): boolean =>
	{
		return fields.every((field) => field===undefined || (!!field?.amount)===undefined||
		(!!field?.amount)===null);
	};

	const getAmount = (field: IAmountNotes|undefined): number =>
	{
		const retVal = Number( (field?.amount??0));
		return isNaN(retVal)?0:retVal;
	};

	const calculateTotal = (taxesPaid: IIncomeTaxesPaid) =>
	{
		if (areAllNullOrUndefined(taxesPaid.corporateIncomeTax,taxesPaid.withholdingTaxCredit,taxesPaid.otherCorporateTax,taxesPaid.other))
		{
			taxesPaid.totalIncomeTaxesPaid = undefined;
		}
		else
		{
			const total1 =  Utils.add(getAmount(taxesPaid.corporateIncomeTax),
				getAmount(taxesPaid.withholdingTaxCredit),
				getAmount(taxesPaid.otherCorporateTax),
				getAmount(taxesPaid.other));

			taxesPaid.totalIncomeTaxesPaid = total1;
		}

		// eslint-disable-next-line max-len
		if (areAllNullOrUndefined(taxesPaid.activeBusinessIncome, taxesPaid.deemedActiveBusinessIncome, taxesPaid.foreignAccrualPropertyIncome, taxesPaid.capitalGains))
		{
			taxesPaid.totalIncomeTaxesPaidProportion = undefined;
		}
		else
		{
			const total2 = Utils.add(getAmount(taxesPaid.activeBusinessIncome),
				getAmount(taxesPaid.deemedActiveBusinessIncome),
				getAmount(taxesPaid.foreignAccrualPropertyIncome),
				getAmount(taxesPaid.capitalGains));

			taxesPaid.totalIncomeTaxesPaidProportion = total2;
		}
	};

	const validateTotal = (taxesPaids: IIncomeTaxesPaid[]) =>
	{
		const warning = taxesPaids.some((taxesPaid: IIncomeTaxesPaid) =>
			(taxesPaid.totalIncomeTaxesPaid || taxesPaid.totalIncomeTaxesPaidProportion) &&
		taxesPaid.totalIncomeTaxesPaid !== taxesPaid.totalIncomeTaxesPaidProportion);

		setHasWarning(warning);
	};

	const handleInputChange = (id: string, field: string, value: any) =>
	{
		if (value==='')
		{
			value=null;
		}
		const updatedIncomeTaxesPaids = incomeTaxesPaids.map((d: any) =>
		{
			if (d.id&&d.id.toString()===id.toString())
			{
				const updatedTaxesPaid = {
					...d,
					[field]: {
						...d[field],
						amount: value
					}
				};

				calculateTotal(updatedTaxesPaid);
				return updatedTaxesPaid;
			}
			else
			{
				return d;
			}
		});


		setIncomeTaxesPaids(updatedIncomeTaxesPaids);

		const hasChange = !isEqual(updatedIncomeTaxesPaids, rawIncomeTaxesPaids);
		setDataChanged(hasChange);
		!!props.handleChange && props.handleChange(hasChange);
		validateTotal(updatedIncomeTaxesPaids);
	};

	const closeUnexpectedErrorModal = () =>
	{
		setUnExpectedError('');
		setShowUnexpectedErrorModal(false);
	};

	const handleApiErrorResponse = (err: any) =>
	{
		if (err.response)
		{
			setErrorCode(err.response.status);
			if (err.response.status >= 400)
			{
				if (err.response.data.Errors&&err.response.data.Errors.length>0)
				{
					setUnExpectedError(err.response.data.Errors[0].Message);
					setShowUnexpectedErrorModal(true);
				}
				else
				{
					const dataRequiredError = t('dataRequired');
					setUnExpectedError(dataRequiredError);
					setShowUnexpectedErrorModal(true);
				}
			}
		}
	};

	const closeWarning = () =>
	{
		setShowWarningModal(false);
	};

	const handleNotesChange = (id: string, field: string, value: any) =>
	{
		if (value==='')
		{
			value=null;
		}
		const updatedIncomeTaxesPaids = incomeTaxesPaids.map((d: any) =>
		{
			if (d.id&&d.id.toString()===id.toString())
			{
				const updatedIncomeTaxesPaid = d[field]? {
					...d,
					[field]: {
						...d[field],
						notes: value
					}
				}:{
					...d,
					[field]: {
						notes: value
					}
				};

				return updatedIncomeTaxesPaid;
			}
			else
			{
				return d;
			}
		});

		setIncomeTaxesPaids(updatedIncomeTaxesPaids);

		const hasChange = !isEqual(updatedIncomeTaxesPaids, rawIncomeTaxesPaids);
		setDataChanged(hasChange);
		!!props.handleChange && props.handleChange(hasChange);
	};

	const onNotesToggle = (value: boolean) =>
	{
		setDisplayNotes(!!value);
	};

	return <div className='income-taxes-paid'>
		<div className="tool-bar">
			<Button kind="primary" disabled={isLoading||!dataChanged||hasWarning}
				onClick={handleSave}
			>{t('Save')}
			</Button>
		</div>
		<div className='table-container'>
			{isLoading && (
				<div className="loading-icon">
					<Loading
						loadingType="circular"
						indeterminate={true}
						compact={false}
					/>
				</div>
			)}
			{ incomeTaxesPaids.length>0 && (
				<table>
					<thead>
						<tr>
							<th>
								<div className="caption">
									<div className="switch-title">
										{t('incomeTaxesPaid')}
									</div>{' '}
									<div className={'toggle'}>
										<Switch
											className={'switch-label'}
											checked={displayNotes}
											disabled={isLoading||incomeTaxesPaids.length===0}
											onChange={onNotesToggle}
										>
											{
												t('showNotes')
											}
										</Switch>
									</div>
								</div>
							</th>
						</tr>
					</thead>
					<tbody>
						{
							lineConfiguration.map((line: ILineItem) => (
								<>
									{ line.field !== IncomeTaxesPaidField.calculatingCurrencyId &&
									<tr>
										{
											line.field === IncomeTaxesPaidField.countryId ?
												<td>
													<div>
														<div>{t(`${IncomeTaxesPaidField.countryId}`)}</div>
														<div>{t(`${IncomeTaxesPaidField.calculatingCurrencyId}`)}</div>
													</div>
												</td>:
												<td>
													<div>{t(`${line.field}`)}</div>
													{line.field === IncomeTaxesPaidField.taxesPaidDeniedForFTXCredit&&<div>
														<Tooltip
															trigger="hover"
															position="top"
															distance={5}
															appendAfterTarget={true}
															content={`${t('tooltipTaxesPaidDeniedForFTXCredit')}`}
														>
															<span className="Appkit4-icon icon-help-question-outline"></span>
														</Tooltip>
													</div>}
													{line.field === IncomeTaxesPaidField.capitalGains&&<div>
														<Tooltip
															trigger="hover"
															position="top"
															distance={5}
															appendAfterTarget={true}
															content={`${t('tooltipCapitalGain')}`}
														>
															<span className="Appkit4-icon icon-help-question-outline"></span>
														</Tooltip>
													</div>}
													{line.field === IncomeTaxesPaidField.taxPaidOnCapitalGain&&<div>
														<Tooltip
															trigger="hover"
															position="top"
															distance={5}
															appendAfterTarget={true}
															content={`${t('tooltipTaxPaidOnCapitalGain')}`}
														>
															<span className="Appkit4-icon icon-help-question-outline"></span>
														</Tooltip>
													</div>}
												</td>
										}

										{
											incomeTaxesPaids.map((item: any) => (
												<>
													{(() =>
													{
														switch (line.type)
														{
														case LineItemTypeEnum.dropdown:
															return <td>
																<div className="country-currency">
																	<div>
																		{EngagementUtil.getCountryName(
																			item[IncomeTaxesPaidField.countryId])}
																	</div>
																	<div>{EngagementUtil.getCurrencyCode(
																		item[IncomeTaxesPaidField.calculatingCurrencyId])}</div>
																</div>

															</td>;
														case LineItemTypeEnum.currency:
															return <>
																<td>
																	{line.editable?
																		<CurrencyEditor
																			value={item[line.field]}
																			disabled={!line.editable}
																			onChange={(value: any) => handleInputChange(
																				item.id,line.field, +value)}
																		/>:<div className="total-cell">

																			{(line.field===IncomeTaxesPaidField.totalIncomeTaxesPaid||
																	line.field===IncomeTaxesPaidField.totalIncomeTaxesPaidProportion) &&
																			item[IncomeTaxesPaidField.totalIncomeTaxesPaid]!==
																			item[IncomeTaxesPaidField.totalIncomeTaxesPaidProportion]&&
																			<div className='warning-icon'
																				onClick={() => setShowWarningModal(true)}>
																				<span className="Appkit4-icon icon-alert-fill"></span>
																			</div>}
																			<div>{item[line.field]===''?'':
																				Utils.formatCurrency(item[line.field], 2)}</div>
																		</div>
																	}

																</td>
															</>;
														case LineItemTypeEnum.amountNotes:
															return <>
																<td>
																	{line.editable?
																		<NumericInputWithNotesControl
																			displayNotes={!!displayNotes}
																			notes={item[line.field]?.notes}
																			value={item[line.field]?.amount}
																			isDisabled={!line.editable}
																			onValueChange={(value: any) => handleInputChange(
																				item.id,line.field, +value)}
																			onNotesSave={(value) =>
																			{
																				handleNotesChange(item.id,line.field, value);
																			}}
																		/>:

																		<div className="total-cell">

																			{(line.field===IncomeTaxesPaidField.totalIncomeTaxesPaid||
																		line.field===IncomeTaxesPaidField.totalIncomeTaxesPaidProportion) &&
																				item[IncomeTaxesPaidField.totalIncomeTaxesPaid]!==
																				item[IncomeTaxesPaidField.totalIncomeTaxesPaidProportion]&&
																				<div className='warning-icon'
																					onClick={() => setShowWarningModal(true)}>
																					<span className="Appkit4-icon icon-alert-fill"></span>
																				</div>}
																			<div>{!!item[line.field]?'':
																				Utils.formatCurrency(item[line.field]?.amount, 2)}</div>
																		</div>
																	}

																</td>
															</>;
														default:
															return <td>
																<div>{item[line.field]}</div>
															</td>;
														}
													})()
													}

												</>
											))
										}
									</tr>
									}
									{line.field===IncomeTaxesPaidField.totalIncomeTaxesPaid&&(
										<>
											<tr className="seperate-row">&nbsp;</tr>
											<tr className="section-header"><td>
												{t('ProportionOfTaxesAllocatedTo')}
											</td></tr>
										</>
									)}
									{line.field===IncomeTaxesPaidField.taxesPaidDeniedForFTXCredit&&(
										<>
											<tr className="seperate-row">&nbsp;</tr>
										</>
									)}
								</>
							)
							)

						}
					</tbody>
				</table>
			)
			}
		</div>
		<div className="tool-bar">
			<Button kind="primary" disabled={isLoading||!dataChanged||hasWarning} onClick={handleSave}>{t('Save')}</Button>
		</div>
		 <UnexpectedErrorModal
			visible={showUnexpectedErrorModal}
			onClose={closeUnexpectedErrorModal}
			message={unexpectedError}
			error={''}
			statusCode={errorCode}
		 />
		<WarningModal
			visible={showWarningModal}
			title={t('warning-title')}
			content={t('warning-content')}
			onClose={closeWarning}
		/>
	</div>;
};