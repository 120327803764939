export enum ABIComputedUnderCanadianRulesTypes {

	TotalAdjustments = 1,
	IncomeLoss = 2,
	ABI = 3
}

export enum ABIComputedUnderCanadianRulesFieldNames {

	Amount = 'amount',
	Notes = 'notes'
}