import {
	Button,
	Combobox,
	ItemDataType,
	Modal
} from '@appkit4/react-components';
import { SelectValue } from '@appkit4/react-components/esm/combobox/Combobox';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UnsavedChangesModal } from 'src/core/components/Dashboard/unsavedModal/UnsavedChangesModal';
import './EditCalculationTypeModal.scss';
import useDisableBodyScroll from 'src/common/hooks/useDisableBodyScroll';

interface Props {
  rowData: Array<Record<string, any>>;
  selectedFA: Array<string>;
  setSelectedFA: Function;
  setVisible: Function;
  visible: boolean;
  calculationTypes: Array<any>;
  onClose: Function;
  calTypeModalContext: any;
}
const EditCalculationTypeModal = (props: Props) =>
{
	const {
		selectedFA,
		rowData,
		setVisible,
		visible,
		calculationTypes,
		onClose
	} = props;
	const [selectedCalcTypeIds, setSelectedCalcTypeIds] = useState<any>([]);
	const [selectedFAWithDetails, setSelectedFAWithDetails] = useState<
    Record<string, any>
  >([]);
	const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
	const [badgeRowsCount, setBadgeRowsCount] = useState<number>(-1);
	const [showLessButtonEnabled, setShowLessButtonEnabled] =
    useState<boolean>(false);
	const [showUnsavedModal, setShowUnsavedModal] = useState<boolean>(false);
	const badgeContainerRef = useRef<HTMLDivElement>(null);
	const { t } = useTranslation('homeDashboard');
	useDisableBodyScroll(visible);

	useEffect(() =>
	{
		const selectedFaWithDetails = rowData.reduce((formatted, item) =>
		{
			if (selectedFA.includes(item['affiliateId']))
			{
				formatted.push({
					id: item['affiliateId'],
					name: item['name']
				});
			}

			return formatted;
		}, []);
		setSelectedFAWithDetails(selectedFaWithDetails);
	}, [selectedFA, visible]);

	useEffect(() =>
	{
		updateBadgeContainerStyle();
	}, [
		selectedFAWithDetails,
		visible,
		badgeContainerRef,
		showLessButtonEnabled
	]);

	useEffect(() =>
	{
		if (!visible)
		{
			setShowLessButtonEnabled(false);
			setSelectedCalcTypeIds([]);
		}
	}, [visible]);

	const updateBadgeContainerStyle = () =>
	{
		if (!!badgeContainerRef?.current && visible)
		{
			const curr = badgeContainerRef.current;
			const childOffsetTopVals: number[] = [];
			curr?.childNodes?.forEach((element) =>
			{
				//@ts-ignore
				const offsetTop = element?.offsetTop;
				if (!childOffsetTopVals.includes(offsetTop))
				{
					childOffsetTopVals.push(offsetTop);
				}
			});

			setBadgeRowsCount(childOffsetTopVals.length);

			childOffsetTopVals.sort((first: number, second: number) =>
			{
				if (first >= second)
				{
					return 1;
				}
				return -1;
			});

			if (showLessButtonEnabled && childOffsetTopVals.length >= 5)
			{
				badgeContainerRef.current.style.maxHeight = 'fit-content';
			}
			else
			{
				const maxHeight: number = childOffsetTopVals[5] - childOffsetTopVals[0];
				badgeContainerRef.current.style.overflow = 'hidden';
				badgeContainerRef.current.style.maxHeight = maxHeight + 'px';
				badgeContainerRef.current.scroll({
					top: 0,
					behavior: 'smooth'
				});
			}
		}
	};

	const onCancel = () =>
	{
		if (selectedCalcTypeIds?.length >= 1 && selectedFA.length >= 1)
		{
			setShowUnsavedModal(true);
		}
		else
		{
			closeUnsavedModal('leave');
			onClose(null);
		}
	};

	const isSaveDisabled = () =>
	{
		if (selectedCalcTypeIds?.length < 1 || selectedFA.length < 1)
		{
			return true;
		}
		else
		{
			return false;
		}
	};

	const updateCalculationType = () =>
	{
		const calcTypeIds = selectedCalcTypeIds;
		const selectedForeignAffiliates = selectedFAWithDetails.map(
			(item: any) => item.id
		);
		const updatedItems: any = {
			foreignAffiliates: selectedForeignAffiliates,
			calculationTypeIds: calcTypeIds
		};
		setSelectedCalcTypeIds([]);
		onClose(updatedItems);
	};

	const onRemove = (itemId: string) =>
	{
		const updatedSelections = selectedFAWithDetails.filter(
			(item: any) => item.id !== itemId
		);
		setSelectedFAWithDetails(updatedSelections);
	};

	const closeUnsavedModal = (str: string) =>
	{
		if (str === 'stay')
		{
			setShowUnsavedModal(false);
		}
		else if (str === 'leave')
		{
			setShowUnsavedModal(false);
			setVisible(false);
			setSelectedCalcTypeIds([]);
		}
	};

	if (props?.visible === false)
	{
		return null;
	}

	return (
		<>
			<Modal
				className={`calculation-type-modal ${isDropdownOpen ? '' : ''}`}
				visible={visible}
				initialFocus={false}
				closeOnPressEscape={false}
				maskCloseable={false}
				title={'Edit Calculation Type'}
				onCancel={() =>
				{
					onCancel();
				}}
				footer={
					<div className="footer-wrapper">
						<Button kind="secondary" onClick={() => onCancel()}>
							{'Cancel'}
						</Button>
						<Button
							kind="primary"
							onClick={() => updateCalculationType()}
							disabled={isSaveDisabled()||selectedFAWithDetails.length===0}
						>
							{'Save'}
						</Button>
					</div>
				}
			>
				<div className="sub-header-wrapper">
					<div style={{
						fontWeight: '500'
					}}>
						{'Selected Foreign Affiliates'}
					</div>
					<div className="badge-container" ref={badgeContainerRef}>
						{selectedFAWithDetails.map(({ id, name }: any, index: number) =>
						{
							return (
								<div className="badge-wrapper" key={id}>
									<span className="selected-fa" key={`badge-${name}-${index}`}>
										{name}
									</span>
									<Button
										key={index}
										kind="text"
										onClick={() => onRemove(id)}
									>
										<span className="Appkit4-icon icon-close-outline ap-font-12"></span>
									</Button>
								</div>
							);
						})}
					</div>
					{badgeRowsCount > 5 && (
						<Button className='link-button showMoreButton'
						 kind='text' onClick={() => setShowLessButtonEnabled(!showLessButtonEnabled)}>
							{showLessButtonEnabled ? 'Show less' : 'Show more'}
						</Button>
					)}
					<div
						className={`calculation-type-dropdown ${
							isDropdownOpen ? 'overlay-visible' : 'overlay-hidden'
						}`}
					>
						<Combobox
							data={calculationTypes}
							valueKey={'value'}
							labelKey={'label'}
							placeholder="Calculation Type"
							value={selectedCalcTypeIds}
							dropdownRenderMode={'portal'}
							multiple
							showSelectAll={false}
							dropdownAlwaysDown={false}
							onVisibleChange={(isDropdownOpen: boolean) =>
								setIsDropdownOpen(isDropdownOpen)
							}
							valueTemplate={(
								value: SelectValue,
								item: ItemDataType | ItemDataType[]
							) =>
							{
								if (selectedCalcTypeIds.length < 1)
								{
									return null;
								}
								const selectedValue = (
									<div className="selected-val">
										{item.map((val: any) =>
											selectedCalcTypeIds.indexOf(val.value) > -1 ?
												`${val.desc}, ` :
												null
										)}
									</div>
								);
								return selectedValue;
							}}
							onSelect={(value: SelectValue) => setSelectedCalcTypeIds(value)}
						></Combobox>
					</div>
				</div>
			</Modal>
			<UnsavedChangesModal
				visible={showUnsavedModal}
				onClose={closeUnsavedModal}
				title={t('unsaved-popup.discardChangesTitle') || ''}
			/>
		</>
	);
};

export default EditCalculationTypeModal;
