import { IABIAggregateRowsProps } from './IReg5907AggregateRowsProps';
import { Utils } from 'src/common/utils/utils';
import { useTranslation } from 'react-i18next';

import './Reg5907AggregateRows.scss';
import { Reg5907AdjustmentsTypes } from 'src/common/types/enums/Reg5907Adjustments';


export function Reg5907AggregateRows(props: IABIAggregateRowsProps): JSX.Element
{
	const { t } = useTranslation(
		'common',
		{
			keyPrefix: 'reg5907AggregateRows'
		}
	);


	return <div
		ref={props.customRef}
		className={'abi-aggregate-rows table-scroll-container'}
		style={{
			visibility: props.isLoading ? 'hidden' : 'visible'
		}}
	>
		<table id={props.id}>
			<tbody>
				<tr>
					<td className={'action-column'} />
					<td className={'first-column'}>
						{
							t('totalAdjustments')
						}
					</td>
					{
						!!props.summationByYear &&
						props.summationByYear
							.map((s) =>
							{
								return <>
									<td className={`content-column ${!!props.displayNotes ? 'has-notes' : ''}`}>
										{
											Utils.formatCurrency(`${s[Reg5907AdjustmentsTypes.TotalAdjustments]}`, 2)
										}
									</td>
								</>;
							})
					}
				</tr>
			</tbody>
		</table>
	</div>;
}