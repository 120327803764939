import {
	toaster,
	Notification
} from '@appkit4/react-components/notification';
import { createElement } from 'react';
import { Utils } from 'src/common/utils/utils';

export const NotificationService = {

	initialize()
	{
		// note: service is required in order to get around an internal race condition issue
		// with the appkit toaster notification. In order to get around the issue we create a dummy
		// notification in order for the toaster component to have a container instance available
		// internally. This will not ever be seen by the end user no matter number of times called,
		// due to display none on notification message
		toaster.notify(
			createElement(
				Notification,
				{
					title: '',
					message: 'Initializing Notifications',
					status: '',
					style: {
						display: 'none'
					}
				}
			),
			{
				position: 'bottomLeft',
				duration: 1
			}
		);
	},

	notify: (
		{
			component,
			position = 'bottomLeft',
			onClose = () =>
			{ },
			duration = 10000,
			showTimer = false
		}:
        {
            component: JSX.Element;
            position?: string;
            onClose?: () => void;
            duration?: number;
			showTimer?: boolean
        }
	) =>
	{
		return toaster.notify(
			component,
			{
				position,
				onClose,
				duration,
				showTimer
			}
		);
	},

	success: (
		{
			message,
			position = 'bottomLeft',
			duration = 10000,
			onClose = () =>
			{ }
		}:
		{
			message: string;
			position?: string;
            duration?: number;
            onClose?: () => void;
		}
	) =>
	{
		const ele = createElement(
			Notification,
			{
				message: message,
				status: 'success'
			}
		);

		NotificationService.notify({
			component: ele,
			duration,
			position,
			onClose
		});
	},

	error: (
		{
			message,
			position = 'bottomLeft',
			duration = 10000,
			onClose = () =>
			{ }
		}:
		{
			message: string;
			position?: string;
            duration?: number;
            onClose?: () => void;
		}
	) =>
	{
		const ele = createElement(
			Notification,
			{
				message: message,
				status: 'error'
			}
		);

		NotificationService.notify({
			component: ele,
			duration,
			position,
			onClose
		});
	},

	warning: (
		{
			message,
			position = 'bottomLeft',
			duration = 10000,
			onClose = () =>
			{ }
		}:
		{
			message: string;
			position?: string;
            duration?: number;
            onClose?: () => void;
		}
	) =>
	{
		const ele = createElement(
			Notification,
			{
				message: message,
				status: 'warning'
			}
		);

		NotificationService.notify({
			component: ele,
			duration,
			position,
			onClose
		});
	},

	clearExisting: async () =>
	{
		toaster.closeAll();
		await Utils.timeout(500);
		NotificationService.initialize();
	}
};