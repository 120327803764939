import { Layout } from '../../../common/components/Layout/Layout';
import { AppDispatch, useAppSelector, RootState } from '../../../store/store';
import TermsofServiceModal from 'src/core/components/TermsOfService/TermsOfServiceModal';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	confirmPrivacyAsync
} from 'src/store/reducers/userProfileSlice';
import { Loading } from '@appkit4/react-components';
import { fetchAppSettingsAsync } from 'src/store/reducers/AppSettingsSlice';
import { Dashboard } from 'src/core/components/Dashboard/Dashboard';
import './HomePage.scss';

const HomePage = () =>
{
	const dispatch: AppDispatch = useDispatch();
	const isLoading = useSelector(
		(state: RootState) => state.userProfile.isLoading
	);

	const userProfile = useAppSelector((state) => state.userProfile.profile);
	const appsettings = useAppSelector((state) => state.appSettings);
	const [showModal, setShowModal] = useState(true);


	useEffect(() =>
	{
		if (appsettings.currencies.length === 0)
		{
			dispatch(fetchAppSettingsAsync());
		}
	}, [dispatch]);


	// only want to show loading if action has been dispatched and profile info isnt already available in store
	if (isLoading && !userProfile)
	{
		return (
			<div className="loading">
				{' '}
				<Loading
					loadingType="circular"
					indeterminate={false}
					compact={false}
				></Loading>
			</div>
		);
	}

	const closeTermsOfServiceModal = async () =>
	{
		dispatch(confirmPrivacyAsync());
		setShowModal(false);
	};

	return (
		<>
			{!(
				userProfile?.isPrivacyPolicyAccepted &&
        userProfile.isTermsOfServiceAccepted
			) ? (
					<TermsofServiceModal
						visible={showModal}
						termsOfService={userProfile?.isTermsOfServiceAccepted || false}
						privacyPolicy={userProfile?.isPrivacyPolicyAccepted || false}
						onClose={closeTermsOfServiceModal}
					></TermsofServiceModal>
				) : (
					<Layout>
						<Dashboard></Dashboard>
					</Layout>
				)}
		</>
	);
};

export default HomePage;
