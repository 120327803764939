import React, { useEffect, useState } from 'react';
import { FADataView } from './FADataView/FADataView';

import './FAAnnualData.scss';
import { FAInputSidebar } from './FAInputSidebar/FAInputSidebar';
import { InputEnum } from 'src/common/types/enums/InputEnum';
import { useParams } from 'react-router-dom';

export const FAAnnualData = (props: {
	handleUnsavedChange: (change: boolean) => void;
  }) =>
{
	const {pageId: inputId } = useParams();
	const [currentInputId, setCurrentInputId] = useState<InputEnum>();
	const [hasChange, setHasChange] = useState<boolean>(false);

	const handleSelect = (inputId: InputEnum) =>
	{
		setCurrentInputId(inputId);
	};

	const handleChange = (change: boolean) =>
	{
		setHasChange(change);
		props.handleUnsavedChange(change);
	};

	useEffect(() =>
	{
		if (inputId)
		{
			setCurrentInputId(inputId as InputEnum);
		}
	},[inputId]);

	return (
		<div className="fa-annual-data">
			<FAInputSidebar onSelect={handleSelect} inputId={inputId} hasChange={hasChange} handleChange={handleChange}/>
			{
				currentInputId && <FADataView inputId={currentInputId} handleChange={handleChange}></FADataView>
			}
		</div>
	);
};
