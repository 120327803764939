import React, { useEffect, useState } from 'react';
import { Modal, Button } from '@appkit4/react-components';
import { Accordion, AccordionItem } from '@appkit4/react-components/accordion';
import { Search } from '@appkit4/react-components/search';
import {
	CheckboxGroup,
	Checkbox,
	CheckboxValueType
} from '@appkit4/react-components/checkbox';

import { useTranslation } from 'react-i18next';
import './FilterModal.scss';
import { coreService } from 'src/core/services/coreService';
import { useAppSelector } from 'src/store/store';
import { ICurrency } from 'src/common/types/interfaces/ICurrency';
import { EngagementPersonalizationStatus } from 'src/core/types/enums/EngagementPersonalizationStatus';

export const FilterModal = (props: {visible: boolean, engagementPersonalizationStatus: EngagementPersonalizationStatus,
	 onClose: () => void,filteredData: Record<string, string[]>, setFilteredData: (data: Record<string, any[]>) => void}) =>
{
	const { t } = useTranslation('homeDashboard');
	const [activeKeys, setActiveKeys] = useState<string[]>([]);
	const [taxPayerData, setTaxPayerData] = useState<string[]>([]);
	const [filteredTaxPayers, setFilteredTaxpayers] = useState<string[]>([]);
	const [searchText, setSearchText] = useState<string>('');
	const [currenctFilteredData, setCurrentFilteredData] = useState<
	Record<string, CheckboxValueType[]>
	>({
  	taxPayers: [],
  	currencies: [],
  	calculation: []
	});

	const taxpayerCurrencies = useAppSelector((state) => state.appSettings.taxpayerCurrencies);

	useEffect(() =>
	{
		if (props.visible === true)
		{
			loadTaxpayers();
			setCurrentFilteredData(props.filteredData);
		}
	}, [props?.visible]);

	useEffect(() =>
	{
		const filteredItem = taxPayerData.filter((x) =>
			x.toLowerCase().includes(searchText)
		);
		setFilteredTaxpayers(filteredItem);
	}, [searchText]);

	const onClickAccordion = (activeKeys: string[]) =>
	{
		setActiveKeys(activeKeys);
	};

	const closeFilterModal = () =>
	{
		setSearchText('');
		props.onClose();
	};

	const loadTaxpayers = () =>
	{
		coreService.getTaxpayers(props.engagementPersonalizationStatus).then((res: any) =>
		{
			if (res.data.result.length > 0)
			{
				const data: any = res.data.result;
				setTaxPayerData(data);
				setFilteredTaxpayers(data);
			}
		});
	};

	const handleSearch = (value: string, event: React.SyntheticEvent) =>
	{
		setSearchText(value);
	};

	if (props.visible === false)
	{
		return <></>;
	}

	return (
		<>
			<Modal
				className="filter-modal"
				visible={props.visible}
				initialFocus={false}
				closeOnPressEscape={false}
				maskCloseable={false}
				title={t('filters.filterHeader') || undefined}
				placement={'right'}
				onCancel={() =>
				{
					closeFilterModal();
				}}
				footer={
					<div className="footer-wrapper">
						<Button
							kind="secondary"
							onClick={() =>
							{
								setCurrentFilteredData({
									taxPayers: [],
									currencies: [],
									calculation: []
								});
							}}
						>
							{t('filters.clearAll')}
						</Button>
						<Button
							kind="primary"
							onClick={() =>
							{
								props?.setFilteredData(currenctFilteredData);
								closeFilterModal();
							}}
						>
							{t('filters.applyFilters')}
						</Button>
					</div>
				}
			>
				<div className="filter-wrapper">
					<Accordion
						multiple={true}
						onClick={onClickAccordion}
						activeKeys={activeKeys}
					>
						<AccordionItem
							key="1"
							title={t('filters.taxPayer') || undefined}
							itemKey="1"
						>
							<div className="menu-wrapper">
								<Search
									searchType={'secondary'}
									onChange={handleSearch}
									searchValue={searchText}
									onClear={() => setSearchText('')}
								/>
								<CheckboxGroup
									name="taxPayers"
									className="list-wrapper"
									value={currenctFilteredData.taxPayers}
									onChange={(
										value: CheckboxValueType[],
										event: React.SyntheticEvent
									) =>
										setCurrentFilteredData({
											...currenctFilteredData,
											taxPayers: value
										})
									}
								>
									{filteredTaxPayers.map((item) => (
										<Checkbox key={item} value={item}>
											{item}
										</Checkbox>
									))}
								</CheckboxGroup>
							</div>
						</AccordionItem>

						<AccordionItem
							key="2"
							className="disable-accordion"
							title={t('filters.lastCalculationTYE') || undefined}
							itemKey="2"
						></AccordionItem>

						<AccordionItem
							key="3"
							title={t('filters.calculation') || undefined}
							itemKey="3"
						>
							<CheckboxGroup
								name="calculation"
								className="list-wrapper"
								value={currenctFilteredData.calculation}
								onChange={(
									value: CheckboxValueType[],
									event: React.SyntheticEvent
								) =>
									setCurrentFilteredData({
										...currenctFilteredData,
										calculation: value
									})
								}
							>
								<Checkbox value={'Surplus'}>Surplus</Checkbox>
							</CheckboxGroup>
						</AccordionItem>

						<AccordionItem
							key="4"
							className="disable-accordion"
							title={t('filters.deliveryDate') || undefined}
							itemKey="4"
						></AccordionItem>

						<AccordionItem
							key="5"
							title={t('filters.taxpayerCurrency') || undefined}
							itemKey="5"
						>
							{taxpayerCurrencies&&<CheckboxGroup
								name="currencies"
								className="list-wrapper"
								value={currenctFilteredData.currencies}
								onChange={(value: CheckboxValueType[]) =>
									setCurrentFilteredData({
										...currenctFilteredData,
										currencies: value
									})
								}
							>
								{taxpayerCurrencies.map((item: ICurrency) => (
									item&&<Checkbox key={item.code} value={item.code}>
										{item.code}
									</Checkbox>
								))}
							</CheckboxGroup>
							}
						</AccordionItem>
					</Accordion>
				</div>
			</Modal>
		</>
	);
};
