import {
	Avatar,
	Button,
	Header as AppkitHeader,
	HeaderOptionItem
} from '@appkit4/react-components';
import './Header.scss';
import { AppDispatch, useAppSelector } from 'src/store/store';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from 'src/features/authentication/AuthContext';
import { IAuthContext } from 'src/features/authentication/Types';
import useClickOutside from 'src/common/hooks/useClickOutside';
import { useDispatch } from 'react-redux';
import { fetchUserProfileAsync } from 'src/store/reducers/userProfileSlice';
import { fetchAppSettingsAsync } from 'src/store/reducers/AppSettingsSlice';
import { RoleEnum } from 'src/common/types/enums/RoleEnum';
import { useTranslation } from 'react-i18next';

const Header = () =>
{
	const [showLogoutModal, setShowLogoutModal] = useState(false);
	const { logOut }: IAuthContext = useContext(AuthContext);
	const popoverDivRef = useRef<HTMLDivElement>(null);

	useClickOutside(popoverDivRef, onClosePopover);
	const userProfile = useAppSelector((state) => state.userProfile.profile);
	const dispatch: AppDispatch = useDispatch();

	const appsettings = useAppSelector((state) => state.appSettings);
	const { t } = useTranslation('homeDashboard');

	useEffect(() =>
	{
		if (appsettings.currencies.length === 0)
		{
			dispatch(fetchUserProfileAsync());
			dispatch(fetchAppSettingsAsync());
		}
	}, [dispatch]);
	const onClickLogo = (event: React.MouseEvent<HTMLElement>) =>
	{};
	const navigate = useNavigate();

	return (
		<div className="header-wrapper" data-mode="dark" data-theme="red">
			<AppkitHeader
				type="solid"
				onClickLogo={onClickLogo}
				titleTemplate={() => `${t('appName')}`}
				contentTemplate={() => (
					<>
						<HeaderOptionItem style={{
							marginLeft: '4.5rem'
						}}
						iconName="Appkit4-icon icon-home-fill ap-font-22"
						label={`${t('header.home')}`}
						onClick={() => navigate('/')}></HeaderOptionItem>
					</>
				)}
				optionsTemplate={() =>
				{
					return (
						<>
							{userProfile?.role === RoleEnum.BusinessOwner && (<HeaderOptionItem
								iconName="Appkit4-icon icon-setting-fill ap-font-22"
								label={`${t('header.settings')}`}
								onClick={() => navigate('/user')}
							></HeaderOptionItem>)}
						</>
					);
				}}
				userTemplate={() => (
					<div className="userProfile">
						<Avatar className="userProfileAvatar"
							label={`${userProfile?.firstName[0]}${userProfile?.lastName[0]}`}
							role="button"
							disabled={false}
							onClick={() => setShowLogoutModal(true)}
							compact
						></Avatar>
						{showLogoutModal && (
							<div className="popover" ref={popoverDivRef}>
								<div className="content-container">
									<h5 className="heading">
										{t('header.welcome')}, {`${userProfile?.firstName} ${userProfile?.lastName}`}!
									</h5>
									<h6>{userProfile?.email || ''}</h6>
									<Button onClick={onLogoutButtonClick} compact>{t('header.logout')}</Button>
								</div>
							</div>
						)}</div>
				)}
			></AppkitHeader>
		</div>
	);
	function onClosePopover()
	{
		if (!!showLogoutModal)
		{
			setShowLogoutModal(false);
		}
	};

	function onLogoutButtonClick()
	{
		logOut();
		navigate('/');
	}
};

export default Header;
