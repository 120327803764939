export enum PropertyIncomeTypes {

	FAPI = 1,
	DeemedABI = 2,
	ABI = 3
}

export enum PropertyIncomeFieldNames {
	TypeId = 'typeId',
	Amount = 'amount',
	Notes = 'notes'
}