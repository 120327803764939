import {
	ApplicationInsights,
	ITelemetryItem
} from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
	config: {
		connectionString: process.env.REACT_APP_APPINSIGHTS,
		extensions: [reactPlugin],
		enableAutoRouteTracking: true,
		autoTrackPageVisitTime: true,
		enableCorsCorrelation: false
	}
});
appInsights.loadAppInsights();
appInsights.trackPageView();

appInsights.addTelemetryInitializer((env: ITelemetryItem) =>
{
	env.tags = env.tags || [];
});

export { reactPlugin, appInsights };
