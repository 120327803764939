import React, { useEffect, useState, useContext } from 'react';
import {
	Button,
	Navigation,
	NavigationItem
} from '@appkit4/react-components';
import './ReportSidebar.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ReportEnum } from 'src/common/types/enums/ReportEnum';
import { CalculationTypesEnum } from 'src/common/types/enums/CalculationTypes';
import { IReportNavigationItem } from 'src/common/types/interfaces/INavigationItem';
import AffiliateContext from 'src/common/components/SharedDataContext/AffiliateContext';
import { useAppSelector } from 'src/store/store';

export const ReportSidebar = (props: {
  onSelect: (reportId: ReportEnum) => void;
  reportId: string | undefined;
}) =>
{
	const { t } = useTranslation('report',{
		keyPrefix: 'sidebar'
	});

	const {engagementId, affiliateId } = useParams();
	const navigate = useNavigate();

	const [dynamicNavList, setDynamicNavList] = useState<IReportNavigationItem[]>([]);

	const affiliateContext = useContext(AffiliateContext);

	const calculationTypes = useAppSelector((state) => state.appSettings.calculationTypes);

	const faCalculationTypeIds = affiliateContext?.affiliateDetail.calculationTypeIds;

	const [collapsed, setCollapsed] = React.useState(false);
	const [selectedKey, setSelectedKey] = React.useState('0');
	const [openKeys, setOpenKeys] = useState<string[]>([]);

	const navigateToReport = (selectedReportId: string) =>
	{
		navigate(`/engagement/${engagementId}/affiliate/${affiliateId}/report/${selectedReportId}`);
	};

	const onCollapseEvent = (
		collapsed: boolean,
		event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
	) =>
	{
		setCollapsed(collapsed);
	};

	const getSelectedReportId = (reportKey: string) =>
	{
		const ids = reportKey.split('-');

		setOpenKeys([ids[0]]);

		if (!!ids && ids.length > 1)
		{
			return dynamicNavList[Number(ids[0])]?.children![Number(ids[1])]?.id;
		}
		else
		{
			return dynamicNavList[Number(ids[0])]?.id;
		}
	};

	const handleItemClick = (event: any, item: NavigationItem, key: string) =>
	{
		   setSelectedKey(key);
		   const selectedReportId = getSelectedReportId(key);
		   if (selectedReportId)
		   {
			navigateToReport(selectedReportId);
			props.onSelect(selectedReportId);
		}
	};

	const navList = (calculationTypes: string[]): IReportNavigationItem[] => [
		{
			name: t('summary-surplus-balances'),
			id: ReportEnum.SurplusBalances,
			isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.Surplus || c === CalculationTypesEnum.EnhancedSurplus)
		},
		{
			name: t('shareholder-ownership'),
			id: ReportEnum.ShareholderOwnership,
			isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.Surplus || c === CalculationTypesEnum.EnhancedSurplus ||
			c === CalculationTypesEnum.FAPI || c === CalculationTypesEnum.ACB)
		},
		{
			name: t('affiliate-ownership'),
			id: ReportEnum.AffiliateOwnership,
			isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.Surplus || c === CalculationTypesEnum.EnhancedSurplus ||
			c === CalculationTypesEnum.FAPI || c === CalculationTypesEnum.ACB)
		},
		{
			name: t('exempt-surplus'),
			id: ReportEnum.ExemptSurplusSummary,
			isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.Surplus || c === CalculationTypesEnum.EnhancedSurplus),
			suffixIcon: 'down-chevron',
			children: [
				{
					name: t('summary'),
					id: ReportEnum.ExemptSurplusSummary,
					isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.Surplus ||
						c === CalculationTypesEnum.EnhancedSurplus)
				},
				{
					name: t('details'),
					id: ReportEnum.ExemptSurplusDetails,
					isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.Surplus ||
						c === CalculationTypesEnum.EnhancedSurplus)
				}
			]
		},
		{
			name: t('hybrid-surplus'),
			id: ReportEnum.HybridSurplusSummary,
			isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.Surplus || c === CalculationTypesEnum.EnhancedSurplus),
			suffixIcon: 'down-chevron',
			children: [
				{
					name: t('summary'),
					id: ReportEnum.HybridSurplusSummary,
					isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.Surplus ||
						c === CalculationTypesEnum.EnhancedSurplus)
				},
				{
					name: t('details'),
					id: ReportEnum.HybridSurplusDetails,
					isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.Surplus ||
						c === CalculationTypesEnum.EnhancedSurplus)
				}
			]
		},
		{
			name: t('taxable-surplus'),
			id: ReportEnum.TaxableSurplusSummary,
			isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.Surplus || c === CalculationTypesEnum.EnhancedSurplus),
			suffixIcon: 'down-chevron',
			children: [
				{
					name: t('summary'),
					id: ReportEnum.TaxableSurplusSummary,
					isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.Surplus ||
						c === CalculationTypesEnum.EnhancedSurplus)
				},
				{
					name: t('details'),
					id: ReportEnum.TaxableSurplusDetails,
					isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.Surplus ||
						c === CalculationTypesEnum.EnhancedSurplus)
				}
			]
		},
		{
			name: t('earnings'),
			id: ReportEnum.EarningsSummary,
			isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.Surplus || c === CalculationTypesEnum.EnhancedSurplus ||
			c === CalculationTypesEnum.FAPI),
			suffixIcon: 'down-chevron',
			children: [
				{
					name: t('summary'),
					id: ReportEnum.EarningsSummary,
					isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.Surplus ||
						c === CalculationTypesEnum.EnhancedSurplus)
				},
				{
					name: t('details'),
					id: ReportEnum.EarningsDetails,
					isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.EnhancedSurplus ||
						c === CalculationTypesEnum.FAPI)
				}
			]
		},
		{
			name: t('exempt-earnings-taxable'),
			id: ReportEnum.ExemptEarningsAndTaxable,
			isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.Surplus || c === CalculationTypesEnum.EnhancedSurplus)
		},
		{
			name: t('adjusted-cost-base'),
			id: ReportEnum.AdjustedCostBaseSummary,
			isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.ACB),
			suffixIcon: 'down-chevron',
			children: [
				{
					name: t('summary'),
					id: ReportEnum.AdjustedCostBaseSummary,
					isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.ACB)
				},
				{
					name: t('details'),
					id: ReportEnum.AdjustedCostBaseDetails,
					isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.ACB)
				}
			]
		},
		{
			name: t('foreign-accrual-property-income'),
			id: ReportEnum.ForeignAccrualPropertyIncome,
			isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.EnhancedSurplus || c === CalculationTypesEnum.FAPI)
		},
		{
			name: t('foreign-taxes'),
			id: ReportEnum.ForeignTaxes,
			isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.Surplus || c === CalculationTypesEnum.EnhancedSurplus ||
			c === CalculationTypesEnum.FAPI)
		},
		{
			name: t('dividends-received'),
			id: ReportEnum.DividendsReceived,
			isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.Surplus || c === CalculationTypesEnum.EnhancedSurplus)
		},
		{
			name: t('dividends-paid'),
			id: ReportEnum.DividendsPaid,
			isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.Surplus || c === CalculationTypesEnum.EnhancedSurplus)
		},
		{
			name: t('capital-gains'),
			id: ReportEnum.CapitalGains,
			isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.Surplus || c === CalculationTypesEnum.EnhancedSurplus ||
			c === CalculationTypesEnum.FAPI)
		}
	];

	const filterVisibleNavItems = (navList: IReportNavigationItem[]): IReportNavigationItem[] =>
	{
		return navList.reduce<IReportNavigationItem[]>((acc, currentItem) =>
		{
			if (currentItem.isVisible)
			{
				const newItem: IReportNavigationItem = {
					...currentItem
				};
				if (newItem.children)
				{
					newItem.children = filterVisibleNavItems(newItem.children);
				}
				acc.push(newItem);
			}
			return acc;
		}, []);
	};

	const findKeyByReportId = (navList: any, id: string, parentIndex: string = ''): string | null =>
	{
		for (let i = 0; i < navList.length; i++)
		{
			const item = navList[i];
			if (item.id === id && !item.children)
			{
				return !!parentIndex ? `${parentIndex}-${i}` : `${i}`;
			}
			if (!!item.children && item.children.length > 0)
			{
				const result = findKeyByReportId(item.children, id, !!parentIndex ? `${parentIndex}-${i}` : `${i}`);
				if (!!result)
				{
					return result;
				}
			}
		}
		return null;
	};

	const setSelectedReport = (key: string , id: string) =>
	{
		setSelectedKey(key);
		navigateToReport(id as ReportEnum);
		props.onSelect(id as ReportEnum);
	};

	useEffect(() =>
	{
		var elems: any = document.querySelector('.ap-navigation-content');
		var anchorElems: any = document.querySelector(
			'.collapse-menu-button .collapse-anchor'
		);
		var navHeadingElems: any = document.querySelector('.ap-navigation-heading');
		var navbarWidth: any = document.querySelector(
			'.report-sidebar'
		);
		if (!collapsed)
		{
			elems.style.visibility = 'initial';
			anchorElems.style.flexDirection = 'row';
			anchorElems.style.width = '220px';
			navHeadingElems.style.marginLeft = '-7px';
			navHeadingElems.style.width = '270px';
			navbarWidth.style.width = '260px';
		}
		else
		{
			elems.style.visibility = 'hidden';
			anchorElems.style.flexDirection = 'column';
			anchorElems.style.width = 'fit-content';
			navHeadingElems.style.marginLeft = '7px';
			navbarWidth.style.width = 'fit-content !important';
		}
	}, [collapsed]);

	useEffect(() =>
	{
		if (!!faCalculationTypeIds && faCalculationTypeIds.length > 0 &&
				!!calculationTypes && calculationTypes.length > 0)
		{
			const calculationTypesFiltered = calculationTypes?.filter((r: Record<string, any>) =>
				faCalculationTypeIds?.includes(r.id)).map((type: Record<string, any>) => type.internalName);

			if (!!calculationTypesFiltered && calculationTypesFiltered.length > 0)
			{
				let upadtedNavList = navList(calculationTypesFiltered);

				if (!!calculationTypesFiltered && calculationTypesFiltered?.includes(CalculationTypesEnum.Surplus) &&
						!calculationTypesFiltered?.includes(CalculationTypesEnum.EnhancedSurplus))
				{
					upadtedNavList = upadtedNavList.map((navItem: IReportNavigationItem) =>
					{
						if (navItem.name === t('earnings') && navItem.children)
						{
							const {suffixIcon, children, ...newItem} = navItem;
							return newItem;
						}
						return navItem;
					});
				}

				const filteredNavList = filterVisibleNavItems(upadtedNavList);
				setDynamicNavList(() => filteredNavList);
				const reportKey = !!props.reportId ? findKeyByReportId(filteredNavList, props.reportId as ReportEnum) : undefined;

				if (!!props.reportId && !!reportKey)
				{
					setSelectedReport(reportKey, props.reportId);
				}
				else
				{
					const firstNavItem: IReportNavigationItem = filteredNavList[0];
					if (!!firstNavItem?.children && !!firstNavItem?.children![0] && !!firstNavItem?.children![0]?.id)
					{
						setSelectedReport('0-0', firstNavItem?.children![0]?.id);
					}
					else
					{
						setSelectedReport( '0', firstNavItem?.id);
					}
				}
			}
		}
		else if (!!faCalculationTypeIds && faCalculationTypeIds.length === 0)
		{
			navigate('/');
		}
	},[faCalculationTypeIds, calculationTypes]);

	return (<>
		{
			!!dynamicNavList &&
			<div className="report-sidebar-container">
				<Navigation
					className="report-sidebar"
					navList={dynamicNavList}
					hasHeader={true}
					hasFooter={false}
					hasLogo={false}
					selectedKey={selectedKey}
					onItemClick={handleItemClick}
					openKeys={openKeys}
					defaultCollapsed={false}
					collapsed={collapsed}
					onClickCollapseEvent={onCollapseEvent}
					solid={true}
					userTemplate={() => (
						<div className="collapse-menu-button">
							{
								<Button className='link-button ' kind='text' onClick={() => setCollapsed(!collapsed)}>
									<div className='collapse-anchor'>
											 	<div
											className={`Appkit4-icon ${
												collapsed ?
													'icon-enter-right-outline' :
													'icon-enter-left-outline'
											}`}
										></div>
										<div>
											{collapsed ?
												t('menu') :
												t('collapseMenu')}
										</div>
									</div>
								</Button>
							}
						</div>
					)}
				></Navigation>
			</div>
		}
	</>

	);
};