import { Modal, Button, Input } from '@appkit4/react-components';
import { useEffect, useState } from 'react';
import './ClientModal.scss';
import { useTranslation } from 'react-i18next';
import { coreService } from 'src/core/services/coreService';
import { ClientChangeSubject } from 'src/core/services/EventBus';
import { UnsavedChangesModal } from '../unsavedModal/UnsavedChangesModal';
import { EVentTypeEnum } from 'src/common/types/enums/EventTypeEnum';

interface ClientData {
  clientName: string;
  iPowerCode: string;
}

const defaultFormValue: ClientData = {
	clientName: '',
	iPowerCode: ''
};

const defaultFormError = {
	clientName: null,
	iPowerCode: null,
	clientNameApiError: null,
	iPowerCodeApiError: null
};

export const ClientModal = (props: any) =>
{
	const [clientData, setClientData] = useState<ClientData>(defaultFormValue);
	const [clientFormError, setClientFormError] = useState<Record<string, any>>(
		defaultFormError
	);
	const { t } = useTranslation('homeDashboard');
	const [showUnsavedModal, setShowUnsavedModal] = useState<boolean>(false);

	useEffect(() =>
	{
		if (!!props?.clientModalContext)
		{
			const { iPowerCode, clientName } = props.clientModalContext;
			setClientData({
				clientName: clientName,
				iPowerCode
			});
		}
	}, [props?.visible]);

	useEffect(() =>
	{
		setShowUnsavedModal(false);
	}, []);

	const closeUnsavedModal = (str: string) =>
	{
		if (str === 'stay')
		{
			setShowUnsavedModal(false);
		}
		else if (str === 'leave')
		{
			setShowUnsavedModal(false);
			setClientFormError(defaultFormError);
			props.onClose(props.clientModalContext, false);
			// resetFormInitialState();
		}
	};

	const isSaveDisabled = () =>
	{
		if (!props?.clientModalContext?.clientName)
		{
			return;
		}
		return (
			props?.clientModalContext?.clientName?.toLowerCase() ===
        clientData?.clientName?.toLowerCase() &&
      props?.clientModalContext.iPowerCode === clientData.iPowerCode
		);
	};

	const doesClientFormHaveErrors = () =>
	{
		const { clientName, iPowerCode } = clientData;
		const formValidationErrors: Record<string, any> = {
			...clientFormError
		};
		if (clientName === defaultFormValue.clientName)
		{
			formValidationErrors['clientName'] = t(
				'client-popup.clientName-required'
			);
		}

		if (iPowerCode === defaultFormValue?.iPowerCode)
		{
			formValidationErrors['iPowerCode'] = t(
				'client-popup.iPowerCode-required'
			);
		}
		else if (iPowerCode?.length < 8)
		{
			formValidationErrors['iPowerCode'] = t('client-popup.iPowerCode-format');
		}

		setClientFormError(formValidationErrors);
		return (
			formValidationErrors?.clientName !== defaultFormError?.clientName ||
      formValidationErrors?.iPowerCode !== defaultFormError?.iPowerCode
		);
	};

	const getErrorMessage = (fieldName: string, apiErrField: string) =>
	{
		if (!!clientFormError)
		{
			let errorMsg = '';
			if (!!clientFormError?.[fieldName])
			{
				errorMsg = clientFormError?.[fieldName] || '';
			}
			else if (!!clientFormError?.[apiErrField])
			{
				errorMsg = clientFormError?.[apiErrField] || '';
			}

			return (
				<div
					id="errormessage"
					aria-live="polite"
					className={'ap-field-email-validation-error'}
				>
					{errorMsg}
				</div>
			);
		}
		return null;
	};

	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		fieldName: string,
		fieldValue: string | number
	) =>
	{
		setClientFormError({
			...clientFormError,
			[fieldName]: null,
			clientNameApiError: null
		});
		if (fieldName === 'iPowerCode')
		{
			setClientData({
				...clientData,
				[fieldName]: `${fieldValue}`.trim().replace(/[^0-9]/g, '')
			});
			return;
		}
		setClientData({
			...clientData,
			[fieldName]: `${fieldValue}`
		});
	};

	const handleInputOnBlur = (event: React.ChangeEvent<HTMLInputElement>) =>
	{
		setClientData({
			...clientData,
			clientName: event.target.value.trim()
		});
	};

	const addNewClient = () =>
	{
		coreService
			.createNewClient(clientData.clientName, clientData.iPowerCode)
			.then((res: any) =>
			{
				const data = res.data;
				const client = {
					...data
				};
				props.setClient(client);
			})
			.catch((err: Record<string, any>) =>
			{
				const {response: {data: { Errors, Status }}} = err;
				const apiErrors = {
					clientName: null,
					iPowerCode: null
				};

				if (Status === 400)
				{
					apiErrors.clientName =
            Errors.filter((x: any) => x['Field'] === 'clientName')?.[0]?.[
            	'Message'
            ] || null;
					apiErrors.iPowerCode =
            Errors.filter((x: any) => x['Field'] === 'iPowerCode')?.[0]?.[
            	'Message'
            ] || null;
				}
				setClientFormError({
					...clientFormError,
					...apiErrors
				});
			});
	};

	const updateClient = () =>
	{
		coreService
			.updateClientDetail(
				clientData.clientName,
				clientData.iPowerCode,
				props.clientModalContext.clientId
			)
			.then((res: any) =>
			{
				props.onClose({
					clientName: clientData.clientName,
					iPowerCode: clientData.iPowerCode,
					clientId: props.clientModalContext.clientId
				});

				ClientChangeSubject.next({
					eventType: EVentTypeEnum.ClientChange,
					clientId: props.clientModalContext.clientId,
					payload: {
						clientName: clientData.clientName,
						iPowerCode: clientData.iPowerCode
					}
				});
			})
			.catch((err: Record<string, any>) =>
			{
				const {response: {data: { Errors, Status }}} = err;
				const apiErrors = {
					clientName: null,
					iPowerCode: null
				};

				if (Status === 400)
				{
					apiErrors.clientName =
            Errors.filter((x: any) => x['Field'] === 'clientName')?.[0]?.[
            	'Message'
            ] || null;
					apiErrors.iPowerCode =
            Errors.filter((x: any) => x['Field'] === 'iPowerCode')?.[0]?.[
            	'Message'
            ] || null;
				}
				setClientFormError({
					...clientFormError,
					...apiErrors
				});
			});
	};

	const upsertClientData = () =>
	{
		if (!props.clientModalContext.clientId)
		{
			addNewClient();
		}
		else
		{
			updateClient();
		}
	};

	const onCancel = () =>
	{
		if (
			props?.clientModalContext?.clientName?.toLowerCase() !==
        clientData.clientName?.toLowerCase() ||
      props?.clientModalContext.iPowerCode !== clientData.iPowerCode
		)
		{
			setShowUnsavedModal(true);
		}
		else
		{
			closeUnsavedModal('leave');
		}
	};

	return (
		<>
			<Modal
				className="client-detail-modal"
				visible={props.visible}
				initialFocus={false}
				closeOnPressEscape={false}
				maskCloseable={false}
				title={props.title}
				onCancel={() =>
				{
					setClientFormError(defaultFormError);
					onCancel();
				}}
				footer={
					<div className="footer-wrapper">
						<Button kind="secondary" onClick={() => onCancel()}>
							{t('client-popup.cancel')}
						</Button>
						<Button
							kind="primary"
							onClick={() => !doesClientFormHaveErrors() && upsertClientData()}
							disabled={isSaveDisabled()}
						>
							{t('client-popup.save')}
						</Button>
					</div>
				}
			>
				<div className="client-form-wrapper">
					<Input
						name={'clientName'}
						type={'text'}
						value={clientData.clientName}
						title={t('client-popup.clientName')}
						inputProps={{
							maxLength: 200
						}}
						error={
							(!!clientFormError &&
                !!clientFormError?.['clientNameApiError']) ||
              (!!clientFormError && !!clientFormError?.['clientName'])
						}
						errorNode={getErrorMessage('clientName', 'clientNameApiError')}
						required={true}
						onChange={(
							value: string,
							event: React.ChangeEvent<HTMLInputElement>
						) => handleInputChange?.(event, 'clientName', value)}
						onBlur={handleInputOnBlur}
					/>

					<Input
						name={'iPowerCode'}
						title={t('client-popup.iPowerCode') || ''}
						inputProps={{
							//@ts-ignore
							maxLength: 8,
							pattern: 'd*'
						}}
						value={clientData.iPowerCode}
						error={
							(!!clientFormError &&
                !!clientFormError?.['iPowerCodeApiError']) ||
              (!!clientFormError && !!clientFormError?.['iPowerCode'])
						}
						errorNode={getErrorMessage('iPowerCode', 'iPowerCodeApiError')}
						required={true}
						onChange={(
							value: string | number,
							formattedValue: string,
							event: React.ChangeEvent<HTMLInputElement>
						) => handleInputChange?.(event, 'iPowerCode', value)}
					/>
				</div>
			</Modal>
			<UnsavedChangesModal
				visible={showUnsavedModal}
				onClose={closeUnsavedModal}
				title={t('unsaved-popup.discardChangesTitle') || ''}
			/>
		</>
	);
};
