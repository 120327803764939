export enum DividendReceivedFromOthersFields {
	id='id',
	engagementId='engagementId',
	affiliateId='affiliateId',
	effectiveDate='effectiveDate',
	payerName = 'payerName',
	isPayerForeignAffiliate = 'isPayerForeignAffiliate',
	payerCurrencyId = 'payerCurrencyId',
	amount = 'amount',
	paidOnEquityClassId = 'paidOnEquityClassId',
	percentageOwned = 'percentageOwned',
	dividendReceivedFrom = 'dividendReceivedFrom',
	exemptSurplus = 'exemptSurplus',
	hybridSurplus = 'hybridSurplus',
	hybridUnderlyingTax = 'hybridUnderlyingTax',
	taxableSurplus = 'taxableSurplus',
	underlyingForeignTax = 'underlyingForeignTax',
	preAcquisitionSurplus = 'preAcquisitionSurplus',
	notes = 'notes',
}