import { Button, Modal } from '@appkit4/react-components';
import { useTranslation } from 'react-i18next';
import './FAWeekWarningAndDeleteModal.scss';

export const FAWeekWarningAndDeleteModal = (props: {
	showWarningModal: boolean,
	showDeleteModal: boolean,
	title: string;
	setShowWarningModal: Function;
	setShowDeleteModal: Function;
	warningMessage: string,
	deleteMessage: string,
	onDelete: () => void
}) =>
{
	const { t } = useTranslation('common');

	return (
		<>
			<Modal
				className="fa-week-warning-modal"
				visible={props.showWarningModal || props.showDeleteModal}
				initialFocus={false}
				closeOnPressEscape={false}
				maskCloseable={false}
				title={props.title}
				onCancel={() =>
				{
					props.setShowDeleteModal(false);
					props.setShowWarningModal(false);
				}}
				footer={
					<div className="footer-wrapper">
						{props.showWarningModal && <Button
							kind="secondary"
							onClick={() =>
							{
								props.setShowWarningModal(false);
								props.setShowDeleteModal(true);
							}}
						>
							{t('modal.Ok')}
						</Button>}
						{props.showDeleteModal &&
						<>
							<Button
								kind="secondary"
								onClick={() =>
								{
									props.setShowDeleteModal(false);
								}}
							>
								{t('modal.cancel')}
							</Button>
							<Button kind="negative" onClick={() => props.onDelete()}>
								{t('modal.delete')}
							</Button>
						</>}
					</div>
				}
			>
				{props.showWarningModal &&<div className="content-text">{props.warningMessage}</div>}
				{props.showDeleteModal && <div className="content-text">{props.deleteMessage}</div>}
			</Modal>
		</>
	);
};
