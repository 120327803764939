import { ITooltipProps } from './ITooltipProps';
import {Tooltip as AppKitTooltip } from '@appkit4/react-components/tooltip';

import './Tooltip.scss';

export function Tooltip(props: ITooltipProps): JSX.Element
{
	return <span className={'tooltip'}>
		<AppKitTooltip
			distance={4}
			position={'top'}
			appendAfterTarget
			content={props.text}
		>
			<span className={'Appkit4-icon icon-help-question-outline'} />
		</AppKitTooltip>
	</span>;
}