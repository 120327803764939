const DividendsPaidReportFields = {
	'header': [
		{
			'name': 'status',
			'type': 'status',
			'background': 'section-top-border'
		},
		{
			'name': 'taxYearEnd',
			'type': 'taxYearEnd'
		},
		{
			'name': 'countryId',
			'type': 'countryId'
		},
		{
			'name': 'calculatingCurrencyId',
			'type': 'currencyId'
		}
	],
	'summary': [
		{
			'name': 'summary-title',
			'type': 'title'
		},
		{
			'name': 'summary.exemptSurplus',
			'type': 'currency'
		},
		{
			'name': 'summary.hybridSurplus',
			'type': 'currency'
		},
		{
			'name': 'summary.taxableSurplus',
			'type': 'currency'
		},
		{
			'name': 'summary.totalPreAcquisitionSurplusBalance',
			'type': 'currency'
		},
		{
			'name': 'summary.totalElectionDividendsBalance',
			'type': 'currency'
		}
	],
	'summaryTotals': [
		{
			'name': 'summary.totalDividendPaid',
			'type': 'currency',
			'background': 'highlight'
		},
		{
			'name': 'summary.totalHUTPaid',
			'type': 'currency',
			'background': 'highlight'
		},
		{
			'name': 'summary.totalUFTPaid',
			'type': 'currency',
			'background': 'highlight'
		}
	],
	'details': [
		{
			'name': 'detail-title',
			'type': 'section'
		},
		{
			'name': 'dividendAmount',
			'type': 'currency',
			'background': 'highlight fixed'
		},
		{
			'name': 'paymentDate',
			'type': 'dateWithTime',
			'background': 'fixed'
		},
		{
			'name': 'equityClassId',
			'type': 'equityClassId',
			'background': 'fixed'
		},
		{
			'name': 'isPost90DayDividend',
			'type': 'yesno',
			'background': 'fixed'
		},
		{
			'name': 'additionalUFT',
			'type': 'currency',
			'background': 'fixed'
		},
		{
			'name': 'notes',
			'type': 'text',
			'background': 'fixed'
		},
		{
			'name': '',
			'type': 'separator section'
		},
		{
			'name': 'surplusBalanceBeforeDividend-title',
			'type': 'subtitle'
		},
		{
			'name': 'surplusBalanceBeforeDividend.exemptSurplus',
			'type': 'currency'
		},
		{
			'name': 'surplusBalanceBeforeDividend.hybridSurplus',
			'type': 'currency'
		},
		{
			'name': 'surplusBalanceBeforeDividend.taxableSurplus',
			'type': 'currency'
		},
		{
			'name': 'surplusBalanceBeforeDividend.totalSurplus',
			'type': 'currency',
			'background': 'highlight'
		},
		{
			'name': 'surplusBalanceBeforeDividend.hut',
			'type': 'currency'
		},
		{
			'name': 'surplusBalanceBeforeDividend.uft',
			'type': 'currency'
		},
		{
			'name': '',
			'type': 'separator section'
		},
		{
			'name': 'dividendAllocation-title',
			'type': 'subtitle'
		},
		{
			'name': 'dividendAllocation.exemptSurplus',
			'type': 'currency'
		},
		{
			'name': 'dividendAllocation.hybridSurplus',
			'type': 'currency'
		},
		{
			'name': 'dividendAllocation.taxableSurplus',
			'type': 'currency'
		},
		{
			'name': 'dividendAllocation.preAcquisitionSurplus',
			'type': 'currency'
		},
		{
			'name': 'dividendAllocation.totalDividendPaid',
			'type': 'currency',
			'background': 'highlight'
		},
		{
			'name': 'dividendAllocation.hut',
			'type': 'currency'
		},
		{
			'name': 'dividendAllocation.uft',
			'type': 'currency'
		}
	],
	'post90DayDetails': [
		{
			'name': '',
			'type': 'separator section'
		},
		{
			'name': 'surplusBalanceBefore90DayAdjustment-title',
			'type': 'subtitle'
		},
		{
			'name': 'surplusBalanceBefore90DayAdjustment.exemptSurplus',
			'type': 'currency'
		},
		{
			'name': 'surplusBalanceBefore90DayAdjustment.hybridSurplus',
			'type': 'currency'
		},
		{
			'name': 'surplusBalanceBefore90DayAdjustment.taxableSurplus',
			'type': 'currency'
		},
		{
			'name': 'surplusBalanceBefore90DayAdjustment.totalSurplus',
			'type': 'currency',
			'background': 'highlight'
		},
		{
			'name': 'surplusBalanceBefore90DayAdjustment.hut',
			'type': 'currency'
		},
		{
			'name': 'surplusBalanceBefore90DayAdjustment.uft',
			'type': 'currency'
		},
		{
			'name': '',
			'type': 'separator section'
		},
		{
			'name': 'post90DayDividendAllocation-title',
			'type': 'subtitle'
		},
		{
			'name': 'post90DayDividendAllocation.exemptSurplus',
			'type': 'currency'
		},
		{
			'name': 'post90DayDividendAllocation.hybridSurplus',
			'type': 'currency'
		},
		{
			'name': 'post90DayDividendAllocation.taxableSurplus',
			'type': 'currency'
		},
		{
			'name': 'post90DayDividendAllocation.preAcquisitionSurplus',
			'type': 'currency'
		},
		{
			'name': 'post90DayDividendAllocation.totalDividendPaid',
			'type': 'currency',
			'background': 'highlight'
		},
		{
			'name': 'post90DayDividendAllocation.hut',
			'type': 'currency'
		},
		{
			'name': 'post90DayDividendAllocation.uft',
			'type': 'currency'
		},
		{
			'name': '',
			'type': 'separator section'
		},
		{
			'name': 'totalDividendAllocation-title',
			'type': 'subtitle'
		},
		{
			'name': 'totalDividendAllocation.exemptSurplus',
			'type': 'currency'
		},
		{
			'name': 'totalDividendAllocation.hybridSurplus',
			'type': 'currency'
		},
		{
			'name': 'totalDividendAllocation.taxableSurplus',
			'type': 'currency'
		},
		{
			'name': 'totalDividendAllocation.preAcquisitionSurplus',
			'type': 'currency'
		},
		{
			'name': 'totalDividendAllocation.totalDividendPaid',
			'type': 'currency',
			'background': 'highlight'
		},
		{
			'name': 'totalDividendAllocation.hut',
			'type': 'currency'
		},
		{
			'name': 'totalDividendAllocation.uft',
			'type': 'currency'
		}
	]
};

export default DividendsPaidReportFields;