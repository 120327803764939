import React, { useEffect, useState } from 'react';
import { RelationshipFieldEnum } from '../../types/enums/RelationshipFieldEnum';
import {
	Input,
	Select
} from '@appkit4/react-components';

import './RelationshipResolutionCellEditor.scss';
import { useAppSelector } from 'src/store/store';
import { INameId } from 'src/common/types/interfaces/INameId';
import { RegularPatternEnum } from 'src/common/types/enums/RegularPatternEnum';
import { IRelationshipCellEditorProps } from './IRelationshipResolutionCellEditorProps';

export const RelationshipResolutionCellEditor = (props: IRelationshipCellEditorProps) =>
{
	const equityClasses = useAppSelector((state) => state.appSettings.equityClasses);

	const [value, setValue] = useState<any>(undefined);
	const [changed, setChanged] = useState<boolean>(false);

	const handleValueChange = (e: any) =>
	{
		const val = e?.target?.value ?? e;
		if (
			props.field === RelationshipFieldEnum.SEP ||
      		props.field === RelationshipFieldEnum.equityPercentage
		)
		{
			const regPattern = new RegExp(RegularPatternEnum.Percentage);
			if (!regPattern.test(val))
			{
				return;
			}
			if (val>100||val<0)
			{
				return;
			}
		}
		if (!props.dataItem[RelationshipFieldEnum.dirty])
		{
			props.dataItem[RelationshipFieldEnum.dirty] = true;
		}
		if (props.field && props.error && props.error[props.field])
		{
			setChanged(true);
		}
		if (props.onChange && props.field === RelationshipFieldEnum.equityClassId)
		{
			props.onChange({
				dataIndex: 0,
				dataItem: props.dataItem,
				field: props.field,
				syntheticEvent: e.syntheticEvent,
				value: e?.target?.value ?? e
			});
		}
		setValue(val);
	};

	const handleBlur = (e: any) =>
	{
		const val = e?.target?.value ?? e;
		if (
			props.field === RelationshipFieldEnum.SEP ||
      		props.field === RelationshipFieldEnum.equityPercentage
		)
		{
			if (props.onChange && val !== '.')
			{
				props.onChange({
					dataIndex: 0,
					dataItem: props.dataItem,
					field: props.field,
					syntheticEvent: e.syntheticEvent,
					value: value
				});
			}
			if (val==='.')
			{
				if (props.onChange)
				{
					props.onChange({
						dataIndex: 0,
						dataItem: props.dataItem,
						field: props.field,
						syntheticEvent: e.syntheticEvent,
						value: 0
					});
				}
				setValue(0);
			}
		}
	};

	const onRenderErrorMessage = (): JSX.Element | null =>
	{
		if (
			!changed &&
			!!props.error &&
			!!props.field &&
			!!props.error[props.field]
		)
		{
			return <div className={'custom-cell-error'}>
				{
					props.error[props.field]
				}
			</div>;
		}

		return null;
	};

	useEffect(
		() =>
		{
			if (!!props.field)
			{
				setValue(props.dataItem[props.field]);
				setChanged(false);
			}
		},
		[
			props.dataItem,
			props.field
		]
	);

	return (
		<td
			className={`relationship-resolution-cell ${props.className}  
			${props.error && props.dataItem[RelationshipFieldEnum.inEdit] ? 'error' :''}
     		${props.dataItem[RelationshipFieldEnum.inEdit] ? 'editing' : ''}`}
			style={props.style}
		>
			{
				props.field === RelationshipFieldEnum.equityClassId &&
				(
					props.dataItem[RelationshipFieldEnum.editable] ?
						<div className="custom-cell">
							<div className="custom-cell-input">
								{
									(

										(
											!!equityClasses &&
											!!equityClasses.length
										)
									) &&
									<Select
										className="client-name-style"
										data={
											equityClasses
										}
										hideTitleOnInput={true}
										valueKey={'id'}
										labelKey={'name'}
										searchable={true}
										value={value}
										placeholder={''}
										dropdownMatchWidth={false}
										dropdownAlwaysDown={false}
										dropdownRenderMode="portal"
										onSelect={(value: any) =>
										{
											handleValueChange(value);
										}}
										error={!changed &&
											!!props.error &&
											!!props.field &&
											!!props.error[props.field]}
									/>

								}
							</div>
							{onRenderErrorMessage()}
						</div> :
						<div className="custom-cell">
							<div className="custom-cell-text">

								{
									equityClasses
										.find(
											(c: INameId) =>
												c.id === props.dataItem[props.field ?? '']
										)?.name
								}
							</div>
						</div>
				)
			}
			{
				(
					props.field === RelationshipFieldEnum.SEP ||
					props.field === RelationshipFieldEnum.equityPercentage
				) &&
				(
					props.dataItem[RelationshipFieldEnum.editable]?
					 <div className="custom-cell">
							<div className="custom-cell-input">
								<Input
									type={'text'}
									className={'fn-name-input'}
									onChange={handleValueChange}
									onBlur={handleBlur}
									hideTitleOnInput={true}
									value={value}
									inputProps={{
										maxLength: 12
									}}
									error={!changed &&
										!!props.error &&
										!!props.field &&
										!!props.error[props.field]}
								/>
							</div>
							{onRenderErrorMessage()}
						</div> :

						<div className="custom-cell">
							<div className="custom-cell-text">
								{parseFloat(props.dataItem[props.field])}
							</div>
						</div>

				)
			}
		</td>
	);
};
