import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { ReportI18NKeys } from 'src/common/types/enums/ReportI18NKeys';
import { Loading, Notification } from '@appkit4/react-components';
import SurplusReportSection from '../ReportCells/SurplusReportSection';
import { ISurplusReportField } from 'src/common/types/interfaces/IReportField';
import { ReportFieldEnum } from 'src/common/types/enums/ReportFieldEnum';
import { ReportUtil } from 'src/features/engagement/utils/ReportUtil';
import  ReportCell  from 'src/features/engagement/components/Report/ReportCells/ReportCell';
import { ExemptSurplusDetailsReportFields, ExemptSurplusDetailsReportNoTransactionsFields }
	from 'src/features/engagement/components/Report/Settings/ExemptSurplusDetailsReportFields';
import { NotificationService } from 'src/core/services/notificationService';
import './ExemptSurplusDetailsReport.scss';

export const ExemptSurplusDetailsReport = (props: {refershReport: boolean}) =>
{
	const {engagementId, affiliateId } = useParams();
	const [reportData, setReportData] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const columnsCount = 4;

	const { t } = useTranslation(
		'report',
		{
			keyPrefix: ReportI18NKeys.ExemptSurplusDetails
		}
	);

	const { t: report } = useTranslation(
		'report',
		{
			keyPrefix: ReportI18NKeys.Report
		}
	);


	const reportFields: ISurplusReportField[] = ExemptSurplusDetailsReportFields;
	const reportNoTransactionsFields: any = ExemptSurplusDetailsReportNoTransactionsFields;

	const renderRowSeperator = () =>
	{
		return (<tr className='separator fixed'>{
			Array.from({
				length: columnsCount
			}, () =>
			{
				return ( <td></td>);
			}
			)
		}</tr>);
	};

	const renderNoTransactionSection = (data: any) =>
	{
		const noData = {
			[ReportFieldEnum.Date]: null,
			[ReportFieldEnum.Description]: t('exempt-earnings-losses'),
			[ReportFieldEnum.Amount]: 0,
			[ReportFieldEnum.Balance]: !!data.endingBalance ? data.endingBalance[ReportFieldEnum.Amount] : 0
		};

		if ((!!data.openingBalance && !!data.openingBalance[ReportFieldEnum.Amount]) ||
			(!!data.endingBalance && !!data.endingBalance[ReportFieldEnum.Amount]))
		{
			return (<>
				{renderRowSeperator()}
				<tr className={`${ReportUtil.getRowClassName(reportNoTransactionsFields)}`}>
					{reportNoTransactionsFields.transactions.map((column: any) =>
					{
						return (<td className={`${ReportUtil.getRowClassName(column)}`}>
							<ReportCell data={noData} field={column}/></td>);
					})}
				</tr>
			</>);
		}
	};

	const triggerErrorNotification = async (message: string): Promise<void> =>
	{
		const ele = (
			<Notification
			  message={message}
			  status={'error'}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};

	const loadExemptSurplusDetailsReportData = () =>
	{
		if (engagementId && affiliateId)
		{
			setIsLoading(true);
			engagementService.loadExemptSurplusDetailsReport(Number(engagementId), Number(affiliateId)).then((res: any) =>
			{
				setIsLoading(false);
				setReportData(res.data.result);
			})
				.catch((error: any) =>
				{
					triggerErrorNotification(
						t('loadingExemptSurplusError')
					);
					setIsLoading(false);
				});
		}
	};

	useEffect(() =>
	{
		loadExemptSurplusDetailsReportData();
	},[engagementId, affiliateId]);

	useEffect(() =>
	{
		if (!!props.refershReport)
		{
			loadExemptSurplusDetailsReportData();
		}
	}, [props.refershReport]);


	return <div className='exempt-surplus-details-report'>
		 <div className='table-container'>
		 {isLoading && (
				<div className="loading-icon">
					<Loading
						loadingType="circular"
						indeterminate={true}
						compact={false}
					/>
				</div>
			)}
			{reportData &&
			<table>
				<thead>
					<tr>
						<div className='header-title'>
							{t('header-title')}
						</div>
						{
							Array.from({
								length: columnsCount-1
							}, () =>
							{
								return ( <th></th>);
							}
							)
						}
					</tr>
				</thead>
				<tbody>
					{reportData.map(( item: any, index: number) =>
						<SurplusReportSection
							index={index}
							reportData={reportData}
							reportFields={reportFields}
							columnsCount={columnsCount}
							rowSeperator={renderRowSeperator}
							data={item}
							i18nKey={ReportI18NKeys.ExemptSurplusDetails}
							noTransactionSection={renderNoTransactionSection}/>
					)}
				</tbody>
			</table>
			}
		</div>
		{!isLoading && !!reportData && reportData.length === 0 &&
			<div className='report-no-data'>
				<div className='content'>
					<div className='title'>{report('report-not-ready')}</div>
					<div className='message'>{report('generate-report')}</div>
				</div>
			</div>
		}
	</div>;
};
