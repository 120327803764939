import { ReportFieldEnum } from 'src/common/types/enums/ReportFieldEnum';
import { IReportField } from 'src/common/types/interfaces/IReportField';
import { ReportUtil } from 'src/features/engagement/utils/ReportUtil';
import StatusCell from './StatusCell';
import { NotesEditor } from 'src/common/components/NotesEditor/NotesEditor';

const ReportCell = (props: {data: any, field: IReportField, displayNotes?: boolean}) =>
{
	const renderCell = () =>
	{
		switch (props.field.type)
		{
		case ReportFieldEnum.Status:
			return <StatusCell/>;
		case ReportFieldEnum.DateTime:
			return (<>
			    <div>{`${ReportUtil.renderField(props.data, {
					...props.field,
					type: ReportFieldEnum.Date
				})}`}</div>
				<div>{`${ReportUtil.renderField(props.data, {
					...props.field,
					type: ReportFieldEnum.Time
				})}`}</div>
			</>);
		case ReportFieldEnum.DateWithTime:
			return (<>
			    <div>{`${ReportUtil.renderField(props.data, {
					...props.field,
					type: ReportFieldEnum.DateTime
				})}`}</div>
			</>);
		case ReportFieldEnum.AmountWithNotes:
			return (<>
				<div className={`${!!props.displayNotes ? 'notes-container' : ''}`}>
					{!!props.displayNotes && <NotesEditor
						displayOnly={true}
						isDisabled={true}
						notes={`${ReportUtil.renderField(props.data, {
							...props.field,
							type: ReportFieldEnum.Notes
						})}`}
					/>}
			    <div>{`${ReportUtil.renderField(props.data, props.field)}`}</div></div>
			</>);
		default:
			return <>{`${ReportUtil.renderField(props.data, props.field)}`}</>;
		}
	};
	return (
		<>
			{renderCell()}
		</>
	);
};

export default ReportCell;