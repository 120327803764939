import { Button } from '@appkit4/react-components';
import { useTranslation } from 'react-i18next';

import './ForeignTaxableIncomeGrid.scss';
import { Tooltip } from '../Tooltip/Tooltip';
import { IForeignTaxableIncomeGridProps } from './IForeignTaxableIncomeGridProps';
import { ForeignTaxableIncomeRowColumns } from './components/ForeignTaxableIncomeRowColumns';
import { IForeignTaxableIncomeYear } from 'src/common/types/interfaces/IForeignTaxableIncome';

export function ForeignTaxableIncomeGrid(props: IForeignTaxableIncomeGridProps): JSX.Element
{
	const { t } = useTranslation(
		'common',
		{
			keyPrefix: 'foreignTaxableIncomeGrid'
		}
	);

	return <>
		{
			!!props.gridData &&
			<div
				className={'foreign-taxable-income-grid'}
				style={{
					visibility: props.isLoading ? 'hidden' : 'visible'
				}}
			>
				{
					!!props.title &&
					<div className={'title'}>
						<span>
							{
								props.title
							}
						</span>
						{props.titleTooltip && <Tooltip
							text={props.titleTooltip}
						/>}
					</div>
				}
				<div
					ref={props.customRef}
					className={'table-container table-scroll-container'}
				>
					<table
						id={props.id}
						className={props.displayNotes ? 'notes' : ''}
					>
						<thead>
							<tr>
								<th className={'action-column'}>
								</th>
								<th className={'first-column'}>
									{
										t('descriptionHeader')
									}
								</th>
								{
									!!props.gridData &&
									props.gridData
										.map((year: IForeignTaxableIncomeYear) =>
										{
											return (
												<>
													<th className={`boundary amount-cell amount-type 
																${!!props.displayNotes ? 'notes-included' : ''}`}>
														{t('amountHeader')}</th>
													<th className={`boundary amount-type 
																${!!props.displayNotes ? 'notes-included' : ''}`}>{t('reg5907Header')}</th>
												</>);
										})
								}
							</tr>
						</thead>

						<tbody>
							{
								!!props.rowData &&
								!!props.rowData.length &&
								props.rowData
									.map((rd, rowIndex) =>
									{
										return <ForeignTaxableIncomeRowColumns
											isLoading={props.isLoading}
											isDisabled={props.isDisabled}
											validate={props.validate}
											displayNotes={props.displayNotes}
											rowData={rd}
											descriptionOptions={props.descriptionOptions}
											onDescriptionUpdate={(value) =>
											{
												props.onDescriptionUpdate(rowIndex, value);
											}}
											onValueUpdate={(fieldName, index, value) =>
											{
												props.onValueUpdate(fieldName, rowIndex, index, value);
											}}
											onDeleteRow={props.onDeleteRow}
										/>;
									})
							}
						</tbody>
					</table>
				</div>
				{
					(
						!props.rowData ||
						!props.rowData.length
					) &&
					<div className={'no-data'}>
						<div>
							{ t('noDataPart1') }
						</div>
						<div>
							{t('noDataPart2')}<strong>+ {props.addRowButtonText}</strong> {t('noDataPart3')}
						</div>
					</div>
				}
				<div className={'actions'}>
					<Button
						kind={'text'}
						icon={'icon-plus-outline'}
						onClick={props.onRowAdd}
					>
						{
							props.addRowButtonText
						}
					</Button>
				</div>
			</div>
		}
	</>;
}