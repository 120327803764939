export enum ForeignAffiliateFields {
	affiliateId = 'affiliateId',
	name = 'name',
	currencyId = 'currencyId',
	countryId = 'countryId',
	taxationYearEnd = 'taxationYearEnd',
	dateBecameFa = 'dateBecameFa',
	analysisStartDate = 'analysisStartDate',
	analysisEndDate = 'analysisEndDate',
	surplus = 'surplus',
	enhancedSurplus = 'enhancedSurplus',
	FAPI = 'FAPI',
	ACB = 'ACB',
}