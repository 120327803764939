import { Select } from '@appkit4/react-components';
import { IDropdownControlProps } from './IDropdownControlProps';
import { useEffect, useState } from 'react';
import { SelectValue } from '@appkit4/react-components/esm/select/Select';
import { useTranslation } from 'react-i18next';

import './DropdownControl.scss';

export function DropdownControl(props: IDropdownControlProps): JSX.Element
{
	const { t: commonControlMessages } = useTranslation(
		'common',
		{
			keyPrefix: 'gridControls'
		});

	const [value, setValue] = useState('');

	const getNoDataTemplate = (): React.ReactNode =>
	{
		return <>
			{
				commonControlMessages('noDataAvailable')
			}
		</>;
	};

	const onSelect = (value: SelectValue) =>
	{
		if (!!value && !!value.toString())
		{
			setValue(value.toString());
			props.onChange(value.toString());
		}
		else
		{
			setValue('');
			props.onChange('');
		}
	};

	useEffect(
		() =>
		{
			if (!!props.value)
			{
				setValue(props.value);
			}
		},
		[
			props.value
		]
	);

	return <div className={'dropdown-control'}
		title={
			!!props.options && !!props.options.length && !!value &&
			!!props.options.some((o) => `${o.id}` === value) ? props.options.find((o) => `${o.id}` === value)?.name : ''
		}
	>
		<Select
			valueKey={'id'}
			labelKey={'name'}
			hideTitleOnInput={!props.showTitleOnInput}
			dropdownAlwaysDown={false}
			searchable={!!props.isSearchable}
			required={!!props.isRequired}
			disabled={!!props.isDisabled}
			dropdownMatchWidth={props.dropdownMatchWidth !== undefined ? props.dropdownMatchWidth : true }
			data={
				!!props.options ?
					!!props.includeResetOption ?
						[
							{
								id: 0,
								name: commonControlMessages('blankOption') || ''
							},
							...props.options.map((o) => ({
								id: `${o.id}`,
								name: o.name
							}))
						] :
						props.options.map((o) => ({
							id: `${o.id}`,
							name: o.name
						})) :
					[]
			}
			dropdownRenderMode={'portal'}
			placeholder={props.placeholder || ''}
			noResultFound={getNoDataTemplate()}
			onSelect={(value: SelectValue) =>
			{
				onSelect(value);
			}}
			value={
				!!value ?
					value :
					''
			}
			error={!!props.isError}
		/>
		{
			!!props.isError &&
			(
				!!props.errorMessage ||
				!!props.onRenderErrorMessage
			) &&
			<div className={'error'}>
				{
					!!props.onRenderErrorMessage ?
						props.onRenderErrorMessage() :
						props.errorMessage
				}
			</div>
		}
	</div>;
}