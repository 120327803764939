import { dateFormatter } from 'src/common/utils/dateFormatter';
import { Utils } from 'src/common/utils/utils';

export interface IDownloadRequest {

	id: number;
	requestedDate: Date;
	requestedBy: string;
	clientName: string;
	engagementName: string;
	fileName: string;
	requestStatus: string;
}

export class DownloadRequest implements IDownloadRequest
{
	constructor()
	{
		this.id = Utils.generateTempId();
		this.requestedDate = new Date();
		this.requestedBy = '';
		this.clientName = '';
		this.engagementName = '';
		this.fileName = '';
		this.requestStatus = '';
	}

	id: number;
	requestedDate: Date;
	requestedBy: string;
	clientName: string;
	engagementName: string;
	fileName: string;
	requestStatus: string;

	static toEntity = (record: any): IDownloadRequest =>
	{
		return {
			...record,
			requestedDate: dateFormatter.utcToEst(record.dateRequested)!,
			requestStatus: record.status
		};
	};
}