import { Button, Input, Modal, Select } from '@appkit4/react-components';
import { ITaxPayerFormProps } from './ITaxPayerFormProps';
import { useTranslation } from 'react-i18next';
import { ITaxPayer, TaxPayerFieldNames } from 'src/features/engagement/models/ITaxPayer';
import { useEffect, useState } from 'react';
import { Utils } from 'src/common/utils/utils';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { NotificationService } from 'src/core/services/notificationService';
import { UnsavedChangesModal } from 'src/core/components/Dashboard/unsavedModal/UnsavedChangesModal';
import { UnexpectedErrorModal } from 'src/common/components/UnexpectedErrorModal/UnexpectedErrorModal';
import { InputError } from 'src/common/components/ModalControls/InputError/InputError';

import './TaxPayerForm.scss';

const defaultFormError: Record<string, string | null> = {
	name: null,
	currencyId: null
};

export function TaxPayerForm(props: ITaxPayerFormProps): JSX.Element
{
	const { t } = useTranslation(
		'homeDashboard',
		{
			keyPrefix: 'taxPayerForm'
		}
	);

	const { t: fieldNames } = useTranslation(
		'homeDashboard',
		{
			keyPrefix: 'taxPayerFormFieldNames'
		}
	);

	const { t: errorMessages } = useTranslation(
		'homeDashboard',
		{
			keyPrefix: 'taxPayerFormErrors'
		}
	);

	const [record, setRecord] = useState<ITaxPayer | undefined>(undefined);

	const [isSaving, setIsSaving] = useState(false);
	const [hasChange, setHasChange] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);

	const [formError, setFormError] = useState<Record<string, any>>(defaultFormError);

	const [displayUnsavedChangesModal, setDisplayUnsavedChangesModal] = useState(false);
	const [errorModalMessage, setErrorModalMessage] = useState('');

	const getErrorMessage = (error: any): string | undefined =>
	{
		if (
			!!error &&
			!!error.response &&
			!!error.response.data &&
			!!error.response.data.Errors &&
			!!error.response.data.Errors.length
		)
		{
			const fieldError = error.response.data.Errors[0];

			if (!!fieldError)
			{
				const fieldName = Utils.pascalToCamel(fieldError.Field);
				const message = errorMessages(error.response.data.Errors[0].ErrorCode) || '';

				setFormError({
					...formError,
					[fieldName]: message
				});

				return message;
			}
		}

		return undefined;
	};

	const onCreate = async (): Promise<void> =>
	{
		try
		{
			setIsSubmitted(true);

			if (!!checkUpdateFormValidity(record!))
			{
				setIsSaving(true);

				const id = await engagementService.createTaxPayer(
					props.clientId,
					record!
				);

				onCloseModal(true, id.data);
				setIsSaving(false);

				onSuccessNotification({
					isCreate: true
				});
			}
		}
		catch (error)
		{
			if (!getErrorMessage(error))
			{
				setErrorModalMessage(errorMessages('createFailed') || '');
			}

			setIsSaving(false);
		}
	};

	const onUpdate = async (): Promise<void> =>
	{
		try
		{
			setIsSubmitted(true);

			if (!!checkUpdateFormValidity(record!))
			{
				setIsSaving(true);

				await engagementService.updateTaxPayer(
					props.clientId,
					record!
				);

				onCloseModal(true, record?.id);
				setIsSaving(false);

				onSuccessNotification({
					isUpdate: true
				});
			}
		}
		catch (error)
		{
			if (!getErrorMessage(error))
			{
				setErrorModalMessage(errorMessages('updateFailed') || '');
			}

			setIsSaving(false);
		}
	};

	const onSuccessNotification = (
		{
			isCreate,
			isUpdate
		}:
		{
			isCreate?: boolean;
			isUpdate?: boolean;
		}
	) =>
	{
		let message = '';

		if (isCreate)
		{
			message = t('createSuccess');
		}
		else if (isUpdate)
		{
			message = t('updateSuccess');
		}

		NotificationService.success({
			message
		});
	};

	const checkUpdateFormValidity = (form: ITaxPayer): boolean =>
	{
		const errors = {
			...defaultFormError
		};

		if (!form.name)
		{
			errors.name = errorMessages('nameRequired');
		}

		if (!form.currencyId)
		{
			errors.currencyId = errorMessages('currencyIdRequired');
		}

		setFormError({
			...errors
		});

		// check if any fields have error messages and return status
		return Object.values(errors).every((e) => !e);
	};

	const onCloseUnsavedChangesModal = (key: 'leave' | 'stay'): void =>
	{
		if (key === 'leave')
		{
			onClose();
		}
		else
		{
			setDisplayUnsavedChangesModal(false);
		}
	};

	const onCloseModal = (isSuccess?: boolean, taxPayerId?: number): void =>
	{
		if (!!hasChange && !isSuccess)
		{
			setDisplayUnsavedChangesModal(true);
		}
		else
		{
			onClose(!!isSuccess, taxPayerId);
		}
	};

	const onClose = async (isSuccess?: boolean, taxPayerId?: number): Promise<void> =>
	{
		setDisplayUnsavedChangesModal(false);
		props.onDismiss();

		await Utils.timeout(500);

		onReset();

		if (!!isSuccess)
		{
			props.onRefresh(taxPayerId!);
		}
	};

	const onReset = () =>
	{
		setIsSaving(false);
		setHasChange(false);
		setRecord(undefined);
		setIsSubmitted(false);
		setErrorModalMessage('');
		setDisplayUnsavedChangesModal(false);
		setFormError({
			...formError
		});
	};

	const onValueChange = (field: string, value: any): void =>
	{
		const updatedForm: ITaxPayer = {
			...record!,
			[field]: value
		};

		checkUpdateFormValidity(updatedForm);

		setRecord(updatedForm);
	};

	useEffect(
		() =>
		{
			if (!!props.record)
			{
				setRecord({
					...props.record
				});
			}
			else
			{
				setRecord(undefined);
			}
		},
		[props.record]
	);

	useEffect(
		() =>
		{
			setHasChange(
				!record ||
				!props.record ||
				record.name !== props.record.name ||
				record.currencyId !== props.record.currencyId
			);
		},
		[record]
	);

	return <>
		<Modal
			className={'tax-payer-modal'}
			visible={
				props.displayModal &&
				!!props.record &&
				!!record
			}
			closeOnPressEscape={false}
			maskCloseable={false}
			initialFocus={false}
			title={
				!!props.isAdd ?
					t('addModalTitle') || '' :
					t('editModalTitle') || ''
			}
			onCancel={() =>
			{
				onCloseModal();
			}}
			footer={
				<div className={'buttons'}>
					<div className={'left'}>
					</div>
					<div className={'right'}>
						{
							!!props.isAdd &&
							<Button
								disabled={
									!hasChange ||
									!!isSaving
								}
								onClick={onCreate}
							>
								{
									t('saveButtonText')
								}
							</Button>
						}
						{
							!props.isAdd &&
							<Button
								disabled={
									!hasChange ||
									!!isSaving
								}
								onClick={onUpdate}
							>
								{
									t('saveButtonText')
								}
							</Button>
						}
						<Button
							kind={'secondary'}
							disabled={!!isSaving}
							onClick={() =>
							{
								onCloseModal();
							}}
						>
							{
								t('cancelButtonText')
							}
						</Button>
					</div>
				</div>
			}
		>
			{
				!!props.record &&
				!!record &&
				<div className={'tax-payer-modal-body'}>
					<div className={'tax-payer-form-body'}>
						<div>
							<Input
								required
								type={'text'}
								title={
									fieldNames(TaxPayerFieldNames.name) || ''
								}
								value={
									!!record.name ?
										record.name :
										''
								}
								inputProps={{
									maxLength: 250
								}}
								error={
									!!isSubmitted &&
									!!formError &&
									!!formError.name
								}
								onChange={(value: string) =>
								{
									onValueChange(
										TaxPayerFieldNames.name,
										value
									);
								}}
							/>
							<InputError
								isError={
									!!isSubmitted &&
									!!formError &&
									!!formError.name
								}
								message={formError.name}
							/>
						</div>
						<div>
							<Select
								required
								searchable
								valueKey={'id'}
								labelKey={'code'}
								data={props.currencies}
								value={record.currencyId}
								dropdownAlwaysDown={false}
								placeholder={fieldNames('currencyId') || undefined}
								dropdownRenderMode={'portal'}
								onSelect={(value) =>
								{
									onValueChange(
										TaxPayerFieldNames.currencyId,
										+value
									);
								}}
								error={
									!!isSubmitted &&
									!!formError &&
									!!formError.currencyId
								}
							/>
							<InputError
								isError={
									!!isSubmitted &&
									!!formError &&
									!!formError.currencyId
								}
								message={formError.currencyId}
							/>
						</div>
						{props.isAdd !== undefined && !props.isAdd && <div className={'currency-change-message'}>
							{t('changeOfCurrencyMessage')}
						</div>}
					</div>
				</div>
			}
		</Modal>
		<UnsavedChangesModal
			visible={displayUnsavedChangesModal}
			onClose={onCloseUnsavedChangesModal}
			title={t('discardChangesTitle') || ''}
		/>
		<UnexpectedErrorModal
			visible={!!errorModalMessage}
			error={''}
			message={errorModalMessage}
			onClose={() =>
			{
				setErrorModalMessage('');
			}}
		/>
	</>;
}