import React, { useContext, useState } from 'react';
import { Button, Tab, Tabs } from '@appkit4/react-components';
import { OpeningBalance } from '../FAHistory/OpeningBalance/OpeningBalance';
import { FAChanges } from '../FAHistory/FAChanges/FAChanges';
import { useNavigate } from 'react-router-dom';
import { FAAnnualData } from '../FAAnnualData/FAAnnualData';
import { useTranslation } from 'react-i18next';
import AffiliateContext from 'src/common/components/SharedDataContext/AffiliateContext';
import { UnsavedModal } from 'src/common/components/UnsaveModal/UnsavedModal';
import './FAInput.scss';

export const FAInput = () =>
{
	const navigate = useNavigate();
	const affiliateContext = useContext(AffiliateContext);
	const [activeIndex, setactiveIndex] = useState(0);
	const [hasUnsavedChange, setHasUnsavedChange] = useState<boolean>(false);
	const [showUnsavedModal, setShowUnsavedModal] = useState<boolean>(false);

	const { t } = useTranslation('input');

	const onTabChange = (i: number, value: string) =>
	{
		(activeIndex !== i && hasUnsavedChange) ? setShowUnsavedModal(true) : setactiveIndex(i);
	};

	const backToReport = () =>
	{
		if (affiliateContext)
		{
			navigate(
				`/engagement/${affiliateContext.engagementDetail.id}/affiliate/${affiliateContext.affiliateDetail.affiliateId}/report`);
		}
	};

	const handleUnsavedChange = (change: boolean) =>
	{
		setHasUnsavedChange(change);
	};

	return (
		<>
			<div className="input-container">
				<div className="input-header">
					<div className="back-link">
						{
							<Button className='link-button' kind='text' onClick={backToReport}>
								<span className="Appkit4-icon icon-arrow-left-outline"></span>{t('backToReport')}
							</Button>
						}
					</div>
					<div className="title">Inputs</div>
					<Tabs
						type="filled"
						activeIndex={activeIndex}
						onTabChange={onTabChange}
					>
						<Tab label="FA Annual Data" value="AnnualData" />
						<Tab label="FA History" value="History" />
					</Tabs>
				</div>
				<div>
					{activeIndex === 0 &&
						<FAAnnualData handleUnsavedChange={handleUnsavedChange} />}
					{
						activeIndex === 1 &&
						<div>
							{' '}
							<OpeningBalance></OpeningBalance>
							<FAChanges></FAChanges>
						</div>
					}
				</div>
			</div>
			<UnsavedModal
				showDialog={showUnsavedModal}
				cancelNavigation={() => setShowUnsavedModal(false)}
				confirmNavigation={() =>
				{
					setactiveIndex(1);
					setHasUnsavedChange(false);
					setShowUnsavedModal(false);
				}
				} />
		</>
	);
};
