import { useTranslation } from 'react-i18next';
import './AccessDeniedPage.scss';

export const AccessDeniedPage = () =>
{
	const { t } = useTranslation(
		'user', {
			keyPrefix: 'systemAccessDenied'
		}
	);

	return (
		<div className="access-denied-page">
			<div className="title">{t('title') || ''}</div>
			<div className="subtitle">{t('messagePart1') || ''}</div>
			<div className="message">{t('messagePart2') || ''}</div>
		</div>
	);
};