import { IPropertyIncomeRow } from 'src/common/types/interfaces/IPropertyIncome';

export const gridIds = {
	header: 'headerTable',
	income: 'IncomeTable',
	expense: 'ExpenseTable',
	adjustment: 'AdjustmentTable',
	aggregateTable: 'AggregateTable'
};

export type ValuesByYear = {
	[yearEnd: string]: IPropertyIncomeRow[]
}

export type ValuesByAffiliateId = {
	[affiliateId: number]: IPropertyIncomeRow[]
}

export type ValuesByDescriptionYear = {
	[descriptionId: number]: ValuesByYear
}

export type RowsByGridType = { [gridId: string]: IPropertyIncomeRow[][] }
export type RecordsByGridType = { [gridId: string]: ValuesByAffiliateId }

export const initialPropertyRowData: RowsByGridType = {

	[gridIds.income]: [],
	[gridIds.expense]: [],
	[gridIds.adjustment]: []
};

export const initialPropertyRecordData: RecordsByGridType = {

	[gridIds.income]: {
	},
	[gridIds.expense]: {
	},
	[gridIds.adjustment]: {
	}
};