import { GridCellProps } from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';
import { RelationshipFieldEnum } from './RelationshipFieldEnum';
import {
	Button,
	Checkbox,
	Input,
	Select,
	TimePicker
} from '@appkit4/react-components';

import './RelationshipCellEditor.scss';
import { useAppSelector } from 'src/store/store';
import { INameId } from 'src/common/types/interfaces/INameId';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import { RegularPatternEnum } from 'src/common/types/enums/RegularPatternEnum';
import { ActionEnum } from 'src/common/types/enums/ActionEnum';
import { IAffiliateOwner } from 'src/features/engagement/models/IAffiliateOwner';
import { CalendarPickerWrapper } from 'src/common/components/CalendarPickerWrapper/CalendarPickerWrapper';
import { NotesEditor } from 'src/common/components/NotesEditor/NotesEditor';

export const RelationshipCellEditor = (
	props: GridCellProps & {
    affiliateOwners: IAffiliateOwner[];
    error: any;
    editing: boolean;
    onAction: (action: ActionEnum, dataItem: any) => void;
	maxDate?: Date | undefined
  }
) =>
{
	const equityClasses = useAppSelector(
		(state) => state.appSettings.equityClasses
	);
	const [value, setValue] = useState<any>(undefined);

	const [changed, setChanged] = useState<Boolean>(false);

	const handleValueChange = (e: any) =>
	{
		const val = e?.target?.value ?? e;
		if (
			props.field === RelationshipFieldEnum.SEP ||
      props.field === RelationshipFieldEnum.equityPercentage
		)
		{
			const regPattern = new RegExp(RegularPatternEnum.Percentage);
			if (!regPattern.test(val))
			{
				return;
			}
		}
		if (!props.dataItem[RelationshipFieldEnum.dirty])
		{
			props.dataItem[RelationshipFieldEnum.dirty] = true;
		}
		if (props.field && props.error && props.error[props.field])
		{
			setChanged(true);
		}
		if (props.onChange)
		{
			props.onChange({
				dataIndex: 0,
				dataItem: props.dataItem,
				field: props.field,
				syntheticEvent: e.syntheticEvent,
				value: e?.target?.value ?? e
			});
		}
		setValue(val);
	};

	useEffect(() =>
	{
		if (props.field)
		{
			if (props.field)
			{
				setValue(props.dataItem[props.field]);
				setChanged(false);
			}
		}
	}, [props.dataItem, props.field]);

	return (
		<td
			className={`relationship-cell ${props.className}  ${
				props.error && props.dataItem[RelationshipFieldEnum.inEdit] ?
					'error' :
					''
			}
      ${props.dataItem[RelationshipFieldEnum.inEdit] ? 'editing' : ''}
      `}
			style={props.style}
		>
			{props.field === RelationshipFieldEnum.selected && (
				<>
					{props.dataItem[RelationshipFieldEnum.primary] ? (
						<div className="custom-cell">

							{props.dataItem[RelationshipFieldEnum.inEdit] ?
								<div className="custom-cell-input">
									<div className="action-buttons"><Button
										icon="icon-save-outline"
										kind="text"
										onClick={() =>
											props.onAction(ActionEnum.Save, props.dataItem)
										}
									></Button></div>
								</div>:
								<div
									className={`${
										props.dataItem[RelationshipFieldEnum.inEdit] ?
											'custom-cell-input' :
											''
									}`}
								>
									<Checkbox
										disabled={props.editing||!props.dataItem.operatable}
										checked={props.dataItem.selected}
										onChange={handleValueChange}
									></Checkbox>
								</div>
							}


						</div>
					) : (
						<div className="custom-cell">
							<div
								className={`${
									props.dataItem[RelationshipFieldEnum.inEdit] ?
										'custom-cell-input' :
										'custom-cell-text'
								}`}
							>
								{' '}
							</div>
						</div>
					)}
				</>
			)}
			{props.field === RelationshipFieldEnum.expanded && (
				<>
					{props.dataItem[RelationshipFieldEnum.primary] &&
          !props.dataItem[RelationshipFieldEnum.editable] ? (
							<div className='expand-icon'>
								{props.dataItem[RelationshipFieldEnum.expanded] ? (
									<span
										className="Appkit4-icon icon-up-chevron-outline"
										onClick={() => handleValueChange(false)}
									></span>
								) : (
									<span
										className="Appkit4-icon icon-down-chevron-outline"
										onClick={() => handleValueChange(true)}
									></span>
								)}
							</div>
						) : (
							<div className='custom-cell'>
								{props.dataItem[RelationshipFieldEnum.primary] ? (<>
									{props.dataItem[RelationshipFieldEnum.inEdit] ?
										(
											<div className='custom-cell-input'>
												<div className="action-buttons">
													<Button
														icon="icon-circle-delete-outline"
														kind="text"
														onClick={() =>
															props.onAction(ActionEnum.Cancel, props.dataItem)
														}
													></Button>
												</div>
											</div>
										):
										(
											<div className='custom-cell-text'>
												<div className="action-buttons">
													<Button
														icon="icon-pencil-outline primary"
														kind="text"
														disabled={props.editing}
														onClick={() =>
															props.onAction(ActionEnum.Edit, props.dataItem)
														}
													></Button>
												</div>
											</div>
										)}
								</>):(
									<>
										{props.dataItem[RelationshipFieldEnum.inEdit]?
											<div className="custom-cell-input"></div>:
											<></>}
									</>

								)
								}
							</div>
						)}
				</>
			)}
			{props.field === RelationshipFieldEnum.faName && (
				<>
					{props.dataItem[RelationshipFieldEnum.primary] ? (
						<div className="custom-cell">
							<div
								className={`${
									props.dataItem[RelationshipFieldEnum.inEdit] ?
										'custom-cell-input' :
										'custom-cell-text'
								}`}
							>
								{props.dataItem[props.field]}
							</div>
						</div>
					) : (
						<div className="custom-cell">
							<div
								className={`${
									props.dataItem[RelationshipFieldEnum.inEdit] ?
										'custom-cell-input' :
										'custom-cell-text'
								}`}
							>
								{props.dataItem[RelationshipFieldEnum.inEdit] ?
									(<div className="action-buttons">
										<Button
											icon="icon-circle-delete-outline"
											kind="text"
											onClick={() =>
												props.onAction(ActionEnum.Cancel, props.dataItem)
											}
										></Button>
										<Button
											icon="icon-save-outline"
											kind="text"
											onClick={() =>
												props.onAction(ActionEnum.Save, props.dataItem)
											}
										></Button>
									</div>):
									(<div className="action-buttons">
										<Button
											icon="icon-archive-outline"
											kind="text"
											disabled={props.editing}
											onClick={() =>
												props.onAction(ActionEnum.Delete, props.dataItem)
											}
										></Button>
										<Button
											icon="icon-pencil-outline secondary"
											kind="text"
											disabled={props.editing}
											onClick={() =>
												props.onAction(ActionEnum.Edit, props.dataItem)
											}
										></Button>
									</div>
									)}
							</div>
						</div>
					)}
				</>
			)}
			{props.field === RelationshipFieldEnum.ownerAffiliateId &&
        (props.dataItem[RelationshipFieldEnum.editable] &&
        props.dataItem[RelationshipFieldEnum.inEdit] ? (
        		<div className="custom-cell">
        			<div className="custom-cell-input">
        				{props.affiliateOwners.length > 0 && (
        					<Select
        						className="client-name-style"
        						data={
        							props.affiliateOwners.filter((a: any) => a.id !== props.dataItem[RelationshipFieldEnum.faId])
        						}
        						hideTitleOnInput={true}
        						valueKey={'id'}
        						labelKey={'name'}
        						searchable={true}
        						value={value}
        						placeholder={''}
        						dropdownMatchWidth={false}
        						dropdownAlwaysDown={false}
        						// noResultFound={getNoDataTemplate()}
        						dropdownRenderMode="portal"
        						onSelect={(value: any) =>
        						{
        							handleValueChange(value);
        						}}
        						error={
        							!changed &&
                    !!props.field &&
                    !!props.error &&
                    !!props.error[props.field]
        						}
        					/>
        				)}
        			</div>
        			{!changed && props.error && props.error[props.field] && (
        				<div className="custom-cell-error">
        					{props.error[props.field]}
        				</div>
        			)}
        		</div>
        	) : (
        		<div className="custom-cell">
        			{props.dataItem[RelationshipFieldEnum.primary] &&
            !props.dataItem[RelationshipFieldEnum.editable] ? (
        					<div>{''}</div>
        				) : (
        					<div
        						className={`${
        							props.dataItem[RelationshipFieldEnum.inEdit] ?
        								'custom-cell-input' :
        								'custom-cell-text'
        						}`}
        					>
        						{`${props.dataItem[props.field]===0?'':
        							props.affiliateOwners.find(
        								(a: INameId) =>
        									a.id === props.dataItem[props.field ?? '']
        							)?.name
        						}`}
        					</div>
        				)}
        		</div>
        	))}
			{props.field === RelationshipFieldEnum.equityClassId &&
        (props.dataItem[RelationshipFieldEnum.editable] &&
        props.dataItem[RelationshipFieldEnum.inEdit] ? (
        		<div className="custom-cell">
        			<div className="custom-cell-input">
        				{equityClasses?.length > 0 && (
        					<Select
        						className="client-name-style"
        						data={equityClasses}
        						hideTitleOnInput={true}
        						valueKey={'id'}
        						labelKey={'name'}
        						searchable={true}
        						value={value}
        						placeholder={''}
        						dropdownMatchWidth={false}
        						dropdownAlwaysDown={false}
        						// noResultFound={getNoDataTemplate()}
        						dropdownRenderMode="portal"
        						onSelect={(value: any) =>
        						{
        							handleValueChange(value);
        						}}
        						error={
        							!changed &&
                    !!props.field &&
                    !!props.error &&
                    !!props.error[props.field]
        						}
        					/>
        				)}
        			</div>
        			{!changed && props.error && props.error[props.field] && (
        				<div className="custom-cell-error">
        					{props.error[props.field]}
        				</div>
        			)}
        		</div>
        	) : (
        		<div>
        			{props.dataItem[RelationshipFieldEnum.primary] &&
            !props.dataItem[RelationshipFieldEnum.editable] ? (
        					<div className="custom-cell"></div>
        				) : (
        					<div className="custom-cell">
        						<div className="custom-cell-text">
        							{
        								equityClasses.find(
        									(c: INameId) =>
        										c.id === props.dataItem[props.field ?? '']
        								)?.name
        							}
        						</div>
        					</div>
        				)}
        		</div>
        	))}
			{props.field === RelationshipFieldEnum.equityPercentage &&
        (props.dataItem[RelationshipFieldEnum.inEdit] ? (
        	<div className="custom-cell">
        		<div className="custom-cell-input">
        			<Input
        				type={'text'}
        				className="fn-name-input"
        				onChange={handleValueChange}
        				hideTitleOnInput={true}
        				value={value}
        				inputProps={{
        					maxLength: 12
        				}}
        				error={
        					!changed &&
                  !!props.field &&
                  !!props.error &&
                  !!props.error[props.field]
        				}
        			></Input>
        		</div>
        		{!changed && props.error && props.error[props.field] && (
        			<div className="custom-cell-error">
        				{props.error[props.field]}
        			</div>
        		)}
        	</div>
        ) : (
        	<div>
        		{props.dataItem[RelationshipFieldEnum.primary] &&
            !props.dataItem[RelationshipFieldEnum.editable] ? (
        				''
        			) : (
        				<div className="custom-cell">
        					<div className="custom-cell-text">
        						{props.dataItem[props.field]===undefined?'':props.dataItem[props.field]}
        					</div>
        				</div>
        			)}
        	</div>
        ))}
			{props.field === RelationshipFieldEnum.SEP &&
        (props.dataItem[RelationshipFieldEnum.inEdit] ? (
        	<div className="custom-cell">
        		<div className="custom-cell-input">
        			<Input
        				type={'text'}
        				className="fn-name-input"
        				onChange={handleValueChange}
        				hideTitleOnInput={true}
        				value={value}
        				inputProps={{
        					maxLength: 12
        				}}
        				error={
        					!changed &&
        					props.field &&
                  props.error &&
                  props.error[props.field]
        				}
        			></Input>
        		</div>
        		{!changed && props.error && props.error[props.field] && (
        			<div className="custom-cell-error">
        				{props.error[props.field]}
        			</div>
        		)}
        	</div>
        ) : (
        	<div>
        		{props.dataItem[RelationshipFieldEnum.primary] &&
            !props.dataItem[RelationshipFieldEnum.editable] ? (
        				''
        			) : (
        				<div className="custom-cell">
        					<div className="custom-cell-text">
        						{props.dataItem[props.field]===undefined?'':props.dataItem[props.field]}
        					</div>
        				</div>
        			)}
        	</div>
        ))}
			{props.field === RelationshipFieldEnum.asOfDate &&
        (props.dataItem[RelationshipFieldEnum.inEdit] ? (
        	<div className="custom-cell">
        		<div className="custom-cell-input">
        			<CalendarPickerWrapper
        				placeholder={' '}
        				fieldTitle={''}
        				format={'MMM DD, YYYY'}
        				hideTitleOnInput={true}
        				editable={false}

        				value={
        					props.dataItem[props.field]?
        						new Date(props.dataItem[props.field]):undefined
        				}
        				minDate={new Date('1972,1,1')}
        				maxDate={props.maxDate}
        				onChange={handleValueChange}
        				datePanelHorizontalAlign="right"
        				datepickerAlwaysDown={false}
        				useCustomValidation={true}
        				error={
        					!changed &&
                  !!props.field &&
                  !!props.error &&
                  !!props.error[props.field]
        				}
        				customErrorNode={<></>}
        			/>
        		</div>
        		{!changed && props.error && props.error[props.field] && (
        			<div className="custom-cell-error">
        				{props.error[props.field]}
        			</div>
        		)}
        	</div>
        ) : (
        	<div>
        		{props.dataItem[RelationshipFieldEnum.primary] &&
            !props.dataItem[RelationshipFieldEnum.editable] ? (
        				''
        			) : (
        				<div className="custom-cell">
        					<div className="custom-cell-text">
        						{props.dataItem[props.field]?dateFormatter.format(
        							new Date(props.dataItem[props.field]),
        							'MMM DD, YYYY'
        						):''}
        					</div>
        				</div>
        			)}
        	</div>
        ))}
			{props.field === RelationshipFieldEnum.asOfTime &&
        (props.dataItem[RelationshipFieldEnum.inEdit] ? (
        	<div className="custom-cell">
        		<div className="custom-cell-input">
        			<TimePicker
        				className="fill-input-container"
        				hourTime={12}
        				hideTitleOnInput={!!value}
        				value={
        					props.dataItem[props.field] ?
        						new Date(props.dataItem[props.field]) :
        						props.dataItem[props.field]
        				}
        				onChange={handleValueChange}
        				error={
        					!changed &&
                  !!props.field &&
                  !!props.error &&
                  !!props.error[props.field]
        				}
        				customErrorNode={<></>}
        			/>
        		</div>
        		{!changed && props.error && props.error[props.field] && (
        			<div className="custom-cell-error">
        				{props.error[props.field]}
        			</div>
        		)}
        	</div>
        ) : (
        	<div>
        		{props.dataItem[RelationshipFieldEnum.primary] &&
            !props.dataItem[RelationshipFieldEnum.editable] ? (
        				''
        			) : (
        				<div className="custom-cell">
        					<div className="custom-cell-text">
        						{dateFormatter.format(props.dataItem[props.field], 'hh:mm A')}
        					</div>
        				</div>
        			)}
        	</div>
        ))}
			{props.field === RelationshipFieldEnum.changeReason && (
				<>
					{props.dataItem[RelationshipFieldEnum.primary] &&
          !props.dataItem[RelationshipFieldEnum.editable] ? (
							''
						) : (
							<div className="custom-cell">
								<div
									className={`${
										props.dataItem[RelationshipFieldEnum.inEdit] ?
											'custom-cell-input wrapped' :
											'custom-cell-text'
									}`}
								>
									{' '}
									{props.dataItem[props.field]}
								</div>
							</div>
						)}
				</>
			)}
			{props.field === RelationshipFieldEnum.survivorAffiliateName && (
				<>
					{props.dataItem[RelationshipFieldEnum.primary] &&
          !props.dataItem[RelationshipFieldEnum.editable] ? (
							''
						) : (
							<div className="custom-cell">
								<div
									className={`${
										props.dataItem[RelationshipFieldEnum.inEdit] ?
											'custom-cell-input' :
											'custom-cell-text'
									}`}
								>
									{props.dataItem[props.field]}
								</div>
							</div>
						)}
				</>
			)}
			{props.field === RelationshipFieldEnum.notes && (
				<>
					{!props.dataItem[RelationshipFieldEnum.inEdit] && !!props.dataItem[props.field] &&
						<div
							className={`${
								props.dataItem[RelationshipFieldEnum.inEdit] ?
									'custom-cell-input' :
									'custom-cell-text'
							}`}
						>
							<NotesEditor
								notes={props.dataItem[props.field]}
								isDisabled={true}
							/>
						</div>
					}
					{!!props.dataItem[RelationshipFieldEnum.inEdit] &&
					<div className="custom-cell">
						<div
							className={`${
								props.dataItem[RelationshipFieldEnum.inEdit] ?
									'custom-cell-input' :
									'custom-cell-text'
							}`}
						>
							<NotesEditor
								notes={props.dataItem[props.field]}
								onSave={handleValueChange}
							/>
						</div>
						{!changed && props.error && props.error[props.field] && (
							<div className="custom-cell-error">
								{props.error[props.field]}
							</div>
						)}
					</div>}
				</>
			)}
		</td>
	);
};
