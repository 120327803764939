import { Modal, Button, Input, Select, TextArea } from '@appkit4/react-components';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UnsavedChangesModal } from 'src/core/components/Dashboard/unsavedModal/UnsavedChangesModal';

import './Reg5907Modal.scss';
import store from 'src/store/store';
import { IOption } from 'src/common/types/interfaces/IOption';
import { INameId } from 'src/common/types/interfaces/INameId';
import { EngagementUtil } from 'src/features/engagement/utils/EngagementUtil';
import { IReg5907Adjustment } from 'src/common/types/interfaces/IForeignTaxableIncome';
import { SelectValue } from '@appkit4/react-components/esm/select/Select';
import { NumericFormControl } from 'src/common/components/FormControls';
import { RegularPatternEnum } from 'src/common/types/enums/RegularPatternEnum';
import AffiliateContext from 'src/common/components/SharedDataContext/AffiliateContext';

export const Reg5907Modal = (props: {visible: boolean, showTaxPayerSelect: boolean | undefined,
	otherTaxpayerIds: number[], reg5907?: IReg5907Adjustment,
	descriptionOptions: INameId[], onCancel: () => void, onSave: (reg5907: IReg5907Adjustment) => void}) =>
{
	const affiliateContext = useContext(AffiliateContext);
	const { t } = useTranslation(
		'input',
		{
			keyPrefix: 'reg5907Modal'
		}
	);

	const [reg5907Adjustment, setReg5907Adjustment] = useState<IReg5907Adjustment|undefined>(undefined);

	const yesNoOptions = store.getState().appSettings.options.filter((c: IOption) => c.field==='YesNo');

	const [showUnsavedModal, setShowUnsavedModal] = useState<boolean>(false);

	const closeUnsavedModal = (str: string) =>
	{
		if (str === 'stay')
		{
			setShowUnsavedModal(false);
		}
		else if (str === 'leave')
		{
			setShowUnsavedModal(false);
		}
	};

	const handleInputChange = (
		fieldName: string,
		fieldValue: string | number
	) =>
	{
		if (reg5907Adjustment)
		{
			setReg5907Adjustment({
				...reg5907Adjustment,
				[fieldName]: fieldValue
			});
		}
	};

	const selectTaxpayer = (
		value: SelectValue
	) =>
	{
		if (reg5907Adjustment)
		{
			const taxPayerId = value==='true'?affiliateContext?.engagementDetail.taxPayerId:undefined;
			setReg5907Adjustment({
				...reg5907Adjustment,
				'taxPayerId': taxPayerId
			});
		}
	};

	useEffect(() =>
	{
		if (props.reg5907)
		{
			setReg5907Adjustment(props.reg5907);
		}
	},[props.reg5907, props.visible]);

	const handleSave = () =>
	{
		if (reg5907Adjustment)
		{
			props.onSave(reg5907Adjustment);
		}
	};

	const getOtherTaxpayerNames = () =>
	{
		const names = props.otherTaxpayerIds.map((taxpayerId: number) =>
		{
			return affiliateContext?.taxpayers.find((t) => t.id===taxpayerId)?.name;
		}
		);
		return names.join(', ');
	};

	return (
		<>
			<Modal
				className="reg5907-adjustment-modal"
				visible={props.visible}
				initialFocus={false}
				closeOnPressEscape={false}
				maskCloseable={false}
				title={t('title')||''}
				onCancel={() =>
				{
					props.onCancel();
				}}
				footer={
					<div className="footer-wrapper">
						<Button kind="secondary" onClick={() => props.onCancel()}>
							{t('cancel')}
						</Button>
						<Button
							kind="primary"
							onClick={() => handleSave() }
							disabled={reg5907Adjustment?.amount===undefined}
						>
							{t('save')}
						</Button>
					</div>
				}
			>
				{props.reg5907 &&	<div className="client-form-wrapper">
					{props.descriptionOptions &&	<Select
						placeholder={t('description') || ''}
						labelKey='name'
						valueKey='id'
						value={reg5907Adjustment?.descriptionId}
						data={props.descriptionOptions}
						disabled={true}
						required={true}
					/>
					}

					<Input
						name={'currencyId'}
						type={'text'}
						value={EngagementUtil.getCurrencyCode(reg5907Adjustment?.currencyId)}
						title={t('currency')}
						required={false}
						disabled={true}
					/>
					<NumericFormControl
						field="amount"
						isRequired={true}
						value={reg5907Adjustment?.amount}
						title={t('amount')||''}
						isNumericFormatted={false}
						format={RegularPatternEnum.Currency}
						onChange={(
							field: string, value: string
						) => handleInputChange?.(field, value)}
					/>
					{yesNoOptions && props.showTaxPayerSelect &&
					<Select
						placeholder={`${t('specificToTaxpayer')} 
						${affiliateContext?.taxpayers.find((t) => t.id===affiliateContext.engagementDetail.taxPayerId)?.name}`}
						labelKey='label'
						valueKey='value'
						required={true}
						disabled={props.otherTaxpayerIds.length>0}
						value={(reg5907Adjustment?.taxPayerId===affiliateContext?.engagementDetail.taxPayerId).toString()}
						data={yesNoOptions}
					    dropdownAlwaysDown={false}
						onSelect ={selectTaxpayer}
					/>
					}
					{props.otherTaxpayerIds.length>0 && props.showTaxPayerSelect &&
					<div className='specificToTaxpayerMessage'>
						{t('specificToTaxpayerMessage').replace('{{taxpayerName}}',
							getOtherTaxpayerNames())}
					</div>
					}
					<TextArea
						autosize
						title={
							t('notes') || ''
						}
						value={
							!!reg5907Adjustment?.notes ? reg5907Adjustment?.notes : ''
						}
						maxLength={255}
						onChange={(value: string) =>
						{
							handleInputChange?.('notes', value);
						}
						}
					/>
				</div>
				}
			</Modal>
			<UnsavedChangesModal
				visible={showUnsavedModal}
				onClose={closeUnsavedModal}
				title={t('unsaved-popup.discardChangesTitle') || ''}
			/>
		</>
	);
};

