import { Badge, Button, Modal } from '@appkit4/react-components';
import { useTranslation } from 'react-i18next';
import './WarningModal.scss';

export const WarningModal = (props: {visible: boolean, title: string, content: string, showWarningIcon?: boolean,
	onClose: () => void, showCancelButton?: boolean, onCancel?: () => void}) =>
{
	const { t } = useTranslation('common');
	return (
		<>
			<Modal
				className="warning-generic-modal"
				visible={props.visible}
				initialFocus={false}
				closeOnPressEscape={false}
				maskCloseable={false}
				title={props.title}
				header={props.showWarningIcon?<span className="Appkit4-icon icon-alert-fill"></span>:
					<Badge type="warning" value={t('modal.Warning')}></Badge>}
				onCancel={!!props.showCancelButton && !!props.onCancel ? props.onCancel : props.onClose}
				footer={
					<div className="footer-wrapper">
						{!!props.showCancelButton && !!props.onCancel &&
						<Button kind="secondary" onClick={props.onCancel}>
							{t('modal.cancel')}
						</Button>}
						<Button kind="negative" onClick={props.onClose}>
							{t('modal.Ok')}
						</Button>
					</div>
				}
			>
				<div>{props.content}</div>
			</Modal>
		</>
	);
};
