import { TFunction } from 'i18next';
import { AdjustedCostBaseFields } from 'src/common/types/enums/AdjustedCostBaseFields';
import { ILineItem } from 'src/common/types/interfaces/ILineItem';
import { IRowInformation } from 'src/common/types/interfaces/IRowInformation';
import { inputRecordHelper } from 'src/common/utils/inputRecordHelper';
export const AdjustedCostBaseHelper = {

	getRowInformation: (
		translationService: TFunction,
		lineItems?: ILineItem[]
	): IRowInformation[] =>
	{
		const lineItemsByKey = inputRecordHelper.lineItemsByKey(lineItems);

		return [
			{
				key: AdjustedCostBaseFields.effectiveDate,
				name: translationService(AdjustedCostBaseFields.effectiveDate),
				lineItemConfiguration: lineItemsByKey[AdjustedCostBaseFields.effectiveDate]
			},
			{
				key: AdjustedCostBaseFields.isAddition,
				name: translationService(AdjustedCostBaseFields.isAddition),
				lineItemConfiguration: lineItemsByKey[AdjustedCostBaseFields.isAddition]
			},
			{
				key: AdjustedCostBaseFields.transactionTypeId,
				name: translationService(AdjustedCostBaseFields.transactionTypeId),
				lineItemConfiguration: lineItemsByKey[AdjustedCostBaseFields.transactionTypeId]
			},
			{
				key: AdjustedCostBaseFields.description,
				name: translationService(AdjustedCostBaseFields.description),
				lineItemConfiguration: lineItemsByKey[AdjustedCostBaseFields.description]
			},
			{
				key: AdjustedCostBaseFields.shareholderName,
				name: translationService(AdjustedCostBaseFields.shareholderName),
				lineItemConfiguration: lineItemsByKey[AdjustedCostBaseFields.shareholderName]
			},
			{
				key: AdjustedCostBaseFields.equityClassId,
				name: translationService(AdjustedCostBaseFields.equityClassId),
				lineItemConfiguration: lineItemsByKey[AdjustedCostBaseFields.equityClassId]
			},
			{
				key: AdjustedCostBaseFields.currencyId,
				name: translationService(AdjustedCostBaseFields.currencyId),
				lineItemConfiguration: lineItemsByKey[AdjustedCostBaseFields.currencyId]
			},

			{
				key: AdjustedCostBaseFields.electionsAmount,
				name: translationService(AdjustedCostBaseFields.electionsAmount),
				lineItemConfiguration: lineItemsByKey[AdjustedCostBaseFields.electionsAmount]
			},
			{
				key: AdjustedCostBaseFields.shareholderEquityPercentage,
				name: translationService(AdjustedCostBaseFields.shareholderEquityPercentage),
				lineItemConfiguration: lineItemsByKey[AdjustedCostBaseFields.shareholderEquityPercentage]
			},
			{
				key: AdjustedCostBaseFields.amount,
				name: translationService(AdjustedCostBaseFields.amount),
				lineItemConfiguration: lineItemsByKey[AdjustedCostBaseFields.amount]
			},
			{
				key: AdjustedCostBaseFields.foreignExchangeRate,
				name: translationService(AdjustedCostBaseFields.foreignExchangeRate),
				lineItemConfiguration: lineItemsByKey[AdjustedCostBaseFields.foreignExchangeRate],
				tooltip: inputRecordHelper.getTooltip(translationService, AdjustedCostBaseFields.foreignExchangeRate)!
			},
			{
				key: AdjustedCostBaseFields.isPartnershipOwned,
				name: translationService(AdjustedCostBaseFields.isPartnershipOwned),
				lineItemConfiguration: lineItemsByKey[AdjustedCostBaseFields.isPartnershipOwned]
			},
			{
				key: AdjustedCostBaseFields.partnershipName,
				name: translationService(AdjustedCostBaseFields.partnershipName),
				lineItemConfiguration: lineItemsByKey[AdjustedCostBaseFields.partnershipName]
			},
			{
				key: AdjustedCostBaseFields.partnershipCurrencyId,
				name: translationService(AdjustedCostBaseFields.partnershipCurrencyId),
				lineItemConfiguration: lineItemsByKey[AdjustedCostBaseFields.partnershipCurrencyId]
			},
			{
				key: AdjustedCostBaseFields.partnershipsEquityPercentage,
				name: translationService(AdjustedCostBaseFields.partnershipsEquityPercentage),
				lineItemConfiguration: lineItemsByKey[AdjustedCostBaseFields.partnershipsEquityPercentage]
			},
			{
				key: AdjustedCostBaseFields.noOfSharesAcquiredOrDisposed,
				name: translationService(AdjustedCostBaseFields.noOfSharesAcquiredOrDisposed),
				lineItemConfiguration: lineItemsByKey[AdjustedCostBaseFields.noOfSharesAcquiredOrDisposed]
			},
			{
				key: AdjustedCostBaseFields.notes,
				name: translationService(AdjustedCostBaseFields.notes),
				lineItemConfiguration: lineItemsByKey[AdjustedCostBaseFields.notes]
			}
		];
	}
};