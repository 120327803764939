import { ReportFieldEnum } from 'src/common/types/enums/ReportFieldEnum';
import { ReportUtil } from 'src/features/engagement/utils/ReportUtil';
import ReportCell from '../../ReportCells/ReportCell';
import { useTranslation } from 'react-i18next';
import { EngagementUtil } from 'src/features/engagement/utils/EngagementUtil';
import { ACBDetailsReportCurrencyTypes, ACBDetailsReportFields } from 'src/common/types/enums/AdjustedCostBaseDetailsReportFields';
import { ReportI18NKeys } from 'src/common/types/enums/ReportI18NKeys';

const renderRowSeperator = (columnsCount: number, isScrollBar?: boolean) =>
{
	return (<tr className={`${!!isScrollBar ? 'scrollBar' : 'separator'} fixed`}>{
		Array.from({
			length: columnsCount
		}, () =>
		{
			return ( <td ></td>);
		}
		)
	}</tr>);
};

const renderTransactions =(transactions: any, row: any, lookupData: any) =>
{
	return (
		<>
			{transactions.map((t: any) =>
			{
				return (
					<tr className={`${ReportUtil.getRowClassName(row)}`}>
						{row.fields.filter((t: any) => !!t.isVisible).map((column: any) =>
						{
							if (!!column && column.type === ReportFieldEnum.TypeId)
							{
								const typeId = !!t && !!t[ReportFieldEnum.TypeId] ? t[ReportFieldEnum.TypeId] : undefined;
								if (typeId===undefined)
								{
									return '';
								}
								const transactionType = !!lookupData && lookupData.length > 0 ?
									lookupData.find((c: any ) => c.id===Number(typeId))?.name : '';

								return (<td className={'description'}>
									{transactionType}</td>);
							}
							else
							{
								return (<td className={`${ReportUtil.getRowClassName(column)}`}>
									<ReportCell data={t} field={column}/></td>);
							}
						})}
					</tr>
				);
			})}
		</>);
};


const EquityClassSectionsByShareholder = (props: {
	index: number,
	i18nKey: string,
	columnsCount: number,
	reportFields: any,
	selectedCurrency: any,
	lookupData: any,
	shareholdersData: any,
	data: any}) =>
{
	const { t } = useTranslation(
		'report',
		{
			keyPrefix: ReportI18NKeys.AdjustedCostBaseDetails
		}
	);

	const shareholderData = props.data;

	const shareholderEquityClasses = !!shareholderData && !!shareholderData.equityClasses &&
		shareholderData.equityClasses.length > 0 ? shareholderData.equityClasses : [];

	return (
		<>
			{!!shareholderData && !!shareholderData.name &&
			<tr className={'shareholder-name'}>
				<td>
					{shareholderData.name}
				</td>
				{
					Array.from({
						length: props.columnsCount-1
					}, () =>
					{
						return ( <td></td>);
					}
					)
				}
			</tr>
			}
		 {!!shareholderEquityClasses && shareholderEquityClasses.length > 0 &&
		 shareholderEquityClasses.map((equityClass: any, index: number) =>
		 {
			 return (
				 <>
					 <tr className={'subtitle'}>
						 <td>
							 {!!equityClass && !!equityClass.id ? `${index+1}. `+ t('equityClass') : ''}
							 <div className={'equityClass'}>
								 {EngagementUtil.getEquityClassName(equityClass.id)}
							 </div>
						 </td>
						 {
							 Array.from({
								 length: props.columnsCount-1
							 }, () =>
							 {
								 return ( <td></td>);
							 }
							 )
						 }
					 </tr>
					 {props.reportFields.map((row: any) =>
					 {
						 switch (row.type)
						 {
						 case ReportFieldEnum.ColumnHeader:
							 return (
								 <>
									 <tr className={`${ReportUtil.getRowClassName(row)}`}>
									 {row.fields.filter((t: any) => !!t.isVisible).map((column: any, index: number) =>
									 {
											 if (column.name === ACBDetailsReportFields.shareholderCurrency ||
													column.name === ACBDetailsReportFields.taxPayerCurrency)
											 {
												 const shareholderCurrency = EngagementUtil
													 .getCurrencyCode(shareholderData.shareholderCurrencyId);
												 const taxPayerCurrency = EngagementUtil
													 .getCurrencyCode(shareholderData.taxPayerCurrencyId);
												 const fieldName = !!column.name ? t(column.name) + ' (' +
												((column.name === ACBDetailsReportFields.shareholderCurrency && shareholderCurrency) ||
													(column.name === ACBDetailsReportFields.taxPayerCurrency && taxPayerCurrency)
												) +
												')' : '';
												 return <td colSpan={2}
													 className={`${ReportUtil.getRowClassName(column) + ' ' +column.name}`}>
												 {fieldName}
											 </td>;
											 }
											 else
											 {
												 return <td rowSpan={2}
													 className={`${ReportUtil.getRowClassName(column) + ' ' +column.name}`}>
												 {`${!!column.name ? t(column.name)  : ''}`}
											 </td>;
											 }
									 }
									 )}
									 </tr>
									 <tr className={`${ReportUtil.getRowClassName(row)}`}>
										 {(props.selectedCurrency  === ACBDetailsReportCurrencyTypes.BothCurrencies ||
											props.selectedCurrency  === ACBDetailsReportCurrencyTypes.ShareholderCurrency) &&
										<>
											<td className={'amount'}>{t(ACBDetailsReportFields.amountInShareholderCurrency)}</td>
											<td className={'cumulativeBalance'}>
												{t(ACBDetailsReportFields.balanceInShareholderCurrency)}
											</td>
										</>
										 }
										 {(props.selectedCurrency  === ACBDetailsReportCurrencyTypes.BothCurrencies ||
											props.selectedCurrency === ACBDetailsReportCurrencyTypes.TaxpayerCurrency) &&
										<>
											<td className={'amount'}>{t(ACBDetailsReportFields.amountInTaxPayerCurrency)}</td>
											<td className={'cumulativeBalance'}>
												{t(ACBDetailsReportFields.balanceInTaxPayerCurrency)}
											</td>
										</>
										 }
									 </tr>
								 </>
							 );

						 case ReportFieldEnum.Transactions:

							 const equityClassTansactions: any[] = !!equityClass && equityClass.transactions ?
								 equityClass.transactions : [];
							 return (<>
								 {renderTransactions([...equityClassTansactions], row, props.lookupData)}
							 </>);


						 default:
							 return (
								 <td></td>
							 );
						 }
					 })}
					 {props.shareholdersData.length > 1 && props.index !== props.shareholdersData.length-1 ?
					 (renderRowSeperator(props.columnsCount)):
					 (shareholderEquityClasses.length-1 === index ? renderRowSeperator(props.columnsCount, true) :
					 	renderRowSeperator(props.columnsCount))
					 }
				 </>

			 );
		 })}
		</>
	);
};

export default EquityClassSectionsByShareholder;