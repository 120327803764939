import { useTranslation } from 'react-i18next';
import { ITableHeaderProps } from './ITableHeaderProps';

import './TableHeader.scss';
import { NotesEditor } from 'src/common/components/NotesEditor/NotesEditor';

export function TableHeader<T>(props: ITableHeaderProps<T>): JSX.Element
{
	const { t } = useTranslation(
		'common',
		{
			keyPrefix: 'columnarTableHeader'
		}
	);

	const onEdit = (data: T) =>
	{
		if (!props.isDisabled && !!props.onEdit)
		{
			props.onEdit(data);
		}
	};

	const onHeaderNoteSave = (updatedNotes: string, data: any) =>
	{
		!!props.onHeaderNoteSave && props.onHeaderNoteSave(updatedNotes, data);
	};

	const onRenderNotesEditor = (data: any): JSX.Element | undefined =>
	{
		if (!!props.displayHeaderNote)
		{
			return <NotesEditor
				notes={data.notes}
				onSave={(updatedNotes: string) => onHeaderNoteSave(updatedNotes, data)}
			/>;
		}
		return undefined;
	};

	return <>
		<thead className={'table-header'}>
			<tr>
				<th className={'description-column'}>
					{
						!!props.rowInformationHeaderText ?
							props.rowInformationHeaderText :
							''
					}
				</th>
				{
					!!props.isLoading ||
					!props.hasData ?
						<th className={'data-column first'}>
							{
								props.columnIndexKeyName
							}
						</th> :
						Object.values(props.data)[0]
							.map((d, index, allItems) =>
							{
								const recordData = d as any;
								const isHighlighted = !!props.highlightedOnField &&
									!!recordData && !!recordData.data && recordData.data?.[props.highlightedOnField];
								return <>
									<th className={`data-column ${index === 0 ? 'first' : ''} ${allItems.length <= 1 ? 'wide' : ''} 
										${isHighlighted ? 'highlight' : ''}`}>
										<div className={'content'}>
											<div>
												{
													`${props.columnIndexKeyName} ${index + 1}`
												}
											</div>
											<div
												className={'edit'}
												title={
													!!props.isDisabled ?
														`${t('editDisabled')}` :
														`${t('editHover')} ${props.columnIndexKeyName} ${index + 1}`
												}
											>
												{
													!!props.onEdit &&
													<span
														className={`Appkit4-icon icon-pencil-outline ${props.isDisabled ? 'disabled' : ''}`}
														onClick={() => onEdit(d.data)}
													/>
												}
												{!!props.displayHeaderNote && onRenderNotesEditor(d.data)}
											</div>
										</div>
									</th>
									{
										!!props.displayNotes &&
										<th className={'notes-column'}>
											{
												t('notesHeader')
											}
										</th>
									}
								</>;
							})
				}
			</tr>
		</thead>
	</>;
}