import React, { useEffect, useState } from 'react';
import {
	Button,
	Modal,
	TimePicker,
	Loading,
	TextArea,
	Input
} from '@appkit4/react-components';
import { useTranslation } from 'react-i18next';

import { UnsavedChangesModal } from 'src/core/components/Dashboard/unsavedModal/UnsavedChangesModal';
import useDisableBodyScroll from 'src/common/hooks/useDisableBodyScroll';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { IRelationship } from 'src/features/engagement/models/IRelationship';
import moment from 'moment';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import { Utils } from 'src/common/utils/utils';
import { UnexpectedErrorModal } from 'src/common/components/UnexpectedErrorModal/UnexpectedErrorModal';

import './LiquidationModal.scss';
import { RelationshipResolutionGrid } from 'src/common/components/RelationshipResolutionGrid/RelationshipResolutionGrid';
import { CalendarPickerWrapper } from 'src/common/components/CalendarPickerWrapper/CalendarPickerWrapper';
interface LiquidationFormData {
  faName: string;
  asOfDate: Date | undefined;
  asOfTime: Date | undefined;
  notes: string;
}

interface LiquidationFormError {
	selectedAffiliate: string,
	asOfDate: string
}

interface LiquidationModalProps {
	visible: boolean,
	selectedItem: IRelationship,
	onClose: (ids?: number[]) => void;
	maxDate: Date|undefined,
	defaultDate: Date|undefined
	engagementId: number;
}


export const LiquidationModal = (props: LiquidationModalProps) =>
{
	const initialFormError: LiquidationFormError = {
		selectedAffiliate: '',
		asOfDate: ''
	};

	const initialFormState: LiquidationFormData = {
		faName: '',
		asOfDate: undefined,
		asOfTime: undefined,
		notes: ''
	};

	useDisableBodyScroll(props.visible);
	const { t } = useTranslation('engagementSetup');
	const { t: errorMessage } = useTranslation('errors');
	const [step, setStep] = useState<number>(1);
	const [selectedForeignAffiliates, setSelectedForeignAffiliate] = useState<IRelationship>();
	const [liquidationFormData, setLiquidationFormData] = useState<LiquidationFormData>(initialFormState);
	const [liquidationFormError, setLiquidationFormError] = useState<LiquidationFormError|undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(false);
	const [showUnsavedModal, setShowUnsavedModal] = useState<boolean>(false);
	const [showUnexpectedErrorModal, setShowUnexpectedErrorModal] =
    useState<boolean>(false);
	const [unexpectedError, setUnExpectedError] = useState<string>('');
	const [disableContinue, setDisableContinue] = useState<boolean>(true);
	const [relationshipsForUpdate, setRelationshipsForUpdate] = useState<IRelationship[]>([]);
	const [disableLiquidate, setDisableLiquidate] = useState<boolean>(true);
	const [unexpectedErrorMessage, setUnexpectedErrorMessage] = useState<string | null>('');
	const genericErrorCodes = ['AFFILIATE_ALREADY_MERGEDORLIQUIDATED',
		'INVALID_LIQUIDATION_DATE_AFTER_LAST_TAXATION_YEAR',
		'INVALID_RELATIONSHIP_COUNT','INVALID_LIQUIDATION_RELATIONSHIP',
		'AFFILIATE_RELATIONSHIP_DUPLICATE_ROW', 'AFFILIATE_RELATIONSHIP_EQUITY_PERCENTAGE',
		'AFFILIATE_RELATIONSHIP_CIRCULAR_REFERENCE'];
	const [errorCode, setErrorCode] = useState<number|undefined>(undefined);

	useEffect(() =>
	{
		if (props.visible)
		{
			setStep(1);
			if (props.visible)
			{
				setLiquidationFormData({
					...initialFormState,
					'faName': props.selectedItem?.faName,
					'asOfDate': props?.defaultDate
				});
			}
			setLiquidationFormError(undefined);
		}
	}, [props.visible,props.defaultDate]);

	useEffect(() =>
	{
		setSelectedForeignAffiliate(props.selectedItem);
	}, [props.selectedItem]);

	useEffect(() =>
	{
		if (!checkIfFormValid())
		{
			setDisableContinue(true);
		}
		else
		{
			setDisableContinue(false);
		}
	},[liquidationFormData]);

	const checkIfFormValid = () =>
	{
		if (!!liquidationFormData.asOfDate)
		{
			return true;
		}
		else
		{
			return false;
		}
	};

	const onLiquidate = () =>
	{
		const haveErrors = validate(liquidationFormData);
		if (!haveErrors && !loading)
		{
			const selectedFAId = selectedForeignAffiliates?.faId;
			const effectiveDate  = liquidationFormData.asOfDate ? dateFormatter.estDateStringOverrideToUtc(
				dateFormatter.mergeDateTime(
					liquidationFormData.asOfDate,
					liquidationFormData.asOfTime??moment().startOf('day')
						.toDate()
				)
			):dateFormatter.estDateStringOverrideToUtc(liquidationFormData.asOfDate);

			setLoading(true);

			const requestBody = {
				engagementId: props.engagementId,
				liquidationAffiliateId: selectedFAId,
				effectiveDate: effectiveDate,
				notes: liquidationFormData.notes
			};
			engagementService.liquidate(requestBody)
				.then((res: any) =>
				{
					setLoading(false);
					const response = res.data;
					if (response.liquidationRelationshipResult?.length > 0)
					{
						const u = response.liquidationRelationshipResult.map((r: any,index: number) =>
						{
							return {
								'recordId': `${r.affiliateId}_${index}`,
								'relationshipId': r.affiliateId,
								'faId': r.affiliateId,
								'faName': r.affiliateName,
								'ownerAffiliateId': r.ownerAffiliateId,
								'ownerName': r.ownerName,
								'equityClassId': r.equityClassId,
								'equityPercentage': r.equity,
								'SEP': r.sep,
								'effectiveDate': new Date(response.effectiveDate),
								'editable': r.isUserInputRequired,
								'inEdit': r.isUserInputRequired
							};
						});
						setRelationshipsForUpdate(u);
						setStep(2);
					}
				})
				.catch((err: any) =>
				{
					setLoading(false);
					handleApiErrorResponse(err);
				});
		}
	};

	const onCancel = () =>
	{
		if (hasChanges())
		{
			setShowUnsavedModal(true);
		}
		else
		{
			setSelectedForeignAffiliate(props.selectedItem);
			props.onClose();
		}
	};

	const hasChanges = () =>
	{
		if (
			!liquidationFormData.asOfDate &&
      		!liquidationFormData.asOfTime &&
      		!liquidationFormData.notes
		)
		{
			return false;
		}
		else
		{
			return true;
		}
	};

	const closeUnsavedModal = (str: string) =>
	{
		if (str === 'stay')
		{
			setShowUnsavedModal(false);
		}
		else if (str === 'leave')
		{
			setShowUnsavedModal(false);
			setSelectedForeignAffiliate(props.selectedItem);
			props.onClose();
		}
	};

	const onValueChange = (field: string, value: any) =>
	{
		const updatedFormData = {
			...liquidationFormData,
			[field]: value
		};
		setLiquidationFormData(updatedFormData);

		validate(updatedFormData);
	};

	const validate = (formData: LiquidationFormData): boolean =>
	{
		const error = {
			...initialFormError
		};
		if (!formData.asOfDate)
		{
			error.asOfDate = t('liquidationModal.asOfDateRequired');
		}
		setLiquidationFormError(error);
		return Utils.hasError(error);
	};

	const closeUnexpectedErrorModal = () =>
	{
		setUnExpectedError('');
		setShowUnexpectedErrorModal(false);
	};

	const handleApiErrorResponse = (err: any) =>
	{
		setUnexpectedErrorMessage('');
		if (err.response)
		{
			setErrorCode(err.response.status);
			if (err.response.status >= 500)
			{
				setUnExpectedError(err.response.data.Errors[0].Message);
				setShowUnexpectedErrorModal(true);
			}
			else if (err.response.status >= 400)
			{
				const errors = err.response.data.Errors;
				const liquidationError = {
					...initialFormError
				};
				if (errors)
				{
					errors.forEach((error: any) =>
					{
						if (genericErrorCodes.indexOf(error.ErrorCode) > -1)
						{
							setUnexpectedErrorMessage(errorMessage(`code.${error.ErrorCode}`));
							setShowUnexpectedErrorModal(true);
						}
						if (error.Field === 'LiquidatedEngagementAffiliate')
						{
							liquidationError.selectedAffiliate = errorMessage(
								`code.${error.ErrorCode}`
							);
						}
						if (error.Field === 'EffectiveDate')
						{
							liquidationError.asOfDate = errorMessage(
								`code.${error.ErrorCode}`
							);
						}
					});
					setLiquidationFormError(liquidationError);
				}
			}
		}
	};

	const saveLiquidate = () =>
	{
		setLoading(true);
		const liquidationRelationshipResult = relationshipsForUpdate.map((r: IRelationship) =>
		{
			return {
			  'relationshipId': r.relationshipId,
			  'affiliateId': r.faId,
			  'affiliateName': r.faName,
			  'ownerAffiliateId': r.ownerAffiliateId,
			  'ownerName': r.ownerName,
			  'equityClassId': r.equityClassId,
			  'equity': r.equityPercentage,
			  'sep': r.SEP,
			  'notes': r.notes,
			  'isUserInputRequired': r.editable
			};
		});

		const payload = {
			engagementId: props.engagementId,
			liquidationAffiliateId: selectedForeignAffiliates?.faId,
			effectiveDate: liquidationFormData.asOfDate ? dateFormatter.estDateStringOverrideToUtc(
				dateFormatter.mergeDateTime(
					liquidationFormData.asOfDate,
					liquidationFormData.asOfTime??moment().startOf('day')
						.toDate()
				)
			):dateFormatter.estDateStringOverrideToUtc(liquidationFormData.asOfDate),
			notes: liquidationFormData.notes,
			liquidationRelationshipResult: liquidationRelationshipResult
		};

		engagementService.liquidate(payload)
			.then((res: any) =>
			{
				setLoading(false);
				const response = res.data;
				if (response['newRelationshipIds']?.length > 0)
				{
					setSelectedForeignAffiliate(props.selectedItem);
					props.onClose(response['newRelationshipIds']);
				}
				else
				{
					props.onClose(undefined);
				}
			})
			.catch((err: any) =>
			{
				setLoading(false);
				handleApiErrorResponse(err);
			});
	};

	if (props?.visible === false)
	{
		return null;
	}
	return (
		<>
			<Modal
				className={`liquidation-modal ${step===1? 'step1':'step2'} `}
				visible={props.visible}
				initialFocus={false}
				closeOnPressEscape={false}
				maskCloseable={false}
				title={t('liquidationModal.title') || ''}
				onCancel={onCancel}
				footer={
					<div className={'footer-wrapper'}>
						<div className='left'>
							{
								step === 2 &&
								<Button
									kind={'secondary'}
									onClick={() =>
									{
										setStep(1);
									}}
								>
									{
										t('liquidationModal.back')
									}
								</Button>
							}
						</div>
						<div className='right'>
							<div>
								{step===1?
									<Button kind="primary"
										disabled={loading || disableContinue}
										onClick={onLiquidate}>
										{t('liquidationModal.continue')}
									</Button>:
									<Button kind="primary" onClick={saveLiquidate} disabled={loading || disableLiquidate}>
										{t('liquidationModal.save')}
									</Button>}</div>
							<Button kind="secondary" onClick={onCancel}>
								{t('liquidationModal.cancel')}
							</Button>
						</div>
					</div>
				}
			>
				<div className={`liquidation-modal-body  ${step===1? 'step1': 'step2'} `}>
					<div className="title">
						{`${t('liquidationModal.step')} ${step}/2`}
					</div>

					{step===1&&
					<div className={`liquidation-form-body  ${step===1? 'step1': 'step2'}`}>
						<div>
							<Input
								title={t('liquidationModal.faName') || ''}
								required={true}
								value={liquidationFormData.faName}
								disabled={true}
							></Input>
						</div>

						<div>
							<CalendarPickerWrapper
								className="calendar-picker"
								format={'MMM DD, YYYY'}
								editable={false}
								disabled={false}
								fieldWidth={'100%'}
								value={liquidationFormData.asOfDate}
								useCustomValidation={true}
								error={liquidationFormError!==undefined&&liquidationFormError.asOfDate!==''}
								customErrorNode={
									liquidationFormError?.asOfDate && (
										<div className="custom-cell-error">
											{liquidationFormError.asOfDate}
										</div>
									)
								}
								onValueChange={(value: string) =>
									!!value && onValueChange('asOfDate', new Date(value))
								}
								fieldTitle={t('liquidationModal.asOfDate') || ''}
								required={true}
								minDate={new Date(props.defaultDate||'')}
								maxDate={props.maxDate}
								datePanelHorizontalAlign="right"
							/>
						</div>
						<TimePicker
							hourTime={12}
							className="time-picker"
							value={liquidationFormData?.asOfTime}
							fieldWidth={'100%'}
							fieldTitle={t('liquidationModal.asOfTime') || ''}
							onChange={(value: Date) =>
								!!value && onValueChange('asOfTime', value)
							}
						/>
						<TextArea
							title={`${t('liquidationModal.notes')}`}
							maxLength={255}
							value={liquidationFormData?.notes}
							onChange={(inputVal: string) => onValueChange('notes', inputVal)}
						></TextArea>
						<div className="dlad-indication">{t('liquidationModal.dladIndicationContent')}</div>
					</div>}
					{
						step === 2 &&
						<div className={'step2'}>
							<div className={'info'}>
								<div className={'row'}>
									<div className={'detail'}>
										<div className={'title'}>
											{
												t('liquidationModal.asOfDate')
											}
										</div>
										<div className={'value'}>
											{
												dateFormatter.format(
													liquidationFormData.asOfDate,
													'MMM DD, YYYY'
												)
											}
										</div>
									</div>
									<div className={'detail'}>
										<div className={'title'}>
											{
												t('liquidationModal.asOfTime')
											}
										</div>
										<div className={'value'}>
											{
												dateFormatter.format(
													liquidationFormData.asOfTime,
													'hh:mm A'
												)
											}
										</div>
									</div>
								</div>
							</div>
							<RelationshipResolutionGrid
								relationships={relationshipsForUpdate}
								onCompleted={(completed: boolean) => setDisableLiquidate(!completed)}
							/>
						</div>
					}
					{loading && (
						<div className="loading-icon">
							<Loading
								loadingType="circular"
								indeterminate={true}
								compact={false}
							/>
						</div>
					)}
				</div>
			</Modal>
			<UnexpectedErrorModal
				visible={showUnexpectedErrorModal}
				onClose={closeUnexpectedErrorModal}
				message={unexpectedErrorMessage?unexpectedErrorMessage:''}
				error={unexpectedError}
				statusCode={errorCode}
			/>
			<UnsavedChangesModal
				visible={showUnsavedModal}
				onClose={closeUnsavedModal}
				title={t('liquidationModal.discardChangesTitle') || ''}
			/>
		</>
	);
};
