import { useEffect, useRef, useState, useContext } from 'react';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { RetainedEarningsHeaderTable } from 'src/common/components/RetainedEarningsGrid/components/RetainedEarningsHeaderTable';
import { RetainedEarningsGrid } from 'src/common/components/RetainedEarningsGrid/RetainedEarningsGrid';
import { GridSeperator } from 'src/common/components/GridSeperator/GridSeperator';
import { Utils } from 'src/common/utils/utils';
import { RetainedEarningsHelpers } from './RetainedEarningsHelpers';
import { SaveProgressBar } from 'src/common/components/SaveProgressBar/SaveProgressBar';
import { NotificationService } from 'src/core/services/notificationService';
import './RetainedEarnings.scss';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import { Button, Notification } from '@appkit4/react-components';
import { DeleteModal } from 'src/common/components/DeleteModal/DeleteModal';
import {
	ValuesByDescriptionYear,
	ValuesByYear,
	gridIds
} from './RetainedEarningsConstants';

import {
	RetainedEarningsRow,
	RetainedEarningsSubmissionToken,
	IRetainedEarningsLookupRecord,
	IRetainedEarningsRow,
	IUnreconciledDifferenceError,
	IRetainedEarningsSubmission,
	IRetainedEarningsYear,
	IRetainedEarningDto
} from 'src/common/types/interfaces/IRetainedEarnings';
import { useTranslation } from 'react-i18next';
import { RetainedEarningsFields, RetainedEarningsFieldNames, RetainedEarningsDifferenceTypes, RetainedEarningsRowBaseFieldNames}
	from 'src/common/types/enums/RetainedEarnings';
import AffiliateContext from 'src/common/components/SharedDataContext/AffiliateContext';

const initialError: IUnreconciledDifferenceError = {
	retainedEarningsError: false,
	netIncomeOrLossError: false
};

const RetainedEarnings = (props: {handleChange?: (change: boolean) => void}) =>
{
	const [isLoading, setIsLoading] = useState(true);
	const [isSaving, setIsSaving] = useState(false);
	const [isValidating, setIsValidating] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isNoFAHistory, setIsNoFAHistory] = useState(false);

	const [displayNotes, setDisplayNotes] = useState(false);

	const affiliateContext = useContext(AffiliateContext);

	const [allStartingData, setAllStartingData] = useState<IRetainedEarningsYear[]>([]);
	const [allRetainedEarningsData, setAllRetainedEarningsData] = useState<IRetainedEarningsYear[]>([]);
	const [affiliateStartTaxYearEndId, setAffiliateStartTaxYearEndId] = useState<number>(0);
	const [affiliateStartTaxYearEnd, setAffiliateStartTaxYearEnd] = useState<string>('');

	const [retainedEarningsData, setRetainedEarningsData] = useState<IRetainedEarningsRow[][]>([]);
	const [netIncomeOrLossData, setNetIncomeOrLossData] = useState<IRetainedEarningsRow[][]>([]);


	const [retainedEarningsDescriptionOptions, setRetainedEarningsDescriptionOptions] = useState<IRetainedEarningsLookupRecord[]>([]);
	const [netIncomeOrLossDescriptionOptions, setNetIncomeOrLossDescriptionOptions] = useState<IRetainedEarningsLookupRecord[]>([]);
	const [nonDynamicDescriptionOptions, setNonDynamicDescriptionOptions] = useState<{ [key: string]: IRetainedEarningsLookupRecord }>({
	});


	const [unreconciledDifferenceError, setUnreconciledDifferenceError] = useState<IUnreconciledDifferenceError>(initialError);
	const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
	const [selectedRowDeletion, setSelectedRowDeletion] = useState<IRetainedEarningsRow[]>([]);

	const [isDataUpdated, setIsDataUpdated] = useState(false);

	const { t } = useTranslation(
		'input',
		{
			keyPrefix: 'retainedEarnings'
		}
	);

	const tableRefs = Array.from(
		{
			length: Object.keys(gridIds).length
		},
		// eslint-disable-next-line react-hooks/rules-of-hooks
		() => useRef<HTMLDivElement>(null)
	);

	const createKey = (...keys: (string | number)[]): string =>
	{
		return keys
			.map((k) => `${k}`)
			.join('::');
	};

	const splitKey = (key: string): string[] =>
	{
		return key.split('::');
	};

	const getKey = (key: string, indexToRetrieve: number): string =>
	{
		if (!!key)
		{
			const keys = splitKey(key);

			if (!!keys && !!keys.length && indexToRetrieve in keys)
			{
				return keys[indexToRetrieve];
			}
		}

		return '';
	};

	const onRenderSaveButton = (): JSX.Element =>
	{
		return <Button
			kind={'primary'}
			disabled={
				isLoading ||
				isSaving ||
				isValidating ||
				!isDataUpdated
			}
			onClick={onSave}
		>
			{
				t('saveButtonText')
			}
		</Button>;
	};

	const triggerWarningNotification = async (): Promise<void> =>
	{
		const ele = (
			<Notification
			  message={t('validationWarning')}
			  status={'warning'}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};

	const triggerErrorNotification = async (message: string): Promise<void> =>
	{
		const ele = (
			<Notification
			  message={message}
			  status={'error'}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};

	const triggerSuccessNotification = async (): Promise<void> =>
	{
		const ele = (
			<Notification
			  message={t('successMessage')}
			  status={'success'}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};

	const triggerDeleteSuccessNotification = async (): Promise<void> =>
	{
		const ele = (
			<Notification
			  message={t('deleteSuccessMessage')}
			  status={'success'}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};

	const updateBalances = (array: any): any[] =>
	{
		const updatedArray = array.map((item: any) =>
		{
			const updatedItem = cloneDeep(item);
			updatedItem[RetainedEarningsFields.netIncomeOrLossSummary][RetainedEarningsFields.netIncomeOrLossAfterTax]
				[RetainedEarningsFieldNames.Amount] = updatedItem[RetainedEarningsFields.retainedEarningsSummary]
					[RetainedEarningsFields.netIncomeOrLossAfterTax][RetainedEarningsFieldNames.Amount];
			return updatedItem;
		});
		return updatedArray.reduce((acc: any , year: any, index: number) =>
		{
			  const previousEndingBalanceSummary = index === 0 ? year[RetainedEarningsFields.retainedEarningsSummary]
			  [RetainedEarningsFields.openingRetainedEarnings][RetainedEarningsFieldNames.Amount] :
			  acc[index - 1][RetainedEarningsFields.retainedEarningsSummary]
			  [RetainedEarningsFields.endingRetainedEarnings][RetainedEarningsFieldNames.Amount];

			  const totalRetainedEarningsSumPerYear = year[RetainedEarningsFields.retainedEarnings]
			  	.reduce((sum: any, item: any) => sum +
				(!!item[RetainedEarningsFieldNames.Amount] ? item[RetainedEarningsFieldNames.Amount] : 0), 0);
			  const totalNetIncomeOrLossSumPerYear = year[RetainedEarningsFields.netIncomeOrLoss]
			  	.reduce((sum: any, item: any) => sum +
				(!!item[RetainedEarningsFieldNames.Amount] ? item[RetainedEarningsFieldNames.Amount] : 0), 0);

			  const updatedYear = {
				...year,
				[RetainedEarningsFields.retainedEarningsSummary]: {
				  ...year[RetainedEarningsFields.retainedEarningsSummary],
				  [RetainedEarningsFields.openingRetainedEarnings]: {
						...year[RetainedEarningsFields.retainedEarningsSummary][RetainedEarningsFields.openingRetainedEarnings],
						[RetainedEarningsFieldNames.Amount]: previousEndingBalanceSummary
				  },
				  [RetainedEarningsFields.endingRetainedEarnings]: {
						...year[RetainedEarningsFields.retainedEarningsSummary][RetainedEarningsFields.endingRetainedEarnings],
						[RetainedEarningsFieldNames.Amount]: previousEndingBalanceSummary +
						year[RetainedEarningsFields.retainedEarningsSummary][RetainedEarningsFields.netIncomeOrLossAfterTax]
							[RetainedEarningsFieldNames.Amount] + totalRetainedEarningsSumPerYear
				  }
				},
				[RetainedEarningsFields.netIncomeOrLossSummary]: {
					...year[RetainedEarningsFields.netIncomeOrLossSummary],
					[RetainedEarningsFields.netIncomeOrLossBeforeTax]: {
					  ...year[RetainedEarningsFields.netIncomeOrLossSummary][RetainedEarningsFields.netIncomeOrLossBeforeTax],
					  [RetainedEarningsFieldNames.Amount]: year[RetainedEarningsFields.netIncomeOrLossSummary]
					  [RetainedEarningsFields.netIncomeOrLossAfterTax][RetainedEarningsFieldNames.Amount] +
					  totalNetIncomeOrLossSumPerYear
					}
			  }
			  };

			  return [...acc, updatedYear];
		}, [] );
	};

	const updateUnreconciledDifferences = (allData: any): any[] =>
	{
		const updatedAllData = allData.map((item: any) =>
		{
			const updatedItem = cloneDeep(item);

			const endingRetainedEarningsAmount = item[RetainedEarningsFields.retainedEarningsSummary]
				[RetainedEarningsFields.endingRetainedEarnings][RetainedEarningsFieldNames.Amount] || 0;
			const financialStatementsAmount = item[RetainedEarningsFields.retainedEarningsSummary]
				[RetainedEarningsFields.endingRetainedEarningsOnFinancialStatements][RetainedEarningsFieldNames.Amount] || 0;

			updatedItem[RetainedEarningsFields.retainedEarningsSummary][RetainedEarningsFields.unreconciledDifference]
				[RetainedEarningsFieldNames.Amount] = financialStatementsAmount - endingRetainedEarningsAmount;


			const netIncomeOrLossBeforeTaxAmount = item[RetainedEarningsFields.netIncomeOrLossSummary]
				[RetainedEarningsFields.netIncomeOrLossBeforeTax][RetainedEarningsFieldNames.Amount] || 0;
			const netIncomeOrLossFromTaxReturnAmount = item[RetainedEarningsFields.netIncomeOrLossSummary]
				[RetainedEarningsFields.netIncomeOrLossFromTaxReturn][RetainedEarningsFieldNames.Amount] || 0;

			updatedItem[RetainedEarningsFields.netIncomeOrLossSummary][RetainedEarningsFields.unreconciledDifference]
				[RetainedEarningsFieldNames.Amount] = netIncomeOrLossFromTaxReturnAmount - netIncomeOrLossBeforeTaxAmount;


			return updatedItem;
		});

		const checkUnreconciledDifference = (id: string): boolean =>
		{
			return updatedAllData.some((year: any ) => year[id][RetainedEarningsFields.unreconciledDifference]
				[RetainedEarningsFieldNames.Amount] !== 0);
		};

		setUnreconciledDifferenceError({
			retainedEarningsError: checkUnreconciledDifference(RetainedEarningsFields.retainedEarningsSummary),
			netIncomeOrLossError: checkUnreconciledDifference(RetainedEarningsFields.netIncomeOrLossSummary)
		});

		 return updatedAllData;
	};

	const stopSaveProgressBar = async (): Promise<void> =>
	{
		await Utils.timeout(500);
		setIsSaving(false);
	};

	// flatten the list of retained earnings for easier lookup by id
	const flattenRetainedEarningsData = (data: IRetainedEarningsYear[]): IRetainedEarningDto[] =>
	{
		const flattenedData: IRetainedEarningDto[] = [];

		for (let i = 0; i < data.length; i++)
		{
			const year = data[i];

			// flatten retained earnings for each year
			for (const retainedEarning of year[RetainedEarningsSubmissionToken.RetainedEarnings])
			{
				flattenedData.push({
					id: retainedEarning.id,
					rowIndex: retainedEarning.rowIndex,
					affiliateTaxYearEndId: year.affiliateTaxYearEndId,
					differenceTypeId: retainedEarning.differenceTypeId,
					amount: retainedEarning.amount,
					notes: retainedEarning.notes
				});
			}

			// only add fields with valid description ids that are not calculated fields
			if (!!year.retainedEarningsSummary)
			{
				// only check opening retained earnings for first tax year (last index in array)
				if (i === (data.length - 1) && !!year.retainedEarningsSummary.openingRetainedEarnings)
				{
					const record = year.retainedEarningsSummary.openingRetainedEarnings;
					const diffType = RetainedEarningsDifferenceTypes.RetainedEarningsReconciliationOpeningRetainedEarnings;

					flattenedData.push({
						id: record.id,
						rowIndex: record.rowIndex,
						affiliateTaxYearEndId: year.affiliateTaxYearEndId,
						differenceTypeId: nonDynamicDescriptionOptions[diffType].id,
						amount: record.amount,
						notes: record.notes
					});
				}

				if (!!year.retainedEarningsSummary.netIncomeOrLossAfterTax)
				{
					const record = year.retainedEarningsSummary.netIncomeOrLossAfterTax;
					const diffType = RetainedEarningsDifferenceTypes.RetainedEarningsReconciliationNetIncomeAfterTax;

					flattenedData.push({
						id: record.id,
						rowIndex: record.rowIndex,
						affiliateTaxYearEndId: year.affiliateTaxYearEndId,
						differenceTypeId: nonDynamicDescriptionOptions[diffType].id,
						amount: record.amount,
						notes: record.notes
					});
				}

				if (!!year.retainedEarningsSummary.endingRetainedEarningsOnFinancialStatements)
				{
					const record = year.retainedEarningsSummary.endingRetainedEarningsOnFinancialStatements;
					const diffType = RetainedEarningsDifferenceTypes.EndingRetainedEarningsOnFinancialStatement;

					flattenedData.push({
						id: record.id,
						rowIndex: record.rowIndex,
						affiliateTaxYearEndId: year.affiliateTaxYearEndId,
						differenceTypeId: nonDynamicDescriptionOptions[diffType].id,
						amount: record.amount,
						notes: record.notes
					});
				}
			}

			// flatten net income or loss for each year
			for (const netIncome of year[RetainedEarningsSubmissionToken.NetIncomeOrLoss])
			{
				flattenedData.push({
					id: netIncome.id,
					rowIndex: netIncome.rowIndex,
					affiliateTaxYearEndId: year.affiliateTaxYearEndId,
					differenceTypeId: netIncome.differenceTypeId,
					amount: netIncome.amount,
					notes: netIncome.notes
				});
			}

			// only add fields with valid description ids that are not calculated fields
			if (!!year.netIncomeOrLossSummary)
			{
				if (!!year.netIncomeOrLossSummary.netIncomeOrLossFromTaxReturn)
				{
					const record = year.netIncomeOrLossSummary.netIncomeOrLossFromTaxReturn;
					const diffType = RetainedEarningsDifferenceTypes.NetIncomeReconciliationNetIncomeLossFromTaxReturn;

					flattenedData.push({
						id: record.id,
						rowIndex: record.rowIndex,
						affiliateTaxYearEndId: year.affiliateTaxYearEndId,
						differenceTypeId: nonDynamicDescriptionOptions[diffType].id,
						amount: record.amount,
						notes: record.notes
					});
				}
			}
		}

		return flattenedData;
	};

	const getChangedRecords = (updatedData: IRetainedEarningsYear[]): IRetainedEarningDto[] =>
	{
		const changedRecords: IRetainedEarningDto[] = [];
		const flattenedUpdatedData = flattenRetainedEarningsData(updatedData);
		const startingRecordsById: { [key: string]: IRetainedEarningDto } = {
		};

		const flattenedStartingData = flattenRetainedEarningsData(allStartingData);

		// create a map of starting records by id for easy lookup when comparing to updated records
		for (const record of flattenedStartingData)
		{
			if (!startingRecordsById[record.id])
			{
				startingRecordsById[record.id] = record;
			}
		}

		// loop through updated records to compare to starting records
		for (const record of flattenedUpdatedData)
		{
			// if record is greater than 0, it means it is an existing record
			// compare to existing record to see if notes/amount/description has changed
			if (record.id > 0)
			{
				const startingRecord = startingRecordsById[record.id];

				if (!!startingRecord)
				{
					if (
						startingRecord.amount !== record.amount ||
						startingRecord.notes !== record.notes ||
						startingRecord.differenceTypeId !== record.differenceTypeId
					)
					{
						changedRecords.push(record);
					}
				}
			}
			// if record is less than 0, it means it is a new record
			// only add to list if it has a difference id/amount/notes defined
			else
			{
				// difference type must be selected in order to allow for save to proceed even if there is an amount or notes
				if (
					!!record.differenceTypeId &&
					(
						!!record.amount ||
						!!record.notes
					)
				)
				{
					// update id to 0 to indicate it is a new record for api request
					changedRecords.push({
						...record,
						id: 0
					});
				}
			}
		}

		return changedRecords;
	};

	const onSave = async (): Promise<void> =>
	{
		try
		{
			// only save records that have been changed or added new
			const updatedRecords: IRetainedEarningDto[] = getChangedRecords(allRetainedEarningsData);

			// check if there are changes to save
			if (!!updatedRecords && !!updatedRecords.length)
			{
				setIsSaving(true);

				const submissionData: IRetainedEarningsSubmission = {
					engagementId: affiliateContext?.engagementDetail.id!,
					affiliateId: affiliateContext?.affiliateDetail.affiliateId!,
					retainedEarnings: [...updatedRecords]
				};

				await engagementService.updateRetainedEarnings(submissionData);

				triggerSuccessNotification();

				await stopSaveProgressBar();

				resetAndLoadData();
			}
			else
			{
				triggerWarningNotification();
			}
		}
		catch (error)
		{
			triggerErrorNotification(
				t('saveError')
			);

			await stopSaveProgressBar();
		}
		finally
		{
			setIsValidating(false);
		}
	};

	const onAddRow = (gridId: string): void =>
	{
		const newRow: IRetainedEarningsRow[] = [];
		const rowIndex = Utils.generateTempId();
		const addNewRow = (
			startingData: IRetainedEarningsRow[][]
		): IRetainedEarningsRow[][] =>
		{
			const updatedRows = cloneDeep(startingData);

			for (const data of allRetainedEarningsData)
			{
				newRow.push(
					new RetainedEarningsRow({
						taxYearEnd: data.taxYearEnd,
						affiliateTaxYearEndId: data.affiliateTaxYearEndId,
						rowIndex: rowIndex,
						calculatingCurrencyId: data.calculatingCurrencyId
					})
				);
			}

			updatedRows.push(newRow);
			return updatedRows;
		};

		const addNewEarningPerYear = (gridId: string) =>
		{
			const updatedAllData = allRetainedEarningsData.map((item: any, index: number) =>
			{
				const updatedItem = cloneDeep(item);
				const {id,rowIndex, differenceTypeId, notes } = newRow[index];
				updatedItem[gridId].push({
					[RetainedEarningsRowBaseFieldNames.Id]: id,
					[RetainedEarningsRowBaseFieldNames.RowIndex]: rowIndex,
					[RetainedEarningsRowBaseFieldNames.DifferenceTypeId]: differenceTypeId,
					[RetainedEarningsRowBaseFieldNames.Amount]: 0,
					[RetainedEarningsRowBaseFieldNames.Notes]: notes
				});
				return updatedItem;
			});

			return updatedAllData;
		};

		if (gridId === gridIds.retainedEarnings)
		{
			setRetainedEarningsData([...addNewRow(retainedEarningsData)]);
		}
		else if (gridId === gridIds.netIncomeOrLoss)
		{
			setNetIncomeOrLossData([...addNewRow(netIncomeOrLossData)]);
		}

		setAllRetainedEarningsData([...addNewEarningPerYear(gridId)]);
	};

	const updateDescriptionFields = (
		startingData: IRetainedEarningsRow[][],
		rowIndex: number,
		value: number
	): IRetainedEarningsRow[][]  =>
	{
		const updatedRows = cloneDeep(startingData);
		const rowData = cloneDeep(updatedRows[rowIndex]);

		rowData.forEach((d) =>
		{
			d.differenceTypeId = value;
		});

		updatedRows[rowIndex] = [...rowData];

		return updatedRows;
	};

	// update description id in the original list of entities for change tracking/submission
	const updateAllDataDescription = (
		id: string,
		rowIndex: number,
		value: number
	) =>
	{
		const tempAllData = cloneDeep([...allRetainedEarningsData]);

		const gridId = id as RetainedEarningsSubmissionToken;

		for (let i = 0; i < tempAllData.length; i++)
		{
			const taxYear = tempAllData[i];
			taxYear[gridId][rowIndex].differenceTypeId = value;

			tempAllData[i] = taxYear;
		}

		setAllRetainedEarningsData(tempAllData);
	};

	const updateDescription = (
		id: string,
		rowIndex: number,
		value: number
	): void =>
	{
		if (id === gridIds.retainedEarnings)
		{
			setRetainedEarningsData([...updateDescriptionFields(retainedEarningsData, rowIndex, value)]);
			updateAllDataDescription(id, rowIndex, value);
		}
		else if (id === gridIds.netIncomeOrLoss)
		{
			setNetIncomeOrLossData([...updateDescriptionFields(netIncomeOrLossData, rowIndex, value)]);
			updateAllDataDescription(id, rowIndex, value);
		}
	};

	const updateValueFields = (
		startingData: IRetainedEarningsRow[][],
		fieldName: string,
		rowIndex: number,
		index: number,
		value: string
	): IRetainedEarningsRow[][] =>
	{
		const updatedRows = cloneDeep(startingData);
		const yearContent = cloneDeep(updatedRows[rowIndex][index]);

		if (fieldName === RetainedEarningsFieldNames.Amount)
		{
			yearContent[fieldName] = Utils.isValidNumber(value) ?
				+value :
				undefined;
		}
		else if (fieldName === RetainedEarningsFieldNames.Notes)
		{
			yearContent[fieldName] = !!value ? value : undefined;
		}

		updatedRows[rowIndex][index] = yearContent;

		return updatedRows;
	};

	const updateSummaryFields = (
		startingData: any,
		id: string,
		fieldName: string,
		index: number,
		value: string): any =>
	{
		const keys = splitKey(fieldName);

		const gridSummary = id + 'Summary';
		const updatedRows = cloneDeep(startingData);
		const yearContent = cloneDeep(updatedRows[index]);

		if (!!yearContent && yearContent[gridSummary])
		{
			if (!!yearContent[gridSummary][keys[1]])
			{
				if (keys[0] === RetainedEarningsFieldNames.Amount)
				{
					yearContent[gridSummary][keys[1]][keys[0]] = Utils.isValidNumber(value) ?
						+value :
						null;
				}
				else if (keys[0] === RetainedEarningsFieldNames.Notes)
				{
					yearContent[gridSummary][keys[1]][keys[0]] = !!value ? value : null;
				}
			}
			else
			{
				yearContent[gridSummary] = {
					...yearContent[gridSummary],
					[keys[1]]: {
						...yearContent[gridSummary][keys[1]],
						[keys[0]]: keys[0] === RetainedEarningsFieldNames.Amount ?
							( Utils.isValidNumber(value) ? 	+value : null) : (!!value ? value : null)
					}
				};
			}


			if (id === gridIds.retainedEarnings)
			{
				switch (keys[1])
				{
				case RetainedEarningsFields.netIncomeOrLossAfterTax :
					yearContent[RetainedEarningsFields.retainedEarningsSummary][keys[1]] = yearContent[gridSummary][keys[1]];
					break;
				}
			}

			updatedRows[index] = yearContent;
		}

		return updatedRows;
	};

	const updateValue = (
		id: string,
		fieldName: string,
		rowIndex: number,
		index: number,
		value: string
	): void =>
	{
		let updatedAllRetainedEarningsData = allRetainedEarningsData;

		var gridId = id as RetainedEarningsSubmissionToken;

		if (gridId === gridIds.retainedEarnings)
		{
			if (rowIndex >= 0)
			{
				setRetainedEarningsData([
					...updateValueFields(
						retainedEarningsData,
						fieldName,
						rowIndex,
						index,
						value
					)
				]);

				if (fieldName === RetainedEarningsFieldNames.Amount)
				{
					updatedAllRetainedEarningsData[index][gridId][rowIndex][RetainedEarningsFieldNames.Amount] =
					Utils.isValidNumber(value) ? +value : undefined;
				}
				else if (fieldName === RetainedEarningsFieldNames.Notes)
				{
					updatedAllRetainedEarningsData[index][gridId][rowIndex][RetainedEarningsFieldNames.Notes] =
					!!value ? value : undefined;
				}
			}
			else if (rowIndex < 0 && fieldName.includes('::'))
			{
				updatedAllRetainedEarningsData = [
					...updateSummaryFields(
						allRetainedEarningsData,
						id,
						fieldName,
						index,
						value
					)
				];
			}
		}
		else if (id === gridIds.netIncomeOrLoss)
		{
			if (rowIndex >= 0)
			{
				setNetIncomeOrLossData([
					...updateValueFields(
						netIncomeOrLossData,
						fieldName,
						rowIndex,
						index,
						value
					)
				]);

				if (fieldName === RetainedEarningsFieldNames.Amount)
				{
					updatedAllRetainedEarningsData[index][gridId][rowIndex][RetainedEarningsFieldNames.Amount] =
						Utils.isValidNumber(value) ? +value : undefined;
				}
				else if (fieldName === RetainedEarningsFieldNames.Notes)
				{
					updatedAllRetainedEarningsData[index][gridId][rowIndex][RetainedEarningsFieldNames.Notes] =
						!!value ? value : undefined;
				}
			}
			else if (rowIndex < 0 && fieldName.includes('::'))
			{
				updatedAllRetainedEarningsData = [
					...updateSummaryFields(
						allRetainedEarningsData,
						id,
						fieldName,
						index,
						value
					)
				];
			}
		}


		const updatedAllData = updateBalances(updatedAllRetainedEarningsData.reverse());

		const updatedDataWithUnreconciledDifferences = updateUnreconciledDifferences(updatedAllData);

		setAllRetainedEarningsData(updatedDataWithUnreconciledDifferences.reverse());
	};

	const debouncedUpdateValue = debounce(updateValue, 400);

	const triggerValueUpdate = (
		gridId: string,
		fieldName: string,
		rowIndex: number,
		index: number,
		value: string
	) =>
	{
		debouncedUpdateValue(gridId, fieldName, rowIndex, index, value);
	};

	const onDeleteRowWarning = (selectedRow: IRetainedEarningsRow[]): void =>
	{
		setSelectedRowDeletion(selectedRow);
		setDisplayDeleteModal(true);
	};

	const onDelete = async (): Promise<void> =>
	{
		try
		{
			const firstDeletionEntity = selectedRowDeletion[0];
			await engagementService
				.deleteRetainedEarning(
						affiliateContext?.engagementDetail.id!,
						affiliateContext?.affiliateDetail.affiliateId!,
						firstDeletionEntity.differenceTypeId,
						firstDeletionEntity.rowIndex!
				);

			setDisplayDeleteModal(false);
			setSelectedRowDeletion([]);

			triggerDeleteSuccessNotification();
			resetAndLoadData();
		}
		catch (error)
		{
			triggerErrorNotification(
				t('deleteError')
			);
		}
	};

	const resetAndLoadData = async (): Promise<void> =>
	{
		setIsLoading(true);
		setIsSaving(false);
		setIsSubmitted(false);
		setIsValidating(false);
		setIsDataUpdated(false);
		!!props.handleChange && props.handleChange(false);

		setAllStartingData([]);
		setAllRetainedEarningsData([]);
		setRetainedEarningsData([]);
		setNetIncomeOrLossData([]);

		await loadRetainedEarnings(
			affiliateContext?.engagementDetail.id!,
			affiliateContext?.affiliateDetail.affiliateId!);
	};

	const loadLookups = async (): Promise<void>  =>
	{
		try
		{
			const lookupResponse = await engagementService.getRetainedEarningsLookupData();

			if (!!lookupResponse.data && !!lookupResponse.data.result &&
				!!lookupResponse.data.result.length && lookupResponse.data.result.length > 0)
			{
				const allOptions: IRetainedEarningsLookupRecord[] = lookupResponse.data.result.sort();

				const retainedEarningsOptions: IRetainedEarningsLookupRecord[] = [];
				const netIncomeOrLossOptions: IRetainedEarningsLookupRecord[] = [];
				const otherOptions: { [key: string]: IRetainedEarningsLookupRecord } = {
				};

				for (const option of allOptions)
				{
					if (option.differenceType === RetainedEarningsDifferenceTypes.RetainedEarningsReconciliation)
					{
						retainedEarningsOptions.push(option);
					}
					else if (option.differenceType === RetainedEarningsDifferenceTypes.NetIncomeReconciliation)
					{
						netIncomeOrLossOptions.push(option);
					}
					else
					{
						if (!otherOptions[option.differenceType])
						{
							otherOptions[option.differenceType] = {
								...option
							};
						}
					}
				}

				setRetainedEarningsDescriptionOptions(retainedEarningsOptions);
				setNetIncomeOrLossDescriptionOptions(netIncomeOrLossOptions);
				setNonDynamicDescriptionOptions(otherOptions);
			}
			else
			{
				setRetainedEarningsDescriptionOptions([]);
				setNetIncomeOrLossDescriptionOptions([]);
				setNonDynamicDescriptionOptions({
				});
			}
		}
		catch (error)
		{
			setRetainedEarningsDescriptionOptions([]);
			setNetIncomeOrLossDescriptionOptions([]);
			setNonDynamicDescriptionOptions({
			});
		}
	};

	const getRecordsByRows = (
		gridId: string,
		allRecords: IRetainedEarningsYear[],
		valuesByDescriptionYear: ValuesByDescriptionYear
	): IRetainedEarningsRow[][] =>
	{
		const allRows: IRetainedEarningsRow[][] = [];

		let rowContinuationIndex = 0;

		if (!!valuesByDescriptionYear)
		{
			const descriptionKeys = Object.keys(valuesByDescriptionYear);

			// loop for every year to determine which array for a given year is longest and fill values for each
			for (const descriptionKey of descriptionKeys)
			{
				const longestArray = Utils.getKeyAndLengthOfLongestArrayInDictionary(
					valuesByDescriptionYear[descriptionKey]
				);

				// get array of longest and seperate from remainder of records.
				// this will be the basis on which the remainder of records are built
				// the longest array already has the maximum number of records, so need to fill remainder of records
				const longestYearRecords = [
					...valuesByDescriptionYear[descriptionKey][longestArray.key]
				];

				const yearRecordsExcludingLongest: ValuesByYear = {
				};

				const yearKeys = Object.keys(valuesByDescriptionYear[descriptionKey]);

				for (const yearKey of yearKeys)
				{
					if (yearKey !== longestArray.key)
					{
						yearRecordsExcludingLongest[yearKey] = valuesByDescriptionYear[descriptionKey][yearKey];
					}
				}

				// fill remainder of records for each year that does not have the full amount of records
				for (const yearKey of Object.keys(yearRecordsExcludingLongest))
				{
					// get affiliate year field for entity by year (assume it will always be found)
					const yearEntity = allRecords
						.find((r) => createKey(r.taxYearEnd, r.affiliateTaxYearEndId) === yearKey)!;

					if (yearRecordsExcludingLongest[yearKey].length < longestYearRecords.length)
					{
						const remainingCount = longestYearRecords.length - yearRecordsExcludingLongest[yearKey].length;

						for (let i = 0; i < remainingCount; i++)
						{
							yearRecordsExcludingLongest[yearKey]
								.push(
									new RetainedEarningsRow({
										differenceTypeId: +getKey(descriptionKey, 0),
										taxYearEnd: yearEntity.taxYearEnd,
										affiliateTaxYearEndId: yearEntity.affiliateTaxYearEndId,
										rowIndex: +getKey(descriptionKey, 1),
										calculatingCurrencyId: yearEntity.calculatingCurrencyId
									})
								);
						}
					}
				}

				// concat the longest and remaining back together for processing into row records
				const allRecordsByYear = {
					[longestArray.key]: longestYearRecords,
					...yearRecordsExcludingLongest
				};

				// add the filler records (while tracking position of rows for each new iteration of description)
				for (let i = 0; i < longestArray.length; i++)
				{
					for (const year of yearKeys)
					{
						if ((allRows.length - 1) < (i + rowContinuationIndex))
						{
							allRows.push([]);
						}

						allRows[(i + rowContinuationIndex)].push(allRecordsByYear[year][i]);
					}
				}

				rowContinuationIndex += longestArray.length;
			}
		}

		return allRows;
	};

	const loadRetainedEarnings = async (
		engagementId: number,
		affiliateId: number
	): Promise<void> =>
	{
		try
		{
			const response = await engagementService
				.getRetainedEarnings(engagementId, affiliateId);

			const records: any[] = !!response.data && !!response.data.result &&
			!!response.data.result.retainedEarningRecords && !!response.data.result.startTaxYearEndId ?
				response.data.result.retainedEarningRecords.sort(RetainedEarningsHelpers.sortFAYear) :
				[];

			if (!!records && !!records.length)
			{
				setAffiliateStartTaxYearEndId(response.data.result.startTaxYearEndId);
				setAffiliateStartTaxYearEnd(response.data.result.startTaxYearEnd);

				const retainedEarningsDescriptionIds: number[] = [];
				const netIncomeOrLossDescriptionIds: number[] = [];

				// get all the unique descriptions (differenceTypeId) across years for each type of amount
				const filterDescriptions = (
					records: IRetainedEarningsRow[],
					uniqueDescriptions: number[]
				): number[] =>
				{
					if (!!records && !!records.length)
					{
						return records
							.filter((d) => !uniqueDescriptions.some((ud) => ud === d.differenceTypeId))
							.map((d) => d.differenceTypeId);
					}

					return [];
				};

				for (const r of records)
				{
					retainedEarningsDescriptionIds.push(
						...filterDescriptions(
							r[RetainedEarningsSubmissionToken.RetainedEarnings],
							retainedEarningsDescriptionIds
						)
					);
					netIncomeOrLossDescriptionIds.push(
						...filterDescriptions(
							r[RetainedEarningsSubmissionToken.NetIncomeOrLoss],
							netIncomeOrLossDescriptionIds
						)
					);
				}

				const getValuesByDescriptionRowIndexYear = (
					valuesByDescriptionYearStart: ValuesByDescriptionYear,
					rows: IRetainedEarningsRow[],
					year: string,
					affiliateTaxYearEndId: number,
					calculatingCurrencyId: number
				): ValuesByDescriptionYear =>
				{
					const valuesByDescriptionYear: ValuesByDescriptionYear = {
						...valuesByDescriptionYearStart
					};

					if (!!rows && !!rows.length)
					{
						for (const row of rows)
						{
							const allYearsBase: { [year: string]: [] } = {
							};

							records.forEach((r) =>
							{
								allYearsBase[createKey(r.taxYearEnd, r.affiliateTaxYearEndId)] = [];
							});

							const indexKey = createKey(row.differenceTypeId, row.rowIndex!);

							if (!valuesByDescriptionYear[indexKey])
							{
								valuesByDescriptionYear[indexKey] = {
								};
							}

							const yearKey = createKey(year, affiliateTaxYearEndId);
							if (!valuesByDescriptionYear[indexKey][yearKey])
							{
								valuesByDescriptionYear[indexKey] = {
									...allYearsBase
								};
							}

							valuesByDescriptionYear[indexKey][yearKey]
								.push({
									...row,
									notes: !!row.notes ? row.notes : undefined,
									taxYearEnd: year,
									affiliateTaxYearEndId: affiliateTaxYearEndId,
									calculatingCurrencyId: calculatingCurrencyId
								});
						}
					}

					return valuesByDescriptionYear;
				};

				// build retained earnings and net income or loss records
				let retainedEarningValuesByDescriptionYear: ValuesByDescriptionYear = {
				};
				let netIncomeOrLossValuesByDescriptionYear: ValuesByDescriptionYear = {
				};

				// for every description there will be an entity (if none exists then empty)
				for (const record of records)
				{
					retainedEarningValuesByDescriptionYear = {
						...getValuesByDescriptionRowIndexYear(
							retainedEarningValuesByDescriptionYear,
							record[RetainedEarningsSubmissionToken.RetainedEarnings],
							record.taxYearEnd,
							record.affiliateTaxYearEndId,
							record.calculatingCurrencyId
						)
					};
					netIncomeOrLossValuesByDescriptionYear = {
						...getValuesByDescriptionRowIndexYear(
							netIncomeOrLossValuesByDescriptionYear,
							record[RetainedEarningsSubmissionToken.NetIncomeOrLoss],
							record.taxYearEnd,
							record.affiliateTaxYearEndId,
							record.calculatingCurrencyId
						)
					};
				}

				const retainedEarnings = getRecordsByRows(gridIds.retainedEarnings, records, retainedEarningValuesByDescriptionYear);
				const netIncomeOrLoss = getRecordsByRows(gridIds.netIncomeOrLoss, records, netIncomeOrLossValuesByDescriptionYear);

				const updateRecordsFromRows = (gridId: string, yearRecord: any, index: number , earnings: any) =>
				{
					const updatedItem = cloneDeep(yearRecord);

					updatedItem[gridId] = [];

					earnings.forEach((data: any) =>
					{
						updatedItem[gridId].push(data[index]);
					});
					return updatedItem;
				};

				const updatedRecords = records.map((item: any, index: number) =>
				{
					let updatedItem = updateRecordsFromRows(RetainedEarningsFields.retainedEarnings, item, index, retainedEarnings);
					updatedItem = updateRecordsFromRows(RetainedEarningsFields.netIncomeOrLoss, updatedItem, index, netIncomeOrLoss);

					return updatedItem;
				});


				setRetainedEarningsData([...retainedEarnings]);
				setNetIncomeOrLossData([...netIncomeOrLoss]);
				setAllRetainedEarningsData([...updatedRecords]);
				setAllStartingData(cloneDeep([...updatedRecords]));

				updateUnreconciledDifferences(updatedRecords);
			}
			else
			{
				setIsNoFAHistory(true);
			}
		}
		catch (error)
		{
		}
		finally
		{
			setIsLoading(false);
		}
	};

	const loadData = async (
		engagementId: number,
		affiliateId: number
	): Promise<void> =>
	{
		await loadLookups();

		await loadRetainedEarnings(
			engagementId,
			affiliateId
		);
	};

	// effect used to check if data has been updated
	useEffect(
		() =>
		{
			// only if there is data to compare against then run the compare, otherwise skip as it means records have not been loaded yet
			if (
				!!allRetainedEarningsData &&
				!!allRetainedEarningsData.length &&
				!!allStartingData &&
				!!allStartingData.length &&
				!!nonDynamicDescriptionOptions &&
				!!Object.keys(nonDynamicDescriptionOptions).length
			)
			{
				const changedRecords = getChangedRecords(allRetainedEarningsData);
				setIsDataUpdated(!!changedRecords && !!changedRecords.length);
				!!props.handleChange && props.handleChange(!!changedRecords && !!changedRecords.length);
			}
			else
			{
				setIsDataUpdated(false);
			}
		},
		[
			allRetainedEarningsData,
			allStartingData,
			nonDynamicDescriptionOptions
		]
	);

	useEffect(() =>
	{
		setIsLoading(true);

		if (
			!!affiliateContext&&
				!!affiliateContext?.engagementDetail.id&&
				!!affiliateContext?.affiliateDetail.affiliateId
		)
		{
			loadData(
					affiliateContext?.engagementDetail.id!,
					affiliateContext?.affiliateDetail.affiliateId!
			);
		}
	},[affiliateContext]);

	useEffect(
		() =>
		{
			const handleScroll = (index: number) => (event: any) =>
			{
				tableRefs
					.forEach((ref, i) =>
					{
						if (i !== index && ref.current)
						{
							ref.current.scrollLeft = event.target.scrollLeft;
						}
					});
			};

			tableRefs
				.forEach((ref, index) =>
				{
					if (!!ref.current)
					{
						ref.current.addEventListener('scroll', handleScroll(index));
					}
				});


			return () =>
			{
				tableRefs
					.forEach((ref, index) =>
					{
						if (!!ref.current)
						{
							ref.current.removeEventListener('scroll', handleScroll(index));
						}
					});
			};
		},
		[]
	);

	return (
		<>
			{
				!isNoFAHistory &&
				<div className={'retained-earnings'}
					style={{
						width: isLoading ? '100%' : 'inherit'
					}}>
					<div className={'action-buttons'}>
						{
							onRenderSaveButton()
						}
					</div>
					<div>
						<SaveProgressBar
							display={isSaving}
							message={t('saveInProgressMessage') || ''}
						/>
					</div>
					<div className={'grid-container'}>
						<div>
							<RetainedEarningsHeaderTable
								id={gridIds.faTaxationDetails}
								customRef={tableRefs[0]}
								data={allRetainedEarningsData}
								isLoading={isLoading}
								isDisabled={isValidating || isSaving}
								validate={isSubmitted}
								onToggleNotes={(isNotesDisplayed) =>
								{
									setDisplayNotes(isNotesDisplayed);
								}}
							/>
						</div>
						<div>
							<RetainedEarningsGrid
								id={gridIds.retainedEarnings}
								isLoading={isLoading}
								isDisabled={isValidating || isSaving}
								validate={isSubmitted}
								displayNotes={displayNotes}
								customRef={[tableRefs[1], tableRefs[2]]}
								isUnreconciledDifferenceErrorVisible={unreconciledDifferenceError.retainedEarningsError}
								rowData={retainedEarningsData}
								descriptionOptions={retainedEarningsDescriptionOptions}
								allRetainedEarningsData={allRetainedEarningsData}
								affiliateStartTaxYearEndId={affiliateStartTaxYearEndId}
								affiliateStartTaxYearEnd={affiliateStartTaxYearEnd}
								addRowButtonText={t('addRowButtonText')}
								onRowAdd={() =>
								{
									onAddRow(gridIds.retainedEarnings);
								}}
								onDescriptionUpdate={(rowIndex, value) =>
								{
									updateDescription(gridIds.retainedEarnings, rowIndex, value);
								}}
								onValueUpdate={(fieldName, rowIndex, index, value) =>
								{
									triggerValueUpdate(gridIds.retainedEarnings, fieldName, rowIndex, index, value);
								}}
								onDeleteRow={onDeleteRowWarning}
							/>
						</div>
						{
							!isLoading &&
							<GridSeperator />
						}
						<div>
							<RetainedEarningsGrid
								id={gridIds.netIncomeOrLoss}
								isLoading={isLoading}
								isDisabled={isValidating || isSaving}
								validate={isSubmitted}
								displayNotes={displayNotes}
								customRef={[tableRefs[3], tableRefs[4]]}
								isUnreconciledDifferenceErrorVisible={unreconciledDifferenceError.netIncomeOrLossError}
								rowData={netIncomeOrLossData}
								descriptionOptions={netIncomeOrLossDescriptionOptions}
								allRetainedEarningsData={allRetainedEarningsData}
								affiliateStartTaxYearEndId={affiliateStartTaxYearEndId}
								affiliateStartTaxYearEnd={affiliateStartTaxYearEnd}
								addRowButtonText={t('addRowButtonText')}
								onRowAdd={() =>
								{
									onAddRow(gridIds.netIncomeOrLoss);
								}}
								onDescriptionUpdate={(rowIndex, value) =>
								{
									updateDescription(gridIds.netIncomeOrLoss, rowIndex, value);
								}}
								onValueUpdate={(fieldName, rowIndex, index, value) =>
								{
									triggerValueUpdate(gridIds.netIncomeOrLoss, fieldName, rowIndex, index, value);
								}}
								onDeleteRow={onDeleteRowWarning}
							/>
						</div>
					</div>
					<div className={'action-buttons'}>
						{
							onRenderSaveButton()
						}
					</div>
				</div>
			}
			{
				!!isNoFAHistory &&
			<div>
				{
					t('noFAHistory')
				}
			</div>
			}
			<DeleteModal
				visible={displayDeleteModal}
				title={t('deleteModalTitle')}
				deleteMessage={t('deleteMessage')}
				setVisible={setDisplayDeleteModal}
				onDelete={onDelete}
			/>
		</>
	);
};

export default RetainedEarnings;