import { ICalendarFormControlProps } from './ICalendarFormControlProps';
import { InputError } from '../../ModalControls/InputError/InputError';
import { CalendarPickerWrapper } from '../../CalendarPickerWrapper/CalendarPickerWrapper';

import './CalendarFormControl.scss';

export function CalendarFormControl(props: ICalendarFormControlProps): JSX.Element
{
	const onChange = (value: Date|undefined): void =>
	{
		if (!!props.onChange)
		{
			if (value===undefined)
			{
				props.onChange(props.field, value);
			}
			else
			{
				props.onChange(props.field, new Date(value));
			}
		}
	};

	return <>
		<div className={'calendar-form-control'}>
			<CalendarPickerWrapper
				useCustomValidation
				editable={props.isEditable}
				required={!!props.isRequired}
				disabled={!!props.isDisabled}
				readonly={!!props.isReadonly}
				fieldTitle={props.title}
				format={
					!!props.format ?
						props.format :
						'MMM DD, YYYY'
				}
				showClearIcon={!!props.showClearIcon}
				value={props.value}
				minDate={
					!!props.minDate ?
						props.minDate :
						new Date(1972, 0, 1)
				}
				maxDate={
					!!props.maxDate ?
						props.maxDate :
						new Date(2050, 11, 31)
				}
				datePanelHorizontalAlign={'right'}
				error={!!props.isError}
				customErrorNode={<></>}
				onChange={onChange}
			/>
			<InputError
				isError={!!props.isError}
				message={props.errorMessage}
				onRenderMessage={props.onRenderErrorMessage}
			/>
		</div>
	</>;
}