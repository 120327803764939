import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { IEarningsSummary } from 'src/features/engagement/models/IEarningsSummary';
import EarningsSummaryReportFields  from './../Settings/EarningsSummaryReportFields';
import { EarningsSummaryFields } from 'src/common/types/enums/EarningsSummaryFields';
import { IReportField } from 'src/common/types/interfaces/IReportField';
import ReportHeader from '../ReportCells/ReportHeader';
import { ReportI18NKeys } from 'src/common/types/enums/ReportI18NKeys';
import ReportRow from '../ReportCells/ReportRow';
import { Loading, Switch } from '@appkit4/react-components';
import './EarningsReport.scss';
import { Utils } from 'src/common/utils/utils';

const EarningsSummaryReport = (props: {refershReport: boolean}) =>
{
	const {engagementId, affiliateId } = useParams();

	const [reportData, setReportData] = useState<any[]>([]);
	const [totalAmounts, setTotalAmounts] = useState<any>();
	const [isLoading, setIsLoading] = useState(false);
	const [displayNotes, setDisplayNotes] = useState(false);

	const report: IEarningsSummary = EarningsSummaryReportFields;

	const { t } = useTranslation(
		'report',
		{
			keyPrefix: ReportI18NKeys.EarningsSummary
		}
	);

	const calculateRowTotal = (data: { [key: string]: any }[]): Record<string, { amount: number }> =>
	{
		const earningsFieldNames: string[] = [];
		for (const key of Object.keys(EarningsSummaryFields))
		{
			earningsFieldNames.push(EarningsSummaryFields[key as keyof typeof EarningsSummaryFields]);
		}
		const earningsTotal: Record<string, { amount: number }> = {
		};
		earningsFieldNames.forEach((earning: any) =>
		{
			const amounts: number[] = [];
			data.forEach((item: any) =>
			{
				if (item[earning] && item[earning].hasOwnProperty('amount'))
				{
					amounts.push(Number(item[earning].amount) || 0);
				}
			});
			earningsTotal[earning] = {
				'amount': Utils.add(...amounts)
			};
		});
		return earningsTotal;
	};

	const loadEarningsSummaryReportData = () =>
	{
		if ( engagementId && affiliateId )
		{
			setIsLoading(true);
			engagementService.loadEarningsSummaryReport(Number(engagementId), Number(affiliateId)).then((res: any) =>
			{
				setIsLoading(false);
				setReportData(res.data.result);

				const rowTotal = calculateRowTotal(res.data.result);
				setTotalAmounts(rowTotal);
			})
				.catch((error: any) =>
				{
					setIsLoading(false);
				});
		}
	};

	useEffect(() =>
	{
		loadEarningsSummaryReportData();
	},[engagementId, affiliateId]);

	useEffect(() =>
	{
		if (!!props.refershReport)
		{
			loadEarningsSummaryReportData();
		}
	}, [props.refershReport]);

	const onNotesToggle = (value: boolean) =>
	{
		setDisplayNotes(!!value);
	};


	return <div className='earnings-report'>

		{EarningsSummaryReportFields && <div className='table-container'>
			{isLoading && (
				<div className="loading-icon">
					<Loading
						loadingType="circular"
						indeterminate={true}
						compact={false}
					/>
				</div>
			)}
			{reportData &&
			<table>
				<thead>
					<tr>
						<th>
							<div className={'title-area'}>
								<div className={'title'}>
									<span>
										{
											t('header-title')
										}
									</span>
								</div>
								<div className={'toggle'}>
									<Switch
										className={'switch-label'}
										checked={displayNotes}
										disabled={false}
										onChange={onNotesToggle}
									>
										{
											t('header-toggleNotesLabel')
										}
									</Switch>
								</div>
							</div>
						</th>
						{
							Array.from({
								length: reportData.length
							}, (_, index) =>
							{
								return (
									<th></th>
								);
							}
							)
						}
					</tr>
				</thead>
				<tbody>
					{report.header.map((field: any) =>
						<ReportHeader
							reportData={reportData}
							field={field}
							i18nKey={ReportI18NKeys.EarningsSummary}
							totalAmounts={totalAmounts}/>
					  )}
					{report.summary.map((field: IReportField) =>
						<ReportRow
							reportData={reportData}
							field={field}
							i18nKey={ReportI18NKeys.EarningsSummary}
							displayNotes={displayNotes}
							totalAmounts={totalAmounts}/>
					  )}
				</tbody>
			</table>
			}
		</div>}
	</div>;
};

export default EarningsSummaryReport;