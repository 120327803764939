import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'src/store/store';
import EngagementForm from 'src/features/engagement/components/EngagementSetup/EngagementDetails/EngagementForm/EngagementForm';
import {IEngagementDetail } from 'src/features/engagement/models/IEngagementDetail';
import { IForeignAffiliate } from 'src/features/engagement//models/IForeignAffiliate';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import { Loading, Button} from '@appkit4/react-components';
import { WarningModal } from 'src/common/components/WarningModal/WarningModal';
import { NotificationService } from 'src/core/services/notificationService';
import { Utils } from 'src/common/utils/utils';
import './EngagementOverview.scss';
import { IEngagementOverview } from 'src/features/engagement/models/IEngagementOverview';

const initEngagementDetail: IEngagementDetail = {
	id: 0,
	clientId: 0,
	name: '',
	taxPayerId: 0,
	status: '',
	lastCalculationTaxationYearEnd: undefined,
	deliveryDate: undefined
};

const defaultFormError = {
	clientName: null,
	engagementName: null,
	taxPayer: null,
	label: null,
	lastCalculationTaxationYearEnd: null,
	deliveryDate: null
};

export const EngagementOverview = (props: {engagementOverview: IEngagementOverview, onEnagementChanged: (engagementId: number) => void}) =>
{
	const { t } = useTranslation(
		'homeDashboard',
		{
			keyPrefix: 'engagementOverview'
		}
	);
	const {engagementId } = useParams();
	const [loading, setLoading] = useState<boolean>(false);
	const [engagementOverview, setEngagementOverview] = useState<IEngagementOverview|undefined>(undefined);
	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const [isFasLoaded, setIsFasLoaded] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);

	const [engagement, setEngagement] = useState<IEngagementDetail>(initEngagementDetail);
	const [originalEngagementData, setOriginalEngagementData] = useState<IEngagementDetail>(initEngagementDetail);
	const [engagementFormError, setEngagementFormError] = useState<Record<string, any>>(defaultFormError);
	const [foreignAffiliates, setForeignAffiliates] = useState<IForeignAffiliate[]>([]);
	const currencies = useAppSelector((state) => state.appSettings.currencies);


	const handleEditToggle = () =>
	{
		setIsEditMode(!isEditMode);
	};

	const closeWarning = () =>
	{
		setShowModal(false);
	};

	const cancelWarning = () =>
	{
		setShowModal(false);
		const key = 'lastCalculationTaxationYearEnd';
		const updateEngagement = (engagement: any) => ({
			...engagement,
			[key]: originalEngagementData[key]
		});

		setEngagement(updateEngagement(engagement));
	};

	const compareObjects = (obj1: any, obj2: any): boolean =>
	{
		const keys = ['name', 'taxPayerId', 'lastCalculationTaxationYearEnd', 'deliveryDate'];

		for (const key of keys)
		{
			if ((key === keys[2] || key === keys[3]))
			{
				if (dateFormatter.dateToString(new Date(obj1[key])) !== dateFormatter.dateToString(new Date(obj2[key])))
				{
					return false;
				}
			}
			else if (obj1[key] !== obj2[key])
			{
				return false;
			}
		}
		return true;
	};

	const showWarningModal = (fas: IForeignAffiliate[], date: string): boolean =>
	{
		const filteredAnalysisEndDates = fas?.filter((fa: IForeignAffiliate) =>
			(!!fa['analysisEndDate'] && new Date(date) < new Date(fa['analysisEndDate'])));

		if (!!filteredAnalysisEndDates && filteredAnalysisEndDates.length > 0)
		{
			return true;
		}
		else
		{
			return false;
		}
	};

	const handleChange = (name: any, val: any, errorParamName?: string) =>
	{
		if (!!errorParamName)
		{
			if (name === 'taxPayerId')
			{
				setEngagementFormError({
					...engagementFormError,
					taxPayer: null
				});
			}
			else
			{
				setEngagementFormError({
					...engagementFormError,
					[errorParamName]: null
				});
			}
		}

		const updateEngagement = (engagement: any) => ({
			...engagement,
			[name]: val
		});

		setEngagement(updateEngagement(engagement));

		if (compareObjects(updateEngagement(engagement), originalEngagementData)&&Utils.areAllPropertiesPopulated(engagement))
		{
			setIsSaveDisabled(true);
		}
		else
		{
			setIsSaveDisabled(false);
		}

		const key = 'lastCalculationTaxationYearEnd';
		if (name === key && dateFormatter.dateToString(new Date(val)) !== dateFormatter.dateToString(originalEngagementData[key]))
		{
			const displayModal = showWarningModal(foreignAffiliates, val);
			setShowModal(displayModal);
		}
	};

	const onSave = () =>
	{
		const {id, name, taxPayerId, lastCalculationTaxationYearEnd, deliveryDate } = engagement;

		const payload: any = {
			id,
			name,
			taxPayerId,
			lastCalculationTaxationYearEnd,
			deliveryDate
		};

		engagementService
			.saveEngagementOverview(payload)
			.catch((err: Record<string, any>) =>
			{
				setLoading(false);

				const {response: {data: { Errors, Status }}} = err;
				const apiErrors = {
					clientName: null,
					engagementName: null,
					taxPayer: null,
					label: null,
					lastCalculationTaxationYearEnd: null,
					deliveryDate: null
				};

				if (Status === 400)
				{
					apiErrors.clientName =
            Errors.filter((x: any) => x['Field'] === 'client')?.[0]?.[
            	'Message'
            ] || null;
					apiErrors.engagementName =
            Errors.filter((x: any) => x['Field'] === 'name')?.[0]?.[
            	'Message'
            ] || null;
					apiErrors.taxPayer =
            Errors.filter((x: any) => x['Field'] === 'taxPayer')?.[0]?.[
            	'Message'
            ] || null;
					apiErrors.lastCalculationTaxationYearEnd =
            Errors.filter(
            	(x: any) => x['Field'] === 'lastCalculationTaxationYearEnd'
            )?.[0]?.['Message'] || null;
					apiErrors.deliveryDate =
            Errors.filter((x: any) => x['Field'] === 'deliveryDate')?.[0]?.[
            	'Message'
            ] || null;
				}
				setEngagementFormError({
					...apiErrors
				});
			})
			.then((response ) =>
			{
				if (!!response && response.status === 204)
				{
					NotificationService.success({
						message: t('saveSuccess')
					});
					setIsEditMode(false);
					setIsSaveDisabled(true);
					props.onEnagementChanged(Number(engagementId));
				}
			});
	};

	const loadFAs = (engagementId: number | undefined) =>
	{
		setLoading(true);
		if (engagementId)
		{
			engagementService
				.getForeignAffiliatesByEngagementId(engagementId)
				.then((res: any) =>
				{
					if (res.data.result)
					{
						const fas = res.data.result.map((fa: any) =>
						{
							const f: IForeignAffiliate = {
								affiliateId: fa.affiliateId,
								name: fa.name,
								currencyId: fa.currencyId,
								countryId: fa.countryId,
								taxationYearEnd: fa.taxationYearEnd,
								dateBecameFa: fa.dateBecameFa,
								analysisStartDate: fa.analysisStartDate,
								analysisEndDate: fa.analysisEndDate,
								inEditableMode: false,
								isNewItem: false,
								selected: false,
								showedWarning: false
							};
							return f;
						});
						setForeignAffiliates(fas);
					}
					setLoading(false);
					setIsFasLoaded(true);
				})
				.catch((error: any) =>
				{
					setLoading(false);
				});
		}
	};

	useEffect(() =>
	{
		if (props.engagementOverview)
		{
			setEngagementOverview(props.engagementOverview);
			setOriginalEngagementData(props.engagementOverview);
			setEngagement(props.engagementOverview);
		}
	}, [props.engagementOverview]);

	useEffect(() =>
	{
		if (isEditMode && !isFasLoaded)
		{
			loadFAs(Number(engagementId));
		}
	}, [isEditMode]);

	return (
		<>
			<div>
				{loading && (
					<div className="loading-icon">
						<Loading
							loadingType="circular"
							indeterminate={true}
							compact={false}
						/>
					</div>
				)}
				{
					!loading && (
						<>
							{!isEditMode &&
							<div className="engagement-overview">
								{!!engagementOverview &&
									<>
										<div className="detail-row">
											<div className="key">{`${t('clientName')}`}</div>
											<div className="value">{engagementOverview?.clientName}</div>
										</div>
										<div className="detail-row">
											<div className="key">{`${t('clientIPowerCode')}`}</div>
											<div className="value">{engagementOverview?.clientIPowerCode}</div>
										</div>
										<div className="detail-row">
											<div className="key">{`${t('engagementName')}`}</div>
											<div className="value">{engagementOverview?.name}</div>
										</div>
										<div className="detail-row">
											<div className="key">{`${t('taxpayerName')}`}</div>
											<div className="value">{engagementOverview?.taxPayerName}</div>
										</div>
										<div className="detail-row">
											<div className="key">{`${t('taxpayerCurrency')}`}</div>
											<div className="value">{currencies?.find((currency: {id: number}) =>
												currency.id ===  engagementOverview?.taxPayerCurrencyId)?.code}</div>
										</div>
										<div className="detail-row">
											<div className="key">{`${t('lastCalculationTaxationYearEnd')}`}</div>
											<div className="value">{dateFormatter.format(dateFormatter.utcToEst(
												engagementOverview?.lastCalculationTaxationYearEnd), 'MMM DD, YYYY')}</div>
										</div>
										<div className="detail-row">
											<div className="key">{`${t('deliveryDate')}`}</div>
											<div className="value">{dateFormatter.format(
												dateFormatter.utcToEst(engagementOverview?.deliveryDate), 'MMM DD, YYYY')}</div>
										</div>
									</>
								}
								<div className="separator"/>
								<div>
									<Button className='link-button' kind='text' onClick={handleEditToggle}>
										<span className="Appkit4-icon icon-pencil-outline"></span>{t('editButtonText')}
									</Button>
								</div>
							</div>}
							{isEditMode &&
							<>
								<div className="engagement-form">
									<EngagementForm
										engagementFormError={engagementFormError}
										engagementDetail={engagement}
										fromEngagementOverview={true}
										onChange={handleChange}
										onCancel={handleEditToggle}
										onSave={onSave}
										isSaveDisabled={isSaveDisabled} />
								</div>
								<div>
									<WarningModal
										visible={showModal}
										title={t('modalTitle') || ''}
										showWarningIcon={true}
										content={t('modalWarningMessage')}
										onClose={closeWarning}
										showCancelButton={true}
										onCancel={cancelWarning}/>
								</div>
							</>
							}
						</>
					)
				}
			</div>
		</>
	);
};