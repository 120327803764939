import { IForeignTaxableIncomeRowColumnsProps } from './IForeignTaxableIncomeRowColumnsProps';
import {
	DropdownControl,
	NumericInputWithNotesControl
} from '../../../GridControls';
import { Utils } from 'src/common/utils/utils';
import { useTranslation } from 'react-i18next';
import { ForeignTaxableIncomeFieldNames } from 'src/common/types/enums/ForeignTaxableIncomeTypes';
import Reg5907TaggleControl from 'src/common/components/GridControls/Reg5902TaggleControl/Reg5907TaggleControl';
import { IReg5907Adjustment } from 'src/common/types/interfaces/IForeignTaxableIncome';

export function ForeignTaxableIncomeRowColumns(props: IForeignTaxableIncomeRowColumnsProps): JSX.Element
{
	const { t } = useTranslation(
		'common',
		{
			keyPrefix: 'foreginTaxableIncomeRowColumns'
		}
	);

	const onDeleteWarning = () =>
	{
		props.onDeleteRow(props.rowData);
	};

	const handleReg5907AdjustmentChange = (value: IReg5907Adjustment|undefined, index: number) =>
	{
		props.onValueUpdate(ForeignTaxableIncomeFieldNames.Reg5907Adjustments, index, !!value?[value]:[]);
	};

	return <>
		{
			!!props.rowData &&
			<tr>
				<td className={'action-column'}>
					{!!props.rowData[0].rowIndex && props.rowData[0].rowIndex > 0 &&
					<span
						className={'Appkit4-icon icon-delete-outline delete-column'}
						onClick={onDeleteWarning}
					/>
					}
				</td>
				<td className={'first-column'}>
					<DropdownControl
						isDisabled={
							props.isLoading ||
							props.isDisabled
						}
						isSearchable={true}
						includeResetOption
						dropdownMatchWidth={false}
						options={props.descriptionOptions}
						placeholder={t('descriptionPlaceholder') || ''}
						value={`${!!props.rowData.length ? props.rowData[0].descriptionId : 0}`}
						isError={
							!!props.validate &&
							!!props.rowData.length &&
							!props.rowData[0].descriptionId &&
							!!props.rowData.some((rd) => !!Utils.isValidNumber(`${rd.amount}`) || !!rd.notes)
						}
						onChange={(value) =>
						{
							props.onDescriptionUpdate(!!value ? +value : 0);
						}}
					/>
				</td>
				{
					props.rowData
						.map((d, index) =>
						{
							return <>
								<td className={`boundary amount-cell amount-type ${!!props.displayNotes ? 'notes-included' : ''}`}>
									<NumericInputWithNotesControl
										isDisabled={
											props.isLoading ||
											props.isDisabled
										}
										displayNotes={!!props.displayNotes}
										notes={d.notes}
										value={d.amount}
										onValueChange={(value) =>
										{
											props.onValueUpdate(ForeignTaxableIncomeFieldNames.Amount, index, +value);
										}}
										onNotesSave={(updatedNotes) =>
										{
											props.onValueUpdate(ForeignTaxableIncomeFieldNames.Notes, index, updatedNotes);
										}}
									/>
								</td>
								<td className={`boundary amount-type ${!!props.displayNotes ? 'notes-included' : ''}`}>
									<Reg5907TaggleControl
										rowData={d}
										descriptionOptions={props.descriptionOptions}
										onChange={(reg5907: IReg5907Adjustment|undefined) =>
											handleReg5907AdjustmentChange(reg5907, index)}
									/>
								</td>
							</>;
						})
				}
			</tr>
		}
	</>;
}