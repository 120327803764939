import { Button, Modal } from '@appkit4/react-components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './UnsavedModal.css';

export const UnsavedModal = (props: {
  showDialog: boolean | (() => void);
  cancelNavigation: any;
  confirmNavigation: any;
}) =>
{
	const [visible, setVisible] = React.useState<boolean>(false);
	const { t } = useTranslation('common');

	useEffect(() =>
	{
		setVisible(!!props.showDialog);
	}, [props]);

	return (
		<>
			<Modal
				className="unsaved-modal"
				visible={visible}
				title={t('modal.unsaved-title') || ''}
				maskCloseable={false}
				header={<span className="Appkit4-icon icon-alert-fill"></span>}
				onCancel={() =>
				{
					setVisible(false);
					props.cancelNavigation();
				}}
				modalStyle={{
					width: '540px'
				}}
				footerStyle={{
					paddingTop: '8px',
					marginTop: '-8px',
					minHeight: '64px'
				}}
				icons={''}
				footer={
					<>
						<Button
							className="unsaved-cancel"
							onClick={() =>
							{
								setVisible(false);
								props.cancelNavigation();
							}}
							kind="secondary"
						>
							{t('modal.cancel')}
						</Button>
						<Button
							onClick={() =>
							{
								setVisible(false);
								props.confirmNavigation();
							}}
						>
              				{t('modal.proceed')}
						</Button>
					</>
				}
				bodyStyle={{
					minHeight: '92px'
				}}
			>
				<div className="unsaved-message">
					{t('modal.unsaved-message')}
				</div>
			</Modal>
		</>
	);
};
