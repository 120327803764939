import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnarGrid } from 'src/common/components/ColumnarGrid/ColumnarGrid';
import { ILineItem } from 'src/common/types/interfaces/ILineItem';
import { cloneDeep } from 'lodash';
import { DividendReceivedOther, IDividendReceivedOthers } from 'src/features/engagement/models/IDividendReceivedOthers';
import { inputRecordHelper } from 'src/common/utils/inputRecordHelper';
import { LineItemCategories } from 'src/common/types/enums/LineItemCategories';
import { TaxYearEndFilter } from 'src/common/components/TaxYearEndFilter/TaxYearEndFilter';
import { Utils } from 'src/common/utils/utils';
import { DividendsReceivedHelper } from '../DividendsRecivedHelper';
import { ForeignAffiliate, IForeignAffiliate } from 'src/features/engagement/models/IForeignAffiliate';
import { EngagementDetail, IEngagementDetail } from 'src/features/engagement/models/IEngagementDetail';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { NotificationService } from 'src/core/services/notificationService';
import { DividendsReceivedForm } from '../DividendsReceivedForm/DividendsReceivedForm';
import { DividendReceivedFromOthersFields } from 'src/common/types/enums/DividendsReceivedFromOtherFields';
import { IDividendsFromOthersProps } from './IDividendsFromOthersProps';
import AffiliateContext from 'src/common/components/SharedDataContext/AffiliateContext';

export function DividendsFromOthers(props: IDividendsFromOthersProps): JSX.Element
{
	const { t } = useTranslation(
		'input',
		{
			keyPrefix: 'dividendsReceivedByOthers'
		}
	);

	const { t: fieldsTranslationService } = useTranslation(
		'input',
		{
			keyPrefix: 'dividendsReceivedFromOthersFieldNames'
		}
	);

	const affiliateContext = useContext(AffiliateContext);
	const [lineConfiguration, setLineConfiguration] = useState<ILineItem[]>([]);
	const [toDate, setToDate] = useState<Date | undefined>(undefined);
	const [fromDate, setFromDate] = useState<Date | undefined>(undefined);
	const [rowInformation, setRowInformation] = useState(DividendsReceivedHelper.getRowInformationForOthers(fieldsTranslationService));
	const [dividendRecords, setDividendRecords] = useState(
		inputRecordHelper
			.getStartRowKeysAsDictionary<IDividendReceivedOthers>(
				DividendsReceivedHelper.getRowInformationForOthers(fieldsTranslationService)));
	const [isLoading, setIsLoading] = useState(true);

	const [selectedRecord, setSelectedRecord] = useState<IDividendReceivedOthers | undefined>(undefined);
	const [displayModal, setDisplayModal] = useState(false);
	const [isAddRecord, setIsAddRecord] = useState(false);
	const [engagementInfo, setEngagementInfo] = useState<IEngagementDetail | undefined>(undefined);
	const [foreignAffiliate, setForeignAffiliate] = useState<IForeignAffiliate | undefined>(undefined);

	const onOpenPanel = (isAdd: boolean, entity: IDividendReceivedOthers): void =>
	{
		setIsAddRecord(!!isAdd);
		setSelectedRecord(entity);
		setDisplayModal(true);
	};

	const loadDividendReceivedData = async (
		{
			engagementId,
			affiliateId,
			fromDate,
			toDate
		}:
		{
			engagementId: number;
			affiliateId: number;
			fromDate?: Date;
			toDate?: Date;
		}
	): Promise<void> =>
	{
		setIsLoading(false);
		try
		{
			const response = await engagementService
				.getDividendsReceivedFromOthers({
					engagementId,
					affiliateId,
					fromDate,
					toDate
				});

			if (
				!!response &&
				!!response.data &&
				!!response.data.result
			)
			{
				const allRecords: IDividendReceivedOthers[] = response.data.result
					.map(DividendReceivedOther.populateFields);

				const gridRecords = {
					...cloneDeep(
						inputRecordHelper
							.getRowData(
								allRecords,
								rowInformation
							)
					)
				};

				setDividendRecords(gridRecords);
			}
		}
		catch (error)
		{
			triggerErrorNotification(
				t('dividendInformationError')
			);
		}
		finally
		{
			await Utils.timeout(1000);
			setIsLoading(false);
		}
	};

	const onRenderFilters = (): JSX.Element =>
	{
		return <>
			<div>
				<TaxYearEndFilter
					onlyRelevantResults
					isDisabled={
						isLoading ||
						(
							!Object.values(dividendRecords).some((d) => !!d.length) &&
							fromDate === undefined &&
							toDate === undefined
						)
					}
					faHistory={props.faHistory}
					onFilter={onFilter}
				/>
			</div>
		</>;
	};

	const onFilter = (
		{
			fromDate,
			toDate
		}:
		{
			fromDate?: Date;
			toDate?: Date;
		}
	): void =>
	{
		setIsLoading(true);

		setFromDate(fromDate);
		setToDate(toDate);

		loadDividendReceivedData({
			engagementId: affiliateContext?.engagementDetail.id!,
			affiliateId: affiliateContext?.affiliateDetail.affiliateId!,
			fromDate,
			toDate
		});
	};


	const triggerErrorNotification = (message: string): void =>
	{
		NotificationService.error({
			message
		});
	};

	const loadEngagementInformation = async (
		engagementId: number
	): Promise<void> =>
	{
		try
		{
			const result = await engagementService
				.getEngagementDetailById(
					engagementId
				);

			if (
				!!result &&
				!!result.data &&
				!!result.data.result
			)
			{
				setEngagementInfo(
					EngagementDetail.parseFields(result.data.result)
				);
			}
		}
		catch (error)
		{
			triggerErrorNotification(
				t('engagementInformationError')
			);
		}
	};

	const loadForeignAffiliateInformation = async (
		engagementId: number,
		affiliateId: number
	): Promise<void> =>
	{
		try
		{
			const result = await engagementService
				.getForeignAffiliate(
					engagementId,
					affiliateId
				);

			if (
				!!result &&
				!!result.data &&
				!!result.data.result
			)
			{
				const fa = ForeignAffiliate.parseFields(result.data.result);
				setForeignAffiliate(
					{
						...fa,
						'name': result.data.result.name
					}
				);
			}
		}
		catch (error)
		{
			triggerErrorNotification(
				t('foreignAffiliateInformationError')
			);
		}
	};

	const loadRowInformation = async (
		engagementId: number,
		affiliateId: number
	): Promise<void> =>
	{
		try
		{
			const lines = await inputRecordHelper
				.initializeLineItems(
					LineItemCategories.DividendsReceivedFromOthers,
					engagementId,
					affiliateId
				);

			const rowInfo = DividendsReceivedHelper.getRowInformationForOthers(
				fieldsTranslationService,
				lines
			);

			   const updatedRowInfo =	rowInfo.map((info: any) =>
			{
				if (info.key===DividendReceivedFromOthersFields.percentageOwned)
				{
					return {
						...info,
						'name': info.name+' '+ affiliateContext?.affiliateDetail.name
					};
				}
				else
				{
					return info;
				}
			});

			setRowInformation(
				updatedRowInfo
			);

			setLineConfiguration(lines);
		}
		catch (error)
		{
			// todo: error handling
		}
	};


	useEffect(
		() =>
		{
			if (
				!!affiliateContext&&
				!!affiliateContext?.engagementDetail.id&&
				!!affiliateContext?.affiliateDetail.affiliateId

			)
			{
				loadEngagementInformation(
					affiliateContext?.engagementDetail.id!
				);
				loadForeignAffiliateInformation(
					affiliateContext?.engagementDetail.id!,
					affiliateContext?.affiliateDetail.affiliateId!
				);
				loadRowInformation(
					affiliateContext?.engagementDetail.id!,
					affiliateContext?.affiliateDetail.affiliateId!
				);
			}
		},
		[affiliateContext]
	);

	useEffect(
		() =>
		{
			setIsLoading(true);

			if (
				!!lineConfiguration &&
				!!lineConfiguration.length &&
				!!affiliateContext&&
				!!affiliateContext?.engagementDetail.id&&
				!!affiliateContext?.affiliateDetail.affiliateId

			)
			{
				loadDividendReceivedData({
					engagementId: affiliateContext?.engagementDetail.id!,
					affiliateId: affiliateContext?.affiliateDetail.affiliateId!

				});
			}
		},
		[
			affiliateContext,
			lineConfiguration
		]
	);

	const hasData = () =>
	{
		return !!dividendRecords &&
			!!Object.keys(dividendRecords) &&
			!!Object.values(dividendRecords).some((d) => !!d.length);
	};

	const onRefresh = async (): Promise<void> =>
	{
		setIsLoading(true);

		loadDividendReceivedData({
			engagementId: affiliateContext?.engagementDetail.id!,
			affiliateId: affiliateContext?.affiliateDetail.affiliateId!,
			fromDate,
			toDate
		});
	};


	return <>
		<div style={{
			width: !isLoading && !!hasData() ?
				'inherit' :
				'100%'
		}}>
			<ColumnarGrid
				title={t('gridTitle')}
				columnIndexKeyName={t('columnIndexKeyName')}
				isLoading={isLoading}
				isDisabled={false}
				rowInformation={rowInformation}
				data={
					!!dividendRecords ?
						dividendRecords :
						{
						}
				}
				addNewButtonText={t('addNewButtonText')}
				addNewMessage={t('addNewMessage')}
				onRenderFilters={onRenderFilters}
				onOpenNew={() =>
				{
					onOpenPanel(
						true,
						new DividendReceivedOther(
							affiliateContext?.engagementDetail.id!,
							affiliateContext?.affiliateDetail.affiliateId!,
							!!foreignAffiliate ?
								foreignAffiliate.currencyId :
								undefined)
					);
				}}
				onEdit={(entity) =>
				{
					onOpenPanel(
						false,
						entity
					);
				}}
			/>
		</div>
		<DividendsReceivedForm
			isAdd={isAddRecord}
			displayModal={displayModal}
			record={selectedRecord}
			engagementInfo={engagementInfo}
			foreignAffiliate={foreignAffiliate}
			lineConfiguration={lineConfiguration}
			onRefresh={onRefresh}
			onReset={() =>
			{
				setSelectedRecord(undefined);
			}}
			onToggleDisplayModal={(display: any) =>
			{
				setDisplayModal(!!display);
			}}
		/>
	</>;
}