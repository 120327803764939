import { Utils } from 'src/common/utils/utils';

export enum ForeignTaxableIncomeLookupTypes {

	Permanent = 'Permanent',
	Temporary = 'Temporary',
	OtherAdjustments = 'OtherAdjustments',
	NetIncome = 'NetIncome',
	IncomeLossAfterAdjustments = 'IncomeLossAfterAdjustments',
	TaxableIncomeFiled = 'TaxableIncomeFiled'
}

export interface IReg5907Adjustment {
	id: number,
	foreignTaxableIncomeId: number|undefined,
	taxPayerId: number|undefined,
	currencyId: number,
	rowIndex: number|undefined,
	descriptionId: number,
	amount: number|undefined,
	notes: string
}

export interface IForeignTaxableIncomeLookupRecord {

	id: number;
	differenceType: ForeignTaxableIncomeLookupTypes;
	description: string;
}

export interface IForeignTaxableIncomeRowBase {

	id?: number;
	rowIndex?: number;
	descriptionId: number;
	amount?: number;
	notes?: string;
	reg5907Adjustments?: IReg5907Adjustment[];
}

export interface IForeignTaxableIncomeRow extends IForeignTaxableIncomeRowBase {

	taxYearEnd?: string;
	affiliateTaxYearEndId: number;
	calculatingCurrencyId: number;
}

export class ForeignTaxableIncomeRow implements IForeignTaxableIncomeRow
{
	constructor (
		{
			descriptionId = 0,
			taxYearEnd = '',
			affiliateTaxYearEndId,
			calculatingCurrencyId,
			rowIndex
		}:
		{
			descriptionId?: number;
			taxYearEnd: string;
			affiliateTaxYearEndId: number;
			calculatingCurrencyId: number;
			rowIndex: number;
		}
	)
	{
		this.id = Utils.generateTempId();
		this.descriptionId = !!descriptionId ? descriptionId : 0;
		this.amount = undefined;
		this.taxYearEnd = taxYearEnd;
		this.notes = undefined;
		this.affiliateTaxYearEndId = affiliateTaxYearEndId;
		this.calculatingCurrencyId = calculatingCurrencyId;
		this.rowIndex = rowIndex;
		this.reg5907Adjustment = undefined;
	}

	id: number;
	descriptionId: number;
	amount?: number;
	notes?: string;
	taxYearEnd?: string;
	rowIndex: number;
	affiliateTaxYearEndId: number;
	calculatingCurrencyId: number;
	reg5907Adjustment: undefined;
}

export enum ForeignTaxableIncomeSubmissionToken {

	NetIncome = 'netIncome',
	PermanentDifferences = 'permanentDifferences',
	TemporaryDifferences = 'temporaryDifferences',
	OtherAdjustments = 'otherAdjustments',
	TaxableIncomeFiled='taxableIncomeFiled',
	IncomeLossAfterAdjustments='incomeLossAfterAdjustments'
}

export interface IForeignTaxableIncomeRecord {

	[ForeignTaxableIncomeSubmissionToken.NetIncome]: IForeignTaxableIncomeRow;
	[ForeignTaxableIncomeSubmissionToken.PermanentDifferences]: IForeignTaxableIncomeRow[];
	[ForeignTaxableIncomeSubmissionToken.TemporaryDifferences]: IForeignTaxableIncomeRow[];
}

export interface IForeignTaxableIncomeYear {

	taxYearEnd: string;
	countryId: number;
	calculatingCurrencyId: number;
	affiliateTaxYearEndId: number;
	[ForeignTaxableIncomeSubmissionToken.NetIncome]: IForeignTaxableIncomeRow;
	[ForeignTaxableIncomeSubmissionToken.TaxableIncomeFiled]: IForeignTaxableIncomeRow;
	[ForeignTaxableIncomeSubmissionToken.IncomeLossAfterAdjustments]: IForeignTaxableIncomeRow;
	[ForeignTaxableIncomeSubmissionToken.PermanentDifferences]: IForeignTaxableIncomeRow[];
	[ForeignTaxableIncomeSubmissionToken.TemporaryDifferences]: IForeignTaxableIncomeRow[];
	[ForeignTaxableIncomeSubmissionToken.OtherAdjustments]: IForeignTaxableIncomeRow[];
}

export interface IForeignTaxableIncomeYearSubmission {

	affiliateTaxYearEndId: number;
	[ForeignTaxableIncomeSubmissionToken.NetIncome]?: IForeignTaxableIncomeRowBase;
	[ForeignTaxableIncomeSubmissionToken.TaxableIncomeFiled]?: IForeignTaxableIncomeRowBase;
	[ForeignTaxableIncomeSubmissionToken.PermanentDifferences]: IForeignTaxableIncomeRowBase[];
	[ForeignTaxableIncomeSubmissionToken.TemporaryDifferences]: IForeignTaxableIncomeRowBase[];
	[ForeignTaxableIncomeSubmissionToken.OtherAdjustments]: IForeignTaxableIncomeRowBase[];
}

export interface IForeignTaxableIncomeSubmission {

	engagementId: number;
	affiliateId: number;
	foreignTaxableIncomes: IForeignTaxableIncomeYearSubmission[];
}