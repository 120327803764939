import { IReg5907GridProps } from './IReg5907GridProps';
import { Reg5907RowColumns } from './components/Reg5907RowColumns/Reg5907RowColumns';
import { Button } from '@appkit4/react-components';
import { useTranslation } from 'react-i18next';
import { Utils } from 'src/common/utils/utils';
import { Reg5907AdjustmentsTypes } from 'src/common/types/enums/Reg5907Adjustments';

import './Reg5907Grid.scss';

export function Reg5907Grid(props: IReg5907GridProps): JSX.Element
{
	const { t } = useTranslation(
		'common',
		{
			keyPrefix: 'reg5907Grid'
		}
	);

	return <>
		{
			!!props.rowData &&
			<div
				className={'reg5907-grid'}
				style={{
					visibility: props.isLoading ? 'hidden' : 'visible'
				}}
			>
				{
					!!props.title &&
					<div className={'title'}>
						<span>
							{
								props.title
							}
						</span>
					</div>
				}
				<div
					ref={props.customRef}
					className={'table-container table-scroll-container'}
				>
					<table
						id={props.id}
						className={props.displayNotes ? 'notes' : ''}
					>
						{
							!!props.rowData &&
								!!props.rowData.length && props.rowData.length > 0 &&
							<>
								<thead>
									<tr>
										<th className={'action-column'} />
										<th className={'first-column'}>
											{
												t('descriptionHeader')
											}
										</th>
										{
											!!props.reg5907Adjustments &&
									!!props.reg5907Adjustments.length &&
									props.reg5907Adjustments
										.map(() =>
										{
											return <>
												<th className={`amount-type ${!!props.displayNotes ? 'notes-included' : ''}`}>
													<div>
														{
															t('amountHeader')
														}
													</div>
												</th>
											</>;
										})
										}
									</tr>
								</thead>
								<tbody>
									{
										!!props.rowData &&
								!!props.rowData.length &&
								props.rowData
									.map((rd, rowIndex) =>
									{
										return <Reg5907RowColumns
											isLoading={props.isLoading}
											isDisabled={props.isDisabled}
											validate={props.validate}
											displayNotes={props.displayNotes}
											rowData={rd}
											descriptionOptions={props.descriptionOptions}
											onDescriptionUpdate={(value) =>
											{
												props.onDescriptionUpdate(rowIndex, value);
											}}
											onValueUpdate={(fieldName, index, value) =>
											{
												props.onValueUpdate(fieldName, rowIndex, index, value);
											}}
											onDeleteRow={props.onDeleteRow}
										/>;
									})
									}
								</tbody>
								<thead>
									<tr>
										<th className={'action-column'} />
										<th className={'first-column'}>
											{
												t('subTotalHeader')
											}
										</th>
										{
											!!props.subTotalsByYear &&
									!!props.subTotalsByYear.length &&
									props.subTotalsByYear
										.map((s) =>
										{
											return <>
												<th className={`subtotal-content amount-type
												${!!props.displayNotes ? 'notes-included' : ''}`}>
													<div>
														{
															Utils.formatCurrency(`${s[Reg5907AdjustmentsTypes.TotalAdjustments]}`, 2)
														}
													</div>
												</th>
											</>;
										})
										}
									</tr>
								</thead>
							</>
						}
					</table>
				</div>
				{
					(
						!props.rowData ||
						!props.rowData.length
					) &&
					<div className={'no-data'}>
						<div>
							{ t('noDataPart1') }
						</div>
						<div>
							{t('noDataPart2')}<strong>+ {props.addRowButtonText}</strong> {t('noDataPart3')}
						</div>
					</div>
				}
				<div className={'actions'}>
					<Button
						kind={'text'}
						icon={'icon-plus-outline'}
						onClick={props.onRowAdd}
					>
						{
							props.addRowButtonText
						}
					</Button>
				</div>
			</div>
		}
	</>;
}