import React, { useEffect, useState } from 'react';
import {
	Button,
	Modal,
	Select,
	TimePicker,
	TextArea,
	Input,
	Loading
} from '@appkit4/react-components';
import { SelectValue } from '@appkit4/react-components/esm/select/Select';
import { useTranslation } from 'react-i18next';
import './MergerModal.scss';
import { UnsavedChangesModal } from 'src/core/components/Dashboard/unsavedModal/UnsavedChangesModal';
import useDisableBodyScroll from 'src/common/hooks/useDisableBodyScroll';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { IRelationship } from 'src/features/engagement/models/IRelationship';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import { UnexpectedErrorModal } from 'src/common/components/UnexpectedErrorModal/UnexpectedErrorModal';
import { RelationshipResolutionGrid } from 'src/common/components/RelationshipResolutionGrid/RelationshipResolutionGrid';
import { CalendarPickerWrapper } from 'src/common/components/CalendarPickerWrapper/CalendarPickerWrapper';
import { IRelationshipAffiliateId } from 'src/common/types/interfaces/IRelationshipAffiliate';

interface IMergerForm {
  faName: string;
  entityToMerge: number | null;
  survivorEntity: number | null | SelectValue;
  asOfDate: Date | undefined;
  asOfTime: Date | undefined;
  notes: string;
  entityToMergeName: string;
  survivorEntityName: string;
}

interface SurvivorEntity {
	faName: string;
	faId: number
}

interface EntityToMerge {
	affiliateName: string;
	affiliateId: number
}

const initialFormState: IMergerForm = {
	faName: '',
	entityToMerge: null,
	survivorEntity: null,
	asOfDate: undefined,
	asOfTime: undefined,
	notes: '',
	entityToMergeName: '',
	survivorEntityName: ''

};

export const MergerModal = (props: {
	visible: boolean,
	onClose: (val: boolean,newAffiliateRelationshipIds?: number[]) => void,
	mergingEntity: IRelationship,
	defaultDate: Date|undefined
	maxDate: Date|undefined
	engagementId: number | undefined;
}) =>
{
	useDisableBodyScroll(props.visible);
	const [step, setStep] = useState<number>(1);
	const { t } = useTranslation('engagementSetup');
	const [mergingEntity, setMergingEntity] = useState<IRelationship|undefined>(undefined);
	const [mergerForm, setMergerForm] = useState<IMergerForm>(initialFormState);
	const [showUnsavedModal, setShowUnsavedModal] = useState<boolean>(false);
	const [mergerFormError, setMergerFormError] = useState<any>(undefined);
	const [, setExistingError] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [entityToMerge,setEntityToMerge] =useState<EntityToMerge[]>([]);
	const [survivorEntity,setSurvivorEntity] =useState<SurvivorEntity[]>([]);
	const [disableContinue, setDisableContinue] = useState<boolean>(true);
	const [disableMerge, setDisableMerge] = useState<boolean>(true);
	const [asOfDate, setAsOfDate] =useState<Date | undefined>(undefined);
	const [asOfTime, setAsOfTime] =useState<Date | undefined>(undefined);
	const [showUnexpectedErrorModal, setShowUnexpectedErrorModal] = useState<boolean>(false);
	const [unexpectedError, setUnExpectedError] = useState<string>('');
	const [unexpectedErrorMessage, setUnexpectedErrorMessage] = useState<string | null>('');
	const { t: errorMessage } = useTranslation('errors');
	const genericErrorCodes = [
		'INVALID_RELATIONSHIP_COUNT',
		'INVALID_MERGE_RELATIONSHIP',
		'INVALID_MERGE_AFFILIATES',
		'INVALID_MERGE_DATE_AFTER_LAST_TAXATION_YEAR',
		'AFFILIATE_RELATIONSHIP_DUPLICATE_ROW',
		'AFFILIATE_RELATIONSHIP_EQUITY_PERCENTAGE'
	];
	const [errorCode, setErrorCode] = useState<number|undefined>(undefined);
	const [relationshipsForUpdate, setRelationshipsForUpdate] = useState<IRelationship[]>([]);
	const [circularFAs,setCircularFAs] = useState<IRelationshipAffiliateId[]>([]);

	useEffect(() =>
	{
		if (props.visible)
		{
			setStep(1);
			if (props.visible && props.defaultDate)
			{
				setMergerForm({
					...initialFormState,
					'faName': props.mergingEntity?.faName,
					'asOfDate': props?.defaultDate
				});

				getEntityToMergeWith(props.mergingEntity.faId, dateFormatter.estDateStringOverrideToUtc(`${props?.defaultDate}`));
			}
			const currentForreignAffiliate = props.mergingEntity;

			const mergerEntity = {
				faName: currentForreignAffiliate?.faName,
				faId: currentForreignAffiliate?.faId
			};
			setSurvivorEntity([mergerEntity]);
			setMergingEntity(props.mergingEntity);
		}
	}, [props.mergingEntity, props.visible, props.defaultDate]);

	useEffect(() =>
	{
		if (!!mergerForm.entityToMerge)
		{
			const isSurvivorValid = survivorEntity.find((x: SurvivorEntity) => x.faId === mergerForm.survivorEntity);
			if (!isSurvivorValid)
			{
				const updatedFormValue = {
					...mergerForm,
					'survivorEntity': null
				};

				setMergerForm(updatedFormValue);
			}
		}
	},[mergerForm.entityToMerge]);

	useEffect(() =>
	{
		if (!!asOfDate)
		{
			const effectiveDate: string | undefined =!!asOfDate && !!asOfTime ? dateFormatter.estDateStringOverrideToUtc(
				dateFormatter.mergeDateTime(
					asOfDate,
					asOfTime
				)
			):dateFormatter.estDateStringOverrideToUtc(`${asOfDate}`);
			getEntityToMergeWith(props.mergingEntity.faId,effectiveDate);
		}
	},[asOfDate,asOfTime]);

	useEffect(() =>
	{
		setExistingError(!!mergerFormError);
	}, [mergerFormError]);

	useEffect(() =>
	{
		if (!checkIfFormValid())
		{
			setDisableContinue(true);
		}
		else
		{
			setDisableContinue(false);
		}
	},[mergerForm]);

	const getEntityToMergeWith = (faId: number,date: string | undefined) =>
	{
		if (faId&&!loading)
		{
			setLoading(true);
			engagementService.getEntityToMergeWith(props.engagementId!, faId, date).then((res: any) =>
			{
				const result = res.data.result;
				setLoading(false);
				setEntityToMerge(result.entitiesToBeMerged);
			})
				.catch((err: any) =>
				{
					setLoading(false);
				 handleApiErrorResponse(err,'getEntity');
				});
		};
	};

	const getSurvivorEntity = (surviourEntity: number) =>
	{
		const currentForreignAffiliate = props.mergingEntity;

		const mergerEntity = [{
			faName: currentForreignAffiliate?.faName,
			faId: currentForreignAffiliate?.faId
		}];
		const filteredData = entityToMerge.reduce((formatted: SurvivorEntity[],item: EntityToMerge) =>
		{
			if (item.affiliateId === surviourEntity)
			{
				const obj: SurvivorEntity = {
					faId: item.affiliateId,
					faName: item.affiliateName
				};
				formatted.push(obj);
			}
			return formatted;
		},[]);
		const sortedData = [...mergerEntity,...filteredData];

		//sorting survivor entity list by foriegn affiliate name
		sortedData.sort((a, b) =>
		{
			const fa = a.faName?.toLowerCase(),
				fb = b.faName?.toLowerCase();

			if (fa < fb)
			{
				return -1;
			}
			if (fa > fb)
			{
				return 1;
			}
			return 0;
		});
		setSurvivorEntity([...sortedData]);
	};

	const onMerge = () =>
	{
		const survivorEntityName = survivorEntity.find((x: SurvivorEntity) => x.faId === mergerForm.survivorEntity
		)?.faName;

		const entityToMergeName = entityToMerge.find((x: EntityToMerge) =>
			x.affiliateId === mergerForm.entityToMerge)?.affiliateName;

		setMergerForm({
			...mergerForm,
			'survivorEntityName': survivorEntityName ? survivorEntityName : '',
			'entityToMergeName': entityToMergeName ? entityToMergeName : ''
		});

		const payload = {
			engagementId: props.engagementId,
			predecessorAffiliateId: (!!mergerForm.survivorEntity && !!mergerForm.entityToMerge) ?
			 (mergerForm.survivorEntity === mergerForm.entityToMerge) ? mergingEntity?.faId :
					mergerForm.entityToMerge : null,
			survivorAffiliateId: !!mergerForm.survivorEntity ? mergerForm.survivorEntity : null,
			asOfDate: mergerForm.asOfDate,
			asOfTime: mergerForm.asOfTime,
			notes: mergerForm.notes
		};
		if (!loading)
		{
			setLoading(true);
			setCircularFAs([]);
			engagementService.mergeRelationship(payload)
				.then((res: any) =>
				{
					setLoading(false);
					const response = res.data;
					if (response.proposedMergerRows?.length > 0)
					{
						setMergerFormError(undefined);
						setMergingEntity(props.mergingEntity);
						const u = response.proposedMergerRows.map((r: any,index: number) =>
						{
							return {
								'recordId': `${r.affiliateId}_${index}`,
								'relationshipId': r.affiliateId,
								'faId': r.affiliateId,
								'faName': r.affiliateName,
								'ownerAffiliateId': r.ownerAffiliateId,
								'ownerName': r.ownerName,
								'equityClassId': r.equityClassId,
								'equityPercentage': r.equity,
								'SEP': r.sep,
								'effectiveDate': new Date(r.effectiveDate),
								'editable': r.isUserInputRequired,
								'inEdit': r.isUserInputRequired
							};
						});

						setRelationshipsForUpdate(u);
						setStep(2);
					}
				})
				.catch((err: any) =>
				{
					setLoading(false);
					handleApiErrorResponse(err,'merge');
				});
		}
	};

	const saveMerge = () =>
	{
		setLoading(true);
		setCircularFAs([]);
		const mergerRows = relationshipsForUpdate.map((r: IRelationship) =>
		{
			return {
			  'relationshipId': r.relationshipId,
			  'affiliateId': r.faId,
			  'affiliateName': r.faName,
			  'ownerAffiliateId': r.ownerAffiliateId,
			  'ownerName': r.ownerName,
			  'equityClassId': r.equityClassId,
			  'equity': r.equityPercentage,
			  'sep': r.SEP,
			  'notes': r.notes,
			  'effectiveDate': r.effectiveDate,
			  'isUserInputRequired': r.editable
			};
		});

		const payload = {
			engagementId: props.engagementId!,
			predecessorAffiliateId: (!!mergerForm.survivorEntity && !!mergerForm.entityToMerge) ?
			 (mergerForm.survivorEntity === mergerForm.entityToMerge) ? mergingEntity?.faId :
					mergerForm.entityToMerge : null,
			survivorAffiliateId: !!mergerForm.survivorEntity ? mergerForm.survivorEntity : null,
			asOfDate: mergerForm.asOfDate,
			asOfTime: mergerForm.asOfTime,
			notes: mergerForm.notes,
			mergerRows: mergerRows
		};
		engagementService.mergeRelationship(payload)
			.then((res: any) =>
			{
				setLoading(false);
				const response = res.data;
				props.onClose(false, response.newRelationshipIds);
			})
			.catch((err: any) =>
			{
				setLoading(false);
				handleApiErrorResponse(err,'merge');
			});;
	};

	const onCancel = () =>
	{
		if (hasChanges())
		{
			setShowUnsavedModal(true);
		}
		else
		{
			setMergerFormError(undefined);
			props.onClose(false);
		}
	};

	const hasChanges = () =>
	{
		if (
			!mergerForm.survivorEntity &&
			!mergerForm.entityToMerge &&
			!mergerForm.asOfDate &&
			!mergerForm.asOfTime &&
			!mergerForm.notes
		)
		{
			return false;
		}
		else
		{
			return true;
		}
	};

	const closeUnsavedModal = (str: string) =>
	{
		if (str === 'stay')
		{
			setShowUnsavedModal(false);
		}
		else if (str === 'leave')
		{
			setShowUnsavedModal(false);
			setMergerFormError(undefined);
			setMergerForm(initialFormState);
			props.onClose(false);
		}
	};

	const onValueChange = (field: string, value: any) =>
	{
		setMergerFormError({
			...mergerFormError,
			[field]: null
		});
		const updatedFA = {
			...mergerForm,
			[field]: value
		};
		setMergerForm(updatedFA);
		if (field === 'entityToMerge')
		{
			setSurvivorEntity([]);
			getSurvivorEntity(value);
		}
		if (field === 'asOfDate')
		{
			setAsOfDate(value);
		}
		else if (field === 'asOfTime')
		{
			setAsOfTime(value);
		}
	};

	const checkIfFormValid = () =>
	{
		if (!!mergerForm.asOfDate &&
			!!mergerForm.entityToMerge &&
			!!mergerForm.survivorEntity)
		{
			return true;
		}
		else
		{
			return false;
		}
	};

	const getNoDataTemplate = (): React.ReactNode =>
	{
		return <>{t('mergerModal.noDataAvailable')}</>;
	};

	const handleApiErrorResponse = (err: any, method: string) =>
	{
		if (err.response)
		{
			setErrorCode(err.response.status);
			if (err.response.status >= 500)
			{
				setUnexpectedErrorMessage('');
				setUnExpectedError(err.response.data.Errors[0].Message);
				setShowUnexpectedErrorModal(true);
			}
			else if (err.response.status >= 400)
			{
				if (err.response.status === 404 && method === 'getEntity')
				{
					setUnexpectedErrorMessage('Something went wrong while loading entites to merge with.');
					setShowUnexpectedErrorModal(true);
					return;
				}
				const errors = err.response.data.Errors;
				const mergeFormError = {
					entityToMerge: null,
					survivorEntity: null,
					asOfDate: null
				};
				if (errors)
				{
					errors.forEach((error: any) =>
					{
						if (genericErrorCodes.indexOf(error.ErrorCode) > -1)
						{
							setUnexpectedErrorMessage(errorMessage(`code.${error.ErrorCode}`));
							setShowUnexpectedErrorModal(true);
						}
						if (error.ErrorCode === 'MERGE_ERROR_EFFECTIVE_DATE_REQUIRED')
						{
							mergeFormError.asOfDate= errorMessage(
								`code.${error.ErrorCode}`
							);
						}
						if (error.ErrorCode === 'MERGE_ERROR_SURVIVOR_NOT_EXISTS')
						{
							mergeFormError.survivorEntity = errorMessage(
								`code.${error.ErrorCode}`
							);
						}
						if (error.ErrorCode === 'MERGE_ERROR_PREDECESSOR_NOT_EXISTS')
						{
							mergeFormError.entityToMerge = errorMessage(
								`code.${error.ErrorCode}`
							);
						}
						if (error.ErrorCode === 'AFFILIATE_RELATIONSHIP_CIRCULAR_REFERENCE')
						{
							setCircularFAs(error.MessageDetail||[]);
						}
						if (error.ErrorCode === 'MERGER_MERGEDATE_INVALID_INELIGIBLE_PARENTS')
						{
							mergeFormError.asOfDate = errorMessage(
								`code.${error.ErrorCode}`
							);
						}
					});
					setMergerFormError(mergeFormError);
				}
			}
		}
	};

	const closeUnexpectedErrorModal = () =>
	{
		setUnExpectedError('');
		setShowUnexpectedErrorModal(false);
	};

	if (props?.visible === false)
	{
		return null;
	}
	return (
		<>
			<Modal
				className={`merger-modal ${step===1? 'step1':'step2'} `}
				visible={props.visible}
				initialFocus={false}
				closeOnPressEscape={false}
				maskCloseable={false}
				title={t('mergerModal.createMerger') || ''}
				onCancel={onCancel}
				footer={
					<div className='footer-wrapper'>
						<div className='left'>{step===2 && <Button kind="secondary" onClick={() =>
						{
							setStep(1);
						}}>
							{t('mergerModal.back')}
						</Button>}
						</div>
						<div className='right'>

							<div>
								{step===1?
									<Button kind="primary" onClick={onMerge}
									 disabled={loading || disableContinue}
									>
										{t('mergerModal.continue')}
									</Button>:
									<Button kind="primary" onClick={saveMerge} disabled={loading || disableMerge}>
										{t('mergerModal.merge')}
									</Button>
								}</div>
							<Button kind="secondary" onClick={onCancel}>
								{t('mergerModal.cancel')}
							</Button>
						</div>
					</div>
				}
			>
				<div className={`merger-modal-body  ${step===1? 'step1': 'step2'} `}>
					<div className="title">
						{`${t('mergerModal.step')} ${step}/2`}
					</div>
					{step===1&&<div className="merger-form-body">
						<div>
							<Input
								title={t('mergerModal.faName') || ''}
								required={true}
								value={mergerForm.faName}
								disabled={true}
							></Input>
						</div>
						<div>
							<Select
								data={entityToMerge}
								valueKey={'affiliateId'}
								labelKey={'affiliateName'}
								dropdownRenderMode={'portal'}
								//@ts-ignore
								value={mergerForm.entityToMerge}
								placeholder={t('mergerModal.entityToMerge') || ''}
								dropdownAlwaysDown={false}
								error={!!mergerFormError && mergerFormError?.['entityToMerge']}
								searchable={false}
								required={true}
								noResultFound={getNoDataTemplate()}
								onSelect={(vals: SelectValue) =>
									onValueChange('entityToMerge', vals)
								}
							/>
							{mergerFormError?.['entityToMerge'] && (
								<div className="custom-cell-error">
									{mergerFormError['entityToMerge']}
								</div>
							)}
						</div>
						<div>
							<Select
								data={survivorEntity}
								valueKey={'faId'}
								labelKey="faName"
								dropdownRenderMode={'portal'}
								//@ts-ignore
								value={mergerForm.survivorEntity}
								placeholder={t('mergerModal.survivor&MergedEntity') || ''}
								dropdownAlwaysDown={false}
								error={!!mergerFormError && mergerFormError?.['survivorEntity']}
								searchable={false}
								required={true}
								noResultFound={getNoDataTemplate()}
								onSelect={(vals: SelectValue) =>
									onValueChange('survivorEntity', vals)
								}
							/>
							{mergerFormError?.['survivorEntity'] && (
								<div className="custom-cell-error">
									{mergerFormError['survivorEntity']}
								</div>
							)}
						</div>
						<div>
							<CalendarPickerWrapper
								className="calendar-picker"
								format={'MMM DD, YYYY'}
								editable={false}
								disabled={false}
								fieldWidth={'100%'}
								value={mergerForm.asOfDate}
								useCustomValidation={true}
								error={!!mergerFormError && mergerFormError?.['asOfDate']}
								customErrorNode={
									mergerFormError?.['asOfDate'] && (
										<div className="custom-cell-error">
											{mergerFormError['asOfDate']}
										</div>
									)
								}
								onValueChange={(value: string) =>
									!!value && onValueChange('asOfDate', new Date(value))
								}
								fieldTitle={t('mergerModal.asOfDate') || ''}
								required={true}
								minDate={new Date(props.defaultDate||'')}
								maxDate={props.maxDate}
								datePanelHorizontalAlign="right"
							/>
						</div>
						<TimePicker
							hourTime={12}
							className="time-picker"
							value={mergerForm?.asOfTime}
							fieldWidth={'100%'}
							fieldTitle={t('mergerModal.asOfTime') || ''}
							onChange={(value: Date) =>
								!!value && onValueChange('asOfTime', value)
							}
						/>
						<TextArea
							title={t('mergerModal.notes')||''}
							className={'textarea-height'}
							value={mergerForm?.notes}
							maxLength={255}
							onChange={(inputVal: string) => onValueChange('notes', inputVal)}
						></TextArea>
						<div className="merge-message">
							{t('mergerModal.mergerMessage')}
						</div>
					</div>}
					{step===2&&<div className="step2">

						<div className="step2-info">
							<div className="step2-info-row">
								<div className="detail"><div className="left-title">Entity to Merge with </div>
									<div className='title-value'>{mergerForm.entityToMergeName}</div></div>
								<div className="detail"><div className="left-title">Survivior of Merger</div>
									<div className='title-value'> {mergerForm.survivorEntityName}</div></div>
							</div>
							<div className="step2-info-row">
								<div className="detail">
									<div className="left-title">As of Date</div>
									<div className='title-value'> {dateFormatter.format(mergerForm.asOfDate,'MMM DD, YYYY')}</div></div>
								<div className="detail"><div className="left-title">As of Time</div>
									<div className='title-value'> {dateFormatter.format(mergerForm.asOfTime,'hh:mm A')}</div></div>
							</div>

						</div>
						<RelationshipResolutionGrid
							relationships={relationshipsForUpdate}
							circularFAs={circularFAs}
							onCompleted={(completed: boolean) => setDisableMerge(!completed)}
						/>
					</div>}
					{loading && (
						<div className="loading-icon">
							<Loading
								loadingType="circular"
								indeterminate={true}
								compact={false}
							/>
						</div>
					)}
				</div>
			</Modal>

			<UnsavedChangesModal
				visible={showUnsavedModal}
				onClose={closeUnsavedModal}
				title={t('mergerModal.discardChangesTitle') || ''}
			/>
			<UnexpectedErrorModal
				visible={showUnexpectedErrorModal}
				onClose={closeUnexpectedErrorModal}
				message={unexpectedErrorMessage?unexpectedErrorMessage:''}
				error={unexpectedError}
				statusCode={errorCode}
			/>
		</>
	);
};
