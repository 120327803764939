import Header from '../Header';
import Sidebar from '../Sidebar';
import './Layout.scss';

export const Layout = ({ children }: any) =>
{
	return (
		<div className="layout-container">
			<Header></Header>
			<div className="layout-body">
				<Sidebar></Sidebar>
				<main className="calcWidth">{children}</main>
			</div>
		</div>
	);
};
