import { Modal } from '@appkit4/react-components/modal';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAppSelector } from 'src/store/store';
import InputField from './InputField';
import DropdownField from './DropdownField';
import { Button } from '@appkit4/react-components/button';
import { UserService } from '../../services/userService';
import './UserModal.scss';
import { useTranslation } from 'react-i18next';
import { UnexpectedErrorModal } from 'src/common/components/UnexpectedErrorModal/UnexpectedErrorModal';

interface UserData {
  email: string;
  name: string;
  office: string;
  role: string;
}

interface Props {
  visible: boolean;
  onClose: Dispatch<SetStateAction<Boolean>>;
  userModalContext?: Record<string, any> | null;
  handleSaveUserData: Function;
  handleUpdateUserData: Function;
}

const defaultFormValue: UserData = {
	email: '',
	name: '',
	office: '',
	role: ''
};

const defaultFormError = {
	email: null,
	role: null,
	apiError: null
};

export const UserModal = (props: Props) =>
{
	const userRoles = useAppSelector((state) => state.appSettings.roles);
	const [userData, setUserData] = useState<UserData>(defaultFormValue);
	const [userFormError, setUserFormError] = useState<Record<string, any>>(
		defaultFormError
	);
	const [errorCode, setErrorCode] = useState<number|undefined>(undefined);
	const [showUnexpectedErrorModal, setShowUnexpectedErrorModal] = useState<boolean>(false);
	const [unexpectedError, setUnExpectedError] = useState<string>('');
	const isEditableModal = !!props?.userModalContext;
	const { t } = useTranslation('user');

	useEffect(() =>
	{
		resetFormInitialState();
	}, [props?.visible]);

	useEffect(() =>
	{
		if (!!props?.userModalContext)
		{
			const {
				firstName,
				lastName,
				email,
				role,
				office
			} = props.userModalContext;
			setUserData({
				email,
				office,
				role,
				name: `${firstName} ${lastName}`
			});
		}
	}, [props?.userModalContext]);

	const handleInputBlur = (
		event: React.ChangeEvent<HTMLInputElement>,
		fieldName: string
	) =>
	{
		if (isEditableModal)
		{
			return;
		}

		const field: string = userData['email'];
		if (isValidEmailAddress(field))
		{
			findUserByEmailId();
		}
		else
		{
			setUserFormError({
				...userFormError,
				email: field?.length > 0 ? t('userPopup.error.invalid-email') : ''
			});
		}
	};

	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		fieldName: string,
		fieldValue: string
	) =>
	{
		setUserFormError({
			...userFormError,
			[fieldName]: null,
			apiError: null
		});
		if (fieldName === 'email')
		{
			setUserData({
				...userData,
				[fieldName]: fieldValue.trim(),
				name: '',
				office: ''
			});
			return;
		}
		setUserData({
			...userData,
			[fieldName]: fieldValue
		});
	};

	const isValidEmailAddress = (data: string) =>
	{
		const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		const email = data.trim();
		if (!!email && email.length > 0 && email.match(validRegex))
		{
			return true;
		}

		return false;
	};

	const handleSaveUserData = () =>
	{
		if (!doesFormHaveErrors())
		{
			if (!isEditableModal)
			{
				props?.handleSaveUserData(userData.email, userData.role);
			}
			else if (isEditableModal)
			{
				props?.handleUpdateUserData(userData.email, userData.role);
			}
		}
	};

	const resetFormInitialState = () =>
	{
		setUserFormError(defaultFormError);
		setUserData(defaultFormValue);
	};

	const doesFormHaveErrors = () =>
	{
		const { email, role } = userData;
		const formValidationErrors: Record<string, any> = {
			...userFormError
		};
		if (email === defaultFormValue.email || !isValidEmailAddress(email))
		{
			formValidationErrors['email'] = t('userPopup.error.invalid-email');
		}
		else if (!isEditableModal)
		{
			findUserByEmailId();
		}
		if (!role || role?.length <= 0 || role === defaultFormValue?.role)
		{
			formValidationErrors['role'] = t('userPopup.error.role-required');
		}
		setUserFormError(formValidationErrors);
		return (
			formValidationErrors?.email !== defaultFormError?.email ||
      formValidationErrors?.role !== defaultFormError?.role
		);
	};

	const handleApiErrorResponse = (err: any) =>
	{
		if (err.response)
		{
			setErrorCode(err.response.status);
			if (err.response.status >= 500)
			{
				setUnExpectedError(err.response.data.Errors[0].Message);
				setShowUnexpectedErrorModal(true);
			}
			else if (err.response.status === 404)
			{
				setUserFormError({
					...userFormError,
					apiError: t('userPopup.error.user-not-found'),
					role:
            !userData?.role ||
            userData?.role?.length <= 0 ||
            userData?.role === defaultFormValue?.role ?
            	t('userPopup.error.role-required') :
            	null
				});
			}
			else if (err.response.status === 400)
			{
				setUserFormError({
					...userFormError,
					apiError: err.response.data,
					role:
            !userData?.role ||
            userData?.role?.length <= 0 ||
            userData?.role === defaultFormValue?.role ?
            	t('userPopup.error.role-required') :
            	null
				});
			}
		}
	};

	const closeUnexpectedErrorModal = () =>
	{
		setUnExpectedError('');
		setShowUnexpectedErrorModal(false);
	};

	const findUserByEmailId = () =>
	{
		UserService.findUserDetailFromEmail(userData.email.trim())
			.then((res: any) =>
			{
				const result = res.data.result;
				setUserData({
					...userData,
					office: result?.office,
					name: `${result?.firstName} ${result.lastName}`
				});
			})
			.catch((err: Record<string, any>) =>
			{
				handleApiErrorResponse(err);
			});
	};

	const handleModalClose = () =>
	{
		closeUserModal();
	};

	const closeUserModal = () =>
	{
		resetFormInitialState();
		props.onClose(false);
	};

	if (props.visible === false)
	{
		return <></>;
	}
	return (
		<>
			<Modal
				className="user-detail-modal"
				visible={props.visible}
				initialFocus={false}
				closeOnPressEscape={false}
				title={
					isEditableModal ?
						t('userPopup.editUser') || '' :
						t('userPopup.addUser') || ''
				}
				onCancel={() => handleModalClose()}
				modalStyle={{
					width: '540px',
					height: 'fit-content'
				}}
				maskCloseable={false}
				footerStyle={{
					paddingTop: '8px',
					marginTop: '-8px',
					minHeight: '64px'
				}}
				footer={
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'end'
						}}
					>
						<Button onClick={() => handleModalClose()} kind="secondary">
							{t('userPopup.cancel') || ''}
						</Button>
						<Button
							kind="primary"
							onClick={() => handleSaveUserData()}
							disabled={
								isEditableModal &&
                				props?.userModalContext?.role === userData.role
							}
						>
            				{t('userPopup.save') || ''}
						</Button>
					</div>
				}
			>
				<div className="user-form-wrapper">
					<InputField
						key={'email'}
						name={'email'}
						title={t('userPopup.email')}
						readonly={!!isEditableModal}
						required={true}
						userFormError={userFormError}
						handleInputBlur={handleInputBlur}
						userData={userData}
						handleInputChange={handleInputChange}
					/>
					<InputField
						key={'name'}
						name={'name'}
						title={t('userPopup.name')}
						readonly={true}
						userData={userData}
					/>
					<DropdownField
						name={'role'}
						title={t('userPopup.role')}
						required={true}
						userFormError={userFormError}
						handleInputBlur={handleInputBlur}
						handleInputChange={handleInputChange}
						userData={userData}
						dropdownValues={userRoles}
					></DropdownField>
					<InputField
						key={'office'}
						name={'office'}
						title={t('userPopup.office')}
						readonly={true}
						userData={userData}
					/>
				</div>
			</Modal>
			<UnexpectedErrorModal
				visible={showUnexpectedErrorModal}
				onClose={closeUnexpectedErrorModal}
				message={unexpectedError}
				error={''}
				statusCode={errorCode}
			/>
		</>
	);
};
