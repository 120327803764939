const ExemptAndTaxableEarningsFields = {
	'header': [
		{
			'name': 'status',
			'type': 'status',
			'background': 'section-top-border'
		},
		{
			'name': 'taxYearEnd',
			'type': 'taxYearEnd'
		},
		{
			'name': 'countryId',
			'type': 'countryId'
		},
		{
			'name': 'calculatingCurrencyId',
			'type': 'currencyId'
		}
	],
	'details': [
		{
			'name': 'exemptEarnings-title',
			'type': 'title'
		},
		{
			'name': 'exemptEarnings.abiEarnings',
			'type': 'currency'
		},
		{
			'name': 'exemptEarnings.capitalGainEP',
			'type': 'currency'
		},
		{
			'name': 'exemptEarnings.capitalGainOther',
			'type': 'currency'
		},
		{
			'name': 'exemptEarnings.deemedABI',
			'type': 'currency'
		},
		{
			'name': 'exemptEarnings.lessTaxesAllocatedToExemptEarnings',
			'type': 'currency'
		},
		{
			'name': 'exemptEarnings.total',
			'type': 'currency',
			'background': 'highlight'
		},
		{
			'name': '',
			'type': 'separator fixed'
		},
		{
			'name': 'taxableEarnings-title',
			'type': 'title'
		},
		{
			'name': 'taxableEarnings.abiEarnings',
			'type': 'currency'
		},
		{
			'name': 'taxableEarnings.capitalGainNonEP',
			'type': 'currency'
		},
		{
			'name': 'taxableEarnings.capitalGainEP',
			'type': 'currency'
		},
		{
			'name': 'taxableEarnings.capitalGainEPShares',
			'type': 'currency'
		},
		{
			'name': 'taxableEarnings.fapi',
			'type': 'currency'
		},
		{
			'name': 'taxableEarnings.deemedABI',
			'type': 'currency'
		},
		{
			'name': 'taxableEarnings.otherTaxableEarnings',
			'type': 'currency'
		},
		{
			'name': 'taxableEarnings.lessTaxesAllocatedToTaxableEarnings',
			'type': 'currency'
		},
		{
			'name': 'taxableEarnings.lessTaxesFAPI',
			'type': 'currency'
		},
		{
			'name': 'taxableEarnings.total',
			'type': 'currency',
			'background': 'highlight'
		}
	]
};

export default ExemptAndTaxableEarningsFields;