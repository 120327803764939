export enum DividendPaidReportFields {
	Summary= 'summary',
	DividendPayments= 'dividendPayments',
	IsPost90DayDividend= 'isPost90DayDividend',
	PreAcquisitionSurplus= 'preAcquisitionSurplus',
	DividendAmount= 'dividendAmount',
	AdditionalUFT= 'additionalUFT',
	DividendAllocation= 'dividendAllocation',
	SurplusBalanceBeforeDividend= 'surplusBalanceBeforeDividend',
	SurplusBalanceBefore90DayAdjustment= 'surplusBalanceBefore90DayAdjustment',
	Post90DayDividendAllocation= 'post90DayDividendAllocation',
	TotalDividendAllocation= 'totalDividendAllocation',
	PreAcquisitionSurpluses= 'preAcquisitionSurpluses',
	ElectionDividends = 'electionDividends',
	UpdatedPreAcquisitionSurpluses= 'updatedPreAcquisitionSurpluses',
	UpdatedElectionDividends = 'updatedElectionDividends',
	TotalPreAcquisitionSurplusBalance = 'totalPreAcquisitionSurplusBalance',
	TotalElectionDividendsBalance = 'totalElectionDividendsBalance'
}