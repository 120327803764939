import {
	IABIComputedUnderCanadianRulesRow
} from 'src/common/types/interfaces/IABIComputedUnderCanadianRules';

export const gridIds = {
	header: 'headerTable',
	netIncomeHeader: 'NetIncomeHeaderTable',
	permanentDifferences: 'PermanentDifferencesTable',
	temporaryDifferences: 'TemporaryDifferencesTable',
	aggregateTable: 'AggregateTable'
};

export type ValuesByYear = {
	[yearEnd: string]: IABIComputedUnderCanadianRulesRow[]
}

export type ValuesByAffiliateId = {
	[affiliateId: number]: IABIComputedUnderCanadianRulesRow[]
}

export type ValuesByDescriptionYear = {
	[descriptionId: string]: ValuesByYear
}

export type RowsByGridType = { [gridId: string]: IABIComputedUnderCanadianRulesRow[][] }
export type RecordsByGridType = { [gridId: string]: ValuesByAffiliateId }

export const initialRowData: RowsByGridType = {

	[gridIds.netIncomeHeader]: [],
	[gridIds.permanentDifferences]: [],
	[gridIds.temporaryDifferences]: []
};

export const initialRecordData: RecordsByGridType = {

	[gridIds.netIncomeHeader]: {
	},
	[gridIds.permanentDifferences]: {
	},
	[gridIds.temporaryDifferences]: {
	}
};