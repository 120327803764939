import React, { useEffect, useState } from 'react';

import './EngagementDetail.scss';
import { Loading } from '@appkit4/react-components';
import {IEngagementDetail} from 'src/features/engagement/models/IEngagementDetail';
import EngagementForm from './EngagementForm/EngagementForm';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { ActionEnum } from 'src/common/types/enums/ActionEnum';
import { EngagementStepEnum } from '../../../models/EngagementStepEnum';
import { cloneDeep, isEqual } from 'lodash';
import { Utils } from 'src/common/utils/utils';

const initEngagementDetail: IEngagementDetail = {
	id: 0,
	clientId: 0,
	name: '',
	taxPayerId: 0,
	status: '',
	lastCalculationTaxationYearEnd: undefined,
	deliveryDate: undefined
};

const defaultFormError = {
	clientName: null,
	engagementName: null,
	taxPayer: null,
	label: null,
	lastCalculationTaxationYearEnd: null,
	deliveryDate: null
};

const EngagementDetail = (props: {
  clientId: number | undefined;
  clientName: string;
  engagementDetail: IEngagementDetail | undefined;
  engagementId: number | undefined;
  currentStep: number;
  action: ActionEnum;
  onAction: (action: ActionEnum, result: boolean) => void;
  onEngagementCreated: (clientId: number|undefined, clientName: string, engagementId: number) => void;
}) =>
{
	const [loading, setLoading] = React.useState<boolean>(false);
	const [engagement, setEngagement] = React.useState<IEngagementDetail>(initEngagementDetail);
	const [originalEngagementData, setOriginalEngagementData] = React.useState<IEngagementDetail>(initEngagementDetail);
	const [engagementFormError, setEngagementFormError] = React.useState<Record<string, any>>(defaultFormError);
	const [clientId, setClientId] = useState<number|undefined>(undefined);
	const [clientName, setClientName] = useState<string>('');

	useEffect(() =>
	{
		if (!!props.engagementDetail)
		{
			setEngagement(props.engagementDetail);
			setOriginalEngagementData(cloneDeep(props.engagementDetail));
		}
	}, [props.engagementDetail]);

	useEffect(() =>
	{
		setClientId(props.clientId);
	}, [props.clientId]);

	useEffect(() =>
	{
		setClientName(props.clientName);
	}, [props.clientName]);

	const handleChange = (name: any, val: any, errorParamName?: string) =>
	{
		if (!!errorParamName)
		{
			if (name === 'taxPayerId')
			{
				setEngagementFormError({
					...engagementFormError,
					taxPayer: null
				});
			}
			else
			{
				setEngagementFormError({
					...engagementFormError,
					[errorParamName]: null
				});
			}
		}

		setEngagement((engagement) => ({
			...engagement,
			[name]: val
		}));
	};

	const handleClientChange = (clientId: number, clientName: string) =>
	{
		setClientId(clientId);
		setClientName(clientName);
	};


	const onSave = (status: string) =>
	{
		if (isEqual(engagement, originalEngagementData)&&Utils.areAllPropertiesPopulated(engagement))
		{
			props.onAction(props.action, true);
			return;
		}

		engagement.status = status;
		setLoading(true);
		engagementService
			.saveEngagement(engagement)
			.then((res: any) =>
			{
				setLoading(false);
				setEngagement((engagement) => ({
					...engagement,
					id: res.data.id
				}));
				props.onEngagementCreated(clientId, clientName, res.data.id);
				setTimeout(() =>
				{
					props.onAction(props.action, true);
				}, 200);
			})
			.catch((err: Record<string, any>) =>
			{
				setLoading(false);
				props.onAction(props.action, false);
				const {response: {data: { Errors, Status }}} = err;
				const apiErrors = {
					clientName: null,
					engagementName: null,
					taxPayer: null,
					label: null,
					lastCalculationTaxationYearEnd: null,
					deliveryDate: null
				};

				if (Status === 400)
				{
					apiErrors.clientName =
            Errors.filter((x: any) => x['Field'] === 'client')?.[0]?.[
            	'Message'
            ] || null;
					apiErrors.engagementName =
            Errors.filter((x: any) => x['Field'] === 'name')?.[0]?.[
            	'Message'
            ] || null;
					apiErrors.taxPayer =
            Errors.filter((x: any) => x['Field'] === 'taxPayer')?.[0]?.[
            	'Message'
            ] || null;
					apiErrors.lastCalculationTaxationYearEnd =
            Errors.filter(
            	(x: any) => x['Field'] === 'lastCalculationTaxationYearEnd'
            )?.[0]?.['Message'] || null;
					apiErrors.deliveryDate =
            Errors.filter((x: any) => x['Field'] === 'deliveryDate')?.[0]?.[
            	'Message'
            ] || null;
				}
				setEngagementFormError({
					...apiErrors
				});
			});
	};

	useEffect(() =>
	{
		if (props.currentStep === EngagementStepEnum.EngagementDetails)
		{
			switch (props.action)
			{
			case ActionEnum.Next:
				onSave('Ready');
				break;
			case ActionEnum.Back:
				props.onAction(props.action, true);
				break;
			case ActionEnum.Draft:
				onSave('Draft');
				break;
			}
		}
	}, [props.action]);

	return (
		<div className="engagement-detail">
			{loading && (
				<div className="loading-icon">
					<Loading
						loadingType="circular"
						indeterminate={true}
						compact={false}
					/>
				</div>
			)}
			<EngagementForm
				engagementFormError={engagementFormError}
				engagementDetail={engagement}
				onChange={handleChange}
				onClientChange={handleClientChange}
			></EngagementForm>
		</div>
	);
};

export default EngagementDetail;
