import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IPropertyIncomeHeaderTableProps } from './IPropertyIncomeHeaderTableProps';
import { Loading, Switch } from '@appkit4/react-components';
import { useAppSelector } from 'src/store/store';
import { dateFormatter } from 'src/common/utils/dateFormatter';

import './PropertyIncomeHeaderTable.scss';

export function PropertyIncomeHeaderTable(props: IPropertyIncomeHeaderTableProps): JSX.Element
{
	const countries = useAppSelector((state) => state.appSettings.countries);
	const currencies = useAppSelector((state) => state.appSettings.currencies);

	const [displayNotes, setDisplayNotes] = useState(false);

	const { t } = useTranslation(
		'common',
		{
			keyPrefix: 'propertyIncomeHeaderTable'
		}
	);

	const onNotesToggle = (value: boolean) =>
	{
		setDisplayNotes(!!value);
		props.onToggleNotes(!!value);
	};

	return <div className={'property-income-header-table'}>
		<div className={'title-area'}>
			<div className={'title'}>
				{
					t('title')
				}
			</div>
			<div className={'toggle'}>
				<Switch
					className={'switch-label'}
					checked={displayNotes}
					disabled={!!props.isLoading || !props.data}
					onChange={onNotesToggle}
				>
					{
						t('toggleNotesLabel')
					}
				</Switch>
			</div>
		</div>
		{
			!!props.isLoading &&
			<div className={'loading-data'}>
				<Loading
					indeterminate
					loadingType={'circular'}
				/>
				{
					t('loadingDataMessage')
				}
			</div>
		}
		<div
			ref={props.customRef}
			className={'table-scroll-container'}
			style={{
				visibility: props.isLoading ? 'hidden' : 'visible'
			}}
		>
			<table id={props.id}>
				<tbody>
					<tr>
						<td className={'first-column tax-year'}>
							{
								t('faYearTitle')
							}
						</td>
						{
							!!props.data &&
							!!props.data.length &&
							props.data
								.map((d, index, allData) =>
								{
									return <td
										key={d.affiliateTaxYearEndId}
										className={`content-column header ${!!displayNotes ? 'has-notes' : ''}`}
									>
										{
											dateFormatter.conditionallyFormatDateWithTime('faYear', d, index, allData)
										}
									</td>;
								})
						}
					</tr>
					<tr>
						<td className={'first-column country-currency'}>
							<div>
								{
									t('countryTitle')
								}
							</div>
							<div>
								{
									t('calculatingCurrencyTitle')
								}
							</div>
						</td>
						{
							!!props.data &&
							!!props.data.length &&
							props.data
								.map((d) =>
								{
									return <td
										key={d.affiliateTaxYearEndId}
										className={`content-column ${!!displayNotes ? 'has-notes' : ''}`}
									>
										<div>
											{
												!!countries &&
												!!countries.length &&
												!!countries.some((c) => c.id === d.countryId) ?
													countries.find((c) => c.id === d.countryId)?.name :
													''
											}
										</div>
										<div>
											{
												!!currencies &&
												!!currencies.length &&
												!!currencies.some((c) => c.id === d.calculatingCurrencyId) ?
													currencies.find((c) => c.id === d.calculatingCurrencyId)?.code :
													''
											}
										</div>
									</td>;
								})
						}
					</tr>
				</tbody>
			</table>
		</div>
	</div>;
}