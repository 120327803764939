import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiService } from 'src/common/services/apiService';
import { IInputForm } from 'src/common/types/interfaces/IInputForm';
import { IAppSettingsState } from 'src/common/types/interfaces/IAppSettingsState';
import { ICurrency } from 'src/common/types/interfaces/ICurrency';

export const fetchAppSettingsAsync = createAsyncThunk(
	'AppSettings/fetch',
	async (): Promise<IAppSettingsState> =>
	{
		const [roleResponse, officeResponse,countryResponse, calculationTypesResponse, currencyResponse,optionsResponse,
			equityClassesResponse,inputFormsResponse,engagementRoleResponse,surplusPoolResponse] = await Promise.all(
			[apiService.getSystemRoles(),
				apiService.getAllOffices(),
				apiService.loadCountries(),
				apiService.loadCalculationTypes(),
				apiService.loadCurrencies(),
				apiService.loadOptions(),
				apiService.loadEquityClasses(),
				apiService.loadInputForms(),
				apiService.loadEngagementRoles(),
				apiService.loadSurplusPoolData()]
		);
		const roles = roleResponse.data.result.map((item: any) => item.name);
		const offices = officeResponse.data.result;
		const currencies = currencyResponse.data.result.map((item: any) =>
		{
			return {
				code: item.code,
				name: item.name,
				id: item.id
			};
		});
		const countries = countryResponse.data.result.map((item: any) =>
		{
			return {
				name: item.name,
				id: item.id
			};
		});
		const calculationTypes = calculationTypesResponse.data.result.map((item: any) =>
		{
			return {
				description: item.description,
				internalName: item.internalName,
				code: item.code,
				id: item.id
			};
		});
		const options = optionsResponse.data.result.map((item: any) =>
		{
			return {
				field: item.field,
				label: item.label,
				value: item.value
			};
		});

		const allEquityClasses = equityClassesResponse.data.result
			.map((item: any) =>
			{
				return {
					name: item.name,
					id: item.id
				};
			})
			.sort((a: any,b: any) =>
			{
				if (a.name < b.name)
				{
					return -1;
				} if (a.name>b.name)
				{
					return 1;
				} return 0;
			});

		const equityClasses = allEquityClasses.filter((item: any) => !item.isHidden);

		const forms = inputFormsResponse.data as IInputForm[];
		const engagementRoles = engagementRoleResponse.data.result;
		const surplusPool = surplusPoolResponse.data.result
			.map((item: any) =>
			{
				return {
					internalName: item.internalName,
					name: item.type,
					id: item.id
				};
			});

		const taxpayerCurrencies: ICurrency[] = process.env.REACT_APP_TAXPAYER_CURRENCIES?.split(',')
			.map((taxpayCurrency: any) =>
			{
				const c = currencies.find((c: any) => c.code===taxpayCurrency);
				return c? c : null;
			})!;

		const appSettings: IAppSettingsState = {
			roles: roles,
			offices: offices,
			currencies: currencies,
			taxpayerCurrencies: taxpayerCurrencies,
			countries: countries,
			calculationTypes: calculationTypes,
			allEquityClasses: allEquityClasses,
			equityClasses: equityClasses,
			options: options,
			inputForms: forms,
			loading: false,
			error: undefined,
			engagementRoles: engagementRoles,
			surplusPool: surplusPool
		};
		return appSettings;
	}
);

const initialState: IAppSettingsState  = {
	roles: [],
	offices: [],
	currencies: [],
	taxpayerCurrencies: [],
	countries: [],
	calculationTypes: [],
	allEquityClasses: [],
	equityClasses: [],
	options: [],
	inputForms: [],
	loading: false,
	error: undefined,
	engagementRoles: [],
	surplusPool: []
};

export const AppSettingsSlice = createSlice({
	name: 'AppSettings',
	initialState,
	reducers: {

	},
	extraReducers: (builder) =>
	{
		builder
			.addCase(fetchAppSettingsAsync.pending, (state) =>
			{
				state.loading = true;
			})
			.addCase(fetchAppSettingsAsync.fulfilled, (state, action: PayloadAction<IAppSettingsState>) =>
			{
				state.loading = false;
				state.roles = action.payload.roles;
				state.offices = action.payload.offices;
				state.currencies = action.payload.currencies;
				state.taxpayerCurrencies = action.payload.taxpayerCurrencies;
				state.countries = action.payload.countries;
				state.calculationTypes = action.payload.calculationTypes;
				state.allEquityClasses = action.payload.allEquityClasses;
				state.equityClasses = action.payload.equityClasses;
				state.options = action.payload.options;
				state.inputForms = action.payload.inputForms;
				state.engagementRoles = action.payload.engagementRoles;
				state.surplusPool = action.payload.surplusPool;
			})
			.addCase(fetchAppSettingsAsync.rejected, (state, action) =>
			{
				state.loading = false;
				state.error = action.error.message;
			})
		;
	}
});


export default AppSettingsSlice.reducer;
