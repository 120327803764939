import {
	Grid,
	GridCellProps,
	GridColumn,
	GridNoRecords
} from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useContext, useEffect, useState } from 'react';
import { IOpeningBalance } from 'src/features/engagement/models/IOpeningBalance';
import { DropdownEditorCell } from 'src/common/components/Editors/DropdownEditor/DropdownEditorCell';
import { CurrencyEditorCell } from 'src/common/components/Editors/CurrencyEditor/CurrencyEditorCell';
import { useAppSelector } from 'src/store/store';

import './OpeningBalance.scss';
import { Button } from '@appkit4/react-components';
import { UnsavedChangesModal } from 'src/core/components/Dashboard/unsavedModal/UnsavedChangesModal';
import { useTranslation } from 'react-i18next';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { Utils } from 'src/common/utils/utils';
import { NumberTypeEnum } from 'src/common/types/enums/NumberTypeEnum';
import AffiliateContext from 'src/common/components/SharedDataContext/AffiliateContext';

export const OpeningBalance = () =>
{
	const affiliateContext = useContext(AffiliateContext);

	const initOpeningBalance: IOpeningBalance = {
		id: 0,
		currency: '',
		exemptSurplus: '',
		hybridSurplus: '',
		underlyingTax: '',
		TaxableSurplus: '',
		foreignTax: '',
		inEditableMode: true,
		isNew: true
	};

	const initialErrors = {
		clientSideErr: {
			currency: '',
			exemptSurplus: '',
			hybridSurplus: '',
			underlyingTax: '',
			TaxableSurplus: '',
			foreignTax: ''
		},
		apiErr: {
			faName: ''
		}
	};

	const defaultErrors = {
		clientSideErr: {
			currency: 'Please select currency',
			exemptSurplus: 'Please enter a valid number',
			hybridSurplus: 'Please enter a valid number',
			underlyingTax: 'Please enter a valid number',
			TaxableSurplus: 'Please enter a valid number',
			foreignTax: 'Please enter a valid number'
		},
		apiErr: {
			faName: null
		}
	};

	const editField: string = 'inEdit';

	const currencies = useAppSelector((state) => state.appSettings.currencies);

	const [changed, setChanged] = useState<boolean>(false);

	const [openingBalances, setOpeningBalances] = useState<IOpeningBalance[]>([]);
	const [currentOpeningBalance, setCurrentOpeningBalance] = useState<
    IOpeningBalance | undefined
  >(undefined);
	const [showUnsavedModal, setShowUnsavedModal] = useState<boolean>(false);

	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		field: string,
		value: any
	) =>
	{
		const currentlyEditedRow = openingBalances.filter(
			(r) => r.inEditableMode === true
		)?.[0] as any;
		currentlyEditedRow[`${field}`] = value;
		if (!changed)
		{
			setChanged(true);
		}
	};

	const [error, setError] = useState<any>(undefined);

	const { t } = useTranslation('homeDashboard');

	const handleRowRender = (
		trElement: React.ReactElement<HTMLTableRowElement>,
		row: any
	) =>
	{
		if (row.dataItem.inEditableMode)
		{
			const color = {
				backgroundColor: '#F3F3F3'
			};
			const trProps: any = {
				style: color
			};
			return React.cloneElement(trElement, {
				...trProps
			});
		}
		else
		{
			const color = {
				backgroundColor: 'white'
			};
			const trProps: any = {
				style: color
			};
			return React.cloneElement(trElement, {
				...trProps
			});
		}
	};

	useEffect(() =>
	{
		if (affiliateContext&&affiliateContext.engagementDetail.id&&affiliateContext.affiliateDetail.affiliateId)
		{
			engagementService
				.getOpeningBalances(
					affiliateContext.engagementDetail.id,
					affiliateContext.affiliateDetail.affiliateId
				)
				.then((res: any) =>
				{
					if (res.data.result)
					{
						const obs = res.data.result.map((item: any) =>
						{
							const op: IOpeningBalance = {
								id: item.surplusId,
								currency: item.calculatingCurrencyId,
								exemptSurplus: item.openingExemptSurplus,
								hybridSurplus: item.openingHybridSurplus,
								underlyingTax: item.openingHybridUnderlyingTax,
								TaxableSurplus: item.openingTaxableSurplus,
								foreignTax: item.openingUnderlyingForeignTax,
								inEditableMode: false,
								isNew: false
							};
							return op;
						});
						setOpeningBalances(obs);
					}
				});
		}
	}, [affiliateContext]);

	const handleInputClick = (field: string, event: any) =>
	{};

	const handleRowClick = async (field: string, event: any) =>
	{};

	const addOpeningBalance = () =>
	{
		if (OpeningBalance.length === 0)
		{
			setOpeningBalances([...openingBalances, initOpeningBalance]);
		}
	};

	const handleSave = () =>
	{
		const validated = ValidateOpeningBalance();
		if (
			validated &&
			affiliateContext?.engagementDetail.id &&
      affiliateContext?.affiliateDetail.affiliateId
		)
		{
			const newItem = openingBalances.find((item: any) => item.isNew === true);
			if (newItem)
			{
				engagementService
					.createOpeningBalance(
						affiliateContext.engagementDetail.id,
						affiliateContext.affiliateDetail.affiliateId,
						newItem
					)
					.then((res) =>
					{
						const updatedItem = {
							...newItem,
							id: res.data.surplusId,
							inEditableMode: false,
							isNew: false
						};
						setOpeningBalances([updatedItem]);
					});
			}
			else
			{
				const editingItem = openingBalances.find(
					(item: any) => item.inEditableMode === true
				);
				if (editingItem)
				{
					engagementService
						.updateOpeningBalance(
							affiliateContext.engagementDetail.id,
							affiliateContext.affiliateDetail.affiliateId,
							editingItem
						)
						.then((res) =>
						{});
				}
			}
			const updatedOpeningBalances = openingBalances.map((item: any) =>
			{
				if (item.inEditableMode === true)
				{
					return {
						...item,
						inEditableMode: false,
						isNew: false
					};
				}
				else
				{
					return item;
				}
			});
			setOpeningBalances(updatedOpeningBalances);
		}
	};

	const confirmCancel = () =>
	{
		if (!changed)
		{
			handleCancel();
		}
		else
		{
			setShowUnsavedModal(true);
		}
	};

	const handleCancel = () =>
	{
		const newItem = openingBalances.find((item: any) => item.isNew === true);
		setError(undefined);
		setChanged(false);
		if (newItem)
		{
			setOpeningBalances([]);
		}
		else
		{
			if (currentOpeningBalance)
			{
				setOpeningBalances([currentOpeningBalance]);
				setCurrentOpeningBalance(undefined);
			}
		}
	};

	const handleEdit = (event: any) =>
	{
		const current = openingBalances.find(
			(item: any) => item.id === event.id
		);
		const updatedOpeningBalances = openingBalances.map((item: any) =>
		{
			if (item.id === event.id)
			{
				return {
					...item,
					inEditableMode: true,
					isNew: false
				};
			}
			else
			{
				return item;
			}
		});
		setChanged(false);
		setOpeningBalances(updatedOpeningBalances);
		setCurrentOpeningBalance(current);
	};

	const closeUnsavedModal = (str: string) =>
	{
		if (str === 'stay')
		{
			setShowUnsavedModal(false);
		}
		else if (str === 'leave')
		{
			setShowUnsavedModal(false);
			handleCancel();
		}
	};

	const ValidateOpeningBalance = () =>
	{
		const validationError = {
			...initialErrors
		};
		if (openingBalances.length === 0)
		{
			return true;
		}
		const openingBalance = openingBalances[0];

		validationError.clientSideErr.currency = !openingBalance.currency ?
			defaultErrors.clientSideErr.currency :
			'';
		validationError.clientSideErr.exemptSurplus = !Utils.isValidNumber(
			openingBalance.exemptSurplus
		) ?
			defaultErrors.clientSideErr.exemptSurplus :
			'';
		validationError.clientSideErr.hybridSurplus = !Utils.isValidNumber(
			openingBalance.hybridSurplus
		) ?
			defaultErrors.clientSideErr.hybridSurplus :
			'';
		validationError.clientSideErr.underlyingTax = !Utils.isValidNumber(
			openingBalance.underlyingTax
		) ?
			defaultErrors.clientSideErr.underlyingTax :
			'';
		validationError.clientSideErr.TaxableSurplus = !Utils.isValidNumber(
			openingBalance.TaxableSurplus
		) ?
			defaultErrors.clientSideErr.TaxableSurplus :
			'';
		validationError.clientSideErr.foreignTax = !Utils.isValidNumber(
			openingBalance.foreignTax
		) ?
			defaultErrors.clientSideErr.foreignTax :
			'';

		if (
			!openingBalance.currency ||
      !Utils.isValidNumber(openingBalance.exemptSurplus) ||
      !Utils.isValidNumber(openingBalance.hybridSurplus) ||
      !Utils.isValidNumber(openingBalance.underlyingTax) ||
      !Utils.isValidNumber(openingBalance.TaxableSurplus) ||
      !Utils.isValidNumber(openingBalance.foreignTax)
		)
		{
			setError(validationError);
			return false;
		}
		else
		{
			setError(undefined);
			return true;
		}
	};

	const ActionsButton = (params: GridCellProps) =>
	{
		const [inEditableMode, setInEditableMode] = useState<boolean>(false);
		const [, setDisableIcon] = useState<boolean>(false);

		useEffect(() =>
		{
			setInEditableMode(params.dataItem?.inEditableMode);
			setDisableIcon(false);
		}, [params.dataItem]);

		return inEditableMode ? (
			<td className="k-command-cell">
				<div className="custom-cell">
					<div className="action-buttons">
						<Button
							kind="primary"
							disabled={!changed && currentOpeningBalance}
							onClick={handleSave}
						>
              				Save
						</Button>
						<Button kind="tertiary" onClick={confirmCancel}>
              				Cancel
						</Button>
					</div>
					{error && <div className="custom-cell-error">&nbsp;</div>}
				</div>
			</td>
		) : (
			<td className="k-command-cell">
				<div className="custom-cell">
					<div className="action-buttons">
						<span
							className="Appkit4-icon icon-pencil-outline"
							onClick={() => handleEdit(params.dataItem)}
						></span>
						<span className="Appkit4-icon icon-circle-delete-outline disabled-icon"></span>
					</div>
				</div>
			</td>
		);
	};

	return (
		<div className="opening-balance-container">
			<div>
				<LocalizationProvider language="en-user">
					<IntlProvider locale="en">
						<Grid
							className={'fa-input-grid'}
							data={openingBalances}
							editField={editField}
							selectable={{
								enabled: true,
								drag: false,
								cell: false,
								mode: 'single'
							}}
							rowRender={handleRowRender}
							sortable={{
								allowUnsort: false
							}}
						>
							{/* <GridToolbar></GridToolbar> */}
							<GridNoRecords>
								<div className="link-add">
									{openingBalances.length === 0 && (
										<Button onClick={addOpeningBalance} kind={'text'}>
											<span className="Appkit4-icon icon-plus-outline"></span>{' '}
                      Add Opening Balance for the First Calculation Year
										</Button>
									)}
								</div>
							</GridNoRecords>

							<GridColumn
								field="currency"
								title={'Calculating Currency'}
								sortable={true}
								cell={(params) =>
									DropdownEditorCell({
										...params,
										fieldErrors: error,
										dropdownValues: currencies,
										labelKey: 'code',
										valueKey: 'id',
										handleInputChange,
										handleRowClick,
										handleInputClick
									})
								}
							/>
							<GridColumn
								field="exemptSurplus"
								title={'Opening Exempt Surplus/(Deficit)'}
								editable={false}
								sortable={true}
								cell={(params) =>
									CurrencyEditorCell({
										...params,
										fieldErrors: error,
										numberType: NumberTypeEnum.Integer,
										handleInputChange
									})
								}
							/>
							<GridColumn
								field="hybridSurplus"
								title={'Opening Hybrid Surplus/(Deficit)'}
								editable={false}
								sortable={true}
								cell={(params) =>
									CurrencyEditorCell({
										...params,
										fieldErrors: error,
										numberType: NumberTypeEnum.Integer,
										handleInputChange
									})
								}
							/>
							<GridColumn
								field="underlyingTax"
								title={'Opening Hybrid Underlying Tax'}
								editable={false}
								sortable={true}
								cell={(params) =>
									CurrencyEditorCell({
										...params,
										fieldErrors: error,
										numberType: NumberTypeEnum.WholeNumber,
										handleInputChange
									})
								}
							/>
							<GridColumn
								field="TaxableSurplus"
								title={'Opening Taxable Surplus/(Deficit)'}
								editable={false}
								sortable={true}
								headerClassName={'FAgrid-header-cell1'}
								cell={(params) =>
									CurrencyEditorCell({
										...params,
										fieldErrors: error,
										numberType: NumberTypeEnum.Integer,
										handleInputChange
									})
								}
							/>
							<GridColumn
								field="foreignTax"
								title={'Opening Underlying Foreign Tax'}
								editable={false}
								sortable={true}
								cell={(params) =>
									CurrencyEditorCell({
										...params,
										fieldErrors: error,
										numberType: NumberTypeEnum.WholeNumber,
										handleInputChange
									})
								}
							/>
							<GridColumn
								title={''}
								className={'FAgrid-icons'}
								sortable={false}
								cell={(params) => ActionsButton({
									...params
								})}
							/>
						</Grid>
					</IntlProvider>
				</LocalizationProvider>
			</div>

			<UnsavedChangesModal
				visible={showUnsavedModal}
				onClose={closeUnsavedModal}
				title={t('unsaved-popup.discardChangesTitle') || ''}
			/>
		</div>
	);
};
