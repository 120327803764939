import { Select } from '@appkit4/react-components';
import { SelectValue } from '@appkit4/react-components/esm/select/Select';
interface DropdownFieldProps {
    title: string
    required?: boolean
    name: string
    dropdownValues: any
    userData: UserData
    handleInputChange?: Function
    handleInputBlur?: Function
    userFormError?: Record<string, any>
}

interface UserData {
    email: string,
    name: string,
    office: string,
    role: string
}

const DropdownField = (props: DropdownFieldProps) =>
{
	const {
		required,
		name,
		dropdownValues,
		userData,
		userFormError,
		handleInputChange
	} = props;

	const testData = dropdownValues.map((x: any) =>
	{
		return {
			value: x,
			label: x
		};
	});
	// @ts-ignore
	const dropdownVal: string = userData[`${name}`];

	return (
		<div>
			<Select
				data={testData}
				value={dropdownVal}
				placeholder={'Role'}
				dropdownAlwaysDown={false}
				onSelect={(value: SelectValue) => handleInputChange?.(null, name, value)}
				error={(!!userFormError && !!userFormError?.[name])}
				required={required}
				dropdownRenderMode="portal"
			/>
			{!!userFormError && !!userFormError?.[name] && (
				<div id="errormessage" aria-live="polite" className="ap-field-email-validation-error"
				> {!!userFormError && !!userFormError?.[name] ? props?.userFormError?.[name] : ''}
				</div>
			)
			}</div>
	);
};

export default DropdownField;