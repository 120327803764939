export enum DividendReceivedReportFields {
	Summary = 'summary',
	DividendPayers = 'dividendPayers',
	PayerName = 'payerName',
	PayerAffiliateId = 'payerAffiliateId',
	PaymentsCount = 'paymentsCount',
	TotalAmountPaid = 'totalAmountPaid',
	OutsideOfThisEngagementFaId = 'outsideEngFaId',
	TotalDividendsReceivedAmounts = 'totalDividendsReceivedAmounts',
	WithholdingTax = 'withholdingTax'
}