const CapitalGainsReportFields = {
	'header': [
		{
			'name': 'status',
			'type': 'status',
			'background': 'section-top-border'
		},
		{
			'name': 'taxYearEnd',
			'type': 'taxYearEnd'
		},
		{
			'name': 'countryId',
			'type': 'countryId'
		},
		{
			'name': 'calculatingCurrencyId',
			'type': 'currencyId'
		}
	],
	'summary': [
		{
			'name': 'summary-title',
			'type': 'title'
		},
		{
			'name': 'subTotal.totalCapitalGain',
			'type': 'currency',
			'background': 'highlight'
		},
		{
			'name': 'subTotal.totalExemptEarnings',
			'type': 'currency'
		},
		{
			'name': 'subTotal.totalHybridSurplus',
			'type': 'currency'
		},
		{
			'name': 'subTotal.totalTaxableEarnings',
			'type': 'currency'
		},
		{
			'name': 'subTotal.totalForeignTaxesPaid',
			'type': 'currency'
		}
	],
	'details': [
		{
			'name': 'detail-title',
			'type': 'section'
		},
		{
			'name': 'type',
			'type': 'dispositionType'
		},
		{
			'name': 'description',
			'type': 'text'
		},
		{
			'name': 'isDepreciableProperty',
			'type': 'yesno'
		},
		{
			'name': 'dateOfAcquisition',
			'type': 'date'
		},
		{
			'name': 'dateOfDisposition',
			'type': 'date'
		},
		{
			'name': 'calculations-title',
			'type': 'subtitle'
		},
		// {
		// 	'name': 'calculations.calculatingCurrencyId',
		// 	'type': 'CurrencyId'
		// },
		{
			'name': 'calculations.proceedsOfDisposition',
			'type': 'currency'
		},
		{
			'name': 'calculations.cost',
			'type': 'currency'
		},
		{
			'name': 'calculations.expenses',
			'type': 'currency'
		},
		{
			'name': 'calculations.otherAdjustments',
			'type': 'currency'
		},
		{
			'name': 'calculations.preAcquisitionAmount',
			'type': 'currency'
		},
		{
			'name': 'calculations.capitalGain',
			'type': 'currency',
			'background': 'highlight fixed'
		},
		{
			'name': 'notes',
			'type': 'text'
		},
		{
			'name': 'allocations-title',
			'type': 'subtitle'
		},
		{
			'name': 'allocations.exemptEarnings',
			'type': 'currency'
		},
		{
			'name': 'allocations.hybridSurplus',
			'type': 'currency'
		},
		{
			'name': 'allocations.taxableEarnings',
			'type': 'currency'
		},
		{
			'name': 'allocations.foreignTaxesPaid',
			'type': 'currency'
		}
	]
};

export default CapitalGainsReportFields;