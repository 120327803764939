export enum CapitalGainsDispositionFields {

	id = 'id',
	engagementId = 'engagementId',
	affiliateId = 'affiliateId',
	type = 'type',
	description = 'description',
	isDepreciableProperty = 'isDepreciableProperty',
	dateOfAcquisition = 'dateOfAcquisition',
	dateOfDisposition = 'dateOfDisposition',
	timeOfDisposition = 'timeOfDisposition',
	transactionCurrencyId = 'transactionCurrencyId',
	proceedsOfDisposition = 'proceedsOfDisposition',
	electionAmount = 'electionAmount',
	cost = 'cost',
	expenses = 'expenses',
	otherAdjustments = 'otherAdjustments',
	preAcquisitionAmount = 'preAcquisitionAmount',
	calculatedCapitalGainOrLoss = 'calculatedCapitalGainOrLoss',
	taxPaidOnDisposition = 'taxPaidOnDisposition',
	notes = 'notes'
}