import './ForeignTaxableIncomePropertyRow.scss';
import { NumericInputControl } from 'src/common/components/GridControls';
import { Utils } from 'src/common/utils/utils';
import { ForeignTaxableIncomeFieldNames } from 'src/common/types/enums/ForeignTaxableIncomeTypes';
import { IForeignTaxableIncomeRow, IForeignTaxableIncomeYear } from 'src/common/types/interfaces/IForeignTaxableIncome';

export interface IForeignTaxableIncomePropertyRowProps {

	id?: string;
	customRef: any;
	validate: boolean;
	isLoading?: boolean;
	isReadOnly?: boolean;
	isDisabled: boolean;
	title: string;
	displayNotes?: boolean;
	data: IForeignTaxableIncomeYear[];
	propertyRow: IForeignTaxableIncomeRow[];
	displayScrollbar?: boolean;

	onValueUpdate: (field: ForeignTaxableIncomeFieldNames, index: number, value: string) => void;
}

export function ForeignTaxableIncomePropertyRow(props: IForeignTaxableIncomePropertyRowProps): JSX.Element
{
	return <div className={'foregin-taxable-income-property-table'}>
		<div
			ref={props.customRef}
			className={`table-scroll-container ${!!props.displayScrollbar ? 'ignore' : ''}`}
			style={{
				visibility: props.isLoading ? 'hidden' : 'visible'
			}}
		>
			<table id={props.id}>
				<tbody>
					<tr>
						<td className={'action-column'} />
						<td className={'first-column country-currency value'}>
							<div>
								{props.title}
							</div>
						</td>
						{
							!!props.propertyRow &&
							!!props.propertyRow.length &&
							props.propertyRow
								.map((d, index) =>
								{
									return (
										<>
											<td
												key={d.id}
												className={`amount-cell ${props.isReadOnly?'content-column':'value-column'} 
												${!!props.displayNotes ? 'has-notes' : ''}`}
											>
												{props.isReadOnly?<>
													{
													 `${d.amount===undefined? '':Utils.formatCurrency(`${d.amount}`, 2)}	`
													}
												</>:
													<NumericInputControl
														isDisabled={
															props.isLoading ||
											props.isDisabled
														}
														value={d.amount}
														isError={
															!!props.validate &&
											!!d.notes &&
											!Utils.isValidNumber(`${d.amount}`)
														}
														onValueChange={(value) =>
														{
															props.onValueUpdate(ForeignTaxableIncomeFieldNames.Amount, index, value);
														}}
													/>
												}

											</td>
											<td className={`content-column	${!!props.displayNotes ? 'has-notes' : ''}` }></td>
										</>
									);
								})
						}
					</tr>
				</tbody>
			</table>
		</div>
	</div>;
}