import { Utils } from 'src/common/utils/utils';

export enum ABIComputedUnderCanadianRulesLookupTypes {

	Permanent = 'Permanent',
	Temporary = 'Temporary',
	NetIncome = 'NetIncome'
}

export interface IABIComputedUnderCanadianRulesLookupRecord {

	id: number;
	differenceType: ABIComputedUnderCanadianRulesLookupTypes;
	description: string;
}

export interface IABIComputedUnderCanadianRulesRowBase {

	id?: number;
	rowIndex?: number;
	descriptionId: number;
	amount?: number;
	notes?: string;
}

export interface IABIComputedUnderCanadianRulesRow extends IABIComputedUnderCanadianRulesRowBase {

	taxYearEnd?: string;
	affiliateTaxYearEndId: number;
}

export class ABIComputedUnderCanadianRulesRow implements IABIComputedUnderCanadianRulesRow
{
	constructor (
		{
			descriptionId = 0,
			taxYearEnd = '',
			affiliateTaxYearEndId,
			rowIndex
		}:
		{
			descriptionId?: number;
			taxYearEnd: string;
			affiliateTaxYearEndId: number;
			rowIndex: number;
		}
	)
	{
		this.id = Utils.generateTempId();
		this.descriptionId = !!descriptionId ? descriptionId : 0;
		this.amount = undefined;
		this.taxYearEnd = taxYearEnd;
		this.notes = undefined;
		this.affiliateTaxYearEndId = affiliateTaxYearEndId;
		this.rowIndex = rowIndex;
	}

	id: number;
	descriptionId: number;
	amount?: number;
	notes?: string;
	taxYearEnd?: string;
	rowIndex: number;
	affiliateTaxYearEndId: number;
}

export enum ABIComputedUnderCanadianRulesSubmissionToken {

	NetIncome = 'netIncome',
	PermanentDifferences = 'permanentDifferences',
	TemporaryDifferences = 'temporaryDifferences'
}

export interface IABIComputedUnderCanadianRulesRecord {

	[ABIComputedUnderCanadianRulesSubmissionToken.NetIncome]: IABIComputedUnderCanadianRulesRow;
	[ABIComputedUnderCanadianRulesSubmissionToken.PermanentDifferences]: IABIComputedUnderCanadianRulesRow[];
	[ABIComputedUnderCanadianRulesSubmissionToken.TemporaryDifferences]: IABIComputedUnderCanadianRulesRow[];
}

export interface IABIComputedUnderCanadianRulesYear {

	taxYearEnd: string;
	countryId: number;
	calculatingCurrencyId: number;
	affiliateTaxYearEndId: number;
	[ABIComputedUnderCanadianRulesSubmissionToken.NetIncome]: IABIComputedUnderCanadianRulesRow;
	[ABIComputedUnderCanadianRulesSubmissionToken.PermanentDifferences]: IABIComputedUnderCanadianRulesRow[];
	[ABIComputedUnderCanadianRulesSubmissionToken.TemporaryDifferences]: IABIComputedUnderCanadianRulesRow[];
}

export interface IABIComputedUnderCanadianRulesYearSubmission {

	affiliateTaxYearEndId: number;
	[ABIComputedUnderCanadianRulesSubmissionToken.NetIncome]?: IABIComputedUnderCanadianRulesRowBase;
	[ABIComputedUnderCanadianRulesSubmissionToken.PermanentDifferences]: IABIComputedUnderCanadianRulesRowBase[];
	[ABIComputedUnderCanadianRulesSubmissionToken.TemporaryDifferences]: IABIComputedUnderCanadianRulesRowBase[];
}

export interface IABIComputedUnderCanadianRulesSubmission {

	engagementId: number;
	affiliateId: number;
	activeBusinessIncomes: IABIComputedUnderCanadianRulesYearSubmission[];
}