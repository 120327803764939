import React, { useEffect, useState } from 'react';
import { RelationshipList } from '../components/EngagementSetup/Relationships/RelationshipsList/RelationshipList';
import { CreateEngagementModal } from '../components/EngagementSetup/Relationships/CreateEngagement/CreateEngagementModal';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from 'src/common/components/Layout/Header';
import EngagementStepper from '../components/EngagementSetup/Stepper/EngagementStepper';
import { Button, Tooltip } from '@appkit4/react-components';
import {
	EngagementState,
	FaListItems
} from '../components/EngagementSetup/ForeignAffiliates/FaListItems/FaListItems';
import EngagementDetail from '../components/EngagementSetup/EngagementDetails/EngagementDetail';
import { ActionEnum } from 'src/common/types/enums/ActionEnum';
import { CalculationTypeList } from '../components/EngagementSetup/CalculationTypes/CalculationTypeList/CalculationTypeList';

import './EngagementSetup.scss';
import { EngagementStepEnum } from '../models/EngagementStepEnum';
import { RoleEnum } from 'src/common/types/enums/RoleEnum';
import { useAppSelector } from 'src/store/store';
import { engagementService } from '../services/engagementService';
import ManageMembers from '../components/EngagementSetup/EngagementMembers/ManageMembers/ManageMembers';
import { useTranslation } from 'react-i18next';
import { Utils } from 'src/common/utils/utils';
import { UnexpectedErrorModal } from 'src/common/components/UnexpectedErrorModal/UnexpectedErrorModal';
import { Constants } from 'src/common/types/constants';
import {
	IEngagementDetail,
	EngagementDetail as EngagementDetailEntity
} from '../models/IEngagementDetail';

const EngagementSetup = () =>
{
	const userProfile = useAppSelector((state) => state?.userProfile.profile);
	const navigate = useNavigate();
	const location = useLocation();
	const [clientId, setclientId] = useState<number | undefined>(
		0
	);
	const [engagementId, setengagementId] = useState<number | undefined>(
		undefined
	);

	const [engagementDetail, setEngagementDetail] = useState<IEngagementDetail|undefined>(undefined);

	const [clientName, setClientName] = useState<string>('');
	const [isDisabled, setDisableNext] = useState<boolean>(false);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [showUnexpectedErrorModal, setShowUnexpectedErrorModal] =
    useState<boolean>(false);
	const [unexpectedError, setUnExpectedError] = useState<string>('');
	const [errorCode, setErrorCode] = useState<number|undefined>(undefined);
	const [createEngagementReady, setCreateEngagementReady] = useState<boolean>(false);
	const { t } = useTranslation('engagementSetup');

	const loadEnagementDetail = (engagementId: number) =>
	{
		engagementService
			.getEngagementDetailById(engagementId)
			.then((res: any) =>
			{
				const parsedEngagement = EngagementDetailEntity.parseFields({
					...engagementDetail,
					...res.data.result
				});

				setEngagementDetail(parsedEngagement);
			});
	};

	useEffect(() =>
	{
		if (engagementId)
		{
			loadEnagementDetail(engagementId);
		}
	}, [engagementId]);

	useEffect(() =>
	{
		if ((location?.state as EngagementState)?.engagementId)
		{
			setengagementId((location?.state as EngagementState)?.engagementId);
			setclientId((location?.state as EngagementState)?.clientId);
			setClientName((location?.state as EngagementState)?.clientName??'');
		}
		else
		{
			setCurrentStep(EngagementStepEnum.EngagementDetails);
		}
	}, [location]);

	const [action, setAction] = useState<ActionEnum>(ActionEnum.None);
	const [currentStep, setCurrentStep] = useState<number>(-1);

	useEffect(() =>
	{
		setIsEditing(false);
		setDisableNext(false);
	},[currentStep]);

	const handleStepChange = (stepIndex: number) =>
	{
		setCurrentStep(stepIndex);
	};

	useEffect(() =>
	{
		if (engagementDetail) //set initial active step
		{
			engagementService
				.getForeignAffiliatesByEngagementId(engagementId)
				.then((res: any) =>
				{
					if (res.data.result && res.data.result.length > 0) //existing Foreign Affiliates
					{
						const requireReleventDates =  res.data.result.some((ea: any) =>
							(new Date(ea.dateBecameFa).getFullYear()<=Constants.MinYear)||
						(new Date(ea.analysisStartDate).getFullYear()<=Constants.MinYear));

						if (requireReleventDates)
						{
							setCurrentStep(EngagementStepEnum.AddForeignAffiliates);
						}
						else
						{
							const calcuationTypeCompleted = res.data.result.every((ea: any) => ea.calculationTypeIds.length>0);

							if (calcuationTypeCompleted)
							{
								setCurrentStep(EngagementStepEnum.Relationships);
							}
							else
							{
								const calcuationTypeNotStarted = res.data.result.every((ea: any) => ea.calculationTypeIds.length===0);
								if (calcuationTypeNotStarted)
								{
									setCurrentStep(EngagementStepEnum.CalculationTypes);
								}
								else
								{
									setCurrentStep(EngagementStepEnum.AddForeignAffiliates);
								}
							}
						}
					}
					else
					{
						const detailCompleted = Utils.areAllPropertiesPopulated(engagementDetail);
						if (detailCompleted) // detail information are all populated
						{
							setCurrentStep(EngagementStepEnum.ManageMembers);
						}
						else
						{
							setCurrentStep(EngagementStepEnum.EngagementDetails);
						}
					}
				})
				.catch((error: any) =>
				{
				});
		}
	}, [engagementDetail]);

	const handleEngagementCreated = (
		clientId: number | undefined,
		clientName: string,
		engagementId: number | undefined
	) =>
	{
		setclientId(clientId);
		setClientName(clientName);
		setengagementId(engagementId);
		if (engagementId)
		{
			loadEnagementDetail(engagementId);
		}
	};
	const [showShowCreateEngagementModal, setShowCreateEngagementModal] =
    useState<boolean>(false);

	const onCreateEngagement = () =>
	{
		setShowCreateEngagementModal(true);
	};


	type EngagementStepKeys = keyof typeof EngagementStepEnum;
	const enumKeys: EngagementStepKeys[]= Object.keys(EngagementStepEnum) as EngagementStepKeys[];

	const getNextStep = (currentStep: number) =>
	{
		const currentIndex = enumKeys.findIndex((key: EngagementStepKeys) => EngagementStepEnum[key]===currentStep);
		const nextIndex = currentIndex+1;
		if (nextIndex < enumKeys.length)
		{
			return EngagementStepEnum[enumKeys[nextIndex]];
		}
		else
		{
			return currentIndex;
		}
	};
	const getPrevStep = (currentStep: number) =>
	{
		const currentIndex = enumKeys.findIndex((key: EngagementStepKeys) => EngagementStepEnum[key]===currentStep);
		const prevIndex = currentIndex-1;
		if (prevIndex > 0)
		{
			return EngagementStepEnum[enumKeys[prevIndex]];
		}
		else
		{
			return currentIndex;
		}
	};

	const handleActionResponse = (action: ActionEnum, result: boolean) =>
	{
		if (result)
		{
			switch (action)
			{
			case ActionEnum.Next:
				setCurrentStep((activeStep) =>
					getNextStep(activeStep)
				);
				break;
			case ActionEnum.Back:
				setCurrentStep((activeStep) =>
					getPrevStep(activeStep)
				);
				break;
			case ActionEnum.Draft:
				navigate('/', {
					state: {
						activeIndex: userProfile?.role === RoleEnum.BusinessOwner ? 3 : 2
					}
				});
				break;
			case ActionEnum.Create:
				onCreateEngagement();
				break;
			}
		}
		setAction(ActionEnum.None);
	};
	const createEngagement = () =>
	{
		if (engagementId)
		{
			engagementService.createEngagement(engagementId).then((r) =>
			{
				setShowCreateEngagementModal(false);
				navigate('/', {
					state: {
						activeIndex:
              userProfile?.role === RoleEnum.BusinessOwner ||
              userProfile?.role === RoleEnum.PwCUser ?
              	0 :
              	null,
				  notification: `${t('createEngagement.notification.newEngagement')} "${engagementDetail?.name}" 
				  ${t('createEngagement.notification.addedTo')} ${clientName}.`
					}
				});
			})
				.catch((err: any) =>
				{
					setShowCreateEngagementModal(false);
					if (err.response)
					{
						setErrorCode(err.response.status);
						if (err.response.status < 500)
						{
							const errorMessage = t(`createEngagement.errors.${err.response.data.Errors[0]?.ErrorCode}`);
							setUnExpectedError(errorMessage);
							setShowUnexpectedErrorModal(true);
						}
						if (err.response.status >= 500)
						{
							setUnExpectedError(err.response.data.Errors[0].Message);
							setShowUnexpectedErrorModal(true);
						}
					}
				});
		}
	};
	const handleDisabledStatus = (status: boolean) =>
	{
		setDisableNext(status);
	};

	const handleEditingStatus = (status: boolean) =>
	{
		setIsEditing(status);
	};

	const updateCreateEngagementReady = (ready: boolean) =>
	{
		setCreateEngagementReady(ready);
	};

	const closeUnexpectedErrorModal = () =>
	{
		setUnExpectedError('');
		setShowUnexpectedErrorModal(false);
	};


	return (
		<div className="layout-container">
			<Header></Header>
			<div className="layout-body">
				<EngagementStepper
					stepIndex={currentStep}
					onStepChange={handleStepChange}
				></EngagementStepper>
				<div className="setup-steps">
					{currentStep === EngagementStepEnum.EngagementDetails && (
						<EngagementDetail
							clientId={clientId}
							clientName={clientName}
							engagementId={engagementId}
							engagementDetail={engagementDetail}
							currentStep={currentStep}
							action={action}
							onAction={handleActionResponse}
							onEngagementCreated={handleEngagementCreated}
						></EngagementDetail>
					)}
					{currentStep === EngagementStepEnum.ManageMembers && (
						<ManageMembers
							clientId={clientId}
							engagementId={engagementId}
							currentStep={currentStep}
							action={action}
							onAction={handleActionResponse}
						></ManageMembers>
					)}
					{currentStep === EngagementStepEnum.AddForeignAffiliates && (
						<FaListItems
							clientId={clientId}
							engagementId={engagementId}
							currentStep={currentStep}
							engagementDetail={engagementDetail}
							action={action}
							onAction={handleActionResponse}
							onEditing={handleEditingStatus}
							onDisableNext={handleDisabledStatus}
						></FaListItems>
					)}
					{currentStep === EngagementStepEnum.CalculationTypes && (
						<CalculationTypeList
							clientId={clientId}
							engagementId={engagementId}
							currentStep={currentStep}
							action={action}
							onAction={handleActionResponse}
							onDataLoading={handleDisabledStatus}
						></CalculationTypeList>
					)}
					{currentStep === EngagementStepEnum.Relationships && (
						<RelationshipList
							clientId={clientId}
							engagementId={engagementId}
							engagementDetail={engagementDetail}
							currentStep={currentStep}
							action={action}
							onAction={handleActionResponse}
							onCreateEngagementReady={updateCreateEngagementReady}
						></RelationshipList>
					)}
					<div className="navigation-buttons">
						<div className="left">
							{currentStep === 0 && (
								<Button kind="secondary" onClick={() => navigate('/')}>
                  Exit to Home Screen
								</Button>
							)}
							{currentStep > 0 && (
								<Button
									kind="secondary"
									className="custom-command"
									disabled={isEditing}
									onClick={() => setAction(ActionEnum.Back)}
								>
                  Back
								</Button>
							)}
						</div>
						<div className="right">
							<div className="right-button">
								{currentStep === EngagementStepEnum.EngagementDetails && (
									<Tooltip
										trigger="hover"
										position="top"
										id="tooltipDesc"
										appendAfterTarget={true}
										content="Client and engagement name are required fields to save as a draft"
									>
										<Button
											kind="secondary"
											disabled={isEditing}
											onClick={() => setAction(ActionEnum.Draft)}
										>
                      Save as Draft
										</Button>{' '}
									</Tooltip>
								)}
								{currentStep !== EngagementStepEnum.EngagementDetails && (
									<Button
										kind="secondary"
										className="custom-command"
										onClick={() => setAction(ActionEnum.Draft)}
										disabled={isEditing}
									>
                    Save as Draft
									</Button>
								)}
							</div>
							<div>
								{currentStep !== EngagementStepEnum.Relationships && (
									<Button
										kind="primary"
										className="custom-command"
										onClick={() => setAction(ActionEnum.Next)}
										disabled={isDisabled||isEditing}
									>
                    Next
									</Button>
								)}
								{currentStep === EngagementStepEnum.Relationships && (
									<Button
										kind="primary"
										disabled={!createEngagementReady}
										onClick={() => setAction(ActionEnum.Create)}
									>
                    Create Engagement
									</Button>
								)}
							</div>
							<CreateEngagementModal
								visible={showShowCreateEngagementModal}
								title="Create Engagement"
								engagementName={engagementDetail?.name}
								onCreate={createEngagement}
								setVisible={(val: boolean) =>
								{
									setShowCreateEngagementModal(val);
								}}
							></CreateEngagementModal>
							<UnexpectedErrorModal
								visible={showUnexpectedErrorModal}
								onClose={closeUnexpectedErrorModal}
								message={unexpectedError}
								error={''}
								statusCode={errorCode}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EngagementSetup;
