import { Modal, Button } from '@appkit4/react-components';
import './UnexpectedErrorModal.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

export const UnexpectedErrorModal = (props: {
  visible: boolean;
  message: string;
  error: string;
  onClose: () => void;
  statusCode?: number;
}) =>
{
	const { t } = useTranslation('homeDashboard');
	const [title, setTitle] = useState<string>('');

	useEffect(() =>
	{
		switch (props.statusCode)
		{
		case 400:
			setTitle(`${t('unexpected-error-popup.error')}`);
			break;
		case 403:
			setTitle(`${t('unexpected-error-popup.permissionErrTitle')}`);
			break;
		default:
			setTitle(`${t('unexpected-error-popup.title')}`);
			break;
		}
	},[	props.statusCode]);


	return (
		<>
			<Modal
				tabIndex={-1}
				className="unexpected-error-modal"
				visible={props.visible}
				initialFocus={false}
				closeOnPressEscape={false}
				maskCloseable={false}
				title={title}
				onCancel={() => props.onClose()}
				footer={
					<div className="footer-wrapper">
						<Button kind="primary" onClick={() => props.onClose()}>
							{t('unexpected-error-popup.ok')}
						</Button>
					</div>
				}
			>
				<div>
					<p>{props.message}</p> {props.error?.length > 0 &&
					<p>{props.error?`[${props.error}]`:''}</p> }
					{(props.statusCode===undefined || props.statusCode ===500) && <p>{t('unexpected-error-popup.try-again')}</p>}
				</div>
			</Modal>
		</>
	);
};
