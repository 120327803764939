import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { IEarningsDetails } from 'src/features/engagement/models/IEarningsDetails';
import EarningsDetailsReportFields from './../Settings/EarningsDetailsReportFields';
import { EarningsDetailsSections } from 'src/common/types/enums/EarningsDetailsSections';
import { IReportField } from 'src/common/types/interfaces/IReportField';
import ReportHeader from '../ReportCells/ReportHeader';
import { ReportI18NKeys } from 'src/common/types/enums/ReportI18NKeys';
import ReportRow from '../ReportCells/ReportRow';
import { Loading, Switch, Notification } from '@appkit4/react-components';
import { Utils } from 'src/common/utils/utils';
import { CalculationTypesEnum } from 'src/common/types/enums/CalculationTypes';
import { useAppSelector } from 'src/store/store';
import AffiliateContext from 'src/common/components/SharedDataContext/AffiliateContext';
import { NotesEditor } from 'src/common/components/NotesEditor/NotesEditor';
import { INameId } from 'src/common/types/interfaces/INameId';
import { NotificationService } from 'src/core/services/notificationService';
import { IRetainedEarningsLookupRecord } from 'src/common/types/interfaces/IRetainedEarnings';
import { IForeignTaxableIncomeLookupRecord } from 'src/common/types/interfaces/IForeignTaxableIncome';
import { IABIComputedUnderCanadianRulesLookupRecord } from 'src/common/types/interfaces/IABIComputedUnderCanadianRules';
import { ILookupRecord } from 'src/common/types/interfaces/ILookupRecord';
import { ReportFieldEnum } from 'src/common/types/enums/ReportFieldEnum';
import './EarningsReport.scss';

const EarningsDetailsReport = (props: { refershReport: boolean }) =>
{
	const { engagementId, affiliateId } = useParams();
	const [reportData, setReportData] = useState<any>([]);
	const [totalAmounts, setTotalAmounts] = useState<any>();
	const [isLoading, setIsLoading] = useState(false);
	const [displayNotes, setDisplayNotes] = useState(false);

	const affiliateContext = useContext(AffiliateContext);
	const calculationTypes = useAppSelector((state) => state.appSettings.calculationTypes);
	const faCalculationTypeIds = affiliateContext?.affiliateDetail.calculationTypeIds;

	const [faCalculationTypes, setFaCalculationTypes] = useState<string[]>([]);
	const [retainedEarningsDescriptionOptions, setRetainedEarningsDescriptionOptions] = useState<IRetainedEarningsLookupRecord[]>([]);
	const [foreignTaxableIncomeDescriptionOptions, setForeignTaxableIncomeDescriptionOptions] =
		useState<IForeignTaxableIncomeLookupRecord[]>([]);
	const [activeBusinessIncomeDescriptionOptions, setActiveBusinessIncomeDescriptionOptions] =
		useState<IABIComputedUnderCanadianRulesLookupRecord[]>([]);
	const [propertyIncomeDescriptionOptions, setPropertyIncomeDescriptionOptions] = useState<ILookupRecord[]>([]);


	const report: IEarningsDetails = EarningsDetailsReportFields;

	const { t } = useTranslation(
		'report',
		{
			keyPrefix: ReportI18NKeys.EarningsDetails
		}
	);

	const triggerErrorNotification = async (message: string): Promise<void> =>
	{
		const ele = (
			<Notification
				message={message}
				status={'error'}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};

	const calculateRowTotal = (data: any): any =>
	{
		const earningDetailsTotals: any = {
		};

		Object.keys(EarningsDetailsSections).forEach((section) =>
		{
			if (!!data && !!data.earningsDetailsData)
			{
				const fields = report[section as keyof IEarningsDetails]
					.filter((field: IReportField) => field.type === ReportFieldEnum.AmountWithNotes);

				const descriptionLists = report[section as keyof IEarningsDetails]
					.filter((field: IReportField) => field.type === ReportFieldEnum.DescriptionList)
					?.map((field: IReportField) => field.name);

				const uniqueFields = !!fields && fields.length > 0 ? [...new Set(fields.map((field: IReportField) => field.name))] : [];

				earningDetailsTotals[section] = !!earningDetailsTotals[section] ? {
					...earningDetailsTotals[section]
				} : {
				};

				data.earningsDetailsData.forEach((yearData: any) =>
				{
					if (!!yearData[section] && !!fields && fields.length > 0)
					{
						uniqueFields.forEach((field: any) =>
						{
							const fieldName = field.split('.')[1];

							if (!!yearData[section][fieldName] && yearData[section][fieldName].hasOwnProperty('amount'))
							{
								const isAmountUpdated = !!earningDetailsTotals[section][fieldName] &&
									!!earningDetailsTotals[section][fieldName].amount;
								 const amounts = isAmountUpdated ?
									[earningDetailsTotals[section][fieldName].amount, yearData[section][fieldName].amount || 0] :[];

								earningDetailsTotals[section][fieldName] = {
									'amount': isAmountUpdated ?
										Utils.add(...amounts) :
										yearData[section][fieldName].amount || 0
								};
							}
						});
					}

					if (!!yearData[section] && !!descriptionLists && descriptionLists.length > 0)
					{
						descriptionLists.forEach((list: any) =>
						{
							earningDetailsTotals[section][list] = !!earningDetailsTotals[section][list] ? [
								...earningDetailsTotals[section][list]
							] : [];
							if (!!yearData[section][list] && yearData[section][list].length > 0)
							{
								yearData[section][list].forEach((d: any) =>
								{
									const object = earningDetailsTotals[section][list]
										.find((c: any) => c.rowIndex === d.rowIndex && c.descriptionId === d.descriptionId);

									if (!!object)
									{
										const fileterdList = earningDetailsTotals[section][list]
											.filter((c: any) => !(c.rowIndex === d.rowIndex && c.descriptionId === d.descriptionId));
										const amounts: number[] = [!!object.amount? object.amount : 0, !!d.amount ? d.amount :0];
										fileterdList.push({
											...object,
											'amount': Utils.add(...amounts)
										});
										earningDetailsTotals[section][list] = fileterdList;
									}
									else
									{
										earningDetailsTotals[section][list].push(d);
									}
								});
							}
						});
					}
				});
			}
		});

		return earningDetailsTotals;
	};

	const loadEarningsDetailsReportData = async (): Promise<void> =>
	{
		if (engagementId && affiliateId)
		{
			setIsLoading(true);
			engagementService.loadEarningsDetailsReport(Number(engagementId), Number(affiliateId)).then((res: any) =>
			{
				setIsLoading(false);
				const result = !!res && !!res.data && !!res.data.result ? res.data.result : [];
				setReportData(result);

				const rowTotal = calculateRowTotal(result);
				setTotalAmounts(rowTotal);
			})
				.catch((error: any) =>
				{
					triggerErrorNotification(
						t('loadingReportError')
					);
					setIsLoading(false);
				});
		}
	};

	const loadLookups = async (): Promise<void> =>
	{
		try
		{
			const [
				retainedEarningsResponse,
				foreignTaxableIncomeResponse,
				activeBusinessIncomeResponse,
				propertyIncomeResponse
			] =
				await Promise.all([
					engagementService.getRetainedEarningsLookupData(),
					engagementService.getForeignTaxableIncomeLookups(),
					engagementService.getABIComputedUnderCanadianRulesLookups(),
					engagementService.getPropertyIncomeLookups()
				]);
			setRetainedEarningsDescriptionOptions(!!retainedEarningsResponse.data && !!retainedEarningsResponse.data.result &&
				retainedEarningsResponse.data.result.length > 0 ? retainedEarningsResponse.data.result : []);
			setForeignTaxableIncomeDescriptionOptions(!!foreignTaxableIncomeResponse.data && !!foreignTaxableIncomeResponse.data.result &&
				foreignTaxableIncomeResponse.data.result.length > 0 ? foreignTaxableIncomeResponse.data.result : []);
			setActiveBusinessIncomeDescriptionOptions(!!activeBusinessIncomeResponse.data && !!activeBusinessIncomeResponse.data.result &&
				activeBusinessIncomeResponse.data.result.length > 0 ? activeBusinessIncomeResponse.data.result : []);
			setPropertyIncomeDescriptionOptions(!!propertyIncomeResponse.data && !!propertyIncomeResponse.data.result &&
				!!propertyIncomeResponse.data.result.propertyIncomeMasterList &&
				propertyIncomeResponse.data.result.propertyIncomeMasterList.length > 0 ?
				propertyIncomeResponse.data.result.propertyIncomeMasterList : []);
		}
		catch
		{
			triggerErrorNotification(
				t('loadingLookupsError')
			);
		}
	};

	const mapLookups = (
		section: keyof IEarningsDetails
	): INameId[] =>
	{
		const mapLookupRecords = (lookupRecords: any, field: string) =>
		{
			return lookupRecords
				.map((l: any) => ({
					id: l.id,
					name: l[field]
				}))
				.sort();
		};

		switch (section)
		{
		case EarningsDetailsSections.retainedEarnings:
			return mapLookupRecords(retainedEarningsDescriptionOptions, 'name');
		case EarningsDetailsSections.activeBusinessIncome:
			return mapLookupRecords(activeBusinessIncomeDescriptionOptions, 'description');
		case EarningsDetailsSections.deemedABIPropertyIncome:
			return mapLookupRecords(propertyIncomeDescriptionOptions, 'fieldName');
		case EarningsDetailsSections.abiPropertyIncome:
			return mapLookupRecords(propertyIncomeDescriptionOptions, 'fieldName');
		default:
			return mapLookupRecords(foreignTaxableIncomeDescriptionOptions, 'description');
		}
	};

	const sortList = (list: any): any =>
	{
		list = !!list && list.length > 1 ? list
			.sort((a: { rowIndex: number; }, b: { rowIndex: number; }) =>
			{
				if (a.rowIndex < b.rowIndex)
				{
					return -1;
				}
				if (a.rowIndex > b.rowIndex)
				{
					return 1;
				}
				return 0;
			}) : list;
		return list;
	};

	const renderDescriptionList = (section: any, options?: INameId[], fieldName?: any, totalAmounts?: any): JSX.Element =>
	{
		return (<>
			{
				!!reportData.earningsDetailsReferenceData[section] && !!reportData.earningsDetailsReferenceData[section][fieldName] &&
				reportData.earningsDetailsReferenceData[section][fieldName].map((rowField: any) =>
				{
					let descriptionList = !!totalAmounts && !!totalAmounts[section] && !!totalAmounts[section][fieldName] ?
						totalAmounts[section][fieldName] : [];

					descriptionList = sortList(descriptionList);

					const rowTotalsByDescriptionId = !!descriptionList && descriptionList.length > 0 ?
						descriptionList?.filter((l: any) => l.descriptionId === rowField.key) : [];

					return (<>
						{!!rowField && !!rowField.count && rowField.count > 0 &&
							Array.from({
								length: rowField.count
							}, (_, index) =>
							{
								return (
									<tr className={'currency left-indented'}>
										<td>
											{!!rowField.key && !!options && options.length > 0 ?
												options.find(((o) => o.id === rowField.key))?.name : ''
											}
										</td>
										<td>
											{
												!!rowTotalsByDescriptionId && rowTotalsByDescriptionId.length > 0 ?
													Utils.formatCurrency(rowTotalsByDescriptionId[index]?.amount, 2) : 0
											}
										</td>
										{!!reportData.earningsDetailsData && reportData.earningsDetailsData.map((yearData: any) =>
										{
											let relavantData: any = {
											};
											if (!!yearData && !!yearData[section] && !!yearData[section][fieldName])
											{
												let filteredData = yearData[section][fieldName]
													.filter((item: any) => item.descriptionId === rowField.key);

												filteredData = sortList(filteredData);

												relavantData = !!filteredData && filteredData.length > 0 &&
													!!rowTotalsByDescriptionId && rowTotalsByDescriptionId.length > 0 &&
													!!rowTotalsByDescriptionId[index] && !!rowTotalsByDescriptionId[index]?.rowIndex ?
													filteredData
														.find((d: any) => d.rowIndex === rowTotalsByDescriptionId[index]?.rowIndex) : {
													};
											}

											return (
												<td >
													{!!relavantData  ?
														<div className={`${!!displayNotes ? 'notes-container' : ''}`}>
															{!!displayNotes &&
																<NotesEditor
																	displayOnly={true}
																	isDisabled={true}
																	notes={!!relavantData.notes ? relavantData.notes : ''}
																/>}
															<div>
																{!!relavantData.amount ? Utils.formatCurrency(relavantData.amount, 2) :
																	(relavantData.amount === null || relavantData.amount === 0 ? 0 : '')}
															</div>
														</div> : ''}
												</td>
											);
										})
										}
									</tr>
								);
							})
						}
					</>);
				})
			}
		</>);
	};

	const renderReportSection = (section: keyof IEarningsDetails): JSX.Element =>
	{
		return (
			<>
				{report[section].map((field: IReportField) =>
				{
					return (
						<>
							{!!field && !!field.type && field.type !== 'descriptionList' ?
								<ReportRow
									reportData={reportData.earningsDetailsData}
									field={field}
									i18nKey={ReportI18NKeys.EarningsDetails}
									displayNotes={displayNotes}
									totalAmounts={totalAmounts} /> :
								renderDescriptionList(section,[...mapLookups(section)], field.name , totalAmounts)}
						</>
					);
				})}
			</>
		);
	};

	const renderSections = (sections: any): JSX.Element =>
	{
		return (<>
			{
				sections.length > 0 && sections.map((section: any) =>
				{
					return (
						<>
							{!!reportData.earningsDetailsReferenceData &&
							!!reportData.earningsDetailsReferenceData[section] &&renderReportSection(section)}
						</>
					);
				})
			}
		</>);
	};

	const onNotesToggle = (value: boolean) =>
	{
		setDisplayNotes(!!value);
	};

	useEffect(() =>
	{
		loadLookups();
		loadEarningsDetailsReportData();
	}, [engagementId, affiliateId]);

	useEffect(() =>
	{
		if (!!props.refershReport)
		{
			loadEarningsDetailsReportData();
		}
	}, [props.refershReport]);


	useEffect(() =>
	{
		if (!!faCalculationTypeIds && faCalculationTypeIds.length > 0 && !!calculationTypes && calculationTypes.length > 0)
		{
			const calculationTypesFiltered = calculationTypes?.filter((r: Record<string, any>) =>
				faCalculationTypeIds?.includes(r.id)).map((type: Record<string, any>) => type.internalName);

			if (!!calculationTypesFiltered && calculationTypesFiltered.length > 0)
			{
				setFaCalculationTypes(calculationTypesFiltered);
			}
			else
			{
				setFaCalculationTypes([]);
			}
		}
	}, [faCalculationTypeIds, calculationTypes]);

	return <div className='earnings-report'>

		{EarningsDetailsReportFields && <div className='table-container'>
			{isLoading && (
				<div className="loading-icon">
					<Loading
						loadingType="circular"
						indeterminate={true}
						compact={false}
					/>
				</div>
			)}
			{reportData &&
				<table>
					<thead>
						<tr>
							<th>
								<div className={'title-area'}>
									<div className={'title'}>
										<span>
											{
												t('header-title')
											}
										</span>
									</div>
									<div className={'toggle'}>
										<Switch
											className={'switch-label'}
											checked={displayNotes}
											disabled={false}
											onChange={onNotesToggle}
										>
											{
												t('header-toggleNotesLabel')
											}
										</Switch>
									</div>
								</div>
							</th>
							{
								Array.from({
									length: !!reportData && !!reportData.earningsDetailsData ? reportData.earningsDetailsData.length : 0
								}, (_, index) =>
								{
									return (
										<th></th>
									);
								}
								)
							}
						</tr>
					</thead>
					{!isLoading && !!reportData && !!reportData.earningsDetailsData && <tbody>
						{report.header.map((field: any) =>
							<ReportHeader
								reportData={reportData.earningsDetailsData}
								field={field}
								i18nKey={ReportI18NKeys.EarningsDetails}
								totalAmounts={totalAmounts} />
						)}
						<>
							{!!faCalculationTypes && faCalculationTypes.length > 0 &&
								faCalculationTypes.some((c) => c === CalculationTypesEnum.EnhancedSurplus) &&
								<>
									{
										renderSections([
											EarningsDetailsSections.retainedEarnings,
											EarningsDetailsSections.foreignTaxableIncome,
											EarningsDetailsSections.reg5907Adjustments,
											EarningsDetailsSections.activeBusinessIncome
										])
									}
								</>
							}
							{!!faCalculationTypes && faCalculationTypes.length > 0 &&
								faCalculationTypes.some((c) => c === CalculationTypesEnum.EnhancedSurplus ||
									c === CalculationTypesEnum.FAPI) &&
								<>
									{
										renderSections([
											EarningsDetailsSections.deemedABIPropertyIncome,
											EarningsDetailsSections.abiPropertyIncome
										])
									}
								</>
							}
						</>
					</tbody>}
				</table>
			}
		</div>}
	</div>;
};

export default EarningsDetailsReport;