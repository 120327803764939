import { Loading } from '@appkit4/react-components';
import { ISaveProgressBarProps } from './ISaveProgressBarProps';
import { useTranslation } from 'react-i18next';

import './SaveProgressBar.scss';

export function SaveProgressBar(props: ISaveProgressBarProps): JSX.Element
{
	const { t: commonProgressBar } = useTranslation(
		'common',
		{
			keyPrefix: 'saveProgressBar'
		});

	return <>
		{
			!!props.display &&
			<div className={'save-progress-bar'}>
				<div className={'message'}>
					{
						!!props.message ?
							props.message :
							commonProgressBar('defaultMessage')
					}
				</div>
				<Loading
					linearWidth={
						!!props.width ?
							props.width :
							'100%'
					}
					indeterminate
				/>
				<div className={'description'}>
					{
						commonProgressBar('description')
					}
				</div>
			</div>
		}
	</>;
}