import React, { useContext, useEffect, useState } from 'react';

import moment from 'moment';
import { IAuthContext } from 'src/features/authentication/Types';
import { AuthContext } from 'src/features/authentication/AuthContext';

export const SessionTimeout = (props: any) =>
{
	const { logOut }: IAuthContext = useContext(AuthContext);

	const [isLogout] = useState(false);

	const sessionInactivityCheckInterval = 300000;

	let timer: any = undefined;
	const events = ['click', 'load', 'keydown'];
	const eventHandler = (eventType: any) =>
	{
		if (!isLogout)
		{
			localStorage.setItem('lastInteractionTime', moment().format());
			if (timer)
			{
				startTimer();
			}
		}
	};

	useEffect(() =>
	{
		addEvents();

		return () =>
		{
			removeEvents();
			clearTimeout(timer);
		};
	}, []);

	const startTimer = () =>
	{
		if (timer)
		{
			clearTimeout(timer);
		}
		timer = setTimeout(async () =>
		{
			const lastInteractionTime = localStorage.getItem('lastInteractionTime');
			const timeSinceLastInteraction =	moment().diff(moment(lastInteractionTime));

			const inactivityTimeoutPeriod = props.timeOutInterval ?
				props.timeOutInterval : 900000;
			if (isLogout)
			{
				clearTimeout(timer);
			}
			else
			{
				if (timeSinceLastInteraction < inactivityTimeoutPeriod)
				{
					startTimer();
				}
				else
				{
					logOut();
				}
			}
		}, sessionInactivityCheckInterval);
	};
	const addEvents = () =>
	{
		events.forEach((eventName) =>
		{
			window.addEventListener(eventName, eventHandler, true);
		});

		startTimer();
	};

	const removeEvents = () =>
	{
		events.forEach((eventName) =>
		{
			window.removeEventListener(eventName, eventHandler, true);
		});
	};

	return <></>;
};
