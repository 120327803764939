import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiService } from 'src/common/services/apiService';
import { IUserProfile } from 'src/common/types/interfaces/IUserProfile';

export const fetchUserProfileAsync = createAsyncThunk(
	'userProfile/fetch',
	async () =>
	{
		const response = await apiService.getProfile();
		const data = response.data.result;
		return data;
	}
);

export const confirmPrivacyAsync = createAsyncThunk(
	'userProfile/patch',
	async () =>
	{
		const response = await apiService.confirmPrivacy();
		const data = response.data.result;
		return data;
	}
);

interface UserProfileState {
  profile: IUserProfile|null;
  isLoading: boolean;
  userProfileError: string|undefined;
  privacyError: string|undefined;
}

const initialState: UserProfileState  = {
	profile: null,
	isLoading: false,
	userProfileError: undefined,
	privacyError: undefined
};

export const userProfileSlice = createSlice({
	name: 'userProfile',
	initialState,
	reducers: {

	},
	extraReducers: (builder) =>
	{
		builder
			.addCase(fetchUserProfileAsync.pending, (state) =>
			{
				state.isLoading = true;
			})
			.addCase(fetchUserProfileAsync.fulfilled, (state, action: PayloadAction<IUserProfile>) =>
			{
				state.isLoading = false;
				state.profile = action.payload;
			})
			.addCase(fetchUserProfileAsync.rejected, (state, action) =>
			{
				state.isLoading = false;
				state.userProfileError = action.error.message;
			})
			.addCase(confirmPrivacyAsync.pending, (state) =>
			{
				state.isLoading = true;
			})
			.addCase(confirmPrivacyAsync.fulfilled, (state, action: PayloadAction<IUserProfile>) =>
			{
				state.isLoading = false;
				state.profile = action.payload;
			})
			.addCase(confirmPrivacyAsync.rejected, (state, action) =>
			{
				state.isLoading = false;
				state.privacyError = action.error.message;
			})
		;
	}
});


export default userProfileSlice.reducer;
