import React from 'react';
import {
	Breadcrumb,
	BreadcrumbItem
} from '@appkit4/react-components/breadcrumb';
import { IEngagementNavigationState } from 'src/common/types/interfaces/IEngagementNavigationState';
import { useNavigate } from 'react-router-dom';
import './BreadCrumb.scss';

const BreadCrumbs = (props: {
  state: IEngagementNavigationState | undefined;
}) =>
{
	const navigate = useNavigate();

	const navigateToHome = () =>
	{
		navigate('/');
	};

	const navigateToEngagement = () =>
	{
		navigate(`/engagement/${props.state?.engagementId}`);
	};

	return (
		<>
			<Breadcrumb className="eng-breadcrumb">
				<BreadcrumbItem onClick={navigateToHome}>
					<span tabIndex={0}>Home/{props.state?.clientName}</span>
				</BreadcrumbItem>
				<BreadcrumbItem onClick={navigateToEngagement}>
					<span>{props.state?.engagementName}</span>
				</BreadcrumbItem>
				{props.state?.foreignAffiliateId && (
					<BreadcrumbItem>
						<span>{props.state?.foreignAffiliateName}</span>
					</BreadcrumbItem>
				)}
			</Breadcrumb>
		</>
	);
};

export default BreadCrumbs;
