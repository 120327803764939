import { TextArea } from '@appkit4/react-components';
import { ITextAreaFormControlProps } from './ITextAreaFormControlProps';
import { InputError } from '../../ModalControls/InputError/InputError';

export function TextAreaFormControl(props: ITextAreaFormControlProps): JSX.Element
{
	const onChange = (value: string): void =>
	{
		if (!!props.onChange)
		{
			props.onChange(props.field, value);
		}
	};

	return <>
		<div>
			<TextArea
				autosize={!!props.isAutosize}
				required={!!props.isRequired}
				disabled={!!props.isDisabled}
				readonly={!!props.isReadonly}
				title={props.title}
				value={
					!!props.value ?
						props.value :
						''
				}
				maxLength={
					!!props.maxLength ?
						props.maxLength :
						250
				}
				error={!!props.isError}
				onChange={onChange}
			/>
			<InputError
				isError={!!props.isError}
				message={props.errorMessage}
				onRenderMessage={props.onRenderErrorMessage}
			/>
		</div>
	</>;
}