import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
	Grid,
	GridColumn as Column,
	GridToolbar,
	GridPageChangeEvent,
	GridCellProps,
	GridDetailRowProps
} from '@progress/kendo-react-grid';

import './ClientList.scss';
import { EngagementList } from './EngagementList';
import { coreService } from 'src/core/services/coreService';
import { IClient } from 'src/common/types/interfaces/IClient';
import { Button, Loading, Search } from '@appkit4/react-components';
import { useTranslation } from 'react-i18next';
import {
	IntlProvider,
	loadMessages,
	LocalizationProvider
} from '@progress/kendo-react-intl';
import esMessages from './../../../i18n/en/en.json';
import { IClientFilter } from 'src/core/modules/IClientFilter';
import {
	ClientChangeSubject,
	EngagementTotalChangeSubject,
	EngagementStatusChangeSubject
} from 'src/core/services/EventBus';
import { EngagementCommand } from 'src/core/types/enums/EngagementCommand';
import { DeleteEngagementModal } from './DeleteEngagementModal';
import { ClientModal } from './editClientModal/ClientModal';
import { useAppSelector } from 'src/store/store';
import { FilterModal } from './filterModal/FilterModal';
//@ts-ignore
import Highlighter from 'react-highlight-words';
import { EngagementPersonalizationStatus } from 'src/core/types/enums/EngagementPersonalizationStatus';
import { useNavigate } from 'react-router-dom';
import { RoleEnum } from 'src/common/types/enums/RoleEnum';
import { isEqual } from 'lodash';
import { EVentTypeEnum } from 'src/common/types/enums/EventTypeEnum';
import { EngagementDetail, IEngagementDetail } from 'src/features/engagement/models/IEngagementDetail';

loadMessages(esMessages, 'en-US');

interface ClientListProps {
  engagementPersonalizationStatus: EngagementPersonalizationStatus;
  activeIndex?: number;
  onAction: (engagementCommand: EngagementCommand) => void;
  updatedClientDetails: Record<string, any> | null;
  setUpdatedClientDetails: (updatedClientDetails: Record<string, any> | null) => void;
}

const initialFilteredData = {
	taxPayers: [],
	currencies: [],
	calculation: []
};

export const ClientList = (props: ClientListProps) =>
{
	const initClientFilter: IClientFilter = {
		searchText: '',
		engagementPersonalizationStatus: props.engagementPersonalizationStatus,
		engagementFilter: {
			taxPayers: [],
			currencies: []
		},
		pageIndex: 1,
		pageSize: 10,
		orderBy: ''
	};
	const navigate = useNavigate();

	const [expand, setExpand] = useState<boolean>(false);
	const [searchText, setSearchText] = useState<string>('');
	const [clientFilter, setClientFilter] =
    useState<IClientFilter>(initClientFilter);
	const clientFilterRef = useRef(clientFilter);
	const [clients, setClients] = useState<IClient[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const clientsRef = useRef(clients);

	const gridRef = useRef<HTMLDivElement | null>(null);

	const [total, setTotal] = useState<number>(0);
	const [showDeleteEngagementModal, setShowDeleteEngagementModal] =
    useState<boolean>(false);
	const [selectedEngagement, setSelectedEngagement] = useState<any | null>(
		null
	);
	const [showEditClientModal, setShowEditClientModal] =
    useState<boolean>(false);
	const [clientModalContext, setClientModalContext] = useState<Record<
    string,
    any
  > | null>(null);

	const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
	const [filteredData, setFilteredData] =
    useState<Record<string, string[]>>(initialFilteredData);
	const [activeFilterData, setActiveFilterData] = useState<string[]>([]);
	const [showMoreCount, setShowMoreCount] = useState<number>(0);

	const { t } = useTranslation('homeDashboard');
	const userProfile = useAppSelector((state) => state.userProfile.profile);

	useEffect(() =>
	{
		clientsRef.current = clients;
		if (expand === false && clientFilter.searchText.length > 1)
		{
			setExpand(true);
			expandAll();
		}
	}, [clients]);

	useEffect(() =>
	{
		if (!!props.updatedClientDetails)
		{
			const updatedClientsInfo = clients.reduce((formatted: any, item: Record<string, any>) =>
			{
				if (item.id === props.updatedClientDetails?.clientId)
				{
					item.clientName = props.updatedClientDetails?.clientName;
				}
				formatted.push(item);
				return formatted;
			}, []);

			setClients(updatedClientsInfo);
		}
	}, [props.updatedClientDetails]);

	useEffect(() =>
	{
		const allFilterVals = [
			...filteredData?.calculation,
			...filteredData?.taxPayers,
			...filteredData?.currencies
		];
		calcOverflowBadgesAndSetShowMore(allFilterVals);

		const updatedFilters = {
			...clientFilter,
			pageIndex: 1,
			engagementFilter: {
				taxPayers: filteredData.taxPayers,
				currencies: filteredData.currencies
			}
		};

		// only trigger filter update if values are different than whats already in entity
		if (!isEqual(clientFilter, updatedFilters))
		{
			setClientFilter((prev: IClientFilter) => ({
				...prev,
				engagementFilter: {
					taxPayers: filteredData.taxPayers,
					currencies: filteredData.currencies
				},
				pageIndex: 1
			}));
		}
	}, [filteredData]);

	const loadClients = (clientFilter: IClientFilter) =>
	{
		setLoading(true);
		coreService
			.getClients(clientFilter)
			.then((res: any) =>
			{
				setTotal(res.data.clientTotal);
				EngagementTotalChangeSubject.next({
					eventType: props.engagementPersonalizationStatus,
					payload: res.data.engagementTotal
				});
				const newRows = res.data.clients.map((row: any) => ({
					...row,
					deliveryDate: false,
					expanded: false,
					engagements: []
				}));
				setClients(newRows);
				setLoading(false);
			})
			.catch((errr: any) =>
			{
				setLoading(false);
			});
	};

	useEffect(() =>
	{
		clientFilterRef.current = clientFilter;
		loadClients(clientFilter);
		setExpand(false);
	}, [clientFilter]);

	useEffect(() =>
	{
		const clientSubscription = ClientChangeSubject.subscribe((e: any) =>
		{
			const clientId = e.clientId;
			const found = clientsRef.current.find((c: any) => c.id === clientId);
			if (found)
			{
				const updatedClients = clientsRef.current.map((c: any) =>
				{
					if (c.id === clientId)
					{
						return {
							...c,
							name: e.payload.clientName,
							iPowerCode: e.payload.iPowerCode
						};
					}
					else
					{
						return c;
					}
				});
				setClients(updatedClients);
			}
		});

		const subscription = EngagementStatusChangeSubject.subscribe((e: any) =>
		{
			switch (props.engagementPersonalizationStatus)
			{
			case EngagementPersonalizationStatus.My:
				if (e.eventType === EVentTypeEnum.Unhide)
				{
					setExpand(false);
					loadClients(clientFilterRef.current);
				}
				break;
			case EngagementPersonalizationStatus.Hidden:
				if (e.eventType === EVentTypeEnum.Hide)
				{
					const clientId = e.clientId;
					const dataItem = e.payload;
					const found = clientsRef.current.find(
						(c: any) => c.id === clientId
					);
					if (found)
					{
						const updatedClients = clientsRef.current.map((c: any) =>
						{
							if (c.id === clientId)
							{
								var updatedEngagements = c.engagements;
								if (c.expanded)
								{
									updatedEngagements = [...c.engagements, dataItem];
									updatedEngagements.sort((a: any, b: any) =>
									{
										if (a.name < b.name)
										{
											return -1;
										}
										if (a.name > b.name)
										{
											return 1;
										}
										return 0;
									});
								}
								return {
									...c,
									engagementCount: c.engagementCount + 1,
									engagements: updatedEngagements
								};
							}
							else
							{
								return c;
							}
						});
						setClients(updatedClients);
					}
					else
					{
						loadClients(clientFilter);
					}
				}

				break;
			} //end of switch
		});
		return () =>
		{
			subscription.unsubscribe();
			clientSubscription.unsubscribe();
		};
	}, []);

	const CommandCell = (gridCellProps: GridCellProps) =>
	{
		let cellValue = <></>;
		switch (props.activeIndex)
		{
		case 0:
			cellValue = (
				<Button
					className="client-edit-btn"
					kind="tertiary"
					compact
					icon="Appkit4-icon icon-edit-outline icon-style"
					onClick={() => editClient(gridCellProps.dataItem)}
				></Button>
			);
			break;

		default:
			cellValue = <></>;
		}
		return (
			<td
				className={'k-command-cell add-top-border'}
				style={{
					borderLeft: 'transparent',
					borderBottom: '1px solid red !important'
				}}
			>
				{' '}
				{(userProfile?.role === RoleEnum.PwCUser ||
          userProfile?.role === RoleEnum.BusinessOwner) &&
          cellValue}
			</td>
		);
	};

	const editClient = (dataItem: any) =>
	{
		setClientModalContext(dataItem);
		setShowEditClientModal(true);
	};

	const closeClientModal = (clientData: any) =>
	{
		if (!!clientData)
		{
			props?.setUpdatedClientDetails(clientData);
		}
		setClientModalContext(null);
		setShowEditClientModal(false);
	};

	const expandAll = () =>
	{
		clientsRef.current.forEach((client: any) =>
		{
			const expandEvent = {
				value: true,
				dataItem: client
			};
			autoExpand(expandEvent);
		});
	};

	const autoExpand = (event: any) =>
	{
		const clientId = event.dataItem.id;

		if (event.dataItem.matchingFlag === 1)
		{
			return;
		}

		const currentClients: any = clientsRef.current.slice();
		const index = currentClients.findIndex((d: any) => d.id === clientId);
		currentClients[index].loading = true;
		setClients(currentClients);

		coreService
			.searchEngagements(
				clientId,
				event.dataItem.matchingFlag === 0 ?
					clientFilter :
					{
						...clientFilter,
						searchText: ''
					}
			)
			.then((res: any) =>
			{
				const data: any = clientsRef.current.slice();
				const index = data.findIndex((d: any) => d.id === clientId);
				if (index >= 0)
				{
					const engagementResults: IEngagementDetail[] = !!res.data && !!res.data.length ?
						res.data :
						[];

					data[index].engagements = engagementResults.map((r) => EngagementDetail.parseFields(r));
					data[index].engagementCount = res.data.length;
					data[index].loading = false;
					data[index].expanded = true;
					setClients(data);
				}
			});
	};

	const expandChange = (event: any) =>
	{
		event.dataItem.expanded = event.value;
		const clientId = event.dataItem.id;

		if (!event.value || event.dataItem.details)
		{
			const data: any = clientsRef.current.slice();
			const index = data.findIndex(
				(d: any) => d.id === clientId //&& d.expanded === true
			);
			if (index >= 0)
			{
				data[index].expanded = false;
				setClients(data.filter((c: any) => c.engagementCount > 0));
			}
			return;
		}

		const currentClients: any = clientsRef.current.slice();
		const index = currentClients.findIndex((d: any) => d.id === clientId);
		currentClients[index].loading = true;
		setClients(currentClients);

		coreService
			.searchEngagements(
				clientId,
				event.dataItem.matchingFlag === 0 ?
					clientFilter :
					{
						...clientFilter,
						searchText: ''
					}
			)
			.then((res: any) =>
			{
				const data: any = clientsRef.current.slice();
				const index = data.findIndex((d: any) => d.id === clientId);
				if (index >= 0)
				{
					const engagementResults: IEngagementDetail[] = !!res.data && !!res.data.length ?
						res.data :
						[];

					data[index].engagements = engagementResults.map((r) => EngagementDetail.parseFields(r));
					data[index].engagementCount = res.data.length;
					data[index].loading = false;
					data[index].expanded = true;
					setClients(data.filter((c: any) => c.engagementCount > 0));
				}
			});
	};

	const pageChange = (event: GridPageChangeEvent) =>
	{
		setClientFilter((prev: IClientFilter) => ({
			...prev,
			pageIndex: Math.floor(event.page.skip / event.page.take) + 1,
			pageSize: event.page.take
		}));
	};

	const getDetailRowComponent = useCallback(
		(gridDetialRowProps: GridDetailRowProps) =>
		{
			return (
				<EngagementList
					engagementPersonalizationStatus={
						props.engagementPersonalizationStatus
					}
					onCommand={handleCommand}
					searchText={clientFilterRef.current?.searchText}
					{...gridDetialRowProps}
				/>
			);
		},
		[]
	);

	const handleCommand = (
		engagementCommand: EngagementCommand,
		engagementPersonalizationStatus: EngagementPersonalizationStatus,
		dataItem: any,
		clientId: string,
		reload: boolean
	) =>
	{
		switch (engagementCommand)
		{
		case EngagementCommand.Edit: {
			break;
		}
		case EngagementCommand.ContinueDraft: {
			break;
		}
		case EngagementCommand.Hide: {
			coreService
				.changeEngagementStatus(
					dataItem.id,
					EngagementPersonalizationStatus.My,
					EngagementPersonalizationStatus.Hidden
				)
				.then((res: any) =>
				{
					var updatedClients = clientsRef.current.map((c: any) =>
					{
						if (c.id === clientId)
						{
							var updatedEngagements = c.engagements.filter(
								(d: any) => d.id !== dataItem.id
							);
							return {
								...c,
								engagementCount: c.engagementCount - 1,
								engagements: updatedEngagements
							};
						}
						else
						{
							return c;
						}
					});
					setClients(updatedClients);
					EngagementStatusChangeSubject.next({
						eventType: EVentTypeEnum.Hide,
						clientId: clientId,
						payload: dataItem
					});
					props.onAction(EngagementCommand.Hide);
				});
			break;
		}
		case EngagementCommand.Delete: {
			var updatedDataItem = {
				...dataItem,
				clientId: clientId
			};
			setSelectedEngagement(updatedDataItem);
			setShowDeleteEngagementModal(true);
			break;
		}
		case EngagementCommand.CancelRequest: {
			coreService.cancelPendingEngagement(dataItem.id).then((res: any) =>
			{
				var updatedClients = clientsRef.current.map((c: any) =>
				{
					if (c.id === clientId)
					{
						var updatedEngagements = c.engagements.filter(
							(d: any) => d.id !== dataItem.id
						);
						return {
							...c,
							engagementCount: c.engagementCount - 1,
							engagements: updatedEngagements
						};
					}
					else
					{
						return c;
					}
				});
				setClients(updatedClients.filter((c) => c.engagementCount > 0));
				props.onAction(EngagementCommand.CancelRequest);
			});
			break;
		}
		case EngagementCommand.Unhide: {
			coreService
				.changeEngagementStatus(
					dataItem.id,
					EngagementPersonalizationStatus.Hidden,
					EngagementPersonalizationStatus.My
				)
				.then((res: any) =>
				{
					var updatedClients = clientsRef.current.map((c: any) =>
					{
						if (c.id === clientId)
						{
							var updatedEngagements = c.engagements.filter(
								(d: any) => d.id !== dataItem.id
							);
							return {
								...c,
								engagementCount: c.engagementCount - 1,
								engagements: updatedEngagements
							};
						}
						else
						{
							return c;
						}
					});
					setClients(updatedClients.filter((c) => c.engagementCount > 0));
					EngagementStatusChangeSubject.next({
						eventType: EVentTypeEnum.Unhide,
						clientId: clientId,
						payload: dataItem
					});
					props.onAction(EngagementCommand.Unhide);
				})
				.catch((e: any) =>
				{
					coreService
						.searchEngagements(clientId, clientFilter)
						.then((res: any) =>
						{
							const data: any = clientsRef.current.slice();
							const index = data.findIndex((d: any) => d.id === clientId);

							const engagementResults: IEngagementDetail[] = !!res.data && !!res.data.length ?
								res.data :
								[];

							data[index].engagements = engagementResults.map((r) => EngagementDetail.parseFields(r));
							data[index].engagementCount = res.data.length;
							setClients(data);
						});
				});
			break;
		}
		}
	};

	const deleteEngagement = (ToDelete: Boolean) =>
	{
		setShowDeleteEngagementModal(false);
		if (ToDelete === false)
		{
			return;
		}

		coreService.deleteEngagement(selectedEngagement.id).then((res: any) =>
		{
			var updatedClients = clientsRef.current.map((c: any) =>
			{
				if (c.id === selectedEngagement.clientId)
				{
					var updatedEngagements = c.engagements.filter(
						(d: any) => d.id !== selectedEngagement.id
					);
					return {
						...c,
						engagementCount: c.engagementCount - 1,
						engagements: updatedEngagements
					};
				}
				else
				{
					return c;
				}
			});
			setClients(updatedClients);
			props.onAction(EngagementCommand.Delete);
		});
	};

	const onSearch = (value: string) =>
	{
		setClientFilter((prev: IClientFilter) => ({
			...prev,
			searchText: searchText,
			pageIndex: 1
		}));
	};

	const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) =>
	{
		if (e.key === 'Enter')
		{
			setClientFilter((prev: IClientFilter) => ({
				...prev,
				searchText: searchText,
				pageIndex: 1
			}));
		}
	};

	const onSearchClear = () =>
	{
		setSearchText('');
		setClientFilter((prev: IClientFilter) => ({
			...prev,
			searchText: '',
			pageIndex: 1
		}));
	};

	const onSearchTextChange = (value: string) =>
	{
		setSearchText(value);
	};

	const addEngagement = () =>
	{
		navigate('/engagement/setup');
	};
	const refreshGridData = () =>
	{
		closeClientModal(null);
	};

	const closeFiltertModal = () =>
	{
		setShowFilterModal(false);
	};

	const calcOverflowBadgesAndSetShowMore = (allFilterVals: Array<string>) =>
	{
		const gridWidth = gridRef.current?.offsetWidth;
		const canvas = document.createElement('canvas');
		const context = canvas.getContext('2d');
		const fontSize = 16; // Set the font size to match your requirement
		var filterWidth = 0;
		var count = 0;
		if (context)
		{
			context.font = `${fontSize}px PwC Helvetica Neue`; // Set the font and size
			for (var i = 0; i < allFilterVals.length; i++)
			{
				const width = context.measureText(allFilterVals[i]).width;
				filterWidth = filterWidth + width + 35;

				if (gridWidth)
				{
					if (filterWidth > gridWidth - 350)
					{
						count = i;
						break;
					}
				}
			}
		}

		if (count > 0)
		{
			setShowMoreCount(allFilterVals.length - count + 1);
			const filtersToShow = allFilterVals.slice(0, count - 1);
			setActiveFilterData(filtersToShow);
		}
		else
		{
			setShowMoreCount(0);
			setActiveFilterData(allFilterVals);
		}
	};

	const onRemoveOneFilter = (val: string) =>
	{
		const updatedFilteredData: any = Object.fromEntries(
			Object.entries(filteredData).map(([key, value]) => [
				key,
				value.filter((x) => x !== val)
			])
		);
		setFilteredData(updatedFilteredData);
	};

	const onRemoveAll = () =>
	{
		setFilteredData(initialFilteredData);
	};

	return (
		<LocalizationProvider language="en-US">
			<IntlProvider locale="en">
				<div className="client-grid-wrapper">
					<Grid
						className={`client-grid ${
							total === 0 ? 'grid-pageSize-disable' : ''
						}`}
						style={{
							height: !loading ? 'fit-content' : '74vh',
							width: '100%'
						}}
						data={clients}
						skip={(clientFilter.pageIndex - 1) * clientFilter.pageSize}
						take={clientFilter.pageSize}
						total={total === 0 ? 1 : total}
						pageable={{
							buttonCount: 2,
							info: false,
							type: 'input',
							pageSizes: [10, 25],
							pageSizeValue: clientFilter.pageSize
						}}
						onPageChange={pageChange}
						detail={getDetailRowComponent}
						expandField="expanded"
						onExpandChange={expandChange}
					>
						<GridToolbar>
							<div ref={gridRef} className="client-engagements-header">
								<div className="client-engagements-header-search">
									<div className="margin-right">
										<Search
											searchType={'secondary'}
											onChange={onSearchTextChange}
											onClear={onSearchClear}
											onKeyDown={onSearchKeyDown}
											disabled={clients?.length === 0}
										/>
									</div>

									{/* <div className="my-engagement-buttons"> */}
									<div className="flex-button margin-right">
										<Button
											kind="tertiary"
											onClick={onSearch}
											disabled={clients?.length === 0 || searchText === ''}
										>
											{t('buttons.search')}
										</Button>
									</div>
									{/* commented partially built Filter functionality as per 43277 PBI
									<div className="flex-button margin-right">
										<Button
											kind="text"
											icon="icon-filter-outline"
											disabled={
												props.engagementPersonalizationStatus !== EngagementPersonalizationStatus.My
											}
											onClick={() => setShowFilterModal(true)}
										>
											{t('buttons.filters')}
										</Button>
									</div>*/}
									<div className="my-engagement-buttons">
										{/*<div className="flex-button margin-right">
												<Button
													kind="secondary"
													icon="icon-plus-outline"
													onClick={expandAll}
												>
													{t('buttons.joinEngagement')}
												</Button>
											</div>*/}
										<div className="flex-button">
											<Button
												icon="icon-plus-outline"
												onClick={addEngagement}
											>
												{t('buttons.addEngagement')}
											</Button>
										</div>
									</div>
								</div>
								{activeFilterData.length > 0 && (
									<div className="client-engagements-header-filters">
										<div className="filter-label">
											{t('filters.activeFilters')}
										</div>
										<div id="engFilters" className="engagement-filters">
											{activeFilterData.map((afData: string) =>
											{
												return (
													<div className="active-eng-filters" key={afData}>
														{afData}
														<Button
															kind="text"
															onClick={() => onRemoveOneFilter(afData)}
														>
															<span className="Appkit4-icon icon-close-outline ap-font-12"></span>
														</Button>
													</div>
												);
											})}
											{showMoreCount > 0 && (
												<div className="show-more-div">
													{`+${showMoreCount} ${t('filters.more')}`}
												</div>
											)}
										</div>

										<div className="engagement-filter-remove-all">
											<Button kind="text" onClick={() => onRemoveAll()}>
												{t('filters.removeAll')}
											</Button>
										</div>
									</div>
								)}
							</div>
						</GridToolbar>
						<Column
							field="clientName"
							title={t('client-grid-column.clientName') || undefined}
							headerCell={(props) => (
								<span className="k-cell-inner">
									<span
										className="k-link !k-cursor-default"
										style={{
											display: 'flex',
											justifyContent: 'flex-start'
										}}
									>
										<span className="client-column"> {props.title}</span>
									</span>
								</span>
							)}
							cell={(props: any) => (
								<td
									className={'k-table-td add-top-border client-name'}
									role="gridcell"
									aria-selected="false"
									data-grid-col-index="1"
								>
									<Highlighter
										highlightClassName="highlightClass"
										searchWords={[clientFilter?.searchText?.trim()]}
										autoEscape={true}
										textToHighlight={props.dataItem.clientName}
									/>{' '}
                  ({props.dataItem.engagementCount})
								</td>
							)}
						/>
						<Column
							field=""
							title={t('client-grid-column.actions') || undefined}
							headerCell={(props) => (
								<span className="k-cell-inner">
									<span
										className="k-link !k-cursor-default"
										style={{
											display: 'flex',
											justifyContent: 'end'
										}}
									>
										<span className="action-column"> {props.title}</span>
									</span>
								</span>
							)}
							cell={CommandCell}
						/>
					</Grid>
					{loading && (
						<div className="client-grid-loader">
							{' '}
							<Loading
								loadingType="circular"
								indeterminate={true}
								compact={false}
								stopPercent={99}
							></Loading>
						</div>
					)}
				</div>

				<DeleteEngagementModal
					visible={showDeleteEngagementModal}
					engagement={selectedEngagement}
					onDeleteEngagement={deleteEngagement}
				></DeleteEngagementModal>
				<ClientModal
					visible={showEditClientModal}
					onClose={closeClientModal}
					clientModalContext={{
						...clientModalContext,
						clientName: clientModalContext?.clientName,
						clientId: clientModalContext?.id
					}}
					refreshGridData={refreshGridData}
					title={t('client-popup.editClient')}
				/>
				<FilterModal
					visible={showFilterModal}
					onClose={closeFiltertModal}
					engagementPersonalizationStatus={
						props.engagementPersonalizationStatus
					}
					filteredData={filteredData}
					setFilteredData={setFilteredData}
				/>
			</IntlProvider>
		</LocalizationProvider>
	);
};
