import { useState } from 'react';
import { NotesEditor } from '../../NotesEditor/NotesEditor';
import { NumericInputControl } from '../NumericInputControl';
import { INumericInputWithNotesControlProps } from './INumericInputWithNotesControlProps';
import { Utils } from 'src/common/utils/utils';

export function NumericInputWithNotesControl(props: INumericInputWithNotesControlProps): JSX.Element
{
	// used in order to force update child component into retaining focus after modal has taken focus away
	const [forceFocusIndex, setForceFocusIndex] = useState<number | undefined>(undefined);

	const onNotesClose = (): void =>
	{
		setForceFocusIndex(Utils.generateTempId());
	};

	const onRenderNotesEditor = (): JSX.Element | undefined =>
	{
		if (!!props.displayNotes)
		{
			return <NotesEditor
				notes={props.notes}
				onSave={props.onNotesSave}
				onClose={onNotesClose}
			/>;
		}

		return undefined;
	};

	return <>
		<div className='numeric-input-control'>
			<NumericInputControl
				id={props.id}
				isRequired={!!props.isRequired}
				isDisabled={!!props.isDisabled}
				isReadonly={!!props.isReadonly}
				regex={props.regex}
				maxLength={props.maxLength}
				placeholder={props.placeholder}
				value={props.value}
				forceFocusIndex={forceFocusIndex}
				onRenderPrefixTemplate={onRenderNotesEditor}
				onBlur={props.onBlur}
				onValueChange={props.onValueChange}
				isError={props.isError}
				errorMessage={props.errorMessage}
				onRenderErrorMessage={props.onRenderErrorMessage}
			/>
		</div>
	</>;
}