import { useEffect, useRef, useState, useContext } from 'react';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { Reg5907HeaderTable } from 'src/common/components/Reg5907Grid/components/Reg5907HeaderTable';
import { Reg5907Grid } from 'src/common/components/Reg5907Grid/Reg5907Grid';
import { Reg5907AggregateRows } from 'src/common/components/Reg5907Grid/components/Reg5907AggregateRows';
import { GridSeperator } from 'src/common/components/GridSeperator/GridSeperator';
import { INameId } from 'src/common/types/interfaces/INameId';
import { Utils } from 'src/common/utils/utils';
import { Reg5907AdjustmentsHelpers } from './Reg5907AdjustmentsHelpers';
import { SaveProgressBar } from 'src/common/components/SaveProgressBar/SaveProgressBar';
import { NotificationService } from 'src/core/services/notificationService';
import './Reg5907Adjustments.scss';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { Button, Notification } from '@appkit4/react-components';
import { DeleteModal } from 'src/common/components/DeleteModal/DeleteModal';
import {
	RecordsByGridType,
	ValuesByAffiliateId as ValuesByAffiliateTaxYearEndId,
	ValuesByDescriptionYear,
	ValuesByYear,
	gridIds,
	initialRecordData,
	initialRowData
} from './Reg5907AdjustmentsConstants';
import {
	Reg5907AdjustmentsRow,
	Reg5907AdjustmentsSubmissionToken,
	IReg5907AdjustmentsLookupRecord,
	IReg5907AdjustmentsRow,
	IReg5907AdjustmentsSubmission,
	IReg5907AdjustmentsYear,
	IReg5907AdjustmentsYearSubmission,
	ISummationBase
} from 'src/common/types/interfaces/IReg5907Adjustments';
import { useTranslation } from 'react-i18next';
import { Reg5907AdjustmentsTypes, Reg5907AdjustmentsFieldNames } from 'src/common/types/enums/Reg5907Adjustments';
import AffiliateContext from 'src/common/components/SharedDataContext/AffiliateContext';

const Reg5907Adjustments = (props: {handleChange?: (change: boolean) => void}) =>
{
	const [isLoading, setIsLoading] = useState(true);
	const [isSaving, setIsSaving] = useState(false);
	const [isValidating, setIsValidating] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isNoFAHistory, setIsNoFAHistory] = useState(false);

	const [displayNotes, setDisplayNotes] = useState(false);

	const [hasChange, setHasChange] = useState(false);

	const affiliateContext = useContext(AffiliateContext);

	const [reg5907AdjustmentsData, setReg5907AdjustmentsData] = useState<IReg5907AdjustmentsYear[]>([]);

	const [initialData, setInitialData] = useState<{ [gridId: string]: IReg5907AdjustmentsRow[][] }>(initialRowData);
	const [allTaxPayersData, setAllTaxPayersData] = useState<IReg5907AdjustmentsRow[][]>([]);
	const [taxPayerOnlyData, setTaxPayerOnlyData] = useState<IReg5907AdjustmentsRow[][]>([]);


	const [allTaxPayersDescriptionOptions, setAllTaxPayersDescriptionOptions] = useState<INameId[]>([]);
	const [taxPayerOnlyDescriptionOptions, setTaxPayerOnlyDescriptionOptions] = useState<INameId[]>([]);

	const [summationByYear, setSummationByYear] = useState<ISummationBase[]>([]);
	const [allTaxPayersSubTotalsByYear, setAllTaxPayersSubTotalsByYear] = useState<ISummationBase[]>([]);
	const [taxPayerOnlySubTotalsByYear, setTaxPayerOnlySubTotalsByYear] = useState<ISummationBase[]>([]);

	const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
	const [selectedRowDeletion, setSelectedRowDeletion] = useState<IReg5907AdjustmentsRow[]>([]);

	const { t } = useTranslation(
		'input',
		{
			keyPrefix: 'reg5907Adjustments'
		}
	);

	const tableRefs = Array.from(
		{
			length: Object.keys(gridIds).length
		},
		// eslint-disable-next-line react-hooks/rules-of-hooks
		() => useRef<HTMLDivElement>(null)
	);

	const createKey = (...keys: (string | number)[]): string =>
	{
		return keys
			.map((k) => `${k}`)
			.join('::');
	};

	const splitKey = (key: string): string[] =>
	{
		return key.split('::');
	};

	const getKey = (key: string, indexToRetrieve: number): string =>
	{
		if (!!key)
		{
			const keys = splitKey(key);

			if (!!keys && !!keys.length && indexToRetrieve in keys)
			{
				return keys[indexToRetrieve];
			}
		}

		return '';
	};

	const getRecordsByRows = (
		gridId: string,
		allRecords: IReg5907AdjustmentsYear[],
		valuesByDescriptionYear: ValuesByDescriptionYear
	): IReg5907AdjustmentsRow[][] =>
	{
		const allRows: IReg5907AdjustmentsRow[][] = [];

		let rowContinuationIndex = 0;

		if (!!valuesByDescriptionYear)
		{
			const descriptionKeys = Object.keys(valuesByDescriptionYear);

			// loop for every year to determine which array for a given year is longest and fill values for each
			for (const descriptionKey of descriptionKeys)
			{
				const longestArray = Utils.getKeyAndLengthOfLongestArrayInDictionary(
					valuesByDescriptionYear[descriptionKey]
				);

				// get array of longest and seperate from remainder of records.
				// this will be the basis on which the remainder of records are built
				// the longest array already has the maximum number of records, so need to fill remainder of records
				const longestYearRecords = [
					...valuesByDescriptionYear[descriptionKey][longestArray.key]
				];

				const yearRecordsExcludingLongest: ValuesByYear = {
				};

				const yearKeys = Object.keys(valuesByDescriptionYear[descriptionKey]);

				for (const yearKey of yearKeys)
				{
					if (yearKey !== longestArray.key)
					{
						yearRecordsExcludingLongest[yearKey] = valuesByDescriptionYear[descriptionKey][yearKey];
					}
				}

				// fill remainder of records for each year that does not have the full amount of records
				for (const yearKey of Object.keys(yearRecordsExcludingLongest))
				{
					// get affiliate year field for entity by year (assume it will always be found)
					const yearEntity = allRecords
						.find((r) => createKey(r.taxYearEnd, r.affiliateTaxYearEndId) === yearKey)!;

					if (yearRecordsExcludingLongest[yearKey].length < longestYearRecords.length)
					{
						const remainingCount = longestYearRecords.length - yearRecordsExcludingLongest[yearKey].length;

						const taxPayerId = affiliateContext?.engagementDetail.taxPayerId;

						for (let i = 0; i < remainingCount; i++)
						{
							yearRecordsExcludingLongest[yearKey]
								.push(
									new Reg5907AdjustmentsRow({
										descriptionId: +getKey(descriptionKey, 0),
										taxYearEnd: yearEntity.taxYearEnd,
										affiliateTaxYearEndId: yearEntity.affiliateTaxYearEndId,
										rowIndex: +getKey(descriptionKey, 1),
										calculatingCurrencyId: yearEntity.calculatingCurrencyId,
										taxPayerId: gridId === gridIds.allTaxPayers ? undefined :
											(gridId === gridIds.taxPayerOnly ? taxPayerId : undefined)
									})
								);
						}
					}
				}

				// concat the longest and remaining back together for processing into row records
				const allRecordsByYear = {
					[longestArray.key]: longestYearRecords,
					...yearRecordsExcludingLongest
				};

				// add the filler records (while tracking position of rows for each new iteration of description)
				for (let i = 0; i < longestArray.length; i++)
				{
					for (const year of yearKeys)
					{
						if ((allRows.length - 1) < (i + rowContinuationIndex))
						{
							allRows.push([]);
						}

						allRows[(i + rowContinuationIndex)].push(allRecordsByYear[year][i]);
					}
				}

				rowContinuationIndex += longestArray.length;
			}
		}

		return allRows;
	};

	const loadReg5907Adjustments = async (
		engagementId: number,
		affiliateId: number
	): Promise<void> =>
	{
		try
		{
			const response = await engagementService
				.getReg5907Adjustments(engagementId, affiliateId);

			const records: IReg5907AdjustmentsYear[] = !!response.data && !!response.data.result ?
				response.data.result.sort(Reg5907AdjustmentsHelpers.sortFAYear) :
				[];

			if (!!records && !!records.length)
			{
				const allTaxPayersDescriptions: number[] = [];
				const taxPayerOnlyDescriptions: number[] = [];

				// get all the unique descriptions across years for each type of amount
				const filterDescriptions = (
					records: IReg5907AdjustmentsRow[],
					uniqueDescriptions: number[]
				): number[] =>
				{
					if (
						!!records &&
						!!records.length
					)
					{
						return records
							.filter((d) => !uniqueDescriptions.some((ud) => ud === d.descriptionId))
							.map((d) => d.descriptionId);
					}

					return [];
				};

				for (const r of records)
				{
					allTaxPayersDescriptions.push(
						...filterDescriptions(
							r[Reg5907AdjustmentsSubmissionToken.AllTaxPayers],
							allTaxPayersDescriptions
						)
					);
					taxPayerOnlyDescriptions.push(
						...filterDescriptions(
							r[Reg5907AdjustmentsSubmissionToken.TaxPayerOnly],
							taxPayerOnlyDescriptions
						)
					);
				}

				const getValuesByDescriptionRowIndexYear = (
					valuesByDescriptionYearStart: ValuesByDescriptionYear,
					rows: IReg5907AdjustmentsRow[],
					year: string,
					affiliateTaxYearEndId: number,
					calculatingCurrencyId: number
				): ValuesByDescriptionYear =>
				{
					const valuesByDescriptionYear: ValuesByDescriptionYear = {
						...valuesByDescriptionYearStart
					};

					if (!!rows && !!rows.length)
					{
						for (const row of rows)
						{
							const allYearsBase: { [year: string]: [] } = {
							};

							records.forEach((r) =>
							{
								allYearsBase[createKey(r.taxYearEnd, r.affiliateTaxYearEndId)] = [];
							});

							const indexKey = createKey(row.descriptionId, row.rowIndex!);

							if (!valuesByDescriptionYear[indexKey])
							{
								valuesByDescriptionYear[indexKey] = {
								};
							}

							const yearKey = createKey(year, affiliateTaxYearEndId);
							if (!valuesByDescriptionYear[indexKey][yearKey])
							{
								valuesByDescriptionYear[indexKey] = {
									...allYearsBase
								};
							}

							valuesByDescriptionYear[indexKey][yearKey]
								.push({
									...row,
									notes: !!row.notes ? row.notes : undefined,
									taxYearEnd: year,
									affiliateTaxYearEndId: affiliateTaxYearEndId,
									calculatingCurrencyId: calculatingCurrencyId
								});
						}
					}

					return valuesByDescriptionYear;
				};

				// build income/expense/adjustments records
				let allTaxPayerValuesByDescriptionYear: ValuesByDescriptionYear = {
				};
				let taxPayerOnlyValuesByDescriptionYear: ValuesByDescriptionYear = {
				};

				// for every description there will be an entity (if none exists then empty)
				for (const record of records)
				{
					allTaxPayerValuesByDescriptionYear = {
						...getValuesByDescriptionRowIndexYear(
							allTaxPayerValuesByDescriptionYear,
							record[Reg5907AdjustmentsSubmissionToken.AllTaxPayers],
							record.taxYearEnd,
							record.affiliateTaxYearEndId,
							record.calculatingCurrencyId
						)
					};
					taxPayerOnlyValuesByDescriptionYear = {
						...getValuesByDescriptionRowIndexYear(
							taxPayerOnlyValuesByDescriptionYear,
							record[Reg5907AdjustmentsSubmissionToken.TaxPayerOnly],
							record.taxYearEnd,
							record.affiliateTaxYearEndId,
							record.calculatingCurrencyId
						)
					};
				}

				const allTaxPayers = getRecordsByRows(gridIds.allTaxPayers, records, allTaxPayerValuesByDescriptionYear);
				const taxPayerOnly = getRecordsByRows(gridIds.taxPayerOnly, records, taxPayerOnlyValuesByDescriptionYear);

				// set all data in state, including starting values
				setInitialData({
					[gridIds.allTaxPayers]: cloneDeep(allTaxPayers),
					[gridIds.taxPayerOnly]: cloneDeep(taxPayerOnly)
				});

				setAllTaxPayersData([...allTaxPayers]);
				setTaxPayerOnlyData([...taxPayerOnly]);

				setReg5907AdjustmentsData([...records]);
			}
			else
			{
				setIsNoFAHistory(true);
			}
		}
		catch (error)
		{
		}
		finally
		{
			setIsLoading(false);
		}
	};

	const resetAndLoadData = async (): Promise<void> =>
	{
		setIsLoading(true);
		setIsSaving(false);
		setIsSubmitted(false);
		setIsValidating(false);
		setHasChange(false);
		!!props.handleChange && props.handleChange(false);

		setInitialData({
			...initialRowData
		});

		setReg5907AdjustmentsData([]);
		setAllTaxPayersData([]);
		setTaxPayerOnlyData([]);

		await loadReg5907Adjustments(
			affiliateContext?.engagementDetail.id!,
			affiliateContext?.affiliateDetail.affiliateId!
		);
	};

	const loadData = async (
		engagementId: number,
		affiliateId: number
	): Promise<void> =>
	{
		await loadLookups();

		await loadReg5907Adjustments(
			engagementId,
			affiliateId
		);
	};

	const mapLookups = (
		allLookups: IReg5907AdjustmentsLookupRecord[]
	): INameId[] =>
	{
		return allLookups
			.map((l) => ({
				id: l.id,
				name: l.description
			}))
			.sort();
	};

	const loadLookups = async (
	): Promise<{
		allTaxPayersOptions: INameId[],
		taxPayerOnlyOptions: INameId[];
	}> =>
	{
		try
		{
			const response = await engagementService
				.getReg5907AdjustmentsLookups();

			const allLookups: IReg5907AdjustmentsLookupRecord[] = !!response.data &&
			!!response.data.result &&
			!!response.data.result.length ?
				response.data.result :
				[];

			const allTaxPayersOptions = [
				...mapLookups(allLookups)
			];
			setAllTaxPayersDescriptionOptions(allTaxPayersOptions);

			const taxPayerOnlyOptions = [
				...mapLookups(allLookups)
			];
			setTaxPayerOnlyDescriptionOptions(taxPayerOnlyOptions);

			return {
				allTaxPayersOptions,
				taxPayerOnlyOptions
			};
		}
		catch (error)
		{
			return {
				allTaxPayersOptions: [],
				taxPayerOnlyOptions: []
			};
		}
	};

	const updateValueFields = (
		startingData: IReg5907AdjustmentsRow[][],
		fieldName: Reg5907AdjustmentsFieldNames,
		rowIndex: number,
		index: number,
		value: string
	): IReg5907AdjustmentsRow[][] =>
	{
		const updatedRows = cloneDeep(startingData);
		const yearContent = cloneDeep(updatedRows[rowIndex][index]);

		if (fieldName === Reg5907AdjustmentsFieldNames.Amount)
		{
			yearContent[fieldName] = Utils.isValidNumber(value) ?
				+value :
				undefined;
		}
		else if (fieldName === Reg5907AdjustmentsFieldNames.Notes)
		{
			yearContent[fieldName] = !!value ? value : undefined;
		}

		updatedRows[rowIndex][index] = yearContent;

		return updatedRows;
	};

	const updateValue = (
		id: string,
		fieldName: Reg5907AdjustmentsFieldNames,
		rowIndex: number,
		index: number,
		value: string
	): void =>
	{
		if (id === gridIds.allTaxPayers)
		{
			setAllTaxPayersData([
				...updateValueFields(
					allTaxPayersData,
					fieldName,
					rowIndex,
					index,
					value
				)
			]);
		}
		else if (id === gridIds.taxPayerOnly)
		{
			setTaxPayerOnlyData([
				...updateValueFields(
					taxPayerOnlyData,
					fieldName,
					rowIndex,
					index,
					value
				)
			]);
		}
	};

	const debouncedUpdateValue = debounce(updateValue, 400);

	const onAddRow = (gridId: string): void =>
	{
		const addNewRow = (
			startingData: IReg5907AdjustmentsRow[][]
		): IReg5907AdjustmentsRow[][] =>
		{
			const updatedRows = cloneDeep(startingData);

			const newRow: IReg5907AdjustmentsRow[] = [];
			const rowIndex = Utils.generateTempId();
			const taxPayerId = affiliateContext?.engagementDetail.taxPayerId;
			for (const data of reg5907AdjustmentsData)
			{
				newRow.push(
					new Reg5907AdjustmentsRow({
						taxYearEnd: data.taxYearEnd,
						affiliateTaxYearEndId: data.affiliateTaxYearEndId,
						rowIndex: rowIndex,
						calculatingCurrencyId: data.calculatingCurrencyId,
						taxPayerId: gridId === gridIds.allTaxPayers ? undefined : (gridId === gridIds.taxPayerOnly ? taxPayerId : undefined)
					})
				);
			}

			updatedRows.push(newRow);
			return updatedRows;
		};

		if (gridId === gridIds.allTaxPayers)
		{
			setAllTaxPayersData([...addNewRow(allTaxPayersData)]);
		}
		else if (gridId === gridIds.taxPayerOnly)
		{
			setTaxPayerOnlyData([...addNewRow(taxPayerOnlyData)]);
		}
	};

	const updateDescriptionFields = (
		startingData: IReg5907AdjustmentsRow[][],
		rowIndex: number,
		value: number
	): IReg5907AdjustmentsRow[][]  =>
	{
		const updatedRows = cloneDeep(startingData);
		const rowData = cloneDeep(updatedRows[rowIndex]);

		rowData.forEach((d) =>
		{
			d.descriptionId = value;
		});

		updatedRows[rowIndex] = [...rowData];

		return updatedRows;
	};

	const updateDescription = (
		id: string,
		rowIndex: number,
		value: number
	): void =>
	{
		if (id === gridIds.allTaxPayers)
		{
			setAllTaxPayersData([...updateDescriptionFields(allTaxPayersData, rowIndex, value)]);
		}
		else if (id === gridIds.taxPayerOnly)
		{
			setTaxPayerOnlyData([...updateDescriptionFields(taxPayerOnlyData, rowIndex, value)]);
		}
	};

	const triggerValueUpdate = (
		gridId: string,
		fieldName: Reg5907AdjustmentsFieldNames,
		rowIndex: number,
		index: number,
		value: string
	) =>
	{
		debouncedUpdateValue(gridId, fieldName, rowIndex, index, value);
	};

	const validateRow = (
		initialRowsById: { [id: number]: IReg5907AdjustmentsRow },
		updatedRow: IReg5907AdjustmentsRow[],
		startingRecordsByYear?: ValuesByAffiliateTaxYearEndId
	): [boolean, ValuesByYear] =>
	{
		let isValid = true;

		let recordsByYear: ValuesByAffiliateTaxYearEndId = {
		};

		if (!!startingRecordsByYear)
		{
			recordsByYear = cloneDeep(startingRecordsByYear);
		}
		else
		{
			reg5907AdjustmentsData
				.forEach((d) =>
				{
					recordsByYear[d.affiliateTaxYearEndId] = [];
				});
		}

		for (let j = 0; j < updatedRow.length; j++)
		{
			const record = updatedRow[j];

			if (
				!record.descriptionId &&
				(
					!!Utils.isValidNumber(`${record.amount}`) ||
					!!record.notes
				)
			)
			{
				isValid = false;
				break;
			}

			// check if record exists in initial list (if not then consider record to be new)
			// check that the record in question is different than original state and add to list for update
			// deleted records do not need to be considered as delete will update the initial comparison list
			if (
				(
					record.id! <= 0 &&
					(
						!!Utils.isValidNumber(`${record.amount}`) ||
						!!record.notes
					)
				) ||
				(
					record.id! > 0 &&
					!isEqual(record, initialRowsById[record.id!])
				)
			)
			{
				recordsByYear[record.affiliateTaxYearEndId].push(record);
			}
		}

		return [isValid, recordsByYear];
	};

	const isValidRows = (
		initialDataRows: IReg5907AdjustmentsRow[][],
		updatedData: IReg5907AdjustmentsRow[][]
	): [boolean, ValuesByYear] =>
	{
		// flatten initial data rows for quick comparison
		const flattenedInitialRowsById: { [id: number]: IReg5907AdjustmentsRow } = {
		};

		initialDataRows
			.flat()
			.concat()
			.forEach((record) =>
			{
				flattenedInitialRowsById[record.id!] = record;
			});


		let recordsByYear: ValuesByAffiliateTaxYearEndId = {
		};

		reg5907AdjustmentsData
			.forEach((d) =>
			{
				recordsByYear[d.affiliateTaxYearEndId] = [];
			});


		// iterate each row and each record to check validity
		// positionally every entity is the same in the rows/records,
		// so can use indexing for matching of records,
		// and if index cannot be found then can be considered a new row (will use id to determine)
		let isValid = true;

		for (let i = 0; i < updatedData.length && isValid; i++)
		{
			const updatedRow = updatedData[i];

			[isValid, recordsByYear] = validateRow(
				flattenedInitialRowsById,
				updatedRow,
				recordsByYear
			);
		}

		return [isValid, recordsByYear];
	};

	const isValid = (): [boolean, RecordsByGridType] =>
	{
		const dataToUpdate = {
			...initialRecordData
		};

		const [isAllTaxPayersValid, permanentRecordsToUpdate] = isValidRows(initialData[gridIds.allTaxPayers], allTaxPayersData);
		dataToUpdate[gridIds.allTaxPayers] = permanentRecordsToUpdate;

		if (!isAllTaxPayersValid)
		{
			return [isAllTaxPayersValid, dataToUpdate];
		}

		const [isTaxPayersOnlyValid, temporaryRecordsToUpdate] = isValidRows(initialData[gridIds.taxPayerOnly], taxPayerOnlyData);
		dataToUpdate[gridIds.taxPayerOnly] = temporaryRecordsToUpdate;

		if (!isTaxPayersOnlyValid)
		{
			return [isTaxPayersOnlyValid, dataToUpdate];
		}

		return [true, dataToUpdate];
	};

	const checkChange = (
		initialData: IReg5907AdjustmentsRow[][],
		updatedData: IReg5907AdjustmentsRow[][]
	): boolean =>
	{
		const newRows: IReg5907AdjustmentsRow[][] = [];
		const existingRows: IReg5907AdjustmentsRow[][] = [];

		for (const row of updatedData)
		{
			if (row.every((record) => record.id! < 0))
			{
				newRows.push([...row]);
			}
			else
			{
				existingRows.push([...row]);
			}
		}

		const newRecordChanges = (rows: IReg5907AdjustmentsRow[][]) =>
		{
			return rows
				.some((row) =>
				{
					return row.some((record) =>
					{
						return !!Utils.isValidNumber(`${record.amount}`) ||
							!!record.notes;
					});
				});
		};

		// check if different number of rows and if new rows have any valid changes for submission
		if (
			initialData.length !== updatedData.length &&
			!!newRecordChanges(newRows)
		)
		{
			return true;
		}

		// check if entities are same
		if (!isEqual(initialData, existingRows))
		{
			return true;
		}

		return false;
	};

	const hasChanges = (): boolean =>
	{
		const changed= checkChange(initialData[gridIds.allTaxPayers], allTaxPayersData) ||
			checkChange(initialData[gridIds.taxPayerOnly], taxPayerOnlyData);

		setHasChange(!!changed);
		!!props.handleChange && props.handleChange(!!changed);

		return changed;
	};

	const transformSubmissionEntities = (
		records: IReg5907AdjustmentsYearSubmission[],
		recordsByAffiliate: ValuesByAffiliateTaxYearEndId,
		submissionToken: Reg5907AdjustmentsSubmissionToken
	): IReg5907AdjustmentsYearSubmission[] =>
	{
		for (const affiliateIdKey of Object.keys(recordsByAffiliate))
		{
			if (
				!!recordsByAffiliate[+affiliateIdKey] &&
				!!recordsByAffiliate[+affiliateIdKey].length
			)
			{
				let index = records.findIndex((r) => r.affiliateTaxYearEndId === +affiliateIdKey);
				if (index < 0)
				{
					const initial: IReg5907AdjustmentsYearSubmission = {
						affiliateTaxYearEndId: +affiliateIdKey,
						[Reg5907AdjustmentsSubmissionToken.AllTaxPayers]: [],
						[Reg5907AdjustmentsSubmissionToken.TaxPayerOnly]: []
					};

					// set the index based on the length of the newly updated array
					index = records.push({
						...initial
					}) - 1;
				}

				const submissionRecords = recordsByAffiliate[+affiliateIdKey]
					.map((r) =>
					{
						return {
							descriptionId: r.descriptionId,
							id: !!r.id && r.id >= 0 ?
								r.id :
								undefined,
							amount: r.amount,
							notes: r.notes,
							currencyId: r.calculatingCurrencyId,
							rowIndex: r.rowIndex,
							taxPayerId: r.taxPayerId
						};
					});

				// add to list based on existing index
				records[index] = {
					...records[index],
					[submissionToken]: submissionRecords
				};
			}
		}

		return records;
	};

	const stopSaveProgressBar = async (): Promise<void> =>
	{
		await Utils.timeout(500);
		setIsSaving(false);
	};

	const onDeleteRowWarning = (selectedRow: IReg5907AdjustmentsRow[]): void =>
	{
		setSelectedRowDeletion(selectedRow);
		setDisplayDeleteModal(true);
	};

	const onDelete = async (): Promise<void> =>
	{
		try
		{
			await engagementService
				.deleteReg5907Adjustment(
					affiliateContext?.engagementDetail.id!,
					affiliateContext?.affiliateDetail.affiliateId!,
					selectedRowDeletion.filter((c) => c.id > 0).map((c) => c.id)
				);

			setDisplayDeleteModal(false);
			setSelectedRowDeletion([]);

			triggerDeleteSuccessNotification();
			resetAndLoadData();
		}
		catch (error)
		{
			triggerErrorNotification(
				t('deleteError')
			);
		}
	};


	const onSave = async (): Promise<void> =>
	{
		try
		{
			setIsValidating(true);
			setIsSubmitted(true);

			if (!!hasChanges())
			{
				const [isValidRecords, recordsByType] = isValid();

				if (!!isValidRecords)
				{
					setIsSaving(true);

					let updatedRecords: IReg5907AdjustmentsYearSubmission[] = [];

					updatedRecords = transformSubmissionEntities(
						updatedRecords,
						recordsByType[gridIds.allTaxPayers],
						Reg5907AdjustmentsSubmissionToken.AllTaxPayers
					);

					updatedRecords = transformSubmissionEntities(
						updatedRecords,
						recordsByType[gridIds.taxPayerOnly],
						Reg5907AdjustmentsSubmissionToken.TaxPayerOnly
					);

					const submissionData: IReg5907AdjustmentsSubmission = {
						engagementId: affiliateContext?.engagementDetail.id!,
						affiliateId: affiliateContext?.affiliateDetail.affiliateId!,
						reg5907Adjustments: [...updatedRecords]
					};

					await engagementService.updateReg5907Adjustment(submissionData);

					triggerSuccessNotification();

					await stopSaveProgressBar();

					resetAndLoadData();
				}
				else
				{
					triggerWarningNotification();
				}
			}
		}
		catch (error)
		{
			triggerErrorNotification(
				t('saveError')
			);

			await stopSaveProgressBar();
		}
		finally
		{
			setIsValidating(false);
		}
	};


	const onRenderSaveButton = (): JSX.Element =>
	{
		return <Button
			kind={'primary'}
			disabled={
				isLoading ||
				isSaving ||
				isValidating ||
				!hasChange
			}
			onClick={onSave}
		>
			{
				t('saveButtonText')
			}
		</Button>;
	};


	const triggerWarningNotification = async (): Promise<void> =>
	{
		const ele = (
			<Notification
			  message={t('validationWarning')}
			  status={'warning'}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};

	const triggerErrorNotification = async (message: string): Promise<void> =>
	{
		const ele = (
			<Notification
			  message={message}
			  status={'error'}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};

	const triggerSuccessNotification = async (): Promise<void> =>
	{
		const ele = (
			<Notification
			  message={t('successMessage')}
			  status={'success'}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};

	const triggerDeleteSuccessNotification = async (): Promise<void> =>
	{
		const ele = (
			<Notification
			  message={t('deleteSuccessMessage')}
			  status={'success'}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};

	const summationBase: ISummationBase = {
		[Reg5907AdjustmentsTypes.TotalAdjustments]: 0
	};

	const sumRecordsByYear = (
		records: IReg5907AdjustmentsRow[][]
	): ISummationBase[] =>
	{
		const sumsByYear: ISummationBase[] = [];

		if (!!records)
		{
			// iterate each and sum all records based on array position
			// index in row records represents years ordered
			for (const row of records)
			{
				if (!!row.length)
				{
					for (let i = 0; i < row.length; i++)
					{
						if ((sumsByYear.length - 1) < i)
						{
							sumsByYear.push({
								...summationBase
							});
						}

						const amount: number = !!Utils.isValidNumber(`${row[i].amount}`) ?
							row[i].amount! :
							0;

						sumsByYear[i][Reg5907AdjustmentsTypes.TotalAdjustments] =
						Utils.areAllNullOrUndefined(row[i].amount,sumsByYear[i][Reg5907AdjustmentsTypes.TotalAdjustments])? undefined :
							Utils.add(amount,sumsByYear[i][Reg5907AdjustmentsTypes.TotalAdjustments]);
					}
				}
			}
		}
		else
		{
			const years = !!reg5907AdjustmentsData.length ?
				reg5907AdjustmentsData.map((d) => d.taxYearEnd) :
				[];

			if (!!years && !!years.length)
			{
				// create dummy data for each year to render 0 for all values as there is no data
				years.forEach((y) =>
				{
					sumsByYear.push({
						...summationBase
					});
				});
			}
		}

		return sumsByYear;
	};

	useEffect(
		() =>
		{
			setIsLoading(true);

			if (
				!!affiliateContext&&
				!!affiliateContext?.engagementDetail.id&&
				!!affiliateContext?.affiliateDetail.affiliateId
			)
			{
				loadData(
					affiliateContext?.engagementDetail.id!,
					affiliateContext?.affiliateDetail.affiliateId!
				);
			}
		},
		[affiliateContext]
	);

	useEffect(
		() =>
		{
			if (
				!!allTaxPayersData &&
				!!taxPayerOnlyData
			)
			{
				const sumsByYear: ISummationBase[] = sumRecordsByYear(
					allTaxPayersData.concat(taxPayerOnlyData)
				);

				setSummationByYear(sumsByYear);

				const sumsByAllTaxPayersYear: ISummationBase[] = sumRecordsByYear(
					allTaxPayersData
				);

				setAllTaxPayersSubTotalsByYear(sumsByAllTaxPayersYear);

				const sumsByTaxPayerOnlyYear: ISummationBase[] = sumRecordsByYear(
					taxPayerOnlyData
				);

				setTaxPayerOnlySubTotalsByYear(sumsByTaxPayerOnlyYear);
			}
		},
		[
			allTaxPayersData,
			taxPayerOnlyData
		]
	);

	useEffect(
		() =>
		{
			hasChanges();
		},
		[
			allTaxPayersData,
			taxPayerOnlyData
		]
	);

	useEffect(
		() =>
		{
			const handleScroll = (index: number) => (event: any) =>
			{
				tableRefs
					.forEach((ref, i) =>
					{
						if (i !== index && ref.current)
						{
							ref.current.scrollLeft = event.target.scrollLeft;
						}
					});
			};

			tableRefs
				.forEach((ref, index) =>
				{
					if (!!ref.current)
					{
						ref.current.addEventListener('scroll', handleScroll(index));
					}
				});


			return () =>
			{
				tableRefs
					.forEach((ref, index) =>
					{
						if (!!ref.current)
						{
							ref.current.removeEventListener('scroll', handleScroll(index));
						}
					});
			};
		},
		[]
	);

	return (
		<>
			{
				!isNoFAHistory &&
			<div
				className={'reg-5907-adjustments'}
				style={{
					width: isLoading ? '100%' : 'inherit'
				}}
			>
				<div className={'action-buttons'}>
					{
						onRenderSaveButton()
					}
				</div>
				<div>
					<SaveProgressBar
						display={isSaving}
						message={t('saveInProgressMessage') || ''}
					/>
				</div>
				<div className={'grid-container'}>
					<div>
						<Reg5907HeaderTable
							id={gridIds.faTaxationDetails}
							customRef={tableRefs[0]}
							data={reg5907AdjustmentsData}
							isLoading={isLoading}
							isDisabled={isValidating || isSaving}
							validate={isSubmitted}
							onToggleNotes={(isNotesDisplayed) =>
							{
								setDisplayNotes(isNotesDisplayed);
							}}
						/>
					</div>
					<div>
						<Reg5907Grid
							id={gridIds.allTaxPayers}
							isLoading={isLoading}
							isDisabled={isValidating || isSaving}
							validate={isSubmitted}
							displayNotes={displayNotes}
							customRef={tableRefs[1]}
							rowData={allTaxPayersData}
							descriptionOptions={allTaxPayersDescriptionOptions}
							reg5907Adjustments={reg5907AdjustmentsData}
							subTotalsByYear={allTaxPayersSubTotalsByYear}
							title={t('allTaxpayersGridTitle')}
							addRowButtonText={t('allTaxpayersGridAddButtonText')}
							onRowAdd={() =>
							{
								onAddRow(gridIds.allTaxPayers);
							}}
							onDescriptionUpdate={(rowIndex, value) =>
							{
								updateDescription(gridIds.allTaxPayers, rowIndex, value);
							}}
							onValueUpdate={(fieldName, rowIndex, index, value) =>
							{
								triggerValueUpdate(gridIds.allTaxPayers, fieldName, rowIndex, index, value);
							}}
							onDeleteRow={onDeleteRowWarning}
						/>
					</div>
					{
						!isLoading &&
						<GridSeperator />
					}
					<div>
						<Reg5907Grid
							id={gridIds.taxPayerOnly}
							isLoading={isLoading}
							isDisabled={isValidating || isSaving}
							validate={isSubmitted}
							displayNotes={displayNotes}
							customRef={tableRefs[2]}
							rowData={taxPayerOnlyData}
							descriptionOptions={taxPayerOnlyDescriptionOptions}
							reg5907Adjustments={reg5907AdjustmentsData}
							subTotalsByYear={taxPayerOnlySubTotalsByYear}
							title={t('reportingTaxpayersOnlyGridTitle')}
							addRowButtonText={t('reportingTaxpayersOnlyGridAddButtonText')}
							onRowAdd={() =>
							{
								onAddRow(gridIds.taxPayerOnly);
							}}
							onDescriptionUpdate={(rowIndex, value) =>
							{
								updateDescription(gridIds.taxPayerOnly, rowIndex, value);
							}}
							onValueUpdate={(fieldName, rowIndex, index, value) =>
							{
								triggerValueUpdate(gridIds.taxPayerOnly, fieldName, rowIndex, index, value);
							}}
							onDeleteRow={onDeleteRowWarning}
						/>
					</div>
					{
						!isLoading &&
						<GridSeperator />
					}
					<div>
						<Reg5907AggregateRows
							id={gridIds.aggregateTable}
							customRef={tableRefs[3]}
							isLoading={isLoading}
							displayNotes={displayNotes}
							summationByYear={summationByYear}
						/>
					</div>
				</div>
				<div className={'action-buttons'}>
					{
						onRenderSaveButton()
					}
				</div>
			</div>
			}
			{
				!!isNoFAHistory &&
			<div>
				{
					t('noFAHistory')
				}
			</div>
			}
			<DeleteModal
				visible={displayDeleteModal}
				title={t('deleteModalTitle')}
				deleteMessage={t('deleteMessage')}
				setVisible={setDisplayDeleteModal}
				onDelete={onDelete}
			/>
		</>
	);
};

export default Reg5907Adjustments;
