import ManageMembers from 'src/features/engagement/components/EngagementSetup/EngagementMembers/ManageMembers/ManageMembers';
import { IEngagementOverview } from 'src/features/engagement/models/IEngagementOverview';
import { ActionEnum } from 'src/common/types/enums/ActionEnum';
import { EngagementStepEnum } from 'src/features/engagement/models/EngagementStepEnum';

const ManageEngagementMembers = (props: { engagementId: number | undefined, engagementOverview: IEngagementOverview }) =>
{
	return (
		<>
			{!!props.engagementOverview?.clientId &&
				<ManageMembers
					clientId={props.engagementOverview?.clientId}
					engagementId={Number(props.engagementId)}
					currentStep={EngagementStepEnum.ManageMembers}
					action={ActionEnum.None}
					fromEngagementDashboard={true}
				/>
			}
		</>
	);
};

export default ManageEngagementMembers;