import { useEffect, useState } from 'react';
import { IColumnarGridProps, SaveButtonLocations } from './IColumnarGridProps';
import { TablePlaceholder } from './components/TablePlaceholder/TablePlaceholder';
import { TableTopHeader } from './components/TableTopHeader/TableTopHeader';
import { TableHeader } from './components/TableHeader/TableHeader';
import { RowData } from './components/RowData/RowData';
import { DescriptionColumn } from './components/DescriptionColumn/DescriptionColumn';
import { ActionBar } from './components/ActionBar/ActionBar';
import debounce from 'lodash/debounce';

import './ColumnarGrid.scss';

export function ColumnarGrid<T>(props: IColumnarGridProps<T>): JSX.Element
{
	const [hasData, setHasData] = useState(false);
	const [displayNotesColumn, setDisplayNotesColumn] = useState(false);
	const [displayHeaderNote, setDisplayHeaderNote] = useState(false);
	const [displayActionBar,setDisplayActionBar] = useState<boolean |undefined>(true);

	const onToggleNotes = (displayNotes: boolean): void =>
	{
		if (!!props.hasHeaderNotes)
		{
			setDisplayHeaderNote(displayNotes);
		}
		else
		{
			setDisplayNotesColumn(displayNotes);
		}

		if (!!props.onToggleNotes)
		{
			props.onToggleNotes(displayNotes);
		}
	};

	const checkHasData = (): void =>
	{
		if (
			!!props.data &&
			!!Object.keys(props.data) &&
			!!Object.values(props.data).some((d) => !!d.length)
		)
		{
			setHasData(true);
		}
		else
		{
			setHasData(false);
		}
	};

	const hasDataDebounced = debounce(checkHasData, 300);

	useEffect(
		() =>
		{
			hasDataDebounced();
		},
		[props.data]
	);

	useEffect(() =>
	{
		if (props.hasActionBar === false)
		{
			setDisplayActionBar(props.hasActionBar);
		}
	},[props.hasActionBar]);

	return <div>
		{
			!!displayActionBar &&
		<ActionBar
			isLoading={props.isLoading}
			isDisabled={!!props.isDisabled}
			addNewButtonText={props.addNewButtonText}
			predefinedSaveButtonLocation={SaveButtonLocations.Top}
			saveButtonLocation={props.saveButtonLocation}
			infoMessage={props.infoMessage}
			onOpenNew={props.onOpenNew}
			onGridSave={props.onGridSave}
		/> }
		{
			!!props.rowInformation &&
			<div className={'columnar-grid'}>
				<TableTopHeader
					hasData={hasData}
					isLoading={props.isLoading}
					isDisabled={!!props.isDisabled}
					hasNotesToggle={!!props.hasNotesToggle}
					hasHeaderNotes={!!props.hasHeaderNotes}
					title={props.title}
					onRenderFilters={props.onRenderFilters}
					onToggleNotes={onToggleNotes}
				/>
				<div className={'grid'}>
					<table>
						<TableHeader
							hasData={hasData}
							isLoading={props.isLoading}
							isDisabled={!!props.isDisabled}
							displayNotes={displayNotesColumn}
							data={props.data}
							columnIndexKeyName={props.columnIndexKeyName}
							rowInformation={props.rowInformation}
							rowInformationHeaderText={props.rowInformationHeaderText}
							onEdit={props.onEdit}
							onHeaderNoteSave={props.onHeaderNoteSave}
							displayHeaderNote={displayHeaderNote}
							highlightedOnField={props.highlightedOnField}
						/>
						{
							<tbody>
								{
									props.rowInformation
										.map((r, index) =>
										{
											return <tr>
												<DescriptionColumn
													index={index}
													rowInformation={r}
												/>
												{
													props.isLoading ||
													!hasData ?
														<>
															{
																index === 0 &&
																<TablePlaceholder
																	isLoading={props.isLoading}
																	title={props.title}
																	columnIndexKeyName={props.columnIndexKeyName}
																	rowInformation={props.rowInformation}
																	addNewButtonText={props.addNewButtonText}
																	addNewMessage={props.addNewMessage}
																	onOpenNew={props.onOpenNew}
																/>
															}
														</> :
														<RowData
															data={props.data[r.key]}
															rowIndex={index}
															rowInformation={r}
															displayNotes={displayNotesColumn}
															highlightedOnField={props.highlightedOnField}
															onChange={props.onInputChanged}
														/>
												}
											</tr>;
										})
								}
							</tbody>
						}
					</table>
				</div>
			</div>
		}
		<ActionBar
			isLoading={props.isLoading}
			isDisabled={!!props.isDisabled}
			predefinedSaveButtonLocation={SaveButtonLocations.Bottom}
			saveButtonLocation={props.saveButtonLocation}
			onGridSave={props.onGridSave}
		/>
	</div>;
}