import { TFunction } from 'i18next';
import { AdjustmentFromAllFields } from 'src/common/types/enums/AdjustmentFromAllFields';
import { AdjustmentFromSpecificTaxpayerFields } from 'src/common/types/enums/AdjustmentFromSpecificTaxpayerFields';
import { ILineItem } from 'src/common/types/interfaces/ILineItem';
import { IRowInformation } from 'src/common/types/interfaces/IRowInformation';
import { inputRecordHelper } from 'src/common/utils/inputRecordHelper';

export const OtherAdjustmentsHelper = {
	getRowInformation: (
		translationService: TFunction,
		lineItems?: ILineItem[]
	): IRowInformation[] =>
	{
		const lineItemsByKey = inputRecordHelper.lineItemsByKey(lineItems);

		return [
			{
				key: AdjustmentFromAllFields.surplusPoolId,
				name: translationService(AdjustmentFromAllFields.surplusPoolId),
				lineItemConfiguration: lineItemsByKey[AdjustmentFromAllFields.surplusPoolId]
			},
			{
				key: AdjustmentFromAllFields.descriptionId,
				name: translationService(AdjustmentFromAllFields.descriptionId),
				lineItemConfiguration: lineItemsByKey[AdjustmentFromAllFields.descriptionId]
			},
			{
				key: AdjustmentFromAllFields.adjustmentDate,
				name: translationService(AdjustmentFromAllFields.adjustmentDate),
				lineItemConfiguration: lineItemsByKey[AdjustmentFromAllFields.adjustmentDate]
			},
			{
				key: AdjustmentFromAllFields.currencyId,
				name: translationService(AdjustmentFromAllFields.currencyId),
				lineItemConfiguration: lineItemsByKey[AdjustmentFromAllFields.currencyId]
			},
			{
				key: AdjustmentFromAllFields.amount,
				name: translationService(AdjustmentFromAllFields.amount),
				lineItemConfiguration: lineItemsByKey[AdjustmentFromAllFields.amount]
			}
		];
	},

	getRowInformationForSpecificTaxpayer: (
		translationService: TFunction,
		lineItems?: ILineItem[]
	): IRowInformation[] =>
	{
		const lineItemsByKey = inputRecordHelper.lineItemsByKey(lineItems);

		return [
			{
				key: AdjustmentFromSpecificTaxpayerFields.surplusPoolId,
				name: translationService(AdjustmentFromSpecificTaxpayerFields.surplusPoolId),
				lineItemConfiguration: lineItemsByKey[AdjustmentFromSpecificTaxpayerFields.surplusPoolId]
			},
			{
				key: AdjustmentFromSpecificTaxpayerFields.descriptionId,
				name: translationService(AdjustmentFromSpecificTaxpayerFields.descriptionId),
				lineItemConfiguration: lineItemsByKey[AdjustmentFromSpecificTaxpayerFields.descriptionId]
			},
			{
				key: AdjustmentFromSpecificTaxpayerFields.adjustmentDate,
				name: translationService(AdjustmentFromSpecificTaxpayerFields.adjustmentDate),
				lineItemConfiguration: lineItemsByKey[AdjustmentFromSpecificTaxpayerFields.adjustmentDate]
			},
			{
				key: AdjustmentFromSpecificTaxpayerFields.currencyId,
				name: translationService(AdjustmentFromSpecificTaxpayerFields.currencyId),
				lineItemConfiguration: lineItemsByKey[AdjustmentFromSpecificTaxpayerFields.currencyId]
			},
			{
				key: AdjustmentFromSpecificTaxpayerFields.amount,
				name: translationService(AdjustmentFromSpecificTaxpayerFields.amount),
				lineItemConfiguration: lineItemsByKey[AdjustmentFromSpecificTaxpayerFields.amount]
			},
			{
				key: AdjustmentFromSpecificTaxpayerFields.specificTo,
				name: translationService(AdjustmentFromSpecificTaxpayerFields.specificTo),
				lineItemConfiguration: lineItemsByKey[AdjustmentFromSpecificTaxpayerFields.specificTo]
			}
		];
	}
};