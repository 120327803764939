import React, { useEffect, useState } from 'react';
import { Button, Modal, Search, Checkbox } from '@appkit4/react-components';
import { useTranslation } from 'react-i18next';
import { IForeignAffiliate } from 'src/features/engagement/models/IForeignAffiliate';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import {
	Grid,
	GridColumn,
	GridNoRecords
} from '@progress/kendo-react-grid';
import { ExportFilesListFields } from 'src/common/types/enums/ImportExportFields';
import { engagementService } from 'src/features/engagement/services/engagementService';
import './RequestTemplateModal.scss';

export const RequestTemplateModal = (props: {
	engagementId: number | undefined,
	visible: boolean,
	setVisible: (visible: boolean) => void,
	setIsRequested: (isRequested: boolean) => void,
	engagementFAs: IForeignAffiliate[]|undefined
}) =>
{
	const { t } = useTranslation(
		'homeDashboard',
		{
			keyPrefix: 'importExportFiles'
		}
	);

	const SELECTED_FIELD: string = 'selected';

	const [foreignAffiliates, setForeignAffiliates] = useState<IForeignAffiliate[]>([]);
	const [filteredFAs, setFilteredFAs] = useState<IForeignAffiliate[]>([]);
	const [selectedRows, setSelectedRows] = useState<any[]>([]);
	const [searchText, setSearchText] = useState<string>('');

	const onSearchTextChange = async (val: string) =>
	{
		if (val !== '')
		{
			const updatedFilteredFAs = foreignAffiliates?.filter(
				(row: any) =>  row.name.toLowerCase().includes(val.trim().toLowerCase())
			);
			setFilteredFAs(updatedFilteredFAs);
		}
		else
		{
			setFilteredFAs(foreignAffiliates);
		}
		setSearchText(val);
	};

	const onSearchClear = () =>
	{
		setSearchText('');
		setFilteredFAs(foreignAffiliates);
	};


	const updatedAllSelection = (fas: any, selected: boolean ) =>
	{
		const updatedFAs= fas.map((r: any) =>
		{
			return {
				...r,
				selected: selected
			};
		});
		return updatedFAs;
	};

	const handleSelectAll = (selected: boolean) =>
	{
		setForeignAffiliates(updatedAllSelection(foreignAffiliates, selected));
		setFilteredFAs(updatedAllSelection(filteredFAs, selected));
	};

	const updatedSelection = (fas: any, param: any, selected: boolean ) =>
	{
		const updatedRowData = fas.map((r: any) =>
		{
			if (r.affiliateId === param.dataItem.affiliateId)
			{
				return {
					...r,
					selected: selected
				};
			}
			else
			{
				return r;
			}
		});
		return updatedRowData;
	};
	const handleSelection = async (param: any, selected: boolean) =>
	{
		setFilteredFAs(updatedSelection(filteredFAs, param, selected));
		setForeignAffiliates(updatedSelection(foreignAffiliates, param, selected));
	};

	const handleCancelClick = () =>
	{
		props.setVisible(false);
		setSearchText('');
	};

	const handleRequestTemplateClick = async () =>
	{
		const selectedAffiliateIds = selectedRows.map((r: any) => r.affiliateId);
		props.setVisible(false);
		if (!!props.engagementId)
		{
			await engagementService.requestEngagementTemplate(props.engagementId, selectedAffiliateIds);
			props.setIsRequested(true);
		}
	};

	const NameCell = (props: any) =>
	{
		return (<>
			{!!props.dataItem && props.field ?
				<div className='custom-cell-text' onClick={() => handleSelection(props, !props.dataItem.selected)}>
					{props.dataItem[props.field]}
				</div>: <div></div>
			}
		</>);
	};


	const HeaderCell = (props: any) =>
	{
		const isAllFAsSelected = selectedRows.length > 0 && selectedRows.length === foreignAffiliates.length;
		return (
			<div className='header-cell-text' onClick={() => handleSelectAll(!isAllFAsSelected)}>
				{t('requestTemplateModal.selectAllFAsText') || ''}
			</div>);
	};

	useEffect(() =>
	{
		if (!!props.engagementFAs)
		{
			const fas = props.engagementFAs.map((fa: any) =>
			{
				const f: IForeignAffiliate = {
					...fa,
					selected: false
				};
				return f;
			});
			setForeignAffiliates(fas);
			setFilteredFAs(fas);
		}
	},[props.engagementFAs, props.visible]);

	useEffect(() =>
	{
		const selectedData = foreignAffiliates.filter((r: any) => r.selected === true);
		setSelectedRows(selectedData);
	},[foreignAffiliates]);

	return (
		<>
			<Modal
				className="request-template-modal"
				visible={props.visible}
				title={t('requestTemplateModal.title') || ''}
				initialFocus={false}
				closeOnPressEscape={false}
				maskCloseable={false}
				header={''}
				onCancel={handleCancelClick}
				modalStyle={{
					width: '540px'
				}}
				icons={''}
				footer={
					<>
						<Button
							className="request-template-modal-cancel"
							onClick={handleCancelClick}
							kind="secondary"
						>
							{t('requestTemplateModal.cancel')}
						</Button>
						<Button
							className="request-template-modal-request"
							onClick={handleRequestTemplateClick}
							disabled={!(selectedRows.length > 0)}
							kind="primary"
						>
							{t('requestTemplateModal.requestTemplate')}
						</Button>
					</>
				}
			>
				<div className="subtitle">
					{t('requestTemplateModal.subtitle')}
				</div>
				<div className="request-template-header-search">
					<Search
						className="file-search"
						searchType={'secondary'}
						placeholder={t('requestTemplateModal.searchForeignAffiliate')||undefined}
						searchValue={searchText}
						onChange={(value: string) => onSearchTextChange(value)}
						onClear={onSearchClear}
						disabled={filteredFAs?.length === 0}
					/>
				</div>
				<LocalizationProvider language="en-user">
					<IntlProvider locale="en">
						<Grid
							className={'engFa-grid'}
							data={filteredFAs}
							total={filteredFAs.length}
						>
							<GridNoRecords/>
							<GridColumn
								field={SELECTED_FIELD}
								editable={false}
								sortable={false}
								width={'32px'}
								headerClassName={'fa-grid-header-cell-text checkbox-column'}
								headerCell={(props) => (
									<Checkbox
										checked={selectedRows.length > 0 && selectedRows.length === foreignAffiliates.length}
										indeterminate={selectedRows.length > 0 && selectedRows.length < foreignAffiliates.length}
										disabled={!(foreignAffiliates.length > 0)}
										onChange={(val) => handleSelectAll(val)}
									></Checkbox>
								)}
								cell={(props) => (
									<td>
										<Checkbox
											disabled={false}
											checked={props.dataItem.selected}
											onChange={(val) => handleSelection(props, val)}
										></Checkbox>
									</td>
								)}
							/>
							<GridColumn
								field={ExportFilesListFields.Name}
								editable={false}
								sortable={false}
								width={'430px'}
								headerClassName={'fa-grid-header-cell-text'}
								headerCell={HeaderCell}
								cell={NameCell}
							/>
						</Grid>
					</IntlProvider>
				</LocalizationProvider>
			</Modal>
		</>
	);
};