import { RelationshipList } from 'src/features/engagement/components/EngagementSetup/Relationships/RelationshipsList/RelationshipList';
import { IEngagementOverview } from 'src/features/engagement/models/IEngagementOverview';
import { ActionEnum } from 'src/common/types/enums/ActionEnum';
import { EngagementStepEnum } from 'src/features/engagement/models/EngagementStepEnum';

const EngagementRelationships = ( props: {engagementId: number | undefined, engagementOverview: IEngagementOverview}) =>
{
	return (
		<>
			{!!props.engagementOverview?.clientId &&
				<RelationshipList
					clientId={props.engagementOverview?.clientId}
					engagementId={Number(props.engagementId)}
					engagementDetail={props.engagementOverview}
					currentStep={EngagementStepEnum.Relationships}
					action={ActionEnum.None}
					fromEngagementDashboard={true}
				/>
			}
		</>
	);
};

export default EngagementRelationships;