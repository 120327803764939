import { TFunction } from 'i18next';
import { DividendsReceivedFromFAFields } from 'src/common/types/enums/DividendsReceivedFromFAFields';
import { DividendReceivedFromOthersFields } from 'src/common/types/enums/DividendsReceivedFromOtherFields';
import { ILineItem } from 'src/common/types/interfaces/ILineItem';
import { IRowInformation } from 'src/common/types/interfaces/IRowInformation';
import { inputRecordHelper } from 'src/common/utils/inputRecordHelper';

export const DividendsReceivedHelper = {

	getRowInformation: (
		translationService: TFunction,
		lineItems?: ILineItem[]
	): IRowInformation[] =>
	{
		const lineItemsByKey = inputRecordHelper.lineItemsByKey(lineItems);

		return [
			{
				key: DividendsReceivedFromFAFields.date,
				name: translationService(DividendsReceivedFromFAFields.date),
				lineItemConfiguration: lineItemsByKey[DividendsReceivedFromFAFields.date]
			},
			{
				key: DividendsReceivedFromFAFields.payerName,
				name: translationService(DividendsReceivedFromFAFields.payerName),
				lineItemConfiguration: lineItemsByKey[DividendsReceivedFromFAFields.payerName]
			},
			{
				key: DividendsReceivedFromFAFields.payerCurrencyId,
				name: translationService(DividendsReceivedFromFAFields.payerCurrencyId),
				lineItemConfiguration: lineItemsByKey[DividendsReceivedFromFAFields.payerCurrencyId]
			},
			{
				key: DividendsReceivedFromFAFields.paidOnEquityClass,
				name: translationService(DividendsReceivedFromFAFields.paidOnEquityClass),
				lineItemConfiguration: lineItemsByKey[DividendsReceivedFromFAFields.paidOnEquityClass]
			},
			{
				key: DividendsReceivedFromFAFields.percentageOwned,
				name: translationService(DividendsReceivedFromFAFields.percentageOwned),
				lineItemConfiguration: lineItemsByKey[DividendsReceivedFromFAFields.percentageOwned]
			},
			{
				key: DividendsReceivedFromFAFields.amountInCurrencyPaid,
				name: translationService(DividendsReceivedFromFAFields.amountInCurrencyPaid),
				lineItemConfiguration: lineItemsByKey[DividendsReceivedFromFAFields.amountInCurrencyPaid]
			},
			{
				key: DividendsReceivedFromFAFields.receiverCurrencyId,
				name: translationService(DividendsReceivedFromFAFields.receiverCurrencyId),
				lineItemConfiguration: lineItemsByKey[DividendsReceivedFromFAFields.receiverCurrencyId]
			},
			{
				key: DividendsReceivedFromFAFields.foreignExchangeRate,
				name: translationService(DividendsReceivedFromFAFields.foreignExchangeRate),
				lineItemConfiguration: lineItemsByKey[DividendsReceivedFromFAFields.foreignExchangeRate]
			},
			{
				key: DividendsReceivedFromFAFields.amountInCurrencyReceived,
				name: translationService(DividendsReceivedFromFAFields.amountInCurrencyReceived),
				lineItemConfiguration: lineItemsByKey[DividendsReceivedFromFAFields.amountInCurrencyReceived]
			},
			{
				key: DividendsReceivedFromFAFields.actualAmount,
				name: translationService(DividendsReceivedFromFAFields.actualAmount),
				lineItemConfiguration: lineItemsByKey[DividendsReceivedFromFAFields.actualAmount]
			},
			{
				key: DividendsReceivedFromFAFields.withholdingTax,
				name: translationService(DividendsReceivedFromFAFields.withholdingTax),
				lineItemConfiguration: lineItemsByKey[DividendsReceivedFromFAFields.withholdingTax]
			},
			{
				key: DividendsReceivedFromFAFields.notes,
				name: translationService(DividendsReceivedFromFAFields.notes),
				lineItemConfiguration: lineItemsByKey[DividendsReceivedFromFAFields.notes]
			}
		];
	},

	getRowInformationForOthers: (
		translationService: TFunction,
		lineItems?: ILineItem[]
	): IRowInformation[] =>
	{
		const lineItemsByKey = inputRecordHelper.lineItemsByKey(lineItems);

		return [
			{
				key: DividendReceivedFromOthersFields.effectiveDate,
				name: translationService(DividendReceivedFromOthersFields.effectiveDate),
				lineItemConfiguration: lineItemsByKey[DividendReceivedFromOthersFields.effectiveDate]
			},
			{
				key: DividendReceivedFromOthersFields.payerName,
				name: translationService(DividendReceivedFromOthersFields.payerName),
				lineItemConfiguration: lineItemsByKey[DividendReceivedFromOthersFields.payerName]
			},
			{
				key: DividendReceivedFromOthersFields.isPayerForeignAffiliate,
				name: translationService(DividendReceivedFromOthersFields.isPayerForeignAffiliate),
				lineItemConfiguration: lineItemsByKey[DividendReceivedFromOthersFields.isPayerForeignAffiliate]
			},
			{
				key: DividendReceivedFromOthersFields.payerCurrencyId,
				name: translationService(DividendReceivedFromOthersFields.payerCurrencyId),
				lineItemConfiguration: lineItemsByKey[DividendReceivedFromOthersFields.payerCurrencyId]
			},
			{
				key: DividendReceivedFromOthersFields.amount,
				name: translationService(DividendReceivedFromOthersFields.amount),
				lineItemConfiguration: lineItemsByKey[DividendReceivedFromOthersFields.amount]
			},
			{
				key: DividendReceivedFromOthersFields.paidOnEquityClassId,
				name: translationService(DividendReceivedFromOthersFields.paidOnEquityClassId),
				lineItemConfiguration: lineItemsByKey[DividendReceivedFromOthersFields.paidOnEquityClassId]
			},
			{
				key: DividendReceivedFromOthersFields.percentageOwned,
				name: translationService(DividendReceivedFromOthersFields.percentageOwned),
				lineItemConfiguration: lineItemsByKey[DividendReceivedFromOthersFields.percentageOwned]
			},
			{
				key: DividendReceivedFromOthersFields.dividendReceivedFrom,
				name: translationService(DividendReceivedFromOthersFields.dividendReceivedFrom),
				lineItemConfiguration: lineItemsByKey[DividendReceivedFromOthersFields.dividendReceivedFrom],
				isBold: true
			},
			{
				key: DividendReceivedFromOthersFields.exemptSurplus,
				name: translationService(DividendReceivedFromOthersFields.exemptSurplus),
				lineItemConfiguration: lineItemsByKey[DividendReceivedFromOthersFields.exemptSurplus]
			},
			{
				key: DividendReceivedFromOthersFields.hybridSurplus,
				name: translationService(DividendReceivedFromOthersFields.hybridSurplus),
				lineItemConfiguration: lineItemsByKey[DividendReceivedFromOthersFields.hybridSurplus]
			},
			{
				key: DividendReceivedFromOthersFields.hybridUnderlyingTax,
				name: translationService(DividendReceivedFromOthersFields.hybridUnderlyingTax),
				lineItemConfiguration: lineItemsByKey[DividendReceivedFromOthersFields.hybridUnderlyingTax]
			},
			{
				key: DividendReceivedFromOthersFields.taxableSurplus,
				name: translationService(DividendReceivedFromOthersFields.taxableSurplus),
				lineItemConfiguration: lineItemsByKey[DividendReceivedFromOthersFields.taxableSurplus]
			},
			{
				key: DividendReceivedFromOthersFields.underlyingForeignTax,
				name: translationService(DividendReceivedFromOthersFields.underlyingForeignTax),
				lineItemConfiguration: lineItemsByKey[DividendReceivedFromOthersFields.underlyingForeignTax]
			},
			{
				key: DividendReceivedFromOthersFields.preAcquisitionSurplus,
				name: translationService(DividendReceivedFromOthersFields.preAcquisitionSurplus),
				lineItemConfiguration: lineItemsByKey[DividendReceivedFromOthersFields.preAcquisitionSurplus]
			},
			{
				key: DividendReceivedFromOthersFields.notes,
				name: translationService(DividendReceivedFromOthersFields.notes),
				lineItemConfiguration: lineItemsByKey[DividendReceivedFromOthersFields.notes]
			}
		];
	}
};