import * as React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import moment from 'moment';
import { useEffect } from 'react';
import './DatePickerEditorCell.scss';
import { CalendarPickerWrapper } from '../../CalendarPickerWrapper/CalendarPickerWrapper';

interface Props {
  isShortFormat?: boolean;
  fieldErrors: Record<string, any>;
  handleInputChange: any;
  handleRowClick: Function;
  handleInputClick: Function;
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
  onRenderRight?: (dataItem: any) => JSX.Element;
}

export const DatePickerEditorCell = (props: GridCellProps & Props) =>
{
	const {
		dataItem,
		fieldErrors,
		handleInputChange,
		handleRowClick,
		handleInputClick,
		minDate,
		maxDate
	} = props;
	const field = props.field || '';
	const dataValue = dataItem[field] === null ? undefined : dataItem[field];
	const [changed, setChanged] = React.useState<boolean>(false);
	const [existingError, setExistingError] = React.useState<boolean>(false);

	useEffect(() =>
	{
		setExistingError(!!fieldErrors);
	}, [fieldErrors]);

	const handleChange = (params: any) =>
	{
		if (params)
		{
			setChanged(true);
			handleInputChange(params, field, params);
		}
	};

	const onClick = () =>
	{
		handleRowClick(field, dataItem);
	};

	return (
		<>
			<td>
				{dataItem.inEditableMode ? (
					<div className="custom-cell">
						<div
							className={`custom-input ${field}  ${
								!changed && fieldErrors?.clientSideErr[field ? field : ''] ?
									'custom-input-error' :
									''
							}`}
							onClick={() => handleInputClick(field)}
						>
							<CalendarPickerWrapper
								placeholder={' '}
								fieldTitle={''}
								format={props.isShortFormat ? 'MMM DD' : 'MMM DD, YYYY'}
								hideTitleOnInput={true}
								editable={false}
								value={dataValue}
								onChange={handleChange}
								datepickerAlwaysDown={false}
								datePanelHorizontalAlign="right"
								minDate={
									!!minDate ?
										minDate :
										new Date(1972, 0, 1)
								}
								maxDate={
									!!maxDate ?
										maxDate :
										new Date(2050, 11, 31)
								}
							/>
						</div>

						{/* <div className="custom-cell-error">
              {changed ? "" : fieldErrors["clientSideErr"][field ? field : ""]}
            </div> */}
						{(changed ? '' : fieldErrors?.clientSideErr[field ? field : '']) ? (
							<div className="custom-cell-error">
								{fieldErrors?.clientSideErr[field ? field : '']}
							</div>
						) : (
							<>
								{existingError && (
									<div className="custom-cell-error">&nbsp;</div>
								)}
							</>
						)}
					</div>
				) : (
					<div className="custom-cell-value" onClick={onClick}>
						{ `${dataValue===undefined?'':props.isShortFormat ?
							moment(dataValue).format('MMM DD') :
							moment(dataValue).format('MMM DD[,] YYYY')}`}
						{
							!!props.onRenderRight &&
							props.onRenderRight(dataItem)
						}
					</div>
				)}
			</td>
		</>
	);
};
