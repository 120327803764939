import React, { useEffect, useState } from 'react';
import { Button, Loading, Search, Badge, Notification, Tab, Tabs } from '@appkit4/react-components';
import { useTranslation } from 'react-i18next';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import {
	Grid,
	GridColumn,
	GridNoRecords,
	GridPageChangeEvent
} from '@progress/kendo-react-grid';
import { PagerTargetEvent } from '@progress/kendo-react-data-tools';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { NotificationService } from 'src/core/services/notificationService';
import { ExportFilesListFields, ImportFilesListFields, ImportExportStatus } from 'src/common/types/enums/ImportExportFields';
import { IForeignAffiliate } from 'src/features/engagement/models/IForeignAffiliate';
import { RequestTemplateModal } from './RequestTemplateModal';
import { UploadFileModal } from './UploadFileModal';
import { Utils } from 'src/common/utils/utils';
import './ImportExportFiles.scss';

interface PageState {
	skip: number;
	take: number;
}

const initialDataState: PageState = {
	skip: 0,
	take: 10
};

interface FilesListGridProps {
	rowData: Array<any>;
	fields: Array<string>;
}

export const ImportExportFiles = (props: {
	engagementId: number | undefined,
	foreignAffiliates: IForeignAffiliate[] | undefined
}) =>
{
	const { t } = useTranslation(
		'homeDashboard',
		{
			keyPrefix: 'importExportFiles'
		}
	);
	const [loading, setLoading] = useState<boolean>(false);
	const [exportFilesListData, setExportFilesListData] = useState<any[]>([]);
	const [importFilesListData, setImportFilesListData] = useState<any[]>([]);
	const [filterExportFilesRowtData, setFilterExportFilesRowtData] = useState<any[]>([]);
	const [filterImportFilesRowtData, setFilterImportFilesRowtData] = useState<any[]>([]);
	const [page, setPage] = useState<PageState>(initialDataState);
	const [pageSizeValue, sePageSizeValue] = useState<number | string | undefined>();
	const [showExportTempModal, setShowExportTempModal] = useState<boolean>(false);
	const [isTemplateRequested, setIsTemplateRequested] = useState<boolean>(false);
	const [activeIndex, setActiveIndex] = useState(0);
	const [showUploadFileModal, setShowUploadFileModal] = useState<boolean>(false);
	const [isUploaded, setIsUploaded] = useState<boolean>(false);

	const exportFilesListFields = [
		ExportFilesListFields.DateExported,
		ExportFilesListFields.FileName,
		ExportFilesListFields.ExportedBy,
		ExportFilesListFields.ExportStatus,
		ExportFilesListFields.Actions
	];

	const importFilesListFields = [
		ImportFilesListFields.DateImported,
		ImportFilesListFields.FileName,
		ImportFilesListFields.ImportedBy,
		ImportFilesListFields.ImportStatus,
		ImportFilesListFields.Actions
	];

	const loadExportTemplatesList = async (): Promise<void> =>
	{
		if (props.engagementId)
		{
			setLoading(true);
			await engagementService
				.getExportTemplatesListById(props.engagementId)
				.then((res: any) =>
				{
					if (!!res && !!res.data && !!res.data.result)
					{
						setExportFilesListData(sortList(res.data.result, ExportFilesListFields.ExportFileId));
						setFilterExportFilesRowtData(sortList(res.data.result, ExportFilesListFields.ExportFileId));
					}
					else
					{
						setExportFilesListData([]);
						setFilterExportFilesRowtData([]);
					}
				})
				.catch((error: any) =>
				{
					triggerNotification(t('loadingExportTemplateListError'), 'error');
				})
				.finally(() => setLoading(false));
		}
	};

	const loadImportFilesList = async (): Promise<void> =>
	{
		if (props.engagementId)
		{
			setLoading(true);
			await engagementService
				.getImportFilesListById(props.engagementId)
				.then((res: any) =>
				{
					if (!!res && !!res.data && !!res.data.result)
					{
						setImportFilesListData(sortList(res.data.result, ImportFilesListFields.ImportFileId));
						setFilterImportFilesRowtData(sortList(res.data.result, ImportFilesListFields.ImportFileId));
					}
					else
					{
						setImportFilesListData([]);
						setFilterImportFilesRowtData([]);
					}
				})
				.catch((error: any) =>
				{
					triggerNotification(t('loadingImportFileListError'), 'error');
				})
				.finally(() => setLoading(false));
		}
	};

	const triggerNotification = async (message: string, status: string): Promise<void> =>
	{
		const ele = (
			<Notification
				message={message}
				status={status}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};

	const sortList = (list: any, id: string): any =>
	{
		list = !!list && list.length > 1 ? list
			.sort((a: any, b: any) =>
			{
				if (a[id] < b[id])
				{
					return 1;
				}
				if (a[id] > b[id])
				{
					return -1;
				}
				return 0;
			}) : list;
		return list;
	};

	const onTabChange = (i: number) =>
	{
		setActiveIndex(i);
	};

	const filterRowData = (rowData: any[], val: string): any[] =>
	{
		return rowData.filter(
			(row: any) => row.fileName.toLowerCase().includes(val.trim().toLowerCase()));
	};

	const setFilteredRowData = (data: any[], val: string, setRowData: any) =>
	{
		if (val !== '')
		{
			const filteredRowData = filterRowData(data, val);

			setRowData(filteredRowData);
		}
		else
		{
			setRowData(data);
		}
	};

	const onSearchTextChange = async (val: string) =>
	{
		setPage(initialDataState);
		if (activeIndex === 0)
		{
			setFilteredRowData(importFilesListData, val, setFilterImportFilesRowtData);
		}
		else if (activeIndex === 1)
		{
			setFilteredRowData(exportFilesListData, val, setFilterExportFilesRowtData);
		}
	};

	const onSearchClear = () =>
	{
		!!activeIndex && activeIndex === 1 ?
			setFilterExportFilesRowtData(exportFilesListData) :
			setFilterImportFilesRowtData(importFilesListData);
	};

	const handleExportTemplateClick = () =>
	{
		if (!!props.foreignAffiliates)
		{
			setShowExportTempModal(true);
		}
	};

	const downloadFile = (fileName: string, res: any) =>
	{
		if (!!res && !!res.data)
		{
			const blob = new Blob([res.data], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			});

			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.download = Utils.escapeHtml(fileName) || 'export-template.xlsx';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			URL.revokeObjectURL(url);
			NotificationService.clearExisting();
		}
	};

	const downloadExportTemplate = async (exportId: number, fileName: string) =>
	{
		if (!!exportId)
		{
			await engagementService.downloadExportTemplate(exportId)
				.then((res: any) =>
				{
					downloadFile(fileName, res);
					triggerNotification(t('downloadingExportTemplateSuccess'), 'success');
				})
				.catch((err: any) =>
				{
					triggerNotification(t('downloadingExportTemplateError'), 'error');
				});
		}
	};

	const downloadUploadedFile = async (importId: number, fileName: string) =>
	{
		if (!!importId)
		{
			await engagementService.downloadImportedFile(importId)
				.then((res: any) =>
				{
					downloadFile(fileName, res);
					triggerNotification(t('downloadingImportFileSuccess'), 'success');
				})
				.catch((err: any) =>
				{
					triggerNotification(t('downloadingImportFileError'), 'error');
				});
		}
	};

	const handleActionClick = (dataItem: any, status: string) =>
	{
		if (!!dataItem && !!dataItem[status] && (dataItem[status] === ImportExportStatus.Completed ||
			dataItem[status] === ImportExportStatus.CompletedWithError))
		{
			triggerNotification(t('downloadWarning'), 'warning');
			if (status === ExportFilesListFields.ExportStatus)
			{
				downloadExportTemplate(dataItem[ExportFilesListFields.ExportFileId], dataItem.fileName);
			}
			else if (status === ImportFilesListFields.ImportStatus)
			{
				downloadUploadedFile(dataItem[ImportFilesListFields.ImportFileId], dataItem.fileName);
			}
		}
	};

	const DateCell = (props: any) =>
	{
		const date = !!props.dataItem && !!props.field && !!props.dataItem[props.field] ? props.dataItem[props.field] : '';
		const formattedDate = !!date ? dateFormatter.format(dateFormatter.utcToEst(date), 'MMM DD, YYYY hh:mm A') : '';

		return (
			<td>
				{formattedDate}
			</td>
		);
	};

	const StatusCell = (props: any) =>
	{
		const data = [
			{
				value: ImportExportStatus.InQueue,
				label: t('exportStatus.inQueue'),
				background: 'rgba(71, 71, 71, 0.12)',
				opacity: '0.12',
				color: '#474747'
			},
			{
				value: ImportExportStatus.New,
				label: t('exportStatus.inQueue'),
				background: 'rgba(71, 71, 71, 0.12)',
				opacity: '0.12',
				color: '#474747'
			},
			{
				value: ImportExportStatus.InProgress,
				label: t('exportStatus.inProgress'),
				background: 'rgba(255, 191, 31, 0.12)',
				opacity: '0.12',
				color: '#252525',
				borderColor: '#FFBF1F'

			},
			{
				value: ImportExportStatus.Completed,
				label: t('exportStatus.completed'),
				background: 'rgba(33, 129, 45, 0.12)',
				color: '#21812D'
			},
			{
				value: ImportExportStatus.Error,
				label: t('exportStatus.error'),
				background: 'rgba(197, 42, 26, 0.12)',
				color: '#C52A1A'
			},
			{
				value: ImportExportStatus.CompletedWithError,
				label: t('exportStatus.completedWithError'),
				background: 'rgba(33, 129, 45, 0.12)',
				color: '#21812D'
			}
		];

		const statusCellData = data.find((item: any) => item.value === props.dataItem[props.field]);

		return (
			<td>
				{!!statusCellData ?
					<Badge size="lg"
						className="status-cell-badge"
						style={{
							border: `1px solid ${!!statusCellData?.borderColor ? statusCellData?.borderColor : statusCellData?.color}`,
							color: `${statusCellData?.color}`,
							backgroundColor: `${statusCellData?.background}`
						}}
						value={statusCellData?.label}>
					</Badge> : <div></div>
				}
			</td>
		);
	};

	const ActionCell = (props: any) =>
	{
		const status = !!activeIndex && activeIndex === 1 ? ExportFilesListFields.ExportStatus : ImportFilesListFields.ImportStatus;

		return (<> {!!props.dataItem && !!props.dataItem[status] &&
			(props.dataItem[status] === ImportExportStatus.Completed || props.dataItem[status] === ImportExportStatus.CompletedWithError) ?
			<div className="actions-cell-icon" onClick={() => handleActionClick(props.dataItem, status)}>
				<span className={'Appkit4-icon icon-download-outline'} />
			</div> :
			<div></div>}
		</>
		);
	};


	const FilesListGrid = ({ rowData, fields }: FilesListGridProps) =>
	{
		const pageChange = (event: GridPageChangeEvent) =>
		{
			const targetEvent = event.targetEvent as PagerTargetEvent;
			const take = event.page.take;

			if (targetEvent.value)
			{
				sePageSizeValue(targetEvent.value);
			}

			setPage({
				...event.page,
				take
			});
		};

		return (<>
			{!!rowData &&
				<LocalizationProvider language="en-user">
					<IntlProvider locale="en">
						<Grid
							className={'engFa-grid'}
							data={rowData.slice(
								page.skip,
								page.take + page.skip
							)}
							skip={page.skip}
							take={page.take}
							total={rowData.length}
							pageable={{
								buttonCount: 2,
								info: false,
								type: 'input',
								pageSizes: [10, 25],
								pageSizeValue: pageSizeValue
							}}
							onPageChange={pageChange}
						>
							<GridNoRecords />
							<GridColumn
								field={fields[0]}
								title={t(`columnHeaders.${[fields[0]]}`) || undefined}
								editable={false}
								sortable={false}
								width={'176px'}
								headerClassName={'fa-grid-header-cell-text'}
								cell={DateCell}
							/>
							<GridColumn
								field={fields[1]}
								title={t(`columnHeaders.${[fields[1]]}`) || undefined}
								editable={false}
								sortable={false}
								width={'417px'}
								headerClassName={'fa-grid-header-cell-text'}
							/>
							<GridColumn
								field={fields[2]}
								title={t(`columnHeaders.${[fields[2]]}`) || undefined}
								editable={false}
								sortable={false}
								width={'236px'}
								headerClassName={'fa-grid-header-cell-text'}
							/>
							<GridColumn
								field={fields[3]}
								title={t(`columnHeaders.${[fields[3]]}`) || undefined}
								editable={false}
								sortable={false}
								width={'125px'}
								headerClassName={'fa-grid-header-cell-text'}
								cell={StatusCell}
							/>
							<GridColumn
								field={fields[4]}
								title={t(`columnHeaders.${[fields[4]]}`) || undefined}
								editable={false}
								sortable={false}
								width={'98px'}
								headerClassName={'fa-grid-header-action-cell'}
								cell={ActionCell}
							/>
						</Grid>
					</IntlProvider>
				</LocalizationProvider>
			}
		</>);
	};

	const loadData = async () =>
	{
		await loadImportFilesList();
		await loadExportTemplatesList();
	};

	useEffect(() =>
	{
		loadData();
	}, [props.engagementId]);

	useEffect(() =>
	{
		if (isTemplateRequested)
		{
			loadExportTemplatesList();
			setIsTemplateRequested(false);
		}
	}, [isTemplateRequested]);

	useEffect(() =>
	{
		if (isUploaded)
		{
			loadImportFilesList();
			setIsUploaded(false);
		}
	}, [isUploaded]);

	return (
		<>
			<div className="import-export-files">
				{loading && (
					<div className="loading-icon">
						<Loading
							loadingType="circular"
							indeterminate={true}
							compact={false}
						/>
					</div>
				)}
				<div className="info-description">
					{t('description-text')}
				</div>
				<div className='import-export-grid-container '>
					<div className='toolbar-container'>
						<div className='toolbar'>
							<div className="header-toolbar">
								<div className="left">
									<div className="toolbar-button">
										<Tabs type="filled" activeIndex={activeIndex} onTabChange={onTabChange}>
											<Tab label={t('importFilesList-title')} value="RF"></Tab>
											<Tab label={t('exportFilesList-title')} value="RF"></Tab>
										</Tabs>
									</div>
									<div className="import-export-header-search">
										<Search
											className="file-search"
											searchType={'secondary'}
											placeholder={t('searchFile') || undefined}
											onChange={(value: string) => onSearchTextChange(value)}
											onClear={onSearchClear}
											disabled={!!activeIndex && activeIndex === 1 ?
												filterExportFilesRowtData?.length === 0 : filterImportFilesRowtData?.length === 0}
										/>
									</div>
								</div>
								<div className="right">
									<div>
										<Button
											kind="primary"
											disabled={false}
											onClick={() => setShowUploadFileModal(true)}
										>
											{t('importButton-title')}
										</Button>
									</div>
									<div>
										<Button
											kind="secondary"
											disabled={false}
											onClick={handleExportTemplateClick}
										>
											{t('exportButton-title')}
										</Button>
									</div>
									<div>
										<Button
											kind="secondary"
											icon="Appkit4-icon icon-refresh-outline"
											onClick={() => loadData()}>
											{t('refreshButton-title')}
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
					{!loading && activeIndex === 0 &&
						<div className={'files-list-grid'}>
							<FilesListGrid rowData={filterImportFilesRowtData} fields={importFilesListFields} />
						</div>}
					{!loading && activeIndex === 1 &&
						<div className={'files-list-grid'}>
							<FilesListGrid rowData={filterExportFilesRowtData} fields={exportFilesListFields} />
						</div>}
				</div>
			</div>
			<RequestTemplateModal engagementId={props.engagementId} visible={showExportTempModal}
				setVisible={setShowExportTempModal} setIsRequested={setIsTemplateRequested} engagementFAs={props.foreignAffiliates} />
			<UploadFileModal engagementId={props.engagementId} visible={showUploadFileModal}
				setVisible={setShowUploadFileModal} setIsUploaded={setIsUploaded} />
		</>
	);
};