import { Notification, Tab, Tabs } from '@appkit4/react-components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import './Dashboard.scss';
import { EngagementPersonalizationStatus } from 'src/core/types/enums/EngagementPersonalizationStatus';
import { ClientList } from './ClientList';
import { useAppSelector } from 'src/store/store';
import { EngagementCommand } from 'src/core/types/enums/EngagementCommand';
import { EngagementTotalChangeSubject } from 'src/core/services/EventBus';
import { RoleEnum } from 'src/common/types/enums/RoleEnum';
import { IUserProfile } from 'src/common/types/interfaces/IUserProfile';
import { NotificationService } from 'src/core/services/notificationService';

export const Dashboard = () =>
{
	const [activeIndex, setactiveIndex] = React.useState(0);
	const [engagementCount, setEngagementCount] = React.useState<number>(0);
	const [hiddenCount, setHiddenCount] = React.useState<number>(0);
	const [draftCount, setDraftCount] = React.useState<number>(0);
	const [updatedClientDetails, setUpdatedClientDetails] = React.useState<Record<string, any> | null>(null);
	const location = useLocation();

	const { t } = useTranslation('homeDashboard');
	const userProfile = useAppSelector((state) => state.userProfile.profile);

	const onRenderTab = (
		tabLabel: string,
		tabValue: string,
		engagementPersonalizationStatus: EngagementPersonalizationStatus
	) =>
	{
		return (
			<Tab key={tabValue} label={tabLabel} value={tabValue}>
				<div className={'client-engagements'}>
					<ClientList
						engagementPersonalizationStatus={engagementPersonalizationStatus}
						activeIndex={activeIndex}
						onAction={handleAction}
						updatedClientDetails={updatedClientDetails}
						setUpdatedClientDetails={setUpdatedClientDetails}
					/>
				</div>
			</Tab>
		);
	};

	const onRenderTabs = (userProfile: IUserProfile) =>
	{
		const children: JSX.Element[] = [];

		// my engagements
		if (
			userProfile.role === RoleEnum.BusinessOwner ||
      userProfile.role === RoleEnum.PwCUser
		)
		{
			children.push(
				onRenderTab(
					`${t('tabs.myEngagement')} (${engagementCount})`,
					'myEngagement',
					EngagementPersonalizationStatus.My
				)
			);
		}

		// hidden
		if (
			userProfile.role === RoleEnum.BusinessOwner ||
      userProfile.role === RoleEnum.PwCUser
		)
		{
			children.push(
				onRenderTab(
					`Hidden (${hiddenCount})`,
					'hidden',
					EngagementPersonalizationStatus.Hidden
				)
			);
		}

		// drafts
		if (
			userProfile.role === RoleEnum.BusinessOwner ||
      userProfile.role === RoleEnum.PwCUser
		)
		{
			children.push(
				onRenderTab(
					`Drafts (${draftCount})`,
					'drafts',
					EngagementPersonalizationStatus.Draft
				)
			);
		}

		// Tabs component cannot handle no children being present
		// so checking to ensure child components exist before render
		if (!!children && !!children.length)
		{
			return (
				<Tabs
					type="underline"
					activeIndex={activeIndex}
					onTabChange={onTabChange}
				>
					{children.map((c) => c)}
				</Tabs>
			);
		}

		return null;
	};

	useEffect(() =>
	{
		const  dashboardState = location.state as any;
		if (dashboardState)
		{
			const notification = dashboardState.notification??'';
			if (notification)
			{
				NotificationService.notify({
					component: <Notification message={notification} ></Notification>
				});
			}
			const activeIndex = dashboardState.activeIndex??{
			};
			if (activeIndex)
			{
				setactiveIndex(activeIndex);
			}
		}
	}, [location]);

	useEffect(() =>
	{
		const subscription = EngagementTotalChangeSubject.subscribe((e: any) =>
		{
			switch (e.eventType)
			{
			case EngagementPersonalizationStatus.My:
				setEngagementCount(e.payload);
				break;
			case EngagementPersonalizationStatus.Hidden:
				setHiddenCount(e.payload);
				break;
			case EngagementPersonalizationStatus.Draft:
				setDraftCount(e.payload);
				break;
			}
		});

		return () =>
		{
			subscription.unsubscribe();
		};
	}, []);

	const onTabChange = (i: number, value: string) =>
	{
		setactiveIndex(i);
	};

	const handleAction = (engagementCommand: EngagementCommand) =>
	{
		switch (engagementCommand)
		{
		case EngagementCommand.Delete: {
			setEngagementCount((prev: number) => prev - 1);
			break;
		}
		/*case EngagementCommand.CancelRequest: {
			setPendingReqCount((prev: number) => prev - 1);
			break;
		}*/
		case EngagementCommand.Unhide: {
			setHiddenCount((prev: number) => prev - 1);
			// setEngagementCount((prev: number) => prev + 1);
			break;
		}
		case EngagementCommand.Hide: {
			setHiddenCount((prev: number) => prev + 1);
			setEngagementCount((prev: number) => prev - 1);
			break;
		}
		}
	};

	return (
		<div className="dashboard">
			<div className="title">{t('homeScreen')}</div>
			{!!userProfile && onRenderTabs(userProfile)}
		</div>
	);
};
