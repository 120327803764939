import { Utils } from 'src/common/utils/utils';

export interface IPropertyIncomeRowBase {

	id?: number;
	typeId?: number;
	descriptionId: number;
	amount?: number;
	notes?: string;
}

export interface IPropertyIncomeRow extends IPropertyIncomeRowBase {

	faYear?: string;
	affiliateTaxYearEndId: number;
}

export class PropertyIncomeRow implements IPropertyIncomeRow
{
	constructor (
		{
			descriptionId = 0,
			faYear = '',
			affiliateTaxYearEndId
		}:
		{
			descriptionId?: number;
			faYear: string;
			affiliateTaxYearEndId: number;
		}
	)
	{
		this.id = Utils.generateTempId();
		this.typeId = undefined;
		this.descriptionId = !!descriptionId ? descriptionId : 0;
		this.amount = undefined;
		this.faYear = faYear;
		this.notes = undefined;
		this.affiliateTaxYearEndId = affiliateTaxYearEndId;
	}

	id: number;
	typeId?: number;
	descriptionId: number;
	amount: number | undefined;
	notes?: string | undefined;
	faYear?: string | undefined;
	affiliateTaxYearEndId: number;
}

export interface IPropertyIncomeRecord {

	income: IPropertyIncomeRow[];
	expenses: IPropertyIncomeRow[];
	adjustments: IPropertyIncomeRow[];
}

export interface IPropertyIncomeYear {

	faYear: string;
	countryId: number;
	calculatingCurrencyId: number;
	recordGuid: string;
	affiliateTaxYearEndId: number;
	propertyIncome: IPropertyIncomeRecord;
}

export enum PropertyIncomeSubmissionToken {

	Income = 'incomes',
	Expenses = 'expenses',
	Adjustments = 'adjustments'
}

export interface IPropertyIncomeYearSubmission {

	affiliateTaxYearEndId: number;
	[PropertyIncomeSubmissionToken.Income]: IPropertyIncomeRowBase[];
	[PropertyIncomeSubmissionToken.Expenses]: IPropertyIncomeRowBase[];
	[PropertyIncomeSubmissionToken.Adjustments]: IPropertyIncomeRowBase[];
}

export interface IPropertyIncomeSubmission {

	engagementId: number;
	affiliateId: number;
	propertyIncomesList: IPropertyIncomeYearSubmission[]
}