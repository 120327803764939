import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { IReport } from 'src/features/engagement/models/IReport';
import DividendsReceivedReportFields  from './../Settings/DividendsReceivedReportFields';
import { ReportUtil } from 'src/features/engagement/utils/ReportUtil';
import { IReportField } from 'src/common/types/interfaces/IReportField';
import { ReportFieldEnum } from 'src/common/types/enums/ReportFieldEnum';
import ReportCell from '../ReportCells/ReportCell';
import ReportHeader from '../ReportCells/ReportHeader';
import { ReportI18NKeys } from 'src/common/types/enums/ReportI18NKeys';
import ReportRow from '../ReportCells/ReportRow';
import { Loading, Button } from '@appkit4/react-components';
import { DividendReceivedReportFields } from 'src/common/types/enums/DividendReceivedReportFields';
import { Utils } from 'src/common/utils/utils';
import './DividendsReceivedReport.scss';

const DividendsReceivedReport = (props: {refershReport: boolean}) =>
{
	const {engagementId, affiliateId } = useParams();

	const [reportData, setReportData] = useState<any[]>([]);

	const [sections, setSections] = useState<{index: number, collapsed: boolean}[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [groupedPaymentsBySections, setGroupedPaymentsBySections] = useState<any>([]);
	const [totalAmounts, setTotalAmounts] = useState<any>();
	const report: IReport = DividendsReceivedReportFields;

	const { t } = useTranslation(
		'report',
		{
			keyPrefix: ReportI18NKeys.DividendsReceived
		}
	);

	const loadDividendsReceivedReportData = () =>
	{
		if (engagementId && affiliateId)
		{
			setIsLoading(true);
			engagementService.loadDividendsReceivedReport(Number(engagementId), Number(affiliateId)).then((res: any) =>
			{
				setIsLoading(false);
				const result = res.data.result;

				setReportData(result);


				if (!!result && result.length > 0)
				{
					const updatedSections = groupPaymentsForDividendPayers(result);

					setGroupedPaymentsBySections(updatedSections);

					const initialSections = Array.from({
						length: !!updatedSections? updatedSections.length : 0
					}, (_, index) => ({
						index,
						collapsed: true
					}));
					setSections(initialSections);

					const rowTotal = calculateRowTotal(result, updatedSections);

					setTotalAmounts(rowTotal);
				}
			})
				.catch((error: any) =>
				{
					setIsLoading(false);
				});
		}
	};

	const groupPaymentsForDividendPayers = (result: any) =>
	{
		//group dividend received payments for each dividend payer across all tax years
		let sections: any = [];

		result.forEach((year: any) =>
		{
			!!year[DividendReceivedReportFields.DividendPayers] &&
			year[DividendReceivedReportFields.DividendPayers].forEach((payer: any) =>
			{
				if (!sections.find((item: any) => item.payerAffiliateId === payer.payerAffiliateId))
				{
					sections.push({
						[DividendReceivedReportFields.PayerName]: payer.payerName,
						[DividendReceivedReportFields.PayerAffiliateId]: payer.payerAffiliateId,
						[DividendReceivedReportFields.TotalAmountPaid]: [payer.totalAmountPaid || 0],
						[DividendReceivedReportFields.PaymentsCount]: !!payer.payments ? payer.payments.length : 0
					});
				}
				else
				{
					sections = sections.map((item: any) => item.payerAffiliateId === payer.payerAffiliateId ? {
						...item,
						[DividendReceivedReportFields.TotalAmountPaid]: [...item.totalAmountPaid, payer.totalAmountPaid || 0],
						[DividendReceivedReportFields.PaymentsCount]: !!payer.payments && (payer.payments.length > item.paymentsCount) ?
						 payer.payments.length : item.paymentsCount
					} : item);
				};
			});
		});

		const dividendReceivedFromFAs = sections.filter((payer: any) => !!payer.payerAffiliateId && payer.payerAffiliateId > 0);
		const  dividendReceivedFromOthers = sections.filter((payer: any) => !!payer.payerAffiliateId && payer.payerAffiliateId < 0)
			.sort((a: any, b: any) => a.payerName.localeCompare(b.payerName));


		sections = [...dividendReceivedFromFAs.sort((a: any, b: any) => a.payerName.localeCompare(b.payerName)),
			...dividendReceivedFromOthers.map((c: any, index: number) =>
			{
				return {
					...c,
					[DividendReceivedReportFields.OutsideOfThisEngagementFaId]: index+1
				};
			})];

		return sections;
	};

	const calculateRowTotal = (data: { [key: string]: any }[], sectionsByFas: any): any =>
	{
		//calculate total amounts for summary section
		const summarySum: any = {
		};
		const summary = DividendReceivedReportFields.Summary;

		data.forEach((item) =>
		{
			!!item[summary] && Object.keys(item[summary]).forEach((key) =>
			{
				const newKey: string = `${summary}-${key}`;
				const amount = item[summary][key];
				summarySum[newKey] = !!summarySum[newKey] ? [...summarySum[newKey], amount || 0] : [amount || 0];
			});
		});

		const summaryTotal: any = {
		};

		Object.keys(summarySum).forEach((earning: string) =>
		{
			const properties = earning.split('-');
			summaryTotal[properties[0]] =  {
				...summaryTotal[properties[0]],
				[properties[1]]: Utils.add(...summarySum[earning])
			};
		});

		//calculate total dividends recieved from each fa (per section)
		const totalDividendsReceivedAmounts: any = [];

		!!sectionsByFas && sectionsByFas.forEach((section: any) =>
		{
			totalDividendsReceivedAmounts.push({
				[DividendReceivedReportFields.TotalAmountPaid]: Utils.add(...section.totalAmountPaid)
			});
		});

		return {
			...summaryTotal,
			[DividendReceivedReportFields.TotalDividendsReceivedAmounts]: totalDividendsReceivedAmounts
		};
	};

	useEffect(() =>
	{
		loadDividendsReceivedReportData();
	},[engagementId, affiliateId]);

	useEffect(() =>
	{
		if (!!props.refershReport)
		{
			loadDividendsReceivedReportData();
		}
	}, [props.refershReport]);

	const handleCollapseSection = (index: number) =>
	{
	  const updatedSections =	sections.map((section: {index: number,
			 collapsed: boolean}) =>
		{
			if (section.index===index)
			{
				return {
					...section,
					collapsed: !section.collapsed
				};
			}
			else
			{
				return {
					...section
				};
			}
		});

		setSections(updatedSections);
	};

	const handleCollapseAll = (expanded: boolean) =>
	{
	  const updatedSections =	sections.map((section: {index: number,
			 collapsed: boolean}) =>
		{
			if (expanded)
			{
				return {
					...section,
					collapsed: false
				};
			}
			else
			{
				return {
					...section,
					collapsed: true
				};
			}
		});
		setSections(updatedSections);
	};

	const renderReportDetailsSection = (section: {index: number, collapsed: boolean}, field: IReportField, key?: number ): JSX.Element =>
	{
		const payerDetailsPerSection = groupedPaymentsBySections[section.index];

		const sectionTitle = (!!payerDetailsPerSection.payerAffiliateId && payerDetailsPerSection.payerAffiliateId < 0 ?
			t('dividendFromOthers-title') + payerDetailsPerSection.outsideEngFaId + ' - ': '') + payerDetailsPerSection.payerName;

		return (
			<tr  className={`${ReportUtil.getRowClassName(field)} ${section.collapsed ?
				 'collapsed': 'expanded'}`}>
				<td className={`${!!field?.background ? field?.background : ''}`}>
					{!!field.type && !field.type.includes(ReportFieldEnum.Separator) &&
					<div className={`${ReportUtil.getRowClassName(field)}`}
						onClick={() => field.type===ReportFieldEnum.Section && handleCollapseSection(section.index)}>
						{ field.type===ReportFieldEnum.Section &&
					<span
						className={`Appkit4-icon 
							${section.collapsed?'icon-down-chevron-outline':'icon-up-chevron-outline'}`}
					></span> }
						{`${!!field.name && field.type!==ReportFieldEnum.Section ? t(field.name) : ''}`}
						{`${field.type===ReportFieldEnum.Section? sectionTitle :''}`}
					</div>}
				</td>
				{!!totalAmounts && <td  className={`${!!field?.background ? field?.background : ''}`}>
					<ReportCell
						data={totalAmounts[DividendReceivedReportFields.TotalDividendsReceivedAmounts][section.index]}
						field={field} />
				</td>}
				{reportData.map((item: any) =>
				{
					const payersPerTaxYear = !!item.dividendPayers ? item.dividendPayers : [];

					const payersPerSection = !!payersPerTaxYear ? payersPerTaxYear.find((c: any) =>
						c.payerAffiliateId === payerDetailsPerSection.payerAffiliateId) : {
					};

					const data = !!field && !!field.name && field.name === DividendReceivedReportFields.TotalAmountPaid ?
						(!!payersPerSection && !!payersPerSection.totalAmountPaid ? payersPerSection : 0 ) :
						(!!payersPerSection && !!payersPerSection && !!payersPerSection.payments ?
							payersPerSection.payments[!!key ? key : 0] : []);

					return (
						<td  className={`${!!field?.background ? field?.background : ''}`}>
							<ReportCell data={data} field={field}/>
						</td>
					);
				})
				 }
			</tr>);
	};

	const paymentsSeparator = {
		'name': '',
		'type': ReportFieldEnum.Separator
	};

	const sectionHeader = 		{
		'name': 'detail-title',
		'type': ReportFieldEnum.Section
	};

	const totalDividendsPerPayer = 		{
		'name': `${DividendReceivedReportFields.TotalAmountPaid}`,
		'type': ReportFieldEnum.Currency,
		'background': 'highlight fixed'
	};

	return <div className='dividends-received-report'>

		{DividendsReceivedReportFields && <div className='table-container'>
			{isLoading && (
				<div className="loading-icon">
					<Loading
						loadingType="circular"
						indeterminate={true}
						compact={false}
					/>
				</div>
			)}
			{reportData &&
			<table>
				<thead>
					<tr>
						<th colSpan={reportData.length+2}>
							<div className={'header-area'}>
								<div className={'left'}>
									<div className='header-title'>
										{t('header-title')}
									</div>
								</div>
								{reportData.length > 0 && <div className={'right'}>
									<div className={'button'}>
										<Button kind='text' className={'text'} onClick={() => handleCollapseAll(false)}>
											{t('collapse-all')}
										</Button>
									</div>
									<div className={'button'}>
										<Button kind='text' className={'text'} onClick={() => handleCollapseAll(true)}>
											{t('expand-all')}
										</Button>
									</div>
								</div>}
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					{report.header.map((field: any) =>
						<ReportHeader reportData={reportData} field={field}
							i18nKey={ReportI18NKeys.DividendsReceived} totalAmounts={totalAmounts}/>
					)}
					{report.summary.map((field: IReportField) =>
						<ReportRow reportData={reportData} field={field}
							i18nKey={ReportI18NKeys.DividendsReceived} totalAmounts={totalAmounts}/>
					)}
					{
						sections.map((section: {index: number, collapsed: boolean}) =>
						{
							let isDividendByOther = false;
							const payerDetailsPerSection = groupedPaymentsBySections[section.index];

							if (!!payerDetailsPerSection.payerAffiliateId && payerDetailsPerSection.payerAffiliateId < 0 )
							{
								isDividendByOther = true;
							}

							const paymentsPerDividendPayer = Array.from({
								length: payerDetailsPerSection.paymentsCount
							},
							(_, key) => (
								<>
									{report.details.map((field: any) =>
									{
										if (!isDividendByOther || field.name !== DividendReceivedReportFields.WithholdingTax)
										{
											return (
												<>
													{renderReportDetailsSection(section, field, key)}
												</>
											);
										}
										return null;
					 				})}
									{renderReportDetailsSection(section, paymentsSeparator)}
								</>
							));

							return (
								<>
									{renderReportDetailsSection(section, sectionHeader)}
									{paymentsPerDividendPayer}
									{renderReportDetailsSection(section, totalDividendsPerPayer)}
								</>);
						})}
				</tbody>
			</table>
			}
		</div>}
	</div>;
};

export default DividendsReceivedReport;