import { TFunction } from 'i18next';
import { DividendPaidFields } from 'src/common/types/enums/DividendsPaidFields';
import { ILineItem } from 'src/common/types/interfaces/ILineItem';
import { IRowInformation } from 'src/common/types/interfaces/IRowInformation';
import { inputRecordHelper } from 'src/common/utils/inputRecordHelper';
export const DividendsPaidHelper = {

	getRowInformation: (
		translationService: TFunction,
		lineItems?: ILineItem[]
	): IRowInformation[] =>
	{
		const lineItemsByKey = inputRecordHelper.lineItemsByKey(lineItems);

		return [
			{
				key: DividendPaidFields.effectiveDate,
				name: translationService(DividendPaidFields.effectiveDate),
				lineItemConfiguration: lineItemsByKey[DividendPaidFields.effectiveDate]
			},
			{
				key: DividendPaidFields.currencyId,
				name: translationService(DividendPaidFields.currencyId),
				lineItemConfiguration: lineItemsByKey[DividendPaidFields.currencyId]
			},
			{
				key: DividendPaidFields.amount,
				name: translationService(DividendPaidFields.amount),
				lineItemConfiguration: lineItemsByKey[DividendPaidFields.amount]
			},
			{
				key: DividendPaidFields.paidOnEquityClassId,
				name: translationService(DividendPaidFields.paidOnEquityClassId),
				lineItemConfiguration: lineItemsByKey[DividendPaidFields.paidOnEquityClassId]
			},
			{
				key: DividendPaidFields.underlyingForeignTax,
				name: translationService(DividendPaidFields.underlyingForeignTax),
				lineItemConfiguration: lineItemsByKey[DividendPaidFields.underlyingForeignTax]
			},
			{
				key: DividendPaidFields.isSurplusElection,
				name: translationService(DividendPaidFields.isSurplusElection),
				lineItemConfiguration: lineItemsByKey[DividendPaidFields.isSurplusElection]
			},
			{
				key: DividendPaidFields.isCapitalElection,
				name: translationService(DividendPaidFields.isCapitalElection),
				lineItemConfiguration: lineItemsByKey[DividendPaidFields.isCapitalElection]
			},
			{
				key: DividendPaidFields.notes,
				name: translationService(DividendPaidFields.notes),
				lineItemConfiguration: lineItemsByKey[DividendPaidFields.notes]
			}
		];
	}
};