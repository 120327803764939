import { IPropertyIncomeRowColumnsProps } from './IPropertyIncomeRowColumnsProps';
import {
	DropdownControl,
	NumericInputWithNotesAndActionControl
} from '../../../GridControls';
import { PropertyIncomeFieldNames } from 'src/common/types/enums/PropertyIncomeTypes';
import { Utils } from 'src/common/utils/utils';
import { useTranslation } from 'react-i18next';

export function PropertyIncomeRowColumns(props: IPropertyIncomeRowColumnsProps): JSX.Element
{
	const { t } = useTranslation(
		'common',
		{
			keyPrefix: 'propertyIncomeRowColumns'
		}
	);

	return <>
		{
			!!props.rowData &&
			<tr>
				<td className={'first-column'}>
					<DropdownControl
						isSearchable={true}
						isDisabled={
							props.isLoading ||
							props.isDisabled
						}
						includeResetOption
						options={props.descriptionOptions}
						placeholder={t('descriptionPlaceholder') || ''}
						value={`${!!props.rowData.length ? props.rowData[0].descriptionId : 0}`}
						isError={
							!!props.validate &&
							!!props.rowData.length &&
							!props.rowData[0].descriptionId &&
							!!props.rowData.some((rd) => !!Utils.isValidNumber(`${rd.amount}`) || !!rd.typeId || !!rd.notes)
						}
						dropdownMatchWidth={false}
						onChange={(value) =>
						{
							props.onDescriptionUpdate(!!value ? +value : 0);
						}}
					/>
				</td>
				{
					props.rowData
						.map((d, index) =>
						{
							return <>
								<td className={`amount-type ${!!props.displayNotes ? 'notes-included' : ''}`}>
									<NumericInputWithNotesAndActionControl
										isDisabled={
											props.isLoading ||
											props.isDisabled
										}
										displayNotes={!!props.displayNotes}
										notes={!!d.notes ? d.notes : '' }
										value={d.amount}
										isError={
											!!props.validate &&
											!Utils.isValidNumber(`${d.amount}`) &&
											(
												!!d.typeId ||
												!!d.notes
											)
										}
										onValueChange={(value) =>
										{
											props.onValueUpdate(PropertyIncomeFieldNames.Amount, index, value);
										}}
										onNotesSave={(updatedNotes) =>
										{
											props.onValueUpdate(PropertyIncomeFieldNames.Notes, index, updatedNotes);
										}}
									/>
								</td>
								<td className={'amount-type boundary'}>
									<DropdownControl
										isDisabled={
											props.isLoading ||
											props.isDisabled
										}
										includeResetOption
										options={props.typeOptions}
										placeholder={t('typePlaceholder') || ''}
										value={`${d.typeId}`}
										isError={
											!!props.validate &&
											!d.typeId &&
											(
												!!Utils.isValidNumber(`${d.amount}`) ||
												!!d.notes
											)
										}
										onChange={(value) =>
										{
											props.onValueUpdate(PropertyIncomeFieldNames.TypeId, index, value);
										}}
									/>
								</td>
							</>;
						})
				}
			</tr>
		}
	</>;
}