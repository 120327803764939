import { IRetainedEarningsGridProps } from './IRetainedEarningsGridProps';
import { RetainedEarningsRowColumns } from './components/RetainedEarningsRowColumns/RetainedEarningsRowColumns';
import { Button, Badge } from '@appkit4/react-components';
import { useTranslation } from 'react-i18next';
import { Utils } from 'src/common/utils/utils';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import { NumericInputWithNotesControl } from 'src/common/components/GridControls';
import { RetainedEarningsFields, RetainedEarningsFieldNames } from 'src/common/types/enums/RetainedEarnings';
import './RetainedEarningsGrid.scss';
import { IRetainedEarningsRow } from 'src/common/types/interfaces/IRetainedEarnings';
import { gridIds } from 'src/features/engagement/components/FAAnnualData/RetainedEarnings/RetainedEarningsConstants';

export function RetainedEarningsGrid(props: IRetainedEarningsGridProps): JSX.Element
{
	const { t } = useTranslation(
		'common',
		{
			keyPrefix: 'retainedEarningsGrid'
		}
	);

	const { t: retainedEarnings } = useTranslation(
		'input',
		{
			keyPrefix: 'retainedEarnings'
		}
	);

	const renderAmountRow = (fieldName: string, gridId: string, isEditable: boolean ) =>
	{
		const gridSummary = gridId + 'Summary';
		return (
			<tr>
				<th className={'action-column'} />
				<th className={`first-column ${props.isUnreconciledDifferenceErrorVisible &&
					RetainedEarningsFields.unreconciledDifference === fieldName ? 'highlight-error' : ''}
					${RetainedEarningsFields.netIncomeOrLossAfterTax === fieldName &&
					gridId === gridIds.retainedEarnings? 'left-indented netIncomeOrLoss' : ''}`}>
					{
						retainedEarnings(fieldName)
					}
				</th>
				{
					!!gridId && !!props.allRetainedEarningsData && !!props.allRetainedEarningsData.length &&
					props.allRetainedEarningsData.map((s: any, index) =>
					{
						const value = !!s[gridSummary] && !!s[gridSummary][fieldName] ?
							s[gridSummary][fieldName].amount : '';
						const notes = !!s[gridSummary] && !!s[gridSummary][fieldName] ?
							s[gridSummary][fieldName].notes : '';

						const highlightUnreconciledDifferenceCell = props.isUnreconciledDifferenceErrorVisible &&
						RetainedEarningsFields.unreconciledDifference === fieldName && !!value;

						return <>
							{!isEditable && <th className={`subtotal-content amount-type 
								${highlightUnreconciledDifferenceCell ? 'highlight-error' : ''}
								${!!props.displayNotes ? 'notes-included' : ''}`}>
								{Utils.formatCurrency(value, 2)}
							</th>}
							{!!isEditable && <td className={`boundary amount-type ${!!props.displayNotes ? 'notes-included' : ''}`}>
								<NumericInputWithNotesControl
									isDisabled={props.isLoading || props.isDisabled}
									displayNotes={!!props.displayNotes}
									value={value}
									onValueChange={(value) =>
									{
										props.onValueUpdate(RetainedEarningsFieldNames.Amount + '::' + fieldName,
											-1,
											index,
											value);
									}}
									notes={notes}
									onNotesSave={(updatedNotes) =>
									{
										props.onValueUpdate(RetainedEarningsFieldNames.Notes + '::' + fieldName,
											-1,
											index,
											updatedNotes);
									}}
								/>
							</td>}
						</>;
					})
				}
			</tr>
		);
	};

	return <>
		{
			!!props.rowData &&
			<div
				className={'retained-earnings-grid'}
				style={{
					visibility: props.isLoading ? 'hidden' : 'visible'
				}}
			>

				<div
					ref={props.customRef[0]}
					className={'table-container table-scroll-container'}
				>
					<table
						id={props.id}
						className={props.displayNotes ? 'notes' : ''}
					>
						{
							!!props.allRetainedEarningsData &&
							!!props.allRetainedEarningsData.length && props.allRetainedEarningsData.length > 0 &&
							<>
								<thead>
									{!!props.id && props.id === gridIds.retainedEarnings &&
										<tr>
											<th className={'action-column'} />
											<th className={'first-column opening-balance'}>
												<div>{retainedEarnings('openingRetainedEarnings')}</div>
												<div className={'subtitle'}>
													{
														Utils.replaceTokens(retainedEarnings('openingRetainedEarnings-description'),
															(dateFormatter.format( dateFormatter.utcToEst(props.affiliateStartTaxYearEnd),
															 'MMM DD, YYYY')))
													}
												</div>
											</th>
											{
												!!props.allRetainedEarningsData &&
												!!props.allRetainedEarningsData.length &&
												props.allRetainedEarningsData
													.map((d: any, index) =>
													{
														let value = '';
														let notes = '';
														const fieldName= 'openingRetainedEarnings';
														if (!!d.retainedEarningsSummary &&
															!!d.retainedEarningsSummary.openingRetainedEarnings)
														{
															const openingEarnings = d.retainedEarningsSummary.openingRetainedEarnings;
															value = !!openingEarnings.amount ? openingEarnings.amount : '';
															notes = !!openingEarnings.notes ? openingEarnings.notes : '';
														}

														return <>
															{props.allRetainedEarningsData.length === index+1 &&
															d.affiliateTaxYearEndId === props.affiliateStartTaxYearEndId ?
																<td className={`boundary amount-type 
															${!!props.displayNotes ? 'notes-included' : ''}`}>
																	<NumericInputWithNotesControl
																		isDisabled={props.isLoading || props.isDisabled}
																		displayNotes={!!props.displayNotes}
																		value={value}
																		onValueChange={(value) =>
																		{
																			props.onValueUpdate(
																				RetainedEarningsFieldNames.Amount + '::' + fieldName,
																				-1,
																				index,
																				value);
																		}}

																		notes={notes}
																		onNotesSave={(updatedNotes) =>
																		{
																			props.onValueUpdate(
																				RetainedEarningsFieldNames.Notes + '::' + fieldName,
																				-1,
																				index,
																				updatedNotes);
																		}}
																	/>
																</td>:
																<th className={`subtotal-content amount-type
																${!!props.displayNotes ? 'notes-included' : ''}`}>
																	{Utils.formatCurrency(value, 2)}
																</th>
															}
														</>;
													})
											}
										</tr>}
								</thead>
								{!!props.id && props.id === gridIds.netIncomeOrLoss &&
									<thead>
										{
											renderAmountRow('netIncomeOrLossAfterTax', !!props.id ? props.id : '', false)
										}
									</thead>
								}
								<tbody>
									{!!props.rowData && !!props.rowData.length &&
										props.rowData.map((rd: IRetainedEarningsRow[], rowIndex: any) =>
										{
											return <RetainedEarningsRowColumns
												isLoading={props.isLoading}
												isDisabled={props.isDisabled}
												validate={props.validate}
												displayNotes={props.displayNotes}
												rowData={rd}
												descriptionOptions={props.descriptionOptions}
												onDescriptionUpdate={(value) =>
												{
													props.onDescriptionUpdate(rowIndex, value);
												}}
												onValueUpdate={(fieldName, index, value) =>
												{
													props.onValueUpdate(fieldName, rowIndex, index, value);
												}}
												onDeleteRow={props.onDeleteRow}
											/>;
										})
									}
								</tbody>
							</>
						}
					</table>
				</div>
				{
					(
						!props.rowData ||
						!props.rowData.length
					) &&
					<div className={'no-data'}>
						<div>
							{t('noDataPart1')}
						</div>
						<div>
							{t('noDataPart2')}<strong>+ {props.addRowButtonText}</strong> {t('noDataPart3')}
						</div>
					</div>
				}
				<div className={'actions'}>
					<Button
						kind={'text'}
						icon={'icon-plus-outline'}
						onClick={props.onRowAdd}
					>
						{
							props.addRowButtonText
						}
					</Button>
				</div>
				<div
					ref={props.customRef[1]}
					className={`table-container ${!!props.id && props.id !== gridIds.netIncomeOrLoss ? 'table-scroll-container' : ''}`}
				>
					<table
						id={props.id}
						className={props.displayNotes ? 'notes' : ''}
					>
						{
							!!props.allRetainedEarningsData &&
							!!props.allRetainedEarningsData.length && props.allRetainedEarningsData.length > 0 &&
							<>
								{!!props.id && props.id === gridIds.retainedEarnings &&
									<thead>
										{
											renderAmountRow(RetainedEarningsFields.netIncomeOrLossAfterTax,
												!!props.id ? props.id : '',
												true)
										}
										{
											renderAmountRow(RetainedEarningsFields.endingRetainedEarnings,
												!!props.id ? props.id : '',
												false)
										}
										{
											renderAmountRow(RetainedEarningsFields.endingRetainedEarningsOnFinancialStatements,
												!!props.id ? props.id : '',
												true)
										}
									</thead>
								}
								{!!props.id && props.id === gridIds.netIncomeOrLoss &&
									<thead>
										{
											renderAmountRow(RetainedEarningsFields.netIncomeOrLossBeforeTax,
												!!props.id ? props.id : '',
												false)
										}
										{
											renderAmountRow(RetainedEarningsFields.netIncomeOrLossFromTaxReturn,
												!!props.id ? props.id : '',
												true)
										}
									</thead>
								}
								<thead>
									{
										renderAmountRow(RetainedEarningsFields.unreconciledDifference, !!props.id ? props.id : '', false)
									}
								</thead>
							</>
						}
					</table>
					{props.isUnreconciledDifferenceErrorVisible &&
						<div className={'error'}>
							<div className={'action-error'} />
							<div className={'error-info'}>
								<Badge value={retainedEarnings('unreconciledDifference-error')} type={'danger'} />
							</div>
							<div className={'error-message'}>
								{retainedEarnings('unreconciledDifference-message')}
							</div>
						</div>}
				</div>
			</div>
		}
	</>;
}