export enum ReportEnum {
	SurplusBalances = 'summary-surplus-balances',
	ShareholderOwnership = 'shareholder-ownership',
	AffiliateOwnership = 'affiliate-ownership',
	ExemptSurplusSummary = 'exempt-surplus-summary',
	ExemptSurplusDetails = 'exempt-surplus-details',
	HybridSurplusSummary = 'hybrid-surplus-summary',
	HybridSurplusDetails = 'hybrid-surplus-details',
	TaxableSurplusSummary = 'taxable-surplus-summary',
	TaxableSurplusDetails = 'taxable-surplus-details',
	EarningsSummary = 'earnings-summary',
	EarningsDetails = 'earnings-details',
	ExemptEarningsAndTaxable = 'exempt-earnings-taxable',
	AdjustedCostBaseSummary = 'adjusted-cost-base-summary',
	AdjustedCostBaseDetails = 'adjusted-cost-base-details',
	ForeignAccrualPropertyIncome='foreign-accrual-property-income',
	ForeignTaxes = 'foreign-taxes',
	DividendsReceived = 'dividends-received',
	DividendsPaid = 'dividends-paid',
	CapitalGains = 'capital-gains'
}