import React, { useEffect, useRef, useState } from 'react';

import './FaListItems.scss';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import {
	Grid,
	GridToolbar,
	GridColumn,
	GridNoRecords,
	GridSortChangeEvent,
	GridRowProps,
	GridCellProps
} from '@progress/kendo-react-grid';
import { Checkbox } from '@appkit4/react-components/checkbox';
import { useAppSelector } from 'src/store/store';
import { Button, Loading, Search } from '@appkit4/react-components';

import { DropdownEditorCell } from 'src/common/components/Editors/DropdownEditor/DropdownEditorCell';
import { TextEditorCell } from 'src/common/components/Editors/TextEditor/TextEditorCell';
import { DatePickerEditorCell } from 'src/common/components/Editors/DatePickerEditor/DatePickerEditorCell';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { IForeignAffiliate } from 'src/features/engagement/models/IForeignAffiliate';
import { SortableHeaderCell } from 'src/common/components/Renderers/SortableHeaderCell';
import { ICurrency } from 'src/common/types/interfaces/ICurrency';
import { INameId } from 'src/common/types/interfaces/INameId';
import { RoleEnum } from 'src/common/types/enums/RoleEnum';
import { AddFAModal } from '../AddFAModal/AddFAModal';
import { FAEditingWarningModal } from '../../../FAEditingWarningModal/FAEditingWarningModal';
import { BulkEditFAModal } from '../BulkEditFAModal/BulkEditFAModal';
import { DeleteFAModal } from '../DeleteFAModal/DeleteFAModal';
import { ActionEnum } from 'src/common/types/enums/ActionEnum';
import { EngagementStepEnum } from '../../../../models/EngagementStepEnum';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import { EngagementStepSubject } from 'src/core/services/EventBus';
import { EVentTypeEnum } from 'src/common/types/enums/EventTypeEnum';
import { useTranslation } from 'react-i18next';
import { Constants } from 'src/common/types/constants';
import { ForeignAffiliateFields } from 'src/common/types/enums/ForeignAffiliateFields';
import { IEngagementDetail } from 'src/features/engagement/models/IEngagementDetail';

export type EngagementState = {
  clientId?: number |undefined;
  clientName?: string|undefined;
  engagementId?: number|undefined;
};


export const FaListItems = (props: {
  clientId: number | undefined;
  engagementId: number | undefined;
  engagementDetail?: IEngagementDetail|undefined;
  currentStep: number;
  action: ActionEnum;
  onAction: (action: ActionEnum, result: boolean) => void;
  onEditing: (editing: boolean) => void;
  onDisableNext: (disableNext: boolean) => void;
}) =>
{
	const [rowData, setRowData] = useState<IForeignAffiliate[]>([]);
	const [displayError, setDisplayError] = useState<boolean>(false);
	const [filterRowData, setFilterRowData] = useState<IForeignAffiliate[]>([]);
	const [bulkUpdateFA, setBulkUpdateFA] = useState<IForeignAffiliate | undefined>(undefined);

	const [errorExisting, setErrorExisting] = useState<boolean>(false);
	const [relevantDatesWarning, setRelevantDatesWarning] = useState<boolean>(false);
	const rowDataRef = useRef(rowData);
	const countries = useAppSelector((state) => state.appSettings.countries);
	const currencies = useAppSelector((state) => state.appSettings.currencies);
	const [sort, setSort] = useState<SortDescriptor[]>();
	const userProfile = useAppSelector((state) => state.userProfile.profile);
	const [showAddFAModal, setShowAddFAModal] = useState<boolean>(false);

	const [editableRowIndex, setEditableRowIndex] = useState<number | null>(null);
	const editableRowIndexRef = useRef(editableRowIndex);
	const [loading, setLoading] = useState<boolean>(false);
	const [addingExistingFAAPIErrors, setAddingExistingFAAPIErrors] = useState<any>(null);
	const [showFAEditingWarningModal, setShowFAEditingWarningModal] =
    useState<boolean>(false);
	const [showFABulkEditingWarningModal, setShowFABulkEditingWarningModal] =
    useState<boolean>(false);
	const [showBulkEditFAModal, setShowBulkEditFAModal] = useState<boolean>(false);
	const [isBulkUpdated, setIsBulkUpdated] = useState<boolean>(false);
	const [showDeleteFAModal, setShowDeleteFAModal] = useState<boolean>(false);

	const [triggerEditField, setTriggerEditField] = useState<string>('');

	const [searchText, setSearchText] = useState<string>('');

	const [editingRow, setEditingRow] = useState<IForeignAffiliate | undefined>(
		undefined
	);
	const [isAddingNewFA, setIsAddingNewFA] = useState<boolean>(false);

	const [selection, setSelection] = useState<boolean>(false);
	const [, setSelectAll] = useState<boolean>(false);
	const [selectedRows, setSelectedRows] = useState<any[]>([]);

	const editingRowRef = useRef(editingRow);

	const SELECTED_FIELD: string = 'selected';

	const { t } = useTranslation('engagementSetup');
	const { t: errorMessages } = useTranslation('errors');

	const defaultFaErrors = {
		clientSideErr: {
			name: t('addForeignAffiliates.error.name'),
			countryId: t('addForeignAffiliates.error.countryId'),
			currencyId: t('addForeignAffiliates.error.currencyId'),
			taxationYearEnd: t('addForeignAffiliates.error.taxationYearEnd'),
			dateBecameFa: t('addForeignAffiliates.error.dateBecameFa'),
			analysisStartDate: t('addForeignAffiliates.error.analysisStartDate'),
			analysisEndDate: t('addForeignAffiliates.error.analysisEndDate')
		},
		apiErr: {
			name: null
		}
	};

	useEffect(() =>
	{
		props.onDisableNext(relevantDatesWarning);
	},[relevantDatesWarning]);


	useEffect(() =>
	{
		editableRowIndexRef.current = editableRowIndex;
		if (editableRowIndex)
		{
			setTimeout(() =>
			{
				const fnInput = document.querySelector(
					'.fn-name-input input'
				) as HTMLInputElement;
				if (fnInput)
				{
					requestAnimationFrame(() =>
					{
						fnInput.focus();
					});
				}
			}, 500);
		}
	}, [editableRowIndex]);

	const initialFaErrors = {
		clientSideErr: {
			name: '',
			countryId: '',
			currencyId: '',
			taxationYearEnd: '',
			dateBecameFa: '',
			analysisStartDate: '',
			analysisEndDate: ''
		},
		apiErr: {
			name: ''
		}
	};
	const [faErrors, setFaErrors] = useState<any>(undefined);

	const faErrorsRef = useRef(faErrors);

	useEffect(() =>
	{
		var elems: any = document.querySelector(
			'.k-grid-container .k-grid-table-wrap'
		);
		if (filterRowData.length > 0)
		{
			elems.style.height = 'fit-content';
		}
		else
		{
			elems.style.height = '100%';
		}
	}, [filterRowData]);

	useEffect(() =>
	{
		setErrorExisting(!!faErrors);
		faErrorsRef.current = faErrors;
	}, [faErrors]);

	useEffect(() =>
	{
		if (editingRow)
		{
			if (editingRowRef.current)
			{
				if (
					!editingRowRef.current.showedWarning &&
          editingRow?.showedWarning &&
          editingRowRef.current.affiliateId === editingRow.affiliateId
				)
				{
					setShowFAEditingWarningModal(true);
				}
				if (
					editingRow?.showedWarning &&
          editingRowRef.current.affiliateId !== editingRow.affiliateId
				)
				{
					setShowFAEditingWarningModal(true);
				}
			}
			else
			{
				if (editingRow && editingRow.showedWarning)
				{
					setShowFAEditingWarningModal(true);
				}
			}
		}
		editingRowRef.current = editingRow;
		props.onEditing(!!editingRow);
	}, [editingRow]);

	useEffect(() =>
	{
		props.onEditing(isAddingNewFA);
	},[isAddingNewFA]);

	const loadForeignAffiliates = () =>
	{
		if (
			props.currentStep === EngagementStepEnum.AddForeignAffiliates &&
			props.engagementId
		)
		{
			setLoading(true);
			engagementService
				.getForeignAffiliatesByEngagementId(props.engagementId)
				.then((res: any) =>
				{
					if (res.data.result)
					{
						const fas = res.data.result.map((fa: any) =>
						{
							const f: IForeignAffiliate = {
								affiliateId: fa.affiliateId,
								name: fa.name,
								currencyId: fa.currencyId,
								countryId: fa.countryId,
								taxationYearEnd: dateFormatter.toStartOfDayLocal(fa.taxationYearEnd),
								dateBecameFa: (new Date(fa.dateBecameFa).getFullYear()<=Constants.MinYear)?
									undefined:dateFormatter.toStartOfDayLocal(fa.dateBecameFa),
								analysisStartDate: (new Date(fa.analysisStartDate).getFullYear()<=Constants.MinYear)?
									undefined:dateFormatter.toStartOfDayLocal(fa.analysisStartDate),
								analysisEndDate: dateFormatter.utcToEst(fa.analysisEndDate),
								inEditableMode: false,
								isNewItem: false,
								selected: false,
								showedWarning: false,
								isSystemAdded: fa.isSystemAdded
							};
							return f;
						});
						setRowData(fas);
					}
					setLoading(false);
					setIsAddingNewFA(false);
					setEditingRow(undefined);
				})
				.catch((error: any) =>
				{
					setLoading(false);
				});
		}
	};

	useEffect(() =>
	{
		loadForeignAffiliates();
	}, [props.currentStep]);

	useEffect(() =>
	{
		setRelevantDatesWarning(rowData.some((row: IForeignAffiliate) => row.isSystemAdded===true&&
		(row.analysisStartDate===undefined||row.dateBecameFa===undefined)));

		rowDataRef.current = rowData;

		const selectedData = rowData.filter((r: any) => r.selected === true);
		setSelection(selectedData.length > 0);
		setSelectedRows(selectedData);
		setSelectAll(
			selectedData.length > 0 && selectedData.length === rowData.length
		);

		if (hasError())
		{
			validateAndSave(undefined);
		}
		else
		{
			if (rowData)
			{
				if (searchText === '')
				{
					setFilterRowData(rowData);
				}
				else
				{
					const updatedFilterRowData = rowDataRef.current.filter(
						(row: any) =>
							!row.affiliateId ||
              row.name.toLowerCase().includes(searchText.trim().toLowerCase())
					);
					setFilterRowData(updatedFilterRowData);
				}
			}
		}
	}, [rowData]);

	useEffect(() =>
	{
		if (rowData)
		{
			handleSelectAll(false);
		}
	}, [searchText]);

	const onSortChange = (event: GridSortChangeEvent) =>
	{
		setSort(event.sort);
		if (event.sort[0].field === ForeignAffiliateFields.countryId)
		{
			const rows = rowDataRef.current.map((r: any) =>
			{
				const c = countries.find((c: INameId) => c.id === r.countryId);
				return {
					...r,
					[`${event.sort[0].field}Name`]: c?.name
				};
			});
			const newSort = [
				{
					dir: event.sort[0].dir,
					field: event.sort[0].field + 'Name'
				}
			];
			const sortedRowData = orderBy(rows, newSort);
			setRowData(sortedRowData);
		}
		else if (event.sort[0].field === ForeignAffiliateFields.currencyId)
		{
			const rows = rowDataRef.current.map((r: any) =>
			{
				const c = currencies.find(
					(c: ICurrency) => c.id === r.currencyId
				);
				return {
					...r,
					[`${event.sort[0].field}Name`]: c?.code
				};
			});
			const newSort = [
				{
					dir: event.sort[0].dir,
					field: event.sort[0].field + 'Name'
				}
			];
			const sortedRowData = orderBy(rows, newSort);
			setRowData(sortedRowData);
		}
		else
		{
			const sortedRowData = orderBy(rowData, event.sort);
			setRowData(sortedRowData);
		}
	};

	const ValidateNewFA = () =>
	{
		const validationError = {
			...initialFaErrors
		};
		const newFA = rowDataRef.current.find((r) => r.inEditableMode === true);
		if (!newFA)
		{
			return true;
		}
		validationError.clientSideErr.name =
      newFA.name === '' ? defaultFaErrors.clientSideErr.name : '';

		if (newFA.name)
		{
			//check whether it is updated
			const duplicatedFA = rowDataRef.current.find(
				(r) =>
					r.inEditableMode === false && r.name.trim() === newFA.name.trim()
			);
			if (duplicatedFA)
			{
				validationError.clientSideErr.name =  t('addForeignAffiliates.duplicateName');
			}
		}

		validationError.clientSideErr.currencyId =
      !newFA.currencyId ?
      	defaultFaErrors.clientSideErr.currencyId :
      	'';

		validationError.clientSideErr.countryId = !newFA.countryId ?
			defaultFaErrors.clientSideErr.countryId :
			'';

		validationError.clientSideErr.taxationYearEnd = !newFA.taxationYearEnd ?
			defaultFaErrors.clientSideErr.taxationYearEnd :
			'';

		validationError.clientSideErr.analysisStartDate = !newFA.analysisStartDate ?
			defaultFaErrors.clientSideErr.analysisStartDate :
			'';

		validationError.clientSideErr.analysisEndDate = newFA.analysisStartDate && newFA.analysisEndDate &&
			 newFA.analysisEndDate < newFA.analysisStartDate ?
			defaultFaErrors.clientSideErr.analysisEndDate :
			'';

		validationError.clientSideErr.dateBecameFa = !newFA.dateBecameFa ?
			defaultFaErrors.clientSideErr.dateBecameFa :
			'';


		if (
			!newFA.name ||
      !newFA.currencyId ||
      !newFA.countryId ||
      !newFA.taxationYearEnd ||
      !newFA.analysisStartDate ||
	  validationError.clientSideErr.analysisEndDate ||
      !newFA.dateBecameFa ||
      validationError.clientSideErr.name
		)
		{
			setFaErrors(validationError);
			return false;
		}
		else
		{
			setFaErrors(undefined);
			return true;
		}
	};

	const handleAPIError = (apierror: any) =>
	{
		const errors = apierror.response.data.Errors;
		if (errors)
		{
			const validationError = {
				...initialFaErrors
			};
			errors.forEach((error: any) =>
			{
				if (error.ErrorCode==='FOREIGNAFFILIATE_DUPLICATE')
				{
					validationError.clientSideErr.name =   errorMessages(`code.${error.ErrorCode}`);
				}
				if (error.ErrorCode==='INVALID_ANALYSIS_END_DATE')
				{
					validationError.clientSideErr.analysisEndDate =  errorMessages(`code.${error.ErrorCode}`);
				}
				if (error.ErrorCode==='INVALID_ANALYSIS_START_DATE_BEFORE_CORP_FA')
				{
					validationError.clientSideErr.analysisStartDate = errorMessages(`code.${error.ErrorCode}`);
				}
				if (error.ErrorCode==='INVALID_ANALYSIS_START_DATE_AFTER_END_DATE')
				{
					validationError.clientSideErr.analysisStartDate = errorMessages(`code.${error.ErrorCode}`);
				}
			});
			setFaErrors(validationError);
		}
	};

	const SaveNewFA = async (editingId: number|undefined) =>
	{
		const newFA = rowDataRef.current.find((r) => r.inEditableMode === true);
		if (newFA)
		{
				 engagementService.addNewFA(
				props.clientId,
				props.engagementId,
				newFA.name,
				newFA.countryId,
				newFA.currencyId,
				newFA.taxationYearEnd,
				newFA.dateBecameFa,
				newFA.analysisStartDate,
				newFA.analysisEndDate
			).then((res: any) =>
			{
				EngagementStepSubject.next({
					eventType: EVentTypeEnum.EngagementStepper,
					payload: EngagementStepEnum.AddForeignAffiliates
				});
				setEditableRowIndex(null);
				setFaErrors(undefined);

				setTriggerEditField('');
				loadForeignAffiliates();
			})
				.catch((error: any) =>
				{
					handleAPIError(error);
					return false;
				}
				);
		}
	};

	//TODO
	const hasChangeOnEditing = () =>
	{
		const editingFA = rowDataRef.current.find((r) => r.inEditableMode === true);
		if (editingFA && editingRowRef.current)
		{
			if (
				editingFA.name !== editingRowRef.current.name ||
        editingFA.countryId !== editingRowRef.current.countryId ||
        editingFA.currencyId !==
          editingRowRef.current.currencyId ||
        editingFA.taxationYearEnd?.toISOString() !==
          dateFormatter.toStartOfDayLocal(editingRowRef.current.taxationYearEnd ?? '')?.toISOString() ||
        editingFA.analysisStartDate?.toISOString() !==
          dateFormatter.toStartOfDayLocal(editingRowRef.current.analysisStartDate ?? '')?.toISOString() ||
        editingFA.dateBecameFa?.toISOString() !==
          dateFormatter.toStartOfDayLocal(editingRowRef.current.dateBecameFa ?? '')?.toISOString()||
		  editingFA.analysisEndDate!== editingRowRef.current.analysisEndDate
			)
			{
				return true;
			}
			else
			{
				return false;
			}
		}
		else
		{
			return false;
		}
	};

	//undefined - do nothing next step, >0: edit =0 add new
	const validateAndSave = async (editingId: number|undefined) =>
	{
		const editingFA = rowDataRef.current.find((r) => r.inEditableMode === true);
		if (editingFA)
		{
			if (editingFA.isNewItem)
			{
				const validated = ValidateNewFA();
				if (validated)
				{
					const result = await SaveNewFA(editingId);

					return result;
				}
				else
				{
					return false;
				}
			}
			else
			{
				const validated = ValidateNewFA();
				if (validated && editingRowRef.current)
				{
					if (hasChangeOnEditing())
					{
						const fa = editingFA;
						try
						{
							await engagementService.updateFA(
								fa.affiliateId,
								props.clientId,
								props.engagementId,
								fa.name,
								fa.countryId,
								fa.currencyId,
								fa.taxationYearEnd,
								fa.dateBecameFa,
								fa.analysisStartDate,
								fa.analysisEndDate
							);

							const updatedRowData = rowDataRef.current.map((r) =>
							{
								if (r.inEditableMode === true && r.isNewItem === false)
								{
									return {
										...r,
										inEditableMode: false
									};
								}
								else if (
									r.affiliateId === editingId &&
                  					editingId && editingId>0
								)
								{
									return {
										...r,
										inEditableMode: true,
										taxationYearEnd: dateFormatter.toStartOfDayLocal(r.taxationYearEnd),
										dateBecameFa: dateFormatter.toStartOfDayLocal(r.dateBecameFa),
										analysisStartDate: dateFormatter.toStartOfDayLocal(r.analysisStartDate)
									};
								}
								else
								{
									return r;
								}
							});
							if (editingId===0)
							{
								const newRecord: IForeignAffiliate = {
									affiliateId: 0,
									name: '',
									currencyId: 0,
									countryId: 0,
									taxationYearEnd: undefined,
									dateBecameFa: undefined,
									analysisStartDate: undefined,
									inEditableMode: true,
									isNewItem: true,
									selected: false,
									showedWarning: false
								};
								if (searchText !== '')
								{
									setSearchText('');
								}
								setRowData([newRecord, ...updatedRowData]);
								if (editableRowIndex)
								{
									setEditableRowIndex(1 + editableRowIndex);
								}
								else
								{
									setEditableRowIndex(1);
								}
								setEditingRow(undefined);
							}
							else
							{
								if ((editingId ? editingId : 0) > 0)
								{
									const selectedRow = updatedRowData.find(
										(r: any) => r.affiliateId === editingId
									);
									if (selectedRow)
									{
										setEditingRow(selectedRow);
									}
								}
								else
								{
									setEditingRow(undefined);
								}
								setTriggerEditField('');

								setEditingRow(undefined);
								setRowData(updatedRowData);
							}

							return true;
						}
						catch (error: any)
						{
							handleAPIError(error);
							return false;
						}
					}
					else
					{
						clearRow(editingId);
						return true;
					}
				}
				else
				{
					if (!editingRowRef.current)
					{
						//create a new
						if (editingId)
	    				{
							clearRow(editingId);
							return true;
						}
					}
					else
					{
						return false;
					}
				}
			}
		}
		else
		{
			return true;
		}
	};

	const addNewAffiliate = async () =>
	{
		if (searchText !== '')
		{
			setSearchText('');
		}
		const newRecord: IForeignAffiliate = {
			affiliateId: 0,
			name: '',
			currencyId: 0,
			countryId: 0,
			taxationYearEnd: undefined,
			dateBecameFa: undefined,
			analysisStartDate: undefined,
			inEditableMode: true,
			isNewItem: true,
			selected: false,
			showedWarning: false
		};
		const updatedRowData = rowDataRef.current.map((r) =>
		{
			if (r.inEditableMode === true)
			{
				return {
					...r,
					inEditableMode: false,
					isNewItem: false
				};
			}
			else
			{
				return r;
			}
		});
		if (searchText !== '')
		{
			setSearchText('');
		}
		setRowData([newRecord, ...updatedRowData]);
		setIsAddingNewFA(true);
		if (editableRowIndex)
		{
			setEditableRowIndex(1 + editableRowIndex);
		}
		else
		{
			setEditableRowIndex(1);
		}
	};

	const SaveDuplicated = async (newFA: IForeignAffiliate) =>
	{
		if (newFA)
		{
			try
			{
				const res: any = await engagementService.addNewFA(
					props.clientId,
					props.engagementId,
					newFA.name,
					newFA.countryId,
					newFA.currencyId,
					newFA.taxationYearEnd,
					newFA.dateBecameFa,
					newFA.analysisStartDate,
					newFA.analysisEndDate
				);

				setIsAddingNewFA(false);

				EngagementStepSubject.next({
					eventType: EVentTypeEnum.EngagementStepper,
					payload: EngagementStepEnum.AddForeignAffiliates
				});

				const dfa = {
					...newFA,
					affiliateId: res.data,
					inEditableMode: false,
					isNewItem: false,
					taxationYearEnd: dateFormatter.toStartOfDayLocal(newFA.taxationYearEnd),
					dateBecameFa: dateFormatter.toStartOfDayLocal(newFA.dateBecameFa),
					analysisStartDate: dateFormatter.toStartOfDayLocal(newFA.analysisStartDate),
					analysisEndDate: newFA.analysisEndDate
				};
				if (searchText !== '')
				{
					setSearchText('');
				}
				setRowData([dfa, ...rowDataRef.current]);
				setFaErrors(initialFaErrors);
				setEditableRowIndex(null);
				setEditingRow(undefined);
			}
			catch (error: any)
			{
				if (error.response.data.Errors)
				{
					if (error.response.data.Errors.find((e: any) => e.Field === 'Name'))
					{
						//Show duplicate with error message

						const newRecord: IForeignAffiliate = {
							affiliateId: 0,
							name: newFA.name,
							currencyId: newFA.currencyId,
							countryId: newFA.countryId,
							taxationYearEnd: newFA.taxationYearEnd,
							dateBecameFa: newFA.dateBecameFa,
							analysisStartDate: newFA.analysisStartDate,
							analysisEndDate: newFA.analysisEndDate,
							inEditableMode: true,
							isNewItem: true,
							selected: false,
							showedWarning: false
						};
						const updatedRowData = rowDataRef.current.map((r) =>
						{
							if (r.inEditableMode === true)
							{
								return {
									...r,
									inEditableMode: false,
									isNewItem: false
								};
							}
							else
							{
								return r;
							}
						});
						if (searchText !== '')
						{
							setSearchText('');
						}
						setRowData([newRecord, ...updatedRowData]);
						if (editableRowIndex)
						{
							setEditableRowIndex(1 + editableRowIndex);
						}
						else
						{
							setEditableRowIndex(1);
						}

						//Show error message
						setTimeout(() =>
						{
							const validationError = {
								...initialFaErrors
							};
							validationError.clientSideErr.name = t('addForeignAffiliates.duplicateName');
							setFaErrors(validationError);
						}, 100);
					}
				}
			}
		}
	};

	const duplicate = async (dataItem: any, dataIndex: number) =>
	{
		const duplicatedRow = {
			...dataItem,
			id: 0,
			inEditableMode: false,
			isNewItem: false,
			selected: false
		};

		var regexPattern = /\((\d+)\)$/;
		var match = dataItem.name.trim().match(regexPattern);
		if (match)
		{
			var num = getNextDuplicatedNo(
				(duplicatedRow.name = match.input.replace(match[0], ''))
			);
			duplicatedRow.name = match.input.replace(match[0], `(${num})`);
		}
		else
		{
			var nextId = getNextDuplicatedNo(duplicatedRow.name);
			duplicatedRow.name = duplicatedRow.name.trim() + ` (${nextId})`;
		}
		await SaveDuplicated(duplicatedRow);
	};

	const getDuplicatedNo = (name: string) =>
	{
		var regexPattern = /\((\d+)\)$/;
		var match = name.match(regexPattern);
		if (match)
		{
			return Number(match[1]);
		}
		else
		{
			return 0;
		}
	};

	const getNextDuplicatedNo = (name: string) =>
	{
		const rows = rowDataRef.current.filter((r) =>
			r.name.trim().startsWith(name)
		);
		const numbers = rows.map((r) => getDuplicatedNo(r.name));
		const max = Math.max(...numbers);
		return 1 + max;
	};

	const handleRowClick = async (field: string, event: any) =>
	{
		const editingFA = rowDataRef.current.find((r) => r.inEditableMode === true && r.affiliateId === event.affiliateId);
		if (!editingFA)
		{
			return;
		}

		if (!editingRowRef.current || editingRowRef.current.affiliateId !== event.affiliateId)
		{
			const selectedRow = rowDataRef.current.find(
				(r: any) => r.affiliateId === event.affiliateId
			);
			if (selectedRow)
			{
				if (field === ForeignAffiliateFields.analysisStartDate || field === ForeignAffiliateFields.analysisEndDate ||
					field === ForeignAffiliateFields.dateBecameFa)
				{
				}
				else
				{
					setTriggerEditField(field);
					setEditingRow({
						...selectedRow,
						showedWarning: true
					});
				}
			}
		}
		else
		{
			if (field === ForeignAffiliateFields.analysisStartDate || field === ForeignAffiliateFields.analysisEndDate ||
				field === ForeignAffiliateFields.dateBecameFa)
			{
			}
			else
			{
				setTriggerEditField(field);
				if (editingRowRef.current)
				{
					setEditingRow({
						...editingRowRef.current,
						showedWarning: true
					});
				}
			}
		}
	};

	const setEditingFocus = () =>
	{
		setTimeout(() =>
		{
			if (triggerEditField === 'taxationYearEnd')
			{
				const dateDivWrapper = document.querySelector(
					`.custom-input.${triggerEditField} .react-date-picker .ap-field-suffix-wrapper span`
				) as HTMLDivElement;
				dateDivWrapper.click();
			}
			else
			{
				const inputDivWrapper = document.querySelector(
					`.custom-input.${triggerEditField} .ap-field-wrapper input`
				) as HTMLDivElement;
				if (inputDivWrapper)
				{
					inputDivWrapper.click();
				}
			}
		}, 501);
	};

	const continueToEdit = () =>
	{
		if (showFABulkEditingWarningModal)
		{
			//bulk updated setBulkUpdateFA
			if (bulkUpdateFA)
			{
				bulkUpdate([], bulkUpdateFA);
			}
		}
		else
		{
			setShowFAEditingWarningModal(false);
			if (editingRow)
			{
				const updatedRowData = rowDataRef.current.map((r: IForeignAffiliate) =>
				{
					if (r.affiliateId === editingRow.affiliateId)
					{
						return {
							...r,
							inEditableMode: true,
							isNewItem: false,
							taxationYearEnd: dateFormatter.toStartOfDayLocal(editingRow.taxationYearEnd),
							dateBecameFa: dateFormatter.toStartOfDayLocal(editingRow.dateBecameFa),
							analysisStartDate: dateFormatter.toStartOfDayLocal(editingRow.analysisStartDate),
							analysisEndDate: dateFormatter.toStartOfDayLocal(editingRow.analysisEndDate)
						};
					}
					else
					{
						return r;
					}
				});
				setRowData(updatedRowData);
				setEditingFocus();
			}
		}
	};

	const cancelToEdit = () =>
	{
		if (showFABulkEditingWarningModal)
		{
			setShowFABulkEditingWarningModal(false);
			const originalSelectedRows = rowDataRef.current.filter(
				(r: any) => r.selected === true
			);
			setSelectedRows(originalSelectedRows);
			setBulkUpdateFA(undefined);
		}
		else
		{
			setShowFAEditingWarningModal(false);
			if (hasChangeOnEditing())
			{
				validateAndSave(undefined);
				setEditingRow(undefined);
				setTriggerEditField('');
			}
			else
			{
				clearRow(0);
				setEditingRow(undefined);
				setTriggerEditField('');
			}
		}
	};

	const hasError = () =>
	{
		if (!faErrorsRef.current)
		{
			return false;
		}
		if (
			faErrorsRef.current.clientSideErr.name ||
      faErrorsRef.current.clientSideErr.currencyId ||
      faErrorsRef.current.clientSideErr.countryId ||
      faErrorsRef.current.clientSideErr.taxationYearEnd ||
      faErrorsRef.current.clientSideErr.analysisStartDate ||
	  faErrorsRef.current.clientSideErr.analysisEndDate ||
      faErrorsRef.current.clientSideErr.dateBecameFa
		)
		{
			return true;
		}
		else
		{
			return false;
		}
	};

	const addExistingAffiliate = async () =>
	{
		setShowAddFAModal(true);
	};

	const editFA = async () =>
	{
		setShowBulkEditFAModal(true);
	};

	const deleteFA = async () =>
	{
		setShowDeleteFAModal(true);
	};

	const addExistingFA = (fa: IForeignAffiliate) =>
	{
		if (fa.dateBecameFa && fa.analysisStartDate)
		{
			engagementService
				.associateForeignAffiliates(
					fa.affiliateId,
					props.engagementId,
					fa.dateBecameFa,
					fa.analysisStartDate,
					fa.analysisEndDate
				)
				.then((res: any) =>
				{
					setShowAddFAModal(false);
					if (searchText !== '')
					{
						setSearchText('');
					}
					loadForeignAffiliates();
				})
				.catch((error: any) =>
				{
					setAddingExistingFAAPIErrors(error);
				});
		}
	};


	const handleInputClick = (field: string, event: any) =>
	{
		if (field === ForeignAffiliateFields.analysisStartDate || field === ForeignAffiliateFields.analysisEndDate ||
			field === ForeignAffiliateFields.dateBecameFa)
		{
		}
		else
		{
			if (editingRowRef.current && !editingRowRef.current.showedWarning)
			{
				setTriggerEditField(field);
				setEditingRow({
					...editingRowRef.current,
					showedWarning: true
				});
			}
		}
	};

	const handleSelection = async (param: any, selected: boolean) =>
	{
		const updatedRowData = rowDataRef.current.map((r: any) =>
		{
			if (r.affiliateId === param.dataItem.affiliateId)
			{
				return {
					...r,
					selected: selected
				};
			}
			else
			{
				return r;
			}
		});
		setRowData(updatedRowData);
	};

	const handleSelectAll = (selected: boolean) =>
	{
		const updatedRowData = rowDataRef.current.map((r: any) =>
		{
			return {
				...r,
				selected: selected
			};
		});
		setSelectAll(selected);
		setRowData(updatedRowData);
	};

	const handleBulkUpdate = (
		list: IForeignAffiliate[],
		updatedFA: IForeignAffiliate
	) =>
	{
		if (
			!!updatedFA.taxationYearEnd||
      updatedFA.countryId  ||
      updatedFA.currencyId
		)
		{
			setSelectedRows(list);
			setBulkUpdateFA(updatedFA);
			setShowFABulkEditingWarningModal(true);
		}
		else
		{
			bulkUpdate(list, updatedFA);
		}
	};

	const bulkUpdate = (
		updatedSelectedRows: IForeignAffiliate[],
		updatedFA: IForeignAffiliate
	) =>
	{
		if (showFABulkEditingWarningModal)
		{
			setShowFABulkEditingWarningModal(false);
		}

		const finalSelectedRow =
      updatedSelectedRows.length === 0 ? selectedRows : updatedSelectedRows;

		const ids = finalSelectedRow.map((r: any) =>
		{
			return r.affiliateId;
		});
		if (props.engagementId && ids.length > 0 && updatedFA)
		{
			setLoading(true);
			engagementService
				.updateBulkFA(props.engagementId, ids, updatedFA)
				.then((res: any) =>
				{
					loadForeignAffiliates();
					setShowBulkEditFAModal(false);
					setIsBulkUpdated(true);
					setFaErrors(undefined);
				})
				.catch((err: any) =>
				{
					setShowBulkEditFAModal(true);
					setIsBulkUpdated(false);
					handleAPIError(err);
					setLoading(false);
				});
		}
	};

	function ActionsButton(params: GridCellProps)
	{
		const { dataItem, dataIndex } = params;
		 const [disableIcon, setDisableIcon] = useState<boolean>(false);

		const preventDoubleClick = () =>
		{
			setDisableIcon(true);

			setTimeout(() =>
			{
				setDisableIcon(false);
			}, 3000);
		};

		return  (
			<td className="k-command-cell">
				<div className="custom-cell">
					<div className="custom-input">
						<div className="custom-icons duplicate" onClick={preventDoubleClick}>
							<Button
								kind="tertiary"
								onClick={() => duplicate(dataItem, dataIndex)}
								disabled={disableIcon||params.dataItem.inEditableMode||
								params.dataItem.dateBecameFa===undefined||params.dataItem.analysisStartDate===undefined}
								className="Appkit4-icon icon-duplicate-outline"
							></Button>
						</div>
					</div>
					{params.dataItem.inEditableMode&&errorExisting && <div className="custom-cell-error">&nbsp;</div>}
				</div>
			</td>
		);
	}


	const clearRow = (editingId: number|undefined) =>
	{
		setFaErrors(undefined);
		setEditableRowIndex(null);
		if (editingRowRef.current && editingRowRef.current.affiliateId)
		{
			const originalRows = rowDataRef.current.map((r: any) =>
			{
				if (r.affiliateId === editingRowRef.current?.affiliateId)
				{
					return {
						affiliateId: editingRowRef.current?.affiliateId,
						name: editingRowRef.current?.name,
						currencyId: editingRowRef.current?.currencyId,
						countryId: editingRowRef.current?.countryId,
						taxationYearEnd: editingRowRef.current?.taxationYearEnd,
						countryName: '',
						currencyName: '',
						dateBecameFa: editingRowRef.current?.dateBecameFa,
						analysisStartDate: editingRowRef.current?.analysisStartDate,
						analysisEndDate: editingRowRef.current?.analysisEndDate,
						inEditableMode: false,
						isNewItem: false
					};
				}
				else if (
					r.affiliateId === editingId &&
          			editingId
				)
				{
					return {
						...r,
						inEditableMode: true,
						taxationYearEnd: dateFormatter.toStartOfDayLocal(r.taxationYearEnd),
						dateBecameFa: dateFormatter.toStartOfDayLocal(r.dateBecameFa),
						analysisStartDate: dateFormatter.toStartOfDayLocal(r.analysisStartDate),
						analysisEndDate: r.analysisEndDate
					};
				}
				else
				{
					return r;
				}
			});

			setRowData(originalRows);

			setEditingRow(undefined);
			setTriggerEditField('');
		}
		else
		{
			const newRowData = rowDataRef.current.filter(
				(r) => r.isNewItem === false
			);
			const resetRowData = newRowData.map((r: any) =>
			{
				if (r.inEditableMode)
				{
					return {
						...r,
						inEditableMode: false
					};
				}
				else
				{
					return r;
				}
			});
			setRowData(resetRowData);
			setEditingRow(undefined);
			setIsAddingNewFA(false);
		}
	};

	const updateFaErrorState = (
		field: string,
		isClientSideErr: boolean = true,
		errMessage: string | null
	) =>
	{
		const { clientSideErr, apiErr } = faErrors;
		if (isClientSideErr)
		{
			setFaErrors({
				...faErrors,
				clientSideErr: {
					...clientSideErr,
					[field]: errMessage
				}
			});

			return;
		}

		setFaErrors({
			...faErrors,
			apiErr: {
				...apiErr,
				[field]: errMessage
			}
		});
	};

	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		field: string,
		value: any
	) =>
	{
		const currentlyEditedRow = rowDataRef.current.filter(
			(r) => r.inEditableMode === true
		)?.[0] as any;
		currentlyEditedRow[`${field}`] = value;
	};

	const handleDelete = () =>
	{
		if (selectedRows.length > 0)
		{
			const ids = selectedRows.map((r: any) =>
			{
				return r.affiliateId;
			});
			const body = {
				engagementId: props.engagementId,
				affiliates: ids
			};
			engagementService.unlinkForeignAffiliate(body).then((res: any) =>
			{
				setShowDeleteFAModal(false);
				loadForeignAffiliates();
			});
		}
	};

	const onSearchTextChange = async (val: string) =>
	{
		setSearchText(val);
	};

	const validRelevantDates = () =>
	{
		const missingRelevantDates = rowDataRef.current.some((r) => r.dateBecameFa === undefined||r.analysisStartDate === undefined);
		setRelevantDatesWarning(missingRelevantDates);
		return !missingRelevantDates;
	};

	const handleAction = (action: ActionEnum, dataItem: any) =>
	{
		switch (action)
		{
		case ActionEnum.Save:
			validateAndSave(dataItem.affiliateId);
			break;
		case ActionEnum.Cancel:
			clearRow(dataItem.affiliateId);
			break;
		case ActionEnum.Edit:
			const updatedRowData = rowDataRef.current.map((r) =>
			{
				if (r.affiliateId === dataItem.affiliateId)
				{
					return {
						...r,
						inEditableMode: true
					};
				}
				else
				{
					return r;
				}
			});
			setEditingRow({
				...dataItem
			});

			setRowData(updatedRowData);

			break;
		}
	};


	useEffect(() =>
	{
		if (props.currentStep === EngagementStepEnum.AddForeignAffiliates)
		{
			switch (props.action)
			{
			case ActionEnum.Next:
				if (rowData.length === 0)
				{
					setDisplayError(true);
					props.onAction(props.action, false);
				}
				else
				{
					if (validRelevantDates())
					{
						props.onAction(props.action, true);
					}
					else
					{
						props.onAction(props.action, false);
					}
				}

				break;
			case ActionEnum.Back:
				props.onAction(props.action, true);
				break;
			case ActionEnum.Draft:
				validateAndSave(undefined).then((res: any) =>
				{
					props.onAction(props.action, res);
				});
				break;
			}
		}
	}, [props.action]);

	const rowRender = (
		row: React.ReactElement<HTMLTableRowElement>,
		props: GridRowProps
	) =>
	{
		if (!!props.dataItem[ForeignAffiliateFields.affiliateId])
		{
		  if (props.dataItem[ForeignAffiliateFields.dateBecameFa]===undefined||
			props.dataItem[ForeignAffiliateFields.analysisStartDate]===undefined)
			{
				return React.cloneElement(row, {
					className: ` ${row.props.className} highlightRow`
				});
			}
			return React.cloneElement(row, {
				className: ` ${row.props.className} nohighlight`
			});
		}
		return row;
	};


	return (
		<div className="foreign-affiliate-wrapper">
			<div className="title">  {t('addForeignAffiliates.addForeignAffiliates')}</div>
			{loading && (
				<div className="loading-icon">
					<Loading
						loadingType="circular"
						indeterminate={true}
						compact={false}
					/>
				</div>
			)}
			<LocalizationProvider language="en-user">
				<IntlProvider locale="en">
					<Grid
						className={'fa-grid'}
						data={filterRowData}
						sortable={{
							allowUnsort: false
						}}
						sort={sort}
						onSortChange={onSortChange}
						rowRender={rowRender}
					>
						<GridToolbar>
							{(userProfile?.role === RoleEnum.BusinessOwner ||
                userProfile?.role === RoleEnum.PwCUser) && (
								<div className='toolbar'>
									<div className="fa-header">
										<div className="fa-header-left">
											<div className="fa-header-search">
												<Search
													searchType="secondary"
													placeholder={t('addForeignAffiliates.searchForeignAffiliate')||undefined}
													searchValue={searchText}
													onChange={(value) =>
													{
														onSearchTextChange(value);
													}}
													disabled={rowData.length === 0||!!editingRow||isAddingNewFA}
												/>
											</div>
										</div>

										<div className="fa-buttons">
											<div className="flex-button margin-right  custom-command">
												<Button
													icon="icon-plus-outline"
													kind="primary"
													disabled={!!editingRow||isAddingNewFA}
													onClick={addNewAffiliate}
												>
													{t('addForeignAffiliates.addNewFA')}
												</Button>
											</div>
											<div className="flex-button margin-right  custom-command">
												<Button
													icon="icon-plus-outline"
													kind="secondary"
													disabled={!!editingRow||isAddingNewFA}
													onClick={addExistingAffiliate}
												>
													{t('addForeignAffiliates.addExistingFA')}
												</Button>
											</div>
											<div className="flex-button margin-right">
												<Button
													kind="secondary"
													disabled={!selection||!!editingRow||isAddingNewFA}
													onClick={editFA}
												>
													{t('addForeignAffiliates.edit')}
												</Button>
											</div>
											<div className="flex-button">
												<Button
													kind="secondary"
													disabled={!selection||!!editingRow||isAddingNewFA}
													onClick={deleteFA}
												>
													{t('addForeignAffiliates.remove')}
												</Button>
											</div>
										</div>
									</div>
									{relevantDatesWarning&&<div className="warning-container">
										<div  className="warning-messsage">
											<div className="Appkit4-icon icon-information-outline"></div>
											<div>{t('addForeignAffiliates.relevantDateRequired')}</div></div>

									</div>
									}
								</div>
							)}

						</GridToolbar>
						<GridNoRecords>
							{!loading && rowData.length > 0 && filterRowData.length === 0 && (
								<div className="no-record-wrapper">No data available</div>
							)}
							{!loading && rowData.length === 0 && (
								<div
									className={
										displayError ? 'no-record-err-wrapper' : 'no-record-wrapper'
									}
								>
									<div className={displayError ? 'err-message' : 'message'}>
										{t('addForeignAffiliates.addAtLeastOneForeignAffilicate')}
									</div>
									<div
										className={
											displayError ? 'text-err-wrapper' : 'text-wrapper'
										}
									>
										{t('addForeignAffiliates.addForiegnAffiliatebyclicking')}
									</div>
									<div className="anchor-wrapper custom-command">
										{
											<Button className={`link-button ${displayError ? 'anchor-err-style' : 'anchor-style'}` }
												kind='text' onClick={addNewAffiliate}>
												{t('addForeignAffiliates.addNewFA')}
											</Button>
										}
										<div className={displayError ? 'err-message' : ''}>or</div>
										{
											<Button className={`link-button ${displayError ? 'anchor-err-style' : 'anchor-style'}`}
											 kind='text' onClick={addExistingAffiliate}>
												{t('addForeignAffiliates.addExistingFA')}
											</Button>
										}
									</div>
								</div>
							)}
						</GridNoRecords>
						<GridColumn
							field={SELECTED_FIELD}
							width={'24px'}
							headerClassName={'FAgrid-header-cell  checkbox-column'}
							headerCell={(props) => (
								<Checkbox
									checked={
										selectedRows.length > 0 &&
                    selectedRows.length === rowData.length
									}
									indeterminate={
										selectedRows.length > 0 &&
                    selectedRows.length < rowData.length
									}
									disabled={!(rowData.length > 0)}
									onChange={(val) => handleSelectAll(val)}
								></Checkbox>
							)}
							cell={(props) => (
								<td>
									<div className="custom-cell">
										<div className="custom-input">
											{props.dataItem.inEditableMode ?
												<Button
													icon="icon-save-outline"
													kind="text"
													onClick={() =>
														handleAction(ActionEnum.Save, props.dataItem)
													}
												></Button>:
												<Checkbox
													disabled={false}
													checked={props.dataItem.selected}
													onChange={(val) => handleSelection(props, val)}
												></Checkbox> }


										</div>
										{props.dataItem.inEditableMode && errorExisting && (
											<div className="custom-cell-error">&nbsp;</div>
										)}
									</div>
								</td>
							)}
						/>
						<GridColumn
							field={'inEditableMode'}
							title=" "
							width={'24px'}
							headerClassName={'FAgrid-header-cell'}
							cell={(props) => (
								<td>
									<div className="custom-cell">
										<div className="custom-input">
											<div className="action-buttons">
												{props.dataItem.inEditableMode ?
													<Button
														icon="icon-circle-delete-outline"
														kind="text"
														onClick={() =>
															handleAction(ActionEnum.Cancel, props.dataItem)
														}
													></Button>:
													<Button
													 icon="icon-pencil-outline"
														kind="text"
														disabled={!!editingRow||isAddingNewFA}
														onClick={() =>
															handleAction(ActionEnum.Edit, props.dataItem)
														}
													></Button>
												}
											</div>
										</div>
										{props.dataItem.inEditableMode && errorExisting && (
											<div className="custom-cell-error">&nbsp;</div>
										)}
									</div>
								</td>
							)}
						/>
						<GridColumn
							field={ForeignAffiliateFields.name}
							title={t('addForeignAffiliates.title.name')||undefined}
							editable={false}
							sortable={true}
							width={'219px'}
							headerClassName={'FAgrid-header-cell1'}
							cell={(params) =>
								TextEditorCell({
									...params,
									fieldErrors: faErrorsRef.current,
									updateFaErrorState,
									handleInputChange,
									handleRowClick,
									handleInputClick
								})
							}
							headerCell={SortableHeaderCell}
						/>
						<GridColumn
							field={ForeignAffiliateFields.countryId}
							title={t('addForeignAffiliates.title.countryId')||undefined}
							editable={true}
							sortable={true}
							headerClassName={'FAgrid-header-cell1'}
							width={'140px'}
							cell={(params) =>
								DropdownEditorCell({
									...params,
									fieldErrors: faErrors,
									dropdownValues: countries,
									labelKey: 'name',
									valueKey: 'id',
									handleInputChange,
									handleRowClick,
									handleInputClick
								})
							}
							headerCell={SortableHeaderCell}
						/>
						<GridColumn
							field={ForeignAffiliateFields.currencyId}
							title={t('addForeignAffiliates.title.currencyId')||undefined}
							headerClassName={'FAgrid-header-cell'}
							sortable={true}
							width={'120px'}
							cell={(params) =>
								DropdownEditorCell({
									...params,
									fieldErrors: faErrors,
									dropdownValues: currencies,
									labelKey: 'code',
									valueKey: 'id',
									handleInputChange,
									handleRowClick,
									handleInputClick
								})
							}
							headerCell={SortableHeaderCell}
						/>
						<GridColumn
							field={ForeignAffiliateFields.taxationYearEnd}
							title={t('addForeignAffiliates.title.taxationYearEnd')||undefined}
							headerClassName={'FAgrid-header-cell'}
							editable={false}
							sortable={true}
							width={'135px'}
							cell={(params) =>
								DatePickerEditorCell({
									...params,
									isShortFormat: true,
									fieldErrors: faErrors,
									handleInputChange,
									handleRowClick,
									handleInputClick
								})
							}
							headerCell={SortableHeaderCell}
						/>
						<GridColumn
							field={ForeignAffiliateFields.dateBecameFa}
							title={t('addForeignAffiliates.title.dateBecameFa')||undefined}
							headerClassName={'FAgrid-header-cell2'}
							editable={false}
							sortable={true}
							width={'165px'}
							cell={(params) =>
								DatePickerEditorCell({
									...params,
									fieldErrors: faErrors,
									handleInputChange,
									handleRowClick,
									handleInputClick
								})
							}
							headerCell={SortableHeaderCell}
						/>
						<GridColumn
							field={ForeignAffiliateFields.analysisStartDate}
							title={t('addForeignAffiliates.title.analysisStartDate')||undefined}
							headerClassName={'FAgrid-header-cell2'}
							editable={false}
							sortable={true}
							width={'135px'}
							cell={(params) =>
								DatePickerEditorCell({
									...params,
									maxDate: props.engagementDetail?.lastCalculationTaxationYearEnd,
									fieldErrors: faErrors,
									handleInputChange,
									handleRowClick,
									handleInputClick
								})
							}
							headerCell={SortableHeaderCell}
						/>
						<GridColumn
							field={ForeignAffiliateFields.analysisEndDate}
							title={t('addForeignAffiliates.title.analysisEndDate')||undefined}
							headerClassName={'FAgrid-header-cell2'}
							editable={false}
							sortable={true}
							width={'135px'}
							cell={(params) =>
								DatePickerEditorCell({
									...params,
									fieldErrors: faErrors,
									maxDate: props.engagementDetail?.lastCalculationTaxationYearEnd,
									handleInputChange,
									handleRowClick,
									handleInputClick
								})
							}
							headerCell={SortableHeaderCell}
						/>

						<GridColumn
							width={'50px'}
							title={''}
							className={'FAgrid-icons'}
							sortable={false}
							cell={(params) => ActionsButton({
								...params
							})}
						/>
					</Grid>
				</IntlProvider>
			</LocalizationProvider>
			<AddFAModal
				clientId={props.clientId}
				engagementId={props.engagementId}
				visible={showAddFAModal}
				engagementDetail={props.engagementDetail}
				setVisible={(val: boolean) => setShowAddFAModal(val)}
				addExistingFA={addExistingFA}
				faModalErros={addingExistingFAAPIErrors}
			/>
			<FAEditingWarningModal
				showDialog={showFAEditingWarningModal}
				cancelNavigation={cancelToEdit}
				confirmNavigation={continueToEdit}
			></FAEditingWarningModal>
			<BulkEditFAModal
				clientId={props.clientId}
				engagementId={props.engagementId}
				selectedItems={selectedRows}
				visible={showBulkEditFAModal}
				setVisible={(val: boolean) =>
				{
					setShowBulkEditFAModal(val);
				}}
				bulkUpdate={handleBulkUpdate}
				fieldErrors= {faErrors}
				isBulkUpdated={isBulkUpdated}
				setFaErrors= {(val: any) =>
					setFaErrors(val)}
				showDialog={showFABulkEditingWarningModal}
				cancelNavigation={cancelToEdit}
				confirmNavigation={continueToEdit}
			></BulkEditFAModal>
			<DeleteFAModal
				selectedItems={selectedRows}
				visible={showDeleteFAModal}
				title={t('addForeignAffiliates.removeForeignAffiliate')}
				setVisible={(val: boolean) =>
				{
					setShowDeleteFAModal(val);
				}}
				onDelete={handleDelete}
			></DeleteFAModal>
		</div>
	);
};
