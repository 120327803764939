export enum ActionEnum {
    None='None',
    Next='Next',
    Back='Back',
    Cancel='Cancel',
    Save='Save',
    Create='Create',
    Update='Update',
    Delete='Delete',
    Clean='Clean',
    Change='Change',
    Init='Init',
    Draft='Draft',
    Edit='Edit',
}