import {
	Accordion,
	AccordionItem,
	Button,
	Checkbox,
	CheckboxGroup,
	Modal
} from '@appkit4/react-components';
import React, { useEffect } from 'react';
import { useAppSelector } from 'src/store/store';
import './UserFilterModal.scss';

export const UserFilterModal = (props: {
  roles: string[];
  offices: string[];
  visible: boolean;
  onClose: (result: boolean, roles: string[], offices: string[]) => void;
}) =>
{
	const [activeKeys, setActiveKeys] = React.useState<string[]>(['1', '2']);

	const [roles, setRoles] = React.useState<string[]>([]);

	const systemRoles = useAppSelector((state) => state.appSettings.roles);
	const allOffices = useAppSelector((state) => state.appSettings.offices);

	const [offices, setOffices] = React.useState<string[]>([]);

	useEffect(() =>
	{
		allOffices.map((item: string | null) => !item ? 'No Office' :item);
	},[allOffices]);

	useEffect(() =>
	{
		setRoles(props.roles);
	}, [props.roles]);

	useEffect(() =>
	{
		setOffices(props.offices);
	}, [props.offices]);

	const onRoleChange = (value: (string | number)[]) =>
	{
		setRoles(value as Array<string>);
	};

	const onOfficeChange = (value: (string | number)[]) =>
	{
		setOffices(value as Array<string>);
	};

	const onClickAccordion = (activeKeys: string[]) =>
	{
		setActiveKeys(activeKeys);
	};

	return (
		<>
			<Modal
				className="user-filter-modal"
				visible={props.visible}
				maskCloseable={false}
				title={'Filters'}
				ariaLabel={''}
				onCancel={() => props.onClose(false, roles, offices)}
				header={''}
				icons={''}
				placement={'right'}
				footer={
					<div className="userFilters-buttons">
						<Button
							onClick={() =>
							{
								props.onClose(true, [], []);
							}}
							kind="secondary"
						>
              Clear All
						</Button>
						<Button
							onClick={() =>
							{
								props.onClose(true, roles, offices);
							}}
						>
              Apply Filters
						</Button>
					</div>
				}
				bodyStyle={{
					minHeight: '92px'
				}}
			>
				<div className="userFilters">
					<Accordion
						multiple={true}
						onClick={onClickAccordion}
						activeKeys={activeKeys}
					>
						<AccordionItem title={'Role'} itemKey="1">
							<div className="AccordionItem-text">
								<CheckboxGroup
									value={roles}
									name="roles"
									className="list-wrapper"
									onChange={onRoleChange}
								>
									{systemRoles?.map((role: string) =>
									{
										return (
											<Checkbox key={role} value={role}>
												{role}
											</Checkbox>
										);
									})}
								</CheckboxGroup>
							</div>
						</AccordionItem>
						<AccordionItem title={'Office'} itemKey="2">
							<div className="AccordionItem-text">
								<CheckboxGroup
									value={offices}
									name="office"
									className="list-wrapper"
									onChange={onOfficeChange}
								>
									{allOffices?.map((office: string) =>
									{
										return (
											<Checkbox key={office} value={!office?'No Office':office}>
												{!office?'No Office':office}
											</Checkbox>
										);
									})}
								</CheckboxGroup>
							</div>
						</AccordionItem>
					</Accordion>
				</div>
			</Modal>
		</>
	);
};
