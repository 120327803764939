import { IRetainedEarningsRowColumnsProps } from './IRetainedEarningsRowColumnsProps';
import {
	DropdownControl,
	NumericInputWithNotesControl
} from 'src/common/components/GridControls';
import { Utils } from 'src/common/utils/utils';
import { useTranslation } from 'react-i18next';
import { RetainedEarningsFieldNames } from 'src/common/types/enums/RetainedEarnings';
import './RetainedEarningsRowColumns.scss';

export function RetainedEarningsRowColumns(props: IRetainedEarningsRowColumnsProps): JSX.Element
{
	const { t } = useTranslation(
		'common',
		{
			keyPrefix: 'reg5907RowColumns'
		}
	);

	const onDeleteWarning = () =>
	{
		props.onDeleteRow(props.rowData);
	};

	return <>
		{
			!!props.rowData &&
			<>
				<tr>
					<td className={'action-column'}>
						{!!props.rowData[0].rowIndex && props.rowData[0].rowIndex > 0 &&
						<span
							className={'Appkit4-icon icon-delete-outline delete-column'}
							onClick={onDeleteWarning}
						/>
						}
					</td>
					<td className={'first-column left-indented'}>
						<DropdownControl
							isSearchable={true}
							isDisabled={props.isLoading || props.isDisabled}
							includeResetOption
							options={props.descriptionOptions}
							placeholder={t('descriptionPlaceholder') || ''}
							value={`${!!props.rowData.length ? props.rowData[0].differenceTypeId : 0}`}
							isError={
								!!props.validate &&
							!!props.rowData.length &&
							!props.rowData[0].differenceTypeId &&
							!!props.rowData.some((rd) => !!Utils.isValidNumber(`${rd.amount}`) || !!rd.notes)
							}
							dropdownMatchWidth={false}
							onChange={(value) =>
							{
								props.onDescriptionUpdate(!!value ? +value : 0);
							}}
						/>
					</td>
					{
						props.rowData
							.map((d, index) =>
							{
								return <>
									<td className={`boundary amount-type ${!!props.displayNotes ? 'notes-included' : ''}`}>
										<NumericInputWithNotesControl
											isDisabled={
												props.isLoading ||
											props.isDisabled
											}
											displayNotes={!!props.displayNotes}
											notes={!!d.notes ? d.notes : '' }
											value={d.amount}
											onValueChange={(value) =>
											{
												props.onValueUpdate(RetainedEarningsFieldNames.Amount, index, value);
											}}
											onNotesSave={(updatedNotes) =>
											{
												props.onValueUpdate(RetainedEarningsFieldNames.Notes, index, updatedNotes);
											}}
										/>
									</td>
								</>;
							})
					}
				</tr>
			</>
		}
	</>;
}