import { Button, Search, Tooltip } from '@appkit4/react-components';
import Select, { SelectValue } from '@appkit4/react-components/esm/select/Select';
import { Grid, GridCellProps, GridColumn, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useEffect, useState, KeyboardEvent, useRef } from 'react';
import { engagementService } from 'src/features/engagement/services/engagementService';
import './ManageMembers.scss';
import { EngagementStepEnum } from 'src/features/engagement/models/EngagementStepEnum';
import { ActionEnum } from 'src/common/types/enums/ActionEnum';
import { HttpStatusCode } from 'axios';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/store/store';
import { RemoveModal } from '../RemoveYourselfModal/RemoveModal';
import { UnexpectedErrorModal } from 'src/common/components/UnexpectedErrorModal/UnexpectedErrorModal';
import { useNavigate } from 'react-router';
import { IManageMembersProps } from './IManageMembersProps';
import { RoleEnum } from 'src/common/types/enums/RoleEnum';

const ManageMembers = (props: IManageMembersProps) =>
{
	const [searchText, setSearchText] = useState<string>('');
	const editField: string = 'inEdit';
	const [searchListItem, setSearchListItem] = useState<Array<Record<string, any>>>([]);
	const [gridData, setGridData] = useState<Array<Record<string, any>>>([]);
	const [errorNodeRows, setErrorNodeRows] = useState<Array<Record<string, any>>>([]);
	const [displayErrors, setDisplayErrors] = useState<boolean>(false);
	const [userSearchError, setUserSearchError] = useState<string | null>(null);
	const membersGridRef = useRef<HTMLDivElement>(null);
	const { t } = useTranslation('engagementSetup');
	const [isDisabled, setIsDisable] = useState<boolean>(false);
	const engagementRoles = useAppSelector((state) => state.appSettings.engagementRoles);
	const userProfile = useAppSelector((state) => state.userProfile.profile);
	const [selfDeleteDialogParams, setSelfDeleteDialogParams] = useState<Record<string, any> | null>(null);
	const [showUnexpectedErrorModal, setShowUnexpectedErrorModal] = useState<boolean>(false);
	const [apiStatusCode, setApiStatusCode] = useState<number>(0);
	const [unexpectedError, setUnExpectedError] = useState<string>('');
	const [defaultErrorMessage, setDeafultErrorMessage] = useState<string>('');
	const navigate = useNavigate();

	useEffect(() =>
	{
		getEngagementMembers();
	}, []);

	useEffect(() =>
	{
		if (props.currentStep === EngagementStepEnum.ManageMembers)
		{
			switch (props.action)
			{
			case ActionEnum.Next:
				if (errorNodeRows.length > 0)
				{
					setDisplayErrors(true);
					!!props.onAction && props.onAction(props.action, false);
				}
				else
				{
					!!props.onAction && props.onAction(props.action, true);
				}
				break;
			case ActionEnum.Back:
				!!props.onAction && props.onAction(props.action, true);
				break;
			case ActionEnum.Draft:
				!!props.onAction && props.onAction(props.action, true);
				break;
			}
		}
	}, [props.action]);

	useEffect(() =>
	{
		setUserSearchError(null);
	}, [gridData, searchText, searchListItem]);

	useEffect(() =>
	{
		const errorNodeRows = gridData.reduce((formatted: any, item: any) =>
		{
			if (item['userRole'] === -1)
			{
				formatted.push(item['userEmail']);
			}

			return formatted;
		}, []);
		setErrorNodeRows(errorNodeRows);
		setDisplayErrors(false);
	}, [gridData]);

	useEffect(() =>
	{
		const existingRoles = gridData.reduce((formatted: any, item: any) =>
		{
			if (item['userRole'] !== -1)
			{
				formatted.push(item['userEmail']);
			}

			return formatted;
		}, []);
		if (existingRoles.length === 1)
		{
			setIsDisable(true);
		}
		else
		{
			setIsDisable(false);
		}
	}, [gridData]);


	useEffect(() =>
	{
		const newData = searchListItem.reduce((formatted: any, item: any) =>
		{
			if (!!item.email)
			{
				const obj = {
					userEmail: item.email,
					userName: `${item.firstName} ${item.lastName}`,
					userRole: -1,
					inEditableMode: true
				};
				formatted.push(obj);
			}
			return formatted;
		}, []);
		const doesUserExist = gridData.find((item: any) => item['userEmail'] === newData?.[0]?.['userEmail']);
		if (!doesUserExist)
		{
			setGridData([...newData, ...gridData]);
			setSearchText('');
		}
		else
		{
			setUserSearchError(t('engagementMember.userAlreadyExist'));
		}
	}, [searchListItem]);

	const getEngagementMembers = () =>
	{
		engagementService.getEngagementMembers(props.engagementId!).then((res) =>
		{
			const result = res.data.result;
			if (result.length > 0)
			{
				const updatedData = result.reduce((formatted: any, item: any) =>
				{
					const formattedData = {
						userName: item.userName,
						userEmail: item.email,
						userRole: item.roleId,
						inEditableMode: !item.roleId
					};
					formatted.push(formattedData);

					return formatted;
				}, []);
				setGridData(updatedData);
			}
		})
			.catch((err: any) =>
			{
				handleApiErrorResponse(err);
			});;
	};

	const getUserByEmail = () =>
	{
		engagementService.userDetailByEmail(props.engagementId!, searchText).then((res) =>
		{
			const result = res.data.result;

			if (Object.keys(result).length > 0)
			{
				setSearchListItem([result]);
			}
		})
			.catch((err: any) =>
			{
				const {
					response: { status = HttpStatusCode.InternalServerError } = {
					}
				} = err;
				if (status === HttpStatusCode.NotFound)
				{
					setUserSearchError(t('engagementMember.emailNotFound'));
				}
				else if (status === HttpStatusCode.BadRequest)
				{
					setUserSearchError(t('engagementMember.invalidEmail'));
				}
			});
	};

	const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) =>
	{
		if (event.key === 'Enter')
		{
			getUserByEmail();
		}
	};

	const CommandCell = (gridCellProps: GridCellProps) =>
	{
		return (
			<td
				className={'k-command-cell'}
				style={{
					borderLeft: 'transparent'
				}}
			>
				{' '}

				<Tooltip
					trigger="hover"
					position="bottom-left"
					distance={4}
					appendAfterTarget={true}
					content={isDisabled && (gridCellProps.dataItem.userRole !== -1) ?
						`${t('engagementMember.removeSelfWarningMsg')}` : `${t('engagementMember.removeUser')}`}
				>
					<span className='btn-position'>
						<Button
							style={{
								padding: '5px',
								width: '45%',
								backgroundColor: 'transparent'
							}}
							className="user-action-button"
							compact
							kind="tertiary"
							icon="Appkit4-icon icon-delete-outline"
							disabled={isDisabled && (gridCellProps.dataItem.userRole !== -1)}
							onClick={() => deleteEngagementMember(gridCellProps.dataItem)}
						></Button>
					</span>
				</Tooltip>
			</td>
		);
	};

	const deleteEngagementMemberAndUpdateData = (engagementId: number, userEmail: string | undefined, closeSelfDeleteDialog?: boolean) =>
	{
		const updatedGridData = gridData.filter((x: any) => x.userEmail !== userEmail);
		engagementService.deleteEngagementMember(engagementId, userEmail || '').then((res: any) =>
		{
			setGridData(updatedGridData);
			getEngagementMembers();
		})
			.catch((err: any) =>
			{
				handleApiErrorResponse(err);
			});;

		if (!!closeSelfDeleteDialog)
		{
			setSelfDeleteDialogParams(null);
			if (userProfile?.role === RoleEnum.PwCUser)
			{
				navigate('/');
			}
		}
	};


	const deleteEngagementMember = (dataItem: Record<string, any>) =>
	{
		if (userProfile?.email === dataItem.userEmail)
		{
			setSelfDeleteDialogParams(dataItem);
		}
		else
		{
			const { userRole, userEmail } = dataItem;
			setSearchListItem([]);
			if (userRole !== -1)
			{
				deleteEngagementMemberAndUpdateData(props.engagementId!, userEmail);
			}
			else
			{
				const updatedGridData = gridData.filter((x: any) => x.userEmail !== userEmail);
				setGridData(updatedGridData);
			}
		}
	};

	const handleInputChange = (
		field: string | undefined = '',
		value: any,
		dataItem: Record<string, any>
	) =>
	{
		let hasDataItemChanged = false;
		const updatedDataItems = gridData.map((item: any) =>
		{
			if (item['inEditableMode'] === true && item['userEmail'] === dataItem['userEmail'])
			{
				if (item['userRole'] !== value)
				{
					hasDataItemChanged = true;
					item[field] = value;
				}

				item['inEditableMode'] = false;
			}

			return item;
		}, []);
		setGridData(updatedDataItems);

		if (hasDataItemChanged)
		{
			engagementService.saveEngagementMember([{
				userEmail: dataItem?.userEmail,
				roleId: dataItem?.userRole
			}], props.engagementId!).then((res) =>
			{

			})
				.catch((err: any) =>
				{
					handleApiErrorResponse(err);
				});;
		}
	};

	const handleRowClick = (field: string | undefined, dataItem: any) =>
	{
		if (!!dataItem.userRole && !dataItem.inEditableMode)
		{
			const updatedGridData = gridData.reduce((formatted: any, item) =>
			{
				if (item.userEmail === dataItem.userEmail)
				{
					item['inEditableMode'] = true;
				}
				formatted.push(item);

				return formatted;
			}, []);

			setGridData(updatedGridData);
		}
	};

	const handleApiErrorResponse = (err: any) =>
	{
		if (err.response)
		{
			setApiStatusCode(err.response.status);
			if (err.response.status >= 500)
			{
				setDeafultErrorMessage(`${t('unexpected-error-popup.message')}`);
				setShowUnexpectedErrorModal(true);
			}
			else if (err.response.status >= 400)
			{
				if (err.response.status === 403)
				{
					setDeafultErrorMessage(`${t('unexpected-error-popup.permissionMessage')}`);
				}
				else
				{
					setDeafultErrorMessage(`${t('unexpected-error-popup.message')}`);
				}
				setShowUnexpectedErrorModal(true);
			}
		}
	};

	const closeUnexpectedErrorModal = () =>
	{
		setUnExpectedError('');
		setShowUnexpectedErrorModal(false);
		if (apiStatusCode === 403)
		{
			navigate('/');
		}
	};


	return (
		<div className={`eng-member-wrapper ${!!props.fromEngagementDashboard ? 'dashboard' : 'draft-step'}`}
			ref={membersGridRef}>
			<div className={`view ${!!props.fromEngagementDashboard ? '' : 'draft-step'}`}>
				{!props.fromEngagementDashboard &&
					<div className='title'>{'Manage Members'}</div>
				}
				<div className={`eng-member-grid ${!!props.fromEngagementDashboard ? '' : 'draft-step'}`}>
					<LocalizationProvider language="en-user">
						<IntlProvider locale="en">
							<Grid
								className={'eng-member-grid'}
								data={gridData}
								editField={editField}
								sortable={{
									allowUnsort: false
								}}
							>
								<GridToolbar>
									<div className="search-sample">
										<Search
											searchType={'secondary'}
											onChange={(
												value: string,
												event: React.SyntheticEvent | React.ChangeEvent<HTMLInputElement>
											) =>
											{
												setSearchText(value.toLowerCase());
											}}
											searchValue={searchText}
											// data={searchListItem}
											labelKey={'email'}
											valueKey={'id'}
											onClear={() => setSearchText('')}
											placeholder={t('engagementMember.searchPlaceholder') || ''}
											onKeyDown={onKeyDown}
										/>
										{
											!!userSearchError && (
												<div className='user-search-error'>
													{userSearchError}
												</div>
											)
										}

									</div>
									<div className='flex-button'>
										<Button
											kind="tertiary"
											onClick={() => getUserByEmail()}>

											{t('engagementMember.searchBtn')}
										</Button>
									</div>
								</GridToolbar>
								<GridNoRecords>&nbsp;</GridNoRecords>
								<GridColumn
									field={'userName'}
									title={t('engagementMember.name') || ''}
									width={212}
									sortable={true}
								/>
								<GridColumn
									field={'userEmail'}
									title={t('engagementMember.email') || ''}
									width={212}
									sortable={true}
								/>
								<GridColumn
									field="userRole"
									title={t('engagementMember.userRole') || ''}
									editable={true}
									sortable={true}
									width={'140px'}
									cell={(params) =>
									{
										const { field, dataItem } = params;
										const dataValue =
											engagementRoles?.filter((v) => v['id'] === dataItem[field ? field : ''])?.[0]?.['id'] ||
											'';
										const hasError = displayErrors && errorNodeRows.includes(dataItem['userEmail']);
										const selectedValue = engagementRoles?.find((d) => d.id === dataItem['userRole'])?.['name'];

										return (
											<td>
												{dataItem?.inEditableMode ? (
													<div className="custom-cell">
														<div
															className={`custom-input ${field}  ${hasError ? 'custom-input-error' : ''}`}
														>
															<Select
																className="client-name-style"
																data={engagementRoles}
																hideTitleOnInput={true}
																valueKey={'id'}
																labelKey={'name'}
																value={dataValue}
																placeholder={t('engagementMember.rolePlaceholder') || ''}
																noResultFound={t('engagementMember.noData')}
																dropdownRenderMode="portal"
																onSelect={(value: SelectValue) => handleInputChange(field, value, dataItem)}
															/>
														</div>
														{
															hasError && (
																<div className='custom-cell-error'>
																	{t('engagementMember.roleErr')}
																</div>
															)
														}
													</div>
												) : (
													<div className="custom-cell-value" onClick={() => handleRowClick(field, dataItem)}>
														{selectedValue}
													</div>
												)}
											</td>
										);
									}}
								/>
								<GridColumn
									field=""
									title=""
									cell={CommandCell}
									width={'40'}
								/>
							</Grid></IntlProvider></LocalizationProvider>
				</div>
			</div>

			<RemoveModal
				visible={!!selfDeleteDialogParams}
				title={t('engagementMember.removeSelf')}
				setVisible={(val: boolean) =>
				{
					if (!val)
					{
						setSelfDeleteDialogParams(null);
					}
				}}
				onDelete={() => deleteEngagementMemberAndUpdateData(props.engagementId!, selfDeleteDialogParams?.userEmail, true)}
				deleteMessage={t('engagementMember.removeMessage')}
			/>
			<UnexpectedErrorModal
				visible={showUnexpectedErrorModal}
				onClose={closeUnexpectedErrorModal}
				message={defaultErrorMessage}
				error={unexpectedError}
				statusCode={apiStatusCode}
			/>
		</div>
	);
};

export default ManageMembers;