export enum ExportReportFields {
	engagementId='engagementId',
	reportType = 'reportType',
	taxpayerTaxYearEnd = 'taxpayerTaxYearEnd',
	currencyId = 'currencyId',
	currencyConversionDate = 'currencyConversionDate',
	isSEPFactorApplicable = 'isSEPFactorApplicable',
	affiliateIds = 'affiliateIds',
	showNotes = 'showNotes'
}

export enum ExportReportTypes {
	TaxpayerSummaryReportAndFAReports = 'Taxpayer Summary Report and FA Report',
	TaxpayerSummaryReportOnly = 'Taxpayer Summary Report',
}