import { Button, Modal } from '@appkit4/react-components';
import React, { useEffect } from 'react';

import './FAEditingWarningModal.scss';

export const FAEditingWarningModal = (props: {
  showDialog: boolean | (() => void);
  cancelNavigation: any;
  confirmNavigation: any;
}) =>
{
	const [visible, setVisible] = React.useState<boolean>(false);

	useEffect(() =>
	{
		setVisible(!!props.showDialog);
	}, [props.showDialog]);

	return (
		<>
			<Modal
				className="fa-editing-warning-modal"
				visible={visible}
				title={''}
				maskCloseable={false}
				ariaLabel={'Lorem ipsum dolor sit'}
				onCancel={() =>
				{
					setVisible(false);
					props.cancelNavigation();
				}}
				modalStyle={{
					width: '540px'
				}}
				footerStyle={{
					paddingTop: '8px',
					marginTop: '-8px',
					minHeight: '64px'
				}}
				header={
					<>
						<span className="Appkit4-icon icon-warning-fill"></span>
						<span className="warning-title">Warning</span>
					</>
				}
				icons={''}
				footer={
					<>
						<Button
							className="unsaved-cancel"
							onClick={() =>
							{
								setVisible(false);
								props.cancelNavigation();
							}}
							kind="secondary"
						>
              Cancel
						</Button>
						<Button
							onClick={() =>
							{
								setVisible(false);
								props.confirmNavigation();
							}}
						>
              OK
						</Button>
					</>
				}
				bodyStyle={{
					minHeight: '92px'
				}}
			>
				<div className="warning-message">
          You are about to make changes to an existing Foreign Affiliate that
          also appears under other engagements. Are you sure you want to
          continue editing?
					<br />
					<br />
          Changes you make to Foreign Affiliate Name, Country, Calculating
          Currency and Taxation Year End of Foreign Affiliate will be applied to
          the other engagements this foreign affiliate is part of.
				</div>
			</Modal>
		</>
	);
};
