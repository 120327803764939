import { useEffect, useState } from 'react';
import { IABIAggregateRowsProps } from './IABIAggregateRowsProps';
import { Utils } from 'src/common/utils/utils';
import { useTranslation } from 'react-i18next';

import './ABIAggregateRows.scss';
import { IABIComputedUnderCanadianRulesRow } from 'src/common/types/interfaces/IABIComputedUnderCanadianRules';
import { ABIComputedUnderCanadianRulesTypes } from 'src/common/types/enums/ABIComputedUnderCanadianRulesTypes';

interface ISummationBase {
	[ABIComputedUnderCanadianRulesTypes.TotalAdjustments]: number | undefined;
	[ABIComputedUnderCanadianRulesTypes.IncomeLoss]: number | undefined;
}

const summationBase: ISummationBase = {
	[ABIComputedUnderCanadianRulesTypes.TotalAdjustments]: undefined,
	[ABIComputedUnderCanadianRulesTypes.IncomeLoss]: undefined
};

export function ABIAggregateRows(props: IABIAggregateRowsProps): JSX.Element
{
	const [summationByYear, setSummationByYear] = useState<ISummationBase[]>([]);

	const { t } = useTranslation(
		'common',
		{
			keyPrefix: 'abiAggregateRows'
		}
	);

	const sumRecordsByYear = (
		records: IABIComputedUnderCanadianRulesRow[][],
		netIncomes: IABIComputedUnderCanadianRulesRow[]
	): ISummationBase[] =>
	{
		const sumsByYear: ISummationBase[] = [];

		if (!!records)
		{
			// iterate each and sum all records based on array position
			// index in row records represents years ordered
			for (const row of records)
			{
				if (!!row.length)
				{
					for (let i = 0; i < row.length; i++)
					{
						if ((sumsByYear.length - 1) < i)
						{
							sumsByYear.push({
								...summationBase
							});
						}

						const amount: number = !!Utils.isValidNumber(`${row[i].amount}`) ?
							row[i].amount! :
							0;


						sumsByYear[i][ABIComputedUnderCanadianRulesTypes.TotalAdjustments] =
						Utils.areAllNullOrUndefined(row[i].amount,sumsByYear[i][ABIComputedUnderCanadianRulesTypes.TotalAdjustments]) ?
							undefined:
							Utils.add(amount,sumsByYear[i][ABIComputedUnderCanadianRulesTypes.TotalAdjustments]);
					}
				}
			}

			// sum for net income after adjustments has been calculated
			for (let i = 0; i < netIncomes.length; i++)
			{
				if ((sumsByYear.length - 1) < i)
				{
					sumsByYear.push({
						...summationBase
					});
				}

				const netIncomeAmount: number = !!Utils.isValidNumber(`${netIncomes[i].amount}`) ?
							netIncomes[i].amount! :
					0;
				const adjustment: number = !!Utils.isValidNumber(`${sumsByYear[i][ABIComputedUnderCanadianRulesTypes.TotalAdjustments]}`) ?
					sumsByYear[i][ABIComputedUnderCanadianRulesTypes.TotalAdjustments]! :
					0;

				sumsByYear[i][ABIComputedUnderCanadianRulesTypes.IncomeLoss] =
				Utils.areAllNullOrUndefined(sumsByYear[i][ABIComputedUnderCanadianRulesTypes.IncomeLoss],netIncomes[i].amount)?
					undefined : Utils.add(adjustment, netIncomeAmount, sumsByYear[i][ABIComputedUnderCanadianRulesTypes.IncomeLoss]);
			}
		}
		else
		{
			const years = !!props.allYearData.length ?
				props.allYearData.map((d) => d.taxYearEnd) :
				[];

			if (!!years && !!years.length)
			{
				// create dummy data for each year to render 0 for all values as there is no data
				years.forEach((y) =>
				{
					sumsByYear.push({
						...summationBase
					});
				});
			}
		}

		return sumsByYear;
	};

	useEffect(
		() =>
		{
			if (
				!!props.permanent &&
				!!props.temporary
			)
			{
				const sumsByYear: ISummationBase[] = sumRecordsByYear(
					props.permanent.concat(props.temporary),
					props.netIncome
				);

				setSummationByYear(sumsByYear);
			}
		},
		[
			props.permanent,
			props.temporary,
			props.netIncome
		]
	);

	return <div
		ref={props.customRef}
		className={'abi-aggregate-rows table-scroll-container'}
		style={{
			visibility: props.isLoading ? 'hidden' : 'visible'
		}}
	>
		<table id={props.id}>
			<tbody>
				<tr>
					<td className={'action-column'} />
					<td className={'first-column'}>
						{
							t('totalAdjustments')
						}
					</td>
					{
						!!summationByYear &&
						summationByYear
							.map((s) =>
							{
								return <>
									<td className={`content-column ${!!props.displayNotes ? 'has-notes' : ''}`}>
										{
											s[ABIComputedUnderCanadianRulesTypes.TotalAdjustments] === undefined ? '':
												Utils.formatCurrency(`${s[ABIComputedUnderCanadianRulesTypes.TotalAdjustments]}`, 2)
										}
									</td>
								</>;
							})
					}
				</tr>
				<tr>
					<td className={'action-column'} />
					<td className={'first-column'}>
						{
							t('incomeLossComputedUnderCanadianRules')
						}
					</td>
					{
						!!summationByYear &&
						summationByYear
							.map((s) =>
							{
								return <>
									<td className={`content-column ${!!props.displayNotes ? 'has-notes' : ''}`}>
										{
											s[ABIComputedUnderCanadianRulesTypes.IncomeLoss] === undefined ? '':
												Utils.formatCurrency(`${s[ABIComputedUnderCanadianRulesTypes.IncomeLoss]}`, 2)
										}
									</td>
								</>;
							})
					}
				</tr>
			</tbody>
		</table>
	</div>;
}