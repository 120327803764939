import React, { useEffect, useState, useContext } from 'react';
import {
	Button,
	Navigation,
	NavigationItem
} from '@appkit4/react-components';
import './FAInputSidebar.scss';
import { useTranslation } from 'react-i18next';
import { InputEnum } from 'src/common/types/enums/InputEnum';
import { IInputNavigationItem } from 'src/common/types/interfaces/INavigationItem';
import { CalculationTypesEnum } from 'src/common/types/enums/CalculationTypes';
import { useAppSelector } from 'src/store/store';
import { useNavigate, useParams, useBlocker } from 'react-router-dom';
import AffiliateContext from 'src/common/components/SharedDataContext/AffiliateContext';
import { UnsavedModal } from 'src/common/components/UnsaveModal/UnsavedModal';

export const FAInputSidebar = (props: {
  onSelect: (inputId: InputEnum) => void;
  inputId: string | undefined;
  hasChange: boolean;
  handleChange: (change: boolean) => void;
}) =>
{
	const navigate = useNavigate();
	const {engagementId, affiliateId } = useParams();
	const { t } = useTranslation('homeDashboard');

	const [dynamicNavList, setDynamicNavList] = useState<IInputNavigationItem[]>([]);

	const affiliateContext = useContext(AffiliateContext);

	const calculationTypes = useAppSelector((state) => state.appSettings.calculationTypes);

	const faCalculationTypeIds = affiliateContext?.affiliateDetail.calculationTypeIds;

	const [collapsed, setCollapsed] = React.useState(false);
	const [selectedKey, setSelectedKey] = React.useState('0');
	const [tempSelectedKey, setTempSelectedKey] = React.useState('0');

	const blocker = useBlocker(
		({ currentLocation, nextLocation }) => !!props.hasChange && currentLocation.pathname !== nextLocation.pathname
	  );

	const confirmNavigation = () =>
	{
		!!blocker.proceed && blocker.proceed();
		props.handleChange(false);
		setSelectedKey(tempSelectedKey);
	};

	const cancelNavigation = () =>
	{
		!!blocker.reset && blocker.reset();
	};

	const navigateToInput = (selectedInputId: string) =>
	{
		navigate(`/engagement/${engagementId}/affiliate/${affiliateId}/input/${selectedInputId}`);
	};

	const onCollapseEvent = (
		collapsed: boolean	) =>
	{
		setCollapsed(collapsed);
	};

	const getSelectedInputId = (inputKey: string) =>
	{
		const ids = inputKey.split('-');

		if (!!ids && ids.length > 1)
		{
			return dynamicNavList[Number(ids[0])]?.children![Number(ids[1])]?.id;
		}
		else
		{
			return dynamicNavList[Number(ids[0])]?.id;
		}
	};

	const handleItemClick = (event: any, item: NavigationItem, key: string) =>
	{
		if (!props.hasChange)
		{
			setSelectedKey(key);
		}
		setTempSelectedKey(key);
		const selectedInputId = getSelectedInputId(key);
		if (selectedInputId)
		{
			navigateToInput(selectedInputId);
		}
	};

	const navList = (calculationTypes: string[]): IInputNavigationItem[] => [
		{
			name: t('inputs-sidebar.netEarning'),
			id: InputEnum.Earnings,
			isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.Surplus || c === CalculationTypesEnum.EnhancedSurplus),
			suffixIcon: 'down-chevron',
			children: [
				{
					name: t('inputs-sidebar.netEarning'),
					id: InputEnum.Earnings,
					isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.EnhancedSurplus ||
						c === CalculationTypesEnum.FAPI )
				},
				{
					name: t('inputs-sidebar.bookForeignTaxableIncome'),
					id: InputEnum.BookToForeignTaxableIncome,
					isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.EnhancedSurplus)
				},
				{
					name: t('inputs-sidebar.regAdjustments'),
					id: InputEnum.Reg5907Adjustments,
					isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.EnhancedSurplus)
				},
				{
					name: t('inputs-sidebar.activeBICUnderCanadianRules'),
					id: InputEnum.ActiveBusinessIncomeUnderCanadianRules,
					isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.EnhancedSurplus)
				},
				{
					name: calculationTypes?.includes(CalculationTypesEnum.FAPI) ?  t('inputs-sidebar.incomeFromProperty') :
						t('inputs-sidebar.incomeFromPropertyOptional'),
					id: InputEnum.IncomeFromProperty,
					isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.EnhancedSurplus) ||
					(calculationTypes?.length > 1 && calculationTypes?.some((c) => c === CalculationTypesEnum.FAPI))
				}
			]
		},
		{
			name: t('inputs-sidebar.incomeTaxPaid'),
			id: InputEnum.IncomeTaxesPaid,
			isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.Surplus || c === CalculationTypesEnum.EnhancedSurplus ||
			c === CalculationTypesEnum.FAPI)
		},
		{
			name: t('inputs-sidebar.dividendsReceived'),
			id: InputEnum.DividendsReceived,
			isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.Surplus || c === CalculationTypesEnum.EnhancedSurplus)
		},
		{
			name: t('inputs-sidebar.dividendsPaid'),
			id: InputEnum.DividendsPaid,
			isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.Surplus || c === CalculationTypesEnum.EnhancedSurplus)
		},
		{
			name: t('inputs-sidebar.capitalGains&Losses'),
			id: InputEnum.CapitalGains,
			isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.Surplus || c === CalculationTypesEnum.EnhancedSurplus ||
			c === CalculationTypesEnum.FAPI)
		},
		{
			name: t('inputs-sidebar.otherAdjustmentsToSurplus'),
			id: InputEnum.OtherAdjustments,
			isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.Surplus || c === CalculationTypesEnum.EnhancedSurplus)
		},
		{
			name: t('inputs-sidebar.surplusEntitlementPercentage'),
			id: InputEnum.SurplusEntitlementPercentageAdjustments,
			isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.Surplus || c === CalculationTypesEnum.EnhancedSurplus)
		},
		{
			name: t('inputs-sidebar.incomeFromProperty'),
			id: InputEnum.IncomeFromProperty,
			isVisible: (calculationTypes?.length === 1 && calculationTypes?.some((c) => c === CalculationTypesEnum.FAPI)) ||
			(calculationTypes?.length === 2 &&
				calculationTypes?.includes(CalculationTypesEnum.FAPI) && calculationTypes?.includes(CalculationTypesEnum.ACB))
		},
		{
			name: t('inputs-sidebar.adjustedCostBase'),
			id: InputEnum.AdjustedCostBase,
			isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.ACB)
		},
		{
			name: t('inputs-sidebar.retainedEarnings'),
			id: InputEnum.RetainedEarnings,
			isVisible: calculationTypes?.some((c) => c === CalculationTypesEnum.EnhancedSurplus)
		}
	];

	const filterVisibleNavItems = (navList: IInputNavigationItem[]): IInputNavigationItem[] =>
	{
		return navList.reduce<IInputNavigationItem[]>((acc, currentItem) =>
		{
			if (currentItem.isVisible)
			{
				const newItem: IInputNavigationItem = {
					...currentItem
				};
				if (newItem.children)
				{
					newItem.children = filterVisibleNavItems(newItem.children);
				}
				acc.push(newItem);
			}
			return acc;
		}, []);
	};

	const findKeyByInputId = (navList: any, id: string, parentIndex: string = ''): string | null =>
	{
		for (let i = 0; i < navList.length; i++)
		{
			const item = navList[i];
			if (item.id === id)
			{
				return !!parentIndex ? `${parentIndex}-${i}` : `${i}`;
			}
			if (!!item.children && item.children.length > 0)
			{
				const result = findKeyByInputId(item.children, id, !!parentIndex ? `${parentIndex}-${i}` : `${i}`);
				if (!!result)
				{
					return result;
				}
			}
		}
		return null;
	};

	const setSelectedInput = (key: string , id: string) =>
	{
		setSelectedKey(key);
		navigateToInput(id as InputEnum);
		props.onSelect(id as InputEnum);
	};

	useEffect(() =>
	{
		var elems: any = document.querySelector('.ap-navigation-content');
		var anchorElems: any = document.querySelector(
			'.collapse-menu-button .collapse-anchor'
		);
		var navHeadingElems: any = document.querySelector('.ap-navigation-heading');
		var navbarWidth: any = document.querySelector(
			'.fa-input-sidebar .input-sidebar'
		);
		if (!collapsed)
		{
			elems.style.visibility = 'initial';
			anchorElems.style.flexDirection = 'row';
			anchorElems.style.width = '220px';
			navHeadingElems.style.marginLeft = '-7px';
			navbarWidth.style.width = '260px';
		}
		else
		{
			elems.style.visibility = 'hidden';
			anchorElems.style.flexDirection = 'column';
			anchorElems.style.width = 'fit-content';
			navHeadingElems.style.marginLeft = '7px';
			navbarWidth.style.width = 'fit-content !important';
		}
	}, [collapsed]);

	useEffect(() =>
	{
		if (!!faCalculationTypeIds && faCalculationTypeIds.length > 0 &&
				!!calculationTypes && calculationTypes.length > 0)
		{
			const calculationTypesFiltered = calculationTypes?.filter((r: Record<string, any>) =>
				faCalculationTypeIds?.includes(r.id)).map((type: Record<string, any>) => type.internalName);

			if (!!calculationTypesFiltered && calculationTypesFiltered.length > 0)
			{
				let upadtedNavList = navList(calculationTypesFiltered);

				if (!!calculationTypesFiltered &&
					((calculationTypesFiltered?.length === 1 && calculationTypesFiltered?.includes(CalculationTypesEnum.Surplus)) ||
					(calculationTypesFiltered?.length === 2 && calculationTypesFiltered?.includes(CalculationTypesEnum.Surplus) &&
					calculationTypesFiltered?.includes(CalculationTypesEnum.ACB))))
				{
					upadtedNavList = upadtedNavList.map((navItem: IInputNavigationItem) =>
					{
						if (navItem.name === t('inputs-sidebar.netEarning') && navItem.children)
						{
							const {suffixIcon, children, ...newItem} = navItem;
							return newItem;
						}
						return navItem;
					});
				}

				const filteredNavList = filterVisibleNavItems(upadtedNavList);
				setDynamicNavList(() => filteredNavList);

				const inputKey = !!props.inputId ? findKeyByInputId(filteredNavList, props.inputId as InputEnum) : undefined;

				if (!!props.inputId && !!inputKey)
				{
					setSelectedInput(inputKey, props.inputId);
				}
				else
				{
					const firstNavItem: IInputNavigationItem = filteredNavList[0];
					if (!!firstNavItem?.children && !!firstNavItem?.children![0] && !!firstNavItem?.children![0]?.id)
					{
						setSelectedInput('0-0', firstNavItem?.children![0]?.id);
					}
					else
					{
						setSelectedInput('0', firstNavItem?.id);
					}
				}
			}
		}
		else if (!!faCalculationTypeIds && faCalculationTypeIds.length === 0)
		{
			navigate('/');
		}
	},[faCalculationTypeIds, calculationTypes]);

	return (<>
		{
			!!dynamicNavList &&
			<div className="fa-input-sidebar">
				<Navigation
					className="input-sidebar"
					navList={dynamicNavList}
					hasHeader={true}
					hasFooter={false}
					hasLogo={false}
					selectedKey={selectedKey}
					onItemClick={handleItemClick}
					openKeys={['0']}
					defaultCollapsed={false}
					collapsed={collapsed}
					onClickCollapseEvent={onCollapseEvent}
					solid={true}
					userTemplate={() => (
						<div className="collapse-menu-button">
							{
								<Button className='link-button ' kind='text' onClick={() => setCollapsed(!collapsed)}>
									<div className='collapse-anchor'>
											 	<div
											className={`Appkit4-icon ${
												collapsed ?
													'icon-enter-right-outline' :
													'icon-enter-left-outline'
											}`}
										></div>
										<div>
											{collapsed ?
												t('inputs-sidebar.menu') :
												t('inputs-sidebar.collapseMenu')}
										</div>
									</div>
								</Button>
							}
						</div>
					)}
				></Navigation>
			</div>
		}
		<UnsavedModal
			showDialog={!!blocker && blocker.state === 'blocked'}
			cancelNavigation={cancelNavigation}
			confirmNavigation={confirmNavigation }
		/>
	</>

	);
};
