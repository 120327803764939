import { Select } from '@appkit4/react-components';
import { ITaxYearEndFilterProps } from './ITaxYearEndFilterProps';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IFAHistoryBase, IFAHistoryLookup } from 'src/features/engagement/models/IFAHistory';
import moment from 'moment';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import { NotificationService } from 'src/core/services/notificationService';

export function TaxYearEndFilter(props: ITaxYearEndFilterProps): JSX.Element
{
	const { t } = useTranslation(
		'common',
		{
			keyPrefix: 'taxYearEndFilter'
		}
	);

	const [isLoading, setIsLoading] = useState(true);
	const [faOptions, setFAOptions] = useState<IFAHistoryLookup[]>([]);

	const loadFAHistoryOptions = async (
		faHistory: IFAHistoryBase[]
	): Promise<void> =>
	{
		try
		{
			if (
				!!faHistory &&
				!!faHistory.length
			)
			{
				const relevantFAHistory = faHistory
					.filter((h) => !props.onlyRelevantResults || (!!props.onlyRelevantResults && !!h.isRelevant));

				const historyLookup: IFAHistoryLookup[] = relevantFAHistory
					.filter((h) => !props.onlyRelevantResults || (!!props.onlyRelevantResults && !!h.isRelevant))
					.map((h, index) =>
					{
						const currentDate = dateFormatter.utcToEst(h.taxYearEnd);

						const isSameDay = relevantFAHistory.some((fa) =>
						{
							const otherDate = moment(
								dateFormatter.utcToEst(fa.taxYearEnd)
							);

							return fa.id !== h.id &&
								otherDate.isSame(moment(currentDate), 'day');
						});

						return {
							...h,
							toDate: currentDate,
							fromDate: index > 0 ?
								moment(dateFormatter.utcToEst(relevantFAHistory[index - 1].taxYearEnd))
									.add(1, 'minutes')
									.toDate() :
								undefined,
							friendlyName: !!isSameDay ?
								dateFormatter.format(currentDate, 'MMM DD, YYYY (hh:mm A)') :
								dateFormatter.format(currentDate, 'MMM DD, YYYY')
						};
					});

				setFAOptions([
					{
						countryId: 0,
						currencyId: 0,
						friendlyName: t('allOptionText'),
						id: 0,
						taxYearEnd: new Date()
					},
					...historyLookup
				]);
			}
			else
			{
				setFAOptions([]);
			}
		}
		catch (error)
		{
			NotificationService
				.error({
					message: t('faHistoryError')
				});
		}
		finally
		{
			setIsLoading(false);
		}
	};

	const onFilter = (value: number) =>
	{
		if (!value)
		{
			props.onFilter({
				fromDate: undefined,
				toDate: undefined
			});
		}
		else
		{
			const historyItem = faOptions.find((f) => f.id === value);

			if (!!historyItem)
			{
				props.onFilter({
					fromDate: historyItem.fromDate,
					toDate: historyItem.toDate
				});
			}
		}
	};

	useEffect(
		() =>
		{
			setIsLoading(true);

			if (
				!!props.faHistory &&
				!!props.faHistory.length
			)
			{
				loadFAHistoryOptions(
					[...props.faHistory]
				);
			}
			else
			{
				setFAOptions([]);
			}
		},
		[
			props.faHistory
		]
	);

	return <>
		<div>
			<Select
				labelKey={'friendlyName'}
				valueKey={'id'}
				placeholder={t('filterPlaceholder') || ''}
				disabled={
					!!isLoading ||
					!!props.isDisabled
				}
				defaultValue={0}
				data={faOptions}
				onSelect={(value) =>
				{
					onFilter(+value);
				}}
			/>
		</div>
	</>;
}