export enum AdjustmentFromAllFields {
	id='id',
	engagementId='engagementId',
	affiliateId='affiliateId',
	adjustmentDate='adjustmentDate',
	amount='amount',
	currencyId='currencyId',
	notes='notes',
	surplusPoolId='surplusPoolId',
	descriptionId='descriptionId',
	taxpayerId='taxpayerId'
}