import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@appkit4/react-components/esm/button';

import './LoginPage.scss';
import { IAuthContext } from 'src/features/authentication/Types';
import { AuthContext } from 'src/features/authentication/AuthContext';

import pwcLogo from '../../../assets/images/PwC_Outline_Logo_White.png';
import { useTranslation } from 'react-i18next';

const LoginPage = () =>
{
	const { t } = useTranslation('ns1');
	const navigate = useNavigate();
	const { idToken, login }: IAuthContext = useContext(AuthContext);

	useEffect(() =>
	{
		if (idToken)
		{
			navigate('/');
		}
	}, [idToken, navigate]);

	return (
		<div className="login-page">
			<div className="login-control">
				<div>
					<img className="pwcLogo" src={pwcLogo} alt="Powered By PwC"></img>
				</div>
				<div className="title">{t('login.title')}</div>
				<div>
					<Button kind="secondary" onClick={() => login()}>
						<span className="login-button">{t('login.button')}</span>
					</Button>
				</div>
			</div>
		</div>
	);
};

export default LoginPage;
