import { RecordsByRowKey } from 'src/common/types/interfaces/IInputRecord';
import { IRowInformation } from 'src/common/types/interfaces/IRowInformation';

export enum SaveButtonLocations {

	Top = 'top',
	Bottom = 'bottom' ,
	TopBottom = 'top-bottom'
}

export interface IColumnarGridProps<T> {

	isLoading: boolean;
	isDisabled?: boolean;
	hasNotesToggle?: boolean;
	hasHeaderNotes?: boolean;
	hasActionBar?: boolean;
	onHeaderNoteSave?: (updatedNote: string, data?: T) => void;
	notes?: string;

	title: string;
	columnIndexKeyName: string;

	rowInformationHeaderText?: string;
	rowInformation: IRowInformation[];
	data: RecordsByRowKey<T>;

	addNewMessage: string;
	addNewButtonText: string;
	highlightedOnField?: string;
	infoMessage?: string;

	onRenderFilters?: () => JSX.Element;
	onToggleNotes?: (displayNotes: boolean) => void;

	onOpenNew?: () => void;
	onEdit?: (entity: T) => void;

	saveButtonLocation?: SaveButtonLocations;
	onGridSave?: () => void;
	onInputChanged?: (id: string, field: string, value: string) => void;
}