import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox } from '@appkit4/react-components';

import './TermsOfService.scss';
import Redstar from 'src/common/components/Redstar';

const TermsOfService = (props: {
  termsOfService: boolean;
  privacyPolicy: boolean;
  onContinue: any;
}) =>
{
	const { t } = useTranslation('ns1');
	const [termsOfService, setTermsOfService] = useState<boolean>(false);
	const [privacyPolicy, setPrivacyPolicy] = useState<boolean>(false);

	useEffect(() =>
	{
		setTermsOfService(props.privacyPolicy);
		setPrivacyPolicy(props.privacyPolicy);
	}, []);
	const changeTermsOfService = (value: any) =>
	{
		setTermsOfService(value);
	};

	const changePrivacyPolicy = (value: any) =>
	{
		setPrivacyPolicy(value);
	};

	return (
		<div className="terms-of-service">
			<div className="title">{t('terms_of_services.title')}</div>
			<div className="description">
				<div>{t('terms_of_services.description.p1')}</div>
				<div>{t('terms_of_services.description.p2')}</div>
				<div>{t('terms_of_services.description.p3')}</div>
				<div>{t('terms_of_services.description.p4')}</div>
				<div>{t('terms_of_services.description.p5')}</div>
				<div>{t('terms_of_services.description.p6')}</div>
				<div>
					<ul>
						<li>{t('terms_of_services.description.p6-1')}</li>
						<li>{t('terms_of_services.description.p6-2')}</li>
						<li>{t('terms_of_services.description.p6-3')}</li>
						<li>{t('terms_of_services.description.p6-4')}</li>
					</ul>
				</div>

				<div>{t('terms_of_services.description.p7')}</div>
			</div>
			<div className="control">
				<div className="checkboxes">
					<div className="checkbox">
						<Checkbox
							checked={termsOfService}
							onChange={changeTermsOfService}
						></Checkbox>
						<span className="item">
							{t('terms_of_services.checkbox_terms')}
						</span>
						<Redstar></Redstar>
					</div>
					<div className="checkbox">
						<Checkbox
							checked={privacyPolicy}
							onChange={changePrivacyPolicy}
						></Checkbox>
						<span className="item">
							{t('terms_of_services.checkbox_policy.text')}
							<a
								href="https://www.pwc.com/ca/en/privacy-policy.html"
								target="blank"
							>
								{t('terms_of_services.checkbox_policy.link')}
							</a>{' '}
							<Redstar></Redstar>
						</span>
					</div>
				</div>
				<div className="button">
					<Button
						className="continue"
						kind="primary"
						onClick={props.onContinue}
						disabled={!(termsOfService && privacyPolicy)}
					>
						<span className="buttonText">
							{t('terms_of_services.button_continue')}
						</span>
					</Button>
				</div>
			</div>
		</div>
	);
};

export default TermsOfService;
