import { TFunction } from 'i18next';
import { CapitalGainsDispositionFields } from 'src/common/types/enums/CapitalGainsDispositionFields';
import { ILineItem } from 'src/common/types/interfaces/ILineItem';
import { IRowInformation } from 'src/common/types/interfaces/IRowInformation';
import { inputRecordHelper } from 'src/common/utils/inputRecordHelper';

export const CapitalGainsDispositionHelper = {

	getRowInformation: (
		translationService: TFunction,
		lineItems?: ILineItem[]
	): IRowInformation[] =>
	{
		const lineItemsByKey = inputRecordHelper.lineItemsByKey(lineItems);

		return [
			{
				key: CapitalGainsDispositionFields.type,
				name: translationService(CapitalGainsDispositionFields.type),
				lineItemConfiguration: lineItemsByKey[CapitalGainsDispositionFields.type]
			},
			{
				key: CapitalGainsDispositionFields.description,
				name: translationService(CapitalGainsDispositionFields.description),
				lineItemConfiguration: lineItemsByKey[CapitalGainsDispositionFields.description]
			},
			{
				key: CapitalGainsDispositionFields.isDepreciableProperty,
				name: translationService(CapitalGainsDispositionFields.isDepreciableProperty),
				lineItemConfiguration: lineItemsByKey[CapitalGainsDispositionFields.isDepreciableProperty]
			},
			{
				key: CapitalGainsDispositionFields.dateOfAcquisition,
				name: translationService(CapitalGainsDispositionFields.dateOfAcquisition),
				lineItemConfiguration: lineItemsByKey[CapitalGainsDispositionFields.dateOfAcquisition]
			},
			{
				key: CapitalGainsDispositionFields.dateOfDisposition,
				name: translationService(CapitalGainsDispositionFields.dateOfDisposition),
				lineItemConfiguration: lineItemsByKey[CapitalGainsDispositionFields.dateOfDisposition]
			},
			{
				key: CapitalGainsDispositionFields.transactionCurrencyId,
				name: translationService(CapitalGainsDispositionFields.transactionCurrencyId),
				lineItemConfiguration: lineItemsByKey[CapitalGainsDispositionFields.transactionCurrencyId]
			},
			{
				key: CapitalGainsDispositionFields.proceedsOfDisposition,
				name: translationService(CapitalGainsDispositionFields.proceedsOfDisposition),
				lineItemConfiguration: lineItemsByKey[CapitalGainsDispositionFields.proceedsOfDisposition]
			},
			{
				key: CapitalGainsDispositionFields.electionAmount,
				name: translationService(CapitalGainsDispositionFields.electionAmount),
				lineItemConfiguration: lineItemsByKey[CapitalGainsDispositionFields.electionAmount]
			},
			{
				key: CapitalGainsDispositionFields.cost,
				name: translationService(CapitalGainsDispositionFields.cost),
				lineItemConfiguration: lineItemsByKey[CapitalGainsDispositionFields.cost],
				tooltip: inputRecordHelper.getTooltip(translationService, CapitalGainsDispositionFields.cost)!
			},
			{
				key: CapitalGainsDispositionFields.expenses,
				name: translationService(CapitalGainsDispositionFields.expenses),
				lineItemConfiguration: lineItemsByKey[CapitalGainsDispositionFields.expenses]
			},
			{
				key: CapitalGainsDispositionFields.otherAdjustments,
				name: translationService(CapitalGainsDispositionFields.otherAdjustments),
				lineItemConfiguration: lineItemsByKey[CapitalGainsDispositionFields.otherAdjustments],
				tooltip: inputRecordHelper.getTooltip(translationService, CapitalGainsDispositionFields.otherAdjustments)!
			},
			{
				key: CapitalGainsDispositionFields.preAcquisitionAmount,
				name: translationService(CapitalGainsDispositionFields.preAcquisitionAmount),
				lineItemConfiguration: lineItemsByKey[CapitalGainsDispositionFields.preAcquisitionAmount]
			},
			{
				key: CapitalGainsDispositionFields.calculatedCapitalGainOrLoss,
				name: translationService(CapitalGainsDispositionFields.calculatedCapitalGainOrLoss),
				lineItemConfiguration: lineItemsByKey[CapitalGainsDispositionFields.calculatedCapitalGainOrLoss],
				isBold: true
			},
			{
				key: CapitalGainsDispositionFields.taxPaidOnDisposition,
				name: translationService(CapitalGainsDispositionFields.taxPaidOnDisposition),
				lineItemConfiguration: lineItemsByKey[CapitalGainsDispositionFields.taxPaidOnDisposition]
			},
			{
				key: CapitalGainsDispositionFields.notes,
				name: translationService(CapitalGainsDispositionFields.notes),
				lineItemConfiguration: lineItemsByKey[CapitalGainsDispositionFields.notes]
			}
		];
	}
};