import { engagementService } from 'src/features/engagement/services/engagementService';
import { InputFieldOptionKeys } from '../types/enums/InputFieldOptionKeys';
import { LineItemTypeEnum } from '../types/enums/LineItemTypeEnum';
import { AdjustmentFromAllFields } from 'src/common/types/enums/AdjustmentFromAllFields';
import { IInputFieldRecord, RecordsByRowKey } from '../types/interfaces/IInputRecord';
import { INameId } from '../types/interfaces/INameId';
import { IOption } from '../types/interfaces/IOption';
import { IRowInformation } from '../types/interfaces/IRowInformation';
import { dateFormatter } from './dateFormatter';
import { Utils } from './utils';
import { ILineItem } from '../types/interfaces/ILineItem';
import { TFunction } from 'i18next';

import store from 'src/store/store';

export const inputRecordHelper = {

	getStartRowKeysAsDictionary: <T>(
		rows: IRowInformation[]
	): RecordsByRowKey<T> =>
	{
		const startKeys: RecordsByRowKey<T> = {
		};

		rows.forEach((r) =>
		{
			startKeys[r.key] = [];
		});

		return startKeys;
	},

	getRowData: <T extends { [key: string]: any }>(
		allRecords: T[],
		rows: IRowInformation[]
	): RecordsByRowKey<T> =>
	{
		const preppedData = inputRecordHelper
			.getStartRowKeysAsDictionary<T>(rows);

		if (
			!!allRecords &&
			!!allRecords.length
		)
		{
			const allKeys = Object.keys(preppedData);

			const recordsByKeys = inputRecordHelper
				.transformRecordsByKeys(allRecords, allKeys);

			for (const record of recordsByKeys)
			{
				for (const key of allKeys)
				{
					preppedData[key].push(record[key]);
				}
			}
		}

		return preppedData;
	},

	transformRecordByKey: <T extends Record<string, T>>(
		inputRecord: T,
		allKeys: string[]
	): { [key: string]: IInputFieldRecord<T> } | undefined =>
	{
		const lineItemRecord: { [key: string]: IInputFieldRecord<T> } = {

		};

		for (const key of allKeys)
		{
			const item = inputRecord[key];

			if (!!item || !!Utils.isFalsyValue(item))
			{
				lineItemRecord[key] = {
					field: key,
					value: item,
					data: inputRecord
				};
			}
			else
			{
				lineItemRecord[key] = {
					field: key,
					value: '',
					note: '',
					data: inputRecord
				};
			}
		}

		if (
			!!lineItemRecord &&
			!!Object.keys(lineItemRecord).length
		)
		{
			return lineItemRecord;
		}

		return undefined;
	},

	transformRecordsByKeys: <T extends { [key: string]: any }>(
		inputRecords: T[],
		allKeys: string[]
	): { [key: string]: IInputFieldRecord<T> }[] =>
	{
		const records: { [key: string]: IInputFieldRecord<T> }[] = [];

		if (
			!!inputRecords &&
			!!inputRecords.length
		)
		{
			for (const record of inputRecords)
			{
				const updatedRecord = inputRecordHelper
					.transformRecordByKey(record, allKeys);

				if (!!updatedRecord)
				{
					records.push(updatedRecord);
				}
			}
		}

		return records;
	},

	lineItemsByKey: (
		lineItems?: ILineItem[]
	): { [key: string]: ILineItem } =>
	{
		const itemsByKey: { [key: string]: ILineItem } = {
		};

		if (
			!!lineItems &&
			!!lineItems.length
		)
		{
			for (const item of lineItems)
			{
				itemsByKey[item.field] = item;
			}
		}

		return itemsByKey;
	},

	initializeLineItems: async (
		category: string,
		engagementId: number,
		affiliateId: number
	): Promise<ILineItem[]> =>
	{
		const lines: ILineItem[] = [];

		const inputForm = store.getState().appSettings.inputForms
			.find((c: any) => c.category === category);

		if (
			!!inputForm &&
			!!inputForm.fields &&
			!!inputForm.fields.length
		)
		{
			for (const item of inputForm.fields)
			{
				lines.push({
					...item,
					field: Utils.pascalToCamel(item.field),
					error: '',
					defaultErrorMessage: item.error,
					type: item.type as LineItemTypeEnum,
					inline: item.inline,
					options: await inputRecordHelper
						.getLineItemOptions(
							item.options,
							engagementId,
							affiliateId
						)
				});
			}
		}

		return Promise.resolve(lines);
	},

	getLineItemOptions: async (
		optionKey: InputFieldOptionKeys,
		engagementId: number,
		affiliateId: number
	): Promise<IOption[]> =>
	{
		// write the explicit rules for mapping options to fields
		if (!!optionKey)
		{
			const options = store.getState().appSettings.options;

			if (optionKey === InputFieldOptionKeys.EquityClasses)
			{
				const response = await engagementService
					.getDividendsPaidEquityClasses({
						engagementId,
						affiliateId
					});

				if (
					!!response.data &&
					!!response.data.result &&
					!!response.data.result.length
				)
				{
					return (response.data.result as INameId[])
						.map((l) =>
						{
							return {
								field: optionKey,
								label: l.name,
								value: l.id
							};
						});
				}
			}
			else if (optionKey === InputFieldOptionKeys.AllEquityClasses)
			{
				return store.getState().appSettings.allEquityClasses
					.map((c) =>
					{
						return {
							field: optionKey,
							value: c.id,
							label: c.name
						};
					});
			}
			else if (optionKey === InputFieldOptionKeys.Currencies)
			{
				return store.getState().appSettings.currencies
					.map((c) =>
					{
						return {
							field: optionKey,
							value: c.id,
							label: c.code
						};
					});
			}
			else if (optionKey === InputFieldOptionKeys.Countries)
			{
				return store.getState().appSettings.countries
					.map((c) =>
					{
						return {
							field: optionKey,
							value: c.id,
							label: c.name
						};
					});
			}
			else if (optionKey === InputFieldOptionKeys.SurplusPool)
			{
				return store.getState().appSettings.surplusPool
					.map((c) =>
					{
						return {
							field: optionKey,
							value: c.id,
							label: c.name
						};
					});
			}
			else if (optionKey === InputFieldOptionKeys.SurplusDescriptions)
			{
				const response = await engagementService
					.getSurplusDescriptionsMasterData();

				if (
					!!response.data &&
						!!response.data.result &&
						!!response.data.result.length
				)
				{
					return (response.data.result)
						.map((surplusPool: any) =>
						{
							return !!surplusPool.surplusDescriptionDetails ?
								surplusPool.surplusDescriptionDetails.map((detail: any) =>
								{
									return {
										field: optionKey,
										value: detail.id,
										label: detail.name,
										internalName: detail.internalName,
										surplusPoolId: surplusPool.surplusPoolId
									};
								}) : [];
						});
				}
			}
			else if (optionKey === InputFieldOptionKeys.TypeOfAdditionDeduction)
			{
				const response = await engagementService.getAdjustedCostBaseMasterData();

				if (
					!!response.data &&
							!!response.data.result &&
							!!response.data.result.length
				)
				{
					return (response.data.result)
						.map((transactionType: any) =>
						{
							return {
								field: optionKey,
								value: transactionType.id,
								label: transactionType.name,
								internalName: transactionType.internalName,
								isAddition: transactionType.isAddition
							};
						});
				}
			}
			else
			{
				return options
					.filter((c: IOption) =>
					{
						return c.field.toLowerCase() === optionKey.toLowerCase();
					});
			}
		}
		return [];
	},

	formatField: <T>(
		rowInformation: IRowInformation,
		record: IInputFieldRecord<T>
	): string =>
	{
		const lineConfiguration = rowInformation.lineItemConfiguration;

		if (!lineConfiguration)
		{
			return '';
		}

		if (lineConfiguration.type === LineItemTypeEnum.datepicker)
		{
			if (!!record.value)
			{
				return dateFormatter.format(
					dateFormatter.utcToEst(record.value),
					'MMM DD, YYYY hh:mm A'
				);
			}
		}
		else if (lineConfiguration.type === LineItemTypeEnum.date)
		{
			if (!!record.value)
			{
				return dateFormatter.format(
					dateFormatter.utcToEst(record.value),
					'MMM DD, YYYY'
				);
			}
		}
		else if (lineConfiguration.type === LineItemTypeEnum.datetime)
		{
			if (!!record.value)
			{
				return dateFormatter.format(
					dateFormatter.utcToEst(record.value),
					'MMM DD, YYYY hh:mm A'
				);
			}
		}
		else if (lineConfiguration.type === LineItemTypeEnum.time)
		{
			if (!!record.value)
			{
				return dateFormatter.format(
					dateFormatter.utcToEst(record.value),
					'hh:mm A'
				);
			}
		}
		else if (lineConfiguration.type === LineItemTypeEnum.currency)
		{
			if (!!Utils.isValidNumber(record.value))
			{
				return Utils.formatCurrency(record.value, 2);
			}
		}
		else if (lineConfiguration.type === LineItemTypeEnum.decimal)
		{
			if (!!Utils.isValidNumber(record.value))
			{
				return parseFloat(record.value).toString();
			}
		}
		else if (lineConfiguration.type === LineItemTypeEnum.percentage)
		{
			if (!!Utils.isValidNumber(record.value))
			{
				return parseFloat(record.value).toString()+'%';
			}
		}
		else if (
			lineConfiguration.type === LineItemTypeEnum.text ||
			lineConfiguration.type === LineItemTypeEnum.textarea
		)
		{
			if (!!record.value)
			{
				return record.value;
			}
		}
		else if (
			lineConfiguration.type === LineItemTypeEnum.dropdown &&
			!!lineConfiguration.options &&
			!!lineConfiguration.options.length
		)
		{
			const item = lineConfiguration.options
				.find((o) => `${o.value}` === `${record.value}`);

			if (!!item && !!item.label)
			{
				return item.label;
			};
		}
		else if (
			lineConfiguration.type === LineItemTypeEnum.surplusDescriptionDropdown &&
			!!lineConfiguration.options && !!lineConfiguration.options.length &&
			!!record && !!record.data
		)
		{
			const data: any = record.data;
			const options: any =  lineConfiguration.options;
			const filterOptions = (options: any[]): any =>
			{
				return options?.reduce<any>((acc, option) =>
				{
					if (!!option.find((l: any) => l.surplusPoolId === data[AdjustmentFromAllFields.surplusPoolId]))
					{
						acc.push(...option);
					}
					return acc;
				}, []);
			};
			const filteredOptions = filterOptions(options);
			if (!!filteredOptions)
			{
				const item = filteredOptions
					.find((o: any) => `${o.value}` === `${record.value}`);

				if (!!item && !!item.label)
				{
					return item.label;
				};
			}
		}
		return '';
	},

	getTooltip: (
		translationService: TFunction,
		key: string
	): string | undefined =>
	{
		if (
			!!translationService &&
			!!key
		)
		{
			const tooltip = translationService(`${key}Tooltip`);

			if (!!tooltip)
			{
				return tooltip;
			}
		}

		return undefined;
	}
};