export enum LineItemTypeEnum {
    text='text',
    textarea='textarea',
    dropdown='dropdown',
    datepicker='datepicker',
    currency='currency',
	datetime='datetime',
	date='date',
	time='time',
	decimal='decimal',
	percentage='percentage',
	earningAmount='earningAmount',
	total='total',
	amountNotes='amountNotes',
	surplusDescriptionDropdown='surplusDescriptionDropdown'
}