import moment from 'moment';
import { IPropertyIncomeYear } from 'src/common/types/interfaces/IPropertyIncome';

export const PropertyIncomeHelpers = {

	/**
	 * Sort entities by date in descending order. Dates are in format of 'Sep 13, 2023'
	 * @param date1 entity for comparison
	 * @param date2 entity for comparison
	 */
	sortFAYear: (entity1: IPropertyIncomeYear, entity2: IPropertyIncomeYear): number =>
	{
		const date1 = moment(entity1.faYear);
		const date2 = moment(entity2.faYear);

		return date2.isBefore(date1) ? -1 : 1;
	}
};