import React, { useState } from 'react';

export const SortableHeaderCell = (props: any) =>
{
	const [sortDir] = useState<string>('');

	const onSort = (e: any) =>
	{
		props.onClick(e);
	};

	return (
		<React.Fragment>
			<span className="k-cell-inner">
				<span className="k-link link-on-hover" onClick={onSort}>
					<span className="k-column-title">{props.title}</span>
					<span className="k-sort-icon">
						{sortDir === '' && (
							<span className={'Appkit4-icon icon-sorting-outline'}></span>
						)}
						{sortDir === 'asc' && (
							<span
								className={'Appkit4-icon icon-sorting-ascendant-outline'}
							></span>
						)}
						{sortDir === 'desc' && (
							<span
								className={'Appkit4-icon icon-sorting-descendant-outline'}
							></span>
						)}
					</span>
				</span>
			</span>
		</React.Fragment>
	);
};
