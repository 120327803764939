import {
	Input,
	Select,
	TextArea
} from '@appkit4/react-components';
import React, { useEffect, useState } from 'react';
import { LineItemTypeEnum } from 'src/common/types/enums/LineItemTypeEnum';
import { SelectValue } from '@appkit4/react-components/esm/combobox/Combobox';
import { Utils } from 'src/common/utils/utils';
import { RegularPatternEnum } from 'src/common/types/enums/RegularPatternEnum';
import { IRowInformation } from 'src/common/types/interfaces/IRowInformation';
import { IInputFieldRecord } from 'src/common/types/interfaces/IInputRecord';
import { CalendarPickerWrapper } from 'src/common/components/CalendarPickerWrapper/CalendarPickerWrapper';

export function GridCellEditor<T>(props:
	{rowInformation: IRowInformation;
	data: IInputFieldRecord<T>;
	onChange?: (id: string, field: string, value: any) => void;}
): JSX.Element
{
	const [editing, setEditing] = useState<boolean>(false);
	const [cellValue, setCellValue] =useState<any>(undefined);

	useEffect(() =>
	{
		setCellValue(props.data.value);
	}, [props.data]);

	const handleFieldChange = (value: any, event: any) =>
	{
		if (props.rowInformation.lineItemConfiguration.type === LineItemTypeEnum.currency)
		{
			const regPattern = new RegExp(RegularPatternEnum.Currency);
			if (!regPattern.test(value))
			{
				return;
			}
		}
		setCellValue(value);
	    if (props.onChange)
		{
			props.onChange((props.data.data as any).id, props.data.field, value);
		}
	};

	const getNoDataTemplate = (): React.ReactNode =>
	{
		return <>No Data Available</>;
	};


	return (
		<div
			className={` ${
				props.rowInformation.lineItemConfiguration.error ? 'error' : ''
			} `}
		>
			{props.rowInformation.lineItemConfiguration.type === LineItemTypeEnum.text && (
				<Input
					type={'text'}
					className={`${
						props.rowInformation.lineItemConfiguration.editable ? '' : 'no-editable-input'
					}`}
					hideTitleOnInput= {true}
					title={`${props.rowInformation.lineItemConfiguration.title}`}
					required={props.rowInformation.lineItemConfiguration.required}
					value={cellValue}
					inputProps={{
						maxLength: 255
					}}
					disabled={!props.rowInformation.lineItemConfiguration.editable}
					onChange={handleFieldChange}
				></Input>
			)}
			{props.rowInformation.lineItemConfiguration.type === LineItemTypeEnum.textarea && (
				<TextArea
					title={`${props.rowInformation.lineItemConfiguration.title}`}
					required={props.rowInformation.lineItemConfiguration.required}
					value={props.rowInformation.lineItemConfiguration.value}
					disabled={!props.rowInformation.lineItemConfiguration.editable}
					maxLength={250}
					onChange={handleFieldChange}
				></TextArea>
			)}
			{props.rowInformation.lineItemConfiguration.type === LineItemTypeEnum.currency && (
				<Input
					type={'text'}
					title={`${props.rowInformation.lineItemConfiguration.title}`}
					required={props.rowInformation.lineItemConfiguration.required}
					disabled={!props.rowInformation.lineItemConfiguration.editable}
					hideTitleOnInput= {true}
					value={
						editing ?
							cellValue :
							Utils.formatCurrency(cellValue, 2)
					}
					error={props.rowInformation.lineItemConfiguration.editable&&
						props.rowInformation.lineItemConfiguration.required&&!cellValue}
					onChange={handleFieldChange}
					onFocus={() => setEditing(true)}
					onBlur={() =>
					{
						setEditing(false);
					}}
				></Input>
			)}
			{props.rowInformation.lineItemConfiguration.type === LineItemTypeEnum.dropdown && (
				<Select
					className="client-name-style"
					data={props.rowInformation.lineItemConfiguration.options}
					valueKey="value"
					labelKey="label"
					searchable={props.rowInformation.lineItemConfiguration.searchable}
					value={props.rowInformation.lineItemConfiguration.value}
					placeholder={props.rowInformation.lineItemConfiguration.title}
					required={props.rowInformation.lineItemConfiguration.required}
					noResultFound={getNoDataTemplate()}
					dropdownRenderMode="portal"
					dropdownAlwaysDown={false}
					onSelect={(value: SelectValue) => handleFieldChange(value, value)}
				/>
			)}
			{props.rowInformation.lineItemConfiguration.type === LineItemTypeEnum.datepicker && (
				<CalendarPickerWrapper
					name={'deliveryDate'}
					className="calendar-style"
					placeholder="MMM DD, YYYY"
					format={'MMM DD, YYYY'}
					locale="en"
					editable={false}
					minDate={new Date('1972,1,1')}
					fieldTitle={props.rowInformation.lineItemConfiguration.title}
					value={props.rowInformation.lineItemConfiguration.value || undefined}
					required={props.rowInformation.lineItemConfiguration.required}
					onValueChange={handleFieldChange}
					datePanelHorizontalAlign="right"
					datepickerAlwaysDown={false}
				/>
			)}
		</div>
	);
};
