
import React, { useState } from 'react';
import {Badge} from '@appkit4/react-components';
import { RelationshipFieldEnum } from '../RelationshipCellEditor/RelationshipFieldEnum';
import { RelationshipsValidationErrorTypes } from 'src/common/types/enums/RelationshipValidationFields';
import { useTranslation } from 'react-i18next';

const renderEquityErrorBadge = () =>
{
	return (<Badge style={{
		backgroundColor: '#D04A02'
	}} value='E'/>);
};

const renderSEPErrorBadge = () =>
{
	return (<Badge style={{
		backgroundColor: '#EB8C00'
	}} value='S'/>);
};

const renderAsOfDateErrorBadge = () =>
{
	return (<div className='circle' style={{
		backgroundColor: '#D04A02'
	}} ><span className="Appkit4-icon icon-calendar-outline"></span>
	</div>);
};

const renderDirectCircularRelationshipErrorBadge = () =>
{
	return (<div className='circle' style={{
		backgroundColor: '#203570'
	}} ><span className="Appkit4-icon icon-transform-outline"></span>
	</div>);
};

const renderIndirectCircularRelationshipErrorBadge = () =>
{
	return (<div className='circle' style={{
		backgroundColor: '#27897E'
	}} ><span className="Appkit4-icon icon-tree-view-outline"></span>
	</div>);
};

export const ValidationErrorsCell = (props: any) =>
{
	if (!!props.dataItem && !!props.dataItem[RelationshipFieldEnum.validationErrors])
	{
		const errors = props.dataItem[RelationshipFieldEnum.validationErrors];
		return (
			<td className='grid-column-errors'>
				{errors.includes(RelationshipsValidationErrorTypes.EquityPercentage) &&
				<div className='item'>{renderEquityErrorBadge()}</div>}
				{errors.includes(RelationshipsValidationErrorTypes.SEP) &&
				<div className='item'>{renderSEPErrorBadge()}</div>}
				{errors.includes(RelationshipsValidationErrorTypes.EffectiveDate) &&
				<div className='item'>{renderAsOfDateErrorBadge()}</div>}
			</td>
		);
	}
	else
	{
		return <td className='grid-column-errors'></td>;
	}
};

export const ValidationInfoElement = (props: any) =>
{
	const [isValidationErrorsExpanded, setIsValidationErrorsExpanded] = useState<Boolean>(false);
	const { t } = useTranslation('engagementSetup');

	return (
		<div className='validation-errors'>
			<div className='error-text'>
				<div className='error-title' onClick={() => setIsValidationErrorsExpanded(!isValidationErrorsExpanded)}>
					{t('relationships.validationResult')}
				</div>
				{isValidationErrorsExpanded ? (
					<span
						className="Appkit4-icon icon-up-chevron-outline"
						onClick={() => setIsValidationErrorsExpanded(false)}
					></span>
				) : (
					<span
						className="Appkit4-icon icon-down-chevron-outline"
						onClick={() => setIsValidationErrorsExpanded(true)}
					></span>
				)}
			</div>
			{isValidationErrorsExpanded &&
				<>
					<div className='error-text info'>{t('relationships.validationResultInfo')}</div>
					<div className='error-info-container'>
						<div className='error-info'>
							<div className='badge-wrapper'>{renderEquityErrorBadge()}</div>
							<div className='error-text info'>{t('relationships.validationEquityError')}</div>
						</div>
						<div className='error-info'>
							<div className='badge-wrapper'>{renderSEPErrorBadge()}</div>
							<div className='error-text info'>{t('relationships.validationSEPError')}</div>
						</div>
						<div className='error-info'>
							<div className='badge-wrapper'>{renderAsOfDateErrorBadge()}</div>
							<div className='error-text info'>{t('relationships.validationAsOfDateError')}</div>
						</div>
						<div className='error-info'>
							<div className='badge-wrapper'>{renderDirectCircularRelationshipErrorBadge()}</div>
							<div className='error-text info'>{t('relationships.directCircularRelationshipError')}</div>
						</div>
						<div className='error-info'>
							<div className='badge-wrapper'>{renderIndirectCircularRelationshipErrorBadge()}</div>
							<div className='error-text info'>{t('relationships.indirectCircularRelationshipError')}</div>
						</div>
					</div>
				</>}
		</div>
	);
};