import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
	Button,
	Modal,
	Select,
	Notification
} from '@appkit4/react-components';
import { SelectValue } from '@appkit4/react-components/esm/combobox/Combobox';
import { ExportReportFields, ExportReportTypes } from 'src/common/types/enums/ExportReportFields';
import { CalendarPickerWrapper } from 'src/common/components/CalendarPickerWrapper/CalendarPickerWrapper';
import { NotificationService } from 'src/core/services/notificationService';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { reportService } from 'src/core/services/reportSevice';
import { IExportReport, ExportReport } from 'src/features/engagement/models/IExportReport';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import { Utils } from 'src/common/utils/utils';
import { IExportReportFormProps } from './IExportReportFormProps';
import './ExportReportForm.scss';

const ExportReportForm = (props: IExportReportFormProps): JSX.Element =>
{
	const { engagementId } = useParams();
	const newRecord = new ExportReport(Number(engagementId));
	const [record, setRecord] = useState<IExportReport>(newRecord);
	const [exportReportFilters, setExportReportFilters] = useState<any>();
	const [isExportDisabled, setIsExportDisabled] = useState<boolean>(true);

	const { t } = useTranslation(
		'report',
		{
			keyPrefix: 'exportReportForm'
		}
	);

	const faCalculatingCurrencyId = -1;

	const onValueChange = (field: string, value: any): void =>
	{
		let updatedForm: any = {
			...record!,
			[field]: value === '' ? undefined : value
		};

		if (field === ExportReportFields.taxpayerTaxYearEnd && !!value)
		{
			updatedForm = {
				...updatedForm!,
				[ExportReportFields.currencyConversionDate]: value
			};
		}

		if (!!updatedForm && !!updatedForm[ExportReportFields.reportType] &&
			updatedForm[ExportReportFields.reportType] === ExportReportTypes.TaxpayerSummaryReportAndFAReports)
		{
			const undefinedKeys = Object.keys(updatedForm).filter((key: string) => updatedForm[key] === undefined);
			const hasAffiliateIds = !!updatedForm[ExportReportFields.affiliateIds] &&
				updatedForm[ExportReportFields.affiliateIds].length > 0;
			setIsExportDisabled((!!undefinedKeys && undefinedKeys.length > 0) || !hasAffiliateIds);
			setRecord(updatedForm);
		}
		else if (!!updatedForm && !!updatedForm[ExportReportFields.reportType] &&
			updatedForm[ExportReportFields.reportType] === ExportReportTypes.TaxpayerSummaryReportOnly)
		{
			const form: any = {
				...updatedForm!,
				[ExportReportFields.affiliateIds]: []
			};
			const undefinedKeysInSummary = Object.keys(form).filter((key: string) => form[key] === undefined);
			setIsExportDisabled(!!undefinedKeysInSummary && undefinedKeysInSummary.length > 0);
			setRecord(form);
		}
	};

	const triggerErrorNotification = async (message: string): Promise<void> =>
	{
		const ele = (
			<Notification
				message={message}
				status={'error'}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};

	const triggerSuccessNotification = async (message: string): Promise<void> =>
	{
		const ele = (
			<Notification
				message={message}
				status={'success'}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};

	const loadExportReportFiltersData = async (): Promise<void> =>
	{
		if (engagementId)
		{
			await engagementService.loadExportReportFilters(
				Number(engagementId)).then((res: any) =>
			{
				const result = !!res && !!res.data && !!res.data.result ? res.data.result : {
				};
				setExportReportFilters(result);
			})
				.catch((error: any) =>
				{
					triggerErrorNotification(
						t('loadingExportReportFiltersError')
					);
				});
		}
	};

	const onClose = () =>
	{
		setRecord({
			...newRecord,
			[ExportReportFields.reportType]: ''
		});
		setIsExportDisabled(true);
		props.onToggleDisplayModal(false);
	};

	const handleExportReport = async () =>
	{
		onClose();
		try
		{
			const response = await reportService.exportReportByEngagementId(
				Number(engagementId),
				record
			);
			if (!!response && !!response.data && !!response.data.result)
			{
				const result = response.data.result;

				if (!!result.exportReportRequestId && result.exportReportRequestId > 0)
				{
					triggerSuccessNotification(
						t('exportReportSucceed')
					);
				}
			}
		}
		catch
		{
			triggerErrorNotification(
				t('exportReportFailed')
			);
		}
	};

	useEffect(() =>
	{
		loadExportReportFiltersData();
	}, [engagementId]);

	useEffect(() =>
	{
		if (!!props.refreshData)
		{
			loadExportReportFiltersData();
		}
	},
	[props.refreshData]);

	return (<>
		<Modal
			className={'expor-report-modal'}
			visible={
				props.displayModal
			}
			closeOnPressEscape={false}
			maskCloseable={false}
			initialFocus={false}
			title={
				t('modalTitle') || ''
			}
			onCancel={onClose}
			footer={
				<div className={'buttons'}>
					<div className={'right'}>
						<Button
							kind={'primary'}
							disabled={isExportDisabled}
							onClick={handleExportReport}
						>
							{
								t('exportButtonText')
							}
						</Button>
						<Button
							kind={'secondary'}
							onClick={onClose}
						>
							{
								t('cancelButtonText')
							}
						</Button>

					</div>
				</div>
			}
		>
			<div className={'export-report-modal-body'}>
				{!!record && <div className={'export-report-form-body'}>
					<div>
						<Select
							required
							data={[
								{
									id: 1,
									name: `${t('taxpayerSummaryReport') || ''} + ${t('foreignAffiliateReports') || ''}`,
									value: ExportReportTypes.TaxpayerSummaryReportAndFAReports
								},
								{
									id: 2,
									name: t('taxpayerSummaryReport') || '',
									value: ExportReportTypes.TaxpayerSummaryReportOnly
								}
							]}
							labelKey={'name'}
							valueKey={'value'}
							dropdownRenderMode={'portal'}
							value={!!record && !!record[ExportReportFields.reportType] ?
								record[ExportReportFields.reportType] : ''}
							placeholder={
								t('reportPlaceholder') || ''
							}
							error={false}
							noResultFound={
								<>
									{
										t('noDataAvailable')
									}
								</>
							}
							onSelect={(value: SelectValue) =>
								onValueChange(ExportReportFields.reportType, value)
							}
						/>
					</div>
					{
						!!record && !!record[ExportReportFields.reportType] &&
						<>
							<div>
								<Select
									required
									data={!!exportReportFilters && !!exportReportFilters.taxPayerFilters &&
										!!exportReportFilters.taxPayerFilters.taxationYearEnds &&
										exportReportFilters.taxPayerFilters.taxationYearEnds.length > 0 ?
										exportReportFilters.taxPayerFilters.taxationYearEnds
											.map((t: string): any =>
											{
												return {
													id: t,
													name: dateFormatter.format(
														dateFormatter.utcToEst(t),
														'MMM DD, YYYY'
													)

												};
											}) : []}
									labelKey={'name'}
									valueKey={'id'}
									dropdownRenderMode={'portal'}
									value={!!record && !!record[ExportReportFields.taxpayerTaxYearEnd] ?
										record[ExportReportFields.taxpayerTaxYearEnd] : undefined}
									placeholder={t('taxpayerTaxationYearPlaceholder') || ''}
									error={false}
									noResultFound={
										<>
											{
												t('noDataAvailable')
											}
										</>
									}
									onSelect={(value: SelectValue) =>
										onValueChange(ExportReportFields.taxpayerTaxYearEnd, value)
									}
								/>
							</div>
							{record[ExportReportFields.reportType] === ExportReportTypes.TaxpayerSummaryReportAndFAReports &&
								<div className={'related-field-title'}>
									{t('relateToTaxpayerSummaryReport') || ''}
								</div>}
							<div>
								<Select
									required
									searchable
									data={!!props.currencyOptions && !!props.currencyOptions.length ?
										props.currencyOptions : []
									}
									labelKey={'name'}
									valueKey={'id'}
									dropdownRenderMode={'portal'}
									value={!!record && !!record[ExportReportFields.currencyId] ?
										record[ExportReportFields.currencyId] : undefined}
									placeholder={
										t('currencyPlaceholder') || ''
									}
									error={false}
									noResultFound={
										<>
											{
												t('noDataAvailable')
											}
										</>
									}
									onSelect={(value: SelectValue) =>
										onValueChange(ExportReportFields.currencyId, value)
									}
								/>
							</div>
							<div className={'calendar-time'}>
								<CalendarPickerWrapper
									required={!!record && record[ExportReportFields.currencyId] !== faCalculatingCurrencyId}
									useCustomValidation
									editable={false}
									fieldTitle={
										t('currencyConversionDatePlaceholder') || ''
									}
									format={'MMM DD, YYYY'}
									value={!!record && !!record[ExportReportFields.currencyConversionDate] ?
										dateFormatter.utcToEst(record[ExportReportFields.currencyConversionDate]) : undefined}
									disabled={!!record && record[ExportReportFields.currencyId] === faCalculatingCurrencyId}
									datePanelHorizontalAlign={'right'}
									error={false}
									customErrorNode={<></>}
									onChange={(value: Date) =>
									{
										const conversion = dateFormatter.estDateStringOverrideToUtc(
											dateFormatter.estDateToString(value),
											false
										);

										// only update if the conversion date is different than what was already selected
										if (conversion !== record[ExportReportFields.currencyConversionDate])
										{
											const currencyConversionDate = dateFormatter.estDateStringOverrideToUtc(
												dateFormatter.estDateToString(value),
												false
											);

											onValueChange(ExportReportFields.currencyConversionDate, currencyConversionDate);
										}
									}}
								/>
							</div>
							<div>
								<Select
									required
									data={[
										{
											id: 1,
											name: Utils.replaceTokens(t('sepApplicableNo'))
										},
										{
											id: 2,
											name: Utils.replaceTokens(t('sepApplicableYes'))
										}
									]}
									labelKey={'name'}
									valueKey={'id'}
									dropdownRenderMode={'portal'}
									value={!!record && !!record[ExportReportFields.isSEPFactorApplicable] ?
										(record[ExportReportFields.isSEPFactorApplicable] ? 2 : 1) :
										undefined}
									placeholder={
										t('sepFactorApplicablePlaceholder') || ''
									}
									error={false}
									noResultFound={
										<>
											{
												t('noDataAvailable')
											}
										</>
									}
									onSelect={(value) =>
										onValueChange(ExportReportFields.isSEPFactorApplicable, value === 2)
									}
								/>
							</div>
							{record[ExportReportFields.reportType] === ExportReportTypes.TaxpayerSummaryReportAndFAReports &&
								<>
									<div className={'related-field-title'}>
										{t('relateToForeignAffiliatesReports') || ''}
									</div>
									<div>
										<Select
											required
											searchable
											multiple
											data={!!exportReportFilters && !!exportReportFilters.affiliates &&
												exportReportFilters.affiliates.length > 0 ?
												exportReportFilters.affiliates : []}
											labelKey={'value'}
											valueKey={'key'}
											dropdownRenderMode={'portal'}
											dropdownAlwaysDown={false}
											value={!!record && !!record[ExportReportFields.affiliateIds] ?
												record[ExportReportFields.affiliateIds] : undefined}
											placeholder={
												t('foreignAffiliatesPlaceholder') || ''
											}
											error={false}
											noResultFound={
												<>
													{
														t('noDataAvailable')
													}
												</>
											}
											onSelect={(value: SelectValue) =>
												onValueChange(ExportReportFields.affiliateIds, value)
											}
										/>
									</div>
									{/* <div className={'show-notes-bar'}>
										<Checkbox
											disabled={false}
											checked={!!record && !!record[ExportReportFields.showNotes] ?
												record[ExportReportFields.showNotes] : false}
											onChange={() => onValueChange(ExportReportFields.showNotes,
												!record[ExportReportFields.showNotes])}
										></Checkbox>
										<div className={'show-notes-label'}>
											{t('showNotes') || ''}
										</div>
									</div> */}
								</>}
						</>
					}
				</div>}
			</div>
		</Modal>
	</>);
};

export default ExportReportForm;