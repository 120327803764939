import { dateFormatter } from 'src/common/utils/dateFormatter';

export interface IForeignAffiliate {
	affiliateId?: number;
	name: string;
	currencyId?: number;
	countryId?: number;
	taxationYearEnd?: Date;
	dateBecameFa?: Date;
	analysisStartDate?: Date;
	analysisEndDate?: Date;
	inEditableMode?: boolean;
	isNewItem?: boolean;
	selected?: boolean;
	showedWarning?: boolean;
	isSystemAdded?: boolean;
	surplus?: boolean,
	enhancedSurplus?: boolean,
	FAPI?: boolean,
	ACB?: boolean
}

export class ForeignAffiliate implements IForeignAffiliate
{
	constructor ()
	{
		this.name = '';
		this.isNewItem = true;
		this.inEditableMode = false;
		this.selected = false;
		this.showedWarning = false;
	}

	affiliateId?: number;
	name: string;
	calculatingCurrencyId?: number;
	currencyId?: number;
	countryId?: number;
	countryName?: string;
	currencyName?: string;
	calculationTypes?: string[];
	taxationYear?: Date;
	taxationYearEnd?: Date;
	dateBecameFa?: Date;
	analysisStartDate?: Date;
	analysisEndDate?: Date;

	inEditableMode: boolean;
	isNewItem: boolean;
	selected: boolean;
	showedWarning: boolean;

	static parseFields(affiliate: IForeignAffiliate): IForeignAffiliate
	{
		return {
			...affiliate,
			dateBecameFa: dateFormatter.toStartOfDayLocal(affiliate.dateBecameFa),
			taxationYearEnd: dateFormatter.toStartOfDayLocal(affiliate.taxationYearEnd),
			analysisStartDate: dateFormatter.toStartOfDayLocal(affiliate.analysisStartDate),
			analysisEndDate: dateFormatter.toEndOfDay(affiliate.analysisEndDate)
		};
	}
}