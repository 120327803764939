import { Utils } from 'src/common/utils/utils';

export enum Reg5907AdjustmentsLookupTypes {

	RetainedEarnings = 'retainedEarnings',
	NetIncomeOrLoss = 'netIncomeOrLoss'
}

export interface IRetainedEarningsLookupRecord {

	id: number;
	differenceType: string;
	name: string;
	internaName: string;
}

export interface IRetainedEarningsRowBase {

	id: number;
	rowIndex: number|undefined,
	differenceTypeId: number;
	amount?: number;
	notes?:  string;
}

export interface IRetainedEarningsRow extends IRetainedEarningsRowBase {
	taxYearEnd?: string;
	affiliateTaxYearEndId: number;
	calculatingCurrencyId: number;
}

export class RetainedEarningsRow implements IRetainedEarningsRow
{
	constructor (
		{
			differenceTypeId = 0,
			taxYearEnd = '',
			affiliateTaxYearEndId,
			calculatingCurrencyId,
			rowIndex
		}:
		{
			differenceTypeId?: number;
			taxYearEnd: string;
			affiliateTaxYearEndId: number;
			calculatingCurrencyId: number;
			rowIndex: number;
		}
	)
	{
		this.id = Utils.generateTempId();
		this.differenceTypeId = !!differenceTypeId ? differenceTypeId : 0;
		this.amount = undefined;
		this.taxYearEnd = taxYearEnd;
		this.notes = undefined;
		this.affiliateTaxYearEndId = affiliateTaxYearEndId;
		this.rowIndex = rowIndex;
		this.calculatingCurrencyId = calculatingCurrencyId;
	}

	id: number;
	differenceTypeId: number;
	amount?: number;
	notes?:  string;
	taxYearEnd?: string;
	rowIndex: number;
	affiliateTaxYearEndId: number;
	calculatingCurrencyId: number;
}

export enum RetainedEarningsSubmissionToken {

	RetainedEarnings = 'retainedEarnings',
	NetIncomeOrLoss = 'netIncomeOrLoss'
}

export interface IRetainedEarningsRecord {

	[RetainedEarningsSubmissionToken.RetainedEarnings]: IRetainedEarningsRow[];
	[RetainedEarningsSubmissionToken.NetIncomeOrLoss]: IRetainedEarningsRow[];
}

export interface INetIncomeSummarySection {

	netIncomeOrLossAfterTax: IRetainedEarningsRowBase;
	netIncomeOrLossBeforeTax: IRetainedEarningsRowBase;
	netIncomeOrLossFromTaxReturn: IRetainedEarningsRowBase;
	unreconciledDifference: IRetainedEarningsRowBase;
}

export interface IRetainedEarningSummarySection {

	endingRetainedEarnings: IRetainedEarningsRowBase;
	endingRetainedEarningsOnFinancialStatements: IRetainedEarningsRowBase;
	netIncomeOrLossAfterTax: IRetainedEarningsRowBase;
	openingRetainedEarnings: IRetainedEarningsRowBase;
	unreconciledDifference: IRetainedEarningsRowBase;
}

export interface IRetainedEarningsYear {

	affiliateTaxYearEndId: number;
	taxYearEnd: string;
	countryId: number;
	calculatingCurrencyId: number;
	netIncomeOrLossSummary?: INetIncomeSummarySection;
	retainedEarningsSummary?: IRetainedEarningSummarySection;
	[RetainedEarningsSubmissionToken.RetainedEarnings]: IRetainedEarningsRow[];
	[RetainedEarningsSubmissionToken.NetIncomeOrLoss]: IRetainedEarningsRow[];
}

export interface IRetainedEarningDto {

	id: number;
	affiliateTaxYearEndId: number;
	rowIndex: number | undefined,
	differenceTypeId: number;
	amount?: number;
	notes?:  string;
}

export interface IRetainedEarningsSubmission {

	engagementId: number;
	affiliateId: number;
	retainedEarnings: IRetainedEarningDto[];
}

export interface IUnreconciledDifferenceError {
	retainedEarningsError: boolean;
	netIncomeOrLossError: boolean;
}