import { Modal, Button } from '@appkit4/react-components';
import './UnsavedChangesModal.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { IUnsavedChangesModalProps } from './IUnsavedChangesModalProps';
import useDisableBodyScroll from 'src/common/hooks/useDisableBodyScroll';

export const UnsavedChangesModal = (props: IUnsavedChangesModalProps) =>
{
	const { t } = useTranslation('homeDashboard');

	const [, setValue] = useState<boolean>(false);
	const [disableIcon, setDisableIcon] = useState<boolean>(false);

	const preventDoubleClick = () =>
	{
		setDisableIcon(true);

		setTimeout(() =>
		{
			setDisableIcon(false);
		}, 3000);
	};

	useEffect(() =>
	{
		setValue(!!props.visible);
	}, []);

	useDisableBodyScroll(!!props.visible);


	if (props?.visible === false)
	{
		return null;
	}


	return (
		<>
			<Modal
				tabIndex={-1}
				className="unsaved-changes-modal"
				visible={props.visible}
				initialFocus={false}
				closeOnPressEscape={false}
				maskCloseable={false}
				title={props.title}
				onCancel={() => props.onClose('stay')}
				footer={
					<div className="footer-wrapper">
						<Button
							tabIndex={0}
							kind="secondary"
							onClick={() =>
							{
								setValue(false);
								props.onClose('stay');
							}}
						>
							{t('unsaved-popup.cancel')}
						</Button>
						<div onClick={preventDoubleClick}>
							<Button kind="primary" onClick={() => props.onClose('leave')} disabled={disableIcon}>
								{t('unsaved-popup.discardChanges')}
							</Button>
						</div>
					</div>
				}
			>
				<div>
					<p>{t('unsaved-popup.message1')}</p>
					<p>{t('unsaved-popup.message2')}</p>
				</div>
			</Modal>
		</>
	);
};
