import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppSelector, RootState } from 'src/store/store';
import { useNavigate } from 'react-router-dom';

export const AppAuthorization = (props: { children: JSX.Element }): JSX.Element =>
{
	const navigate = useNavigate();

	const userProfileError = useSelector((state: RootState) => state.userProfile.userProfileError);
	const privacyError = useSelector((state: RootState) => state.userProfile.privacyError);
	const userProfile = useAppSelector((state) => state.userProfile.profile);

	const REQUEST_FAILED_ERROR = 'Request failed with status code';

	useEffect(() =>
	{
		if ((userProfileError?.includes(REQUEST_FAILED_ERROR) || privacyError?.includes(REQUEST_FAILED_ERROR)) &&
			((!!userProfileError && Number(userProfileError?.slice(-3)) >= 400) ||
			(!!privacyError && Number(privacyError?.slice(-3)) >= 400)))
		{
			navigate('/access-denied');
		}
	}, [navigate, userProfileError, privacyError]);

	return (
		<>
			{!!userProfile && props.children}
		</>
	);
};