
import { EarningsSummaryFields } from 'src/common/types/enums/EarningsSummaryFields';

const EarningsSummaryReportFields = {
	'header': [
		{
			'name': 'status',
			'type': 'status',
			'background': 'section-top-border'
		},
		{
			'name': 'taxYearEnd',
			'type': 'taxYearEnd'
		},
		{
			'name': 'countryId',
			'type': 'countryId'
		},
		{
			'name': 'calculatingCurrencyId',
			'type': 'currencyId'
		}
	],
	'summary': [
		{
			'name': 'foreignRules-title',
			'type': 'title'
		},
		{
			'name': `${EarningsSummaryFields.foreignTaxableIncome}`,
			'type': 'amountWithNotes'
		},
		{
			'name': 'incomeLossFromProperty-title',
			'type': 'subtitle'
		},
		{
			'name': `${EarningsSummaryFields.lessIncomeFromPropertyFAPI}`,
			'type': 'amountWithNotes',
			'background': 'left-indented'
		},
		{
			'name': `${EarningsSummaryFields.lessIncomeFromPropertyABI}`,
			'type': 'amountWithNotes',
			'background': 'left-indented'
		},
		{
			'name': `${EarningsSummaryFields.lessCapitalGain}`,
			'type': 'amountWithNotes'
		},
		{
			'name': `${EarningsSummaryFields.reg5907Adjustments}`,
			'type': 'amountWithNotes'
		},
		{
			'name': `${EarningsSummaryFields.additionalReg5907Adjustments}`,
			'type': 'amountWithNotes'
		},
		{
			'name': `${EarningsSummaryFields.earningsUnderForeignRules}`,
			'type': 'amountWithNotes',
			'background': 'highlight'
		},
		{
			'name': '',
			'type': 'separator'
		},
		{
			'name': 'partOfTheAct-title',
			'type': 'title'
		},
		{
			'name': `${EarningsSummaryFields.fapi}`,
			'type': 'amountWithNotes'
		},
		{
			'name': `${EarningsSummaryFields.deemedABI}`,
			'type': 'amountWithNotes'
		},
		{
			'name': `${EarningsSummaryFields.abi}`,
			'type': 'amountWithNotes'
		},
		{
			'name': `${EarningsSummaryFields.otherABIAdjustments}`,
			'type': 'amountWithNotes'
		},
		{
			'name': `${EarningsSummaryFields.otherTaxableEarnings}`,
			'type': 'amountWithNotes'
		},
		{
			'name': `${EarningsSummaryFields.earningsUnderCanadianRules}`,
			'type': 'amountWithNotes',
			'background': 'highlight'
		}
	]
};

export default EarningsSummaryReportFields;