import axios from 'axios';
import { IExportReport } from 'src/features/engagement/models/IExportReport';

export const reportService = {
	getMyDownloadRequests: async (engagementId: number) =>
	{
		return axios.get(`${process.env.REACT_APP_REPORT_API}/reports/exportrequests`,
			{
				params: {
					engagementId
				}
			}
		);
	},

	downloadReport: async (requestId: number) =>
	{
		return axios.post(
			`${process.env.REACT_APP_REPORT_API}/reports/downloads/${requestId}`, {
			}, {
				responseType: 'blob'
			}
		);
	},

	exportReportByEngagementId: async (engagementId: number, payload: IExportReport) =>
	{
		return axios.post(
			`${process.env.REACT_APP_REPORT_API}/engagement/${engagementId}/report/export`,
			payload
		);
	}
};