import { Button } from '@appkit4/react-components';
import { IActionBarProps } from './IActionBarProps';
import { useTranslation } from 'react-i18next';

import './ActionBar.scss';
import { SaveButtonLocations } from '../../IColumnarGridProps';

export function ActionBar(props: IActionBarProps): JSX.Element
{
	const { t } = useTranslation(
		'common',
		{
			keyPrefix: 'columnarTableActionBar'
		}
	);

	return <div className={'action-bar'}>
		<div className={'left'}>
			{
				!!props.infoMessage &&
				<div className="infoMessage">
					<span className={'Appkit4-icon icon-information-outline'} />
					{props.infoMessage}
				</div>
			}
		</div>
		<div className={'right'}>
			{
				!!props.onOpenNew &&
			<Button
				kind={'primary'}
				icon={'icon-plus-outline'}
				disabled={
					!!props.isLoading ||
					!!props.isDisabled
				}
				onClick={props.onOpenNew}
			>
				{
					props.addNewButtonText
				}
			</Button>
			}
			{
				!!props.saveButtonLocation &&
			(
				props.predefinedSaveButtonLocation === props.saveButtonLocation ||
				props.saveButtonLocation === SaveButtonLocations.TopBottom
			) &&
			!!props.onGridSave &&
			<Button
				kind={'primary'}
				disabled={
					props.isLoading ||
					props.isDisabled
				}
				onClick={props.onGridSave}
			>
				{
					t('saveButtonText')
				}
			</Button>
			}
		</div>
	</div>;
}