import { useEffect, useState, useRef } from 'react';
import { ITaxPayerReportProps } from './ITaxPayerReportProps';
import { Accordion, AccordionItem, Button, Pagination, Search, Tooltip, Loading } from '@appkit4/react-components';
import { ITaxPayerReportRecord, TaxPayerReportRecord } from 'src/common/types/interfaces/ITaxPayerReport';
import { useAppSelector } from 'src/store/store';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import { INameId } from 'src/common/types/interfaces/INameId';
import { Utils } from 'src/common/utils/utils';
import { CalendarFormControl, DropdownFormControl } from 'src/common/components/FormControls';
import { NotificationService } from 'src/core/services/notificationService';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { useTranslation } from 'react-i18next';
import { ReportI18NKeys } from 'src/common/types/enums/ReportI18NKeys';
import { useParams } from 'react-router-dom';
import { CalculationStatus } from 'src/common/types/enums/CalculationStatus';
import { ICalculationHistory } from 'src/features/engagement/models/ICalculationHistory';
import ExportReportForm from 'src/features/engagement/components/Report/ExportReportForm/ExportReportForm ';
import './TaxPayerReport.scss';

export const TaxPayerReport = (props: ITaxPayerReportProps): JSX.Element =>
{
	const { t: report } = useTranslation(
		'report',
		{
			keyPrefix: ReportI18NKeys.Report
		}
	);

	const runCalcInitialTooltip = {
		'status': `${report('status')}: ${report('neverRunStatus')}`,
		'started': `${report('startedTime')}: ${report('notApplicable')}`
	};

	const relationshipsNotValidTooltip = {
		'message': report('relationshipsNotValidated')
	};

	const faCalculatingCurrencyId = -1;

	const { engagementId } = useParams();
	const [isLoading, setIsLoading] = useState(true);

	const [taxPayerRecords, setTaxPayerRecords] = useState<ITaxPayerReportRecord[]>([]);
	const [filteredTaxPayerRecords, setFilteredTaxPayerRecords] = useState<ITaxPayerReportRecord[]>([]);
	const [taxYearEndOptions, setTaxYearEndOptions] = useState<string[]>([]);

	const [displaySEP, setDisplaySEP] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [selectedTaxYearEnd, setSelectedTaxYearEnd] = useState<string | undefined>(undefined);
	const [selectedCurrencyId, setSelectedCurrencyId] = useState<number | undefined>(undefined);
	const [selectedCurrencyConversionDate, setSelectedCurrencyConversionDate] = useState<string | undefined>(undefined);

	const countries = useAppSelector((state) => state.appSettings.countries);
	const currencies = useAppSelector((state) => state.appSettings.currencies);
	const [activeSummaryKeys, setActiveSummaryKeys] = useState<string[]>(['1']);

	const [currencyOptions, setCurrencyOptions] = useState<INameId[]>([]);

	const defaultRecordsPerPage = 25;

	const pagingOptions = [25, 50, 100];
	const [recordsPerPage, setRecordsPerPage] = useState(defaultRecordsPerPage); // todo: add setRecordsPerPage for paging dropdown use
	const [currentPage, setCurrentPage] = useState(1);

	const [isPagePickerExpanded, setIsPagePickerExpanded] = useState<boolean>(false);
	const [calculationHistory, setCalculationHistory] = useState<ICalculationHistory>();
	const [calculationStatus, setCalculationStatus] = useState<string>();
	const [isCalcProcessing, setIsCalcProcessing] = useState<boolean>(false);
	const [isRunCalcDisabled, setIsRunCalcDisabled] = useState<boolean>(true);
	const [refershReportData, setRefershReportData] = useState<boolean>(false);
	const [isEngagementRelationshipsValid, setIsEngagementRelationshipsValid] = useState<boolean | undefined>(undefined);
	const [runCalcTooltip, setRunCalcTooltip] = useState<any>(runCalcInitialTooltip);
	const intervalId = useRef<number | undefined>(undefined);
	const calcStatusErrorCount = useRef<number>(0);

	const [displayExportReportModal, setDisplayExportReportModal] = useState<boolean>(false);

	const { t } = useTranslation(
		'report',
		{
			keyPrefix: ReportI18NKeys.TaxPayerReport
		}
	);

	const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) =>
	{
		if (e.key === 'Enter')
		{
			filterData(taxPayerRecords, searchText);
		}
	};

	const onSearchClear = () =>
	{
		setSearchText('');
		filterData(taxPayerRecords, '');
	};

	const onSearchTextChange = (value: string) =>
	{
		setSearchText(value);
	};

	const filterData = (
		records: ITaxPayerReportRecord[],
		searchText: string
	) =>
	{
		if (
			!!searchText &&
			!!searchText.length &&
			!!searchText.trim().length
		)
		{
			setFilteredTaxPayerRecords(
				records.filter((r) => !!r.affiliateName.toLowerCase().includes(searchText.toLowerCase()))
			);
		}
		else
		{
			setFilteredTaxPayerRecords([...records]);
		}

		setCurrentPage(1);
	};

	const getBalanceValue = (
		isSEPDisplayed: boolean,
		value?: number,
		sep?: number
	): string =>
	{
		let multiplier = 1;

		// if SEP is displayed and there is a valid sep value, apply the SEP factor
		if (!!isSEPDisplayed && !!sep)
		{
			multiplier = sep / 100;
		}

		//  if there is a value and it is not 0, apply numeric formatting
		if (!!value)
		{
			return Utils.formatCurrency(`${value * multiplier}`, 0);
		}

		return value === null || value === 0 ? '0' : '';
	};

	const getSummaryBalance = (
		records: any[],
		key: string,
		isSEPDisplayed: boolean
	): string =>
	{
		let total = 0;

		// if there are records and the filtered records are not empty, calculate total
		if (
			!!filteredTaxPayerRecords &&
			!!filteredTaxPayerRecords.length
		)
		{
			total = records.reduce((acc, r) =>
			{
				// if SEP is displayed, apply the SEP factor to the value
				const sepValue = !!isSEPDisplayed ?
					(!!r.taxpayerSEP ? (r.taxpayerSEP / 100) : 1) :
					1;

				return acc + ((r[key] || 0) * sepValue);
			}, 0);
		}

		// if there is a total, apply numeric formatting
		if (!!total)
		{
			return Utils.formatCurrency(`${total}`, 0);
		}

		return total === null || total === 0 ? '0' : '';
	};

	const loadCurrencies = (): number =>
	{
		const taxPayerCurrency = currencies
			.find((c) => c.id === props.engagementOverview!.taxPayerCurrencyId);

		setCurrencyOptions([
			{
				id: taxPayerCurrency!.id,
				name: Utils.replaceTokens(t('currencyOptionTaxPayer'), taxPayerCurrency!.code)
			},
			{
				id: faCalculatingCurrencyId,
				name: Utils.replaceTokens(t('currencyOptionFACurrency'))
			},
			...currencies
				.filter((c) => c.id !== taxPayerCurrency!.id)
				.map((c) =>
				{
					return {
						id: c.id,
						name: c.code
					};
				})
		]);

		setSelectedCurrencyId(taxPayerCurrency!.id);

		return taxPayerCurrency!.id;
	};

	const loadTaxYearEnds = async (): Promise<string | undefined> =>
	{
		try
		{
			const response = await engagementService
				.getTaxPayerTaxYearEnds(props.engagementOverview!.id!);

			let defaultTaxYearEnd: string | undefined;

			// if data, set the options
			if (
				!!response &&
				!!response.data &&
				!!response.data.result &&
				!!response.data.result.length
			)
			{
				setTaxYearEndOptions(response.data.result);

				defaultTaxYearEnd = response.data.result[response.data.result.length - 1];

				// set the latest date to the selected tax year end
				setSelectedTaxYearEnd(defaultTaxYearEnd);
				setSelectedCurrencyConversionDate(defaultTaxYearEnd);
			}
			// if no data, reset the options
			else
			{
				setTaxYearEndOptions([]);
				setSelectedTaxYearEnd(undefined);
			}

			return defaultTaxYearEnd;
		}
		catch (error)
		{
			triggerErrorNotification(
				Utils.replaceTokens(t('taxYearOptionsError'))
			);

			setTaxYearEndOptions([]);
			setSelectedTaxYearEnd(undefined);
		}
	};

	const loadTaxPayerReportRecords = async (
		taxYearEnd?: string,
		currencyId?: number,
		currencyConversionDate?: string
	) =>
	{
		try
		{
			setIsLoading(true);

			const response = await engagementService
				.getTaxPayerReportRecords(
					props.engagementOverview!.id!,
					taxYearEnd!,
					currencyId!,
					currencyConversionDate!
				);

			if (
				!!response &&
				!!response.data &&
				!!response.data.result &&
				!!response.data.result.length
			)
			{
				const transformedTaxPayerRecords = response.data.result
					.map(TaxPayerReportRecord.toEntity);

				setTaxPayerRecords([...transformedTaxPayerRecords]);
				filterData(transformedTaxPayerRecords, searchText);
				setRefershReportData(false);
			}
			else
			{
				setTaxPayerRecords([]);
				filterData([], searchText);
			}
		}
		catch (error: any)
		{
			if (!!taxPayerRecords && taxPayerRecords.length > 0 && !!error && !!error.response &&
				!!error.response.data && !!error.response.data.Errors && error.response.data.Errors.length > 0 &&
				!!error.response.data.Errors[0].ErrorCode &&
				error.response.data.Errors[0].ErrorCode === 'TAXPAYER_FXRATE_NOT_FOUND'
			)
			{
				const updatedTaxPayerRecords = taxPayerRecords.map((record: ITaxPayerReportRecord) =>
				{
					const updatedRecord: { [key: string]: any } = {
						...record
					};

					Object.keys(updatedRecord).forEach((key) =>
					{
						if (key.toLowerCase().includes('balance'))
						{
							updatedRecord[key] = 0;
						}
					});

					return updatedRecord;
				});

				filterData(updatedTaxPayerRecords.map(TaxPayerReportRecord.toEntity), searchText);
				triggerErrorNotification(
					Utils.replaceTokens(t('fxRateNotFoundError'))
				);
			}
			else
			{
				triggerErrorNotification(
					Utils.replaceTokens(t('taxYearReportLoadingError'))
				);
			}
		}

		setIsLoading(false);
	};

	const loadData = async () =>
	{
		if (
			!!props.engagementOverview &&
			!!currencies &&
			!!currencies.length &&
			!!countries &&
			!!countries.length
		)
		{
			const taxPayerCurrencyId = loadCurrencies();
			const taxYearEnd = await loadTaxYearEnds();
			await loadTaxPayerReportRecords(taxYearEnd, taxPayerCurrencyId, taxYearEnd);
		}
	};

	const triggerErrorNotification = async (message: string): Promise<void> =>
	{
		NotificationService.error({
			message
		});
	};

	const loadCalculationHistory = async (
		engagementId: number
	): Promise<void> =>
	{
		try
		{
			const response = await engagementService.getCalculationHistory(engagementId);
			checkCalculationStatus(response);
		}
		catch (error)
		{
			triggerErrorNotification(
				report('calcHistoryError')
			);
			setIsRunCalcDisabled(false);
			setRunCalcTooltip(runCalcInitialTooltip);
		}
	};

	const loadEngagementRelationshipsValidStatus = async (
		engagementId: number
	): Promise<void> =>
	{
		try
		{
			await engagementService.getEngagementRelationshipsValidStatus(engagementId).then((res: any) =>
			{
				if (!!res && !!res.data && !!res.data.result)
				{
					const result = res.data.result;
					setIsEngagementRelationshipsValid(result.isEngagementRelationshipsValid);
				}
				else
				{
					setIsEngagementRelationshipsValid(false);
				}
			});
		}
		catch (error)
		{
			triggerErrorNotification(
				report('relationshipsValidatedStatusError')
			);
			setIsRunCalcDisabled(false);
			setRunCalcTooltip(runCalcInitialTooltip);
		}
	};

	const loadCalculationStatus = async (
		engagementId: number,
		calculationId: number
	): Promise<void> =>
	{
		try
		{
			const response = await engagementService.getCalculationStatus(engagementId, calculationId);

			if (!!response && !!response.data && !!response.data.result)
			{
				const result = response.data.result;

				if (!!result[CalculationStatus.SurplusCalculationHistory])
				{
					const surplusCalculationHistory = result[CalculationStatus.SurplusCalculationHistory];
					processCalculationStatus(surplusCalculationHistory);
				}
			}
			else
			{
				setCalculationStatus('');
			}
			calcStatusErrorCount.current = 0;
		}
		catch (error)
		{
			calcStatusErrorCount.current += 1;
			if (!!calcStatusErrorCount && calcStatusErrorCount.current >= 3)
			{
				triggerErrorNotification(
					report('calcStatusError')
				);

				if (intervalId.current !== undefined)
				{
					clearInterval(intervalId.current);
				}
				calcStatusErrorCount.current = 0;
				setIsCalcProcessing(false);
				props.setShowBanner(false);
				setIsRunCalcDisabled(false);
				setRunCalcTooltip(createTooltip(CalculationStatus.Failed, calculationHistory?.createdOn!));
			}
		}
	};

	const processCalculationStatus = (result: any) =>
	{
		if (!!result[CalculationStatus.Status])
		{
			const status = result[CalculationStatus.Status];
			const createdOn = result[CalculationStatus.createdOn];
			const updatedOn = result[CalculationStatus.UpdatedOn];

			setCalculationStatus(status);

			if (status === CalculationStatus.Processed || status === CalculationStatus.Failed)
			{
				setIsCalcProcessing(false);
				props.setShowBanner(false);
				setRunCalcTooltip(createTooltip(status, createdOn, updatedOn));
				if (intervalId.current !== undefined)
				{
					clearInterval(intervalId.current);
					intervalId.current = undefined;
					setRefershReportData(true);
				}
				setIsRunCalcDisabled(!isEngagementRelationshipsValid);
				!isEngagementRelationshipsValid && setRunCalcTooltip(relationshipsNotValidTooltip);
			}
			else if (status === CalculationStatus.NotProcessed)
			{
				setIsCalcProcessing(true);
				setIsRunCalcDisabled(true);
				props.setShowBanner(true);
				setRunCalcTooltip(createTooltip(status, createdOn));
			}
		}
	};

	const checkCalculationStatus = (response: any) =>
	{
		if (!!response && !!response.data && !!response.data.result)
		{
			const result = response.data.result;

			setCalculationHistory(result);
			processCalculationStatus(result);
		}
		else
		{
			setCalculationHistory(undefined);
			setIsRunCalcDisabled(false);
		}
	};

	const runCalculations = async (
		engagementId: number,
		calculationMode: number
	): Promise<void> =>
	{
		try
		{
			const response = await engagementService.runCalculationsForEngagement(engagementId, calculationMode);
			if (!!response && !!response.data && !!response.data.result)
			{
				const status = response.data.result[CalculationStatus.Status];
				setRefershReportData(status === CalculationStatus.Processed || status === CalculationStatus.Failed);
			}
			else
			{
				setRefershReportData(false);
			}
			checkCalculationStatus(response);
		}
		catch (error)
		{
			setIsCalcProcessing(false);
			props.setShowBanner(false);
			setIsRunCalcDisabled(false);
			setRunCalcTooltip(createTooltip(calculationHistory?.status!, calculationHistory?.createdOn!));
			triggerErrorNotification(
				report('reCalculationFailed')
			);
		}
	};


	const formatDate = (date: any) =>
	{
		return `${!!date ?
			dateFormatter.format(dateFormatter.utcToEst(date), 'MMM DD, YYYY hh:mm A') : ''}`;
	};

	const createTooltip = (status: string, createdOn: string, updatedOn?: string) =>
	{
		const calcStatus = {
			'status': `${report('status')}: ${status === CalculationStatus.Processed ? report('processedStatus') :
				(status === CalculationStatus.Failed ? report('failedStatus') : report('inProgressStatus'))}`
		};
		const calcLastRun = {
			'calcLastRun': `${report('calcLastRun')}: ${formatDate(createdOn)}`
		};
		const completedTime = !!updatedOn ? {
			'completedTime': `${report('completedTime')}: ${formatDate(updatedOn)}`
		} : {
		};

		return ({
			...calcStatus,
			...calcLastRun,
			...completedTime
		});
	};

	const handleRecordsPerPage = (option: any) =>
	{
		setRecordsPerPage(option);
		setIsPagePickerExpanded(false);
	};

	const handleRunCalculation = () =>
	{
		setIsCalcProcessing(true);
		props.setShowBanner(true);
		setIsRunCalcDisabled(true);
		setRunCalcTooltip(createTooltip(CalculationStatus.NotProcessed, calculationHistory?.createdOn!));
		runCalculations(Number(engagementId), 1);
	};

	const renderRunCalcTooltip = () =>
	{
		return (<>
			{!!runCalcTooltip && Object.keys(runCalcTooltip).map((item: any, index: number) =>
			{
				return <div key={index}> {runCalcTooltip[item]}</div>;
			})}
		</>
		);
	};

	const renderNumericFieldTitle = (title: string) =>
	{
		return !!currencies && !!selectedCurrencyId && selectedCurrencyId !== faCalculatingCurrencyId ?
			`${title} ${t('titleCurrencyIn')} ${currencies.find((c) => c.id === selectedCurrencyId)!.code}` :
			title || '';
	};

	useEffect(() =>
	{
		// load main data
		loadData();
	},
	[props.engagementOverview, currencies, countries]);

	useEffect(
		() =>
		{
			if (!!engagementId)
			{
				loadEngagementRelationshipsValidStatus(Number(engagementId));
			}
		}, [engagementId]);

	useEffect(
		() =>
		{
			if (!!engagementId)
			{
				loadCalculationHistory(Number(engagementId));
			}
		}, [isEngagementRelationshipsValid]);

	useEffect(() =>
	{
		if (!!calculationStatus && calculationStatus === CalculationStatus.NotProcessed &&
			!!engagementId &&
			!!calculationHistory && !!calculationHistory[CalculationStatus.Id])
		{
			loadCalculationStatus(Number(engagementId), calculationHistory[CalculationStatus.Id]);
			intervalId.current = window.setInterval(() =>
			{
				loadCalculationStatus(Number(engagementId), calculationHistory[CalculationStatus.Id]);
			}, 60000);
		}

		if (!!calculationStatus && (calculationStatus === CalculationStatus.Processed ||
			calculationStatus === CalculationStatus.Failed) && intervalId.current !== undefined)
		{
			setIsCalcProcessing(false);
			props.setShowBanner(false);
			setIsRunCalcDisabled(false);
			clearInterval(intervalId.current);
			setRefershReportData(true);
			intervalId.current = undefined;
		}
	}, [calculationStatus]);

	useEffect(() =>
	{
		if (!!props.calcHistory)
		{
			processCalculationStatus(props.calcHistory);
		}
	}, [props.calcHistory]);

	useEffect(() =>
	{
		if (!!refershReportData)
		{
			props.onRefreshReport(Number(engagementId));
		}
	},
	[refershReportData]);

	return (
		<div className={'taxpayer-report'}>
			<div className={'tax-payer-header'}>
				<div className={'search'}>
					<Search
						searchType={'secondary'}
						onChange={onSearchTextChange}
						onClear={onSearchClear}
						onKeyDown={onSearchKeyDown}
					/>
				</div>
				<div className="report-buttons">
					<div>
						<Button
							kind='primary'
							icon={'Appkit4-icon icon-document-export-outline'}
							disabled={calculationStatus !== CalculationStatus.Processed}
							onClick={() => setDisplayExportReportModal(true)}>
							{t('exportReport')}
						</Button>
					</div>
					<div className="run-calc-container">
						<div className="loading">
							{isCalcProcessing && <Loading loadingType='circular' indeterminate={true} compact={true} />}
						</div>
						<div className="calc-button">
							<Tooltip
								trigger='hover'
								position='top-right'
								distance={4}
								id="tooltipDesc"
								content={renderRunCalcTooltip}
								style={{
									maxWidth: '300px'
								}}
							>
								<div>
									<Button
										kind='secondary'
										icon={!isCalcProcessing && 'Appkit4-icon icon-calculator-outline'}
										disabled={isRunCalcDisabled}
										onClick={handleRunCalculation}
									>
										{t('runCalculation')}
									</Button>
								</div>
							</Tooltip>
						</div>

					</div>
				</div>
			</div>
			<div className={'filters'}>
				<div>
					<DropdownFormControl
						field={'taxYearEnd'}
						labelKey={'name'}
						valueKey={'id'}
						placeholder={Utils.replaceTokens(t('taxYearEnd'))}
						isDisabled={!!isLoading}
						value={selectedTaxYearEnd}
						options={
							!!taxYearEndOptions &&
								!!taxYearEndOptions.length ?
								taxYearEndOptions.map((t): any =>
								{
									return {
										id: t,
										name: dateFormatter.format(
											dateFormatter.utcToEst(t),
											'MMM DD, YYYY'
										)

									};
								}) :
								[]
						}
						onChange={(_field, value) =>
						{
							setSelectedTaxYearEnd(value);
							setSelectedCurrencyConversionDate(value);
							loadTaxPayerReportRecords(value, selectedCurrencyId, value);
						}}
					/>
				</div>
				<div>
					<DropdownFormControl
						isSearchable
						field={'currency'}
						placeholder={Utils.replaceTokens(t('currency'))}
						labelKey={'name'}
						valueKey={'id'}
						isDisabled={!!isLoading}
						value={selectedCurrencyId}
						options={currencyOptions}
						onChange={(_field, value) =>
						{
							const currencyId = +value!;
							setSelectedCurrencyId(currencyId);
							loadTaxPayerReportRecords(selectedTaxYearEnd, currencyId, selectedCurrencyConversionDate);
						}}
					/>
				</div>
				<div>
					<CalendarFormControl
						field={'currencyConversionDate'}
						title={Utils.replaceTokens(t('currencyConversionDate'))}
						value={dateFormatter.utcToEst(selectedCurrencyConversionDate)}
						isDisabled={!!selectedCurrencyId && selectedCurrencyId === faCalculatingCurrencyId}
						onChange={(_field, value) =>
						{
							const conversion = dateFormatter.estDateStringOverrideToUtc(
								dateFormatter.estDateToString(value),
								false
							);

							// only update if the conversion date is different than what was already selected
							if (conversion !== selectedCurrencyConversionDate)
							{
								const currencyConversionDate = dateFormatter.estDateStringOverrideToUtc(
									dateFormatter.estDateToString(value)
								);

								setSelectedCurrencyConversionDate(currencyConversionDate);
								loadTaxPayerReportRecords(selectedTaxYearEnd, selectedCurrencyId, currencyConversionDate);
							}
						}}
					/>
				</div>
				<div>
					<DropdownFormControl
						field={'sepApplicable'}
						placeholder={Utils.replaceTokens(t('sepApplicable'))}
						labelKey={'name'}
						valueKey={'id'}
						options={[
							{
								id: 0,
								name: Utils.replaceTokens(t('sepApplicableNo'))
							},
							{
								id: 1,
								name: Utils.replaceTokens(t('sepApplicableYes'))
							}
						]}
						value={!!displaySEP ? 1 : 0}
						onChange={(_field, value) =>
						{
							setDisplaySEP(!!value);
						}}
					/>
				</div>
			</div>
			{
				!!selectedCurrencyId &&
				selectedCurrencyId !== faCalculatingCurrencyId &&
				<div className='overview-section'>
					<Accordion
						activeKeys={activeSummaryKeys}
						onClick={(activeKeys: string[]) =>
						{
							setActiveSummaryKeys(activeKeys);
						}}
					>
						<AccordionItem
							itemKey={'1'}
							title={Utils.replaceTokens(t('summaryTitle'))}
						>
							<div className={'overview-items'}>
								<div className={'item'}>
									<div className={'item-title'}>
										{
											Utils.replaceTokens(t('summaryCurrency'))
										}
									</div>
									<div>
										{
											!!currencyOptions &&
											!!currencyOptions.length &&
											!!currencyOptions.some((c) => c.id === selectedCurrencyId) &&
											<>
												{
													currencyOptions.find((c) => c.id === selectedCurrencyId)!.name
												}
											</>
										}
									</div>
								</div>
								<div className={'item'}>
									<div className={'item-title'}>
										{
											Utils.replaceTokens(t('summaryExemptSurplus'))
										}
									</div>
									<div>
										{
											getSummaryBalance(filteredTaxPayerRecords, 'exemptSurplusBalance', displaySEP)
										}
									</div>
								</div>
								<div className={'item'}>
									<div className={'item-title'}>
										{
											Utils.replaceTokens(t('summaryHybridSurplus'))
										}
									</div>
									<div>
										{
											getSummaryBalance(filteredTaxPayerRecords, 'hybridSurplusBalance', displaySEP)
										}
									</div>
								</div>
								<div className={'item'}>
									<div className={'item-title'}>
										{
											Utils.replaceTokens(t('summaryHUT'))
										}
									</div>
									<div>
										{
											getSummaryBalance(filteredTaxPayerRecords, 'hybridUnderlyingTaxBalance', displaySEP)
										}
									</div>
								</div>
								<div className={'item'}>
									<div className={'item-title'}>
										{
											Utils.replaceTokens(t('summaryTaxableSurplus'))
										}
									</div>
									<div>
										{
											getSummaryBalance(filteredTaxPayerRecords, 'taxableSurplusBalance', displaySEP)
										}
									</div>
								</div>
								<div className={'item'}>
									<div className={'item-title'}>
										{
											Utils.replaceTokens(t('summaryUFT'))
										}
									</div>
									<div>
										{
											getSummaryBalance(filteredTaxPayerRecords, 'underlyingForeignTaxBalance', displaySEP)
										}
									</div>
								</div>
							</div>
						</AccordionItem>
					</Accordion>
				</div>
			}
			<div className={'grid'}>
				<table>
					<thead>
						<tr>
							<td className={'affiliate-name'}>
								{
									Utils.replaceTokens(t('tableFA'))
								}
							</td>
							<td className={'currency'}>
								{
									Utils.replaceTokens(t('tableCurrency'))
								}
							</td>
							<td className={'numeric-header'}>
								{
									renderNumericFieldTitle(Utils.replaceTokens(t('tableExemptSurplus')))
								}
							</td>
							<td className={'numeric-header'}>
								{
									renderNumericFieldTitle(Utils.replaceTokens(t('tableHybridSurplus')))
								}
							</td>
							<td className={'numeric-header'}>
								{
									renderNumericFieldTitle(Utils.replaceTokens(t('tableHUT')))
								}
							</td>
							<td className={'numeric-header'}>
								{
									renderNumericFieldTitle(Utils.replaceTokens(t('tableTaxableSurplus')))
								}
							</td>
							<td className={'numeric-header'}>
								{
									renderNumericFieldTitle(Utils.replaceTokens(t('tableUFT')))
								}
							</td>
							<td className={'medium'}>
								{
									Utils.replaceTokens(t('tableCountry'))
								}
							</td>
							<td className={'medium'}>
								{
									Utils.replaceTokens(t('tableTaxYearEnd'))
								}
							</td>
							{
								!!selectedCurrencyId && selectedCurrencyId !== faCalculatingCurrencyId &&
								<td className={'medium'}>
									{
										!!currencies ?
											t('tableFxRate') + t('titleFxRateTo') +
											`${currencies.find((c) => c.id === selectedCurrencyId)!.code}` + t('titleFxRateAsOf') +
											` ${dateFormatter.format(dateFormatter.utcToEst(selectedCurrencyConversionDate),
												'MMM DD, YYYY')}` :
											t('tableFxRate') + t('titleFxRateAsOf') +
											dateFormatter.format(dateFormatter.utcToEst(selectedCurrencyConversionDate), 'MMM DD, YYYY')
									}
								</td>
							}
							{
								!!displaySEP &&
								<td className={'medium'}>
									{
										Utils.replaceTokens(t('tableSEPApplied'))
									}
								</td>
							}
						</tr>
					</thead>
					<tbody>
						{
							!isLoading &&
							!!filteredTaxPayerRecords &&
							!!filteredTaxPayerRecords.length &&
							filteredTaxPayerRecords
								.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage)
								.map((t) =>
								{
									return <tr key={t.id}>
										<td className={'affiliate-name'}>
											{
												t.affiliateName
											}
										</td>
										<td className={'currency'}>
											{
												!!currencies &&
												!!currencies.length &&
												!!currencies.some((c) => c.id === t.affiliateTaxYearEndCurrencyId) &&
												<>
													{
														currencies.find((c) => c.id === t.affiliateTaxYearEndCurrencyId)!.code
													}
												</>
											}
										</td>
										<td className={'numeric'}>
											{
												getBalanceValue(displaySEP, t.exemptSurplusBalance, t.taxpayerSEP)
											}
										</td>
										<td className={'numeric'}>
											{
												getBalanceValue(displaySEP, t.hybridSurplusBalance, t.taxpayerSEP)
											}
										</td>
										<td className={'numeric'}>
											{
												getBalanceValue(displaySEP, t.hybridUnderlyingTaxBalance, t.taxpayerSEP)
											}
										</td>
										<td className={'numeric'}>
											{
												getBalanceValue(displaySEP, t.taxableSurplusBalance, t.taxpayerSEP)
											}
										</td>
										<td className={'numeric'}>
											{
												getBalanceValue(displaySEP, t.underlyingForeignTaxBalance, t.taxpayerSEP)
											}
										</td>
										<td className={'medium'}>
											{
												!!countries &&
												!!countries.length &&
												!!countries.some((c) => c.id === t.affiliateTaxYearEndCountryId) &&
												<>
													{
														countries.find((c) => c.id === t.affiliateTaxYearEndCountryId)!.name
													}
												</>
											}
										</td>
										<td className={'medium'}>
											{
												dateFormatter.format(t.affiliateTaxYearEnd, 'MMM DD')
											}
										</td>
										{
											!!selectedCurrencyId &&
											selectedCurrencyId !== faCalculatingCurrencyId &&
											<td className={'fx-rate'}>
												{
													t.foreignExchangeRate
												}
											</td>
										}
										{
											!!displaySEP &&
											<td className={'numeric medium'}>
												{
													`${Utils.formatCurrency(`${t.taxpayerSEP}`, 2)}%`
												}
											</td>
										}
									</tr>;
								})
						}
					</tbody>
				</table>
			</div>
			<div className={'paging'}>
				<div className={'page-picker'}>
					<div className={'page-picker-text'}>
						{t('show')}
					</div>
					<div className={'page-selector'}>
						<div className={'dropdown-container'}>
							<div className={'page-picker-text highlight'} onClick={() => setIsPagePickerExpanded(!isPagePickerExpanded)}>
								{recordsPerPage}
							</div>
							{isPagePickerExpanded &&
								<div className="dropdown-menu">
									{pagingOptions.map((option: any) =>
										<div className="dropdown-item" onClick={() => handleRecordsPerPage(option)}>
											{option}
										</div>)}
								</div>}
						</div>
						<div className={'icons'}>
							{isPagePickerExpanded ? (
								<span
									className="Appkit4-icon icon-up-chevron-outline"
									onClick={() => setIsPagePickerExpanded(false)}
								></span>
							) : (
								<span
									className="Appkit4-icon icon-down-chevron-outline"
									onClick={() => setIsPagePickerExpanded(true)}
								></span>
							)}
						</div>
					</div>
					<div className={'page-picker-text'}>
						{t('itemPerPage')}
					</div>
				</div>

				<Pagination
					defaultCurrent={currentPage}
					total={Math.ceil(filteredTaxPayerRecords.length / recordsPerPage)}
					onPageChange={(page) =>
					{
						// ensure the page is within the bounds of min (1) and max pages
						const maxPage = Math.ceil(filteredTaxPayerRecords.length / recordsPerPage);
						setCurrentPage(Math.max(1, Math.min(page, maxPage)));
					}}
				/>
			</div>

			<ExportReportForm
				displayModal={displayExportReportModal}
				refreshData={refershReportData}
				currencyOptions={currencyOptions}
				onToggleDisplayModal={(display: any) =>
				{
					setDisplayExportReportModal(!!display);
				}}
			/>
		</div>
	);
};