import React, { useEffect, useState } from 'react';

import {
	Button,
	Modal,
	Select
} from '@appkit4/react-components';

import './BulkEditFAModal.scss';
import { IForeignAffiliate } from '../../../../models/IForeignAffiliate';
import { UnsavedChangesModal } from 'src/core/components/Dashboard/unsavedModal/UnsavedChangesModal';
import { useTranslation } from 'react-i18next';
import {
	SelectValue
} from '@appkit4/react-components/esm/combobox/Combobox';
import useDisableBodyScroll from 'src/common/hooks/useDisableBodyScroll';
import { useAppSelector } from 'src/store/store';
import { CalendarPickerWrapper } from 'src/common/components/CalendarPickerWrapper/CalendarPickerWrapper';
import { FAEditingWarningModal } from '../../../FAEditingWarningModal/FAEditingWarningModal';

interface BulkEditProps {
  clientId: number | undefined;
  engagementId: number | undefined;
  selectedItems: any[];
  visible: boolean;
  setVisible: Function;
  bulkUpdate: Function;
  fieldErrors: Record<string, any>;
  isBulkUpdated: boolean;
  setFaErrors: Function;
  showDialog: boolean;
  cancelNavigation: Function;
  confirmNavigation: Function;
}

const initForeignAffiliate: IForeignAffiliate = {
	affiliateId: 0,
	name: '',
	currencyId: 0,
	countryId: 0,
	taxationYearEnd: undefined,
	dateBecameFa: undefined,
	analysisStartDate: undefined,
	inEditableMode: false,
	isNewItem: false,
	selected: false,
	showedWarning: false
};

export const BulkEditFAModal = (props: BulkEditProps) =>
{
	const [foreignAffiliate, setForeignAffiliate] =
    useState<IForeignAffiliate>(initForeignAffiliate);
	const [showUnsavedModal, setShowUnsavedModal] = useState<boolean>(false);
	const [moreOrLess, setMoreOrLess] = useState<number>(0);
	const [fiveRowHeight, setFiveRowHeight] = useState<number>(0);
	const { visible, setVisible } = props;
	const [badgeHeight, setBadgeHeight] = useState<string>('auto');
	const [selectedItems, setSelectedItems] = useState<IForeignAffiliate[]>([]);

	const countries = useAppSelector((state) => state.appSettings.countries);
	const currencies = useAppSelector((state) => state.appSettings.currencies);

	const { t } = useTranslation('homeDashboard');
	useDisableBodyScroll(visible);

	useEffect(() =>
	{
		setSelectedItems(props.selectedItems);
	}, [props.selectedItems]);

	useEffect(() =>
	{
		if (!!props.isBulkUpdated)
		{
			setForeignAffiliate(initForeignAffiliate);
		}
	}, [props.isBulkUpdated]);

	const calcuateRows = () =>
	{
		const parent = document.getElementById('fa-list');
		const children = parent?.getElementsByClassName('badge-wrapper');

		const tops = [];
		if (children)
		{
			for (let i = 0; i < children.length; i++)
			{
				const child = children[i];
				const rect = child.getBoundingClientRect();
				tops.push(rect.top);
			}
			const rows = [...new Set(tops)];
			if (rows.length > 5)
			{
				if (moreOrLess < 2)
				{
					const newHeight = rows[5] - rows[0];
					setFiveRowHeight(newHeight);
					setBadgeHeight(`${newHeight}px`);
					setMoreOrLess(1);
				}
			}
			else
			{
				setMoreOrLess(0);
				setBadgeHeight('auto');
			}
		}
		else
		{
		}
	};

	useEffect(() =>
	{
		if (props.visible && selectedItems.length > 0)
		{
			setTimeout(() =>
			{
				calcuateRows();
			}, 2);
		}
	}, [selectedItems, props.visible]);

	useEffect(() =>
	{}, [props.visible]);

	const closeUnsavedModal = (str: string) =>
	{
		if (str === 'stay')
		{
			setShowUnsavedModal(false);
		}
		else if (str === 'leave')
		{
			setShowUnsavedModal(false);
			setSelectedItems(props.selectedItems);
			setVisible(false);
			setForeignAffiliate(initForeignAffiliate);
			setMoreOrLess(0);
			props.setFaErrors(undefined);
		}
	};

	if (props?.visible === false)
	{
		return null;
	}

	const hasChanges = () =>
	{
		if (
			!foreignAffiliate.countryId &&
      !foreignAffiliate.currencyId &&
      !foreignAffiliate.taxationYearEnd &&
      !foreignAffiliate.dateBecameFa &&
      !foreignAffiliate.analysisStartDate
		)
		{
			return false;
		}
		else
		{
			return true;
		}
	};

	const onCancel = () =>
	{
		if (hasChanges())
		{
			setShowUnsavedModal(true);
		}
		else
		{
			setSelectedItems(props.selectedItems);
			setMoreOrLess(0);
			setVisible(false);
			setForeignAffiliate(initForeignAffiliate);
			props.setFaErrors(undefined);
		}
	};

	const onSave = () =>
	{
		setMoreOrLess(0);
		props.bulkUpdate(selectedItems, foreignAffiliate);
	};

	const onValueChange = (field: string, value: any) =>
	{
		const updatedFA = {
			...foreignAffiliate,
			[field]: value
		};
		setForeignAffiliate(updatedFA);
	};

	const showMoreorLess = () =>
	{
		if (moreOrLess === 1)
		{
			setMoreOrLess(2);
			setBadgeHeight('auto');
		}
		if (moreOrLess === 2)
		{
			setMoreOrLess(1);
			setBadgeHeight(`${fiveRowHeight}px`);
		}
	};

	const removeFA = (affiliateId: number) =>
	{
		const updatedList = selectedItems.filter((item: any) => item.affiliateId !== affiliateId);
		setSelectedItems(updatedList);
	};

	return (
		<div>
			<Modal
				className="bulk-edit-fa-modal"
				initialFocus={false}
				visible={props.visible}
				closeOnPressEscape={false}
				maskCloseable={false}
				closable={true}
				title={'Edit Foreign Affiliate Information'}
				onCancel={onCancel}
				footer={
					<>
						<Button kind="secondary" onClick={onCancel}>
              Cancel
						</Button>
						<Button
							onClick={onSave}
							disabled={selectedItems.length === 0 || hasChanges() === false}
						>
              Save
						</Button>
					</>
				}
			>
				<div className="bulk-edit-fa-body">
					<div className="title">Selected Foreign Affiliates</div>
					<div className="fa-list" id="fa-list" style={{
						height: badgeHeight
					}}>
						{selectedItems.map((item: any) =>
						{
							return (
								<div className="badge-wrapper" key={item.id}>
									<div className="left">
										{' '}
										<span className="selected-fa" key={`${item.id}`}>
											{item.name}
										</span>
									</div>
									<div className="right">
										{' '}
										<span
											className="Appkit4-icon icon-close-outline ap-font-14"
											onClick={() => removeFA(item.affiliateId)}
										></span>
									</div>
								</div>
							);
						})}
					</div>
					<div>
						{moreOrLess > 0 && (
							<Button className='link-button showMoreButton'
								kind='text' onClick={showMoreorLess}>
							   {moreOrLess === 1 ? 'Show more' : 'Show less'}
						   </Button>
						)}
					</div>
					<div className="fa-form">
						<div className="field combo-dropdown">
							<Select
								data={countries}
								valueKey={'id'}
								labelKey="name"
								value={foreignAffiliate.countryId}
								placeholder={'Country'}
								dropdownAlwaysDown={false}
								onSelect={(vals: SelectValue) => onValueChange('countryId', vals)}
								dropdownRenderMode={'portal'}
							/>
							<div className="appending-icon">
								{foreignAffiliate.countryId  ? (
									<>
										<i
											className="Appkit4-icon icon-circle-delete-outline "
											onClick={() => onValueChange('countryId', '')}
										></i>
										<span className="separte-line" />
									</>
								) : (
									<div className="whitespace">{''}</div>
								)}
							</div>
						</div>
						<div className="field combo-dropdown">
							<Select
								data={currencies}
								valueKey="id"
								labelKey="code"
								placeholder={'Calculating Currency'}
								value={foreignAffiliate.currencyId}
								dropdownAlwaysDown={false}
								onSelect={(vals) => onValueChange('currencyId', vals)}
								dropdownRenderMode={'portal'}
							/>
							<div className="appending-icon">
								{foreignAffiliate.currencyId ? (
									<>
										<i
											className="Appkit4-icon icon-circle-delete-outline "
											onClick={() => onValueChange('currencyId', '')}
										></i>
										<span className="separte-line" />
									</>
								) : (
									<div className="whitespace">{''}</div>
								)}
							</div>
						</div>
						<div className="field">
							<CalendarPickerWrapper
								format={'MMM DD, YYYY'}
								editable={false}
								disabled={false}
								locale="en"
								fieldTitle="Taxation Year End of Foreign Affiliate"
								fieldWidth={'100%'}
								value={foreignAffiliate.taxationYearEnd}
								onChange={(val: Date) => onValueChange('taxationYearEnd', val)}
								datePanelHorizontalAlign="right"
								datepickerAlwaysDown={false}
								minDate={new Date('1972,1,1')}
							/>
						</div>
						<div className="field">
							<CalendarPickerWrapper
								format={'MMM DD, YYYY'}
								editable={false}
								disabled={false}
								locale="en"
								fieldTitle="Date Corporation became Foreign Affiliate"
								fieldWidth={'100%'}
								value={foreignAffiliate.dateBecameFa}
								onChange={(val: Date) => onValueChange('dateBecameFa', val)}
								datePanelHorizontalAlign="right"
								datepickerAlwaysDown={false}
								minDate={new Date('1972,1,1')}
							/>
						</div>
						<div className="field">
							<CalendarPickerWrapper
								format={'MMM DD, YYYY'}
								editable={false}
								disabled={false}
								locale="en"
								fieldTitle="Analysis Start Date"
								fieldWidth={'100%'}
								value={foreignAffiliate.analysisStartDate}
								onChange={(val: Date) => onValueChange('analysisStartDate', val)}
								datePanelHorizontalAlign="right"
								datepickerAlwaysDown={false}
								minDate={new Date('1972,1,1')}
							/>
						</div>
						{!!props.fieldErrors && !!props.fieldErrors.clientSideErr?.analysisStartDate &&
							<div className="custom-error">
								{props.fieldErrors.clientSideErr?.analysisStartDate}
							</div>
						}
					</div>
				</div>
			</Modal>
			<FAEditingWarningModal
				showDialog={props.showDialog}
				cancelNavigation={props.cancelNavigation}
				confirmNavigation={props.confirmNavigation}
			></FAEditingWarningModal>
			<UnsavedChangesModal
				visible={showUnsavedModal}
				onClose={closeUnsavedModal}
				title={t('unsaved-popup.discardChangesTitle') || ''}
			/>
		</div>
	);
};
