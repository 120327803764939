import {
	IRetainedEarningsRow
} from 'src/common/types/interfaces/IRetainedEarnings';

export const gridIds = {
	header: 'headerTable',
	faTaxationDetails: 'faTaxationDetailsTable',
	retainedEarnings: 'retainedEarnings',
	netIncomeOrLoss: 'netIncomeOrLoss',
	aggregateTable: 'AggregateTable',
	allRecords: 'allRecords'
};

export type ValuesByYear = {
	[yearEnd: string]: IRetainedEarningsRow[]
}

export type ValuesByAffiliateId = {
	[affiliateId: number]: IRetainedEarningsRow[]
}

export type ValuesByDescriptionYear = {
	[descriptionId: string]: ValuesByYear
}

export type RowsByGridType = { [gridId: string]: IRetainedEarningsRow[][] }
export type RecordsByGridType = { [gridId: string]: ValuesByAffiliateId }

export const initialRowData: RowsByGridType = {

	[gridIds.faTaxationDetails]: [],
	[gridIds.retainedEarnings]: [],
	[gridIds.netIncomeOrLoss]: []
};

export const initialRecordData: RecordsByGridType = {

	[gridIds.faTaxationDetails]: {
	},
	[gridIds.retainedEarnings]: {
	},
	[gridIds.netIncomeOrLoss]: {
	}
};