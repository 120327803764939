import React from 'react';
import { ReportUtil } from 'src/features/engagement/utils/ReportUtil';
import ReportCell from './ReportCell';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@appkit4/react-components';

const ReportRow = (props: {reportData: any, field: any, i18nKey: string, displayNotes?: boolean, totalAmounts?: any}) =>
{
	const { t } = useTranslation(
		'report',
		{
			keyPrefix: props.i18nKey
		}
	);

	const renderReportRow = (reportData: any, field: any, totalAmounts?: any) =>
	{
		return (
			<tr className={`${ReportUtil.getRowClassName(field)}`}>
				<td className={`${!!field?.background ? field?.background : ''}`}>
					{`${!!field.name ? t(field.name) : ''}`}
					{!!field?.tooltip &&
						<Tooltip
							distance={4}
							position={'top'}
							appendAfterTarget
							content={t(field.tooltip) || ''}
						>
							<span className={'Appkit4-icon icon-help-question-outline'} />
						</Tooltip>
					}
				</td>
				{!!totalAmounts && <td className={`${!!field?.background ? field?.background : ''}`}>
					<ReportCell
						data={totalAmounts}
						field={field} />
				</td>}
				{
					reportData.map((item: any) =>
					{
						return (
							<td className={`${!!field?.background ? field?.background : ''}`}>
								<ReportCell
									data={item}
									field={field}
									displayNotes={props.displayNotes}/>
							</td>
						);
					})
				}
			</tr>
		);
	};

	return (
		<>
			{renderReportRow(props.reportData, props.field, props.totalAmounts)}
		</>
	);
};

export default ReportRow;