import { Button, Modal } from '@appkit4/react-components';
import { useTranslation } from 'react-i18next';
import './DeleteEngagementModal.scss';

export const DeleteEngagementModal = (props: {
	visible: boolean;
	engagement: any;
	onDeleteEngagement: (ToDelete: Boolean) => void;
}) =>
{
	const { t } = useTranslation('homeDashboard');
	return (
		<>
			<Modal
				className="delete-engagement-modal"
				visible={props.visible}
				title={t('deleteEngagementModal.title') || ''}
				maskCloseable={false}
				ariaLabel={'Lorem ipsum dolor sit'}
				onCancel={() =>
				{
					props.onDeleteEngagement(false);
				}}
				header={''}
				icons={''}
				footer={
					<>
						<Button
							className="delete-engagement-cancel"
							onClick={() =>
							{
								props.onDeleteEngagement(false);
							}}
							kind="secondary"
						>
							{t('deleteEngagementModal.cancel')}
						</Button>
						<Button
							onClick={() =>
							{
								props.onDeleteEngagement(true);
							}}
							kind="negative"
						>
							{t('deleteEngagementModal.delete')}
						</Button>
					</>
				}
				bodyStyle={{
					minHeight: '92px'
				}}
			>
				<div className="delete-engagement-message">
					{t('deleteEngagementModal.messagePart1')} "{props.engagement?.name}" {t('deleteEngagementModal.messagePart2')}
				</div>
			</Modal>
		</>
	);
};
