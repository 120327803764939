import React, {
	useState,
	useEffect,
	KeyboardEvent,
	useRef,
	useCallback
} from 'react';
import { UserService } from '../services/userService';

import './UserManagement.scss';
import './UserHeader.scss';
import {
	IntlProvider,
	LocalizationProvider,
	loadMessages
} from '@progress/kendo-react-intl';

import { useTranslation } from 'react-i18next';
import {
	Grid,
	GridColumn,
	GridItemChangeEvent,
	GridPageChangeEvent,
	GridSortChangeEvent,
	GridToolbar
} from '@progress/kendo-react-grid';

import esMessages from './../../../i18n/en/en-user.json';
import { Button, Search } from '@appkit4/react-components';
import { UserFilterModal } from './UserFilterModal';
import { IUserFilter } from '../models/IUserFilter';
import { SortDescriptor } from '@progress/kendo-data-query';
import { SortableHeaderCell } from 'src/common/components/Renderers/SortableHeaderCell';
import { RoleEditor } from 'src/common/components/Editors/RoleEditor/RoleEditor';
import { UserModal } from './addUserModal/UserModal';
import { DeleteUserModal } from './DeleteUserModal';
import { useAppSelector } from 'src/store/store';
import { RoleEnum } from 'src/common/types/enums/RoleEnum';
import { HttpStatusCode } from 'axios';
import { useNavigate } from 'react-router-dom';
loadMessages(esMessages, 'en-user');

const initialSort: SortDescriptor[] = [
	{
		field: 'email',
		dir: 'asc'
	}
];

export const UserManagement = () =>
{
	const { t } = useTranslation('user');
	const userProfile = useAppSelector((state) => state.userProfile.profile);

	const [sort, setSort] = React.useState<SortDescriptor[]>(initialSort);

	const initUserFilter: IUserFilter = {
		pageIndex: 1,
		pageSize: 10,
		searchText: '',
		roles: [],
		offices: [],
		orderBy: 'email asc'
	};
	const [searchText, setSearchText] = useState<string>('');
	const [userFilter, setUserFilter] = useState<IUserFilter>(initUserFilter);
	const [showUserModal, setShowUserModal] = useState<boolean>(false);
	const [userModalContext, setUserModalContext] = useState<Record<
    string,
    any
  > | null>(null);

	const [rowData, setRowData] = useState<any[]>([]);
	const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);

	const [total, setTotal] = useState(0);

	const [showFilters, setShowFilters] = useState(false);
	const [activeFilterData, setActiveFilterData] = useState<string[]>([]);
	const [showMoreCount, setShowMoreCount] = useState<number>(0);
	const navigate = useNavigate();
	const gridRef = useRef<HTMLDivElement | null>(null);

	const loadUsers = useCallback(() =>
	{
		UserService.searchUsers(userFilter)
			.then((res: any) =>
			{
				setRowData(res.data.data);
				setTotal(res.data.total);
			})
			.catch((err: any) =>
			{
				if (!!err && !!err.response && err.response.status ===  HttpStatusCode.Forbidden)
				{
					navigate('/access-denied');
				}
			});
	}, [userFilter]);

	useEffect(() =>
	{
		if (userProfile?.role === RoleEnum.BusinessOwner)
		{
			loadUsers();
		}
	}, [loadUsers, userProfile]);

	useEffect(() =>
	{
		const allFilterVals = [...userFilter.roles, ...userFilter.offices];
		calcOverflowBadgesAndSetShowMore(allFilterVals);
	}, [userFilter]);

	const itemChange = (event: GridItemChangeEvent) =>
	{
		const inEditId = event.dataItem.email;
		const field = event.field || '';
		const newData = rowData.map((row: any) =>
			row.email === inEditId ? {
				...row,
				[field]: event.value
			} : row
		);
		setRowData(newData);
	};

	const addUser = () =>
	{
		setUserModalContext(null);
		setShowUserModal(true);
	};

	const closeUserModal = () =>
	{
		setShowUserModal(false);
		setUserModalContext(null);
	};

	const closeDeleteUserModal = (toDelete: boolean, email: any) =>
	{
		setShowDeleteUserModal(false);
		setUserModalContext(null);
		if (toDelete)
		{
			UserService.deleteUser(email).then(() =>
			{
				loadUsers();
			});
		}
	};

	const showUserModalForEdit = (dataItem: any) =>
	{
		setUserModalContext(dataItem);
		setShowUserModal(true);
	};

	const showUserModalForDelete = (dataItem: any) =>
	{
		setUserModalContext(dataItem);
		setShowDeleteUserModal(true);
	};

	const onCloseFilters = (
		result: boolean,
		roles: string[],
		offices: string[]
	) =>
	{
		setShowFilters(false);
		if (result)
		{
			setUserFilter((prev: IUserFilter) => ({
				...prev,
				roles: roles,
				offices: offices
			}));
		}
	};

	const onFilters = () =>
	{
		setShowFilters(true);
	};

	const onRemoveOneFilter = (filter: string) =>
	{
		if (userFilter.roles.find((role: string) => role === filter))
		{
			setUserFilter((prev: IUserFilter) => ({
				...prev,
				roles: prev.roles.filter((role: string) => role !== filter)
			}));
		}
		if (userFilter.offices.find((office: string) => office === filter))
		{
			setUserFilter((prev: IUserFilter) => ({
				...prev,
				offices: prev.offices.filter((office: string) => office !== filter)
			}));
		}
	};

	const onClearAllFilters = () =>
	{
		setUserFilter((prev: IUserFilter) => ({
			...prev,
			roles: [],
			offices: []
		}));
	};

	const sortChange = (event: GridSortChangeEvent) =>
	{
		setSort(event.sort);
		if (event.sort.length > 0)
		{
			setUserFilter((prev: IUserFilter) => ({
				...prev,
				orderBy: `${event.sort[0].field} ${event.sort[0].dir}`
			}));
		}
	};

	const pageChange = (event: GridPageChangeEvent) =>
	{
		setUserFilter((prev: IUserFilter) => ({
			...prev,
			pageIndex: Math.floor(event.page.skip / event.page.take) + 1,
			pageSize: event.page.take
		}));
	};

	const onClearSearch = () =>
	{
		setUserFilter((prev: IUserFilter) => ({
			...prev,
			searchText: ''
		}));
	};

	const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) =>
	{
		if (event.key === 'Enter')
		{
			setUserFilter((prev: IUserFilter) => ({
				...prev,
				searchText: searchText
			}));
		}
	};

	const handleSaveUserData = (email: string, role: string) =>
	{
		UserService.addUser(email, role)
			.then((res: any) =>
			{
				const { data } = res;
				setRowData([...rowData, data]);
				closeUserModal();
			})
			.catch((err: any) =>
			{});
	};

	const handleUpdateUserData = (email: string, role: string) =>
	{
		UserService.updateUser(email, role)
			.then((res: any) =>
			{
				const { data } = res;
				const newData = rowData.map((item: any) =>
					item.email === email ?
						{
							...item,
							email: data.email,
							role: data.role
						} :
						item
				);

				setRowData(newData);
				closeUserModal();
			})
			.catch((err: any) =>
			{});
	};

	const calcOverflowBadgesAndSetShowMore = (allFilterVals: Array<string>) =>
	{
		const gridWidth = gridRef.current?.offsetWidth;
		const canvas = document.createElement('canvas');
		const context = canvas.getContext('2d');
		const fontSize = 16;
		var filterWidth = 0;
		var count = 0;
		if (context)
		{
			context.font = `${fontSize}px PwC Helvetica Neue`; // Set the font and size
			for (var i = 0; i < allFilterVals.length; i++)
			{
				const width = context.measureText(allFilterVals[i]).width;
				filterWidth = filterWidth + width + 35;

				if (gridWidth)
				{
					if (filterWidth > gridWidth - 570)
					{
						count = i;
						break;
					}
				}
			}
		}

		if (count > 0)
		{
			setShowMoreCount(allFilterVals.length - count + 1);
			const filtersToShow = allFilterVals.slice(0, count - 1);
			setActiveFilterData(filtersToShow);
		}
		else
		{
			setShowMoreCount(0);
			setActiveFilterData(allFilterVals);
		}
	};

	return (
		<div style={{
			height: '100%'
		}}>
			<LocalizationProvider language="en-user">
				<IntlProvider locale="en">
					<Grid
						className={`user-grid ${
							total === 0 ? 'grid-pageSize-disable' : ''
						}`}
						style={{
							height: 'fit-content',
							width: '100%'
						}}
						data={rowData}
						skip={(userFilter.pageIndex - 1) * userFilter.pageSize}
						take={userFilter.pageSize}
						total={total === 0 ? 1 : total}
						sortable={true}
						sort={sort}
						onSortChange={sortChange}
						pageable={{
							buttonCount: 5,
							type: 'input',
							info: true,
							pageSizes: [10, 25, 50, 100],
							pageSizeValue: userFilter.pageSize
						}}
						onPageChange={pageChange}
						onItemChange={itemChange}
					>
						<GridToolbar>
							{userProfile?.role === RoleEnum.BusinessOwner && (
								<div ref={gridRef} className="user-header">
									<div className="user-header-left">
										<div className="user-header-search">
											<Search
												searchType="secondary"
												searchValue={searchText}
												onChange={(value) =>
												{
													setSearchText(value);
												}}
												onClear={onClearSearch}
												onKeyDown={onKeyDown}
											/>
										</div>
										<div className="user-header-filters">
											{activeFilterData.map((afData: string) =>
											{
												return (
													<div key={afData}>
														{afData}
														<Button
															kind="text"
															onClick={() => onRemoveOneFilter(`${afData}`)}
														>
															<span className="Appkit4-icon icon-close-outline ap-font-12"></span>
														</Button>
													</div>
												);
											})}
											{showMoreCount > 0 && (
												<div className="show-more-div">
													{`+${showMoreCount} ${'more'}`}
												</div>
											)}
										</div>
									</div>

									<div className="user-header-button user-header-filter">
										<Button kind="text" onClick={onFilters}>
											{t('button.filters')}
										</Button>
									</div>
									<div className="user-header-separate">&nbsp;</div>
									<div className="user-header-button user-header-filter">
										<Button
											kind="text"
											onClick={onClearAllFilters}
											disabled={
												userFilter.roles.length === 0 &&
                        userFilter.offices.length === 0
											}
										>
											{t('button.clear-filters')}
										</Button>
									</div>
									<div className="user-header-button">
										<Button
											kind="primary"
											icon="icon-plus-outline"
											onClick={addUser}
										>
											{t('button.add-user')}
										</Button>
									</div>
								</div>
							)}
						</GridToolbar>
						<GridColumn
							field="firstName"
							title={t('column.username') || ''}
							editable={false}
							sortable={{
								allowUnsort: false
							}}
							width={'200px'}
							headerCell={SortableHeaderCell}
						/>
						<GridColumn
							field="email"
							title={t('column.email') || ''}
							editor="text"
							editable={false}
							sortable={true}
							headerCell={SortableHeaderCell}
							width={'285px'}
						/>
						<GridColumn
							field="role"
							title={t('column.role') || ''}
							editor="text"
							cell={RoleEditor}
							sortable={false}
							width={'195px'}
						/>
						<GridColumn
							field="office"
							title={t('column.office') || ''}
							editable={false}
							sortable={false}
							width={'195px'}
						/>
						<GridColumn
							width={'80px'}
							title={t('column.actions') || ''}
							headerCell={(props) => (
								<span className="k-cell-inner">
									<span
										className="k-link !k-cursor-default"
										style={{
											display: 'flex',
											justifyContent: 'end'
										}}
									>
										<span className="action-column"> {props.title}</span>
									</span>
								</span>
							)}
							cell={(props: any) => (
								<td
									className="k-table-td"
									style={{
										borderLeft: 'transparent',
										borderBottom: '1px solid red !important',
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'flex-end'
									}}
								>
									<Button
										style={{
											padding: '5px',
											width: '45%',
											backgroundColor: 'transparent'
										}}
										className="user-action-button"
										kind="tertiary"
										compact
										icon="Appkit4-icon icon-edit-outline icon-style"
										onClick={() => showUserModalForEdit(props.dataItem)}
									></Button>
                  &nbsp;
									<Button
										style={{
											padding: '5px',
											width: '45%',
											backgroundColor: 'transparent'
										}}
										className="user-action-button"
										compact
										kind="tertiary"
										icon="Appkit4-icon icon-delete-outline"
										onClick={() => showUserModalForDelete(props.dataItem)}
									></Button>
								</td>
							)}
							sortable={false}
						/>
					</Grid>
				</IntlProvider>
			</LocalizationProvider>

			<UserModal
				visible={showUserModal}
				onClose={closeUserModal}
				userModalContext={userModalContext}
				handleSaveUserData={handleSaveUserData}
				handleUpdateUserData={handleUpdateUserData}
			/>

			<DeleteUserModal
				visible={showDeleteUserModal}
				row={userModalContext}
				onClose={closeDeleteUserModal}
			></DeleteUserModal>
			<div id="user-management-floating-div"></div>
			<UserFilterModal
				visible={showFilters}
				roles={userFilter.roles}
				offices={userFilter.offices}
				onClose={onCloseFilters}
			></UserFilterModal>
		</div>
	);
};
