export enum RetainedEarningsFields {
	openingRetainedEarnings = 'openingRetainedEarnings',
	netIncomeOrLossAfterTax = 'netIncomeOrLossAfterTax',
	endingRetainedEarnings = 'endingRetainedEarnings',
	endingRetainedEarningsOnFinancialStatements = 'endingRetainedEarningsOnFinancialStatements',
	netIncomeOrLossBeforeTax = 'netIncomeOrLossBeforeTax',
	netIncomeOrLossFromTaxReturn = 'netIncomeOrLossFromTaxReturn',
	unreconciledDifference = 'unreconciledDifference',
	retainedEarningsSummary = 'retainedEarningsSummary',
	netIncomeOrLossSummary = 'netIncomeOrLossSummary',
	retainedEarnings = 'retainedEarnings',
	netIncomeOrLoss = 'netIncomeOrLoss'
}

export enum RetainedEarningsFieldNames {
	Amount = 'amount',
	Notes = 'notes'
}

export enum RetainedEarningsDifferenceTypes {

	NetIncomeReconciliation = 'NetIncomeReconciliation',
	NetIncomeReconciliationNetIncomeAfterTax = 'NetIncomeReconciliationNetIncomeAfterTax',
	NetIncomeReconciliationNetIncomeBeforeTax = 'NetIncomeReconciliationNetIncomeBeforeTax',
	NetIncomeReconciliationNetIncomeLossFromTaxReturn = 'NetIncomeReconciliationNetIncomeLossFromTaxReturn',
	NetIncomeReconciliationUnreconciledDifference = 'NetIncomeReconciliationUnreconciledDifference',
	RetainedEarningsReconciliation = 'RetainedEarningsReconciliation',
	RetainedEarningsReconciliationEndingRetainedEarnings = 'RetainedEarningsReconciliationEndingRetainedEarnings',
	EndingRetainedEarningsOnFinancialStatement =
		'RetainedEarningsReconciliationEndingRetainedEarningsOnFinancialStatement',
	RetainedEarningsReconciliationNetIncomeAfterTax = 'RetainedEarningsReconciliationNetIncomeAfterTax',
	RetainedEarningsReconciliationOpeningRetainedEarnings = 'RetainedEarningsReconciliationOpeningRetainedEarnings',
	RetainedEarningsReconciliationUnreconciledDifference = 'RetainedEarningsReconciliationUnreconciledDifference'
}

export enum RetainedEarningsRowBaseFieldNames {
	Id = 'id',
	RowIndex = 'rowIndex',
	DifferenceTypeId = 'differenceTypeId',
	Amount = 'amount',
	Notes = 'notes'
}