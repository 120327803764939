import { useState } from 'react';
import { Modal, Button } from '@appkit4/react-components';
import { useTranslation } from 'react-i18next';
import { IConfirmationModalProps } from './IConfirmationModalProps';
import { Utils } from 'src/common/utils/utils';

import './ConfirmationModal.scss';

export const ConfirmationModal = (props: IConfirmationModalProps) =>
{
	const { t } = useTranslation(
		'common',
		{
			keyPrefix: 'confirmationModal'
		}
	);

	const [disableIcon, setDisableIcon] = useState<boolean>(false);

	const preventDoubleClick = async () =>
	{
		setDisableIcon(true);

		await Utils.timeout(3000);

		setDisableIcon(false);
	};

	return <>
		<Modal
			tabIndex={-1}
			className={'confirmation-modal'}
			visible={!!props.visible}
			initialFocus={false}
			closeOnPressEscape={false}
			maskCloseable={false}
			title={
				!!props.title ?
					props.title :
					t('defaultTitle') || ''
			}
			onCancel={props.onClose}
			footer={
				<div className={'footer-wrapper'}>
					<Button
						tabIndex={0}
						kind={'secondary'}
						onClick={props.onClose}
					>
						{
							t('cancelButtonText')
						}
					</Button>
					<div
						onClick={preventDoubleClick}
					>
						<Button
							kind={'primary'}
							disabled={disableIcon}
							onClick={props.onContinue}
						>
							{
								t('saveButtonText')
							}
						</Button>
					</div>
				</div>
			}
		>
			<div>
				{
					!!props.onRenderMessage ?
						props.onRenderMessage() :
						<p>
							{
								!!props.message ?
									props.message :
									t('defaultMessage')
							}
						</p>
				}
			</div>
		</Modal>
	</>;
};
