export enum ForeignTaxesFields {
	corporateIncomeTax = 'incomeTaxesPaid-corporateIncomeTax',
	withholdingTaxCredit = 'incomeTaxesPaid-withholdingTaxCredit',
	groupTaxAdjustment = 'incomeTaxesPaid-groupTaxAdjustment',
	otherCorporateTax = 'incomeTaxesPaid-otherCorporateTax',
	totalIncomeTaxesPaid = 'incomeTaxesPaid-total',
	incomeFapi = 'taxableIncomes-fapi',
	incomeCapitalGains = 'taxableIncomes-capitalGains',
	incomeABINonDTC = 'taxableIncomes-abiNonDTC',
	incomeDeemedABIDTC = 'taxableIncomes-deemedABIDTC',
	incomeDeemedABINonDTC =	'taxableIncomes-deemedABINonDTC',
	incomeAbidtc =	'taxableIncomes-abidtc',
	totalTaxableIncome=	'taxableIncomes-total',
	allocationFapi = 'taxAllocations-fapi',
	allocationCapitalGainsInHybridSurplus = 'taxAllocations-capitalGainsInHybridSurplus',
	allocationABINonDTC = 'taxAllocations-abiNonDTC',
	allocationDeemedABIDTC = 'taxAllocations-deemedABIDTC',
	allocationDeemedABINonDTC =	'taxAllocations-deemedABINonDTC',
	allocationAbidtc = 'taxAllocations-abidtc',
	totalTaxAllocation = 'taxAllocations-total',
	taxPaidButDeniedFTXCredit = 'taxPaidButDeniedFTXCredit'
}