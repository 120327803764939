import { Utils } from 'src/common/utils/utils';
import { Reg5907AdjustmentsTypes } from 'src/common/types/enums/Reg5907Adjustments';

export enum Reg5907AdjustmentsLookupTypes {

	AllTaxPayers = 'AllTaxPayers',
	TaxPayerOnly = 'TaxPayerOnly'
}

export interface IReg5907AdjustmentsLookupRecord {

	id: number;
	differenceType: Reg5907AdjustmentsLookupTypes;
	description: string;
}

export interface IReg5907AdjustmentsRowBase {

	id: number;
	rowIndex: number|undefined,
	descriptionId: number;
	amount?: number;
	notes?:  string;
	taxPayerId: number|undefined;
	foreignTaxableIncomeId:  number|undefined;
}

export interface IReg5907AdjustmentsRow extends IReg5907AdjustmentsRowBase {
	taxYearEnd?: string;
	affiliateTaxYearEndId: number;
	calculatingCurrencyId: number;
}

export class Reg5907AdjustmentsRow implements IReg5907AdjustmentsRow
{
	constructor (
		{
			descriptionId = 0,
			taxYearEnd = '',
			affiliateTaxYearEndId,
			calculatingCurrencyId,
			rowIndex,
			taxPayerId
		}:
		{
			descriptionId?: number;
			taxYearEnd: string;
			affiliateTaxYearEndId: number;
			calculatingCurrencyId: number;
			rowIndex: number;
			taxPayerId: number | undefined;
		}
	)
	{
		this.id = Utils.generateTempId();
		this.descriptionId = !!descriptionId ? descriptionId : 0;
		this.amount = undefined;
		this.taxYearEnd = taxYearEnd;
		this.notes = undefined;
		this.affiliateTaxYearEndId = affiliateTaxYearEndId;
		this.rowIndex = rowIndex;
		this.calculatingCurrencyId = calculatingCurrencyId;
		this.taxPayerId = taxPayerId;
		this.foreignTaxableIncomeId = undefined;
	}

	id: number;
	descriptionId: number;
	amount?: number;
	notes?:  string;
	taxYearEnd?: string;
	rowIndex: number;
	affiliateTaxYearEndId: number;
	calculatingCurrencyId: number;
	taxPayerId: number | undefined;
	foreignTaxableIncomeId:  number|undefined;
}

export enum Reg5907AdjustmentsSubmissionToken {

	AllTaxPayers = 'allTaxPayers',
	TaxPayerOnly = 'taxPayerOnly'
}

export interface IReg5907AdjustmentsRecord {

	[Reg5907AdjustmentsSubmissionToken.AllTaxPayers]: IReg5907AdjustmentsRow[];
	[Reg5907AdjustmentsSubmissionToken.TaxPayerOnly]: IReg5907AdjustmentsRow[];
}
export interface IReg5907AdjustmentsYear {

	affiliateTaxYearEndId: number;
	taxYearEnd: string;
	countryId: number;
	calculatingCurrencyId: number;
	[Reg5907AdjustmentsSubmissionToken.AllTaxPayers]: IReg5907AdjustmentsRow[];
	[Reg5907AdjustmentsSubmissionToken.TaxPayerOnly]: IReg5907AdjustmentsRow[];
}

export interface IReg5907AdjustmentsYearSubmission {

	affiliateTaxYearEndId: number;
	[Reg5907AdjustmentsSubmissionToken.AllTaxPayers]: IReg5907AdjustmentsRow[];
	[Reg5907AdjustmentsSubmissionToken.TaxPayerOnly]: IReg5907AdjustmentsRow[];
}

export interface IReg5907AdjustmentsSubmission {

	engagementId: number;
	affiliateId: number;
	reg5907Adjustments: IReg5907AdjustmentsYearSubmission[];
}

export interface ISummationBase {
	[Reg5907AdjustmentsTypes.TotalAdjustments]: number | undefined;
}