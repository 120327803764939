import axios from 'axios';
import { IEngagementDetail } from '../models/IEngagementDetail';
import { IForeignAffiliate } from '../models/IForeignAffiliate';
import { IOpeningBalance } from '../models/IOpeningBalance';
import { ILineItem } from 'src/common/types/interfaces/ILineItem';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import { IRelationship } from '../models/IRelationship';
import moment from 'moment';
import { IPropertyIncomeSubmission } from 'src/common/types/interfaces/IPropertyIncome';
import { IDividendPaid } from 'src/common/types/interfaces/IDividendsPaid';
import { Utils } from 'src/common/utils/utils';
import { IDividendReceivedOthers } from '../models/IDividendReceivedOthers';
import { ITaxPayer } from '../models/ITaxPayer';
import { CapitalGainsDisposition, ICapitalGainsDisposition } from 'src/common/types/interfaces/ICapitalGainsDisposition';
import { IABIComputedUnderCanadianRulesSubmission } from 'src/common/types/interfaces/IABIComputedUnderCanadianRules';
import { IForeignTaxableIncomeSubmission } from 'src/common/types/interfaces/IForeignTaxableIncome';
import { IReg5907AdjustmentsSubmission } from 'src/common/types/interfaces/IReg5907Adjustments';
import { IAdjustmentFromSpecificTaxpayer } from '../models/IAdjustmentFromSpecificTaxpayer';
import { ISurplusEPSubmissions } from '../models/ISurplusEPAdjustment';
import { IRetainedEarningsSubmission } from 'src/common/types/interfaces/IRetainedEarnings';
import { IAdjustedCostBase } from 'src/common/types/interfaces/IAdjustedCostBase';

export const engagementService = {

	saveEngagement: async (engagementDetail: IEngagementDetail) =>
	{
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/Engagement`,
			{
				...engagementDetail,
				lastCalculationTaxationYearEnd: dateFormatter.estDateStringOverrideToUtc(
					dateFormatter.estDateToString(engagementDetail.lastCalculationTaxationYearEnd)),
				deliveryDate: dateFormatter.estDateStringOverrideToUtc(
					dateFormatter.estDateToString(engagementDetail.deliveryDate))
			}
		);
	},

	saveEngagementOverview: async (engagementOverview: IEngagementDetail) =>
	{
		return axios.put(
			`${process.env.REACT_APP_WEBAPI}/Engagement`,
			{
				...engagementOverview,
				lastCalculationTaxationYearEnd: dateFormatter.estDateStringOverrideToUtc(
					dateFormatter.estDateToString(engagementOverview.lastCalculationTaxationYearEnd)),
				deliveryDate: dateFormatter.estDateStringOverrideToUtc(
					dateFormatter.estDateToString(engagementOverview.deliveryDate))
			}
		);
	},

	getEngagementDetailById: async (engagementId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/Engagement/${engagementId}`
		);
	},

	getCalculationTypes: async () =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/Lookup/CalculationTypes`
		);
	},

	updateFACalculationType: async (payload: any) =>
	{
		return axios.put(
			`${process.env.REACT_APP_WEBAPI}/ForeignAffiliate/UpdateCalculationTypes`,
			payload
		);
	},

	getForeignAffiliate: async (
		engagementId: number,
		foreignAffiliateId: number | null
	) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/ForeignAffiliate/${engagementId}/${foreignAffiliateId}`
		);
	},

	getForeignAffiliatesByEngagementId: async (
		engagementId: number | undefined
	) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/engagement/${engagementId}/affiliates`
		);
	},

	addNewFA: async (
		clientId: number | undefined,
		engagementId: number | undefined,
		name: string,
		countryId: number|undefined,
		currencyId: number|undefined,
		taxationYear: Date | undefined,
		dateBecameFa: Date | undefined,
		analysisStartDate: Date | undefined,
		analysisEndDate: Date | undefined,
		calculationTypeIds: number[]|undefined = undefined
	) =>
	{
		const newFA = {
			clientId: clientId,
			name: name,
			countryId: countryId,
			currencyId: currencyId,
			taxationYearEnd: dateFormatter.estDateStringOverrideToUtc(dateFormatter.toStartOfDayUtc(taxationYear)),
			dateBecameFa: dateFormatter.estDateStringOverrideToUtc(dateFormatter.toStartOfDayUtc(dateBecameFa)),
			analysisStartDate: dateFormatter.estDateStringOverrideToUtc(dateFormatter.toStartOfDayUtc(analysisStartDate)),
			analysisEndDate: dateFormatter.estDateStringOverrideToUtc(
				dateFormatter.estDateToString(dateFormatter.toEndOfDay(analysisEndDate))
			),
			engagementId: engagementId,
			calculationTypeIds: calculationTypeIds
		};
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/ForeignAffiliate/CreateAndAssociate`,
			newFA
		);
	},


	searchForeignAffiliates: async (clientId: number, engagementId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/ForeignAffiliate/Search/${clientId}/${engagementId}`
		);
	},

	getTaxPayers: async (clientId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/Client/${clientId}/taxpayers`
		);
	},

	createTaxPayer: async (clientId: number, taxpayer: ITaxPayer) =>
	{
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/Client/${clientId}/taxpayer`,
			{
				name: taxpayer.name,
				currencyId: taxpayer.currencyId
			}
		);
	},

	updateTaxPayer: async (clientId: number, taxpayer: ITaxPayer) =>
	{
		return axios.put(
			`${process.env.REACT_APP_WEBAPI}/Client/${clientId}/taxpayer`,
			taxpayer
		);
	},

	createEngagement: async (engagementId: number) =>
	{
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/engagement/activate/${engagementId}`
		);
	},

	associateForeignAffiliates: async (
		affiliateId: number | undefined,
		engagementId: number | undefined,
		dateBecameFa: Date,
		analysisStartDate: Date,
		analysisEndDate: Date|undefined
	) =>
	{
		const associateFA = {
			affiliateId: affiliateId,
			engagementId: engagementId,
			dateBecameFa: dateFormatter.estDateStringOverrideToUtc(dateFormatter.toStartOfDayUtc(dateBecameFa)),
			analysisStartDate: dateFormatter.estDateStringOverrideToUtc(dateFormatter.toStartOfDayUtc(analysisStartDate)),
			analysisEndDate: dateFormatter.estDateStringOverrideToUtc(
				dateFormatter.estDateToString(dateFormatter.toEndOfDay(analysisEndDate))
			)
		};
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/ForeignAffiliate/Associate`, associateFA);
	},

	updateFA: async (
		affiliateId: number|undefined,
		clientId: number | undefined,
		engagementId: number | undefined,
		name: string,
		countryId: number|undefined,
		currencyId: number|undefined,
		taxationYear: Date | undefined,
		dateBecameFa: Date | undefined,
		analysisStartDate: Date | undefined,
		analysisEndDate: Date | undefined,
		calculationTypeIds: number[]|undefined = undefined
	) =>
	{
		const newFA = {
			affiliateId: affiliateId,
			clientId: clientId,
			name: name,
			countryId: countryId,
			currencyId: currencyId,
			taxationYearEnd: dateFormatter.estDateStringOverrideToUtc(dateFormatter.toStartOfDayUtc(taxationYear)),
			dateBecameFa: dateFormatter.estDateStringOverrideToUtc(dateFormatter.toStartOfDayUtc(dateBecameFa)),
			analysisStartDate: dateFormatter.estDateStringOverrideToUtc(dateFormatter.toStartOfDayUtc(analysisStartDate)),
			analysisEndDate: dateFormatter.estDateStringOverrideToUtc(
				dateFormatter.estDateToString(dateFormatter.toEndOfDay(analysisEndDate))
			),
			engagementId: engagementId,
			calculationTypeIds: calculationTypeIds
		};
		return axios.put(
			`${process.env.REACT_APP_WEBAPI}/ForeignAffiliate`,
			newFA
		);
	},

	updateBulkFA: async (
		engagementId: number,
		faIds: number[],
		UpdatedFA: IForeignAffiliate
	) =>
	{
		const updatePayload = {
			engagementId: engagementId,
			foreignAffiliates: faIds,
			countryId: !!UpdatedFA.countryId?UpdatedFA.countryId:undefined,
			currencyId: !!UpdatedFA.currencyId?UpdatedFA.currencyId:undefined,
			taxationYearEnd: dateFormatter.estDateStringOverrideToUtc(dateFormatter.toStartOfDayUtc(UpdatedFA.taxationYearEnd)),
			dateBecameFa: dateFormatter.estDateStringOverrideToUtc(dateFormatter.toStartOfDayUtc(UpdatedFA.dateBecameFa)),
			analysisStartDate: dateFormatter.estDateStringOverrideToUtc(dateFormatter.toStartOfDayUtc(UpdatedFA.analysisStartDate))
		};
		return axios.patch(
			`${process.env.REACT_APP_WEBAPI}/ForeignAffiliate/BulkUpdate`,
			updatePayload
		);
	},

	updateBulkFACalculationType: async (body: any) =>
	{
		return axios.put(
			`${process.env.REACT_APP_WEBAPI}/ForeignAffiliate/BulkUpdateCalculationTypes`,
			body
		);
	},

	unlinkForeignAffiliate: async (body: any) =>
	{
		return axios.put(
			`${process.env.REACT_APP_WEBAPI}/ForeignAffiliate/Unlink`,
			body
		);
	},

	createOpeningBalance: async (
		engagementId: number,
		foreignAffiliateId: number,
		openingBalance: IOpeningBalance
	) =>
	{
		const payload = {
			calculatingcurrencyId: openingBalance.currency,
			openingExemptSurplus: parseFloat(openingBalance.exemptSurplus),
			openingHybridSurplus: parseFloat(openingBalance.hybridSurplus),
			openingHybridUnderlyingTax: parseFloat(openingBalance.underlyingTax),
			openingTaxableSurplus: parseFloat(openingBalance.TaxableSurplus),
			openingUnderlyingForeignTax: parseFloat(openingBalance.foreignTax),
			foreignAffiliateId: foreignAffiliateId,
			engagementId: engagementId
		};
		return axios.post(`${process.env.REACT_APP_WEBAPI}/Surplus`, payload);
	},

	updateOpeningBalance: async (
		engagementId: number,
		foreignAffiliateId: number,
		openingBalance: IOpeningBalance
	) =>
	{
		const payload = {
			calculatingcurrencyId: openingBalance.currency,
			openingExemptSurplus: parseFloat(openingBalance.exemptSurplus),
			openingHybridSurplus: parseFloat(openingBalance.hybridSurplus),
			openingHybridUnderlyingTax: parseFloat(openingBalance.underlyingTax),
			openingTaxableSurplus: parseFloat(openingBalance.TaxableSurplus),
			openingUnderlyingForeignTax: parseFloat(openingBalance.foreignTax),
			foreignAffiliateId: foreignAffiliateId,
			engagementId: engagementId,
			surplusId: openingBalance.id
		};
		return axios.put(`${process.env.REACT_APP_WEBAPI}/Surplus`, payload);
	},

	getOpeningBalances: async (
		engagementId: number,
		foreignAffiliateId: number
	) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/Surplus/${engagementId}/${foreignAffiliateId}`
		);
	},

	createInput: (
		engagementId: number,
		foreignAffiateId: number,
		lineItems: ILineItem[]
	) =>
	{
		const items = lineItems.map((item: ILineItem) =>
		{
			return {
				field: item.field,
				value: item.value,
				note: item.note
			};
		});
		const payload = {
			engagementId: engagementId,
			foreignAffiliateId: foreignAffiateId,
			recordType: 'Disposition',
			lineITemValues: items
		};
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/Input/capitalgain`,
			payload
		);
	},

	updateInput: (
		engagementId: number,
		foreignAffiateId: number,
		recordGuid: string,
		lineItems: ILineItem[]
	) =>
	{
		const items = lineItems.map((item: ILineItem) =>
		{
			return {
				field: item.field,
				value: item.value,
				note: item.note
			};
		});
		const payload = {
			engagementId: engagementId,
			foreignAffiliateId: foreignAffiateId,
			recordGuid: recordGuid,
			lineITemValues: items
		};
		return axios.put(
			`${process.env.REACT_APP_WEBAPI}/Input/capitalgain`,
			payload
		);
	},

	listInput: async (engagementId: number, foreignAffiliateId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/Input/capitalgain/${engagementId}/${foreignAffiliateId}`
		);
	},

	getEarnings: async (engagementId: number, affiliateId: number,fromDate?: Date,
		toDate?: Date) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/Earnings/${engagementId}/${affiliateId}`
		);
	},

	saveEarnings: async (payload: any) =>
	{
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/Earnings`,
			payload
		);
	},

	getIncomeTaxPaid: async (engagementId: number, affiliateId: number,fromDate?: Date,
		toDate?: Date) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/incometaxespaid/${engagementId}/${affiliateId}`
		);
	},

	saveIncomeTaxPaid: async (incomeTaxesPaids: any) =>
	{
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/incometaxespaid`,
			incomeTaxesPaids
		);
	},

	getRelationships: async (engagementId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/Relationships/${engagementId}`
		);
	},

	updateRelationship: (relationship: IRelationship) =>
	{
		const payload = {
			engagementId: relationship.engagementId,
			ownerAffiliateId: relationship.ownerAffiliateId,
			equityClassId: relationship.equityClassId,
			equityPercentage: relationship.equityPercentage,
			sep: relationship.SEP,
			notes: relationship.notes,
			effectiveDate: dateFormatter.estDateStringOverrideToUtc(
				dateFormatter.mergeDateTime(
					relationship.asOfDate,
					relationship.asOfTime
				)
			)
		};
		return axios.put(
			`${process.env.REACT_APP_WEBAPI}/Relationships/${relationship.relationshipId}`,
			payload
		);
	},

	addNewRelationship: (relationship: IRelationship) =>
	{
		const payload = {
			engagementId: relationship.engagementId,
			affiliateId: relationship.faId,
			ownerAffiliateId: relationship.ownerAffiliateId,
			equityClassId: relationship.equityClassId,
			equityPercentage: relationship.equityPercentage,
			sep: relationship.SEP,
			effectiveDate: dateFormatter.estDateStringOverrideToUtc(
				dateFormatter.mergeDateTime(
					new Date(relationship.asOfDate??''),
					relationship.asOfTime??moment().startOf('day')
						.toDate()
				)
			),
			notes: relationship.notes
		};
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/Relationships`,
			payload
		);
	},

	mergeRelationship: (payload: any) =>
	{
		const requestBody = {
			engagementId: payload.engagementId,
			predecessorAffiliateId: payload.predecessorAffiliateId,
			survivorAffiliateId: payload.survivorAffiliateId,
			effectiveDate: dateFormatter.estDateStringOverrideToUtc(
				dateFormatter.mergeDateTime(
					payload?.asOfDate,
					payload?.asOfTime??moment().startOf('day')
						.toDate()
				)
			),
			notes: payload.notes,
			mergerRows: payload.mergerRows
		};
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/relationships/merge`,
			requestBody
		);
	},

	deleteRelationship: async(engagementId: number, relationshipId: number) =>
	{
		return axios.delete(
			`${process.env.REACT_APP_WEBAPI}/relationships?engagementId=${engagementId}&relationshipId=${relationshipId}`
		);
	},

	addAcquisitionDisposition: (payload: any) =>
	{
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/relationships/acquisition`,
			payload
		);
	},

	liquidate: (requestBody: any) =>
	{
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/relationships/liquidation`,
			requestBody
		);
	},

	deleteEngagementMember: async (
		engagementId: number,
		useremail: string
	) =>
	{
		return axios.delete(
			`${process.env.REACT_APP_WEBAPI}/Engagement/${engagementId}/users/${useremail}`
		);
	},

	userDetailByEmail: async (
		engagementId: number,
		email: string
	) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/Engagement/${engagementId}/searchuser/${email}`
		);
	},

	getEngagementMembers: async (
		engagementId: number
	) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/engagement/${engagementId}/users`
		);
	},

	saveEngagementMember: async (engMemberDetails: any,engagmentId: number) =>
	{
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/engagement/${engagmentId}/users`,
			engMemberDetails
		);
	},
	getFAHistory: async (engagementId: number,affiliateId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/fahistory/${engagementId}/${affiliateId}`
		);
	},
	createFaHistory: async(payload: any) =>
	{
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/FaHistory`,payload
		);
	},
	updateFaHistory: async(payload: any) =>
	{
		return axios.put(
			`${process.env.REACT_APP_WEBAPI}/FaHistory`,payload
		);
	},
	deleteFaHistory: async(engagementId: number|undefined,affiliateId: number|undefined,faHistoryId: number) =>
	{
		return axios.delete(
			`${process.env.REACT_APP_WEBAPI}/FaHistory/${engagementId}/${affiliateId}/${faHistoryId}`
		);
	},
	getEntityToMergeWith: async (engagementId: number, affiliateId: number,date: string | undefined) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/Relationships/EntitiesForMerge/${engagementId}/${affiliateId}/${date}`
		);
	},

	getPropertyIncome: async (engagementId: number, affiliateId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/PropertyIncome/${engagementId}/${affiliateId}`
		);
	},

	getPropertyIncomeLookups: async () =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/Lookup/PropertyIncomeMasterData`
		);
	},

	updatePropertyIncome: async (propertyIncomeSubmission: IPropertyIncomeSubmission) =>
	{
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/PropertyIncome`,
			propertyIncomeSubmission
		);
	},

	getForeignTaxableIncome: async (engagementId: number, affiliateId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/ForeignTaxableIncome/${engagementId}/${affiliateId}`
		);
	},

	getForeignTaxableIncomeLookups: async () =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/Lookup/ForeignTaxableIncomeMasterData`
		);
	},

	updateForeignTaxableIncome: async (foreignTaxableIncomeSubmission: IForeignTaxableIncomeSubmission) =>
	{
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/ForeignTaxableIncome`,
			foreignTaxableIncomeSubmission
		);
	},

	deleteForeignTaxableIncomeRow: async (
		engagementId: number,
		affiliateId: number,
		descriptionId: number,
		rowIndex: number
	) =>
	{
		return axios.delete(
			`${process.env.REACT_APP_WEBAPI}/ForeignTaxableIncome/${engagementId}/${affiliateId}`,
			{
				params: {
					descriptionId,
					rowIndex
				}
			}
		);
	},


	getABIComputedUnderCanadianRules: async (engagementId: number, affiliateId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/ActiveBusinessIncome/${engagementId}/${affiliateId}`
		);
	},

	getABIComputedUnderCanadianRulesLookups: async () =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/Lookup/ActiveBusinessIncomeMasterData`
		);
	},

	updateABIComputedUnderCanadianRulesIncome: async (abiSubmission: IABIComputedUnderCanadianRulesSubmission) =>
	{
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/ActiveBusinessIncome`,
			abiSubmission
		);
	},

	deleteABIComputedUnderCanadianRulesIncomeRow: async (
		engagementId: number,
		affiliateId: number,
		descriptionId: number,
		rowIndex: number
	) =>
	{
		return axios.delete(
			`${process.env.REACT_APP_WEBAPI}/ActiveBusinessIncome/${engagementId}/${affiliateId}`,
			{
				params: {
					descriptionId,
					rowIndex
				}
			}
		);
	},

	getDividendsPaidInput: async (
		{
			engagementId,
			affiliateId,
			fromDate,
			toDate
		}:
		{
			engagementId: number;
			affiliateId: number;
			fromDate?: Date;
			toDate?: Date;
		}
	) =>
	{
		const queryStrings: Record<string, string> = {
		};

		if (!!fromDate)
		{
			queryStrings.fromDate = dateFormatter.estDateStringOverrideToUtc(
				dateFormatter.estDateToString(fromDate)
			)!;
		}

		if (!!toDate)
		{
			queryStrings.toDate = dateFormatter.estDateStringOverrideToUtc(
				dateFormatter.estDateToString(toDate)
			)!;
		}

		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/Dividends/Paid/${engagementId}/${affiliateId}`,
			{
				params: queryStrings
			}

		);
	},

	getDividendsPaidEquityClasses: async (
		{
			engagementId,
			affiliateId
		}:
		{
			engagementId: number;
			affiliateId: number
		}
	) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/Dividends/Paid/EquityClasses/${engagementId}/${affiliateId}`
		);
	},

	createDividendPaid: async (dividendPaid: IDividendPaid) =>
	{
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/Dividends/Paid`,
			{
				engagementId: dividendPaid.engagementId,
				affiliateId: dividendPaid.affiliateId,
				effectiveDate: dateFormatter.estDateStringOverrideToUtc(
					dateFormatter.mergeDateTime(
						dividendPaid.date,
						dividendPaid.time
					)
				),
				currencyId: dividendPaid.currencyId,
				paidOnEquityClassId: dividendPaid.paidOnEquityClassId,
				amount: Utils.isValidNumber(`${dividendPaid.amount}`) ?
					+`${dividendPaid.amount}` :
					undefined,
				underlyingForeignTax: Utils.isValidNumber(`${dividendPaid.underlyingForeignTax}`) ?
					+`${dividendPaid.underlyingForeignTax}` :
					undefined,
				isSurplusElection: dividendPaid.isSurplusElection,
				isCapitalElection: dividendPaid.isCapitalElection,
				notes: dividendPaid.notes
			}
		);
	},

	updateDividendPaid: async (dividendPaid: IDividendPaid) =>
	{
		return axios.put(
			`${process.env.REACT_APP_WEBAPI}/Dividends/Paid`,
			{
				id: dividendPaid.id,
				effectiveDate: dateFormatter.estDateStringOverrideToUtc(
					dateFormatter.mergeDateTime(
						dividendPaid.date,
						dividendPaid.time
					)
				),
				currencyId: dividendPaid.currencyId,
				paidOnEquityClassId: dividendPaid.paidOnEquityClassId,
				amount: Utils.isValidNumber(`${dividendPaid.amount}`) ?
					+`${dividendPaid.amount}` :
					undefined,
				underlyingForeignTax: Utils.isValidNumber(`${dividendPaid.underlyingForeignTax}`) ?
					+`${dividendPaid.underlyingForeignTax}` :
					undefined,
				isSurplusElection: dividendPaid.isSurplusElection,
				isCapitalElection: dividendPaid.isCapitalElection,
				notes: dividendPaid.notes,
				engagementId: dividendPaid.engagementId,
				affiliateId: dividendPaid.affiliateId
			}
		);
	},

	deleteDividendPaid: async (engagementId: number, dividendPaidId: number) =>
	{
		return axios.delete(
			`${process.env.REACT_APP_WEBAPI}/Dividends/Paid/${engagementId}/${dividendPaidId}`
		);
	},

	getDividendsReceivedFromFA: async (
		{
			engagementId,
			affiliateId,
			fromDate,
			toDate
		}:
		{
			engagementId: number;
			affiliateId: number;
			fromDate?: Date;
			toDate?: Date;
		}
	) =>
	{
		const queryStrings: Record<string, string> = {
		};

		if (!!fromDate)
		{
			queryStrings.fromDate = dateFormatter.estDateStringOverrideToUtc(
				dateFormatter.estDateToString(fromDate)
			)!;
		}

		if (!!toDate)
		{
			queryStrings.toDate = dateFormatter.estDateStringOverrideToUtc(
				dateFormatter.estDateToString(toDate)
			)!;
		}

		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/dividends/received/affiliate/${engagementId}/${affiliateId}`,
			{
				params: queryStrings
			}

		);
	},

	getDividendsReceivedFromOthers: async ({
		engagementId,
		affiliateId,
		fromDate,
		toDate
	}:
	{
		engagementId: number;
		affiliateId: number;
		fromDate?: Date;
		toDate?: Date;
	}) =>
	{
		const queryStrings: Record<string, string> = {
		};

		if (!!fromDate)
		{
			queryStrings.fromDate = dateFormatter.estDateStringOverrideToUtc(
				dateFormatter.estDateToString(fromDate)
			)!;
		}

		if (!!toDate)
		{
			queryStrings.toDate = dateFormatter.estDateStringOverrideToUtc(
				dateFormatter.estDateToString(toDate)
			)!;
		}
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/dividends/received/others/${engagementId}/${affiliateId}`,
			{
				params: queryStrings
			}

		);
	},

	updateDividendsReceivedFromFA: async (dividends: any) =>
	{
		return axios.put(
			`${process.env.REACT_APP_WEBAPI}/dividends/received/affiliate`,
			dividends
		);
	},

	addDividendReceivedFromOthers: async (dividendReceivedOthers: IDividendReceivedOthers) =>
	{
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/dividends/received/others`,
			{
				...dividendReceivedOthers,
				effectiveDate: dateFormatter.estDateStringOverrideToUtc(
					dateFormatter.estDateToString(dividendReceivedOthers.effectiveDate)
				)
			}
		);
	},

	updateDividendReceivedFromOthers: async (dividendReceivedOthers: IDividendReceivedOthers) =>
	{
		return axios.put(
			`${process.env.REACT_APP_WEBAPI}/dividends/received/others`,
			{
				...dividendReceivedOthers,
				effectiveDate: dateFormatter.estDateStringOverrideToUtc(
					dateFormatter.estDateToString(dividendReceivedOthers.effectiveDate)
				)
			}
		);
	},

	deleteDividendReceivedFromOthers: async (engagementId: number, dividendReceivedOthersId: number) =>
	{
		return axios.delete(
			`${process.env.REACT_APP_WEBAPI}/dividends/received/others/${engagementId}/${dividendReceivedOthersId}`
		);
	},

	getRelationshipOwners: async (clientId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/Client/${clientId}/affiliates`
		);
	},

	getCapitalGainsDispositionInput: async (
		{
			engagementId,
			affiliateId
		}:
		{
			engagementId: number;
			affiliateId: number;
		}
	) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/CapitalGains/${engagementId}/${affiliateId}`
		);
	},

	createCapitalGainsDisposition: async (capitalGainDisposition: ICapitalGainsDisposition) =>
	{
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/CapitalGains`,
			CapitalGainsDisposition.toRequestBody(capitalGainDisposition)
		);
	},

	updateCapitalGainsDisposition: async (capitalGainDisposition: ICapitalGainsDisposition) =>
	{
		return axios.put(
			`${process.env.REACT_APP_WEBAPI}/CapitalGains`,
			CapitalGainsDisposition.toRequestBody(capitalGainDisposition, true)
		);
	},

	deleteCapitalGainsDisposition: async (engagementId: number, capitalGainDispositionId: number) =>
	{
		return axios.delete(
			`${process.env.REACT_APP_WEBAPI}/CapitalGains/${engagementId}/${capitalGainDispositionId}`
		);
	},

	getAdjustedCostBaseInput: async (
		{
			engagementId,
			affiliateId,
			fromDate,
			toDate
		}:
		{
			engagementId: number;
			affiliateId: number;
			fromDate?: Date;
			toDate?: Date;
		}
	) =>
	{
		const queryStrings: Record<string, string> = {
		};

		if (!!fromDate)
		{
			queryStrings.fromDate = dateFormatter.estDateStringOverrideToUtc(
				dateFormatter.estDateToString(fromDate)
			)!;
		}

		if (!!toDate)
		{
			queryStrings.toDate = dateFormatter.estDateStringOverrideToUtc(
				dateFormatter.estDateToString(toDate)
			)!;
		}

		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/AdjustedCostBase/${engagementId}/${affiliateId}`,
			{
				params: queryStrings
			}

		);
	},

	getAdjustedCostBaseShareholders: async (
		engagementId: number,
		affiliateId: number,
		transactionDate?: Date
	) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/AdjustedCostBase/Shareholders/${engagementId}/${affiliateId}`,
			{
				params: {
					transactionDate: dateFormatter.estDateStringOverrideToUtc(
						dateFormatter.estDateToString(transactionDate)
					)!
				}
			}
		);
	},

	createAdjustedCostBase: async (adjustedCostBase: IAdjustedCostBase) =>
	{
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/AdjustedCostBase`,
			{
				engagementId: adjustedCostBase.engagementId,
				affiliateId: adjustedCostBase.affiliateId,
				isAddition: adjustedCostBase.isAddition,
				transactionTypeId: adjustedCostBase.transactionTypeId,
				description: adjustedCostBase.description,
				shareholderId: !!adjustedCostBase.shareholderId && adjustedCostBase.shareholderId < 0 ?
					null : adjustedCostBase.shareholderId,
				equityClassId: adjustedCostBase.equityClassId,
				currencyId: adjustedCostBase.currencyId,
				effectiveDate: dateFormatter.estDateStringOverrideToUtc(dateFormatter.estDateToString(adjustedCostBase.effectiveDate)),
				electionsAmount: Utils.isValidNumber(`${adjustedCostBase.electionsAmount}`) ?
					+`${adjustedCostBase.electionsAmount}` :
					undefined,
				shareholderEquityPercentage: adjustedCostBase.shareholderEquityPercentage,
				amount: Utils.isValidNumber(`${adjustedCostBase.amount}`) ?
					+`${adjustedCostBase.amount}` :
					undefined,
				foreignExchangeRate: !!adjustedCostBase.foreignExchangeRate ?
					Number(adjustedCostBase.foreignExchangeRate) : null,
				isPartnershipOwned: adjustedCostBase.isPartnershipOwned,
				partnershipName: adjustedCostBase.partnershipName,
				partnershipCurrencyId: adjustedCostBase.partnershipCurrencyId,
				noOfSharesAcquiredOrDisposed: !!adjustedCostBase.noOfSharesAcquiredOrDisposed ?
					Number(adjustedCostBase.noOfSharesAcquiredOrDisposed) : null,
				partnershipsEquityPercentage: !adjustedCostBase.isPartnershipOwned ? null : (
					!!adjustedCostBase.partnershipsEquityPercentage ?
						Number(adjustedCostBase.partnershipsEquityPercentage) : null
				),
				notes: adjustedCostBase.notes
			}
		);
	},

	updateAdjustedCostBase: async (adjustedCostBase: IAdjustedCostBase) =>
	{
		return axios.put(
			`${process.env.REACT_APP_WEBAPI}/AdjustedCostBase`,
			{
				id: adjustedCostBase.id,
				engagementId: adjustedCostBase.engagementId,
				affiliateId: adjustedCostBase.affiliateId,
				isAddition: adjustedCostBase.isAddition,
				transactionTypeId: adjustedCostBase.transactionTypeId,
				description: adjustedCostBase.description,
				shareholderId: !!adjustedCostBase.shareholderId && adjustedCostBase.shareholderId < 0 ?
					null : adjustedCostBase.shareholderId,
				equityClassId: adjustedCostBase.equityClassId,
				currencyId: adjustedCostBase.currencyId,
				effectiveDate: dateFormatter.estDateStringOverrideToUtc(dateFormatter.estDateToString(adjustedCostBase.effectiveDate)),
				electionsAmount: Utils.isValidNumber(`${adjustedCostBase.electionsAmount}`) ?
					+`${adjustedCostBase.electionsAmount}` :
					undefined,
				shareholderEquityPercentage: adjustedCostBase.shareholderEquityPercentage,
				amount: Utils.isValidNumber(`${adjustedCostBase.amount}`) ?
					+`${adjustedCostBase.amount}` :
					undefined,
				foreignExchangeRate: !!adjustedCostBase.foreignExchangeRate ?
					Number(adjustedCostBase.foreignExchangeRate) : null,
				isPartnershipOwned: adjustedCostBase.isPartnershipOwned,
				partnershipName: adjustedCostBase.partnershipName,
				partnershipCurrencyId: adjustedCostBase.partnershipCurrencyId,
				noOfSharesAcquiredOrDisposed: !!adjustedCostBase.noOfSharesAcquiredOrDisposed ?
					Number(adjustedCostBase.noOfSharesAcquiredOrDisposed) : null,
				partnershipsEquityPercentage: !adjustedCostBase.isPartnershipOwned ? null : (
					!!adjustedCostBase.partnershipsEquityPercentage ?
						Number(adjustedCostBase.partnershipsEquityPercentage) : null
				),
				notes: adjustedCostBase.notes
			}
		);
	},

	deleteAdjustedCostBase: async (engagementId: number, adjustedCostBaseId: number) =>
	{
		return axios.delete(
			`${process.env.REACT_APP_WEBAPI}/AdjustedCostBase/${engagementId}/${adjustedCostBaseId}`
		);
	},

	loadCaptialGainsReport: async (engagementId: number, affiliateId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/CapitalGains/Report/${engagementId}/${affiliateId}`
		);
	},

	loadEarningsSummaryReport: async (engagementId: number, affiliateId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/Earnings/Report/${engagementId}/${affiliateId}`
		);
	},

	loadEarningsDetailsReport: async (engagementId: number, affiliateId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/Earnings/Report/Details/${engagementId}/${affiliateId}`
		);
	},

	loadForeignTaxesReport: async (engagementId: number, affiliateId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/foreignTaxes/report/${engagementId}/${affiliateId}`
		);
	},

	loadExemptAndTaxableEarningsReport: async (engagementId: number, affiliateId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/ExemptAndTaxableEarnings/report/${engagementId}/${affiliateId}`
		);
	},

	loadExemptSurplusDetailsReport: async (engagementId: number, affiliateId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/surplus/exempt/report/details/${engagementId}/${affiliateId}`
		);
	},

	loadTaxableSurplusDetailsReport: async (engagementId: number, affiliateId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/surplus/taxable/report/details/${engagementId}/${affiliateId}`
		);
	},

	loadHybridSurplusDetailsReport: async (engagementId: number, affiliateId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/surplus/hybrid/report/details/${engagementId}/${affiliateId}`
		);
	},

	loadDividendsPaidReport: async (engagementId: number, affiliateId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/dividends/paid/report/${engagementId}/${affiliateId}`
		);
	},

	loadDividendsReceivedReport: async (engagementId: number, affiliateId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/dividends/received/report/${engagementId}/${affiliateId}`
		);
	},

	loadAdjustedCostBaseDetailsReport: async (engagementId: number, affiliateId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/adjustedcostbase/report/${engagementId}/${affiliateId}`
		);
	},

	loadForeignAccurualProertyIncomeReport: async (engagementId: number, affiliateId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/FAPI/report/${engagementId}/${affiliateId}`
		);
	},

	getAdjustedCostBaseMasterData: async () =>
	{
		return axios.get(`${process.env.REACT_APP_WEBAPI}/Lookup/AdjustedCostBaseMasterData`);
	},

	getReg5907Adjustments: async (engagementId: number, affiliateId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/Reg5907Adjustment/${engagementId}/${affiliateId}`
		);
	},

	getReg5907AdjustmentsLookups: async () =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/Lookup/ForeignTaxableIncomeMasterData`
		);
	},

	updateReg5907Adjustment: async (reg5907AdjustmentSubmission: IReg5907AdjustmentsSubmission) =>
	{
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/Reg5907Adjustment`,
			reg5907AdjustmentSubmission
		);
	},

	deleteReg5907Adjustment: async (
		engagementId: number,
		affiliateId: number,
		reg5907AdjustmentIds: number[]
	) =>
	{
		return axios.delete(
			`${process.env.REACT_APP_WEBAPI}/Reg5907Adjustment/${engagementId}/${affiliateId}`,
			{
				params: {
					reg5907AdjustmentIds: JSON.stringify(reg5907AdjustmentIds)
				}
			}
		);
	},

	getEngagementOverviewById: async (engagementId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/Engagement/${engagementId}/overview`
		);
	},

	getAllAdjustmentsReceived: async ({
		engagementId,
		affiliateId,
		fromDate,
		toDate
	}:
	{
		engagementId: number;
		affiliateId: number;
		fromDate?: Date;
		toDate?: Date;
	}) =>
	{
		const queryStrings: Record<string, string> = {
		};

		if (!!fromDate)
		{
			queryStrings.fromDate = dateFormatter.estDateStringOverrideToUtc(
				dateFormatter.estDateToString(fromDate)
			)!;
		}

		if (!!toDate)
		{
			queryStrings.toDate = dateFormatter.estDateStringOverrideToUtc(
				dateFormatter.estDateToString(toDate)
			)!;
		}
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/OtherAdjustmentsToSurplus/all/${engagementId}/${affiliateId}`,
			{
				params: queryStrings
			}

		);
	},

	getSurplusDescriptionsMasterData: async () =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/Lookup/SurplusDescriptionsMasterData`
		);
	},

	getSpecificAdjustmentsReceived: async ({
		engagementId,
		affiliateId,
		fromDate,
		toDate
	}:
	{
		engagementId: number;
		affiliateId: number;
		fromDate?: Date;
		toDate?: Date;
	}) =>
	{
		const queryStrings: Record<string, string> = {
		};

		if (!!fromDate)
		{
			queryStrings.fromDate = dateFormatter.estDateStringOverrideToUtc(
				dateFormatter.estDateToString(fromDate)
			)!;
		}

		if (!!toDate)
		{
			queryStrings.toDate = dateFormatter.estDateStringOverrideToUtc(
				dateFormatter.estDateToString(toDate)
			)!;
		}
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/OtherAdjustmentsToSurplus/${engagementId}/${affiliateId}`,
			{
				params: queryStrings
			}

		);
	},

	createAdjustment: async (adjustments: IAdjustmentFromSpecificTaxpayer) =>
	{
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/OtherAdjustmentsToSurplus`,
			{
				engagementId: adjustments.engagementId,
				affiliateId: adjustments.affiliateId,
				adjustmentDate: dateFormatter.estDateStringOverrideToUtc(
					dateFormatter.mergeDateTime(
						adjustments.adjustmentDate,
						adjustments.time
					)
				),
				currencyId: adjustments.currencyId,
				taxpayerId: adjustments.specificTo ? adjustments.taxpayerId : null,
				surplusPoolId: adjustments.surplusPoolId,
				descriptionId: adjustments.descriptionId,
				amount: Utils.isValidNumber(`${adjustments.amount}`) ?
					+`${adjustments.amount}` :
					undefined,
				notes: adjustments.notes
			}
		);
	},

	updateAdjustment: async (adjustments: IAdjustmentFromSpecificTaxpayer) =>
	{
		return axios.put(
			`${process.env.REACT_APP_WEBAPI}/OtherAdjustmentsToSurplus`,
			{
				id: adjustments.id,
				engagementId: adjustments.engagementId,
				affiliateId: adjustments.affiliateId,
				adjustmentDate: dateFormatter.estDateStringOverrideToUtc(
					dateFormatter.mergeDateTime(
						adjustments.adjustmentDate,
						adjustments.time
					)
				),
				currencyId: adjustments.currencyId,
				taxpayerId: adjustments.taxpayerId,
				surplusPoolId: adjustments.surplusPoolId,
				descriptionId: adjustments.descriptionId,
				amount: Utils.isValidNumber(`${adjustments.amount}`) ?
					+`${adjustments.amount}` :
					undefined,
				notes: adjustments.notes
			}
		);
	},

	deleteAdjustment: async (engagementId: number, affiliateId: number, otherAdjustmentToSurplusId: number) =>
	{
		return axios.delete(
			`${process.env.REACT_APP_WEBAPI}/OtherAdjustmentsToSurplus/${engagementId}/${affiliateId}/${otherAdjustmentToSurplusId}`
		);
	},

	getSurplusEntitlementPercentageAdjustmenst: async ({
		engagementId,
		affiliateId,
		pageSize,
		pageNumber
	}:
	{
		engagementId: number;
		affiliateId: number;
		pageSize?: number;
		pageNumber?: number;
	}) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/SEPChanges/${engagementId}/${affiliateId}?pageSize=${pageSize}&pageNumber=${pageNumber}`
		);
	},

	updateSurplusEntPerAdjustment: async (surplusEPData: ISurplusEPSubmissions) =>
	{
		return axios.put(
			`${process.env.REACT_APP_WEBAPI}/SEPChanges`,
			surplusEPData
		);
	},

	getExportTemplatesListById: async (engagementId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/ImportExport/export/${engagementId}`
		);
	},

	getImportFilesListById: async (engagementId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/ImportExport/uploadlist/${engagementId}`
		);
	},

	downloadExportTemplate: async (exportId: number) =>
	{
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/ImportExport/download/${exportId}`, {
			}, {
				responseType: 'blob'
			}
		);
	},

	downloadImportedFile: async (importId: number) =>
	{
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/ImportExport/downloaduploadtemplate/${importId}`, {
			}, {
				responseType: 'blob'
			}
		);
	},

	uploadTemplateForImport: async (engagementId: number, formData: any) =>
	{
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/ImportExport/uploadtemplate/${engagementId}`, formData, {
				headers: {
				  'Content-Type': 'multipart/form-data'
				}
			  }
		);
	},

	requestEngagementTemplate: async (engagementId: number, listOfAffiliateIds: any) =>
	{
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/ImportExport/CreateExportTemplate`,
			{
				engagementId: engagementId,
				listOfAffiliateIds: listOfAffiliateIds
			}
		);
	},

	getRelationshipsValidationStatus: async (engagementId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/relationships/validation/${engagementId}/status`
		);
	},

	getRelationshipsValidationDetails: async (engagementId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/relationships/validation/${engagementId}`
		);
	},

	validateRelationshipsByEngagementId: async (engagementId: number) =>
	{
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/relationships/validation/${engagementId}`
		);
	},

	getEngagementRelationshipsValidStatus: async (engagementId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/Relationships/${engagementId}/IsEngagementRelationshipsValidated`
		);
	},

	getCalculationHistory: async (engagementId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_FUNCTIONAPP_API}/engagement/${engagementId}/calculation`
		);
	},

	getCalculationStatus: async (engagementId: number, calculationId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_FUNCTIONAPP_API}/engagement/${engagementId}/calculation/${calculationId}`
		);
	},

	runCalculationsForEngagement: async (engagementId: number, calculationMode: number) =>
	{
		return axios.post(
			`${process.env.REACT_APP_FUNCTIONAPP_API}/engagement/${engagementId}/calculation?mode=${calculationMode}`
		);
	},

	getRetainedEarnings: async (engagementId: number, affiliateId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/RetainedEarnings/${engagementId}/${affiliateId}`
		);
	},

	updateRetainedEarnings: async (retainedEarningsSubmission: IRetainedEarningsSubmission) =>
	{
		return axios.post(
			`${process.env.REACT_APP_WEBAPI}/RetainedEarnings`,
			retainedEarningsSubmission
		);
	},

	getRetainedEarningsLookupData: async () =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/lookup/RetainedEarningsDifferenceTypesMasterData`
		);
	},

	deleteRetainedEarning: async (
		engagementId: number,
		affiliateId: number,
		retainedEarningDiffTypeId: number,
		rowIndex: number
	) =>
	{
		return axios.delete(
			`${process.env.REACT_APP_WEBAPI}/RetainedEarnings/${engagementId}/${affiliateId}`,
			{
				params: {
					retainedEarningDiffTypeId,
					rowIndex
				}
			}
		);
	},

	getTaxPayerTaxYearEnds: async (engagementId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/TaxPayer/TaxYearEnds/${engagementId}`
		);
	},

	getTaxPayerReportRecords: async (
		engagementId: number,
		taxpayerTaxYearEnd?: string,
		currencyId?: number,
		currencyConversionDate?: string
	) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/TaxPayer/Report/${engagementId}`,
			{
				params: {
					taxpayerTaxYearEnd,
					currencyId,
					currencyConversionDate
				}
			}
		);
	},

	loadExportReportFilters: async (engagementId: number) =>
	{
		return axios.get(
			`${process.env.REACT_APP_WEBAPI}/Report/ReportFilters/${engagementId}`
		);
	}
};
