import React from 'react';
import { UnderConstruction } from 'src/common/components/UnderConstruction/UnderConstruction';

const ExemptSurplusSummaryReport = (props: {refershReport: boolean}) =>
{
	return (
		<div>
			<div>
				<UnderConstruction>
					<div>Exempt Surplus Summary Report</div>
				</UnderConstruction>
			</div>
		</div>
	);
};

export default ExemptSurplusSummaryReport;