
import { Utils } from 'src/common/utils/utils';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import { DividendsReceivedFromFAFields } from 'src/common/types/enums/DividendsReceivedFromFAFields';

export interface IDividendReceivedFA {

	[DividendsReceivedFromFAFields.id]: number;
	[DividendsReceivedFromFAFields.engagementId]: number;
	[DividendsReceivedFromFAFields.affiliateId]: number;
	[DividendsReceivedFromFAFields.engagementAffiliateId]?: number;
	[DividendsReceivedFromFAFields.effectiveDate]: Date;
	[DividendsReceivedFromFAFields.date]?: Date;
	[DividendsReceivedFromFAFields.time]?: Date;
	[DividendsReceivedFromFAFields.paidOnEquityClass]: string;
	[DividendsReceivedFromFAFields.percentageOwned]: number|undefined;
	[DividendsReceivedFromFAFields.amountInCurrencyPaid]: number|undefined;
	[DividendsReceivedFromFAFields.foreignExchangeRate]: number|undefined;
	[DividendsReceivedFromFAFields.amountInCurrencyReceived]: number|undefined;
	[DividendsReceivedFromFAFields.actualAmount]: number|undefined;
	[DividendsReceivedFromFAFields.withholdingTax]: number|undefined;
	[DividendsReceivedFromFAFields.notes]?: string;
}

export class DividendReceivedFA implements IDividendReceivedFA
{
	constructor(
		engagementId: number,
		affiliateId: number
	)
	{
		this[DividendsReceivedFromFAFields.id] = Utils.generateTempId();
		this[DividendsReceivedFromFAFields.engagementId] = engagementId;
		this[DividendsReceivedFromFAFields.affiliateId] = affiliateId;
		this[DividendsReceivedFromFAFields.engagementAffiliateId] = undefined;
		this[DividendsReceivedFromFAFields.effectiveDate] = new Date();
		this[DividendsReceivedFromFAFields.date] = dateFormatter.utcToEst(this[DividendsReceivedFromFAFields.effectiveDate]);
		this[DividendsReceivedFromFAFields.time] = dateFormatter.utcToEst(this[DividendsReceivedFromFAFields.effectiveDate]);
		this[DividendsReceivedFromFAFields.paidOnEquityClass] = '';
		this[DividendsReceivedFromFAFields.percentageOwned] = undefined;
		this[DividendsReceivedFromFAFields.amountInCurrencyPaid] = undefined;
		this[DividendsReceivedFromFAFields.foreignExchangeRate] = undefined;
		this[DividendsReceivedFromFAFields.amountInCurrencyReceived] = undefined;
		this[DividendsReceivedFromFAFields.actualAmount] = undefined;
		this[DividendsReceivedFromFAFields.withholdingTax] = undefined;
		this[DividendsReceivedFromFAFields.notes] = undefined;
	}

	[DividendsReceivedFromFAFields.id]: number;
	[DividendsReceivedFromFAFields.engagementId]: number;
	[DividendsReceivedFromFAFields.affiliateId]: number;
	[DividendsReceivedFromFAFields.engagementAffiliateId]?: number;
	[DividendsReceivedFromFAFields.effectiveDate]: Date;
	[DividendsReceivedFromFAFields.date]?: Date;
	[DividendsReceivedFromFAFields.time]?: Date;
	[DividendsReceivedFromFAFields.paidOnEquityClass]: string;
	[DividendsReceivedFromFAFields.percentageOwned]: number|undefined;
	[DividendsReceivedFromFAFields.amountInCurrencyPaid]: number|undefined;
	[DividendsReceivedFromFAFields.amountInCurrencyReceived]: number|undefined;
	[DividendsReceivedFromFAFields.foreignExchangeRate]: number|undefined;
	[DividendsReceivedFromFAFields.actualAmount]: number|undefined;
	[DividendsReceivedFromFAFields.withholdingTax]: number|undefined;
	[DividendsReceivedFromFAFields.notes]?: string;

	static populateFields(dividend: IDividendReceivedFA): IDividendReceivedFA
	{
		return {
			...dividend,
			[DividendsReceivedFromFAFields.effectiveDate]: dividend[DividendsReceivedFromFAFields.effectiveDate],
			[DividendsReceivedFromFAFields.date]: dividend[DividendsReceivedFromFAFields.effectiveDate],
			[DividendsReceivedFromFAFields.time]: dividend[DividendsReceivedFromFAFields.effectiveDate]
		};
	}
}