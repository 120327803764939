export enum IncomeTaxesPaidField {
	id = 'id',
	affiliateTaxYearEndId = 'affiliateTaxYearEndId',
	taxYearEnd = 'taxYearEnd',
	countryId = 'countryId',
	calculatingCurrencyId = 'calculatingCurrencyId',
	corporateIncomeTax = 'corporateIncomeTax',
	withholdingTaxCredit = 'withholdingTaxCredit',
	otherCorporateTax =	'otherCorporateTax',
	other =	'other',
	totalIncomeTaxesPaid =	'totalIncomeTaxesPaid',
	activeBusinessIncome=	'activeBusinessIncome',
	deemedActiveBusinessIncome =	'deemedActiveBusinessIncome',
	foreignAccrualPropertyIncome =	'foreignAccrualPropertyIncome',
	capitalGains =	'capitalGains',
	totalIncomeTaxesPaidProportion =	'totalIncomeTaxesPaidProportion',
	taxesPaidDeniedForFTXCredit = 'taxesPaidDeniedForFTXCredit',
	taxPaidOnCapitalGain = 'taxPaidOnCapitalGain',
}

