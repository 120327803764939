import { useEffect, useState } from 'react';
import { Utils } from 'src/common/utils/utils';
import { useTranslation } from 'react-i18next';
import { Button, Input, Modal, Select, TimePicker } from '@appkit4/react-components';
import { IOtherAdjustmentsFormProps } from './IOtherAdjustmentsFormProps';
import { InputError } from 'src/common/components/ModalControls/InputError/InputError';
import { AdjustmentFromSpecificTaxpayerFields } from 'src/common/types/enums/AdjustmentFromSpecificTaxpayerFields';
import { SelectValue } from '@appkit4/react-components/esm/combobox/Combobox';
import { AdjustmentFromSpecificTaxpayer, IAdjustmentFromSpecificTaxpayer } from
	'src/features/engagement/models/IAdjustmentFromSpecificTaxpayer';
import { CalendarPickerWrapper } from 'src/common/components/CalendarPickerWrapper/CalendarPickerWrapper';
import { RegularPatternEnum } from 'src/common/types/enums/RegularPatternEnum';
import './OtherAdjustmentsForm.scss';
import { UnsavedChangesModal } from 'src/core/components/Dashboard/unsavedModal/UnsavedChangesModal';
import { NotificationService } from 'src/core/services/notificationService';
import { DeleteModal } from 'src/common/components/DeleteModal/DeleteModal';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { UnexpectedErrorModal } from 'src/common/components/UnexpectedErrorModal/UnexpectedErrorModal';

const defaultFormError: Record<string, string | null> = {
	[AdjustmentFromSpecificTaxpayerFields.surplusPoolId]: null,
	[AdjustmentFromSpecificTaxpayerFields.adjustmentDate]: null,
	[AdjustmentFromSpecificTaxpayerFields.currencyId]: null,
	[AdjustmentFromSpecificTaxpayerFields.amount]: null,
	[AdjustmentFromSpecificTaxpayerFields.specificTo]: null,
	[AdjustmentFromSpecificTaxpayerFields.time]: null
};

export function OtherAdjustmentsForm(props: IOtherAdjustmentsFormProps): JSX.Element
{
	const [record, setRecord] = useState<IAdjustmentFromSpecificTaxpayer | undefined>(undefined);
	const [hasChange, setHasChange] = useState(false);
	const [displayUnsavedChangesModal, setDisplayUnsavedChangesModal] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [errorModalMessage, setErrorModalMessage] = useState('');
	const [formError, setFormError] = useState<Record<string, any>>(defaultFormError);
	const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
	const [allDescriptionOptions, setAllDescriptionOptions] = useState<any>();

	const { t } = useTranslation(
		'input',
		{
			keyPrefix: 'addAdjustmentsForm'
		}
	);
	const { t: fieldNames } = useTranslation(
		'input',
		{
			keyPrefix: 'adjustmentsFromSpecificTaxpayersFieldsNames'
		}
	);
	const { t: errorMessages } = useTranslation(
		'input',
		{
			keyPrefix: 'adjustmentsFormErrorMessages'
		}
	);


	const onCloseModal = (isSuccess?: boolean, isSpecificTo?: boolean): void =>
	{
		if (!!hasChange && !isSuccess)
		{
			setDisplayUnsavedChangesModal(true);
		}
		else
		{
			onClose(!!isSuccess, !!isSpecificTo);
		}
	};

	const onClose = async (isSuccess?: boolean, isSpecificTo?: boolean): Promise<void> =>
	{
		setDisplayUnsavedChangesModal(false);
		props.onToggleDisplayModal(false);

		await Utils.timeout(500);

		onReset();
		props.onReset();

		if (!!isSuccess)
		{
			props.onRefresh(!!isSpecificTo);
		}
	};

	const onCloseUnsavedChangesModal = (key: 'leave' | 'stay'): void =>
	{
		if (key === 'leave')
		{
			onClose();
		}
		else
		{
			setDisplayUnsavedChangesModal(false);
		}
	};

	const onReset = () =>
	{
		setIsSaving(false);
		setHasChange(false);
		setRecord(undefined);
		setIsSubmitted(false);
		setErrorModalMessage('');
		setDisplayDeleteModal(false);
		setDisplayUnsavedChangesModal(false);
		setFormError({
			...formError
		});
	};

	const onCreate = () =>
	{
		setIsSubmitted(true);

		if (!!checkUpdateFormValidity(record!))
		{
			setIsSaving(true);
			engagementService.createAdjustment(
					record!
			).then(() =>
			{
				onCloseModal(true, record?.specificTo);
				setIsSaving(false);
				onSuccessNotification({
					isCreate: true
				});
			})
				.catch( (error) =>
				{
					setErrorModalMessage(errorMessages('createFailed') || '');
					setIsSaving(false);
				});
		}
	};

	const onUpdate = () =>
	{
		setIsSubmitted(true);
		if (!!checkUpdateFormValidity(record!))
		{
			setIsSaving(true);
			engagementService.updateAdjustment({
				...record!,
				engagementId: props.engagementInfo?.id!,
				affiliateId: props.foreignAffiliate?.affiliateId!,
				taxpayerId: record?.specificTo ? props.engagementInfo?.taxPayerId : null
			}).then(() =>
			{
				onCloseModal(true, record?.specificTo);
				setIsSaving(false);

				onSuccessNotification({
					isUpdate: true
				});
			})
				.catch((error) =>
				{
					setErrorModalMessage(errorMessages('updateFailed') || '');
					setIsSaving(false);
				});
		}
	};

	const onDelete = () =>
	{
		setIsSubmitted(true);

		setDisplayDeleteModal(false);

		if (!!checkUpdateFormValidity(record!))
		{
			setIsSaving(true);

			engagementService.deleteAdjustment(
					props.engagementInfo?.id!,
					props.foreignAffiliate?.affiliateId!,
					record!.id
			).then(() =>
			{
				onCloseModal(true, record?.specificTo);
				setIsSaving(false);
				onSuccessNotification({
					isDelete: true
				});
			})
				.catch((error) =>
				{
					setErrorModalMessage(errorMessages('deleteFailed') || '');
					setIsSaving(false);
				});
		}
	};

	const onDisplayDeleteModal = (): void =>
	{
		setDisplayDeleteModal(true);
	};

	const onSuccessNotification = (
		{
			isCreate,
			isUpdate,
			isDelete
		}:
		{
			isCreate?: boolean;
			isUpdate?: boolean;
			isDelete?: boolean;
		}
	) =>
	{
		let message = '';

		if (isCreate)
		{
			message = t('createSuccess');
		}
		else if (isUpdate)
		{
			message = t('updateSuccess');
		}
		else if (isDelete)
		{
			message = t('deleteSuccess');
		}

		NotificationService.success({
			message
		});
	};

	const onValueChange = (field: string, value: any): void =>
	{
		let updatedForm: IAdjustmentFromSpecificTaxpayer = {
			...record!,
			[field]: value === '' ? undefined : value
		};
		if (field === AdjustmentFromSpecificTaxpayerFields.surplusPoolId && !!value && !!record &&
			record[AdjustmentFromSpecificTaxpayerFields.surplusPoolId] !== updatedForm[AdjustmentFromSpecificTaxpayerFields.surplusPoolId])
		{
			updatedForm = {
				...updatedForm!,
				[AdjustmentFromSpecificTaxpayerFields.descriptionId]: undefined
			};
		}
		checkUpdateFormValidity(updatedForm);
		setRecord(updatedForm);
	};

	const checkUpdateFormValidity = (form: IAdjustmentFromSpecificTaxpayer): boolean =>
	{
		const errors = {
			...defaultFormError
		};

		if (!form[AdjustmentFromSpecificTaxpayerFields.adjustmentDate])
		{
			errors[AdjustmentFromSpecificTaxpayerFields.adjustmentDate] = errorMessages('dateRequired');
		}

		if (!form[AdjustmentFromSpecificTaxpayerFields.surplusPoolId])
		{
			errors[AdjustmentFromSpecificTaxpayerFields.surplusPoolId] = errorMessages('surplusPoolRequired');
		}

		if (!form[AdjustmentFromSpecificTaxpayerFields.descriptionId])
		{
			errors[AdjustmentFromSpecificTaxpayerFields.descriptionId] = errorMessages('descriptionRequired');
		}

		if (!form[AdjustmentFromSpecificTaxpayerFields.currencyId])
		{
			errors[AdjustmentFromSpecificTaxpayerFields.currencyId] = errorMessages('currencyRequired');
		}

		if (!form[AdjustmentFromSpecificTaxpayerFields.amount])
		{
			errors[AdjustmentFromSpecificTaxpayerFields.amount] = errorMessages('amountRequired');
		}

		if (Utils.isNullUndefined(form[AdjustmentFromSpecificTaxpayerFields.specificTo]))
		{
			errors[AdjustmentFromSpecificTaxpayerFields.specificTo] = `${errorMessages('specificToRequired')} 
			${props.engagementInfo?.taxPayerName}`;
		}

		setFormError({
			...errors
		});

		// check if any fields have error messages and return status
		return Object.values(errors).every((e) => !e);
	};

	const filterSurplusDescriptions = (options: any[], id: number): any =>
	{
		return options?.reduce<any>((acc, option) =>
		{
			if (!!option.find((l: any) => l.surplusPoolId === id))
			{
				acc.push(...option);
			}
			return acc;
		}, []);
	};

	useEffect(
		() =>
		{
			if (!!props.record)
			{
				setRecord({
					...props.record
				});
			}
			else
			{
				setRecord(undefined);
			}
		},
		[props.record]
	);

	useEffect(
		() =>
		{
			setHasChange(
				!AdjustmentFromSpecificTaxpayer.isSame(props.record, record)
			);
		},
		[record]
	);

	useEffect(() =>
	{
		if (!!props.lineConfiguration)
		{
			setAllDescriptionOptions(props.lineConfiguration.some((l) => l.field === AdjustmentFromSpecificTaxpayerFields.descriptionId) ?
				props.lineConfiguration.find((l) => l.field === AdjustmentFromSpecificTaxpayerFields.descriptionId)!
					.options :
				[]);
		}
	},[props.lineConfiguration]);

	return (
		<>
			<Modal className={'adjustments-form-modal'}
				visible={
					props.displayModal &&
					!!props.record &&
					!!record
				}
				closeOnPressEscape={false}
				maskCloseable={false}
				initialFocus={false}
				title={
					!!props.isAdd ?
						t('addModalTitle') || '' :
						t('editModalTitle') || ''
				}
				onCancel={() =>
				{
					onCloseModal();
				}}
				footer={
					<div className={'buttons'}>
						<div className={'left'}>
							{
								!props.isAdd &&
								<Button
									kind={'negative'}
									disabled={!!isSaving}
									onClick={() => onDisplayDeleteModal()}
								>
									{
										t('deleteButtonText')
									}
								</Button>
							}
						</div>
						<div className={'right'}>
							{
								!!props.isAdd &&
								<Button
									disabled={
										!hasChange ||
										!!isSaving
									}
									onClick={onCreate}
								>
									{
										t('saveButtonText')
									}
								</Button>
							}
							{
								!props.isAdd &&
								<Button
									disabled={
										!hasChange ||
										!!isSaving
									}
									onClick={onUpdate}
								>
									{
										t('saveButtonText')
									}
								</Button>
							}
							<Button
								kind={'secondary'}
								disabled={!!isSaving}
								onClick={() =>
								{
									onCloseModal();
								}}
							>
								{
									t('cancelButtonText')
								}
							</Button>
						</div>
					</div>
				}>
				{
					!!props.record &&
					!!record &&
					!!props.lineConfiguration &&
					!!props.lineConfiguration.length &&
					<div className={'adjustments-modal-body'}>
						<div className={'adjustments-form-body'}>
							<div>
								<Select
									required
									searchable
									data={
										!!props.lineConfiguration &&
									props.lineConfiguration.some((l) => l.field === AdjustmentFromSpecificTaxpayerFields.surplusPoolId) ?
										props.lineConfiguration.find((l) => l.field === AdjustmentFromSpecificTaxpayerFields.surplusPoolId)!
											.options :
											[]
									}
									labelKey={'label'}
									valueKey={'value'}
									dropdownRenderMode={'portal'}
									value={record[AdjustmentFromSpecificTaxpayerFields.surplusPoolId]}
									placeholder={
										fieldNames(AdjustmentFromSpecificTaxpayerFields.surplusPoolId) || ''
									}
									error={
										!!isSubmitted &&
									!!formError &&
									!!formError[AdjustmentFromSpecificTaxpayerFields.surplusPoolId]
									}
									noResultFound={
										<>
											{
												t('noDataAvailable')
											}
										</>
									}
									onSelect={(value: SelectValue) =>
										onValueChange(AdjustmentFromSpecificTaxpayerFields.surplusPoolId, value)
									}
								/>
								<InputError
									isError={
										!!isSubmitted &&
									!!formError &&
									!!formError[AdjustmentFromSpecificTaxpayerFields.surplusPoolId]
									}
									message={formError[AdjustmentFromSpecificTaxpayerFields.surplusPoolId]}
								/>
							</div>
							<div>
								<Select
									required
									searchable
									disabled={!record[AdjustmentFromSpecificTaxpayerFields.surplusPoolId]}
									data={!!record[AdjustmentFromSpecificTaxpayerFields.surplusPoolId] ? filterSurplusDescriptions(
										allDescriptionOptions,record[AdjustmentFromSpecificTaxpayerFields.surplusPoolId]) : []}
									labelKey={'label'}
									valueKey={'value'}
									dropdownRenderMode={'portal'}
									value={!!record[AdjustmentFromSpecificTaxpayerFields.descriptionId] ?
										record[AdjustmentFromSpecificTaxpayerFields.descriptionId] : ''}
									placeholder={
										fieldNames(AdjustmentFromSpecificTaxpayerFields.descriptionId) || ''
									}
									error={
										!!isSubmitted &&
									!!formError &&
									!!formError[AdjustmentFromSpecificTaxpayerFields.descriptionId]
									}
									noResultFound={
										<>
											{
												t('noDataAvailable')
											}
										</>
									}
									onSelect={(value: SelectValue) =>
										onValueChange(AdjustmentFromSpecificTaxpayerFields.descriptionId, value)
									}
								/>
								<InputError
									isError={
										!!isSubmitted &&
									!!formError &&
									!!formError[AdjustmentFromSpecificTaxpayerFields.descriptionId]
									}
									message={formError[AdjustmentFromSpecificTaxpayerFields.descriptionId]}
								/>
							</div>
							<div className={'calendar-time'}>
								<CalendarPickerWrapper
									required
									useCustomValidation
									editable={false}
									fieldTitle={
										fieldNames(AdjustmentFromSpecificTaxpayerFields.adjustmentDate) || ''
									}
									format={'MMM DD, YYYY'}
									value={record[AdjustmentFromSpecificTaxpayerFields.adjustmentDate]}
									minDate={
										!!props.foreignAffiliate && !!props.foreignAffiliate.analysisStartDate ?
											props.foreignAffiliate.analysisStartDate :
											new Date(1972, 1, 1)
									}
									maxDate={
										!!props.engagementInfo && !!props.engagementInfo.lastCalculationTaxationYearEnd ?
											props.engagementInfo.lastCalculationTaxationYearEnd :
											new Date()
									}
									datePanelHorizontalAlign={'right'}
									error={
										!!isSubmitted &&
									!!formError &&
									!!formError[AdjustmentFromSpecificTaxpayerFields.adjustmentDate]
									}
									customErrorNode={<></>}
									onChange={(value: Date) =>
									{
										onValueChange(AdjustmentFromSpecificTaxpayerFields.adjustmentDate, new Date(value));
									}}
								/>
								<InputError
									isError={
										!!isSubmitted &&
									!!formError &&
									!!formError[AdjustmentFromSpecificTaxpayerFields.adjustmentDate]
									}
									message={formError[AdjustmentFromSpecificTaxpayerFields.adjustmentDate]}
								/>
							</div>
							<div className={'calendar-time'}>
								<TimePicker
									required
									hourTime={12}
									fieldTitle={
										fieldNames(AdjustmentFromSpecificTaxpayerFields.time) || ''
									}
									value={record[AdjustmentFromSpecificTaxpayerFields.time]}
									onChange={(value: Date) =>
									{
										onValueChange(AdjustmentFromSpecificTaxpayerFields.time, new Date(value));
									}}
									error={
										!!isSubmitted &&
									!!formError &&
									!!formError[AdjustmentFromSpecificTaxpayerFields.time]
									}
								/>
								<InputError
									isError={
										!!isSubmitted &&
									!!formError &&
									!!formError[AdjustmentFromSpecificTaxpayerFields.time]
									}
									message={formError[AdjustmentFromSpecificTaxpayerFields.time]}
								/>
							</div>
							<div>
								<Select
									required
									searchable
									data={
										!!props.lineConfiguration &&
									props.lineConfiguration.some((l) => l.field === AdjustmentFromSpecificTaxpayerFields.currencyId) ?
										props.lineConfiguration.find((l) => l.field === AdjustmentFromSpecificTaxpayerFields.currencyId)!
											.options :
											[]
									}
									labelKey={'label'}
									valueKey={'value'}
									dropdownRenderMode={'portal'}
									dropdownAlwaysDown={false}
									value={record[AdjustmentFromSpecificTaxpayerFields.currencyId]}
									placeholder={
										fieldNames(AdjustmentFromSpecificTaxpayerFields.currencyId) || ''
									}
									error={
										!!isSubmitted &&
									!!formError &&
									!!formError[AdjustmentFromSpecificTaxpayerFields.currencyId]
									}
									noResultFound={
										<>
											{
												t('noDataAvailable')
											}
										</>
									}
									onSelect={(value: SelectValue) =>
										onValueChange(AdjustmentFromSpecificTaxpayerFields.currencyId, value)
									}
								/>
								<InputError
									isError={
										!!isSubmitted &&
									!!formError &&
									!!formError[AdjustmentFromSpecificTaxpayerFields.currencyId]
									}
									message={formError[AdjustmentFromSpecificTaxpayerFields.currencyId]}
								/>
							</div>
							<div>
								<Input
									required
									type={'text'}
									title={
										fieldNames(AdjustmentFromSpecificTaxpayerFields.amount) || ''
									}
									value={
										record[AdjustmentFromSpecificTaxpayerFields.amount] !== undefined &&
									record[AdjustmentFromSpecificTaxpayerFields.amount] !== null ?
											`${record[AdjustmentFromSpecificTaxpayerFields.amount]}` :
											''
									}
									error={
										!!isSubmitted &&
									!!formError &&
									!!formError[AdjustmentFromSpecificTaxpayerFields.amount]
									}
									onChange={(value: string) =>
									{
										const regPattern = new RegExp(RegularPatternEnum.Currency);
										if (!regPattern.test(value))
										{
											return;
										}

										onValueChange(
											AdjustmentFromSpecificTaxpayerFields.amount,
											value
										);
									}}
								/>
								<InputError
									isError={
										!!isSubmitted &&
									!!formError &&
									!!formError[AdjustmentFromSpecificTaxpayerFields.amount]
									}
									message={formError[AdjustmentFromSpecificTaxpayerFields.amount]}
								/>
							</div>
							<div>
								<Select
									required
									data={
										!!props.lineConfiguration &&
									props.lineConfiguration.some((l) => l.field === AdjustmentFromSpecificTaxpayerFields.specificTo) ?
										props.lineConfiguration.find((l) => l.field === AdjustmentFromSpecificTaxpayerFields.specificTo)!
											.options :
											[]
									}
									labelKey={'label'}
									valueKey={'value'}
									dropdownRenderMode={'portal'}
									dropdownAlwaysDown={false}
									value={
										record[AdjustmentFromSpecificTaxpayerFields.specificTo] ? 'true' :
											record[AdjustmentFromSpecificTaxpayerFields.taxpayerId] === null ||
											 record[AdjustmentFromSpecificTaxpayerFields.specificTo] === false ? 'false':''
									}
									placeholder={
										`${fieldNames(AdjustmentFromSpecificTaxpayerFields.specificTo)} 
										${!!props.engagementInfo && props.engagementInfo.taxPayerName}`  || ''
									}
									error={
										!!isSubmitted &&
									!!formError &&
									!!formError[AdjustmentFromSpecificTaxpayerFields.specificTo]
									}
									noResultFound={
										<>
											{
												t('noDataAvailable')
											}
										</>
									}
									onSelect={(value: SelectValue) =>
									{
										onValueChange(
											AdjustmentFromSpecificTaxpayerFields.specificTo,
											JSON.parse(value.toString().toLowerCase())
										);
									}}
								/>
								<InputError
									isError={
										!!isSubmitted &&
									!!formError &&
									!!formError[AdjustmentFromSpecificTaxpayerFields.specificTo]
									}
									message={formError[AdjustmentFromSpecificTaxpayerFields.specificTo]}
								/>
							</div>
						</div>
					</div>
				}
			</Modal>
			<UnsavedChangesModal
				visible={displayUnsavedChangesModal}
				onClose={onCloseUnsavedChangesModal}
				title={t('discardChangesTitle') || ''}
			/>
			<DeleteModal
				visible={displayDeleteModal}
				title={t('deleteModalTitle')}
				deleteMessage={t('deleteMessage')}
				setVisible={setDisplayDeleteModal}
				onDelete={onDelete}
			/>
			<UnexpectedErrorModal
				visible={!!errorModalMessage}
				error={''}
				message={errorModalMessage}
				onClose={() =>
				{
					setErrorModalMessage('');
				}}
			/>
		</>
	);
}