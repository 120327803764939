import {
	Button,
	Checkbox,
	Search,
	Badge,
	Combobox,
	ItemDataType,
	Loading
} from '@appkit4/react-components';

import React, { useEffect, useState } from 'react';
import './CalculationTypeList.scss';
import { SelectValue } from '@appkit4/react-components/esm/combobox/Combobox';
import Highlighter from 'react-highlight-words';
import { engagementService } from 'src/features/engagement/services/engagementService';
import EditCalculationTypeModal from '../EditCalculationTypeModal/EditCalculationTypeModal';
import { ActionEnum } from 'src/common/types/enums/ActionEnum';
import { EngagementStepEnum } from '../../../../models/EngagementStepEnum';

interface CalculationType {
  calculationTypes: [string];
}
const defaultValue: CalculationType = {
	calculationTypes: ['']
};

export const CalculationTypeList = (props: {
  clientId: number | undefined;
  engagementId: number | undefined;
  currentStep: number;
  action: ActionEnum;
  onAction: (action: ActionEnum, result: boolean) => void;
  onDataLoading: (diabledStatus: boolean) => void;
}) =>
{
	const [displayErrors, setDisplayErrors] = useState<boolean>(false);
	const [errorNodeRows, setErorrNodeRows] = useState<Array<string>>([]);
	const [rowData, setRowData] = useState<any[]>([]);
	const [filteredData, setFilteredData] = useState<any[]>([]);
	const [selectedRows, setSelectedRows] = useState<Array<string>>([]);
	const [calculationTypes, setCalculationTypes] = useState([]);
	const [searchText, setSearchText] = useState<string>('');
	const [showEditCalculationTypeModal, setShowEditCalculationTypeModal] =
    useState<boolean>(false);
	const [calTypeModalContext] = useState<CalculationType>(defaultValue);
	const [loading, setLoading] = useState<boolean>(false);

	const updateRowData = (affiliateId: string, calculationTypeIds: any) =>
	{
		const updatedRowData = rowData.map((row: any) =>
		{
			if (row.affiliateId.toString()===affiliateId.toString())
			{
				return {
					...row,
					'calculationTypeIds': calculationTypeIds
				};
			}
			else
			{
				return row;
			}
		});

		setRowData(updatedRowData);
	};

	const updateCalculationtypes = (affiliateId: string, calcTypeIds: number[]) =>
	{
		const updatedItem: any = {
			calculationTypeIds: calcTypeIds,
			affiliateId: affiliateId,
			engagementId: props.engagementId
		};

		engagementService
			.updateFACalculationType(updatedItem)
			.then((res: any) =>
			{
				updateRowData(affiliateId, calcTypeIds);
			});
	};

	const saveCalculationTypes = (visible: boolean, affiliateId: string) =>
	{
	   if (!visible)
		{
			const calcTypeIds = rowData.find((row: any) => row.affiliateId.toString()===affiliateId.toString()).calculationTypeIds;
			if  (!!calcTypeIds && calcTypeIds.length > 0)
			{
				updateCalculationtypes(affiliateId, calcTypeIds);
			}
		}
	};

	const handleClear = (affiliateId: string) =>
	{
		updateRowData(affiliateId,[]);
	};


	useEffect(() =>
	{
		engagementService.getCalculationTypes().then((res: any) =>
		{
			const result = !!res.data &&
				!!res.data.result &&
				!!res.data.result.length ?
				res.data.result :
				[];

			if (result?.length > 0)
			{
				const updatedData = result.reduce(
					(
						formatted: Array<Record<string, any>>,
						item: Record<string, any>
					) =>
					{
						const updatedItem = {
							label: `${item.description} (${item.code})`,
							value: item.id,
							desc: item.description
						};
						formatted.push(updatedItem);
						return formatted;
					},
					[]
				);

				setCalculationTypes(updatedData);
			}
		});
	}, []);

	useEffect(() =>
	{
		return () => props.onDataLoading(false);
	},[]);

	useEffect(() =>
	{
		if (props.engagementId)
		{
			setLoading(true);
			props.onDataLoading(true);
			engagementService
				.getForeignAffiliatesByEngagementId(props.engagementId)
				.then((res: any) =>
				{
					const result = res.data.result;
					if (result?.length > 0)
					{
						setRowData(result);
						props.onDataLoading(false);
					}
					setLoading(false);
				})
				.catch((error: any) =>
				{
					setLoading(false);
				});
		}
	}, [props.engagementId]);

	useEffect(() =>
	{
		const errorNodeRows = rowData?.reduce((formatted, item) =>
		{
			if (!!item['calculationTypeIds'] && item['calculationTypeIds'].length < 1)
			{
				formatted.push(item['affiliateId']);
			}
			return formatted;
		}, []);
		setErorrNodeRows(errorNodeRows);
	}, [rowData]);

	useEffect(() =>
	{
		const filteredItem = rowData.filter((x) =>
			x['name'].toLowerCase().includes(searchText.trim().toLowerCase())
		);
		setFilteredData(filteredItem);
	}, [rowData, searchText]);

	const onClear = () =>
	{
		setSearchText('');
		setSelectedRows([]);
	};

	const closeEditCalculationTypeModal = (updatedDataItems: any) =>
	{
		setShowEditCalculationTypeModal(false);

		if (!!updatedDataItems)
		{
			const { foreignAffiliates, calculationTypeIds } = updatedDataItems;
			const selectedRows: Array<string> = [];
			const updatedRowData = rowData.reduce((formatted, dataItem) =>
			{
				const rowDataItemId = dataItem['affiliateId'];
				if (foreignAffiliates.includes(rowDataItemId) && !!calculationTypeIds)
				{
					dataItem = {
						...dataItem,
						calculationTypeIds: calculationTypeIds
					};
					selectedRows.push(rowDataItemId);
				}

				formatted.push(dataItem);
				return formatted;
			}, []);

			const prevRowData = rowData;
			setSelectedRows(selectedRows);
			setRowData(updatedRowData);
			engagementService
				.updateBulkFACalculationType({
					engagementId: props.engagementId,
					affiliates: updatedDataItems.foreignAffiliates,
					calculationTypeIds: updatedDataItems.calculationTypeIds
				})
				.then((res: any) =>
				{

				})
				.catch((err: any) => setRowData(prevRowData));
		}
	};

	useEffect(() =>
	{
		if (props.currentStep === EngagementStepEnum.CalculationTypes)
		{
			switch (props.action)
			{
			case ActionEnum.Next:
				if (errorNodeRows.length > 0)
				{
					setDisplayErrors(true);
					props.onAction(props.action, false);
				}
				else
				{
					props.onAction(props.action, true);
				}

				break;
			case ActionEnum.Back:
				props.onAction(props.action, true);
				break;
			case ActionEnum.Draft:
				props.onAction(props.action, true);
				break;
			}
		}
	}, [props.action]);

	return (
		<div className="calculation-list-wrapper">
			<div className="title"> {'Select Calculation Types'}</div>
			<div className="cal-grid-toolbar">
				<div className="search-sample">
					<Search
						searchType={'secondary'}
						onChange={(
							value: string,
							event: React.SyntheticEvent | React.ChangeEvent<HTMLInputElement>
						) =>
						{
							setSearchText(value.toLowerCase());
							setSelectedRows([]);
						}}
						onClear={onClear}
						placeholder={'Search Foreign Affiliate'}
					/>
				</div>
				<div className="flex-button">
					<Button
						kind="secondary"
						disabled={selectedRows.length < 1}
						onClick={() =>
						{
							setShowEditCalculationTypeModal(true);
						}}
					>
						{'Edit'}
					</Button>
				</div>
			</div>
			<div
				className="cal-grid-wrapper"
				style={{
					height: loading ? '62vh' : ''
				}}
			>
				{loading && (
					<div className="loading-icon">
						<Loading
							loadingType="circular"
							indeterminate={true}
							compact={false}
						/>
					</div>
				)}
				<div className="header-row row">
					<div className="checkbox-col">
						<Checkbox
							checked={
								selectedRows.length > 0 &&
                selectedRows.length === rowData.length
							}
							indeterminate={
								selectedRows.length > 0 && selectedRows.length < rowData.length
							}
							onChange={(checked: boolean) =>
							{
								let updatedSelectedRows = [];
								if (checked)
								{
									updatedSelectedRows = filteredData.reduce(
										(formatted, item) =>
										{
											if (!formatted.includes(item?.['affiliateId']))
											{
												formatted.push(item?.['affiliateId']);
											}

											return formatted;
										},
										[...selectedRows]
									);
								}
								else
								{
									updatedSelectedRows = filteredData.reduce(
										(formatted, item) =>
										{
											if (selectedRows.includes(item?.['affiliateId']))
											{
												formatted = formatted.filter(
													(x: any) => x !== item?.['affiliateId']
												);
											}

											return formatted;
										},
										[...selectedRows]
									);
								}
								setSelectedRows(updatedSelectedRows);
							}}
						/>
					</div>
					<div className="first-col">
						<div className="header-title">Foreign Affiliate Name</div>
					</div>
					<div className="second-col">
						<div className="header-title">Calculation Type</div>
					</div>
				</div>
				<div className="cal-content-wrapper">
					{!!filteredData &&
            filteredData?.length > 0 &&
            filteredData?.map((item, index) =>
            {
            	const faName = item['name'];
            	const itemId: string = item['affiliateId'];
            	const showErrorNode: boolean =
                displayErrors && errorNodeRows?.includes(itemId);
            	let calculationView = null;

            	calculationView = (
            		<div
            			key={itemId}
            			className={`cal-combobox ${
            				showErrorNode ?
            					'' :'hoverable-div'
            			}`}
            		>
            			<Combobox
            				className={`${showErrorNode ? 'error' : ''}`}
            				data={calculationTypes}
            				valueKey={'value'}
            				labelKey={'label'}
            				value={item['calculationTypeIds']}
            				placeholder=" "
            				multiple
            				showSelectAll={true}
            				dropdownMatchWidth={true}
            				valueTemplate={(
            					value: SelectValue,
            					item: ItemDataType | ItemDataType[]
            				) =>
            				{
            					const selectedValue = (
            						<div className="selected-val">
            							{item.map((val: any) =>
            								<Badge
            									key={`badge1-${itemId}-${index}`}
            									value={val?.desc || ''}
            									type={'info'}
            								/>
            							)}
            						</div>
            					);
            					return selectedValue;
            				}}
            				onSelect={(value: SelectValue) =>
            					updateRowData(itemId, value)
            				}
            				onVisibleChange={(visible: boolean) =>
            					saveCalculationTypes(visible, itemId)
            				}
            				onClear={() => handleClear(itemId)}
            				dropdownRenderMode="portal"
            			></Combobox>
            		</div>
            	);


            	return (
            		<>
            			<div
            				key={`${faName}-${index}`}
            				className={`container-rows row ${
            					index % 2 === 0 ? 'even-row' : 'odd-row'
            				} `}
            			>
            				<div className="checkbox-col">
            					<Checkbox
            						checked={selectedRows.includes(itemId)}
            						onChange={(checked: boolean) =>
            						{
            							const filteredSelectedRows = selectedRows.filter(
            								(f) => f !== itemId
            							);
            							if (checked)
            							{
            								setSelectedRows([
            									...filteredSelectedRows,
            									itemId
            								]);
            							}
            							else
            							{
            								setSelectedRows(filteredSelectedRows);
            							}
            						}}
            					/>
            				</div>
            				<div className="first-col">
            					<div className="header-title text-wrap">
            						<span className="wrap-text"
            							title={
            								!!faName && faName.length > 0 ?  faName : ''
            							}>
            							<Highlighter
            								highlightClassName="highlightClass"
            								searchWords={[searchText?.trim()]}
            								autoEscape={true}
            								textToHighlight={faName ? faName : '-'}
            							/>
            						</span>
            					</div>
            				</div>
            				<div className={'second-col'}>{calculationView}</div>
            			</div>
            			{showErrorNode && (
            				<div
            					className={'error-node ap-field-email-validation-error'}
            				>
                      Please select calculation type
            				</div>
            			)}
            		</>
            	);
            })}
					{filteredData?.length === 0 && <div>No data available</div>}
				</div>
			</div>
			<EditCalculationTypeModal
				visible={showEditCalculationTypeModal}
				onClose={closeEditCalculationTypeModal}
				calTypeModalContext={calTypeModalContext}
				calculationTypes={calculationTypes}
				selectedFA={selectedRows}
				setSelectedFA={setSelectedRows}
				rowData={rowData}
				setVisible={setShowEditCalculationTypeModal}
			/>
		</div>
	);
};
