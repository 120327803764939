import React, { useEffect, useRef, useState } from 'react';

import './ForeignAffiliates.scss';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import {
	Grid,
	GridColumn,
	GridNoRecords,
	GridSortChangeEvent,
	GridRowProps,
	GridPageChangeEvent
} from '@progress/kendo-react-grid';
import { Checkbox } from '@appkit4/react-components/checkbox';
import { useAppSelector } from 'src/store/store';
import { Button, DropdownButton, ItemDataType, Loading, Search, ValueType } from '@appkit4/react-components';

import { DropdownEditorCell } from 'src/common/components/Editors/DropdownEditor/DropdownEditorCell';
import { TextEditorCell } from 'src/common/components/Editors/TextEditor/TextEditorCell';
import { DatePickerEditorCell } from 'src/common/components/Editors/DatePickerEditor/DatePickerEditorCell';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { IForeignAffiliate } from 'src/features/engagement/models/IForeignAffiliate';
import { SortableHeaderCell } from 'src/common/components/Renderers/SortableHeaderCell';
import { ICurrency } from 'src/common/types/interfaces/ICurrency';
import { INameId } from 'src/common/types/interfaces/INameId';
import { RoleEnum } from 'src/common/types/enums/RoleEnum';
import { ActionEnum } from 'src/common/types/enums/ActionEnum';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import { EngagementStepSubject } from 'src/core/services/EventBus';
import { EVentTypeEnum } from 'src/common/types/enums/EventTypeEnum';
import { useTranslation } from 'react-i18next';
import { Constants } from 'src/common/types/constants';
import { ForeignAffiliateFields } from 'src/common/types/enums/ForeignAffiliateFields';
import { IEngagementDetail } from 'src/features/engagement/models/IEngagementDetail';
import { EngagementStepEnum } from 'src/features/engagement/models/EngagementStepEnum';
import { AddFAModal } from '../../EngagementSetup/ForeignAffiliates/AddFAModal/AddFAModal';
import { FAEditingWarningModal } from '../../FAEditingWarningModal/FAEditingWarningModal';
import { BulkEditFAModal } from '../../EngagementSetup/ForeignAffiliates/BulkEditFAModal/BulkEditFAModal';
import { DeleteFAModal } from '../../EngagementSetup/ForeignAffiliates/DeleteFAModal/DeleteFAModal';
import { CalculationTypesEnum } from 'src/common/types/enums/CalculationTypes';
import { useNavigate } from 'react-router-dom';
import { ReportEnum } from 'src/common/types/enums/ReportEnum';
import { PagerTargetEvent } from '@progress/kendo-react-data-tools';
import { CheckboxEditorCell } from 'src/common/components/Editors/CheckboxEditor/CheckboxEditorCell';

interface PageState {
	skip: number;
	take: number;
}

const initialDataState: PageState = {
	skip: 0,
	take: 10
};

export type EngagementState = {
	clientId?: number | undefined;
	clientName?: string | undefined;
	engagementId?: number | undefined;
};

enum FAMenuEnum {
	AddNewFA = 'AddNewFA',
	AddExistingFA = 'AddExistingFA'
};

export const ForeginAffiliates = (props: {
	clientId: number | undefined;
	engagementId: number | undefined;
	engagementDetail?: IEngagementDetail | undefined;
	foreignAffiliates: IForeignAffiliate[] | undefined;
	onRequireFAData: (value: boolean) => void;
}) =>
{
	const navigate = useNavigate();
	const calculationTypes = useAppSelector((state) => state.appSettings.calculationTypes);

	const [rowData, setRowData] = useState<IForeignAffiliate[]>([]);
	const [displayError, setDisplayError] = useState<boolean>(false);
	const [filterRowData, setFilterRowData] = useState<IForeignAffiliate[]>([]);
	const [bulkUpdateFA, setBulkUpdateFA] = useState<IForeignAffiliate | undefined>(undefined);

	const [errorExisting, setErrorExisting] = useState<boolean>(false);
	const [relevantDatesWarning, setRelevantDatesWarning] = useState<boolean>(false);
	const rowDataRef = useRef(rowData);
	const countries = useAppSelector((state) => state.appSettings.countries);
	const currencies = useAppSelector((state) => state.appSettings.currencies);
	const [sort, setSort] = useState<SortDescriptor[]>();
	const userProfile = useAppSelector((state) => state.userProfile.profile);
	const [showAddFAModal, setShowAddFAModal] = useState<boolean>(false);
	const [addFaModalApiErrors, setAddFaModalApiErrors] = useState<any>([]);

	const [editableRowIndex, setEditableRowIndex] = useState<number | null>(null);
	const editableRowIndexRef = useRef(editableRowIndex);
	const [loading, setLoading] = useState<boolean>(false);

	const [showFAEditingWarningModal, setShowFAEditingWarningModal] =
		useState<boolean>(false);
	const [showFABulkEditingWarningModal, setShowFABulkEditingWarningModal] =
		useState<boolean>(false);
	const [showBulkEditFAModal, setShowBulkEditFAModal] = useState<boolean>(false);
	const [isBulkUpdated, setIsBulkUpdated] = useState<boolean>(false);
	const [showDeleteFAModal, setShowDeleteFAModal] = useState<boolean>(false);

	const [triggerEditField, setTriggerEditField] = useState<string>('');

	const [searchText, setSearchText] = useState<string>('');

	const [editingRow, setEditingRow] = useState<IForeignAffiliate | undefined>(
		undefined
	);
	const [isAddingNewFA, setIsAddingNewFA] = useState<boolean>(false);

	const [selection, setSelection] = useState<boolean>(false);
	const [, setSelectAll] = useState<boolean>(false);
	const [selectedRows, setSelectedRows] = useState<any[]>([]);

	const editingRowRef = useRef(editingRow);

	const SELECTED_FIELD: string = 'selected';

	const [page, setPage] = React.useState<PageState>(initialDataState);
	const [pageSizeValue, setPageSizeValue] = React.useState<
		number | string | undefined
	>();

	const { t } = useTranslation('engagementSetup');
	const { t: errorMessages } = useTranslation('errors');

	const defaultFaErrors = {
		clientSideErr: {
			name: t('addForeignAffiliates.error.name'),
			countryId: t('addForeignAffiliates.error.countryId'),
			currencyId: t('addForeignAffiliates.error.currencyId'),
			taxationYearEnd: t('addForeignAffiliates.error.taxationYearEnd'),
			dateBecameFa: t('addForeignAffiliates.error.dateBecameFa'),
			analysisStartDate: t('addForeignAffiliates.error.analysisStartDate'),
			analysisEndDate: t('addForeignAffiliates.error.analysisEndDate'),
			surplus: t('addForeignAffiliates.error.surplus')
		},
		apiErr: {
			name: null
		}
	};

	useEffect(() =>
	{
		props.onRequireFAData(relevantDatesWarning || displayError);
	}, [relevantDatesWarning, displayError]);


	useEffect(() =>
	{
		editableRowIndexRef.current = editableRowIndex;
		if (editableRowIndex)
		{
			setTimeout(() =>
			{
				const fnInput = document.querySelector(
					'.fn-name-input input'
				) as HTMLInputElement;
				if (fnInput)
				{
					requestAnimationFrame(() =>
					{
						fnInput.focus();
					});
				}
			}, 500);
		}
	}, [editableRowIndex]);

	const initialFaErrors = {
		clientSideErr: {
			name: '',
			countryId: '',
			currencyId: '',
			taxationYearEnd: '',
			dateBecameFa: '',
			analysisStartDate: '',
			analysisEndDate: '',
			surplus: ''
		},
		apiErr: {
			name: ''
		}
	};
	const [faErrors, setFaErrors] = useState<any>(undefined);

	const faErrorsRef = useRef(faErrors);

	useEffect(() =>
	{
		var elems: any = document.querySelector(
			'.k-grid-container .k-grid-table-wrap'
		);
		if (filterRowData.length > 0)
		{
			elems.style.height = 'fit-content';
		}
		else
		{
			elems.style.height = '100%';
		}
	}, [filterRowData]);

	useEffect(() =>
	{
		setErrorExisting(!!faErrors);
		faErrorsRef.current = faErrors;
	}, [faErrors]);

	useEffect(() =>
	{
		if (editingRow)
		{
			if (editingRowRef.current)
			{
				if (
					!editingRowRef.current.showedWarning &&
					editingRow?.showedWarning &&
					editingRowRef.current.affiliateId === editingRow.affiliateId
				)
				{
					setShowFAEditingWarningModal(true);
				}
				if (
					editingRow?.showedWarning &&
					editingRowRef.current.affiliateId !== editingRow.affiliateId
				)
				{
					setShowFAEditingWarningModal(true);
				}
			}
			else
			{
				if (editingRow && editingRow.showedWarning)
				{
					setShowFAEditingWarningModal(true);
				}
			}
		}
		editingRowRef.current = editingRow;
	}, [editingRow]);

	const mapForeignAffiliates = (data: any) =>
	{
		if (!!data)
		{
			const fas = data.map((fa: any) =>
			{
				const f: IForeignAffiliate = {
					affiliateId: fa.affiliateId,
					name: fa.name,
					currencyId: fa.currencyId,
					countryId: fa.countryId,
					taxationYearEnd: dateFormatter.toStartOfDayLocal(fa.taxationYearEnd),
					dateBecameFa: (new Date(fa.dateBecameFa).getFullYear() <= Constants.MinYear) ?
						undefined : dateFormatter.toStartOfDayLocal(fa.dateBecameFa),
					analysisStartDate: (new Date(fa.analysisStartDate).getFullYear() <= Constants.MinYear) ?
						undefined : dateFormatter.toStartOfDayLocal(fa.analysisStartDate),
					analysisEndDate: dateFormatter.utcToEst(fa.analysisEndDate),
					inEditableMode: false,
					isNewItem: false,
					selected: false,
					showedWarning: false,
					isSystemAdded: fa.isSystemAdded,
					surplus: fa.calculationTypeIds.includes(
						calculationTypes.find((c) => c.code === CalculationTypesEnum.Surplus)?.id),
					enhancedSurplus: fa.calculationTypeIds.includes(
						calculationTypes.find((c) => c.code === CalculationTypesEnum.EnhancedSurplus)?.id),
					FAPI: fa.calculationTypeIds.includes(
						calculationTypes.find((c) => c.code === CalculationTypesEnum.FAPI)?.id),
					ACB: fa.calculationTypeIds.includes(
						calculationTypes.find((c) => c.code === CalculationTypesEnum.ACB)?.id)
				};
				return f;
			});
			return fas;
		}
	};

	const loadForeignAffiliates = () =>
	{
		if (props.engagementId)
		{
			setLoading(true);
			engagementService
				.getForeignAffiliatesByEngagementId(props.engagementId)
				.then((res: any) =>
				{
					if (res.data.result)
					{
						const fas = mapForeignAffiliates(res.data.result);
						setRowData(fas);
					}
					setLoading(false);
					setIsAddingNewFA(false);
					setEditingRow(undefined);
				})
				.catch((error: any) =>
				{
					setLoading(false);
				});
		}
	};

	useEffect(() =>
	{
		setLoading(true);
		if (!!props.foreignAffiliates)
		{
			const fas = mapForeignAffiliates(props.foreignAffiliates);
			setRowData(fas);
			setLoading(false);
			setIsAddingNewFA(false);
			setEditingRow(undefined);
		}
	}, [props.foreignAffiliates, calculationTypes]);

	useEffect(() =>
	{
		setRelevantDatesWarning(rowData.some((row: IForeignAffiliate) => row.isSystemAdded === true &&
			(row.analysisStartDate === undefined || row.dateBecameFa === undefined)));

		rowDataRef.current = rowData;

		const selectedData = rowData.filter((r: any) => r.selected === true);
		setSelection(selectedData.length > 0);
		setSelectedRows(selectedData);
		setSelectAll(
			selectedData.length > 0 && selectedData.length === rowData.length
		);

		if (hasError())
		{
			validateAndSave(undefined);
		}
		else
		{
			if (rowData)
			{
				if (searchText === '')
				{
					setFilterRowData(rowData);
				}
				else
				{
					const updatedFilterRowData = rowDataRef.current.filter(
						(row: any) =>
							!row.affiliateId ||
							row.name.toLowerCase().includes(searchText.trim().toLowerCase())
					);
					setFilterRowData(updatedFilterRowData);
				}
			}
		}
		if (rowData.length === 0)
		{
			setDisplayError(true);
		}
		else
		{
			setDisplayError(false);
		}
	}, [rowData]);

	useEffect(() =>
	{
		if (rowData)
		{
			handleSelectAll(false);
		}
	}, [searchText]);

	const onSortChange = (event: GridSortChangeEvent) =>
	{
		setSort(event.sort);
		if (event.sort[0].field === ForeignAffiliateFields.countryId)
		{
			const rows = rowDataRef.current.map((r: any) =>
			{
				const c = countries.find((c: INameId) => c.id === r.countryId);
				return {
					...r,
					[`${event.sort[0].field}Name`]: c?.name
				};
			});
			const newSort = [
				{
					dir: event.sort[0].dir,
					field: event.sort[0].field + 'Name'
				}
			];
			const sortedRowData = orderBy(rows, newSort);
			setRowData(sortedRowData);
		}
		else if (event.sort[0].field === ForeignAffiliateFields.currencyId)
		{
			const rows = rowDataRef.current.map((r: any) =>
			{
				const c = currencies.find(
					(c: ICurrency) => c.id === r.currencyId
				);
				return {
					...r,
					[`${event.sort[0].field}Name`]: c?.code
				};
			});
			const newSort = [
				{
					dir: event.sort[0].dir,
					field: event.sort[0].field + 'Name'
				}
			];
			const sortedRowData = orderBy(rows, newSort);
			setRowData(sortedRowData);
		}
		else
		{
			const sortedRowData = orderBy(rowData, event.sort);
			setRowData(sortedRowData);
		}
	};

	const ValidateEditingFA = () =>
	{
		const validationError = {
			...initialFaErrors
		};
		const newFA = rowDataRef.current.find((r) => r.inEditableMode === true);
		if (!newFA)
		{
			return true;
		}
		validationError.clientSideErr.name =
			newFA.name === '' ? defaultFaErrors.clientSideErr.name : '';

		if (newFA.name)
		{
			//check whether it is updated
			const duplicatedFA = rowDataRef.current.find(
				(r) =>
					r.inEditableMode === false && r.name.trim() === newFA.name.trim()
			);
			if (duplicatedFA)
			{
				validationError.clientSideErr.name = t('addForeignAffiliates.duplicateName');
			}
		}

		validationError.clientSideErr.currencyId =
			!newFA.currencyId ?
				defaultFaErrors.clientSideErr.currencyId :
				'';

		validationError.clientSideErr.countryId = !newFA.countryId ?
			defaultFaErrors.clientSideErr.countryId :
			'';

		validationError.clientSideErr.taxationYearEnd = !newFA.taxationYearEnd ?
			defaultFaErrors.clientSideErr.taxationYearEnd :
			'';

		validationError.clientSideErr.analysisStartDate = !newFA.analysisStartDate ?
			defaultFaErrors.clientSideErr.analysisStartDate :
			'';

		validationError.clientSideErr.analysisEndDate = newFA.analysisStartDate && newFA.analysisEndDate &&
			newFA.analysisEndDate < newFA.analysisStartDate ?
			defaultFaErrors.clientSideErr.analysisEndDate :
			'';

		validationError.clientSideErr.dateBecameFa = !newFA.dateBecameFa ?
			defaultFaErrors.clientSideErr.dateBecameFa :
			'';

		validationError.clientSideErr.surplus = !newFA.surplus && !newFA.enhancedSurplus && !newFA.FAPI && !newFA.ACB ?
			defaultFaErrors.clientSideErr.surplus :
			'';


		if (
			!newFA.name ||
			!newFA.currencyId ||
			!newFA.countryId ||
			!newFA.taxationYearEnd ||
			!newFA.analysisStartDate ||
			validationError.clientSideErr.analysisEndDate ||
			!newFA.dateBecameFa ||
			(!newFA.surplus && !newFA.enhancedSurplus && !newFA.FAPI && !newFA.ACB) ||
			validationError.clientSideErr.name
		)
		{
			setFaErrors(validationError);
			return false;
		}
		else
		{
			setFaErrors(undefined);
			return true;
		}
	};

	const handleAPIError = (apierror: any) =>
	{
		const errors = apierror.response.data.Errors;
		if (errors)
		{
			const validationError = {
				...initialFaErrors
			};
			errors.forEach((error: any) =>
			{
				if (error.ErrorCode === 'FOREIGNAFFILIATE_DUPLICATE')
				{
					validationError.clientSideErr.name = errorMessages(`code.${error.ErrorCode}`);
				}
				if (error.ErrorCode === 'INVALID_ANALYSIS_END_DATE')
				{
					validationError.clientSideErr.analysisEndDate = errorMessages(`code.${error.ErrorCode}`);
				}
				if (error.ErrorCode === 'INVALID_ANALYSIS_START_DATE_BEFORE_CORP_FA')
				{
					validationError.clientSideErr.analysisStartDate = errorMessages(`code.${error.ErrorCode}`);
				}
				if (error.ErrorCode === 'INVALID_ANALYSIS_START_DATE_AFTER_END_DATE')
				{
					validationError.clientSideErr.analysisStartDate = errorMessages(`code.${error.ErrorCode}`);
				}
				if (error.ErrorCode === 'EMPTY_CALCULATION_TYPE')
				{
					validationError.clientSideErr.surplus = errorMessages(`code.${error.ErrorCode}`);
				}
			});
			setFaErrors(validationError);
		}
	};

	const SaveNewFA = async (editingId: number | undefined) =>
	{
		const newFA = rowDataRef.current.find((r) => r.inEditableMode === true);
		if (newFA)
		{
			engagementService.addNewFA(
				props.clientId,
				props.engagementId,
				newFA.name,
				newFA.countryId,
				newFA.currencyId,
				newFA.taxationYearEnd,
				newFA.dateBecameFa,
				newFA.analysisStartDate,
				newFA.analysisEndDate,
				getFACalculationTypes(newFA)
			).then((res: any) =>
			{
				EngagementStepSubject.next({
					eventType: EVentTypeEnum.EngagementStepper,
					payload: EngagementStepEnum.AddForeignAffiliates
				});
				setEditableRowIndex(null);
				setFaErrors(undefined);

				setTriggerEditField('');
				loadForeignAffiliates();
			})
				.catch((error: any) =>
				{
					handleAPIError(error);
					return false;
				}
				);
		}
	};


	const hasChangeOnEditing = () =>
	{
		const editingFA = rowDataRef.current.find((r) => r.inEditableMode === true);
		if (editingFA && editingRowRef.current)
		{
			if (
				editingFA.name !== editingRowRef.current.name ||
				editingFA.countryId !== editingRowRef.current.countryId ||
				editingFA.currencyId !==
				editingRowRef.current.currencyId ||
				editingFA.taxationYearEnd?.toISOString() !==
				dateFormatter.toStartOfDayLocal(editingRowRef.current.taxationYearEnd ?? '')?.toISOString() ||
				editingFA.analysisStartDate?.toISOString() !==
				dateFormatter.toStartOfDayLocal(editingRowRef.current.analysisStartDate ?? '')?.toISOString() ||
				editingFA.dateBecameFa?.toISOString() !==
				dateFormatter.toStartOfDayLocal(editingRowRef.current.dateBecameFa ?? '')?.toISOString() ||
				editingFA.surplus !== editingRowRef.current.surplus ||
				editingFA.enhancedSurplus !== editingRowRef.current.enhancedSurplus ||
				editingFA.FAPI !== editingRowRef.current.FAPI ||
				editingFA.ACB !== editingRowRef.current.ACB
			)
			{
				return true;
			}
			else
			{
				return true;
			}
		}
		else
		{
			return true;
		}
	};

	const getFACalculationTypes = (fa: IForeignAffiliate): number[] =>
	{
		const updatedCalculationTypes: number[] = [];

		if (calculationTypes && calculationTypes.length > 0)
		{
			if (fa.surplus)
			{
				updatedCalculationTypes.push(calculationTypes.find((c) => c.code === CalculationTypesEnum.Surplus)?.id!);
			}

			if (fa.enhancedSurplus)
			{
				updatedCalculationTypes.push(calculationTypes.find((c) => c.code === CalculationTypesEnum.EnhancedSurplus)?.id!);
			}
			if (fa.FAPI)
			{
				updatedCalculationTypes.push(calculationTypes.find((c) => c.code === CalculationTypesEnum.FAPI)?.id!);
			}
			if (fa.ACB)
			{
				updatedCalculationTypes.push(calculationTypes.find((c) => c.code === CalculationTypesEnum.ACB)?.id!);
			}
		}


		return updatedCalculationTypes;
	};

	//undefined - do nothing next step, >0: edit =0 add new
	const validateAndSave = async (editingId: number | undefined) =>
	{
		const editingFA = rowDataRef.current.find((r) => r.inEditableMode === true);

		if (editingFA)
		{
			if (editingFA.isNewItem)
			{
				const validated = ValidateEditingFA();
				if (validated)
				{
					const result = await SaveNewFA(editingId);

					return result;
				}
				else
				{
					return false;
				}
			}
			else
			{
				const validated = ValidateEditingFA();
				if (validated && editingRowRef.current)
				{
					if (hasChangeOnEditing())
					{
						const fa = editingFA;
						try
						{
							await engagementService.updateFA(
								fa.affiliateId,
								props.clientId,
								props.engagementId,
								fa.name,
								fa.countryId,
								fa.currencyId,
								fa.taxationYearEnd,
								fa.dateBecameFa,
								fa.analysisStartDate,
								fa.analysisEndDate,
								getFACalculationTypes(fa)
							);

							const updatedRowData = rowDataRef.current.map((r) =>
							{
								if (r.inEditableMode === true && r.isNewItem === false)
								{
									return {
										...r,
										inEditableMode: false
									};
								}
								else if (
									r.affiliateId === editingId &&
									editingId && editingId > 0
								)
								{
									return {
										...r,
										inEditableMode: true,
										taxationYearEnd: dateFormatter.toStartOfDayLocal(r.taxationYearEnd),
										dateBecameFa: dateFormatter.toStartOfDayLocal(r.dateBecameFa),
										analysisStartDate: dateFormatter.toStartOfDayLocal(r.analysisStartDate)
									};
								}
								else
								{
									return r;
								}
							});
							if (editingId === 0)
							{
								const newRecord: IForeignAffiliate = {
									affiliateId: 0,
									name: '',
									currencyId: 0,
									countryId: 0,
									taxationYearEnd: undefined,
									dateBecameFa: undefined,
									analysisStartDate: undefined,
									inEditableMode: true,
									isNewItem: true,
									selected: false,
									showedWarning: false
								};
								if (searchText !== '')
								{
									setSearchText('');
								}
								setRowData([newRecord, ...updatedRowData]);
								if (editableRowIndex)
								{
									setEditableRowIndex(1 + editableRowIndex);
								}
								else
								{
									setEditableRowIndex(1);
								}
								setEditingRow(undefined);
							}
							else
							{
								if ((editingId ? editingId : 0) > 0)
								{
									const selectedRow = updatedRowData.find(
										(r: any) => r.affiliateId === editingId
									);
									if (selectedRow)
									{
										setEditingRow(selectedRow);
									}
								}
								else
								{
									setEditingRow(undefined);
								}
								setTriggerEditField('');

								setEditingRow(undefined);
								setRowData(updatedRowData);
							}

							return true;
						}
						catch (error: any)
						{
							handleAPIError(error);
							return false;
						}
					}
					else
					{
						clearRow(editingId);
						return true;
					}
				}
				else
				{
					if (!editingRowRef.current)
					{
						//create a new
						if (editingId)
						{
							clearRow(editingId);
							return true;
						}
					}
					else
					{
						return false;
					}
				}
			}
		}
		else
		{
			return true;
		}
	};

	const addNewAffiliate = () =>
	{
		if (searchText !== '')
		{
			setSearchText('');
		}
		const newRecord: IForeignAffiliate = {
			affiliateId: 0,
			name: '',
			currencyId: 0,
			countryId: 0,
			taxationYearEnd: undefined,
			dateBecameFa: undefined,
			analysisStartDate: undefined,
			inEditableMode: true,
			isNewItem: true,
			selected: false,
			showedWarning: false
		};
		const updatedRowData = rowDataRef.current.map((r) =>
		{
			if (r.inEditableMode === true)
			{
				return {
					...r,
					inEditableMode: false,
					isNewItem: false
				};
			}
			else
			{
				return r;
			}
		});
		if (searchText !== '')
		{
			setSearchText('');
		}
		setRowData([newRecord, ...updatedRowData]);
		setIsAddingNewFA(true);
		if (editableRowIndex)
		{
			setEditableRowIndex(1 + editableRowIndex);
		}
		else
		{
			setEditableRowIndex(1);
		}
	};


	const handleRowClick = async (field: string, event: any) =>
	{
		const editingFA = rowDataRef.current.find((r) => r.inEditableMode === true && r.affiliateId === event.affiliateId);
		if (!editingFA)
		{
			if (!relevantDatesWarning)
			{
				const editingOtherFA = rowDataRef.current.find((r) => r.inEditableMode === true);
				if (!editingOtherFA)
				{
					navigate(`/engagement/${props.engagementId}/affiliate/${event.affiliateId}/report/${ReportEnum.SurplusBalances}`);
				}
			}
			return;
		}

		if (!editingRowRef.current || editingRowRef.current.affiliateId !== event.affiliateId)
		{
			const selectedRow = rowDataRef.current.find(
				(r: any) => r.affiliateId === event.affiliateId
			);
			if (selectedRow)
			{
				if (field === ForeignAffiliateFields.analysisStartDate || field === ForeignAffiliateFields.analysisEndDate ||
					field === ForeignAffiliateFields.dateBecameFa)
				{
				}
				else
				{
					setTriggerEditField(field);
					setEditingRow({
						...selectedRow,
						showedWarning: true
					});
				}
			}
		}
		else
		{
			if (field === ForeignAffiliateFields.analysisStartDate || field === ForeignAffiliateFields.analysisEndDate ||
				field === ForeignAffiliateFields.dateBecameFa)
			{
			}
			else
			{
				setTriggerEditField(field);
				if (editingRowRef.current)
				{
					setEditingRow({
						...editingRowRef.current,
						showedWarning: true
					});
				}
			}
		}
	};

	const setEditingFocus = () =>
	{
		setTimeout(() =>
		{
			if (triggerEditField === 'taxationYearEnd')
			{
				const dateDivWrapper = document.querySelector(
					`.custom-input.${triggerEditField} .react-date-picker .ap-field-suffix-wrapper span`
				) as HTMLDivElement;
				dateDivWrapper.click();
			}
			else
			{
				const inputDivWrapper = document.querySelector(
					`.custom-input.${triggerEditField} .ap-field-wrapper input`
				) as HTMLDivElement;
				if (inputDivWrapper)
				{
					inputDivWrapper.click();
				}
			}
		}, 501);
	};

	const continueToEdit = () =>
	{
		if (showFABulkEditingWarningModal)
		{
			//bulk updated setBulkUpdateFA
			if (bulkUpdateFA)
			{
				bulkUpdate([], bulkUpdateFA);
			}
		}
		else
		{
			setShowFAEditingWarningModal(false);
			if (editingRow)
			{
				setEditingFocus();
			}
		}
	};

	const cancelToEdit = () =>
	{
		if (showFABulkEditingWarningModal)
		{
			setShowFABulkEditingWarningModal(false);
			const originalSelectedRows = rowDataRef.current.filter(
				(r: any) => r.selected === true
			);
			setSelectedRows(originalSelectedRows);
			setBulkUpdateFA(undefined);
		}
		else
		{
			setShowFAEditingWarningModal(false);
			if (hasChangeOnEditing())
			{
				clearRow(editingRow?.affiliateId);

				setEditingRow(undefined);
				setTriggerEditField('');
			}
			else
			{
				clearRow(0);
				setEditingRow(undefined);
				setTriggerEditField('');
			}
		}
	};

	const hasError = () =>
	{
		if (!faErrorsRef.current)
		{
			return false;
		}
		if (
			faErrorsRef.current.clientSideErr.name ||
			faErrorsRef.current.clientSideErr.currencyId ||
			faErrorsRef.current.clientSideErr.countryId ||
			faErrorsRef.current.clientSideErr.taxationYearEnd ||
			faErrorsRef.current.clientSideErr.analysisStartDate ||
			faErrorsRef.current.clientSideErr.analysisEndDate ||
			faErrorsRef.current.clientSideErr.dateBecameFa
		)
		{
			return true;
		}
		else
		{
			return false;
		}
	};

	const addExistingAffiliate = () =>
	{
		setShowAddFAModal(true);
	};

	const editFA = async () =>
	{
		setShowBulkEditFAModal(true);
	};

	const deleteFA = async () =>
	{
		setShowDeleteFAModal(true);
	};

	const addExistingFA = async (fa: IForeignAffiliate) =>
	{
		if (fa.dateBecameFa && fa.analysisStartDate)
		{
			await engagementService
				.associateForeignAffiliates(
					fa.affiliateId,
					props.engagementId,
					fa.dateBecameFa,
					fa.analysisStartDate,
					fa.analysisEndDate
				)
				.then((res: any) =>
				{
					setShowAddFAModal(false);
					if (searchText !== '')
					{
						setSearchText('');
					}
					loadForeignAffiliates();
				})
				.catch((err: any) =>
				{
					setAddFaModalApiErrors(err);
				});
		}
	};

	const handleInputClick = (field: string, event: any) =>
	{
		if (field === ForeignAffiliateFields.analysisStartDate || field === ForeignAffiliateFields.analysisEndDate ||
			field === ForeignAffiliateFields.dateBecameFa)
		{
		}
		else
		{
			if (editingRowRef.current && !editingRowRef.current.showedWarning)
			{
				setTriggerEditField(field);
				setEditingRow({
					...editingRowRef.current,
					showedWarning: true
				});
			}
		}
	};

	const handleSelection = async (param: any, selected: boolean) =>
	{
		const updatedRowData = rowDataRef.current.map((r: any) =>
		{
			if (r.affiliateId === param.dataItem.affiliateId)
			{
				return {
					...r,
					selected: selected
				};
			}
			else
			{
				return r;
			}
		});
		setRowData(updatedRowData);
	};

	const handleSelectAll = (selected: boolean) =>
	{
		const updatedRowData = rowDataRef.current.map((r: any) =>
		{
			return {
				...r,
				selected: selected
			};
		});
		setSelectAll(selected);
		setRowData(updatedRowData);
	};

	const handleBulkUpdate = (
		list: IForeignAffiliate[],
		updatedFA: IForeignAffiliate
	) =>
	{
		if (
			!!updatedFA.taxationYearEnd ||
			updatedFA.countryId ||
			updatedFA.currencyId
		)
		{
			setSelectedRows(list);
			setBulkUpdateFA(updatedFA);
			setShowFABulkEditingWarningModal(true);
		}
		else
		{
			bulkUpdate(list, updatedFA);
		}
	};

	const bulkUpdate = (
		updatedSelectedRows: IForeignAffiliate[],
		updatedFA: IForeignAffiliate
	) =>
	{
		if (showFABulkEditingWarningModal)
		{
			setShowFABulkEditingWarningModal(false);
		}

		const finalSelectedRow =
			updatedSelectedRows.length === 0 ? selectedRows : updatedSelectedRows;

		const ids = finalSelectedRow.map((r: any) =>
		{
			return r.affiliateId;
		});
		if (props.engagementId && ids.length > 0 && updatedFA)
		{
			setLoading(true);
			engagementService
				.updateBulkFA(props.engagementId, ids, updatedFA)
				.then((res: any) =>
				{
					loadForeignAffiliates();
					setShowBulkEditFAModal(false);
					setIsBulkUpdated(true);
					setFaErrors(undefined);
				})
				.catch((err: any) =>
				{
					setShowBulkEditFAModal(true);
					setIsBulkUpdated(false);
					handleAPIError(err);
					setLoading(false);
				});
		}
	};

	const clearRow = (editingId: number | undefined) =>
	{
		setFaErrors(undefined);
		setEditableRowIndex(null);
		if (editingRowRef.current && editingRowRef.current.affiliateId)
		{
			const originalRows = rowDataRef.current.map((r: any) =>
			{
				if (r.affiliateId === editingRowRef.current?.affiliateId)
				{
					return {
						affiliateId: editingRowRef.current?.affiliateId,
						name: editingRowRef.current?.name,
						currencyId: editingRowRef.current?.currencyId,
						countryId: editingRowRef.current?.countryId,
						taxationYearEnd: editingRowRef.current?.taxationYearEnd,
						countryName: '',
						currencyName: '',
						dateBecameFa: editingRowRef.current?.dateBecameFa,
						analysisStartDate: editingRowRef.current?.analysisStartDate,
						analysisEndDate: editingRowRef.current?.analysisEndDate,
						inEditableMode: false,
						isNewItem: false,
						surplus: editingRowRef.current?.surplus,
						enhancedSurplus: editingRowRef.current?.enhancedSurplus,
						FAPI: editingRowRef.current?.FAPI,
						ACB: editingRowRef.current?.ACB
					};
				}
				else if (
					r.affiliateId === editingId &&
					editingId
				)
				{
					return {
						...r,
						inEditableMode: true,
						taxationYearEnd: dateFormatter.toStartOfDayLocal(r.taxationYearEnd),
						dateBecameFa: dateFormatter.toStartOfDayLocal(r.dateBecameFa),
						analysisStartDate: dateFormatter.toStartOfDayLocal(r.analysisStartDate),
						analysisEndDate: r.analysisEndDate
					};
				}
				else
				{
					return r;
				}
			});

			setRowData(originalRows);

			setEditingRow(undefined);
			setTriggerEditField('');
		}
		else
		{
			const newRowData = rowDataRef.current.filter(
				(r) => r.isNewItem === false
			);
			const resetRowData = newRowData.map((r: any) =>
			{
				if (r.inEditableMode)
				{
					return {
						...r,
						inEditableMode: false
					};
				}
				else
				{
					return r;
				}
			});
			setRowData(resetRowData);
			setEditingRow(undefined);
			setIsAddingNewFA(false);
		}
	};

	const updateFaErrorState = (
		field: string,
		isClientSideErr: boolean = true,
		errMessage: string | null
	) =>
	{
		const { clientSideErr, apiErr } = faErrors;
		if (isClientSideErr)
		{
			setFaErrors({
				...faErrors,
				clientSideErr: {
					...clientSideErr,
					[field]: errMessage
				}
			});

			return;
		}

		setFaErrors({
			...faErrors,
			apiErr: {
				...apiErr,
				[field]: errMessage
			}
		});
	};

	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		field: string,
		value: any
	) =>
	{
		const currentlyEditedRow = rowDataRef.current.filter(
			(r) => r.inEditableMode === true
		)?.[0] as any;
		if (currentlyEditedRow)
		{
			currentlyEditedRow[`${field}`] = value;
		}
		//remove error indicator for all calculaton type checkboxes
		if (field === ForeignAffiliateFields.surplus || field === ForeignAffiliateFields.enhancedSurplus ||
			field === ForeignAffiliateFields.FAPI || field === ForeignAffiliateFields.ACB)
		{
			if (!!value && faErrors?.clientSideErr?.surplus)
			{
				ValidateEditingFA();
			}
		}
	};

	const handleDelete = () =>
	{
		if (selectedRows.length > 0)
		{
			const ids = selectedRows.map((r: any) =>
			{
				return r.affiliateId;
			});
			const body = {
				engagementId: props.engagementId,
				affiliates: ids
			};
			engagementService.unlinkForeignAffiliate(body).then((res: any) =>
			{
				setShowDeleteFAModal(false);
				loadForeignAffiliates();
			});
		}
	};

	const onSearchTextChange = async (val: string) =>
	{
		setPage(initialDataState);
		setSearchText(val);
	};

	const handleAction = (action: ActionEnum, dataItem: any) =>
	{
		switch (action)
		{
		case ActionEnum.Save:
			validateAndSave(dataItem.affiliateId);
			break;
		case ActionEnum.Cancel:
			clearRow(dataItem.affiliateId);
			break;
		case ActionEnum.Edit:
			const updatedRowData = rowDataRef.current.map((r) =>
			{
				if (r.affiliateId === dataItem.affiliateId)
				{
					return {
						...r,
						inEditableMode: true
					};
				}
				else
				{
					return r;
				}
			});
			setEditingRow({
				...dataItem
			});

			setRowData(updatedRowData);

			break;
		}
	};


	const rowRender = (
		row: React.ReactElement<HTMLTableRowElement>,
		props: GridRowProps
	) =>
	{
		if (!!props.dataItem[ForeignAffiliateFields.affiliateId])
		{
			if (props.dataItem[ForeignAffiliateFields.dateBecameFa] === undefined ||
				props.dataItem[ForeignAffiliateFields.analysisStartDate] === undefined)
			{
				return React.cloneElement(row, {
					className: ` ${row.props.className} highlightRow`
				});
			}
			return React.cloneElement(row, {
				className: ` ${row.props.className} nohighlight`
			});
		}
		return row;
	};

	const onSelect = (value: ValueType, item: ItemDataType, event: React.SyntheticEvent) =>
	{
		switch (value)
		{
		case FAMenuEnum.AddNewFA:
			addNewAffiliate();
			break;
		case FAMenuEnum.AddExistingFA:
			addExistingAffiliate();
			break;
		}
	};

	const pageChange = (event: GridPageChangeEvent) =>
	{
		const targetEvent = event.targetEvent as PagerTargetEvent;
		const take = event.page.take;

		if (targetEvent.value)
		{
			setPageSizeValue(targetEvent.value);
		}
		setPage({
			...event.page,
			take
		});
	};

	const onSearchClear = () =>
	{
		setSearchText('');
		setFilterRowData(rowData);
	};


	return (
		<div className="foreign-affiliates-wrapper">
			{loading && (
				<div className="loading-icon">
					<Loading
						loadingType="circular"
						indeterminate={true}
						compact={false}
					/>
				</div>
			)}
			<div className='fa-grid-container'>
				<div className='instruction-message'>{t('addForeignAffiliates.instructionMessage') || ''}</div>
				<div className='toolbar-container'>
					{(userProfile?.role === RoleEnum.BusinessOwner ||
						userProfile?.role === RoleEnum.PwCUser) && (
						<div className='toolbar'>
							<div className="fa-header">
								<div className="fa-header-left">
									<div className="fa-header-search">
										<Search
											className="engagementFa-search"
											searchType={'secondary'}
											placeholder={t('addForeignAffiliates.searchForeignAffiliate') || undefined}
											searchValue={searchText}
											onChange={(value: string) => onSearchTextChange(value)}
											onClear={onSearchClear}
											disabled={rowData.length === 0 || !!editingRow || isAddingNewFA}
										/>
									</div>
								</div>

								<div className="fa-buttons">
									<div className="flex-button margin-right  custom-command">
										<DropdownButton splitButton={false}
											disabled={!!editingRow || isAddingNewFA}
											kind="secondary"
											align='right'
											data={
												[
													{
														label: t('addForeignAffiliates.addNewFA') || '',
														value: FAMenuEnum.AddNewFA,
														disabled: false
													},
													{
														label: t('addForeignAffiliates.addExistingFA') || '',
														value: FAMenuEnum.AddExistingFA,
														disabled: false
													}
												]}
											onSelect={onSelect}
										>
											{t('addForeignAffiliates.addForeignAffiliates')}
										</DropdownButton>
									</div>
									<div className="flex-button margin-right">
										<Button
											kind="secondary"
											disabled={!selection || !!editingRow || isAddingNewFA}
											onClick={editFA}
										>
											{t('addForeignAffiliates.edit')}
										</Button>
									</div>
									<div className="flex-button">
										<Button
											kind="secondary"
											disabled={!selection || !!editingRow || isAddingNewFA}
											onClick={deleteFA}
										>
											{t('addForeignAffiliates.remove')}
										</Button>
									</div>
								</div>
							</div>
							{relevantDatesWarning && <div className="warning-container">
								<div className="warning-messsage">
									<div className="Appkit4-icon icon-information-outline"></div>
									<div>{t('addForeignAffiliates.relevantDateRequired')}</div></div>

							</div>
							}
						</div>
					)}

				</div>
				<LocalizationProvider language="en-user">
					<IntlProvider locale="en">
						<Grid
							className={'engFa-grid'}
							sort={sort}
							onSortChange={onSortChange}
							rowRender={rowRender}
							data={filterRowData.slice(
								page.skip,
								page.take + page.skip
							)}
							sortable={{
								allowUnsort: false
							}}
							skip={page.skip}
							take={page.take}
							total={filterRowData.length}
							pageable={{
								buttonCount: 2,
								info: false,
								type: 'input',
								pageSizes: [10, 25],
								pageSizeValue: pageSizeValue
							}}
							onPageChange={pageChange}
						>
							<GridNoRecords>
								{!loading && rowData.length > 0 && filterRowData.length === 0 && (
									<div className="no-record-wrapper">{t('addForeignAffiliates.noDataAvailable')}</div>
								)}
								{!loading && rowData.length === 0 && (
									<div
										className={
											displayError ? 'no-record-err-wrapper' : 'no-record-wrapper'
										}
									>
										<div className={displayError ? 'err-message' : 'message'}>
											{t('addForeignAffiliates.addAtLeastOneForeignAffilicate')}
										</div>
										<div
											className={
												displayError ? 'text-err-wrapper' : 'text-wrapper'
											}
										>
											{t('addForeignAffiliates.addForiegnAffiliatebyclicking')}
										</div>
										<div className="anchor-wrapper custom-command">
											{
												<Button className={`link-button ${displayError ? 'anchor-err-style' : 'anchor-style'}`}
													kind='text' onClick={addNewAffiliate}>
													{t('addForeignAffiliates.addNewFA')}
												</Button>
											}
											<div className={displayError ? 'err-message' : ''}>or</div>
											{
												<Button className={`link-button ${displayError ? 'anchor-err-style' : 'anchor-style'}`}
													kind='text' onClick={addExistingAffiliate}>
													{t('addForeignAffiliates.addExistingFA')}
												</Button>
											}
										</div>
									</div>
								)}
							</GridNoRecords>
							<GridColumn
								field={SELECTED_FIELD}
								width={'32px'}
								headerClassName={'fa-grid-header-cell-text checkbox-column'}
								headerCell={(props) => (
									<Checkbox
										checked={
											selectedRows.length > 0 &&
											selectedRows.length === rowData.length
										}
										indeterminate={
											selectedRows.length > 0 &&
											selectedRows.length < rowData.length
										}
										disabled={!(rowData.length > 0)}
										onChange={(val) => handleSelectAll(val)}
									></Checkbox>
								)}
								cell={(props) => (
									<td>
										<div className="custom-cell">
											<div className="custom-input">
												{props.dataItem.inEditableMode ?
													<Button
														icon="icon-save-outline"
														kind="text"
														onClick={() =>
															handleAction(ActionEnum.Save, props.dataItem)
														}
													></Button> :
													<Checkbox
														disabled={false}
														checked={props.dataItem.selected}
														onChange={(val) => handleSelection(props, val)}
													></Checkbox>}


											</div>
											{props.dataItem.inEditableMode && errorExisting && (
												<div className="custom-cell-error">&nbsp;</div>
											)}
										</div>
									</td>
								)}
							/>
							<GridColumn
								field={'inEditableMode'}
								title=" "
								width={'32px'}
								headerClassName={'fa-grid-header-cell-text'}
								cell={(props) => (
									<td>
										<div className="custom-cell">
											<div className="custom-input">
												<div className="action-buttons">
													{props.dataItem.inEditableMode ?
														<Button
															icon="icon-circle-delete-outline"
															kind="text"
															onClick={() =>
																handleAction(ActionEnum.Cancel, props.dataItem)
															}
														></Button> :
														<Button
															icon="icon-pencil-outline"
															kind="text"
															disabled={!!editingRow || isAddingNewFA}
															onClick={() =>
																handleAction(ActionEnum.Edit, props.dataItem)
															}
														></Button>
													}
												</div>
											</div>
											{props.dataItem.inEditableMode && errorExisting && (
												<div className="custom-cell-error">&nbsp;</div>
											)}
										</div>
									</td>
								)}
							/>
							<GridColumn
								field={ForeignAffiliateFields.name}
								title={t('addForeignAffiliates.title.name') || undefined}
								editable={false}
								sortable={true}
								width={'219px'}
								headerClassName={'fa-grid-header-cell-text'}
								cell={(params) =>
									TextEditorCell({
										...params,
										fieldErrors: faErrorsRef.current,
										updateFaErrorState,
										handleInputChange,
										handleRowClick,
										handleInputClick
									})
								}
								headerCell={SortableHeaderCell}
							/>
							<GridColumn
								field={ForeignAffiliateFields.countryId}
								title={t('addForeignAffiliates.title.countryId') || undefined}
								editable={true}
								sortable={true}
								headerClassName={'fa-grid-header-cell-dropdown'}
								width={'140px'}
								cell={(params) =>
									DropdownEditorCell({
										...params,
										fieldErrors: faErrors,
										dropdownValues: countries,
										labelKey: 'name',
										valueKey: 'id',
										handleInputChange,
										handleRowClick,
										handleInputClick
									})
								}
								headerCell={SortableHeaderCell}
							/>
							<GridColumn
								field={ForeignAffiliateFields.currencyId}
								title={t('addForeignAffiliates.title.currencyId') || undefined}
								headerClassName={'fa-grid-header-cell-dropdown'}
								sortable={true}
								width={'160px'}
								cell={(params) =>
									DropdownEditorCell({
										...params,
										fieldErrors: faErrors,
										dropdownValues: currencies,
										labelKey: 'code',
										valueKey: 'id',
										handleInputChange,
										handleRowClick,
										handleInputClick
									})
								}
								headerCell={SortableHeaderCell}
							/>
							<GridColumn
								field={ForeignAffiliateFields.taxationYearEnd}
								title={t('addForeignAffiliates.title.taxationYearEnd') || undefined}
								headerClassName={'fa-grid-header-cell-date-checkbox'}
								editable={false}
								sortable={true}
								width={'135px'}
								cell={(params) =>
									DatePickerEditorCell({
										...params,
										isShortFormat: true,
										fieldErrors: faErrors,
										handleInputChange,
										handleRowClick,
										handleInputClick
									})
								}
								headerCell={SortableHeaderCell}
							/>
							<GridColumn
								field={ForeignAffiliateFields.dateBecameFa}
								title={t('addForeignAffiliates.title.dateBecameFa') || undefined}
								headerClassName={'fa-grid-header-date-checkbox'}
								editable={false}
								sortable={true}
								width={'165px'}
								cell={(params) =>
									DatePickerEditorCell({
										...params,
										fieldErrors: faErrors,
										handleInputChange,
										handleRowClick,
										handleInputClick
									})
								}
								headerCell={SortableHeaderCell}
							/>
							<GridColumn
								field={ForeignAffiliateFields.analysisStartDate}
								title={t('addForeignAffiliates.title.analysisStartDate') || undefined}
								headerClassName={'fa-grid-header-date-checkbox'}
								editable={false}
								sortable={true}
								width={'135px'}
								cell={(params) =>
									DatePickerEditorCell({
										...params,
										maxDate: props.engagementDetail?.lastCalculationTaxationYearEnd,
										fieldErrors: faErrors,
										handleInputChange,
										handleRowClick,
										handleInputClick
									})
								}
								headerCell={SortableHeaderCell}
							/>
							<GridColumn
								field={ForeignAffiliateFields.analysisEndDate}
								title={t('addForeignAffiliates.title.analysisEndDate') || undefined}
								headerClassName={'fa-grid-header-date-checkbox'}
								editable={false}
								sortable={true}
								width={'135px'}
								cell={(params) =>
									DatePickerEditorCell({
										...params,
										fieldErrors: faErrors,
										maxDate: props.engagementDetail?.lastCalculationTaxationYearEnd,
										handleInputChange,
										handleRowClick,
										handleInputClick
									})
								}
								headerCell={SortableHeaderCell}
							/>
							<GridColumn
								field={ForeignAffiliateFields.surplus}
								title={t(`addForeignAffiliates.title.${ForeignAffiliateFields.surplus}`) || undefined}
								editable={false}
								sortable={true}
								width={'120px'}
								headerClassName={'fa-grid-header-date-checkbox'}
								cell={(params) =>
									CheckboxEditorCell({
										...params,
										fieldErrors: faErrorsRef.current,
										validationField: ForeignAffiliateFields.surplus,
										updateFaErrorState,
										handleInputChange,
										handleRowClick,
										handleInputClick
									})
								}
								headerCell={SortableHeaderCell}
							/>
							<GridColumn
								field={ForeignAffiliateFields.enhancedSurplus}
								title={t(`addForeignAffiliates.title.${ForeignAffiliateFields.enhancedSurplus}`) || undefined}
								editable={false}
								sortable={true}
								width={'140px'}
								headerClassName={'fa-grid-header-date-checkbox'}
								cell={(params) =>
									CheckboxEditorCell({
										...params,
										fieldErrors: faErrorsRef.current,
										validationField: ForeignAffiliateFields.surplus,
										updateFaErrorState,
										handleInputChange,
										handleRowClick,
										handleInputClick
									})
								}
								headerCell={SortableHeaderCell}
							/>
							<GridColumn
								field={ForeignAffiliateFields.FAPI}
								title={t(`addForeignAffiliates.title.${ForeignAffiliateFields.FAPI}`) || undefined}
								editable={false}
								sortable={true}
								width={'100px'}
								headerClassName={'fa-grid-header-date-checkbox'}
								cell={(params) =>
									CheckboxEditorCell({
										...params,
										fieldErrors: faErrorsRef.current,
										validationField: ForeignAffiliateFields.surplus,
										updateFaErrorState,
										handleInputChange,
										handleRowClick,
										handleInputClick
									})
								}
								headerCell={SortableHeaderCell}
							/>
							<GridColumn
								field={ForeignAffiliateFields.ACB}
								title={t(`addForeignAffiliates.title.${ForeignAffiliateFields.ACB}`) || undefined}
								editable={false}
								sortable={true}
								width={'100px'}
								headerClassName={'fa-grid-header-date-checkbox'}
								cell={(params) =>
									CheckboxEditorCell({
										...params,
										fieldErrors: faErrorsRef.current,
										validationField: ForeignAffiliateFields.surplus,
										updateFaErrorState,
										handleInputChange,
										handleRowClick,
										handleInputClick
									})
								}
								headerCell={SortableHeaderCell}
							/>

						</Grid>
					</IntlProvider>
				</LocalizationProvider>
			</div>
			<AddFAModal
				clientId={props.clientId}
				engagementId={props.engagementId}
				visible={showAddFAModal}
				engagementDetail={props.engagementDetail}
				setVisible={(val: boolean) => setShowAddFAModal(val)}
				addExistingFA={addExistingFA}
				faModalErros={addFaModalApiErrors}
			/>
			<FAEditingWarningModal
				showDialog={showFAEditingWarningModal}
				cancelNavigation={cancelToEdit}
				confirmNavigation={continueToEdit}
			></FAEditingWarningModal>
			<BulkEditFAModal
				clientId={props.clientId}
				engagementId={props.engagementId}
				selectedItems={selectedRows}
				visible={showBulkEditFAModal}
				setVisible={(val: boolean) =>
				{
					setShowBulkEditFAModal(val);
				}}
				bulkUpdate={handleBulkUpdate}
				fieldErrors={faErrors}
				isBulkUpdated={isBulkUpdated}
				setFaErrors={(val: any) =>
					setFaErrors(val)}
				showDialog={showFABulkEditingWarningModal}
				cancelNavigation={cancelToEdit}
				confirmNavigation={continueToEdit}
			></BulkEditFAModal>
			<DeleteFAModal
				selectedItems={selectedRows}
				visible={showDeleteFAModal}
				title={t('addForeignAffiliates.removeForeignAffiliate')}
				setVisible={(val: boolean) =>
				{
					setShowDeleteFAModal(val);
				}}
				onDelete={handleDelete}
			></DeleteFAModal>
		</div>
	);
};
