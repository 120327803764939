export const Constants = {
	GUID_EMPTY: '00000000-0000-0000-0000-000000000000',
	ID_EMPTY: -1,
	NAME_TAXPAYER: 'Taxpayer',

	ID_OTHER: -2,
	NAME_OTHER: 'Other',

	DEFAULT_CURRENCE: 'CAD',
	MinYear: 1900,

	MaxInitialRowsCount: 15,

	EngagementNotificationSubscriptionName: 'EngagementNotification',
	SubscribeToEngagementNotificationsMethodName: 'SubscribeToEngagementNotifications'
};