import React, { useContext, useEffect, useState, useRef } from 'react';
import { ReportSidebar } from './ReportSidebar';
import { Button, DropdownButton, Loading, Tooltip, ValueType } from '@appkit4/react-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'src/store/store';
import { RoleEnum } from 'src/common/types/enums/RoleEnum';
import { Report } from '../Report';
import { ReportEnum } from 'src/common/types/enums/ReportEnum';
import { CalculationStatus } from 'src/common/types/enums/CalculationStatus';
import { ReportI18NKeys } from 'src/common/types/enums/ReportI18NKeys';
import { useTranslation } from 'react-i18next';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { NotificationService } from 'src/core/services/notificationService';
import { ICalculationHistory } from 'src/features/engagement/models/ICalculationHistory';
import AffiliateContext from 'src/common/components/SharedDataContext/AffiliateContext';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import ExportReportForm from 'src/features/engagement/components/Report/ExportReportForm/ExportReportForm ';
import { INameId } from 'src/common/types/interfaces/INameId';
import { Utils } from 'src/common/utils/utils';
import './ReportLayout.scss';

export const ReportLayout = (props: { setShowBanner: any, calcHistory?: ICalculationHistory }) =>
{
	const affiliateContext = useContext(AffiliateContext);
	const userProfile = useAppSelector((state) => state.userProfile.profile);
	const navigate = useNavigate();
	const { pageId: reportId } = useParams();
	const [currentReportId, setCurrentReportId] = useState<ReportEnum>();
	const [calculationHistory, setCalculationHistory] = useState<ICalculationHistory>();
	const [calculationStatus, setCalculationStatus] = useState<string>();
	const [isCalcProcessing, setIsCalcProcessing] = useState<boolean>(false);
	const [isRunCalcDisabled, setIsRunCalcDisabled] = useState<boolean>(true);
	const [refershReportData, setRefershReportData] = useState<boolean>(false);
	const [isEngagementRelationshipsValid, setIsEngagementRelationshipsValid] = useState<boolean | undefined>(undefined);
	const [displayExportReportModal, setDisplayExportReportModal] = useState<boolean>(false);
	const [currencyOptions, setCurrencyOptions] = useState<INameId[]>([]);
	const intervalId = useRef<number | undefined>(undefined);
	const calcStatusErrorCount = useRef<number>(0);
	const currencies = useAppSelector((state) => state.appSettings.currencies);
	const faCalculatingCurrencyId = -1;

	const { t } = useTranslation(
		'report',
		{
			keyPrefix: ReportI18NKeys.Report
		}
	);

	const runCalcInitialTooltip = {
		'status': `${t('status')}: ${t('neverRunStatus')}`,
		'started': `${t('startedTime')}: ${t('notApplicable')}`
	};

	const relationshipsNotValidTooltip = {
		'message': t('relationshipsNotValidated')
	};

	const [runCalcTooltip, setRunCalcTooltip] = useState<any>(runCalcInitialTooltip);

	const handleSelect = (reportId: ReportEnum) =>
	{
		setCurrentReportId(reportId);
	};

	const handleLanchInputClick = () =>
	{
		if (affiliateContext)
		{
			navigate(
				`/engagement/${affiliateContext.engagementDetail.id}/affiliate/${affiliateContext.affiliateDetail.affiliateId}/input`);
		}
	};

	const createTooltip = (status: string, createdOn: string, updatedOn?: string) =>
	{
		const calcStatus = {
			'status': `${t('status')}: ${status === CalculationStatus.Processed ? t('processedStatus') :
				(status === CalculationStatus.Failed ? t('failedStatus') : t('inProgressStatus'))}`
		};
		const calcLastRun = {
			'calcLastRun': `${t('calcLastRun')}: ${formatDate(createdOn)}`
		};
		const completedTime = !!updatedOn ? {
			'completedTime': `${t('completedTime')}: ${formatDate(updatedOn)}`
		} : {
		};
		return ({
			...calcStatus,
			...calcLastRun,
			...completedTime
		});
	};

	const formatDate = (date: any) =>
	{
		return `${!!date ?
			dateFormatter.format(dateFormatter.utcToEst(date), 'MMM DD, YYYY hh:mm A') : ''}`;
	};

	const processCalculationStatus = (result: any) =>
	{
		if (!!result[CalculationStatus.Status])
		{
			const status = result[CalculationStatus.Status];
			const createdOn = result[CalculationStatus.createdOn];
			const updatedOn = result[CalculationStatus.UpdatedOn];

			setCalculationStatus(status);

			if (status === CalculationStatus.Processed || status === CalculationStatus.Failed)
			{
				setIsCalcProcessing(false);
				props.setShowBanner(false);
				setRunCalcTooltip(createTooltip(status, createdOn, updatedOn));
				if (intervalId.current !== undefined)
				{
					clearInterval(intervalId.current);
					intervalId.current = undefined;
					setRefershReportData(true);
				}
				setIsRunCalcDisabled(!isEngagementRelationshipsValid);
				!isEngagementRelationshipsValid && setRunCalcTooltip(relationshipsNotValidTooltip);
			}
			else if (status === CalculationStatus.NotProcessed)
			{
				setIsCalcProcessing(true);
				setIsRunCalcDisabled(true);
				props.setShowBanner(true);
				setRunCalcTooltip(createTooltip(status, createdOn));
			}
		}
	};

	const checkCalculationStatus = (response: any) =>
	{
		if (!!response && !!response.data && !!response.data.result)
		{
			const result = response.data.result;

			setCalculationHistory(result);
			processCalculationStatus(result);
		}
		else
		{
			setCalculationHistory(undefined);
			setIsRunCalcDisabled(false);
		}
	};

	const triggerErrorNotification = (message: string): void =>
	{
		NotificationService.error({
			message
		});
	};

	const runCalculations = async (
		engagementId: number,
		calculationMode: number
	): Promise<void> =>
	{
		try
		{
			const response = await engagementService.runCalculationsForEngagement(engagementId, calculationMode);
			if (!!response && !!response.data && !!response.data.result)
			{
				const status = response.data.result[CalculationStatus.Status];
				setRefershReportData(status === CalculationStatus.Processed || status === CalculationStatus.Failed);
			}
			else
			{
				setRefershReportData(false);
			}
			checkCalculationStatus(response);
		}
		catch (error)
		{
			setIsCalcProcessing(false);
			props.setShowBanner(false);
			setIsRunCalcDisabled(false);
			setRunCalcTooltip(createTooltip(calculationHistory?.status!, calculationHistory?.createdOn!));
			triggerErrorNotification(
				t('reCalculationFailed')
			);
		}
	};

	const onRunCalculationsSelect = (value: ValueType) =>
	{
		if (!!affiliateContext && !!affiliateContext?.engagementDetail.id && !isRunCalcDisabled)
		{
			setIsCalcProcessing(true);
			props.setShowBanner(true);
			setIsRunCalcDisabled(true);
			setRunCalcTooltip(createTooltip(calculationHistory?.status!, calculationHistory?.createdOn!));
			runCalculations(affiliateContext.engagementDetail.id!, Number(value));
		}
	};

	const loadCalculationHistory = async (
		engagementId: number
	): Promise<void> =>
	{
		try
		{
			const response = await engagementService.getCalculationHistory(engagementId);
			checkCalculationStatus(response);
		}
		catch (error)
		{
			triggerErrorNotification(
				t('calcHistoryError')
			);
			setIsRunCalcDisabled(false);
			setRunCalcTooltip(runCalcInitialTooltip);
		}
	};

	const loadCalculationStatus = async (
		engagementId: number,
		calculationId: number
	): Promise<void> =>
	{
		try
		{
			const response = await engagementService.getCalculationStatus(engagementId, calculationId);

			if (!!response && !!response.data && !!response.data.result)
			{
				const result = response.data.result;

				if (!!result[CalculationStatus.SurplusCalculationHistory])
				{
					const surplusCalculationHistory = result[CalculationStatus.SurplusCalculationHistory];
					processCalculationStatus(surplusCalculationHistory);
				}
			}
			else
			{
				setCalculationStatus('');
			}
			calcStatusErrorCount.current = 0;
		}
		catch (error)
		{
			calcStatusErrorCount.current += 1;
			if (!!calcStatusErrorCount && calcStatusErrorCount.current >= 3)
			{
				triggerErrorNotification(
					t('calcStatusError')
				);

				if (intervalId.current !== undefined)
				{
					clearInterval(intervalId.current);
				}
				calcStatusErrorCount.current = 0;
				setIsCalcProcessing(false);
				props.setShowBanner(false);
				setIsRunCalcDisabled(false);
				setRunCalcTooltip(createTooltip(CalculationStatus.Failed, calculationHistory?.createdOn!));
			}
		}
	};

	const loadEngagementRelationshipsValidStatus = async (
		engagementId: number
	): Promise<void> =>
	{
		try
		{
			await engagementService.getEngagementRelationshipsValidStatus(engagementId).then((res: any) =>
			{
				if (!!res && !!res.data && !!res.data.result)
				{
					const result = res.data.result;
					setIsEngagementRelationshipsValid(result.isEngagementRelationshipsValid);
				}
				else
				{
					setIsEngagementRelationshipsValid(false);
				}
			});
		}
		catch (error)
		{
			triggerErrorNotification(
				t('relationshipsValidatedStatusError')
			);
			setIsRunCalcDisabled(false);
			setRunCalcTooltip(runCalcInitialTooltip);
		}
	};

	const loadCurrencies = () =>
	{
		const taxPayerCurrencyId = !!affiliateContext?.engagementDetail && !!affiliateContext?.engagementDetail.taxPayerId &&
			!!affiliateContext?.taxpayers && affiliateContext?.taxpayers.length > 0 ?
			affiliateContext?.taxpayers.find((t) => t.id === affiliateContext?.engagementDetail.taxPayerId)?.currencyId : 0;

		const taxPayerCurrency = !!taxPayerCurrencyId && taxPayerCurrencyId > 0 ?
			currencies.find((c) => c.id === taxPayerCurrencyId) : undefined;

		setCurrencyOptions(!!taxPayerCurrency ?
			[{
				id: taxPayerCurrency!.id,
				name: Utils.replaceTokens(t('currencyOptionTaxPayer'), taxPayerCurrency!.code)
			},
			{
				id: faCalculatingCurrencyId,
				name: Utils.replaceTokens(t('currencyOptionFACurrency'))
			},
			...currencies
				.filter((c) => c.id !== taxPayerCurrency!.id)
				.map((c) =>
				{
					return {
						id: c.id,
						name: c.code
					};
				})] : [
				...currencies.map((c) =>
				{
					return {
						id: c.id,
						name: c.code
					};
				})
			]
		);
	};

	const renderRunCalcTooltip = () =>
	{
		return (<>
			{!!runCalcTooltip && Object.keys(runCalcTooltip).map((item: any, index: number) =>
			{
				return <div key={index}> {runCalcTooltip[item]}</div>;
			})}
		</>
		);
	};


	useEffect(() =>
	{
		if (!!props.calcHistory)
		{
			processCalculationStatus(props.calcHistory);
		}
	}, [props.calcHistory]);

	useEffect(() =>
	{
		if (reportId)
		{
			setCurrentReportId(reportId as ReportEnum);
		}
	}, [reportId]);

	useEffect(
		() =>
		{
			if (!!affiliateContext && !!affiliateContext?.engagementDetail.id)
			{
				loadEngagementRelationshipsValidStatus(affiliateContext?.engagementDetail.id!);
				loadCurrencies();
			}
		}, [affiliateContext]);

	useEffect(
		() =>
		{
			if (!!affiliateContext && !!affiliateContext?.engagementDetail.id)
			{
				loadCalculationHistory(affiliateContext?.engagementDetail.id!);
			}
		}, [isEngagementRelationshipsValid]);

	useEffect(() =>
	{
		if (!!calculationStatus && calculationStatus === CalculationStatus.NotProcessed &&
			!!affiliateContext && !!affiliateContext?.engagementDetail.id &&
			!!calculationHistory && !!calculationHistory[CalculationStatus.Id])
		{
			loadCalculationStatus(affiliateContext?.engagementDetail.id!, calculationHistory[CalculationStatus.Id]);
			intervalId.current = window.setInterval(() =>
			{
				loadCalculationStatus(affiliateContext?.engagementDetail.id!, calculationHistory[CalculationStatus.Id]);
			}, 60000);
		}

		if (!!calculationStatus && (calculationStatus === CalculationStatus.Processed ||
			calculationStatus === CalculationStatus.Failed) && intervalId.current !== undefined)
		{
			setIsCalcProcessing(false);
			props.setShowBanner(false);
			setIsRunCalcDisabled(false);
			clearInterval(intervalId.current);
			setRefershReportData(true);
			intervalId.current = undefined;
		}
	}, [calculationStatus]);

	return (
		<div className="report-layout">
			<div className="report-header">
				<div className="title">{t('title')}</div>
				<div className="report-header-buttons">
					{(userProfile?.role === RoleEnum.PwCUser ||
						userProfile?.role === RoleEnum.BusinessOwner) && (
						<Button
							kind="primary"
							icon="Appkit4-icon icon-pencil-outline"
							onClick={handleLanchInputClick}>
							{t('launch-input')}
						</Button>
					)}
					<div className="run-calc-container">
						<div className="loading">
							{isCalcProcessing && <Loading loadingType='circular' indeterminate={true} compact={true} />}
						</div>

						<div className="calc-button">
							<Tooltip
								trigger='hover'
								position='top-left'
								distance={4}
								id="tooltipDesc"
								content={renderRunCalcTooltip}
								style={{
									maxWidth: '300px'
								}}
							>
								<div>
									<DropdownButton
										disabled={isRunCalcDisabled}
										dropdownMatchWidth={true}
										data={
											[
												{
													label: t('run-calculation-light') || '',
													value: 0,
													disabled: false
												},
												{
													label: t('run-calculation-full') || '',
													value: 1,
													disabled: false
												}
											]}
										onSelect={onRunCalculationsSelect}
									>
										{t('run-calculation')}
									</DropdownButton>
								</div>
							</Tooltip>
						</div>
					</div>
					<Button
						kind='secondary'
						icon="Appkit4-icon icon-document-export-outline"
						disabled={calculationStatus !== CalculationStatus.Processed}
						onClick={() => setDisplayExportReportModal(true)}>
						{t('exportReport')}
					</Button>
				</div>
			</div>
			<div className="report-body">
				<ReportSidebar onSelect={handleSelect} reportId={reportId} />
				<div className="report-placeholder ">
					{
						currentReportId && <Report selectedReportId={currentReportId} refershReport={refershReportData}></Report>
					}
				</div>
			</div>
			<ExportReportForm
				displayModal={displayExportReportModal}
				refreshData={refershReportData}
				currencyOptions={currencyOptions}
				onToggleDisplayModal={(display: any) =>
				{
					setDisplayExportReportModal(!!display);
				}}
			/>
		</div>
	);
};
