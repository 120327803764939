import { ExportReportFields } from 'src/common/types/enums/ExportReportFields';

export interface IExportReport {
	[ExportReportFields.engagementId]: number;
	[ExportReportFields.reportType]?: string;
	[ExportReportFields.taxpayerTaxYearEnd]?: string;
	[ExportReportFields.currencyId]?: number;
	[ExportReportFields.currencyConversionDate]?: string;
	[ExportReportFields.isSEPFactorApplicable]?: boolean;
	[ExportReportFields.affiliateIds]?: number[];
	[ExportReportFields.showNotes]?: boolean;
}

export class ExportReport implements IExportReport
{
	constructor(engagementId: number)
	{
		this[ExportReportFields.engagementId] = engagementId;
		this[ExportReportFields.reportType] = undefined;
		this[ExportReportFields.taxpayerTaxYearEnd] = undefined;
		this[ExportReportFields.currencyId] = undefined;
		this[ExportReportFields.currencyConversionDate] = undefined;
		this[ExportReportFields.isSEPFactorApplicable] = undefined;
		this[ExportReportFields.affiliateIds] = undefined;
		this[ExportReportFields.showNotes] = false;
	}
	[ExportReportFields.engagementId]: number;
	[ExportReportFields.reportType]?: string;
	[ExportReportFields.taxpayerTaxYearEnd]?: string;
	[ExportReportFields.currencyId]?: number;
	[ExportReportFields.currencyConversionDate]?: string;
	[ExportReportFields.isSEPFactorApplicable]?: boolean;
	[ExportReportFields.affiliateIds]?: number[];
	[ExportReportFields.showNotes]?: boolean;
}