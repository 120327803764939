import { useContext,useState } from 'react';
import { ColumnarGrid } from 'src/common/components/ColumnarGrid/ColumnarGrid';
import { useTranslation } from 'react-i18next';
import { IOtherAdjustmentSurplusProps } from '../IOtherAdjustmentSurplusProps';
import { TaxYearEndFilter } from 'src/common/components/TaxYearEndFilter/TaxYearEndFilter';
import AffiliateContext from 'src/common/components/SharedDataContext/AffiliateContext';
import { AdjustmentFromSpecificTaxpayer, IAdjustmentFromSpecificTaxpayer } from
	'src/features/engagement/models/IAdjustmentFromSpecificTaxpayer';
import { OtherAdjustmentsForm } from '../OtherAdjustmentsForm/OtherAdjustmentsForm';
import { AdjustmentFromSpecificTaxpayerFields } from 'src/common/types/enums/AdjustmentFromSpecificTaxpayerFields';

export function OtherAdjustmentsFromAllTaxpayer(props: IOtherAdjustmentSurplusProps): JSX.Element
{
	const [toDate, setToDate] = useState<Date | undefined>(undefined);
	const [fromDate, setFromDate] = useState<Date | undefined>(undefined);
	const affiliateContext = useContext(AffiliateContext);
	const [isAddRecord, setIsAddRecord] = useState(false);
	const [selectedRecord, setSelectedRecord] = useState<IAdjustmentFromSpecificTaxpayer | undefined>(undefined);
	const [displayModal, setDisplayModal] = useState(false);
	const { t } = useTranslation(
		'input',
		{
			keyPrefix: 'adjustmentFromAllTaxpayer'
		}
	);

	const onRenderFilters = (): JSX.Element =>
	{
		return <>
			<TaxYearEndFilter
				onlyRelevantResults
				isDisabled={
					props.isLoading ||
					(
						!Object.values(props?.adjustmentRecordForAll!).some((d) => !!d.length) &&
						fromDate === undefined &&
						toDate === undefined
					)
				}
				faHistory={props.faHistory}
				onFilter={onFilter}
			/>
		</>;
	};

	const onFilter = (
		{
			fromDate,
			toDate
		}:
		{
			fromDate?: Date;
			toDate?: Date;
		}
	): void =>
	{
		setFromDate(fromDate);
		setToDate(toDate);
		props.onReloadAdjustmentData({
			engagementId: affiliateContext?.engagementDetail.id!,
			affiliateId: affiliateContext?.affiliateDetail.affiliateId!,
			fromDate,
			toDate
		});
	};

	const onOpenPanel = (isAdd: boolean, entity: IAdjustmentFromSpecificTaxpayer): void =>
	{
		setIsAddRecord(!!isAdd);
		setSelectedRecord(entity);
		setDisplayModal(true);
	};

	const onRefresh = async (isSpecificTo?: boolean): Promise<void> =>
	{
		props.onReloadAdjustmentData({
			engagementId: affiliateContext?.engagementDetail.id!,
			affiliateId: affiliateContext?.affiliateDetail.affiliateId!,
			fromDate,
			toDate,
			isSpecificTo
		});
	};

	return (
		<>
			<div className={'other-adjustments-all-taxpayer'}>
				<ColumnarGrid
					title={t('gridTitle')}
					columnIndexKeyName={t('columnIndexKeyName')}
					isLoading={props.isLoading}
					isDisabled={false}
					rowInformation={props.rowInformation}
					data={
						!!props.adjustmentRecordForAll ?
							props.adjustmentRecordForAll :
							{
							}}
					addNewButtonText={t('addNewButtonText')}
					addNewMessage={t('addNewMessage')}
					onRenderFilters={onRenderFilters}
					onHeaderNoteSave={props.onHeaderNoteSave}
					hasHeaderNotes={true}
					onOpenNew={() =>
					{
						onOpenPanel(
							true,
							new AdjustmentFromSpecificTaxpayer(
								affiliateContext?.engagementDetail.id!,
								affiliateContext?.affiliateDetail.affiliateId!,
								props.foreignAffiliate?.currencyId!,
								props.engagementInfo?.taxPayerId!
							)
						);
					}}
					onEdit={(entity: IAdjustmentFromSpecificTaxpayer) =>
					{
						if ( entity[AdjustmentFromSpecificTaxpayerFields.taxpayerId] === null)
						{
							entity[AdjustmentFromSpecificTaxpayerFields.specificTo] = false;
						}
						onOpenPanel(
							false,
							entity
						);
					}}
				></ColumnarGrid>
			</div>
			<OtherAdjustmentsForm
				isAdd={isAddRecord}
				displayModal={displayModal}
				record={selectedRecord}
				engagementInfo={props.engagementInfo}
				foreignAffiliate={props.foreignAffiliate}
				lineConfiguration={props.lineConfiguration}
				onRefresh={onRefresh}
				onReset={() =>
				{
					setSelectedRecord(undefined);
				}}
				onToggleDisplayModal={(display) =>
				{
					setDisplayModal(!!display);
				}}/>
		</>
	);
};
