import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { IExemptAndTaxableEarnings } from 'src/features/engagement/models/IExemptAndTaxableEarnings';
import ExemptAndTaxableEarningsFields  from './../Settings/ExemptAndTaxableEarningsFields';
import { IReportField } from 'src/common/types/interfaces/IReportField';
import ReportHeader from '../ReportCells/ReportHeader';
import { ReportI18NKeys } from 'src/common/types/enums/ReportI18NKeys';
import ReportRow from '../ReportCells/ReportRow';
import { Loading } from '@appkit4/react-components';
import { Utils } from 'src/common/utils/utils';
import './ExemptEarningsAndTaxableEarnings.scss';

const ExemptEarningsAndTaxableEarningReport = (props: {refershReport: boolean}) =>
{
	const {engagementId, affiliateId } = useParams();
	const [reportData, setReportData] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [totalAmounts, setTotalAmounts] = useState<any>();

	const report: IExemptAndTaxableEarnings = ExemptAndTaxableEarningsFields;

	const { t } = useTranslation(
		'report',
		{
			keyPrefix: ReportI18NKeys.ExemptAndTaxableEarnings
		}
	);

	const calculateRowTotal = (data: { [key: string]: any }[]): any =>
	{
		const earningsParentKeys: string[] = ['exemptEarnings', 'taxableEarnings'];

		const earningsSum: any = {
		};
		data.forEach((item) =>
		{
			earningsParentKeys.forEach((earning) =>
			{
				Object.keys(item[earning]).forEach((key) =>
				{
					const newKey: string = `${earning}-${key}`;
					const amount = item[earning][key];
					earningsSum[newKey] = !!earningsSum[newKey] ? [...earningsSum[newKey], amount || 0] : [amount || 0];
				});
			});
		});

		const earningsTotal: any = {
		};
		Object.keys(earningsSum).forEach((earning: string) =>
		{
			const properties = earning.split('-');
			earningsTotal[properties[0]] =  {
				...earningsTotal[properties[0]],
				[properties[1]]: Utils.add(...earningsSum[earning])
			};
		});

		return earningsTotal;
	};

	const loadExemptAndTaxableEarningsReportData = () =>
	{
		if ( engagementId && affiliateId )
		{
			setIsLoading(true);
			engagementService.loadExemptAndTaxableEarningsReport(Number(engagementId), Number(affiliateId)).then((res: any) =>
			{
				setIsLoading(false);
				setReportData(res.data.result);
				const rowTotal = calculateRowTotal(res.data.result);
				setTotalAmounts(rowTotal);
			})
				.catch((error: any) =>
				{
					setIsLoading(false);
				});
		}
	};
	useEffect(() =>
	{
		loadExemptAndTaxableEarningsReportData();
	},[engagementId, affiliateId]);

	useEffect(() =>
	{
		if (!!props.refershReport)
		{
			loadExemptAndTaxableEarningsReportData();
		}
	}, [props.refershReport]);

	return <div className='exempt-and-taxable-earnings-report'>

		{ExemptAndTaxableEarningsFields && <div className='table-container'>
			{isLoading && (
				<div className="loading-icon">
					<Loading
						loadingType="circular"
						indeterminate={true}
						compact={false}
					/>
				</div>
			)}
			{reportData &&
			<table>
				<thead>
					<tr>
						<div className='header-title'>
							{t('header-title')}
						</div>
						{
							Array.from({
								length: reportData.length
							}, (_, index) =>
							{
								return (
									<th></th>
								);
							}
							)
						}
					</tr>
				</thead>
				<tbody>
					{report.header.map((field: any) =>
						<ReportHeader reportData={reportData} field={field}
							i18nKey={ReportI18NKeys.ExemptAndTaxableEarnings} totalAmounts={totalAmounts}/>
					  )}
					{report.details.map((field: IReportField) =>
						<ReportRow reportData={reportData} field={field}
							i18nKey={ReportI18NKeys.ExemptAndTaxableEarnings} totalAmounts={totalAmounts}/>
					  )}
				</tbody>
			</table>
			}
		</div>}
	</div>;
};

export default ExemptEarningsAndTaxableEarningReport;