export enum EarningsSummaryFields {
	earningsUnderForeignRules = 'earningsUnderForeignRules',
	earningsUnderCanadianRules = 'earningsUnderCanadianRules',
	foreignTaxableIncome = 'foreignTaxableIncome',
	reg5907Adjustments = 'reg5907Adjustments',
	additionalReg5907Adjustments = 'additionalReg5907Adjustments',
	fapi = 'fapi',
	deemedABI =	'deemedABI',
	abi =	'abi',
	otherABIAdjustments =	'otherABIAdjustments',
	otherTaxableEarnings=	'otherTaxableEarnings',
	lessCapitalGain =	'lessCapitalGain',
	lessIncomeFromPropertyFAPI =	'lessIncomeFromPropertyFAPI',
	lessIncomeFromPropertyABI =	'lessIncomeFromPropertyABI'
}