import { useEffect, useState } from 'react';
import { INotesEditorProps } from './INotesEditorProps';

import './NotesEditor.scss';
import { Button, Modal } from '@appkit4/react-components';
import { useTranslation } from 'react-i18next';
import { TextAreaFormControl } from '../FormControls';

export function NotesEditor(props: INotesEditorProps): JSX.Element
{
	const [notes, setNotes] = useState<string>('');
	const [displayNotesModal, setDisplayNotesModal] = useState(false);

	const { t } = useTranslation(
		'common',
		{
			keyPrefix: 'notesEditor'
		}
	);

	const onEditNotes = () =>
	{
		setDisplayNotesModal(true);
	};

	const onSaveNotes = () =>
	{
		!!props.onSave && props.onSave(notes);
		onCloseModal();
	};

	const onCloseModal = () =>
	{
		setDisplayNotesModal(false);

		if (!!props.onClose)
		{
			props.onClose();
		}
	};

	useEffect(
		() =>
		{
			setNotes(!!props.notes ? props.notes : '');
		},
		[props.notes, displayNotesModal]
	);

	return <>
		<div className={'notes-editor'}>
			{/*only for displaying filled notes on summary reports */}
			{!!props.displayOnly ?  <div className={'only-display-notes'}>
				<span
					className={`${!!props.displayOnly && !!props.notes  ? 'Appkit4-icon icon-note-fill' : ''}`}
					title={!!props.notes ? t('showNotesTooltip') || '' : ''}
					onClick={() =>
					{
						onEditNotes();
					}}
				/>
			</div> :
				<span
					className={`Appkit4-icon ${!!props.notes ? 'icon-note-fill' : 'icon-note-outline'}`}
					title={!!props.notes ? t('editNotesTooltip') || '' : t('addNotesTooltip') || ''}
					onClick={() =>
					{
						onEditNotes();
					}}
				/>}
		</div>
		<Modal
			className={'notes-editor-modal'}
			visible={!!displayNotesModal}
			closeOnPressEscape={true}
			maskCloseable={false}
			closable={false}
			initialFocus={false}
			footer={
				<div className={'buttons'}>
					<div className={'left'}></div>
					<div className={'right'}>
						{
							!props.isDisabled &&
							<Button
								dis
								onClick={onSaveNotes}
							>
								{
									t('okButtonText')
								}
							</Button>

						}
						<Button
							kind={'secondary'}
							onClick={() =>
							{
								onCloseModal();
							}}
						>
							{
								t('cancelButtonText')
							}
						</Button>
					</div>
				</div>
			}
		>
			<div className={'notes-editor-modal-body'}>
				<div className={'notes-editor-form-body'}>
					<TextAreaFormControl
						field={''}
						isDisabled={props.isDisabled}
						isRequired={false}
						title={t('notesControlTitle') || ''}
						maxLength={250}
						value={!!notes ? notes : ''}
						onChange={(_field: string, value: string) =>
						{
							setNotes(value);
						}}
					/>
				</div>
			</div>
		</Modal>
	</>;
}