import React, { useEffect, useRef, useState } from 'react';
import { Stepper, Step } from '@progress/kendo-react-layout';
import { IEngagementStep } from '../../../models/IEngagementStep';
import { EngagementStepEnum } from 'src/features/engagement/models/EngagementStepEnum';
import { useTranslation } from 'react-i18next';
import './EngagementStepper.scss';
import { EngagementStepSubject } from 'src/core/services/EventBus';
import { EVentTypeEnum } from 'src/common/types/enums/EventTypeEnum';

const CustomStep = (props: any) =>
{
	return (
		<Step
			{...props}
			id={props.index}
			className={`${props.ready === 'true' ? 'ready-link' : 'pending-link'}`}
		>
			<div
				className={`${
					props.substep === 'true' ? 'custom-sub-step' : 'custom-step'
				} ${props.ready === 'true' ? 'ready-step' : 'pending-step'}`}
			></div>
			<span
				id={props.index}
				className={`${props.substep === 'true' ? 'sub-step' : 'main-step'} ${
					props.ready === 'true' ? 'ready-label' : 'pending-label'
				} 
				 ${props.active ? 'selected' : ''}
				`}
			>
				{props.label}
			</span>
		</Step>
	);
};

const EngagementStepper = (props: {
  stepIndex: number;
  onStepChange: (stepIndex: number) => void;
}) =>
{
	const [maxStep, setMaxStep] = useState<number>(0);
	const maxStepRef = useRef<number>(maxStep);
	const [currentStep, setCurrentStep] = useState<number>(0);

	const { t } = useTranslation('engagementSetup');

	const customSteps: IEngagementStep[] = [
		{
			label: t('stepper.engagementDetails'),
			substep: 'false',
			active: currentStep===EngagementStepEnum.EngagementDetails,
			ready: 'true'
		},
		{
			label: t('stepper.manageMembers'),
			substep: 'false',
			active: currentStep===EngagementStepEnum.ManageMembers,
			ready: maxStep>=EngagementStepEnum.ManageMembers  ? 'true' : 'false'
		},
		{
			label: t('stepper.foreignAffiliates'),
			substep: 'false',
			active: currentStep>=EngagementStepEnum.AddForeignAffiliates,
			ready: maxStep>=EngagementStepEnum.AddForeignAffiliates  ? 'true' : 'false'
		},
		{
			label: t('stepper.addForeignAffiliates'),
			substep: 'true',
			active: currentStep===EngagementStepEnum.AddForeignAffiliates,
			ready: maxStep>=EngagementStepEnum.AddForeignAffiliates  ? 'true' : 'false'
		},
		{
			label: t('stepper.calculationTypes'),
			substep: 'true',
			active: currentStep===EngagementStepEnum.CalculationTypes,
			ready: maxStep>= EngagementStepEnum.CalculationTypes ? 'true' : 'false'
		},
		{
			label: t('stepper.relationships'),
			substep: 'true',
			active: currentStep===EngagementStepEnum.Relationships,
			ready: maxStep>=EngagementStepEnum.Relationships ? 'true' : 'false'
		}
	];

	const handleChange = (e: any) =>
	{
		const stepIndex = e.value;
		props.onStepChange(stepIndex);
	};

	useEffect(() =>
	{
		setCurrentStep(props.stepIndex);
		if (props.stepIndex > maxStep)
		{
			//Stepper onChange event will not be fire when the value is MaxStep
			setMaxStep(props.stepIndex>=EngagementStepEnum.Relationships?EngagementStepEnum.Relationships+1:props.stepIndex);
		}
	}, [props.stepIndex]);

	//Update stepper based on user action
	useEffect(() =>
	{
		const subscription = EngagementStepSubject.subscribe((e: any) =>
		{
			if (e.eventType === EVentTypeEnum.EngagementStepper)
			{
				const lastStepIndex = e.payload;
				if (lastStepIndex<maxStepRef.current)
				{
					setMaxStep(lastStepIndex);
				}
			}
		});

		return () =>
		{
			subscription.unsubscribe();
		};
	}, []);

	useEffect(() =>
	{
		maxStepRef.current = maxStep;
	},
	[maxStep]);

	return (
		<div className="engagement-stepper">
			<Stepper
				value={maxStep}
				onChange={handleChange}
				items={customSteps}
				item={CustomStep}
				orientation={'vertical'}
			/>
		</div>
	);
};

export default EngagementStepper;
