
import { LineItemCategories } from 'src/common/types/enums/LineItemCategories';
import { inputRecordHelper } from 'src/common/utils/inputRecordHelper';
import { useContext, useEffect, useState } from 'react';
import { ILineItem } from 'src/common/types/interfaces/ILineItem';
import { CapitalGainsDispositionHelper } from './CapitalGainsDispositionHelper';
import { useTranslation } from 'react-i18next';
import { NotificationService } from 'src/core/services/notificationService';
import {
	ICapitalGainsDisposition,
	CapitalGainsDisposition as CapitalGainsDispositionEntity
} from 'src/common/types/interfaces/ICapitalGainsDisposition';
import { Utils } from 'src/common/utils/utils';
import { engagementService } from 'src/features/engagement/services/engagementService';
import cloneDeep from 'lodash/cloneDeep';
import { ColumnarGrid } from 'src/common/components/ColumnarGrid/ColumnarGrid';
import { HorizontalScrollContainer } from 'src/common/components/HorizontalScrollContainer/HorizontalScrollContainer';
import { CapitalGainsDispositionForm } from './CapitalGainsDispositionForm/CapitalGainsDispositionForm';
import { EngagementDetail, IEngagementDetail } from 'src/features/engagement/models/IEngagementDetail';
import { ForeignAffiliate, IForeignAffiliate } from 'src/features/engagement/models/IForeignAffiliate';

import './CapitalGainsDisposition.scss';
import AffiliateContext from 'src/common/components/SharedDataContext/AffiliateContext';

export function CapitalGainsDisposition(): JSX.Element
{
	const { t } = useTranslation(
		'input',
		{
			keyPrefix: 'capitalGainsDisposition'
		}
	);

	const { t: fieldsTranslationService } = useTranslation(
		'input',
		{
			keyPrefix: 'capitalGainsDispositionFieldNames'
		}
	);

	const affiliateContext = useContext(AffiliateContext);
	const [lineConfiguration, setLineConfiguration] = useState<ILineItem[]>([]);
	const [engagementInfo, setEngagementInfo] = useState<IEngagementDetail | undefined>(undefined);
	const [foreignAffiliate, setForeignAffiliate] = useState<IForeignAffiliate | undefined>(undefined);
	const [rowInformation, setRowInformation] = useState(
		CapitalGainsDispositionHelper.getRowInformation(fieldsTranslationService)
	);

	const [capitalGainsDispositionRecords, setCapitalGainsDispositionRecords] = useState(
		inputRecordHelper
			.getStartRowKeysAsDictionary<ICapitalGainsDisposition>(
				CapitalGainsDispositionHelper.getRowInformation(fieldsTranslationService)
			)
	);

	const [isLoading, setIsLoading] = useState(true);

	const [selectedRecord, setSelectedRecord] = useState<ICapitalGainsDisposition | undefined>(undefined);
	const [displayModal, setDisplayModal] = useState(false);
	const [isAddRecord, setIsAddRecord] = useState(false);

	const onOpenPanel = (isAdd: boolean, entity: ICapitalGainsDisposition): void =>
	{
		setIsAddRecord(!!isAdd);
		setSelectedRecord(entity);
		setDisplayModal(true);
	};

	const loadCapitalGainsDispositionData = async (
		{
			engagementId,
			affiliateId
		}:
		{
			engagementId: number;
			affiliateId: number;
		}
	): Promise<void> =>
	{
		try
		{
			const response = await engagementService
				.getCapitalGainsDispositionInput({
					engagementId,
					affiliateId
				});

			if (
				!!response &&
				!!response.data &&
				!!response.data.result
			)
			{
				const allRecords: ICapitalGainsDisposition[] = response.data.result
					.map(CapitalGainsDispositionEntity.populateFields);

				const a = {
					...cloneDeep(
						inputRecordHelper
							.getRowData(
								allRecords,
								rowInformation
							)
					)
				};
				setCapitalGainsDispositionRecords(a);
			}
		}
		catch (error)
		{
			triggerErrorNotification(
				t('capitalGainsDispositionError')
			);
		}
		finally
		{
			await Utils.timeout(1000);
			setIsLoading(false);
		}
	};

	const loadForeignAffiliateInformation = async (
		engagementId: number,
		affiliateId: number
	): Promise<void> =>
	{
		try
		{
			const result = await engagementService
				.getForeignAffiliate(
					engagementId,
					affiliateId
				);

			if (
				!!result &&
				!!result.data &&
				!!result.data.result
			)
			{
				setForeignAffiliate(
					ForeignAffiliate.parseFields(result.data.result)
				);
			}
		}
		catch (error)
		{
			triggerErrorNotification(
				t('foreignAffiliateInformationError')
			);
		}
	};

	const loadEngagementInformation = async (
		engagementId: number
	): Promise<void> =>
	{
		try
		{
			const result = await engagementService
				.getEngagementDetailById(
					engagementId
				);

			if (
				!!result &&
				!!result.data &&
				!!result.data.result
			)
			{
				setEngagementInfo(
					EngagementDetail.parseFields(result.data.result)
				);
			}
		}
		catch (error)
		{
			triggerErrorNotification(
				t('engagementInformationError')
			);
		}
	};

	const loadRowInformation = async (
		engagementId: number,
		affiliateId: number
	): Promise<void> =>
	{
		try
		{
			const lines = await inputRecordHelper
				.initializeLineItems(
					LineItemCategories.Disposition,
					engagementId,
					affiliateId
				);

			setRowInformation(
				CapitalGainsDispositionHelper.getRowInformation(
					fieldsTranslationService,
					lines
				)
			);

			setLineConfiguration(lines);
		}
		catch (error)
		{
			triggerErrorNotification(
				t('rowInformationError')
			);
		}
	};

	const onRefresh = async (): Promise<void> =>
	{
		setIsLoading(true);

		loadCapitalGainsDispositionData({
			engagementId: affiliateContext?.engagementDetail.id!,
			affiliateId: affiliateContext?.affiliateDetail.affiliateId!!
		});
	};

	const triggerErrorNotification = (message: string): void =>
	{
		NotificationService.error({
			message
		});
	};

	useEffect(
		() =>
		{
			if (
				!!affiliateContext &&
				!!affiliateContext.engagementDetail &&
				!!affiliateContext.affiliateDetail
			)
			{
				loadEngagementInformation(
					affiliateContext?.engagementDetail.id!
				);

				loadForeignAffiliateInformation(
					affiliateContext?.engagementDetail.id!,
					affiliateContext?.affiliateDetail.affiliateId!
				);

				loadRowInformation(
					affiliateContext?.engagementDetail.id!,
					affiliateContext?.affiliateDetail.affiliateId!
				);
			}
		},
		[affiliateContext]
	);

	useEffect(
		() =>
		{
			setIsLoading(true);

			if (
				!!lineConfiguration &&
				!!lineConfiguration.length &&
				!!affiliateContext &&
				!!affiliateContext.engagementDetail &&
				!!affiliateContext.affiliateDetail
			)
			{
				loadCapitalGainsDispositionData({
					engagementId: affiliateContext?.engagementDetail.id!,
					affiliateId: affiliateContext?.affiliateDetail.affiliateId!
				});
			}
		},
		[
			affiliateContext,
			lineConfiguration
		]
	);

	return <>
		<HorizontalScrollContainer>
			<ColumnarGrid
				isLoading={isLoading}
				title={t('gridTitle')}
				addNewMessage={t('addNewMessage')}
				addNewButtonText={t('addNewButtonText')}
				columnIndexKeyName={t('columnIndexKeyName')}
				rowInformation={rowInformation}
				data={
					!!capitalGainsDispositionRecords ?
						capitalGainsDispositionRecords :
						{
						}
				}
				onOpenNew={() =>
				{
					onOpenPanel(
						true,
						new CapitalGainsDispositionEntity(
							affiliateContext?.engagementDetail.id!,
							affiliateContext?.affiliateDetail.affiliateId!,
							foreignAffiliate?.currencyId!
						)
					);
				}}
				onEdit={(entity) =>
				{
					onOpenPanel(
						false,
						entity
					);
				}}
			/>
		</HorizontalScrollContainer>
		<CapitalGainsDispositionForm
			isAdd={isAddRecord}
			displayModal={displayModal}
			record={selectedRecord}
			engagementInfo={engagementInfo}
			foreignAffiliate={foreignAffiliate}
			lineConfiguration={lineConfiguration}
			onRefresh={onRefresh}
			onReset={() =>
			{
				setSelectedRecord(undefined);
			}}
			onToggleDisplayModal={(display) =>
			{
				setDisplayModal(!!display);
			}}
		/>
	</>;
}