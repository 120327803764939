import React, { useContext, useEffect } from 'react';
import './App.scss';
import { BrowserRouter, Route, RouterProvider, Routes } from 'react-router-dom';
import RequestInterceptor from './common/components/RequestInterceptor';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './common/utils/appInsights';
import './i18n/config';
import { IAuthContext } from './features/authentication/Types';
import { AuthContext } from './features/authentication/AuthContext';
import LoginPage from './core/pages/Login/LoginPage';
import { router } from './core/components/AppRoutes';
import { SessionTimeout } from './core/components/SessionTimeout';
import { AccessDeniedPage } from './core/pages/AccessDenied/AccessDeniedPage';
import { NotificationService } from './core/services/notificationService';
import { useDispatch } from 'react-redux';
import signalRService from './common/services/signalRService';
import { setSignalRHubConnectionOpen } from 'src/store/reducers/notificationSlice';
import { fetchUserProfileAsync } from 'src/store/reducers/userProfileSlice';
import { AppDispatch } from 'src/store/store';

function App()
{
	const { token }: IAuthContext = useContext(AuthContext);

	const dispatch = useDispatch();
	const appDispatch: AppDispatch = useDispatch();


	useEffect(() =>
	{
		if (token)
		{
			appDispatch(fetchUserProfileAsync());// fetch user profile at App startup
		}
	}, [appDispatch, token]);

	useEffect(() =>
	{
		if (token)
		{
			const initializeConnection = async () =>
			{
				signalRService.setDispatch(dispatch);
				await signalRService.startConnection().then(() =>
				{
					dispatch(setSignalRHubConnectionOpen());
				});
			};

			signalRService.stopConnection().then(async () =>
			{
				initializeConnection();
			});

			return () =>
			{
				signalRService.stopConnection();
			};
		}
	}, [dispatch, token]);

	useEffect(
		() =>
		{
			// only initialize service once at startup of application
			NotificationService.initialize();
		},
		[]
	);

	return (
		<div className="App">
			{token? (

				<AppInsightsContext.Provider value={reactPlugin}>
					<RequestInterceptor>
						<RouterProvider router={router} />
						<SessionTimeout
							timeOutInterval={process.env.REACT_APP_TIMEOUT}
						></SessionTimeout>
					</RequestInterceptor>
				</AppInsightsContext.Provider>

			) : (
				<BrowserRouter>
					<Routes>
						{/* <Route path="/login" element={<LoginPage />} /> */}
						<Route path="access-denied" element={<AccessDeniedPage />} />
						<Route path="*" element={<LoginPage />} />
					</Routes>
				</BrowserRouter>
			)}
		</div>
	);
}

export default App;
