import { IDividendsPaidFormProps } from './IDividendsPaidFormProps';
import { DeleteModal } from 'src/common/components/DeleteModal/DeleteModal';
import { useTranslation } from 'react-i18next';
import { NotificationService } from 'src/core/services/notificationService';
import {
	Button,
	Input,
	Modal,
	Select,
	TextArea,
	TimePicker
} from '@appkit4/react-components';
import { useEffect, useState } from 'react';
import { UnsavedChangesModal } from 'src/core/components/Dashboard/unsavedModal/UnsavedChangesModal';
import { UnexpectedErrorModal } from 'src/common/components/UnexpectedErrorModal/UnexpectedErrorModal';
import { DividendPaid, IDividendPaid } from 'src/common/types/interfaces/IDividendsPaid';
import { DividendPaidFields } from 'src/common/types/enums/DividendsPaidFields';
import { SelectValue } from '@appkit4/react-components/esm/combobox/Combobox';
import { RegularPatternEnum } from 'src/common/types/enums/RegularPatternEnum';
import { InputError } from 'src/common/components/ModalControls/InputError/InputError';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { Utils } from 'src/common/utils/utils';

import './DividendsPaidForm.scss';
import { CalendarPickerWrapper } from 'src/common/components/CalendarPickerWrapper/CalendarPickerWrapper';

const defaultFormError: Record<string, string | null> = {
	[DividendPaidFields.date]: null,
	[DividendPaidFields.time]: null,
	[DividendPaidFields.currencyId]: null,
	[DividendPaidFields.amount]: null,
	[DividendPaidFields.underlyingForeignTax]: null,
	[DividendPaidFields.paidOnEquityClassId]: null,
	[DividendPaidFields.isSurplusElection]: null,
	[DividendPaidFields.isCapitalElection]: null,
	[DividendPaidFields.notes]: null
};

export function DividendsPaidForm(props: IDividendsPaidFormProps): JSX.Element
{
	const { t } = useTranslation(
		'input',
		{
			keyPrefix: 'dividendsPaidForm'
		}
	);

	const { t: fieldNames } = useTranslation(
		'input',
		{
			keyPrefix: 'dividendsPaidFieldNames'
		}
	);

	const { t: errorMessages } = useTranslation(
		'input',
		{
			keyPrefix: 'dividendsPaidFormErrorMessages'
		}
	);

	const [record, setRecord] = useState<IDividendPaid | undefined>(undefined);

	const [hasChange, setHasChange] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [selectedElectionType, setSelectedElectionType] = useState({
		dropdown: '',
		value: false
	});

	const [formError, setFormError] = useState<Record<string, any>>(defaultFormError);

	const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
	const [displayUnsavedChangesModal, setDisplayUnsavedChangesModal] = useState(false);
	const [errorModalMessage, setErrorModalMessage] = useState('');

	const onCreate = async (): Promise<void> =>
	{
		try
		{
			setIsSubmitted(true);

			if (!!checkUpdateFormValidity(record!))
			{
				setIsSaving(true);

				await engagementService.createDividendPaid(
					record!
				);

				onCloseModal(true);
				setIsSaving(false);

				onSuccessNotification({
					isCreate: true
				});
			}
		}
		catch (error)
		{
			setErrorModalMessage(errorMessages('createFailed') || '');
			setIsSaving(false);
		}
	};

	const onUpdate = async (): Promise<void> =>
	{
		try
		{
			setIsSubmitted(true);

			if (!!checkUpdateFormValidity(record!))
			{
				setIsSaving(true);

				await engagementService.updateDividendPaid({
					...record!,
					engagementId: props.engagementInfo?.id!
				});

				onCloseModal(true);
				setIsSaving(false);

				onSuccessNotification({
					isUpdate: true
				});
			}
		}
		catch (error)
		{
			setErrorModalMessage(errorMessages('updateFailed') || '');
			setIsSaving(false);
		}
	};

	const onDelete = async (): Promise<void> =>
	{
		try
		{
			setIsSubmitted(true);

			setDisplayDeleteModal(false);

			if (!!checkUpdateFormValidity(record!))
			{
				setIsSaving(true);

				await engagementService.deleteDividendPaid(
					props.engagementInfo?.id!,
					record!.id
				);

				onCloseModal(true);
				setIsSaving(false);

				onSuccessNotification({
					isDelete: true
				});
			}
		}
		catch (error)
		{
			setErrorModalMessage(errorMessages('deleteFailed') || '');
			setIsSaving(false);
		}
	};

	const onDisplayDeleteModal = (): void =>
	{
		setDisplayDeleteModal(true);
	};

	const onCloseModal = (isSuccess?: boolean): void =>
	{
		if (!!hasChange && !isSuccess)
		{
			setDisplayUnsavedChangesModal(true);
		}
		else
		{
			onClose(!!isSuccess);
		}
	};

	const onCloseUnsavedChangesModal = (key: 'leave' | 'stay'): void =>
	{
		if (key === 'leave')
		{
			onClose();
		}
		else
		{
			setDisplayUnsavedChangesModal(false);
		}
	};

	const onReset = () =>
	{
		setIsSaving(false);
		setHasChange(false);
		setRecord(undefined);
		setIsSubmitted(false);
		setErrorModalMessage('');
		setDisplayDeleteModal(false);
		setDisplayUnsavedChangesModal(false);
		setFormError({
			...formError
		});
	};

	const onClose = async (isSuccess?: boolean): Promise<void> =>
	{
		setDisplayUnsavedChangesModal(false);
		props.onToggleDisplayModal(false);

		await Utils.timeout(500);

		onReset();
		props.onReset();

		if (!!isSuccess)
		{
			props.onRefresh();
		}
	};

	const onSuccessNotification = (
		{
			isCreate,
			isUpdate,
			isDelete
		}:
		{
			isCreate?: boolean;
			isUpdate?: boolean;
			isDelete?: boolean;
		}
	) =>
	{
		let message = '';

		if (isCreate)
		{
			message = t('createSuccess');
		}
		else if (isUpdate)
		{
			message = t('updateSuccess');
		}
		else if (isDelete)
		{
			message = t('deleteSuccess');
		}

		NotificationService.success({
			message
		});
	};

	const onValueChange = (field: string, value: any): void =>
	{
		if (field === DividendPaidFields.isSurplusElection || field === DividendPaidFields.isCapitalElection)
		{
			setSelectedElectionType({
				dropdown: field,
				value: value === '' ? undefined : value
			});
		}

		const updatedForm: IDividendPaid = {
			...record!,
			[field]: value === '' ? undefined : value
		};

		checkUpdateFormValidity(updatedForm);

		setRecord(updatedForm);
	};

	const checkUpdateFormValidity = (form: IDividendPaid): boolean =>
	{
		const errors = {
			...defaultFormError
		};

		if (!form[DividendPaidFields.date])
		{
			errors[DividendPaidFields.date] = errorMessages('dateRequired');
		}

		if (!form[DividendPaidFields.time])
		{
			errors[DividendPaidFields.time] = errorMessages('timeRequired');
		}

		if (!form[DividendPaidFields.currencyId])
		{
			errors[DividendPaidFields.currencyId] = errorMessages('currencyRequired');
		}

		if (!form[DividendPaidFields.amount])
		{
			errors[DividendPaidFields.amount] = errorMessages('amountRequired');
		}

		if (!form[DividendPaidFields.paidOnEquityClassId])
		{
			errors[DividendPaidFields.paidOnEquityClassId] = errorMessages('paidOnEquityClassRequired');
		}

		setFormError({
			...errors
		});

		// check if any fields have error messages and return status
		return Object.values(errors).every((e) => !e);
	};

	useEffect(
		() =>
		{
			setSelectedElectionType(
				{
					dropdown: props.record?.isCapitalElection?
						DividendPaidFields.isCapitalElection:
						props.record?.isSurplusElection?
							DividendPaidFields.isSurplusElection:
							'',
					value: !!props.record?.isCapitalElection || !! props.record?.isSurplusElection
				}
			);

			if (!!props.record)
			{
				setRecord({
					...props.record
				});
			}
			else
			{
				setRecord(undefined);
			}
		},
		[props.record]
	);

	useEffect(
		() =>
		{
			setHasChange(
				!DividendPaid.isSame(props.record, record)
			);
		},
		[record]
	);

	return <>
		<Modal
			className={'dividends-paid-modal'}
			visible={
				props.displayModal &&
				!!props.record &&
				!!record
			}
			closeOnPressEscape={false}
			maskCloseable={false}
			initialFocus={false}
			title={
				!!props.isAdd ?
					t('addModalTitle') || '' :
					t('editModalTitle') || ''
			}
			onCancel={() =>
			{
				onCloseModal();
			}}
			footer={
				<div className={'buttons'}>
					<div className={'left'}>
						{
							!props.isAdd &&
							<Button
								kind={'negative'}
								disabled={!!isSaving}
								onClick={() => onDisplayDeleteModal()}
							>
								{
									t('deleteButtonText')
								}
							</Button>
						}
					</div>
					<div className={'right'}>
						{
							!!props.isAdd &&
							<Button
								disabled={
									!hasChange ||
									!!isSaving
								}
								onClick={onCreate}
							>
								{
									t('saveButtonText')
								}
							</Button>
						}
						{
							!props.isAdd &&
							<Button
								disabled={
									!hasChange ||
									!!isSaving
								}
								onClick={onUpdate}
							>
								{
									t('saveButtonText')
								}
							</Button>
						}
						<Button
							kind={'secondary'}
							disabled={!!isSaving}
							onClick={() =>
							{
								onCloseModal();
							}}
						>
							{
								t('cancelButtonText')
							}
						</Button>
					</div>
				</div>
			}
		>
			{
				!!props.record &&
				!!record &&
				!!props.lineConfiguration &&
				!!props.lineConfiguration.length &&
				<div className={'dividends-paid-modal-body'}>
					<div className={'dividends-paid-form-body'}>
						<div className={'calendar-time'}>
							<CalendarPickerWrapper
								required
								useCustomValidation
								editable={false}
								fieldTitle={
									fieldNames(DividendPaidFields.date) || ''
								}
								format={'MMM DD, YYYY'}
								value={record[DividendPaidFields.date]}
								minDate={
									!!props.foreignAffiliate && !!props.foreignAffiliate.analysisStartDate ?
										props.foreignAffiliate.analysisStartDate :
										new Date(1972, 1, 1)
								}
								maxDate={
									!!props.engagementInfo && !!props.engagementInfo.lastCalculationTaxationYearEnd ?
										props.engagementInfo.lastCalculationTaxationYearEnd :
										new Date()
								}
								datePanelHorizontalAlign={'right'}
								error={
									!!isSubmitted &&
									!!formError &&
									!!formError[DividendPaidFields.date]
								}
								customErrorNode={<></>}
								onChange={(value: Date) =>
								{
									onValueChange(DividendPaidFields.date, new Date(value));
								}}
							/>
							<InputError
								isError={
									!!isSubmitted &&
									!!formError &&
									!!formError[DividendPaidFields.date]
								}
								message={formError[DividendPaidFields.date]}
							/>
						</div>
						<div className={'calendar-time'}>
							<TimePicker
								required
								hourTime={12}
								fieldTitle={
									fieldNames(DividendPaidFields.time) || ''
								}
								value={record[DividendPaidFields.time]}
								onChange={(value: Date) =>
								{
									onValueChange(DividendPaidFields.time, new Date(value));
								}}
								error={
									!!isSubmitted &&
									!!formError &&
									!!formError[DividendPaidFields.time]
								}
							/>
							<InputError
								isError={
									!!isSubmitted &&
									!!formError &&
									!!formError[DividendPaidFields.time]
								}
								message={formError[DividendPaidFields.time]}
							/>
						</div>
						<div>
							<Select
								required
								searchable
								data={
									!!props.lineConfiguration &&
									props.lineConfiguration.some((l) => l.field === DividendPaidFields.currencyId) ?
										props.lineConfiguration.find((l) => l.field === DividendPaidFields.currencyId)!.options :
										[]
								}
								labelKey={'label'}
								valueKey={'value'}
								dropdownRenderMode={'portal'}
								value={record[DividendPaidFields.currencyId]}
								placeholder={
									fieldNames(DividendPaidFields.currencyId) || ''
								}
								error={
									!!isSubmitted &&
									!!formError &&
									!!formError[DividendPaidFields.currencyId]
								}
								noResultFound={
									<>
										{
											t('noDataAvailable')
										}
									</>
								}
								onSelect={(value: SelectValue) =>
									onValueChange(DividendPaidFields.currencyId, value)
								}
							/>
							<InputError
								isError={
									!!isSubmitted &&
									!!formError &&
									!!formError[DividendPaidFields.currencyId]
								}
								message={formError[DividendPaidFields.currencyId]}
							/>
						</div>
						<div>
							<Input
								required
								type={'text'}
								title={
									fieldNames(DividendPaidFields.amount) || ''
								}
								value={
									record[DividendPaidFields.amount] !== undefined &&
									record[DividendPaidFields.amount] !== null ?
										`${record[DividendPaidFields.amount]}` :
										''
								}
								error={
									!!isSubmitted &&
									!!formError &&
									!!formError[DividendPaidFields.amount]
								}
								onChange={(value: string) =>
								{
									const regPattern = new RegExp(RegularPatternEnum.CurrencyNonNegative);
									if (!regPattern.test(value))
									{
										return;
									}

									onValueChange(
										DividendPaidFields.amount,
										value
									);
								}}
							/>
							<InputError
								isError={
									!!isSubmitted &&
									!!formError &&
									!!formError[DividendPaidFields.amount]
								}
								message={formError[DividendPaidFields.amount]}
							/>
						</div>
						<div>
							<Select
								required
								searchable
								data={
									!!props.lineConfiguration &&
									props.lineConfiguration.some((l) => l.field === DividendPaidFields.paidOnEquityClassId) ?
										props.lineConfiguration.find((l) => l.field === DividendPaidFields.paidOnEquityClassId)!.options :
										[]
								}
								labelKey={'label'}
								valueKey={'value'}
								dropdownRenderMode={'portal'}
								value={record[DividendPaidFields.paidOnEquityClassId]}
								placeholder={
									fieldNames(DividendPaidFields.paidOnEquityClassId) || ''
								}
								error={
									!!isSubmitted &&
									!!formError &&
									!!formError[DividendPaidFields.paidOnEquityClassId]
								}
								noResultFound={
									<>
										{
											t('noDataAvailable')
										}
									</>
								}
								onSelect={(value: SelectValue) =>
									onValueChange(DividendPaidFields.paidOnEquityClassId, value)
								}
							/>
							<InputError
								isError={
									!!isSubmitted &&
									!!formError &&
									!!formError[DividendPaidFields.paidOnEquityClassId]
								}
								message={formError[DividendPaidFields.paidOnEquityClassId]}
							/>
						</div>
						<div>
							<Input
								type={'text'}
								title={
									fieldNames(DividendPaidFields.underlyingForeignTax) || ''
								}
								value={
									record[DividendPaidFields.underlyingForeignTax] !== undefined &&
									record[DividendPaidFields.underlyingForeignTax] !== null ?
										`${record[DividendPaidFields.underlyingForeignTax]}` :
										''
								}
								error={
									!!isSubmitted &&
									!!formError &&
									!!formError[DividendPaidFields.underlyingForeignTax]
								}
								onChange={(value: string) =>
								{
									const regPattern = new RegExp(RegularPatternEnum.Currency);
									if (!regPattern.test(value))
									{
										return;
									}

									onValueChange(
										DividendPaidFields.underlyingForeignTax,
										value
									);
								}}
							/>
							<InputError
								isError={
									!!isSubmitted &&
									!!formError &&
									!!formError[DividendPaidFields.underlyingForeignTax]
								}
								message={formError[DividendPaidFields.underlyingForeignTax]}
							/>
						</div>
						<div>
							<Select
								required
								data={
									!!props.lineConfiguration &&
									props.lineConfiguration.some((l) => l.field === DividendPaidFields.isSurplusElection) ?
										props.lineConfiguration.find((l) => l.field === DividendPaidFields.isSurplusElection)!.options :
										[]
								}
								labelKey={'label'}
								valueKey={'value'}
								dropdownRenderMode={'portal'}
								dropdownAlwaysDown={false}
								value={
									record[DividendPaidFields.isSurplusElection] ?
										'true' :
										'false'
								}
								placeholder={
									fieldNames(DividendPaidFields.isSurplusElection) || ''
								}
								disabled={selectedElectionType.dropdown === DividendPaidFields.isCapitalElection &&
									selectedElectionType.value === true}
								error={
									!!isSubmitted &&
									!!formError &&
									!!formError[DividendPaidFields.isSurplusElection]
								}
								noResultFound={
									<>
										{
											t('noDataAvailable')
										}
									</>
								}
								onSelect={(value: SelectValue) =>
								{
									onValueChange(
										DividendPaidFields.isSurplusElection,
										JSON.parse(value.toString().toLowerCase())
									);
								}}
							/>
							<InputError
								isError={
									!!isSubmitted &&
									!!formError &&
									!!formError[DividendPaidFields.isSurplusElection]
								}
								message={formError[DividendPaidFields.isSurplusElection]}
							/>
						</div>
						<div>
							<Select
								required
								data={
									!!props.lineConfiguration &&
									props.lineConfiguration.some((l) => l.field === DividendPaidFields.isCapitalElection) ?
										props.lineConfiguration.find((l) => l.field === DividendPaidFields.isCapitalElection)!.options :
										[]
								}
								labelKey={'label'}
								valueKey={'value'}
								dropdownRenderMode={'portal'}
								dropdownAlwaysDown={false}
								value={
									record[DividendPaidFields.isCapitalElection] ?
										'true' :
										'false'
								}
								placeholder={
									fieldNames(DividendPaidFields.isCapitalElection) || ''
								}
								disabled={selectedElectionType.dropdown === DividendPaidFields.isSurplusElection &&
									selectedElectionType.value === true}
								error={
									!!isSubmitted &&
									!!formError &&
									!!formError[DividendPaidFields.isCapitalElection]
								}
								noResultFound={
									<>
										{
											t('noDataAvailable')
										}
									</>
								}
								onSelect={(value: SelectValue) =>
								{
									onValueChange(
										DividendPaidFields.isCapitalElection,
										JSON.parse(value.toString().toLowerCase())
									);
								}}
							/>
							<InputError
								isError={
									!!isSubmitted &&
									!!formError &&
									!!formError[DividendPaidFields.isCapitalElection]
								}
								message={formError[DividendPaidFields.isCapitalElection]}
							/>
						</div>
						<div>
							<TextArea
								autosize
								title={
									fieldNames(DividendPaidFields.notes) || ''
								}
								value={
									!!record[DividendPaidFields.notes] ?
										record[DividendPaidFields.notes] :
										''
								}
								maxLength={255}
								error={
									!!isSubmitted &&
									!!formError &&
									!!formError[DividendPaidFields.notes]
								}
								onChange={(value: string) =>
								{
									onValueChange(DividendPaidFields.notes, value);
								}}
							/>
							<InputError
								isError={
									!!isSubmitted &&
									!!formError &&
									!!formError[DividendPaidFields.notes]
								}
								message={formError[DividendPaidFields.notes]}
							/>
						</div>
					</div>
				</div>
			}
		</Modal>
		<UnsavedChangesModal
			visible={displayUnsavedChangesModal}
			onClose={onCloseUnsavedChangesModal}
			title={t('discardChangesTitle') || ''}
		/>
		<UnexpectedErrorModal
			visible={!!errorModalMessage}
			error={''}
			message={errorModalMessage}
			onClose={() =>
			{
				setErrorModalMessage('');
			}}
		/>
		<DeleteModal
			visible={displayDeleteModal}
			title={t('deleteModalTitle')}
			deleteMessage={t('deleteMessage')}
			setVisible={setDisplayDeleteModal}
			onDelete={onDelete}
		/>
	</>;
}