import { IInputErrorProps } from './IInputErrorProps';

import './InputError.scss';

export function InputError(props: IInputErrorProps): JSX.Element
{
	return <>
		{
			!!props.isError &&
			(
				!!props.message ||
				!!props.onRenderMessage
			) &&
			<div className={'input-error'}>
				{
					!!props.onRenderMessage ?
						props.onRenderMessage() :
						!!props.message ?
							props.message :
							''
				}
			</div>
		}
	</>;
}