import { IncomeTaxesPaid } from '../IncomeTaxesPaid/IncomeTaxesPaid';
import { PropertyIncome } from '../PropertyIncome/PropertyIncome';
import { DividendsPaid } from '../DividendsPaid/DividendsPaid';
import { DividendsReceived } from '../DividendsReceived/DividendsReceived';
import { CapitalGainsDisposition } from '../CapitalGainsDisposition/CapitalGainsDisposition';
import { InputEnum } from 'src/common/types/enums/InputEnum';
import RetainedEarnings from '../RetainedEarnings/RetainedEarnings';
import { AdjustedCostBaseInput } from '../AdjustedCostBase/AdjustedCostBase';
import {OtherAdjustments} from '../OtherAdjustments/OtherAdjustments';
import BookToForeignTaxableIncome from '../BookToForeignTaxableIncome/BookToForeignTaxableIncome';
import { ABIComputedUnderCanadianRules } from '../ABIComputedUnderCanadianRules/ABIComputedUnderCanadianRules';
import Earnings from '../Earnings/Earnings';
import SurplusEntitlementPercentageAdjustments from '../SurplusEntitlementPercentageAdjustments/SurplusEntitlementPercentageAdjustments';
import Reg5907Adjustments from '../Reg5907Adjustments/Reg5907Adjustments';

export const FADataView = (props: { inputId: InputEnum ,  handleChange: (change: boolean) => void}) =>
{
	return (
		<>
			{
				props.inputId === InputEnum.Earnings &&
				<Earnings handleChange={props.handleChange}/>
			}
			{
				props.inputId === InputEnum.BookToForeignTaxableIncome &&
				<BookToForeignTaxableIncome  handleChange={props.handleChange}/>
			}
			{
				props.inputId === InputEnum.Reg5907Adjustments &&
				<Reg5907Adjustments handleChange={props.handleChange}/>
			}
			{
				props.inputId === InputEnum.ActiveBusinessIncomeUnderCanadianRules &&
				<ABIComputedUnderCanadianRules handleChange={props.handleChange}/>
			}
			{
				props.inputId === InputEnum.IncomeFromProperty &&
				<PropertyIncome handleChange={props.handleChange}/>
			}
			{
				props.inputId === InputEnum.CapitalGains &&
				<CapitalGainsDisposition />
			}
			{
				props.inputId === InputEnum.IncomeTaxesPaid &&
				<IncomeTaxesPaid handleChange={props.handleChange}/>
			}
			{
				props.inputId === InputEnum.DividendsPaid &&
				<DividendsPaid />
			}
			{
				props.inputId === InputEnum.DividendsReceived &&
				<DividendsReceived handleChange={props.handleChange}/>
			}
			{
				props.inputId === InputEnum.OtherAdjustments &&
				<OtherAdjustments />
			}
			{
				props.inputId === InputEnum.SurplusEntitlementPercentageAdjustments &&
				<SurplusEntitlementPercentageAdjustments handleChange={props.handleChange}/>
			}
			{
				props.inputId === InputEnum.AdjustedCostBase &&
				<AdjustedCostBaseInput />
			}
			{
				props.inputId === InputEnum.RetainedEarnings &&
				<RetainedEarnings handleChange={props.handleChange}/>
			}
		</>
	);
};
