export enum InputFieldOptionKeys {

	DispositionType = 'DispositionType',
	YesNo = 'yesno',
	Currencies = 'currencies',
	Countries = 'countries',
	EquityClasses = 'EquityClasses',
	AllEquityClasses = 'AllEquityClasses',
	SurplusPool = 'surplusPool',
	SurplusDescriptions = 'surplusDescriptions',
	ACBTransactionType = 'acbTransactionType',
	TypeOfAdditionDeduction = 'typeOfAdditionDeduction'
}