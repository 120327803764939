export enum Reg5907AdjustmentsFields {

	id = 'id',
	engagementId = 'engagementId',
	affiliateId = 'affiliateId',
	foreignTaxableIncomeId = 'foreignTaxableIncomeId',
	isSpecificToTaxPayer = 'isSpecificToTaxPayer',
	currencyId = 'currencyId',
	amount = 'amount',
	descriptionId = 'descriptionId',
	notes = 'notes',
}

export enum Reg5907AdjustmentsTypes {

	TotalAdjustments = 1,
}

export enum Reg5907AdjustmentsFieldNames {

	Amount = 'amount',
	Notes = 'notes'
}