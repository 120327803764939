import React, { useEffect, useState } from 'react';
import { Button, Modal} from '@appkit4/react-components';
import './DeleteFAModal.scss';
import { IForeignAffiliate } from '../../../../models/IForeignAffiliate';
import { useTranslation } from 'react-i18next';
import useDisableBodyScroll from 'src/common/hooks/useDisableBodyScroll';
import { Constants } from 'src/common/types/constants';
interface IDeleteFAModalProps {
    selectedItems: IForeignAffiliate[],
  visible: boolean,
  title: string,
  setVisible: Function,
  onDelete: Function
}

export const DeleteFAModal = (props: IDeleteFAModalProps) =>
{
	const [moreOrLess, setMoreOrLess] = useState<number>(0);
	const [listItemHeight, setListItemHeight] = useState<string>('auto');
	const [initialRowHeight, setInitialRowHeight] = useState<number>(0);
	const { t } = useTranslation(
		'engagementSetup',
		{
			keyPrefix: 'deleteFAModal'
		}
	);

	const calcuateRows = () =>
	{
		const parent = document.getElementById('fa-list');
		const children = parent?.getElementsByClassName('list-wrapper');

		const tops = [];
		if (children)
		{
			for (let i = 0; i < children.length; i++)
			{
				const child = children[i];
				const rect = child.getBoundingClientRect();
				tops.push(rect.top);
			}
			const rows = [...new Set(tops)];
			if (rows.length > Constants.MaxInitialRowsCount)
			{
				if (moreOrLess < 2)
				{
					const newHeight = rows[Constants.MaxInitialRowsCount] - rows[0];
					setInitialRowHeight(newHeight);
					setListItemHeight(`${newHeight}px`);
					setMoreOrLess(1);
				}
			}
			else
			{
				setMoreOrLess(0);
				setListItemHeight('auto');
			}
		}
		else
		{
		}
	};

	const showMoreorLess = () =>
	{
		if (moreOrLess === 1)
		{
			setMoreOrLess(2);
			setListItemHeight('auto');
		}
		if (moreOrLess === 2)
		{
			setMoreOrLess(1);
			setListItemHeight(`${initialRowHeight}px`);
		}
	};

	useEffect(() =>
	{
		if (props.visible && props.selectedItems.length > 0)
		{
			setTimeout(() =>
			{
				calcuateRows();
			}, 2);
		}
	}, [props.selectedItems, props.visible]);


	useDisableBodyScroll(props.visible);


	if (props?.visible === false)
	{
		return null;
	}


	return (
		<>
			<Modal
				className="delete-one-or-more-modal"
				visible={props.visible}
				initialFocus={false}
				closeOnPressEscape={false}
				maskCloseable={false}
				title={props.title}
				onCancel={() =>
				{
					props.setVisible(false);
					setMoreOrLess(0);
				}}
				footer={
					<div className="footer-wrapper">
						<Button
							kind="secondary"
							onClick={() =>
							{
								props.setVisible(false);
								setMoreOrLess(0);
							}}
						>
							{t('cancel')}
						</Button>
						<Button kind="primary" onClick={() =>
						{
							props.onDelete();
							setMoreOrLess(0);
						}}>
							{t('remove')}
						</Button>
					</div>
				}
			>
				<div>
					{
						props.selectedItems.length > 1 && <div>
							<p>{`${t('deleteMutipleConfirm')} ${props.selectedItems.length} ${t('faFromEngagement')}`}</p>
							<div className="fa-list" id="fa-list" style={{
								height: listItemHeight
							}}>
								{props.selectedItems.map((item: any) =>
								{
									return (
										<div className="list-wrapper" key={item.id}>
											<ul className="left">
												{' '}
												<li className="selected-fa" key={`${item.id}`}>
													{item.name}
												</li>
											</ul>

										</div>
									);
								})}
							</div>
							<div>
								{moreOrLess > 0 && (
									<Button className='link-button showMoreButton'
										kind='text' onClick={showMoreorLess}>
									   {moreOrLess === 1 ? `${t('showMore')}` : `${t('showLess')}`}
								   </Button>
								)}
							</div>
						</div>}
					{
						props.selectedItems.length === 1 &&
						<p>{`${t('deleteOneConfirm')} "${props.selectedItems[0].name}"?`}</p>
					}

				</div>
			</Modal>
		</>
	);
};