import React from 'react';
import { ReportFieldEnum } from 'src/common/types/enums/ReportFieldEnum';
import { ReportUtil } from 'src/features/engagement/utils/ReportUtil';
import ReportCell from './ReportCell';
import { useTranslation } from 'react-i18next';

const ReportHeader = (props: {reportData: any, field: any, i18nKey: string, totalAmounts?: any}) =>
{
	const { t } = useTranslation(
		'report',
		{
			keyPrefix: props.i18nKey
		}
	);

	const renderReportHeader = (data: any, field: any, totalAmounts?: any) =>
	{
		if (field.type===ReportFieldEnum.CurrencyId)
		{
			return (<></>);
		}
		else
			if (field.type===ReportFieldEnum.CountryId)
			{
				return (
					<tr className={`header ${ReportUtil.getRowClassName(field)}`}>
						<td>
							<div>{`${t(ReportFieldEnum.CountryId)}`}</div>
							<div>{`${t(ReportFieldEnum.CalculatingCurrencyId)}`}</div>
						</td>
						{!!totalAmounts && <td className={'total'}>
							{`${t(ReportFieldEnum.Total)}`}
						</td>}
						{
							data.map((item: any) =>
							{
								return (
									<td>
										<div>
											<ReportCell data={item} field={field} />
										</div>
										<div>
											<ReportCell data={item} field={{
												name: ReportFieldEnum.CalculatingCurrencyId,
												type: ReportFieldEnum.CurrencyId
											}} />
										</div>
									</td>
								);
							})
						}
					</tr>);
			}
			else if (field.type===ReportFieldEnum.TaxYearEnd)
			{
				return (
					<tr className={`header ${ReportUtil.getRowClassName(field)}`}>
						<td>
							{`${t(field.name)}`}
						</td>
						{!!totalAmounts && <td className={'total'}/>}
						{
							data.map((item: any) =>
							{
								return (
									<td>
										{ReportUtil.hasYearEndDuplicatedDate(item, data)?
											<ReportCell data={item} field={{
												...field,
												type: ReportFieldEnum.DateTime
											}

											} />:
											<ReportCell data={item} field={{
												...field,
												type: ReportFieldEnum.Date
											}

											} />}

									</td>
								);
							})
						}
					</tr>);
			}
			else
			{
				return (
					<tr className={`header ${ReportUtil.getRowClassName(field)}`}>
						<td>
							{`${t(field.name)}`}

						</td>
						{!!totalAmounts && <td/>}
						{
							data.map((item: any) =>
							{
								return (
									<td>
										<ReportCell data={item} field={field} />
									</td>
								);
							})
						}
					</tr>);
			}
	  };


	return (
		<>
			{renderReportHeader(props.reportData, props.field, props.totalAmounts)}
		</>
	);
};

export default ReportHeader;