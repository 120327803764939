import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { engagementService } from 'src/features/engagement/services/engagementService';
import { NotificationService } from 'src/core/services/notificationService';
import AffiliateContext from 'src/common/components/SharedDataContext/AffiliateContext';
import { OtherAdjustmentsFromSpecificTaxpayer } from './OtherAdjustmentsFromSpecificTaxpayer/OtherAdjustmentsFromSpecificTaxpayer';
import { OtherAdjustmentsFromAllTaxpayer } from './OtherAdjustmentsFromAllTaxpayer/OtherAdjustmentsFromAllTaxpayer';
import { IFAHistoryBase } from 'src/features/engagement/models/IFAHistory';
import { ILineItem } from 'src/common/types/interfaces/ILineItem';
import { IAdjustmentFromAllTaxpayer } from 'src/features/engagement/models/IAdjustmentFromAllTaxpayer';
import { cloneDeep } from 'lodash';
import { inputRecordHelper } from 'src/common/utils/inputRecordHelper';
import { OtherAdjustmentsHelper } from './OtherAdjustmentsHelper';
import { Utils } from 'src/common/utils/utils';
import { IRowInformation } from 'src/common/types/interfaces/IRowInformation';
import { AdjustmentFromSpecificTaxpayer, IAdjustmentFromSpecificTaxpayer } from
	'src/features/engagement/models/IAdjustmentFromSpecificTaxpayer';
import { ForeignAffiliate, IForeignAffiliate } from 'src/features/engagement/models/IForeignAffiliate';
import { LineItemCategories } from 'src/common/types/enums/LineItemCategories';
import { UnexpectedErrorModal } from 'src/common/components/UnexpectedErrorModal/UnexpectedErrorModal';
import './OtherAdjustments.scss';
import { AdjustmentFromSpecificTaxpayerFields } from 'src/common/types/enums/AdjustmentFromSpecificTaxpayerFields';
import { IEngagementOverview, EngagementOverviewEntity} from 'src/features/engagement/models/IEngagementOverview';
import { IReloadAdjustmentDataProps } from './IOtherAdjustmentSurplusProps';

export function OtherAdjustments(): JSX.Element
{
	const [faHistory, setFAHistory] = useState<IFAHistoryBase[]>([]);
	const affiliateContext = useContext(AffiliateContext);
	const [isLoading, setIsLoading] = useState(false);
	const [rowInformation, setRowInformation] = useState<IRowInformation[]>([]);
	const [rowInformationForSpecificTP, setRowInformationForSpecificTP] = useState<IRowInformation[]>([]);
	const [lineConfiguration, setLineConfiguration] = useState<ILineItem[]>([]);
	const [specificTPLineConfiguration, setSpecificTPLineConfiguration] = useState<ILineItem[]>([]);
	const [engagementInfo, setEngagementInfo] = useState<IEngagementOverview | undefined>(undefined);
	const [foreignAffiliate, setForeignAffiliate] = useState<IForeignAffiliate | undefined>(undefined);
	const { t: fieldsTranslationService } = useTranslation(
		'input',
		{
			keyPrefix: 'adjustmentsFromSpecificTaxpayersFieldsNames'
		}
	);
	const { t: errorMessages } = useTranslation(
		'input',
		{
			keyPrefix: 'adjustmentsFormErrorMessages'
		}
	);
	const { t } = useTranslation(
		'input',
		{
			keyPrefix: 'addAdjustmentsForm'
		}
	);

	const [errorModalMessage, setErrorModalMessage] = useState('');
	const [adjustmentsReceivedForAll, setAdjustmentReceivedForAll] = useState(inputRecordHelper
		.getStartRowKeysAsDictionary<IAdjustmentFromAllTaxpayer>(
			OtherAdjustmentsHelper.getRowInformation(fieldsTranslationService)));

	const [adjReceivedForSpecificTaxpayer, setAdjReceivedForSpecificTaxpayer] = useState(inputRecordHelper
		.getStartRowKeysAsDictionary<IAdjustmentFromSpecificTaxpayer>(
			OtherAdjustmentsHelper.getRowInformationForSpecificTaxpayer(fieldsTranslationService)));


	const onHeaderNoteSave =(updatedNote: string, data?: IAdjustmentFromSpecificTaxpayer) =>
	{
		if ((!!data?.notes && !!updatedNote) &&
		 (data?.notes === updatedNote))
		{
			return;
		}

		engagementService.updateAdjustment({
			...data!,
			notes: updatedNote,
			engagementId: engagementInfo?.id!,
			affiliateId: foreignAffiliate?.affiliateId!
		}).then(() =>
		{
			if (!!data && !!data.taxpayerId)
			{
				loadAdjustmentsDataForSpecificTaxpayer({
					engagementId: affiliateContext?.engagementDetail.id!,
					affiliateId: affiliateContext?.affiliateDetail.affiliateId!
				});
			}
			else
			{
				loadAdjustmentsData({
					engagementId: affiliateContext?.engagementDetail.id!,
					affiliateId: affiliateContext?.affiliateDetail.affiliateId!
				});
			}
			onSuccessNotification({
				isUpdate: true
			});
		})
			.catch((error) =>
			{
				setErrorModalMessage(errorMessages('updateFailed') || '');
			});
	};

	const onSuccessNotification = (
		{isUpdate}:
		{
			isUpdate?: boolean;
		}
	) =>
	{
		let message = '';

		if (isUpdate)
		{
			message = t('updateSuccess');
		}

		NotificationService.success({
			message
		});
	};

	const loadFAHistory = (
		engagementId: number,
		affiliateId: number
	) =>
	{
		engagementService
			.getFAHistory(
				engagementId,
				affiliateId
			).then((response) =>
			{
				if (
					!!response &&
						!!response.data &&
						!!response.data.result
				)
				{
					setFAHistory([
						...response.data.result
					]);
				}
				else
				{
					setFAHistory([]);
				}
			})
			.catch((error) =>
			{
				triggerErrorNotification(
					t('faHistoryError')
				);
			});
	};

	const loadEngagementInformation = (
		engagementId: number
	) =>
	{
		engagementService
			.getEngagementOverviewById(
				engagementId
			).then((result) =>
			{
				if (
					!!result &&
						!!result.data &&
						!!result.data.result
				)
				{
					setEngagementInfo(
						EngagementOverviewEntity.parseFields(result.data.result)
					);
				}
			})
			.catch((error) =>
			{
				triggerErrorNotification(
					t('engagementInformationError')
				);
			});
	};

	const loadForeignAffiliateInformation = (
		engagementId: number,
		affiliateId: number
	) =>
	{
		engagementService
			.getForeignAffiliate(
				engagementId,
				affiliateId
			).then((result) =>
			{
				if (
					!!result &&
						!!result.data &&
						!!result.data.result
				)
				{
					setForeignAffiliate(
						ForeignAffiliate.parseFields(result.data.result)
					);
				}
			})
			.catch((error) =>
			{
				triggerErrorNotification(
					t('foreignAffiliateInformationError')
				);
			});
	};

	const loadAdjustmentsData = (
		{
			engagementId,
			affiliateId,
			fromDate,
			toDate
		}:
		{
			engagementId: number;
			affiliateId: number;
			fromDate?: Date;
			toDate?: Date;
		}
	) =>
	{
		setIsLoading(true);
		engagementService
			.getAllAdjustmentsReceived({
				engagementId,
				affiliateId,
				fromDate,
				toDate
			}).then((response) =>
			{
				if (
					!!response &&
						!!response.data &&
						!!response.data.result
				)
				{
					const allRecords: IAdjustmentFromAllTaxpayer[] = response.data.result
						.map(AdjustmentFromSpecificTaxpayer.populateFields);

					const allTaxpayerRecords: IAdjustmentFromAllTaxpayer[] = allRecords.filter((x) => x.taxpayerId === null);

					const gridRecordForAllTaxpayers = {
						...cloneDeep(
							inputRecordHelper
								.getRowData(
									allTaxpayerRecords,
									rowInformation
								)
						)
					};

					setAdjustmentReceivedForAll(gridRecordForAllTaxpayers);
					Utils.timeout(1000);
					setIsLoading(false);
				}
			})
			.catch((error) =>
			{
				triggerErrorNotification(
					t('adjustmentInformationError')
				);
				setIsLoading(false);
			});
	};

	const loadAdjustmentsDataForSpecificTaxpayer = (
		{
			engagementId,
			affiliateId,
			fromDate,
			toDate
		}:
		{
			engagementId: number;
			affiliateId: number;
			fromDate?: Date;
			toDate?: Date;
		}
	) =>
	{
		setIsLoading(true);
		engagementService
			.getSpecificAdjustmentsReceived({
				engagementId,
				affiliateId,
				fromDate,
				toDate
			}).then((response) =>
			{
				if (
					!!response &&
						!!response.data &&
						!!response.data.result
				)
				{
					const allRecords: IAdjustmentFromAllTaxpayer[] = response.data.result
						.map(AdjustmentFromSpecificTaxpayer.populateFields);

					const specificTaxpayerRecords: IAdjustmentFromSpecificTaxpayer[] = allRecords.filter((x) => !!x.taxpayerId)
						 .reduce((formatted: IAdjustmentFromSpecificTaxpayer[],item: IAdjustmentFromSpecificTaxpayer) =>
						 {
							 if (!!item['taxpayerId'])
							 {
								 item = {
									 ...item,
									 specificTo: true
								 };
							 }
							 formatted.push(item);
							 return formatted;
						 }, []);

					const gridRecordForSpecificTaxpayers = {
						...cloneDeep(
							inputRecordHelper
								.getRowData(
									specificTaxpayerRecords,
									rowInformationForSpecificTP
								)
						)
					};
					setAdjReceivedForSpecificTaxpayer(gridRecordForSpecificTaxpayers);
					Utils.timeout(1000);
					setIsLoading(false);
				}
			})
			.catch((error) =>
			{
				triggerErrorNotification(
					t('adjustmentInformationError')
				);
				setIsLoading(false);
			});
	};

	const loadRowInformation = async (
		engagementId: number,
		affiliateId: number
	): Promise<void> =>
	{
		try
		{
			const allTaxpayerLines = await inputRecordHelper
				.initializeLineItems(
					LineItemCategories.AdjustmentsFromAllTaxpayer,
					engagementId,
					affiliateId
				);

			const specificTaxpayerLines = await inputRecordHelper
				.initializeLineItems(
					LineItemCategories.AdjustmentsFromSpecificTaxpayer,
					engagementId,
					affiliateId
				);

			const rowInfo = OtherAdjustmentsHelper.getRowInformation(
				fieldsTranslationService,
				allTaxpayerLines
			);

			const specificTPRowInfo = OtherAdjustmentsHelper.getRowInformationForSpecificTaxpayer(
				fieldsTranslationService,
				specificTaxpayerLines
			);
			const updatedRowInfo =	specificTPRowInfo.map((info: any) =>
			{
				if (info.key===AdjustmentFromSpecificTaxpayerFields.specificTo)
				{
					return {
						...info,
						'name': info.name+' '+ engagementInfo?.taxPayerName
					};
				}
				else
				{
					return info;
				}
			});

			setRowInformation(
				rowInfo
			);
			setRowInformationForSpecificTP(
				updatedRowInfo
			);
			setLineConfiguration(allTaxpayerLines);
			setSpecificTPLineConfiguration(specificTaxpayerLines);
		}
		catch (error)
		{
			triggerErrorNotification(
				t('adjustmentInformationError')
			);
		}
	};

	const triggerErrorNotification = (message: string): void =>
	{
		NotificationService.error({
			message
		});
	};

	const onReloadAllAdjustmentData = (val: IReloadAdjustmentDataProps) =>
	{
		setIsLoading(true);
		const properties = {
			engagementId: val.engagementId!,
			affiliateId: val.affiliateId,
			fromDate: val.fromDate,
			toDate: val.toDate
		};
		loadAdjustmentsData(properties);
		if (!!val && val?.isSpecificTo === true)
		{
			loadAdjustmentsDataForSpecificTaxpayer(properties);
		}
	};

	const onReloadSpecificAdjustmentData = (val: IReloadAdjustmentDataProps) =>
	{
		setIsLoading(true);
		const properties = {
			engagementId: val.engagementId!,
			affiliateId: val.affiliateId,
			fromDate: val.fromDate,
			toDate: val.toDate
		};
		loadAdjustmentsDataForSpecificTaxpayer(properties);
		if (!!val && val?.isSpecificTo === false)
		{
			loadAdjustmentsData(properties);
		}
	};

	useEffect(
		() =>
		{
			if (
				!!affiliateContext&&
				!!affiliateContext?.engagementDetail.id&&
				!!affiliateContext?.affiliateDetail.affiliateId

			)
			{
				loadFAHistory(
					affiliateContext?.engagementDetail.id!,
					affiliateContext?.affiliateDetail.affiliateId!

				);
				loadEngagementInformation(
					affiliateContext?.engagementDetail.id!
				);

				loadForeignAffiliateInformation(
					affiliateContext?.engagementDetail.id!,
					affiliateContext?.affiliateDetail.affiliateId!
				);
			}
		},
		[affiliateContext]
	);

	useEffect(
		() =>
		{
			setIsLoading(true);

			if (
				!!lineConfiguration &&
				!!lineConfiguration.length &&
				!!affiliateContext&&
				!!affiliateContext?.engagementDetail.id&&
				!!affiliateContext?.affiliateDetail.affiliateId

			)
			{
				loadAdjustmentsData({
					engagementId: affiliateContext?.engagementDetail.id!,
					affiliateId: affiliateContext?.affiliateDetail.affiliateId!
				});
				loadAdjustmentsDataForSpecificTaxpayer({
					engagementId: affiliateContext?.engagementDetail.id!,
					affiliateId: affiliateContext?.affiliateDetail.affiliateId!
				});
			}
		},
		[
			affiliateContext,
			lineConfiguration
		]
	);

	useEffect(() =>
	{
		if (engagementInfo)
		{
			loadRowInformation(
		affiliateContext?.engagementDetail.id!,
		affiliateContext?.affiliateDetail.affiliateId!

			);
		}
	},[affiliateContext,engagementInfo]);

	return (
		<div className='other-adjustment-container'>
			<OtherAdjustmentsFromAllTaxpayer
				faHistory={faHistory}
				adjustmentRecordForAll={adjustmentsReceivedForAll}
				rowInformation={rowInformation}
				lineConfiguration={specificTPLineConfiguration}
				isLoading={isLoading}
				engagementInfo={engagementInfo}
				foreignAffiliate={foreignAffiliate}
				onHeaderNoteSave={onHeaderNoteSave}
				onReloadAdjustmentData={onReloadAllAdjustmentData}/>
			<OtherAdjustmentsFromSpecificTaxpayer
				faHistory={faHistory}
				isLoading={isLoading}
				adjustmentRecordForSpecific={adjReceivedForSpecificTaxpayer}
				rowInformation={rowInformationForSpecificTP}
				lineConfiguration={specificTPLineConfiguration}
				engagementInfo={engagementInfo}
				foreignAffiliate={foreignAffiliate}
				onHeaderNoteSave={onHeaderNoteSave}
				onReloadAdjustmentData={onReloadSpecificAdjustmentData}
			/>
			<UnexpectedErrorModal
				visible={!!errorModalMessage}
				error={''}
				message={errorModalMessage}
				onClose={() =>
				{
					setErrorModalMessage('');
				}}
			/>
		</div>
	);
};

