import { Button, Select } from '@appkit4/react-components';
import { IFormPickerControlProps } from './IFormPickerControlProps';
import { SelectValue } from '@appkit4/react-components/esm/select/Select';
import { INameId } from 'src/common/types/interfaces/INameId';
import { useEffect, useState } from 'react';
import { Utils } from 'src/common/utils/utils';

import './FormPickerControl.scss';

export function FormPickerControl<T extends INameId>(props: IFormPickerControlProps<T>): JSX.Element
{
	const [selectKey, setSelectKey] = useState(`selector${Utils.generateTempId()}`);

	const onChange = (selectedValue: SelectValue): void =>
	{
		if (!!props.onChange)
		{
			props.onChange(+selectedValue.toString());
		}
	};

	const deSelectOption = (): void =>
	{
		props.onChange();
	};

	const onEdit = (): void =>
	{
		if (!!props.onEditButtonTrigger)
		{
			props.onEditButtonTrigger(props.selectedKey!);
		}
	};

	const onCreateButtonTrigger = () =>
	{
		props.onCreateButtonTrigger();
	};

	const onRenderRow = (
		key: JSX.Element,
		value?: JSX.Element
	): JSX.Element =>
	{
		return <div
			className={'row'}
		>
			<div className={'col name'}>
				{
					key
				}
			</div>
			<div  className={'col value'}>
				{
					value
				}
			</div>
		</div>;
	};

	useEffect(
		() =>
		{
			setSelectKey(`selector${Utils.generateTempId()}`);
		},
		[props.options]
	);

	return <>
		<div className={'form-picker-control'}>
			<div className={'controls'}>
				<div className={'dropdown'}>
					<Select
						required
						searchable
						key={selectKey}
						valueKey={'id'}
						labelKey={'name'}
						data={props.options}
						value={props.selectedKey}
						disabled={props.isDisabled}
						placeholder={props.placeholder}
						dropdownRenderMode={'portal'}
						dropdownAlwaysDown={false}
						onSelect={onChange}
						error={!!props.isError}
					/>
					<div className={'appending-icon'}>
						{
							!!props.selectedKey ?
								<>
									{
										!!props.isButtonDisabled ?
											<i
												className={'Appkit4-icon icon-circle-delete-outline disabled-icion'}
											/> :
											<i
												className={'Appkit4-icon icon-circle-delete-outline'}
												onClick={deSelectOption}
											/>
									}
									<span className={'line-separator'} />
								</>	:
								<div className={'whitespace'}>
									{''}
								</div>
						}
					</div>
				</div>
				<Button
					className={'form-button-control'}
					kind={'secondary'}
					disabled={props.isButtonDisabled}
					onClick={onCreateButtonTrigger}
				>
					{
						props.createButtonText
					}
				</Button>
			</div>
			<div>
				{
					!!props.onRenderErrorMessage &&
					props.onRenderErrorMessage()
				}
			</div>
			<div>
				{
					!!props.onRenderNote &&
					props.onRenderNote()
				}
			</div>
			<div>
				{
					!!props.selectedViewInformation &&
					!!props.selectedViewInformation.length &&
					<div className={'selected-view'}>
						{
							props.selectedViewInformation
								.map((s) =>
								{
									return onRenderRow(
										<>
											{
												s.name
											}
										</>,
										<>
											{
												s.value
											}
										</>
									);
								})
						}
						{
							onRenderRow(
								<Button
									className={'link-button'}
									kind={'text'}
									onClick={onEdit}
								>
									{
										props.editButtonText
									}
								</Button>
							)
						}
					</div>
				}
			</div>
		</div>
	</>;
}