export enum InputEnum {
	Earnings='earnings',
	BookToForeignTaxableIncome='book-to-foreign-taxable-income',
	Reg5907Adjustments='reg5907-adjustments',
	ActiveBusinessIncomeUnderCanadianRules='active-business-income-under-canadian-rules',
	IncomeFromProperty='income-from-property',
	IncomeTaxesPaid='income-taxes-paid',
	DividendsReceived='dividends-received',
	DividendsPaid='dividends-paid',
	CapitalGains='capital-gains',
	OtherAdjustments='other-adjustments',
	SurplusEntitlementPercentageAdjustments='surplus-entitlement-percentage-adjustments',
	AdjustedCostBase='adjusted-cost-base',
	RetainedEarnings='retained-earnings'
}