export enum RelationshipValidationFields {
	NotProcessed= 'Not Processed',
	InProgress= 'In Progress',
	Completed = 'Completed',
	Failed= 'Failed',
	Status= 'status',
	UpdatedOn= 'updatedOn',
	ErrorTypes= 'ErrorTypes',
	SEP= 'SEP',
	EquityPercentage = 'EquityPercentage',
	EffectiveDate= 'EffectiveDate'
}

export enum RelationshipsValidationErrorTypes {
	EquityPercentage= 'EquityPercentage',
	SEP= 'SEP',
	EffectiveDate= 'EffectiveDate'
}