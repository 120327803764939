import { useTranslation } from 'react-i18next';
import './RelationshipResolutionGrid.scss';
import  React, { useEffect, useState } from 'react';
import { IRelationship } from 'src/features/engagement/models/IRelationship';
import { Grid, GridColumn, GridItemChangeEvent, GridRowProps } from '@progress/kendo-react-grid';
import { RelationshipFieldEnum } from 'src/common/types/enums/RelationshipFieldEnum';
import { RelationshipResolutionCellEditor } from '../RelationshipResolutionCellEditor/RelationshipResolutionCellEditor';
import { CellRender } from './components/CellRender';
import { IRelationshipAffiliateId } from 'src/common/types/interfaces/IRelationshipAffiliate';
import { Utils } from 'src/common/utils/utils';

interface IRowError {
	relationshipId: number;
	equityClassId: string;
	equityPercentage: string;
	SEP: string;
	recordId: string;
}

export const RelationshipResolutionGrid = (props: {relationships: IRelationship[],
	circularFAs?: IRelationshipAffiliateId[], onCompleted: (completed: boolean) => void}) =>
{
	const { t } = useTranslation(
		'sharedComponents',
		{
			keyPrefix: 'relationshipResolutionGrid'
		}
	);

	const [circularFAs, setCircularFAs] = useState<IRelationshipAffiliateId[]>([]);

	const [editingErrors,setEditingErrors] = useState<IRowError[]>([]);

	useEffect(() =>
	{
		const hasEmptyField = props.relationships.some((r: any) => Object.values(r).some((value) => value===null||value===''));
		props.onCompleted(!hasEmptyField);
	},[props.relationships]);

	useEffect(() =>
	{
		setCircularFAs(props.circularFAs||[]);
	},[props.circularFAs]);


	const getErrorRecord = (rowErrors: IRowError[], relationship: IRelationship) =>
	{
		if (!!rowErrors && !!rowErrors.length)
		{
			return rowErrors
				.find((e) =>
				{
					return e.recordId === relationship.recordId;
				});
		}
		return undefined;
	};

	const itemChange = (event: GridItemChangeEvent) =>
	{
		if (event.field)
		{
			event.dataItem[event.field] = event.value;
		}

		const filteredErrors = editingErrors.filter((f) => f.recordId !== event.dataItem['recordId']);
		if (event.field === 'SEP' || event.field === 'equityPercentage')
		{
			if (Number(event.dataItem['SEP']) > 0 &&
			(!Utils.isNullUndefined(event.dataItem['equityPercentage']) &&
			event.dataItem['equityPercentage'] !== '' &&
			 Number(event.dataItem['equityPercentage']) === 0 ))
			{
				filteredErrors.push({
					relationshipId: event.dataItem['relationshipId'],
					equityClassId: '',
					equityPercentage: '',
					SEP: t('nilSEP'),
					recordId: event.dataItem['recordId']
				});
			}
		}

		setEditingErrors(filteredErrors);

		const hasEmptyField = props.relationships.some((r: any) => Object.values(r).some((value) => value===null||value===''));
		const hasErrors = (!!filteredErrors && filteredErrors.length > 0);
		props.onCompleted(!(hasEmptyField || hasErrors));
	};

	const rowRender = (
		row: React.ReactElement<HTMLTableRowElement>,
		props: GridRowProps
	) =>
	{
		const circularFA = circularFAs?.find((c: IRelationshipAffiliateId) =>
			c.AffiliateId===props.dataItem.faId&&c.OwnerAffiliateId===props.dataItem.ownerAffiliateId);

		if (circularFA)
		{
			const modifiedRow = React.cloneElement(row, {
				className: ` ${row.props.className} highlightRow`
			});

			return (
				<React.Fragment>
					{modifiedRow}
					<tr className='error-row'>
						<td className='error-cell'>{`${t('circularRelationshipError')}`}</td>
					</tr>
				</React.Fragment>
			);
		}
		else
		{
			return React.cloneElement(row, {
				className: ` ${row.props.className}`
			});
		}
	};


	return <div className='merger-rel-container'>
		{
			!!props.relationships &&
			!!props.relationships.length &&
			<Grid
				className={'merger-relationship-grid'}
				style={{
					maxHeight: '300px'
				}}
				data={props.relationships}
				rowRender={rowRender}
				onItemChange={itemChange}
			>
				<GridColumn
					field={RelationshipFieldEnum.faName}
					title={t(RelationshipFieldEnum.faName) || ''}
					width={200}
					sortable={false}
					cell={(params) =>
					{
						return CellRender({
							...params,
							value: params.dataItem[RelationshipFieldEnum.faName]
						});
					}}
				/>
				<GridColumn
					field={RelationshipFieldEnum.ownerName}
					title={t(RelationshipFieldEnum.ownerName) || ''}
					width={200}
					sortable={false}
					cell={(params) =>
					{
						return CellRender({
							...params,
							value: params.dataItem[RelationshipFieldEnum.ownerName]
						});
					}}
				/>
				<GridColumn
					field={RelationshipFieldEnum.equityClassId}
					title={t(RelationshipFieldEnum.equityClass) || ''}
					width={240}
					sortable={false}
					cell={(params) =>
						RelationshipResolutionCellEditor({
							...params,
							error: getErrorRecord(editingErrors, params.dataItem)
						})
					}
				/>
				<GridColumn
					field={RelationshipFieldEnum.equityPercentage}
					title={t(RelationshipFieldEnum.equityPercentage) || ''}
					width={100}
					sortable={false}
					cell={(params) =>
						RelationshipResolutionCellEditor({
							...params,
							error: getErrorRecord(editingErrors, params.dataItem)
						})
					}
				/>
				<GridColumn
					field={RelationshipFieldEnum.SEP}
					title={t(RelationshipFieldEnum.SEP) || ''}
					width={100}
					sortable={false}
					cell={(params) =>
						RelationshipResolutionCellEditor({
							...params,
							error: getErrorRecord(editingErrors, params.dataItem)
						})
					}
				/>
			</Grid>
		}

	</div>;
};
