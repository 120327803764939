import { IABIRowColumnsProps } from './IABIRowColumnsProps';
import {
	DropdownControl,
	NumericInputWithNotesControl
} from '../../../GridControls';
import { Utils } from 'src/common/utils/utils';
import { useTranslation } from 'react-i18next';
import { ABIComputedUnderCanadianRulesFieldNames } from 'src/common/types/enums/ABIComputedUnderCanadianRulesTypes';

export function ABIRowColumns(props: IABIRowColumnsProps): JSX.Element
{
	const { t } = useTranslation(
		'common',
		{
			keyPrefix: 'abiRowColumns'
		}
	);

	const onDeleteWarning = () =>
	{
		props.onDeleteRow(props.rowData);
	};

	return <>
		{
			!!props.rowData &&
			<tr>
				<td className={'action-column'}>
					{!!props.rowData[0].rowIndex && props.rowData[0].rowIndex > 0 &&
						<span
							className={'Appkit4-icon icon-delete-outline delete-column'}
							onClick={onDeleteWarning}
						/>
					}
				</td>
				<td className={'first-column'}>
					<DropdownControl
						isSearchable={true}
						isDisabled={
							props.isLoading ||
							props.isDisabled
						}
						includeResetOption
						options={props.descriptionOptions}
						placeholder={t('descriptionPlaceholder') || ''}
						value={`${!!props.rowData.length ? props.rowData[0].descriptionId : 0}`}
						isError={
							!!props.validate &&
							!!props.rowData.length &&
							!props.rowData[0].descriptionId &&
							!!props.rowData.some((rd) => !!Utils.isValidNumber(`${rd.amount}`) || !!rd.notes)
						}
						dropdownMatchWidth={false}
						onChange={(value) =>
						{
							props.onDescriptionUpdate(!!value ? +value : 0);
						}}
					/>
				</td>
				{
					props.rowData
						.map((d, index) =>
						{
							return <>
								<td className={`boundary amount-type ${!!props.displayNotes ? 'notes-included' : ''}`}>
									<NumericInputWithNotesControl
										isDisabled={
											props.isLoading ||
											props.isDisabled
										}
										displayNotes={!!props.displayNotes}
										notes={d.notes}
										value={d.amount}
										onValueChange={(value) =>
										{
											props.onValueUpdate(ABIComputedUnderCanadianRulesFieldNames.Amount, index, value);
										}}
										onNotesSave={(updatedNotes) =>
										{
											props.onValueUpdate(ABIComputedUnderCanadianRulesFieldNames.Notes, index, updatedNotes);
										}}
									/>
								</td>
							</>;
						})
				}
			</tr>
		}
	</>;
}