
import { IHorizontalScrollContainerProps } from './IHorizontalScrollContainerProps';

import './HorizontalScrollContainer.scss';

export function HorizontalScrollContainer(props: IHorizontalScrollContainerProps): JSX.Element
{
	return <>
		<div className={'horizontal-scroll-container'}>
			{
				props.children
			}
		</div>
	</>;
}