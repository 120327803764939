import * as signalR from '@microsoft/signalr';
import { Dispatch } from 'redux';
import { addRelationshipValidationNotification, addSurplusCalculationNotification } from 'src/store/reducers/notificationSlice';
import { Constants } from '../types/constants';
import { NotificationTypeEnum } from '../types/enums/NotificationTypeEnum';
import { decodeAndUnscramble } from 'src/features/authentication/encryptUtil';

class signalRService
{
	private static instance: signalRService;
	private connection: signalR.HubConnection;
	private isConnected: boolean = false;
	private dispatch: Dispatch<any> | null = null;
	private readonly maxRetries: number = 5;
	private readonly retryDelay: number = 2000;

	private constructor()
	{
		const options: signalR.IHttpConnectionOptions = {
			accessTokenFactory: () =>
			{
				const token = sessionStorage.getItem('ROCP_idToken');
				const decodedToken = token ? decodeAndUnscramble(token, 10) : '';
				return decodedToken;
			},
			withCredentials: false
		};

		this.connection = new signalR.HubConnectionBuilder()
			.withUrl(`${process.env.REACT_APP_WEBAPI}/notify`, options)
			.withAutomaticReconnect()
			.build();

		this.connection.onclose(() =>
		{
			this.isConnected = false;
			this.connection.off(Constants.EngagementNotificationSubscriptionName);
		});
	}

	public static getInstance(): signalRService
	{
		if (!signalRService.instance)
		{
			signalRService.instance = new signalRService();
		}
		return signalRService.instance;
	}

	private async tryStartConnection(retryCount: number = 0): Promise<void>
	{
		if (!this.isConnected)
		{
			try
			{
				await this.connection.start();
				this.isConnected = true;
			}
			catch (error)
			{
				if (retryCount < this.maxRetries)
				{
					setTimeout(() => this.tryStartConnection(retryCount + 1), this.retryDelay);
				}
				else
				{
					// eslint-disable-next-line no-console
					console.error('Could not establish connection after several attempts', error);
				}
			}
		}
	}

	async startConnection()
	{
		await this.tryStartConnection();
	}

	async stopConnection()
	{
		if (this.isConnected)
		{
			await this.connection.stop();
			this.isConnected = false;
		}
	}

	setDispatch(dispatch: Dispatch<any>)
	{
		this.dispatch = dispatch;
	}

	private handleReceiveMessage = (message: any) =>
	{
		if (this.dispatch)
		{
			switch (message.type)
			{
			case NotificationTypeEnum.SurplusCalculation:
				if (!!message.payload.engagementId)
				{
					this.dispatch(addSurplusCalculationNotification(message.payload));
				}
				break;
			case NotificationTypeEnum.RelationshipValidation:
				if (!!message.payload.engagementId)
				{
					this.dispatch(addRelationshipValidationNotification(message.payload));
				}
				break;
			}
		}
	};

	subscribeToEngagementNotifications (engagementId: number)
	{
		if (this.isConnected)
		{
			this.connection.invoke(Constants.SubscribeToEngagementNotificationsMethodName, engagementId);
			this.connection.on(Constants.EngagementNotificationSubscriptionName, this.handleReceiveMessage);
		}
	}

	unsubscribeFromEngagementNotifications()
	{
		this.connection.off(Constants.EngagementNotificationSubscriptionName);
	}
}

export default signalRService.getInstance();
