import { useState } from 'react';
import { IReg5907RowColumnsProps } from './IReg5907RowColumnsProps';
import {
	DropdownControl
} from 'src/common/components/GridControls';
import { Utils } from 'src/common/utils/utils';
import { useTranslation } from 'react-i18next';
import { Reg5907AdjustmentsFieldNames } from 'src/common/types/enums/Reg5907Adjustments';
import { NumericInputWithNotesAndActionControl } from 'src/common/components/GridControls/NumericInputWithNotesAndActionControl';
import { IReg5907AdjustmentsRow } from 'src/common/types/interfaces/IReg5907Adjustments';
import { IReg5907Adjustment } from 'src/common/types/interfaces/IForeignTaxableIncome';
import './Reg5907RowColumns.scss';
import { Reg5907Modal } from 'src/common/components/GridControls/Reg5902TaggleControl/Reg5907Modal/Reg5907Modal';


export function Reg5907RowColumns(props: IReg5907RowColumnsProps): JSX.Element
{
	const [displayReg5907AdjustmentsModal, setDisplayReg5907AdjustmentsModal] = useState(false);
	const [modalCellData, setModalCellData] = useState<IReg5907AdjustmentsRow>();

	const { t } = useTranslation(
		'common',
		{
			keyPrefix: 'reg5907RowColumns'
		}
	);

	const onDeleteWarning = () =>
	{
		props.onDeleteRow(props.rowData);
	};

	const onEditReg5907Adjustments = () =>
	{
		setDisplayReg5907AdjustmentsModal(true);
	};

	const onCloseModal = () =>
	{
		setDisplayReg5907AdjustmentsModal(false);
	};

	const onRenderActionComponent = (d: IReg5907AdjustmentsRow): JSX.Element =>
	{
		return (
			<span className={`Appkit4-icon icon-pencil-outline ${props.isDisabled ? 'disabled' : ''}`}
				onClick={() =>
				{
					setModalCellData(d);
					onEditReg5907Adjustments();
				}}/>
		);
	};

	const transformRowData = (d: IReg5907AdjustmentsRow): IReg5907Adjustment | undefined =>
	{
		const reg5907 = {
		} as IReg5907Adjustment;
		reg5907.amount = d.amount;
		reg5907.currencyId = d.calculatingCurrencyId;
		reg5907.descriptionId = d.descriptionId;
		reg5907.foreignTaxableIncomeId = d.foreignTaxableIncomeId;
		reg5907.id = d.id;
		reg5907.notes = d?.notes !== undefined ? d?.notes : '';
		reg5907.rowIndex = d.rowIndex;
		reg5907.taxPayerId = d.taxPayerId;

		return reg5907;
	};

	const handleSave = (reg5907Adjustment: IReg5907Adjustment, index: number) =>
	{
		if (modalCellData?.amount !== reg5907Adjustment.amount)
		{
			props.onValueUpdate(Reg5907AdjustmentsFieldNames.Amount, index, !!reg5907Adjustment? reg5907Adjustment.amount : undefined);
		}
		else if (modalCellData?.notes !== reg5907Adjustment?.notes)
		{
			props.onValueUpdate(Reg5907AdjustmentsFieldNames.Notes, index, !!reg5907Adjustment? reg5907Adjustment.notes : '');
		}

		setDisplayReg5907AdjustmentsModal(false);
	};

	return <>
		{
			!!props.rowData &&
			<>
				<tr>
					<td className={'action-column'}>
						{!!props.rowData[0].rowIndex && props.rowData[0].rowIndex > 0 &&
						<span
							className={'Appkit4-icon icon-delete-outline delete-column'}
							onClick={onDeleteWarning}
						/>
						}
					</td>
					<td className={'first-column'}>
						<DropdownControl
							isSearchable={true}
							isDisabled={
								props.isLoading || props.isDisabled ||
								(!!props.rowData && !!props.rowData.length && props.rowData.some((r) => !!r.foreignTaxableIncomeId))
							}
							includeResetOption
							options={props.descriptionOptions}
							placeholder={t('descriptionPlaceholder') || ''}
							value={`${!!props.rowData.length ? props.rowData[0].descriptionId : 0}`}
							isError={
								!!props.validate &&
							!!props.rowData.length &&
							!props.rowData[0].descriptionId &&
							!!props.rowData.some((rd) => !!Utils.isValidNumber(`${rd.amount}`) || !!rd.notes)
							}
							dropdownMatchWidth={false}
							onChange={(value) =>
							{
								props.onDescriptionUpdate(!!value ? +value : 0);
							}}
						/>
					</td>
					{
						props.rowData
							.map((d, index) =>
							{
								return <>
									<td className={`boundary amount-type ${!!props.displayNotes ? 'notes-included' : ''}`}>
										<NumericInputWithNotesAndActionControl
											isDisabled={
												props.isLoading ||
											props.isDisabled
											}
											displayNotes={!!props.displayNotes}
											notes={!!d.notes ? d.notes : '' }
											value={d.amount}
											actionComponent={d.descriptionId ? onRenderActionComponent(d) : <></>}
											onValueChange={(value) =>
											{
												props.onValueUpdate(Reg5907AdjustmentsFieldNames.Amount, index, value);
											}}
											onNotesSave={(updatedNotes) =>
											{
												props.onValueUpdate(Reg5907AdjustmentsFieldNames.Notes, index, updatedNotes);
											}}
										/>
									</td>
									 {d && displayReg5907AdjustmentsModal && (d.id === modalCellData?.id) &&<Reg5907Modal
										visible={displayReg5907AdjustmentsModal}
										otherTaxpayerIds={[]}
										reg5907={transformRowData(d)}
										descriptionOptions={props.descriptionOptions}
										onCancel={onCloseModal}
										showTaxPayerSelect={false}
										onSave={(reg5907) =>
										{
											handleSave(reg5907, index);
										}} />
									}
								</>;
							})
					}
				</tr>

			</>
		}
	</>;
}