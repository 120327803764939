import axios from 'axios';
import { IUserFilter } from '../models/IUserFilter';

export const UserService = {
	getUsers: async () =>
	{
		return axios.get(`${process.env.REACT_APP_WEBAPI}/user`);
	},

	addUser: async (email: string, role: string) =>
	{
		return axios.post(`${process.env.REACT_APP_WEBAPI}/User`, {
			email: email,
			role: role
		});
	},

	searchUsers: async (userFilter: IUserFilter) =>
	{
		return axios.post(`${process.env.REACT_APP_WEBAPI}/User/SearchUsers`, userFilter);
	},

	updateUser: async (email: string, role: string) =>
	{
		return axios.patch(`${process.env.REACT_APP_WEBAPI}/User`, {
			email: email,
			role: role
		});
	},

	deleteUser: async (email: string) =>
	{
		const encodedEmail = encodeURIComponent(email);
		return axios.delete(`${process.env.REACT_APP_WEBAPI}/User/${encodedEmail}`);
	},

	findUserDetailFromEmail: async (email: string) =>
	{
		const encodedEmail = encodeURIComponent(email);
		return axios.get(`${process.env.REACT_APP_WEBAPI}/User/find/${encodedEmail}`);
	}
};
