import { Switch } from '@appkit4/react-components';
import React, { useContext, useEffect, useState } from 'react';

import './Reg5907TaggleControl.scss';
import { Reg5907Modal } from './Reg5907Modal/Reg5907Modal';
import { INameId } from 'src/common/types/interfaces/INameId';
import { IForeignTaxableIncomeRow, IReg5907Adjustment } from 'src/common/types/interfaces/IForeignTaxableIncome';
import AffiliateContext from '../../SharedDataContext/AffiliateContext';
import { Utils } from 'src/common/utils/utils';

const Reg5907TaggleControl = (props: {rowData?: IForeignTaxableIncomeRow,
	descriptionOptions: INameId[]; onChange: (reg5907: IReg5907Adjustment|undefined) => void}) =>
{
	const [hasReg5907, setHasReg5907] = useState<boolean>(false);
	const [otherTaxpayerIds, setOtherTaxpayerIds] = useState<number[]>([]);
	const [showReg5907Modal, setShowReg5907Modal] = useState<boolean>(false);
	const [reg5907, setReg5907] = useState<IReg5907Adjustment|undefined>(undefined);
	const [isInitReg5907, setIsInitReg5907] = useState<boolean>(false);
	const affiliateContext = useContext(AffiliateContext);

	const handleSwitchChange = (val: any) =>
	{
		if (val===true&&reg5907===undefined)
		{
			if (props.rowData)
			{
				const otherTaxpayer = props.rowData.reg5907Adjustments?.find((r: IReg5907Adjustment) =>
					r.taxPayerId!==affiliateContext?.engagementDetail.taxPayerId&&!Utils.isNullUndefined(r.taxPayerId));
				const taxpayerId = otherTaxpayer? affiliateContext?.engagementDetail.taxPayerId : undefined;

				const reg: IReg5907Adjustment = {
					id: 0,
					foreignTaxableIncomeId: props.rowData.id,
					taxPayerId: taxpayerId,
					currencyId: props.rowData?.calculatingCurrencyId,
					rowIndex: props.rowData.rowIndex,
					descriptionId: props.rowData?.descriptionId,
					amount: props.rowData.amount?props.rowData.amount*-1:undefined,
					notes: ''
				};
				setIsInitReg5907(true);
				setReg5907(reg);
				setShowReg5907Modal(true);
			}
		}
		if (val===false&&!!reg5907)
		{
			props.onChange(undefined);
		}
		setHasReg5907(val);
	};

	const handleEdit = () =>
	{
		setShowReg5907Modal(true);
	};

	useEffect(() =>
	{
		if (props.rowData?.reg5907Adjustments&&props.rowData.reg5907Adjustments.length>0)
		{
			const otherReg5907s = props.rowData.reg5907Adjustments?.filter((r: IReg5907Adjustment) =>
				r.taxPayerId!==affiliateContext?.engagementDetail.taxPayerId&&!Utils.isNullUndefined(r.taxPayerId));
			if (otherReg5907s.length>0)
			{
				const taxpayerIds = otherReg5907s.map((r: IReg5907Adjustment) => r.taxPayerId!);
				setOtherTaxpayerIds(taxpayerIds);
			}

			const reg5907 = props.rowData.reg5907Adjustments.find((r: IReg5907Adjustment) =>
				r.taxPayerId===affiliateContext?.engagementDetail.taxPayerId||Utils.isNullUndefined(r.taxPayerId));
			setHasReg5907(!!reg5907);
			if (reg5907)
			{
				setIsInitReg5907(false);
				setReg5907(reg5907);
			}
		}
		else
		{
			setHasReg5907(false);
		}
	},[]);

	const handleCancel = () =>
	{
		if (isInitReg5907)
		{
			setReg5907(undefined);
			setHasReg5907(false);
		}
		setShowReg5907Modal(false);
	};

	const handleSave = (reg5907Adjustment: IReg5907Adjustment) =>
	{
		setReg5907(reg5907Adjustment);
		setIsInitReg5907(false);
		props.onChange(reg5907Adjustment);
		setShowReg5907Modal(false);
	};

	return (
		<div className="reg5907">
			<div>
				{hasReg5907&&<span className="Appkit4-icon icon-pencil-outline" onClick={handleEdit}></span>}
			</div>
			<div>
				<Switch checked={hasReg5907} onChange={handleSwitchChange}
					disabled={
						(!props.rowData?.descriptionId||Utils.isNullUndefined(props.rowData?.amount))&&
						!(props.rowData&&props.rowData.id&&props.rowData.id>0)
					}
				>{`${hasReg5907?'Yes':'No'}`}</Switch>
			</div>
			{reg5907 &&<Reg5907Modal visible={showReg5907Modal} otherTaxpayerIds={otherTaxpayerIds}
				reg5907={reg5907} showTaxPayerSelect={true}
				descriptionOptions={props.descriptionOptions} onCancel={handleCancel} onSave={handleSave} />
			}
		</div>
	);
};

export default Reg5907TaggleControl;