import { GridCellProps } from '@progress/kendo-react-grid';
import * as React from 'react';
import { Input } from '@appkit4/react-components/field';
import { useEffect, useState } from 'react';

import './CurrencyEditorCell.scss';
import { NumberTypeEnum } from 'src/common/types/enums/NumberTypeEnum';
import { RegularPatternEnum } from 'src/common/types/enums/RegularPatternEnum';
import { Utils } from 'src/common/utils/utils';

interface Props {
  fieldErrors: Record<string, any>;
  numberType: NumberTypeEnum;
  handleInputChange: Function;
}

export const CurrencyEditorCell = (props: GridCellProps & Props) =>
{
	const { dataItem, field = '', fieldErrors, handleInputChange } = props;
	const dataValue = dataItem[field];
	const [value, setValue] = useState<string | null>(dataValue);
	const [changed, setChanged] = useState<boolean>(false);
	const [existingError, setExistingError] = useState<boolean>(false);
	const [pattern, setPattern] = useState<string>(RegularPatternEnum.Currency);

	useEffect(() =>
	{
		if (props.numberType)
		{
			switch (props.numberType)
			{
			case NumberTypeEnum.WholeNumber:
				setPattern(RegularPatternEnum.WholeNumber);
				break;
			case NumberTypeEnum.Integer:
				setPattern(RegularPatternEnum.Integer);
				break;
			case NumberTypeEnum.Currency:
				setPattern(RegularPatternEnum.Currency);
				break;
			}
		}
	}, [props.numberType]);

	useEffect(() =>
	{
		setExistingError(!!fieldErrors);
	}, [fieldErrors]);

	const handleChange = (inputValue: string) =>
	{
		const regPattern = new RegExp(pattern);
		if (regPattern.test(inputValue))
		{
			setValue(inputValue);
			handleInputChange(dataItem, field, inputValue?.trim());
			setChanged(true);
		}
	};

	return (
		<td>
			{dataItem?.inEditableMode ? (
				<div className="custom-cell">
					<div
						className={`custom-input ${field}  ${
							!changed && fieldErrors?.clientSideErr[field] ?
								'custom-input-error' :
								''
						}`}
					>
						{' '}
						<Input
							type={'text'}
							className="fn-name-input"
							onChange={handleChange}
							hideTitleOnInput={true}
							value={value}
							inputProps={{
								maxLength: 12
							}}
						></Input>{' '}
					</div>
					{(changed ? '' : fieldErrors?.clientSideErr[field ? field : '']) ? (
						<div className="custom-cell-error">
							{fieldErrors?.clientSideErr[field ? field : '']}
						</div>
					) : (
						<>
							{existingError && <div className="custom-cell-error">&nbsp;</div>}
						</>
					)}
				</div>
			) : (
				<div className="custom-cell-value">
					{Utils.formatCurrency(dataValue, 2)}
				</div>
			)}
		</td>
	);
};
