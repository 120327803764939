import React, { useState } from 'react';

import './ClientInfo.scss';
import { ClientModal } from 'src/core/components/Dashboard/editClientModal/ClientModal';
import { useTranslation } from 'react-i18next';
import { Button } from '@appkit4/react-components';

const ClientInfo = (props: { client: any; setClient: any }) =>
{
	const [showEditClientModal, setShowEditClientModal] = useState<boolean>(
		false
	);
	const { t } = useTranslation('homeDashboard');

	const closeClientModal = (
		clientInfo: any,
		shouldUpdateClient: boolean = true
	) =>
	{
		if (!shouldUpdateClient)
		{
			setShowEditClientModal(false);
			return;
		}
		props.setClient(clientInfo);
		setShowEditClientModal(false);
	};

	return (
		<div className="client-info">
			<div className="row">
				<div className="col left-col">
					<span className="color_69">{t('client-card.clientName')}</span>
				</div>
				<div className="col">
					<span className="color_47">{props?.client?.clientName}</span>
				</div>
			</div>
			<div className="row">
				<div className="col left-col">
					<span className="color_69">{t('client-card.iPowerCode')}</span>
				</div>
				<div className="col">
					<span className="color_47">{props?.client?.iPowerCode}</span>
				</div>
			</div>
			<div className="row">
				<div className="col padding_t4">
					{
						<Button className='link-button' kind='text' onClick={() => setShowEditClientModal(true)}>
							{t('client-card.editClient')}
						</Button>
					}
				</div>
				<div className="col"></div>
			</div>
			<ClientModal
				visible={showEditClientModal}
				onClose={closeClientModal}
				clientModalContext={props.client}
				title={t('client-popup.editClient')}
			/>
		</div>
	);
};

export default ClientInfo;
