import { useContext, useEffect, useRef, useState } from 'react';
import { PropertyIncomeGrid } from 'src/common/components/PropertyIncomeGrid/PropertyIncomeGrid';
import { PropertyIncomeHeaderTable } from 'src/common/components/PropertyIncomeGrid/components/PropertyIncomeHeaderTable';
import { PropertyIncomeAggregateRows } from 'src/common/components/PropertyIncomeGrid/components/PropertyIncomeAggregateRows';
import { engagementService } from 'src/features/engagement/services/engagementService';
import {
	IPropertyIncomeRow,
	IPropertyIncomeSubmission,
	IPropertyIncomeYear,
	IPropertyIncomeYearSubmission,
	PropertyIncomeRow,
	PropertyIncomeSubmissionToken
} from 'src/common/types/interfaces/IPropertyIncome';
import { GridSeperator } from 'src/common/components/GridSeperator/GridSeperator';
import { INameId } from 'src/common/types/interfaces/INameId';
import { ILookupRecord } from 'src/common/types/interfaces/ILookupRecord';
import { LookupCategories } from 'src/common/types/enums/LookupCategories';
import { Utils } from 'src/common/utils/utils';
import { PropertyIncomeFieldNames } from 'src/common/types/enums/PropertyIncomeTypes';
import { Button, Notification } from '@appkit4/react-components';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import { SaveProgressBar } from 'src/common/components/SaveProgressBar/SaveProgressBar';
import {
	RecordsByGridType,
	ValuesByAffiliateId,
	ValuesByDescriptionYear,
	ValuesByYear,
	gridIds,
	initialPropertyRecordData,
	initialPropertyRowData
} from './PropertyIncomeConstants';
import { NotificationService } from 'src/core/services/notificationService';
import { PropertyIncomeHelpers } from './PropertyIncomeHelpers';
import { useTranslation } from 'react-i18next';

import './PropertyIncome.scss';
import AffiliateContext from 'src/common/components/SharedDataContext/AffiliateContext';

export function PropertyIncome(props: {handleChange?: (change: boolean) => void}): JSX.Element
{
	const [isLoading, setIsLoading] = useState(true);
	const [isSaving, setIsSaving] = useState(false);
	const [isValidating, setIsValidating] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isNoFAHistory, setIsNoFAHistory] = useState(false);

	const [displayNotes, setDisplayNotes] = useState(false);

	const [hasChange, setHasChange] = useState(false);

	const affiliateContext = useContext(AffiliateContext);

	const [propertyIncomeData, setPropertyIncomeData] = useState<IPropertyIncomeYear[]>([]);

	const [initialData, setInitialData] = useState<{ [gridId: string]: IPropertyIncomeRow[][] }>(initialPropertyRowData);
	const [incomeData, setIncomeData] = useState<IPropertyIncomeRow[][]>([]);
	const [expenseData, setExpenseData] = useState<IPropertyIncomeRow[][]>([]);
	const [adjustmentData, setAdjustmentData] = useState<IPropertyIncomeRow[][]>([]);

	const [typeOptions, setTypeOptions] = useState<INameId[]>([]);
	const [incomeDescriptionOptions, setIncomeDescriptionOptions] = useState<INameId[]>([]);
	const [expenseDescriptionOptions, setExpenseDescriptionOptions] = useState<INameId[]>([]);
	const [adjustmentDescriptionOptions, setAdjustmentDescriptionOptions] = useState<INameId[]>([]);

	const { t } = useTranslation(
		'input',
		{
			keyPrefix: 'propertyIncome'
		}
	);

	const tableRefs = Array.from(
		{
			length: Object.keys(gridIds).length
		},
		// eslint-disable-next-line react-hooks/rules-of-hooks
		() => useRef<HTMLDivElement>(null)
	);

	const groupByKey = (faYear: string, affiliateId: number): string =>
	{
		return `${faYear}::${affiliateId}`;
	};

	const getRecordsByRows = (
		allRecords: IPropertyIncomeYear[],
		valuesByDescriptionYear: ValuesByDescriptionYear
	): IPropertyIncomeRow[][] =>
	{
		const allRows: IPropertyIncomeRow[][] = [];

		let rowContinuationIndex = 0;

		if (!!valuesByDescriptionYear)
		{
			const descriptionKeys = Object.keys(valuesByDescriptionYear);

			// loop for every year to determine which array for a given year is longest and fill values for each
			for (const descriptionKey of descriptionKeys)
			{
				const longestArray = Utils.getKeyAndLengthOfLongestArrayInDictionary(
					valuesByDescriptionYear[+descriptionKey]
				);

				// get array of longest and seperate from remainder of records.
				// this will be the basis on which the remainder of records are built
				// the longest array already has the maximum number of records, so need to fill remainder of records
				const longestYearRecords = [
					...valuesByDescriptionYear[+descriptionKey][longestArray.key]
				];

				const yearRecordsExcludingLongest: ValuesByYear = {
				};

				const yearKeys = Object.keys(valuesByDescriptionYear[+descriptionKey]);

				for (const yearKey of yearKeys)
				{
					if (yearKey !== longestArray.key)
					{
						yearRecordsExcludingLongest[yearKey] = valuesByDescriptionYear[+descriptionKey][yearKey];
					}
				}

				// fill remainder of records for each year that does not have the full amount of records
				for (const yearKey of Object.keys(yearRecordsExcludingLongest))
				{
					// get affiliate year field for entity by year (assume it will always be found)
					const yearEntity = allRecords
						.find((r) => groupByKey(r.faYear, r.affiliateTaxYearEndId) === yearKey)!;

					if (yearRecordsExcludingLongest[yearKey].length < longestYearRecords.length)
					{
						const remainingCount = longestYearRecords.length - yearRecordsExcludingLongest[yearKey].length;

						for (let i = 0; i < remainingCount; i++)
						{
							yearRecordsExcludingLongest[yearKey]
								.push(
									new PropertyIncomeRow({
										descriptionId: +descriptionKey,
										faYear: yearEntity.faYear,
										affiliateTaxYearEndId: yearEntity.affiliateTaxYearEndId
									})
								);
						}
					}
				}

				// concat the longest and remaining back together for processing into row records
				const allRecordsByYear = {
					[longestArray.key]: longestYearRecords,
					...yearRecordsExcludingLongest
				};

				// add the filler records (while tracking position of rows for each new iteration of description)
				for (let i = 0; i < longestArray.length; i++)
				{
					for (const year of yearKeys)
					{
						if ((allRows.length - 1) < (i + rowContinuationIndex))
						{
							allRows.push([]);
						}

						allRows[(i + rowContinuationIndex)].push(allRecordsByYear[year][i]);
					}
				}

				rowContinuationIndex += longestArray.length;
			}
		}

		return allRows;
	};

	const loadPropertyIncome = async (
		engagementId: number,
		affiliateId: number
	): Promise<void> =>
	{
		try
		{
			const result = await engagementService
				.getPropertyIncome(engagementId, affiliateId);

			const records: IPropertyIncomeYear[] = !!result.data && !!result.data.result ?
				result.data.result.sort(PropertyIncomeHelpers.sortFAYear) :
				[];

			if (!!records && !!records.length)
			{
				const incomeDescriptions: number[] = [];
				const expenseDescriptions: number[] = [];
				const adjustmentDescriptions: number[] = [];

				// get all the unique descriptions across years for each income/expense/adjustment
				const filterDescriptions = (
					records: IPropertyIncomeRow[],
					uniqueDescriptions: number[]
				): number[] =>
				{
					if (
						!!records &&
						!!records.length
					)
					{
						return records
							.filter((d) => !uniqueDescriptions.some((ud) => ud === d.descriptionId))
							.map((d) => d.descriptionId);
					}

					return [];
				};

				for (const r of records)
				{
					if (!!r.propertyIncome)
					{
						incomeDescriptions.push(...filterDescriptions(r.propertyIncome.income, incomeDescriptions));
						expenseDescriptions.push(...filterDescriptions(r.propertyIncome.expenses, expenseDescriptions));
						adjustmentDescriptions.push(...filterDescriptions(r.propertyIncome.adjustments, adjustmentDescriptions));
					}
				}

				const getValuesByDescriptionYear = (
					valuesByDescriptionYearStart: ValuesByDescriptionYear,
					rows: IPropertyIncomeRow[],
					year: string,
					affiliateTaxYearEndId: number
				): ValuesByDescriptionYear =>
				{
					const valuesByDescriptionYear: ValuesByDescriptionYear = {
						...valuesByDescriptionYearStart
					};

					if (!!rows && !!rows.length)
					{
						for (const row of rows)
						{
							const allYearsBase: { [year: string]: [] } = {
							};

							records.forEach((r) =>
							{
								allYearsBase[groupByKey(r.faYear, r.affiliateTaxYearEndId)] = [];
							});

							if (!valuesByDescriptionYear[row.descriptionId])
							{
								valuesByDescriptionYear[row.descriptionId] = {
								};
							}

							if (!valuesByDescriptionYear[row.descriptionId][groupByKey(year, affiliateTaxYearEndId)])
							{
								valuesByDescriptionYear[row.descriptionId] = {
									...allYearsBase
								};
							}

							valuesByDescriptionYear[row.descriptionId][groupByKey(year, affiliateTaxYearEndId)]
								.push({
									...row,
									notes: !!row.notes ? row.notes : undefined,
									faYear: year,
									affiliateTaxYearEndId: affiliateTaxYearEndId
								});
						}
					}

					return valuesByDescriptionYear;
				};

				// build income/expense/adjustments records
				let incomeValuesByDescriptionYear: ValuesByDescriptionYear = {
				};
				let expenseValuesByDescriptionYear: ValuesByDescriptionYear = {
				};
				let adjustmentValuesByDescriptionYear: ValuesByDescriptionYear = {
				};

				// for every description there will be an entity (if none exists then empty)
				for (const record of records)
				{
					if (!!record.propertyIncome)
					{
						incomeValuesByDescriptionYear = {
							...getValuesByDescriptionYear(
								incomeValuesByDescriptionYear,
								record.propertyIncome.income,
								record.faYear,
								record.affiliateTaxYearEndId
							)
						};
						expenseValuesByDescriptionYear = {
							...getValuesByDescriptionYear(
								expenseValuesByDescriptionYear,
								record.propertyIncome.expenses,
								record.faYear,
								record.affiliateTaxYearEndId
							)
						};
						adjustmentValuesByDescriptionYear = {
							...getValuesByDescriptionYear(
								adjustmentValuesByDescriptionYear,
								record.propertyIncome.adjustments,
								record.faYear,
								record.affiliateTaxYearEndId
							)
						};
					}
				}

				const income = getRecordsByRows(records, incomeValuesByDescriptionYear);
				const expense = getRecordsByRows(records, expenseValuesByDescriptionYear);
				const adjustment = getRecordsByRows(records, adjustmentValuesByDescriptionYear);

				// set all data in state, including starting values
				setInitialData({
					[gridIds.income]: cloneDeep(income),
					[gridIds.expense]: cloneDeep(expense),
					[gridIds.adjustment]: cloneDeep(adjustment)
				});

				setIncomeData([...income]);
				setExpenseData([...expense]);
				setAdjustmentData([...adjustment]);

				setPropertyIncomeData([...records]);
			}
			else
			{
				setIsNoFAHistory(true);
			}
		}
		catch (error)
		{
			triggerErrorNotification(
				t('loadingIncomeError')
			);
		}
		finally
		{
			setIsLoading(false);
		}
	};

	const resetAndLoadPropertyIncome = async (): Promise<void> =>
	{
		setIsLoading(true);
		setIsSaving(false);
		setIsSubmitted(false);
		setIsValidating(false);
		setHasChange(false);
		!!props.handleChange && props.handleChange(false);

		setInitialData({
			...initialPropertyRowData
		});

		setPropertyIncomeData([]);
		setIncomeData([]);
		setExpenseData([]);
		setAdjustmentData([]);


		await loadPropertyIncome(
			affiliateContext?.engagementDetail.id!,
					affiliateContext?.affiliateDetail.affiliateId!
		);
	};

	const filterLookups = (
		allLookups: ILookupRecord[],
		category: LookupCategories
	): INameId[] =>
	{
		return allLookups
			.filter((l) => l.category === category)
			.map((l) => ({
				id: l.id,
				name: l.fieldName
			}))
			.sort();
	};

	const loadLookups = async (): Promise<void> =>
	{
		try
		{
			const response = await engagementService
				.getPropertyIncomeLookups();

			const allLookups: ILookupRecord[] = !!response.data &&
			!!response.data.result &&
			!!response.data.result.propertyIncomeMasterList &&
			response.data.result.propertyIncomeMasterList.length ?
				response.data.result.propertyIncomeMasterList :
				[];

			setTypeOptions([...filterLookups(allLookups, LookupCategories.Types)]);
			setIncomeDescriptionOptions([...filterLookups(allLookups, LookupCategories.Income)]);
			setExpenseDescriptionOptions([...filterLookups(allLookups, LookupCategories.Expenses)]);
			setAdjustmentDescriptionOptions([...filterLookups(allLookups, LookupCategories.Adjustments)]);
		}
		catch (error)
		{
			triggerErrorNotification(
				t('loadingLookupsError')
			);
		}
	};

	const updateDescriptionFields = (
		startingData: IPropertyIncomeRow[][],
		rowIndex: number,
		value: number
	): IPropertyIncomeRow[][]  =>
	{
		const updatedRows = cloneDeep(startingData);
		const rowData = cloneDeep(updatedRows[rowIndex]);

		rowData.forEach((d) =>
		{
			d.descriptionId = value;
		});

		updatedRows[rowIndex] = [...rowData];

		return updatedRows;
	};

	const updateDescription = (
		id: string,
		rowIndex: number,
		value: number
	): void =>
	{
		if (id === gridIds.income)
		{
			setIncomeData([...updateDescriptionFields(incomeData, rowIndex, value)]);
		}
		else if (id === gridIds.expense)
		{
			setExpenseData([...updateDescriptionFields(expenseData, rowIndex, value)]);
		}
		else if (id === gridIds.adjustment)
		{
			setAdjustmentData([...updateDescriptionFields(adjustmentData, rowIndex, value)]);
		}
	};

	const updateValueFields = (
		startingData: IPropertyIncomeRow[][],
		fieldName: PropertyIncomeFieldNames,
		rowIndex: number,
		index: number,
		value: string
	): IPropertyIncomeRow[][] =>
	{
		const updatedRows = cloneDeep(startingData);
		const yearContent = cloneDeep(updatedRows[rowIndex][index]);

		if (
			fieldName === PropertyIncomeFieldNames.TypeId ||
			fieldName === PropertyIncomeFieldNames.Amount
		)
		{
			yearContent[fieldName] = Utils.isValidNumber(value) ?
				+value :
				undefined;
		}
		else if (fieldName === PropertyIncomeFieldNames.Notes)
		{
			yearContent[fieldName] = !!value ? value : undefined;
		}

		updatedRows[rowIndex][index] = yearContent;

		return updatedRows;
	};

	const updateValue = (
		id: string,
		fieldName: PropertyIncomeFieldNames,
		rowIndex: number,
		index: number,
		value: string
	): void =>
	{
		if (id === gridIds.income)
		{
			setIncomeData([
				...updateValueFields(
					incomeData,
					fieldName,
					rowIndex,
					index,
					value
				)
			]);
		}
		else if (id === gridIds.expense)
		{
			setExpenseData([
				...updateValueFields(
					expenseData,
					fieldName,
					rowIndex,
					index,
					value
				)
			]);
		}
		else if (id === gridIds.adjustment)
		{
			setAdjustmentData([
				...updateValueFields(
					adjustmentData,
					fieldName,
					rowIndex,
					index,
					value
				)
			]);
		}
	};

	const debouncedUpdateValue = debounce(updateValue, 400);

	const onAddRow = (gridId: string): void =>
	{
		const addNewRow = (startingData: IPropertyIncomeRow[][]): IPropertyIncomeRow[][] =>
		{
			const updatedRows = cloneDeep(startingData);

			const newRow: PropertyIncomeRow[] = [];
			for (const data of propertyIncomeData)
			{
				newRow.push(
					new PropertyIncomeRow({
						faYear: data.faYear,
						affiliateTaxYearEndId: data.affiliateTaxYearEndId
					})
				);
			}

			updatedRows.push(newRow);
			return updatedRows;
		};

		if (gridId === gridIds.income)
		{
			setIncomeData([...addNewRow(incomeData)]);
		}
		else if (gridId === gridIds.expense)
		{
			setExpenseData([...addNewRow(expenseData)]);
		}
		else if (gridId === gridIds.adjustment)
		{
			setAdjustmentData([...addNewRow(adjustmentData)]);
		}
	};

	const checkChange = (
		initialData: IPropertyIncomeRow[][],
		updatedData: IPropertyIncomeRow[][]
	): boolean =>
	{
		const newRows: IPropertyIncomeRow[][] = [];
		const existingRows: IPropertyIncomeRow[][] = [];

		for (const row of updatedData)
		{
			if (row.every((record) => record.id! < 0))
			{
				newRows.push([...row]);
			}
			else
			{
				existingRows.push([...row]);
			}
		}

		const newRecordChanges = (rows: IPropertyIncomeRow[][]) =>
		{
			return rows
				.some((row) =>
				{
					return row.some((record) =>
					{
						return !!Utils.isValidNumber(`${record.amount}`) ||
							!!record.typeId ||
							!!record.notes;
					});
				});
		};

		// check if different number of rows and if new rows have any valid changes for submission
		if (
			initialData.length !== updatedData.length &&
			!!newRecordChanges(newRows)
		)
		{
			return true;
		}

		// check if entities are same
		if (!isEqual(initialData, existingRows))
		{
			return true;
		}

		return false;
	};

	const hasChanges = (): boolean =>
	{
		const changed = checkChange(initialData[gridIds.income], incomeData) ||
			checkChange(initialData[gridIds.expense], expenseData) ||
			checkChange(initialData[gridIds.adjustment], adjustmentData);

		setHasChange(!!changed);
		!!props.handleChange && props.handleChange(!!changed);
		return changed;
	};

	const isValidRows = (
		initialDataRows: IPropertyIncomeRow[][],
		updatedData: IPropertyIncomeRow[][]
	): [boolean, ValuesByYear] =>
	{
		// flatten initial data rows for quick comparison
		const flattenedInitialRowsById: { [id: number]: IPropertyIncomeRow } = {
		};

		initialDataRows
			.flat()
			.concat()
			.forEach((record) =>
			{
				flattenedInitialRowsById[record.id!] = record;
			});


		const recordsByYear: ValuesByAffiliateId = {
		};

		propertyIncomeData
			.forEach((d) =>
			{
				recordsByYear[d.affiliateTaxYearEndId] = [];
			});


		// iterate each row and each record to check validity
		// positionally every entity is the same in the rows/records,
		// so can use indexing for matching of records,
		// and if index cannot be found then can be considered a new row (will use id to determine)
		let isValid = true;

		for (let i = 0; i < updatedData.length && isValid; i++)
		{
			const updatedRow = updatedData[i];

			for (let j = 0; j < updatedRow.length; j++)
			{
				const record = updatedRow[j];

				if (
					(
						!record.descriptionId &&
						(
							!!Utils.isValidNumber(`${record.amount}`) ||
							!!record.typeId ||
							!!record.notes
						)
					) ||
					(
						!!Utils.isValidNumber(`${record.amount}`) &&
						!record.typeId
					) ||
					(
						!Utils.isValidNumber(`${record.amount}`) &&
						!!record.typeId
					) ||
					(
						!!record.notes &&
						(
							!Utils.isValidNumber(`${record.amount}`) ||
							!record.typeId
						)
					)
				)
				{
					isValid = false;
					break;
				}

				// check if record exists in initial list (if not then consider record to be new)
				// check that the record in question is different than original state and add to list for update
				// deleted records do not need to be considered as delete will update the initial comparison list
				if (
					(
						record.id! <= 0 &&
						!!Utils.isValidNumber(`${record.amount}`) &&
						!!record.typeId
					) ||
					(
						record.id! > 0 &&
						!isEqual(record, flattenedInitialRowsById[record.id!])
					)
				)
				{
					recordsByYear[record.affiliateTaxYearEndId].push(record);
				}
			}
		}

		return [isValid, recordsByYear];
	};

	const isValid = (): [boolean, RecordsByGridType] =>
	{
		const dataToUpdate = {
			...initialPropertyRecordData
		};

		const [isIncomeValid, incomeRecordsToUpdate] = isValidRows(initialData[gridIds.income], incomeData);
		dataToUpdate[gridIds.income] = incomeRecordsToUpdate;

		if (!isIncomeValid)
		{
			return [isIncomeValid, dataToUpdate];
		}

		const [isExpenseValid, expenseRecordsToUpdate] = isValidRows(initialData[gridIds.expense], expenseData);
		dataToUpdate[gridIds.expense] = expenseRecordsToUpdate;

		if (!isExpenseValid)
		{
			return [isExpenseValid, dataToUpdate];
		}

		const [isAdjustmentValid, adjustmentRecordsToUpdate] = isValidRows(initialData[gridIds.adjustment], adjustmentData);
		dataToUpdate[gridIds.adjustment] = adjustmentRecordsToUpdate;

		if (!isAdjustmentValid)
		{
			return [isAdjustmentValid, dataToUpdate];
		}

		return [true, dataToUpdate];
	};

	const transformSubmissionEntities = (
		records: IPropertyIncomeYearSubmission[],
		recordsByAffiliate: ValuesByAffiliateId,
		submissionToken: PropertyIncomeSubmissionToken
	): IPropertyIncomeYearSubmission[] =>
	{
		for (const affiliateIdKey of Object.keys(recordsByAffiliate))
		{
			if (
				!!recordsByAffiliate[+affiliateIdKey] &&
				!!recordsByAffiliate[+affiliateIdKey].length
			)
			{
				let index = records.findIndex((r) => r.affiliateTaxYearEndId === +affiliateIdKey);
				if (index < 0)
				{
					const initial: IPropertyIncomeYearSubmission = {
						affiliateTaxYearEndId: +affiliateIdKey,
						incomes: [],
						expenses: [],
						adjustments: []
					};

					// set the index based on the length of the newly updated array
					index = records.push({
						...initial
					}) - 1;
				}

				// add to list based on existing index
				records[index] = {
					...records[index],
					[submissionToken]: recordsByAffiliate[+affiliateIdKey]
						.map((r) =>
						{
							return {
								descriptionId: r.descriptionId,
								id: !!r.id && r.id >= 0 ?
									r.id :
									undefined,
								amount: r.amount,
								typeId: r.typeId,
								notes: r.notes
							};
						})
				};
			}
		}

		return records;
	};

	const stopSaveProgressBar = async (): Promise<void> =>
	{
		await Utils.timeout(500);
		setIsSaving(false);
	};

	const onSave = async (): Promise<void> =>
	{
		try
		{
			setIsValidating(true);
			setIsSubmitted(true);

			if (!!hasChanges())
			{
				const [isValidRecords, recordsByType] = isValid();

				if (!!isValidRecords)
				{
					setIsSaving(true);

					let updatedRecords: IPropertyIncomeYearSubmission[] = [];

					updatedRecords = transformSubmissionEntities(
						updatedRecords,
						recordsByType[gridIds.income],
						PropertyIncomeSubmissionToken.Income
					);

					updatedRecords = transformSubmissionEntities(
						updatedRecords,
						recordsByType[gridIds.expense],
						PropertyIncomeSubmissionToken.Expenses
					);

					updatedRecords = transformSubmissionEntities(
						updatedRecords,
						recordsByType[gridIds.adjustment],
						PropertyIncomeSubmissionToken.Adjustments
					);

					const submissionData: IPropertyIncomeSubmission = {
						engagementId: affiliateContext?.engagementDetail.id!,
						affiliateId: affiliateContext?.affiliateDetail.affiliateId!,

						propertyIncomesList: [...updatedRecords]
					};

					await engagementService.updatePropertyIncome(submissionData);

					triggerSuccessNotification();

					await stopSaveProgressBar();

					resetAndLoadPropertyIncome();
				}
				else
				{
					triggerWarningNotification();
				}
			}
		}
		catch (error)
		{
			triggerErrorNotification(
				t('saveError')
			);

			await stopSaveProgressBar();
		}
		finally
		{
			setIsValidating(false);
		}
	};

	const onRenderSaveButton = (): JSX.Element =>
	{
		return <Button
			kind={'primary'}
			disabled={
				isLoading ||
				isSaving ||
				isValidating ||
				!hasChange
			}
			onClick={onSave}
		>
			{
				t('saveButtonText')
			}
		</Button>;
	};

	const triggerWarningNotification = async (): Promise<void> =>
	{
		const ele = (
			<Notification
			  message={t('validationWarning')}
			  status={'warning'}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};

	const triggerErrorNotification = async (message: string): Promise<void> =>
	{
		const ele = (
			<Notification
			  message={message}
			  status={'error'}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};

	const triggerSuccessNotification = async (): Promise<void> =>
	{
		const ele = (
			<Notification
			  message={t('successMessage')}
			  status={'success'}
			/>
		);

		await NotificationService.clearExisting();

		NotificationService
			.notify({
				component: ele
			});
	};

	const triggerValueUpdate = (
		gridId: string,
		fieldName: PropertyIncomeFieldNames,
		rowIndex: number,
		index: number,
		value: string
	) =>
	{
		// only delay if update field is a textual/numeric value
		if (fieldName === PropertyIncomeFieldNames.TypeId)
		{
			updateValue(gridId, fieldName, rowIndex, index, value);
		}
		else
		{
			debouncedUpdateValue(gridId, fieldName, rowIndex, index, value);
		}
	};


	useEffect(
		() =>
		{
			setIsLoading(true);

			if (
				!!affiliateContext&&
				!!affiliateContext?.engagementDetail.id&&
				!!affiliateContext?.affiliateDetail.affiliateId

			)
			{
				loadLookups();

				loadPropertyIncome(
					affiliateContext?.engagementDetail.id!,
					affiliateContext?.affiliateDetail.affiliateId!
				);
			}
		},
		[affiliateContext]
	);

	useEffect(
		() =>
		{
			const handleScroll = (index: number) => (event: any) =>
			{
				tableRefs
					.forEach((ref, i) =>
					{
						if (i !== index && ref.current)
						{
							ref.current.scrollLeft = event.target.scrollLeft;
						}
					});
			};

			tableRefs
				.forEach((ref, index) =>
				{
					if (!!ref.current)
					{
						ref.current.addEventListener('scroll', handleScroll(index));
					}
				});


			return () =>
			{
				tableRefs
					.forEach((ref, index) =>
					{
						if (!!ref.current)
						{
							ref.current.removeEventListener('scroll', handleScroll(index));
						}
					});
			};
		},
		[]
	);

	useEffect(
		() =>
		{
			hasChanges();
		},
		[
			incomeData,
			expenseData,
			adjustmentData
		]
	);

	return <>
		{
			!isNoFAHistory &&
			<div
				className={'property-income'}
				style={{
					width: isLoading ? '100%' : 'inherit'
				}}
			>
				<div className={'action-buttons'}>
					{
						onRenderSaveButton()
					}
				</div>
				<div>
					<SaveProgressBar
						display={isSaving}
						width={
							!!incomeData &&
							!!incomeData.length ?
								`${incomeData[0].length * (300 + (!!displayNotes ? 400 : 0)) + 305}px` :
								undefined
						}
						message={t('saveInProgressMessage') || ''}
					/>
				</div>
				<div className={'grid-container'}>
					<div>
						<PropertyIncomeHeaderTable
							id={gridIds.header}
							customRef={tableRefs[0]}
							data={propertyIncomeData}
							isLoading={isLoading}
							onToggleNotes={(isNotesDisplayed) =>
							{
								setDisplayNotes(isNotesDisplayed);
							}}
						/>
					</div>
					<div>
						<PropertyIncomeGrid
							id={gridIds.income}
							isLoading={isLoading}
							isDisabled={isValidating || isSaving}
							validate={isSubmitted}
							displayNotes={displayNotes}
							customRef={tableRefs[1]}
							rowData={incomeData}
							propertyIncomeData={propertyIncomeData}
							typeOptions={typeOptions}
							descriptionOptions={incomeDescriptionOptions}
							title={t('incomeGridTitle')}
							addRowButtonText={t('incomeGridAddButtonText')}
							onRowAdd={() =>
							{
								onAddRow(gridIds.income);
							}}
							onDescriptionUpdate={(rowIndex, value) =>
							{
								updateDescription(gridIds.income, rowIndex, value);
							}}
							onValueUpdate={(fieldName, rowIndex, index, value) =>
							{
								triggerValueUpdate(gridIds.income, fieldName, rowIndex, index, value);
							}}
						/>
					</div>
					{
						!isLoading &&
						<GridSeperator />
					}
					<div>
						<PropertyIncomeGrid
							id={gridIds.expense}
							isLoading={isLoading}
							isDisabled={isValidating || isSaving}
							validate={isSubmitted}
							displayNotes={displayNotes}
							customRef={tableRefs[2]}
							rowData={expenseData}
							propertyIncomeData={propertyIncomeData}
							typeOptions={typeOptions}
							descriptionOptions={expenseDescriptionOptions}
							title={t('expenseGridTitle')}
							addRowButtonText={t('expenseGridAddButtonText')}
							onRowAdd={() =>
							{
								onAddRow(gridIds.expense);
							}}
							onDescriptionUpdate={(rowIndex, value) =>
							{
								updateDescription(gridIds.expense, rowIndex, value);
							}}
							onValueUpdate={(fieldName, rowIndex, index, value) =>
							{
								triggerValueUpdate(gridIds.expense, fieldName, rowIndex, index, value);
							}}
						/>
					</div>
					{
						!isLoading &&
						<GridSeperator />
					}
					<div>
						<PropertyIncomeGrid
							id={gridIds.adjustment}
							isLoading={isLoading}
							isDisabled={isValidating || isSaving}
							validate={isSubmitted}
							displayNotes={displayNotes}
							customRef={tableRefs[3]}
							rowData={adjustmentData}
							propertyIncomeData={propertyIncomeData}
							typeOptions={typeOptions}
							descriptionOptions={adjustmentDescriptionOptions}
							title={t('adjustmentsGridTitle')}
							addRowButtonText={t('adjustmentGridAddButtonText')}
							onRowAdd={() =>
							{
								onAddRow(gridIds.adjustment);
							}}
							onDescriptionUpdate={(rowIndex, value) =>
							{
								updateDescription(gridIds.adjustment, rowIndex, value);
							}}
							onValueUpdate={(fieldName, rowIndex, index, value) =>
							{
								triggerValueUpdate(gridIds.adjustment, fieldName, rowIndex, index, value);
							}}
						/>
					</div>
					{
						!isLoading &&
						<GridSeperator />
					}
					<div>
						<PropertyIncomeAggregateRows
							id={gridIds.aggregateTable}
							customRef={tableRefs[4]}
							isLoading={isLoading}
							displayNotes={displayNotes}
							income={incomeData}
							expenses={expenseData}
							adjustments={adjustmentData}
							allYearData={propertyIncomeData}
						/>
					</div>
				</div>
				<div className={'action-buttons'}>
					{
						onRenderSaveButton()
					}
				</div>
			</div>
		}
		{
			!!isNoFAHistory &&
			<div>
				{
					t('noFAHistory')
				}
			</div>
		}
	</>;
}