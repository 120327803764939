import { cloneDeep } from 'lodash';
import moment from 'moment';
import { AdjustmentFromSpecificTaxpayerFields } from 'src/common/types/enums/AdjustmentFromSpecificTaxpayerFields';
import { dateFormatter } from 'src/common/utils/dateFormatter';
import { Utils } from 'src/common/utils/utils';

export interface IAdjustmentFromSpecificTaxpayer {

	[AdjustmentFromSpecificTaxpayerFields.id]: number;
	[AdjustmentFromSpecificTaxpayerFields.engagementId]: number;
	[AdjustmentFromSpecificTaxpayerFields.affiliateId]: number;
	[AdjustmentFromSpecificTaxpayerFields.surplusPoolId]: number|undefined;
	[AdjustmentFromSpecificTaxpayerFields.descriptionId]: number|undefined;
	[AdjustmentFromSpecificTaxpayerFields.adjustmentDate]: Date | undefined;
	[AdjustmentFromSpecificTaxpayerFields.currencyId]: number|undefined;
	[AdjustmentFromSpecificTaxpayerFields.amount]: number|undefined;
	[AdjustmentFromSpecificTaxpayerFields.taxpayerId]: number|undefined|null;
	[AdjustmentFromSpecificTaxpayerFields.notes]?: string;
	[AdjustmentFromSpecificTaxpayerFields.specificTo]?: boolean;
	[AdjustmentFromSpecificTaxpayerFields.time]?: Date;
}

export class AdjustmentFromSpecificTaxpayer implements IAdjustmentFromSpecificTaxpayer
{
	constructor(
		engagementId: number,
		affiliateId: number,
		currencyId: number,
		taxPayerId: number
	)
	{
		const parsedDate = moment()
			.set({
				hour: 0,
				minute: 1,
				second: 0,
				millisecond: 0
			})
			.toDate();
		this[AdjustmentFromSpecificTaxpayerFields.id] = Utils.generateTempId();
		this[AdjustmentFromSpecificTaxpayerFields.engagementId] = engagementId;
		this[AdjustmentFromSpecificTaxpayerFields.affiliateId] =affiliateId;
		this[AdjustmentFromSpecificTaxpayerFields.surplusPoolId] = undefined;
		this[AdjustmentFromSpecificTaxpayerFields.descriptionId] = undefined;
		this[AdjustmentFromSpecificTaxpayerFields.adjustmentDate]= undefined;
		this[AdjustmentFromSpecificTaxpayerFields.currencyId]= currencyId;
		this[AdjustmentFromSpecificTaxpayerFields.amount]= undefined;
		this[AdjustmentFromSpecificTaxpayerFields.taxpayerId]= taxPayerId;
		this[AdjustmentFromSpecificTaxpayerFields.notes]=undefined;
		this[AdjustmentFromSpecificTaxpayerFields.specificTo]=undefined;
		this[AdjustmentFromSpecificTaxpayerFields.time] = cloneDeep(parsedDate);
	}

	[AdjustmentFromSpecificTaxpayerFields.id]: number;
	[AdjustmentFromSpecificTaxpayerFields.engagementId]: number;
	[AdjustmentFromSpecificTaxpayerFields.affiliateId]: number;
	[AdjustmentFromSpecificTaxpayerFields.surplusPoolId]: number|undefined;
	[AdjustmentFromSpecificTaxpayerFields.descriptionId]: number|undefined;
	[AdjustmentFromSpecificTaxpayerFields.adjustmentDate]: Date |undefined;
	[AdjustmentFromSpecificTaxpayerFields.currencyId]: number|undefined;
	[AdjustmentFromSpecificTaxpayerFields.amount]: number|undefined;
	[AdjustmentFromSpecificTaxpayerFields.taxpayerId]: number;
	[AdjustmentFromSpecificTaxpayerFields.notes]?: string;
	[AdjustmentFromSpecificTaxpayerFields.specificTo]?: boolean;
	[AdjustmentFromSpecificTaxpayerFields.time]?: Date;


	static populateFields(adjustments: IAdjustmentFromSpecificTaxpayer): IAdjustmentFromSpecificTaxpayer
	{
		const parsedDate = dateFormatter
			.utcToEst(adjustments[AdjustmentFromSpecificTaxpayerFields.adjustmentDate])!;

		return {
			...adjustments,
			[AdjustmentFromSpecificTaxpayerFields.adjustmentDate]: cloneDeep(parsedDate),
			[AdjustmentFromSpecificTaxpayerFields.time]: cloneDeep(parsedDate)
		};
	}

	static isSame(adjustments?: IAdjustmentFromSpecificTaxpayer, compareAdjustments?: IAdjustmentFromSpecificTaxpayer): boolean
	{
		const isDateSame = (date1?: Date, date2?: Date): boolean =>
		{
			return !!date1 && !!date2 ?
				moment(date1).isSame(date2) :
				!(
					(!!date1 && !date2) ||
					(!date1 && !!date2)
				);
		};

		return !!adjustments && !!compareAdjustments &&
			adjustments[AdjustmentFromSpecificTaxpayerFields.id] === compareAdjustments[AdjustmentFromSpecificTaxpayerFields.id] &&
			adjustments[AdjustmentFromSpecificTaxpayerFields.affiliateId] ===
			compareAdjustments[AdjustmentFromSpecificTaxpayerFields.affiliateId] &&
			adjustments[AdjustmentFromSpecificTaxpayerFields.engagementId] ===
			compareAdjustments[AdjustmentFromSpecificTaxpayerFields.engagementId] &&
			adjustments[AdjustmentFromSpecificTaxpayerFields.amount] ===
			compareAdjustments[AdjustmentFromSpecificTaxpayerFields.amount] &&
			adjustments[AdjustmentFromSpecificTaxpayerFields.currencyId] ===
			compareAdjustments[AdjustmentFromSpecificTaxpayerFields.currencyId] &&
			isDateSame(adjustments[AdjustmentFromSpecificTaxpayerFields.adjustmentDate],
				compareAdjustments[AdjustmentFromSpecificTaxpayerFields.adjustmentDate]) &&
			isDateSame(adjustments[AdjustmentFromSpecificTaxpayerFields.time],
				compareAdjustments[AdjustmentFromSpecificTaxpayerFields.time]) &&
			adjustments[AdjustmentFromSpecificTaxpayerFields.specificTo]===
			 compareAdjustments[AdjustmentFromSpecificTaxpayerFields.specificTo] &&
			adjustments[AdjustmentFromSpecificTaxpayerFields.notes] ===
			 compareAdjustments[AdjustmentFromSpecificTaxpayerFields.notes] &&
			 adjustments[AdjustmentFromSpecificTaxpayerFields.surplusPoolId] ===
			  compareAdjustments[AdjustmentFromSpecificTaxpayerFields.surplusPoolId] &&
			  adjustments[AdjustmentFromSpecificTaxpayerFields.descriptionId] ===
			   compareAdjustments[AdjustmentFromSpecificTaxpayerFields.descriptionId];;
	}
}