import { ICellRenderProps } from './ICellRenderProps';

import './CellRender.scss';

export function CellRender(props: ICellRenderProps): JSX.Element
{
	return <td>
		<div className={'cell-render'}>
			{
				!!props.onRenderValue ?
					props.onRenderValue() :
					!!props.value ?
						props.value :
						''
			}
		</div>
	</td>;
}