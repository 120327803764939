import { Select } from '@appkit4/react-components';
import { IDropdownFormControlProps } from './IDropdownFormControlProps';
import { SelectValue } from '@appkit4/react-components/esm/select/Select';
import { InputError } from '../../ModalControls/InputError/InputError';
import { useTranslation } from 'react-i18next';

export function DropdownFormControl(props: IDropdownFormControlProps): JSX.Element
{
	const defaultKey = 'label';
	const defaultValue = 'value';

	const { t: commonControlMessages } = useTranslation(
		'common',
		{
			keyPrefix: 'formControls'
		});

	const onChange = (value: SelectValue): void =>
	{
		if (!!props.onChange)
		{
			props.onChange(
				props.field,
				!!value ?
					value.toString() :
					undefined
			);
		}
	};

	return <>
		<div>
			<Select
				required={!!props.isRequired}
				disabled={!!props.isDisabled}
				searchable={!!props.isSearchable}
				placeholder={props.placeholder}
				data={
					!!props.options ?
						!!props.includeResetOption ?
							[
								{
									[!!props.labelKey ? props.labelKey : defaultKey]: 0,
									[!!props.valueKey ? props.valueKey : defaultValue]: commonControlMessages('blankOption') || ''
								},
								...props.options
							] :
							props.options :
						[]
				}
				labelKey={
					!!props.labelKey ?
						props.labelKey :
						defaultKey
				}
				valueKey={
					!!props.valueKey ?
						props.valueKey :
						defaultValue
				}
				dropdownRenderMode={'portal'}
				dropdownAlwaysDown={false}
				value={props.value}
				error={!!props.isError}
				noResultFound={
					<>
						{
							commonControlMessages('noDataAvailable')
						}
					</>
				}
				onSelect={onChange}
			/>
			<InputError
				isError={!!props.isError}
				message={props.errorMessage}
				onRenderMessage={props.onRenderErrorMessage}
			/>
		</div>
	</>;
}