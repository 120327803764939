import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import ns1 from './en/ns1.json';
import ns2 from './en/ns2.json';
import user from './en/user.json';
import homeDashboard from './en/homeDashboard.json';
import input from './en/input.json';
import common from './en/common.json';
import engagementSetup from './en/engagementSetup.json';
import errors from './en/errors.json';
import engInput from './en/engInput.json';
import sharedComponents from './en/sharedComponents.json';
import report from './en/report.json';

export const defaultNS = 'ns1';
export const resources = {
	en: {
		ns1,
		ns2,
		user,
		homeDashboard,
		input,
		common,
		engagementSetup,
		errors,
		engInput,
		sharedComponents,
		report
	}
} as const;

i18next.use(initReactI18next).init({
	lng: 'en',
	ns: ['ns1', 'ns2', 'user', 'homeDashboard', 'input','common', 'engagementSetup','errors','engInput','sharedComponents','report'],
	defaultNS,
	resources
});
