import { Input } from '@appkit4/react-components';
import React, { useEffect, useState } from 'react';
import { RegularPatternEnum } from 'src/common/types/enums/RegularPatternEnum';
import { Utils } from 'src/common/utils/utils';

export const CurrencyEditor = (props: {value: any, disabled: boolean, onChange: (v: any) => void}) =>
{
	const [currencyValue,setCurrencyValue] = useState('');
	const [isFocused, setIsFocused] = useState<boolean>(false);

	useEffect(() =>
	{
		setCurrencyValue(props.value);
	},[props.value]);

	const handleChange = (v: any) =>
	{
		const val = v?.target?.value ?? v;
		if (
			val
		)
		{
			const regPattern = new RegExp(RegularPatternEnum.Currency);
			if (!regPattern.test(val))
			{
				return;
			}
		}

		props.onChange(v);
		setCurrencyValue(val);
	};

	const handleBlur = () =>
	{
		const isValueNaN = isNaN(Number(currencyValue));
		if (isValueNaN)
		{
			props.onChange('');
			setCurrencyValue('');
		}
		setIsFocused(false);
	};

	const handleFocus = () =>
	{
		setIsFocused(true);
	};

	return (
		<>
			<Input
				type={'text'}
				value={isFocused?currencyValue: Utils.formatCurrency(currencyValue, 2)}
				disabled={props.disabled}
				hideTitleOnInput={true}
				onChange={handleChange}
				onBlur={handleBlur}
				onFocus={handleFocus}
			></Input>
		</>
	);
};
