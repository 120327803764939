import React, { useEffect, useState } from 'react';
import { FAQuickView } from '../../components/FAQuickView/FAQuickView';

import './AffiliatePage.scss';
import { Layout } from 'src/common/components/Layout/Layout';
import { ReportLayout } from '../../components/Report/Layout/ReportLayout';
import { useParams, useNavigate } from 'react-router-dom';
import { IAffiliateContext } from 'src/common/types/interfaces/IAffiliateContext';
import { engagementService } from '../../services/engagementService';
import { EngagementDetail } from '../../models/IEngagementDetail';
import { ITaxPayer } from '../../models/ITaxPayer';
import AffiliateContext from 'src/common/components/SharedDataContext/AffiliateContext';
import { coreService } from 'src/core/services/coreService';
import { FAInput } from '../../components/FAInput/FAInput';
import { AffiliatePageTypes } from 'src/common/types/enums/AffiliatePageTypes';
import { useDispatch, useSelector } from 'react-redux';
import signalRService from 'src/common/services/signalRService';
import { RootState } from 'src/store/store';
import { clearSurplusCalculationNotification } from 'src/store/reducers/notificationSlice';
import { CalculationStatus } from 'src/common/types/enums/CalculationStatus';
import { ICalculationHistory } from 'src/features/engagement/models/ICalculationHistory';
import { HttpStatusCode } from 'axios';

export const AffiliatePage = () =>
{
	const [affiliateContext, setAffiliateContext] = useState<IAffiliateContext | undefined>(undefined);
	const { engagementId, affiliateId, pageType } = useParams();
	const [showCalcInProgressBanner, setShowCalcInProgressBanner] = useState<boolean>(false);
	const isHubConnectionOpen = useSelector((state: RootState) => state.notifications.isHubConnectionOpen);
	const [calculationHistory, setCalculationHistory] = useState<ICalculationHistory>();
	const surplusCalculationNotification = useSelector((state: RootState) => state.notifications.surplusCalculationNotification);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() =>
	{
		if (isHubConnectionOpen)
		{
			signalRService.subscribeToEngagementNotifications(Number(engagementId));

			return () =>
			{
				signalRService.unsubscribeFromEngagementNotifications();
			};
		}
	}, [isHubConnectionOpen]);

	useEffect(() =>
	{
		const fetchAffiliateContext = async (engagementId: number, affiliateId: number) =>
		{
			if (engagementId && affiliateId)
			{
				try
				{
					const [engagementResponse, affiliateResponse] = await Promise.all(
						[
							engagementService.getEngagementDetailById(engagementId),
							engagementService.getForeignAffiliate(engagementId, affiliateId)
						]
					);
					const engagementDetails = EngagementDetail.parseFields(engagementResponse.data.result);
					const affiliateDetail = affiliateResponse.data.result;

					const taxpayersResponse = await engagementService
						.getTaxPayers(engagementDetails.clientId!);

					const taxpayers: ITaxPayer[] = taxpayersResponse.data.result;

					const clientsResponse = await coreService.getAllClients();

					const clients = clientsResponse.data.result;

					const ctx: IAffiliateContext = {
						engagementDetail: engagementDetails,
						affiliateDetail: affiliateDetail,
						taxpayers: taxpayers,
						clients: clients
					};
					setAffiliateContext(ctx);
				}
				catch (error: any)
				{
					if (!!error && !!error.response && !!error.response.data)
					{
						const status = error.response.data.Status;
						const errors = error.response.data.Errors;

						if (status === HttpStatusCode.BadRequest && errors.length > 0 &&
							errors.find((e: any) => e?.ErrorCode === 'ENGAGEMENT_FOREIGN_MAPPING_DOES_NOT_EXIST'))
						{
							navigate('/resource-not-found');
						}
					}
					if (!!error && !!error.response && error.response.status ===  HttpStatusCode.NotFound)
					{
						navigate('/resource-not-found');
					}
					if (!!error && !!error.response && error.response.status ===  HttpStatusCode.Forbidden)
					{
						navigate('/user-access-denied');
					}
				}
			}
		};
		if (engagementId && affiliateId)
		{
			fetchAffiliateContext(+engagementId, +affiliateId);
		}
	}, [engagementId, affiliateId]);

	useEffect(() =>
	{
		if (!!surplusCalculationNotification)
		{
			if (!!surplusCalculationNotification[CalculationStatus.SurplusCalculationHistory])
			{
				const surplusCalculationHistory = surplusCalculationNotification[CalculationStatus.SurplusCalculationHistory];
				setCalculationHistory(surplusCalculationHistory);

				const status = surplusCalculationHistory[CalculationStatus.Status];

				if (status === CalculationStatus.Processed || status === CalculationStatus.Failed)
				{
					setShowCalcInProgressBanner(false);
				}
				else if (status === CalculationStatus.NotProcessed)
				{
					setShowCalcInProgressBanner(true);
				}

				dispatch(clearSurplusCalculationNotification());
			}
		}
	}, [surplusCalculationNotification]);


	return (
		<Layout>
			{affiliateContext &&
				<AffiliateContext.Provider value={affiliateContext} >
					<div className="affiliate-page">
						<FAQuickView
							showCalcInProgressBanner={showCalcInProgressBanner} />
						{
							pageType?.toLowerCase() === AffiliatePageTypes.Report &&
							<ReportLayout setShowBanner={setShowCalcInProgressBanner} calcHistory={calculationHistory} />
						}
						{
							pageType?.toLowerCase() === AffiliatePageTypes.Input && <FAInput />
						}
					</div>
				</AffiliateContext.Provider>}
		</Layout>
	);
};
